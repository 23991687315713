//GiftCardRecipients
import { useEffect, useState } from "react";
import { SelectField, CustomButton } from "../HtmlComponents";
import { getCurrentDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth } from '../../helpers';
import { createReferrerGiftCardReport } from '../../api';
import { useToken } from "../../hooks/useAuth";
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';

const GiftCardRecipients = () => {
    const [month, setMonth] = useState("");     //current, previous
    const [duration, setDuration] = useState({ fromMonth: 0, fromYear: 0, toMonth: 0, toYear: 0 });
    const [processing, setProcessing] = useState(false);



    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "july" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    var token = useToken();


    const updateMonthsAndYears = () => {
        var localYears = [];
        for (var i = 2018; i <= getCurrentDate("YYYY"); i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    useEffect(() => {
        updateMonthsAndYears();
    }, [])

    useEffect(() => {
        if (duration["fromMonth"] > 0 && duration["fromYear"] > 0 && duration["toMonth"] > 0 && duration["toYear"] > 0) {
            setAllowSubmission(true);
        }
        else if (month !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [duration, month])

    const handler = async (name, value) => {
        switch (name) {
            case "monthSelection":
                setMonth(value);
                break;
            case "fromMonth":
                setDuration({ ...duration, fromMonth: value })
                break;
            case "fromYear":
                setDuration({ ...duration, fromYear: value })
                break;
            case "toMonth":
                setDuration({ ...duration, toMonth: value })
                break;
            case "toYear":
                setDuration({ ...duration, toYear: value })
                break;
            case "clearFilters":
                setDuration({ ...duration, fromMonth: "", fromYear: "", toMonth: "", toYear: "" });
                setMonth("");
                break;
            case "submit":
                var startDate = 0;
                var endDate = 0;
                setProcessing(true);

                if (month.length > 0) {
                    if (month === "currentMonth") {
                        startDate = convertDateintoUnix(getCurrentMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    } else {
                        startDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getPreviousMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    }
                }
                else {
                    //DURATION IS SELECTED
                    startDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
                    endDate = convertDateintoUnix(getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`), "YYYY-MM-DD H:mm:ss");
                }
                //callback("dateFilter" , {startDate: startDate, endDate: endDate});
                var paylaod = { startDate: startDate, endDate: endDate };
                console.log(paylaod);

                //MAKE API REQUEST
                var resp = await createReferrerGiftCardReport(paylaod, token);
                console.log("API resp:: ", resp);
                if (resp["error"]) {
                    Notifications("error", resp["message"] || "Unable to process the request. ")
                }
                else {
                    var pdfBytes = resp["referrerGroup"]["pdfBytes"];
                    //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                    const bytes = new Uint8Array(Object.values(pdfBytes));
                    const blob = new Blob([bytes], { type: "application/pdf" });
                    const docUrl = URL.createObjectURL(blob);

                    let alink = document.createElement('a');
                    alink.href = docUrl;
                    alink.download = 'Gift Card Report.pdf';
                    alink.click();
                    Notifications("success", resp["message"] || "Downloaded Successfully. ")
                }
                setProcessing(false);
                break;
        }
    }

    return (<>

        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                    <div className="card-body">
                        <div className="row">

                            { /** SEARCH TYPE 1 **/}
                            <div className="col-sm-2 fieldSet" >
                                <div className="heading">Select</div>
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Month</label>
                                <SelectField
                                    fieldName="monthSelection"
                                    defaultSelectedOption={{ value: month, label: "" }}
                                    options={[{ "value": "", "label": "Select Month" }, { "value": "currentMonth", "label": "Current Month" }, { "value": "previousMonth", "label": "Previous Month" }]}
                                    setFieldValue={handler}
                                />
                            </div>

                            { /** SEARCH TYPE 2 **/}
                            <div className="col-sm-10 fieldSet">
                                <div className="heading">Or Search</div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Month</label>
                                        <SelectField
                                            fieldName="fromMonth"
                                            defaultSelectedOption={{ value: duration['fromMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Year</label>
                                        <SelectField
                                            fieldName="fromYear"
                                            defaultSelectedOption={{ value: duration['fromYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Month</label>
                                        <SelectField
                                            fieldName="toMonth"
                                            defaultSelectedOption={{ value: duration['toMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Year</label>
                                        <SelectField
                                            fieldName="toYear"
                                            defaultSelectedOption={{ value: duration['toYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <CustomButton
                                buttonClassName="btn btn-sm btn-primary "
                                buttonStyle={{ marginTop: "10px", float: "right" }}
                                buttonOnClick={() => { handler("submit", "") }}
                                buttonText="Submit"
                                isActive={allowSubmission}
                            />

                            <CustomButton
                                buttonClassName="btn btn-sm btn-secondary "
                                buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                                buttonOnClick={() => { handler("clearFilters", "") }}
                                buttonText="Clear Filters"
                                isActive={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { processing ? <LoadingScreen /> : "" }

    </>)
}

export default GiftCardRecipients;