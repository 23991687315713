import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox } from '../../HtmlComponents';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";

import { getBankStatementLinks, getComments , sendBankStatementRequestToCustomer} from '../../../api';
import { useToken } from '../../../hooks/useAuth';

import LoadingScreen from '../../global/LoadingScreen';

import BankStatementRequestValidationSchema from '../schema/BankStatementRequestValidationSchema';

const BankStatementRequestForm = ({ applicationId, applicant, callback }) => {
    const [bankStatementLinks, setBankStatementLinks] = useState([]);
    
    const [initialValues, setInitialValues] = useState({applicationId: applicationId,
        applicantId: applicant.id !== undefined ? applicant.id: 0,
        bankStatementLinkId: 0,
        receiver: {
            name: "",
            email: ""
        }});
    const [processing, setProcessing] = useState(false);

    const token = useToken();

    //FETCH ELECTRONIC SIGNATURES TEMPLATES
    const fetchBankStatementLinks = async () => {
        var response = await getBankStatementLinks(token);
        if (response["error"] !== undefined && response["error"] == 0) {
            var bankStatementLinksData = response["data"];
            var tmpOptions = [];
            bankStatementLinksData.map(singleBankStatementLink => {
                tmpOptions.push({ label: singleBankStatementLink["duration"] + " Months", value: singleBankStatementLink["id"] });
            });
            setBankStatementLinks(tmpOptions);
        }
    }

 
    useEffect(() => {
        fetchBankStatementLinks();
    }, []);

    useEffect(() => {
        var personalDetails = applicant["personalDetail"] !== undefined ? applicant["personalDetail"] : {};
        var firstName = personalDetails["firstName"] !== undefined ? personalDetails["firstName"] : ""
        var middleName = personalDetails["middleName"] !== undefined ? personalDetails["middleName"] : ""
        var surname = personalDetails["surname"] !== undefined ? personalDetails["surname"] : ""
        var email = personalDetails["email"] !== undefined ? personalDetails["email"] : ""

        var tmpInitialValues = {
            applicationId: applicationId,
            applicantId: applicant.id !== undefined ? applicant.id: 0,
            "bankStatementLinkId": 0,
            "receiver": {
                "name": firstName + " " + middleName + " " + surname,
                "email": email
            }
        }
        setInitialValues({...tmpInitialValues })
    }, [applicant])


    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validateOnBlur={false}
                        validationSchema={BankStatementRequestValidationSchema}
                        onSubmit={async (values) => {
                            //MAKE AN API CALL 
                            setProcessing(true);

                            var response = await sendBankStatementRequestToCustomer(token, values);
                            setProcessing(false);

                            if(response["error"] == 0){ Notifications("success", response["message"]); }
                            else{ Notifications("error", response["message"]); }
                            
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label className="fs-8 fw-bold mb-2">Receiver Name </label>
                                        <Field name="receiver.name" className="form-control  " />
                                        <div className="error">{errors !== undefined && errors.receiver !== undefined && errors.receiver.name ? errors.receiver.name : ""}</div>
                                    </div>

                                    <div className="col-sm-6">
                                        <label className="fs-8 fw-bold mb-2">Receiver Email </label>
                                        <Field name="receiver.email" className="form-control  " />
                                        <div className="error">{errors !== undefined && errors.receiver !== undefined && errors.receiver.email ? errors.receiver.email : ""}</div>
                                    </div>

                                    <div className="col-sm-6 mt-5">
                                        <label className="required fs-8 fw-bold mb-2">Duration </label>
                                        <Field name="bankStatementLinkId">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="bankStatementLinkId"
                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                    options={bankStatementLinks}
                                                    setFieldValue={setFieldValue}
                                                    callback={() => { }}
                                                />

                                            )}
                                        </Field>
                                        <div className="error">{errors.bankStatementLinkId ? errors.bankStatementLinkId : ""}</div>
                                    </div>
                                    <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default BankStatementRequestForm;
