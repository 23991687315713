
import { IconButton, ConfirmDialog } from '../HtmlComponents';
import { deleteUser } from "../../api";
import { useToken } from '../../hooks/useAuth';
import Notifications from '../global/Notifications';
import { Link } from 'react-router-dom';


const ReferrerGroupManagerListItem = ({ key, referrerGroupManager, callback }) => {

    const handler = (action, paylaod) => {



    }


    //GET FORMATTED REFERRER GROUP MANAGER LIST ITEM
    const getFormattedReferrerGroupManagerListItem = () => {
        let assignedReferrerGroups = referrerGroupManager.assignedReferrerGroups;  //IT WILL HAVE A STRING SEPRATED BY COMMA, MAKE THEM AS ARRAY
        assignedReferrerGroups = assignedReferrerGroups.split(",");
        let formattedValue = assignedReferrerGroups = assignedReferrerGroups.map((item) => {
            let referrerGroupId = item.split(":")[0];
            let referrerGroupName = item.split(":")[1];
            return <span><Link to={`/user/referrerGroupDetail/${referrerGroupId}`}>{referrerGroupName.trim()}</Link>    |   </span>
        })
        return formattedValue;
    }

    return (
        <>
            <tr>
                <td>{referrerGroupManager && referrerGroupManager.userName ? referrerGroupManager.userName : ""}</td>
                <td>
                    {referrerGroupManager && referrerGroupManager.firstName ? referrerGroupManager.firstName : ""}
                    {referrerGroupManager && referrerGroupManager.surname ? " " + referrerGroupManager.surname : ""}
                </td>
                <td>{referrerGroupManager && referrerGroupManager.email ? referrerGroupManager.email : ""}</td>
                <td>{referrerGroupManager && referrerGroupManager.contactNumber ? referrerGroupManager.contactNumber : ""}</td>
                <td>{
                    getFormattedReferrerGroupManagerListItem()
                }</td>
                <td>
                    <IconButton
                        iconType="edit"
                        buttonClassName="btn btn-sm btn-light btn-active-secondary"
                        buttonStyle={{ background: "transparent" }}
                        buttonOnClick={() => { callback("openUpdatereferrerGroupManagerDraw", { referrerGroupManagerId: referrerGroupManager.id } ) }}
                        tooltipDescription="Update Referrer Group Manager"
                    />

                    {/*
                    <IconButton
                        iconType="delete"
                        buttonClassName="btn btn-sm btn-light btn-active-secondary"
                        buttonStyle={{ background: "transparent", color: "red" }}
                        buttonOnClick={() => { handler("deleteReferrerGroupManager", { referrerGroupManagerId: referrerGroupManager.id }) }}
                        tooltipDescription="Delete Referrer Group Manager"
                    /> */}

                </td>
            </tr>
        </>

    )

}


export default ReferrerGroupManagerListItem;