import { useEffect, useState } from "react";
import { SelectField, CustomButton, TextEditor , IconButton} from "../HtmlComponents";
import { formatDate, getCurrentDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth } from '../../helpers';
import { getReferrersList, createReferrerRCTI, getUsers, getRctiAdditionalData } from '../../api';
import { useToken, useEntityType } from "../../hooks/useAuth";
import SingleRebate from './components/SingleRebate';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';

const ReferrerRCTI = () => {
    const [referrers, setReferrers] = useState([]);
    const [selectedReferrer, setSelectedReferrer] = useState("");
    const [month, setMonth] = useState("");     //current, previous
    const [duration, setDuration] = useState({ fromMonth: 0, fromYear: 0, toMonth: 0, toYear: 0 });
    const [rebates, setRebates] = useState([]);
    const [processing, setProcessing] = useState(false);
    const userEntityType = useEntityType();


    //DATA FOR RCTI EMAIL
    const [singleReferrerUsers, setSingleReferrerUsers] = useState([]);
    const [sendEmailScreen, setSendEmailScreen] = useState(false);
    const [emailPayload, setEmailPayload] = useState({ userEmail: "", emailBody: "" })


    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "july" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    var token = useToken();


    const updateMonthsAndYears = () => {
        var localYears = [];

        for (var i = 2018; i <= getCurrentDate("YYYY"); i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    async function fetchReferrerList() {

        //IF LOGGED IN USER IS REFERRER
        if (userEntityType == "referrer") {
            try {
                var localStorageReferrersList = JSON.parse(localStorage.getItem("referrersList"));

                var referrerList = [];
                referrerList.push({ "value": 0, "label": "Select" });
                if (localStorageReferrersList !== undefined && Array.isArray(localStorageReferrersList)) {
                    localStorageReferrersList.map(singleReferrer => { referrerList.push({ "value": singleReferrer["id"], "label": singleReferrer["name"] }) })
                    setReferrers(referrerList)
                }
            }
            catch (e) { }
        }
        //OTHERWISE
        else {
            console.log("ELSE PART - LISTING REFERRERS ---------------   LINE 62 ---------")
            const result = await getReferrersList(token, "active");
            var referrers = [];
            if (result["error"] == 0 && result["referrers"] != undefined) { referrers = result["referrers"]; }

            var referrerList = [];
            referrerList.push({ "value": 0, "label": "Select" });
            if (referrers !== undefined && Array.isArray(referrers)) {
                referrers.map(singleReferrer => { referrerList.push({ "value": singleReferrer["id"], "label": singleReferrer["tradingName"] }) })
                setReferrers(referrerList)
            }
        }

    }

    //FETCH REFERRER USERS LIST 
    const fetchreferrerUsers = async (referrerId) => {
        if (referrerId === 0) {
            var referrersUsersList = [];
            referrersUsersList.push({ "value": "", "label": "Select" });
            setSingleReferrerUsers(referrersUsersList);
        }
        else {
            const response = await getUsers(token, "referrer", referrerId);
            var referrersUsers = response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"]) ? response["data"] : [];
            var referrersUsersList = [];
            referrersUsersList = referrersUsers.map(user => { return { "value": user["email"], "label": user["firstName"] } })

            referrersUsersList.unshift({ "value": "", "label": "Select" });
            setSingleReferrerUsers(referrersUsersList);
        }
    }

    useEffect(() => {
        updateMonthsAndYears();
        fetchReferrerList();
    }, [])

    useEffect(() => {
        if (duration["fromMonth"] > 0 && duration["fromYear"] > 0 && duration["toMonth"] > 0 && duration["toYear"] > 0) {
            setAllowSubmission(true);
        }
        else if (month !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [duration, month])

    useEffect(() => {
        console.log("rebates", rebates);
    }, [rebates]);

    const handler = async (name, value) => {
        switch (name) {
            case "monthSelection":
                setMonth(value);
                break;
            case "fromMonth":
                setDuration({ ...duration, fromMonth: value })
                break;
            case "fromYear":
                setDuration({ ...duration, fromYear: value })
                break;
            case "toMonth":
                setDuration({ ...duration, toMonth: value })
                break;
            case "toYear":
                setDuration({ ...duration, toYear: value })
                break;
            case "referer":
                console.log("referer ::: ", value);
                setSelectedReferrer(value);
                break;
            case "addRebate":
                setRebates(current => [...current, { description: "", amount: "" }])
                break;
            case "updateRebateValue":
                var fieldName = value["name"];
                var fieldValue = value["value"];
                var index = value["index"];

                setRebates(current => current.map((rebate, rebateIndex) => { return index == rebateIndex ? { ...rebate, [fieldName]: fieldValue } : rebate; }))
                break;
            case "deleteRebate":
                var index = value["index"];
                setRebates(current => current.filter((rebate, rebateIndex) => index !== rebateIndex))
                break;
            case "clearFilters":
                setDuration({ ...duration, fromMonth: "", fromYear: "", toMonth: "", toYear: "" });
                setMonth("");
                setSelectedReferrer("");
                break;

            case "goBackToPreviousScreen":
                setDuration({ ...duration, fromMonth: "", fromYear: "", toMonth: "", toYear: "" });
                setMonth("");
                setSelectedReferrer("");
                setEmailPayload({ userEmail: "", emailBody: "" });
                setSendEmailScreen(false);
                break;

            case "submit":
                var startDate = 0;
                var endDate = 0;
                setProcessing(true);

                if (month.length > 0) {
                    if (month === "currentMonth") {
                        startDate = convertDateintoUnix(getCurrentMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    } else {
                        startDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getPreviousMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    }
                }
                else {
                    //DURATION IS SELECTED
                    startDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
                    endDate = convertDateintoUnix(getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`) + " 23:59:59", "YYYY-MM-DD H:mm:ss");
                }
                //callback("dateFilter" , {startDate: startDate, endDate: endDate});
                var referrerValue = isNaN(parseInt(selectedReferrer)) || parseInt(selectedReferrer) == 0 ? "any" : parseInt(selectedReferrer);
                var paylaod = { startDate: startDate, endDate: endDate, referrerId: referrerValue, rebates: rebates, sendEmail: sendEmailScreen, email: emailPayload["userEmail"], emailBody: emailPayload["emailBody"] };
                console.log(paylaod);

                //MAKE API REQUEST
                var query = "";
                if (userEntityType == "referrer") {
                    var selectedReferrerId = localStorage.getItem("selectedReferrer");
                    query = `?referrerId=${selectedReferrerId}`;
                }


                if (!isNaN(parseInt(paylaod["referrerId"]))) { fetchreferrerUsers(paylaod["referrerId"]); }

                var resp = await createReferrerRCTI(paylaod, token, query);
                console.log("API resp:: ", resp);
                if (resp["error"]) {
                    Notifications("error", resp["message"] || "Unable to process the request. ")
                }
                else if (resp["data"]["pdfExist"]) {
                    var pdfBytes = resp["data"]["pdfBytes"];

                    //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                    const bytes = new Uint8Array(Object.values(pdfBytes));
                    const blob = new Blob([bytes], { type: "application/pdf" });
                    const docUrl = URL.createObjectURL(blob);

                    let alink = document.createElement('a');
                    alink.href = docUrl;
                    alink.download = 'RCTI.pdf';
                    alink.click();
                    if (["creditRep", "company"].includes(userEntityType)) {
                        setSendEmailScreen(true);    //SET THIS FLAG TRUE, THE VIEW DATA IS DECIDED ON BASE OF THIS FLAG
                    }
                    Notifications("success", resp["message"] || "Created Successfully. ")
                }
                else if (!resp["data"]["pdfExist"]) {
                    setSendEmailScreen(false);
                    setEmailPayload({ userEmail: "", emailBody: "" });
                    Notifications("success", resp["message"] || "")
                }
                setProcessing(false);
                break;
        }
    }


    //LOAD OLD/SAVED REBATES
    const loadRebates = async () => {
        //getRctiAdditionalData
        //token , selectedReferrer, rctiDate

        var error = 0;
        var message = '';
        if (selectedReferrer === "") {
            error = 1;
            message = "Please select the referrer first";
        }

        var rctiDate = 0;
        if (month.length > 0) {
            if (month === "currentMonth") { rctiDate = convertDateintoUnix(getCurrentMonth("firstDay"), "YYYY-MM-DD H:mm:ss"); }
            else { rctiDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss"); }
        }
        else {
            rctiDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
            if (duration["fromMonth"] !== 0 && (duration["fromMonth"] !== duration["toMonth"] || duration["fromYear"] !== duration["toYear"])) { error = 1; message = " Please select single month only."; }
        }

        if (error === 0 && (isNaN(rctiDate) || isNaN(parseInt(rctiDate)))) {
            message = " Please select the date";
            error = 1;
        }

        if (error) {  Notifications("error", message);  }
        else {
            //LOAD THE SAVED REBATES
            try {
                var response = await getRctiAdditionalData(token, selectedReferrer, "referrer", rctiDate);
                if (response["error"] == 0) {
                    let data = JSON.parse(response["data"]);
                    let tmpRebates = data["rebates"];
                    setRebates(tmpRebates);
                }
            }
            catch (e) {
                console.log(JSON.stringify(e));
                console.log("REBATE ERROR --- ", e.message);
             }
        }
    }

    return (<>

        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                    <div className="card-body">
                        <div className="row">
                            {userEntityType !== "referrer" && (!isNaN(parseInt(selectedReferrer)) && parseInt(selectedReferrer) !== 0) && sendEmailScreen ?
                                <>
                                    <div className="col-sm-12 ">
                                        <SelectField
                                            fieldName="userEmail"
                                            defaultSelectedOption={{ value: emailPayload["userEmail"], label: "" }}
                                            options={singleReferrerUsers}
                                            setFieldValue={(name, value) => { console.log("EMAIL ", value); setEmailPayload({ ...emailPayload, userEmail: value }) }}
                                        />
                                    </div>
                                    <div className="col-sm-12 ">
                                        <div className="mt-5">
                                            <TextEditor fieldName="emailBody" initialValue={""} height={300}
                                                setFieldValue={(name, value) => { setEmailPayload({ ...emailPayload, emailBody: value }) }} />
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        {JSON.stringify(emailPayload)}
                                        <CustomButton
                                            buttonClassName="btn btn-sm btn-primary "
                                            buttonStyle={{ marginTop: "10px", float: "right" }}
                                            buttonOnClick={() => { handler("submit", "") }}
                                            buttonText="Send Email"
                                            isActive={emailPayload["userEmail"] !== "" ? true : false}
                                        />

                                        <CustomButton
                                            buttonClassName="btn btn-sm btn-secondary "
                                            buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                                            buttonOnClick={() => { handler("goBackToPreviousScreen", "") }}
                                            buttonText="Go Back TO Previous Screen"
                                            isActive={true}
                                        />
                                    </div>
                                </>
                                :

                                <>
                                    <div className="col-sm-12 mb-5" style={{ padding: "0px" }} >
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Referrer</label>
                                        <SelectField
                                            fieldName="referer"
                                            defaultSelectedOption={{ value: selectedReferrer, label: "" }}
                                            options={referrers}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                    { /** SEARCH TYPE 1 **/}
                                    <div className="col-sm-2 fieldSet" >
                                        <div className="heading">Select</div>
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Month</label>
                                        <SelectField
                                            fieldName="monthSelection"
                                            defaultSelectedOption={{ value: month, label: "" }}
                                            options={[{ "value": "", "label": "Select Month" }, { "value": "currentMonth", "label": "Current Month" }, { "value": "previousMonth", "label": "Previous Month" }]}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                    { /** SEARCH TYPE 2 **/}
                                    <div className="col-sm-10 fieldSet">
                                        <div className="heading">Or Search</div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label style={{ fontSize: "10px", fontWeight: "600" }}>From Month</label>
                                                <SelectField
                                                    fieldName="fromMonth"
                                                    defaultSelectedOption={{ value: duration['fromMonth'], label: "" }}
                                                    options={months}
                                                    setFieldValue={handler}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label style={{ fontSize: "10px", fontWeight: "600" }}>From Year</label>
                                                <SelectField
                                                    fieldName="fromYear"
                                                    defaultSelectedOption={{ value: duration['fromYear'], label: "" }}
                                                    options={years}
                                                    setFieldValue={handler}
                                                />
                                            </div>

                                            <div className="col-sm-3">
                                                <label style={{ fontSize: "10px", fontWeight: "600" }}>To Month</label>
                                                <SelectField
                                                    fieldName="toMonth"
                                                    defaultSelectedOption={{ value: duration['toMonth'], label: "" }}
                                                    options={months}
                                                    setFieldValue={handler}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label style={{ fontSize: "10px", fontWeight: "600" }}>To Year</label>
                                                <SelectField
                                                    fieldName="toYear"
                                                    defaultSelectedOption={{ value: duration['toYear'], label: "" }}
                                                    options={years}
                                                    setFieldValue={handler}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-5">
                                        <div className="row">
                                            {
                                                userEntityType !== "referrer" ?
                                                    <div className="col-sm-6">
                                                        <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => { handler("addRebate", "") }}>
                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                                </svg>
                                                            </span>
                                                            Add Rebate
                                                        </button>
                                                    </div> : ""
                                            }
                                             
                                            {
                                                userEntityType !== "referrer" ?
                                                    <div className="col-sm-6">
                                                        <IconButton iconType="refresh" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f", float: "right" }} tootltip={true} tooltipDescription="Load saved Rebates" buttonOnClick={() => { loadRebates() }} />
                                                    </div>
                                                    : ""
                                            }

                                            {/** REBATES LISTING  **/}
                                            <div className="col-sm-12">
                                                {
                                                    Array.isArray(rebates) ?
                                                        rebates.map((rebate, index) => {
                                                            return (
                                                                <SingleRebate rebate={rebate} index={index} callback={handler} key={index} />
                                                            )

                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <CustomButton
                                            buttonClassName="btn btn-sm btn-primary "
                                            buttonStyle={{ marginTop: "10px", float: "right" }}
                                            buttonOnClick={() => { handler("submit", "") }}
                                            buttonText="Submit"
                                            isActive={allowSubmission}
                                        />

                                        <CustomButton
                                            buttonClassName="btn btn-sm btn-secondary "
                                            buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                                            buttonOnClick={() => { handler("clearFilters", "") }}
                                            buttonText="Clear Filters"
                                            isActive={true}
                                        />
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>
            </div>


        </div>
        {processing ? <LoadingScreen /> : ""}

    </>)
}

export default ReferrerRCTI;