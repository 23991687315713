import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import CreditProposalValidationSchema from '../schema/CreditProposalValidationSchema';
import Notifications from "../../global/Notifications";


import { getApplication, performApplicationAdditionalActions, getLendersList, getLender, getComments } from "../../../api";
import { useToken } from '../../../hooks/useAuth';
import LoadingScreen from '../../global/LoadingScreen';

//application, applicant, 
const CreditProposal = ({ applicationId, callback }) => {

    const [application, setApplication] = useState({});
    const [applicants, setApplicants] = useState([]);
    const [applicant, setApplicant] = useState({});
    const [listApplicants, setListApplicants] = useState([]);

    const [individualDetails, setIndividualDetails] = useState({});
    const [comments, setComments] = useState([]);
    const [lenders, setLenders] = useState([]);
    const [processing, setProcessing] = useState(false);

    const formRef = useRef();
    const token = useToken();

    const applicationType = application.type !== undefined ? application.type : "";


    //LOAD APPLICATION 
    const loadApplication = async () => {
        const response = await getApplication(applicationId, token);
        if (response["httpCode"]) {
            if (response["httpCode"] == 200) {
                var quoteData = response.data;

                var tmpApplicants = JSON.parse(JSON.stringify(quoteData.applicants));
                console.log("applicants::::::::::: ", tmpApplicants);
                var tmpApplicantsQuickInfo = [];
                var tmpListApplicants = [];         //USE FOR DROPDOWN OPTIONS
                if (Array.isArray(tmpApplicants)) {
                    tmpApplicants.map(singleApplicant => {
                        let applicantCurrentAddress = singleApplicant.personalDetail !== undefined && singleApplicant.personalDetail.residentialHistory !== undefined && singleApplicant.personalDetail.residentialHistory.current !== undefined &&
                            singleApplicant.personalDetail.residentialHistory.current.address !== undefined ? singleApplicant.personalDetail.residentialHistory.current.address : {};

                        tmpApplicantsQuickInfo.push({
                            id: singleApplicant.id,
                            firstName: singleApplicant.firstName,
                            middleName: singleApplicant.middleName,
                            surname: singleApplicant.surname,
                            contactNumber: singleApplicant.contactNumber,
                            email: singleApplicant.email,
                            address: applicantCurrentAddress
                        });

                        //OPTIONS
                        tmpListApplicants.push({ value: singleApplicant.id, label: singleApplicant.firstName })
                    })
                }

                setListApplicants(tmpListApplicants);
                setApplicants(tmpApplicantsQuickInfo);
                setApplicant(tmpApplicantsQuickInfo[0] !== undefined ? tmpApplicantsQuickInfo[0] : {});
                delete quoteData.applicants;

                setApplication(response.data);
                setProcessing(false);
            }
            else {

                setProcessing(false);
            }
        }
    }

    const fetchLendersList = async () => {
        const response = await getLendersList(token);

        if (response["error"] === 0 && response["lenders"]) {
            var lenders = response["lenders"];
            var lendersList = [];
            if (lenders !== undefined && Array.isArray(lenders)) {
                lenders.map(singleLender => { lendersList.push({ "value": singleLender["id"], "label": singleLender["name"] }) })
                setLenders(lendersList)
            }
        }
        else {
            //ERROR OCCURED
        }

    }

    const fetchSingleLender = async (id) => {
        var response = await getLender(id, token);
        var feeStructure = response.feeStructure !== undefined ? response.feeStructure : {};
        var creditSearchFee = feeStructure.creditSearchFee !== undefined ? feeStructure.creditSearchFee : 0;

        var consumerFee = feeStructure.consumerFee !== undefined ? feeStructure.consumerFee : 0;
        var commercialFee = feeStructure.commercialFee !== undefined ? feeStructure.commercialFee : 0;
        var registrationfee = feeStructure.creditSearchFee !== undefined ? feeStructure.registrationFee : 0;
        var creditSearchFee = feeStructure.creditSearchFee !== undefined ? feeStructure.creditSearchFee : 0;
        var monthlyFee = feeStructure.monthlyFee !== undefined ? feeStructure.monthlyFee : 0;

        var initialValues = formRef.current.values;
        initialValues["applicationFee"] = applicationType == "commercial" ? commercialFee : consumerFee;
        initialValues["creditSearchFee"] = creditSearchFee;
        initialValues["registrationFee"] = registrationfee;

        setIndividualDetails({ ...initialValues, initialValues });
    }

    //FETCH NOTES /COMMENTS
    const fetchComments = async () => {
        var response = await getComments(token, "creditProposal", applicationId);
        if (response["error"] == 0) {
            setComments(response["data"]);
        }
    }

    useEffect(() => {
        loadApplication();
        fetchLendersList();
        fetchComments();

    }, [])

    useEffect(() => {
        /*var tmpIndividualDetails = {
            name: "",
            contactNumber: "",
            email: "",
            address: "",
            lender: application.lenderId !== undefined ? application.lenderId : 0,
            netFinanceAmount: application.loan !== undefined && application.loan.totalFinancedAmountExcludingInsurances !== undefined ? application.loan.totalFinancedAmountExcludingInsurances : 0,
            assetPrice: application.financial !== undefined && application.financial.assetPrice !== undefined ? application.financial.assetPrice : 0,
            cashDeposit: application.financial !== undefined && application.financial.cashDeposit !== undefined ? application.financial.cashDeposit : 0,
            tradeInAmount: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.amount ? application.financial.tradeIn.amount : 0,

            amountOutstanding: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.isTradingIn === true &&
                application.financial.tradeIn.finance !== undefined && application.financial.tradeIn.finance.financed === true && application.financial.tradeIn.finance.financier !== undefined && application.financial.tradeIn.finance.financier.amountOutstanding !== undefined ? application.financial.tradeIn.finance.financier.amountOutstanding : 0,

            ppsrSearchFee: 0,
            applicationFee: application.financial !== undefined && application.financial.lenderFee !== undefined ? application.financial.lenderFee : 0,
            creditSearchFee: 0,
            registrationFee: 0,
            stampDuty: 0,
            lenderRiskFee: application.financial !== undefined && application.financial.lenderRiskFee !== undefined ? application.financial.lenderRiskFee : 0,
            lenderEquilisationFee: application.financial !== undefined && application.financial.lenderEqualisationFee !== undefined ? application.financial.lenderEqualisationFee : 0,
            lenderPrivateSaleAdminFee: application.financial !== undefined && application.financial.lenderPrivateSaleAdminFee !== undefined ? application.financial.lenderPrivateSaleAdminFee : 0,
            referrer: "",
            date: getCurrentDate(),
            "sendEmail": false
        }*/
        setIndividualDetails((prevState) => {
            return {
                ...prevState,
                lender: application.lenderId !== undefined ? application.lenderId : 0,
                netFinanceAmount: application.loan !== undefined && application.loan.totalFinancedAmountExcludingInsurances !== undefined ? application.loan.totalFinancedAmountExcludingInsurances : 0,
                assetPrice: application.financial !== undefined && application.financial.assetPrice !== undefined ? application.financial.assetPrice : 0,
                cashDeposit: application.financial !== undefined && application.financial.cashDeposit !== undefined ? application.financial.cashDeposit : 0,
                tradeInAmount: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.amount ? application.financial.tradeIn.amount : 0,

                amountOutstanding: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.isTradingIn === true &&
                    application.financial.tradeIn.finance !== undefined && application.financial.tradeIn.finance.financed === true && application.financial.tradeIn.finance.financier !== undefined && application.financial.tradeIn.finance.financier.amountOutstanding !== undefined ? application.financial.tradeIn.finance.financier.amountOutstanding : 0,

                ppsrSearchFee: 0,
                applicationFee: application.financial !== undefined && application.financial.lenderFee !== undefined ? application.financial.lenderFee : 0,
                creditSearchFee: 0,
                registrationFee: 0,
                stampDuty: 0,
                lenderRiskFee: application.financial !== undefined && application.financial.lenderRiskFee !== undefined ? application.financial.lenderRiskFee : 0,
                lenderEquilisationFee: application.financial !== undefined && application.financial.lenderEqualisationFee !== undefined ? application.financial.lenderEqualisationFee : 0,
                lenderPrivateSaleAdminFee: application.financial !== undefined && application.financial.lenderPrivateSaleAdminFee !== undefined ? application.financial.lenderPrivateSaleAdminFee : 0,
                referrer: "",
                date: getCurrentDate(),
                "sendEmail": false

            }

        });
    }, [application])


    useEffect(() => {
        var applicantCurrentAddress = applicant.address !== undefined ? applicant.address : {};

        var firstName = applicant.firstName !== undefined ? applicant.firstName : "";
        var surname = applicant.surname !== undefined ? applicant.surname : "";
        var middleName = applicant.middleName !== undefined ? applicant.middleName : "";
        var fullName = firstName + " " + middleName + " " + surname;

        setIndividualDetails((prevState) => {
            return {
                ...prevState,
                name: fullName,
                contactNumber: applicant.contactNumber !== undefined ? applicant.contactNumber : "",
                email: applicant.email !== undefined ? applicant.email : "",
                address: applicantCurrentAddress.fullAddress !== undefined ? applicantCurrentAddress.fullAddress : "",
            }
        });

    }, [applicant])

    const handler = (name, value) => {
        switch (name) {
            case "lender":
                console.log("value", value);
                fetchSingleLender(value);
                break;

            case "selectApplicant":
                var findApplicant = applicants.find(applicant => applicant.id === value);
                console.log("findApplicant:::: ", findApplicant)
                if (findApplicant !== undefined) {
                    setApplicant({
                        ...applicant,
                        firstName: findApplicant.firstName !== undefined ? findApplicant.firstName : "",
                        middleName: findApplicant.middleName !== undefined ? findApplicant.middleName : "",
                        surname: findApplicant.surname !== undefined ? findApplicant.surname : "",
                        contactNumber: findApplicant.contactNumber !== undefined ? findApplicant.contactNumber : "",
                        email: findApplicant.email !== undefined ? findApplicant.email : "",
                        address: findApplicant.address !== undefined ? findApplicant.address : "",
                    });
                }
                else { setApplicant({ ...applicant, firstName: "", middleName: "", surname: "", contactNumber: "", email: "", address: {} }); }
                break;

        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={individualDetails}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validateOnBlur={false}
                        validationSchema={CreditProposalValidationSchema}

                        onSubmit={async (values) => {
                            var valuesClone = JSON.parse(JSON.stringify(values));
                            //MAKE AN API CALL 
                            setProcessing(true);
                            var sendingEmail = values["sendEmail"];
                            var selectedLender = lenders.find(singleLender => singleLender.value == values["lender"]);
                            valuesClone["lender"] = selectedLender !== undefined && selectedLender.label !== undefined ? selectedLender.label : "";  //FIND THE SELECTED LENDER FROM LENDERS STATE AND PROVIDE THE NAME

                            var response = await performApplicationAdditionalActions(token, "creditProposal", applicationId, valuesClone);

                            var responseResult = response.data !== undefined ? response.data : {};
                            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                                if (sendingEmail) {
                                    var message = responseResult["message"] !== undefined ? responseResult["message"] : "";
                                    Notifications("success", message);
                                    callback("checkCreditProposalExist");
                                }
                                else {
                                    //DOWNLOAD FILE
                                    var pdfBytes = responseResult["pdfBytes"];
                                    //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                                    const bytes = new Uint8Array(Object.values(pdfBytes));
                                    const blob = new Blob([bytes], { type: "application/pdf" });
                                    const docUrl = URL.createObjectURL(blob);

                                    let alink = document.createElement('a');
                                    alink.href = docUrl;
                                    //alink.download = 'Credit Proposal.pdf';
                                    alink.target = '_blank';
                                    alink.click();
                                    Notifications("success", responseResult["message"] || "Downloaded Successfully. ")
                                    callback("checkCreditProposalExist");
                                }
                            }
                            else {
                                Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error")
                            }
                            fetchComments();        //REFERESH LOGS
                            setProcessing(false);
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Select Applicant</label>
                                        <SelectField
                                            fieldName="selectApplicant"
                                            defaultSelectedOption={{ value: "", label: "" }}
                                            options={listApplicants}
                                            setFieldValue={() => { }}
                                            callback={handler}

                                        />
                                    </div>

                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Name</label>
                                        <Field name="name" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Contact #</label>
                                        <Field name="contactNumber" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Email</label>
                                        <Field name="email" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Address</label>
                                        <Field name="address" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Lender</label>
                                        <Field name="lender">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="lender"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={lenders}
                                                    setFieldValue={setFieldValue}
                                                    callback={handler}

                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Net Finance Amount</label>
                                        <Field name="netFinanceAmount" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Asset Price</label>
                                        <Field name="assetPrice" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Cash Deposit</label>
                                        <Field name="cashDeposit" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">TradeIn Amount</label>
                                        <Field name="tradeInAmount" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Amount Outstanding</label>
                                        <Field name="amountOutstanding" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">PPSR Search Fee</label>
                                        <Field name="ppsrSearchFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Application Fee</label>
                                        <Field name="applicationFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Credit Search Fee</label>
                                        <Field name="creditSearchFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Security/Registration Fee</label>
                                        <Field name="registrationFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Stamp Duty</label>
                                        <Field name="stampDuty" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Lender Risk Fee</label>
                                        <Field name="lenderRiskFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Lender Equilisation Fee</label>
                                        <Field name="lenderEquilisationFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Lender Private Sale Admin Fee</label>
                                        <Field name="lenderPrivateSaleAdminFee" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referrer</label>
                                        <Field name="referrer" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Date</label>
                                        <Field name="date" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginRight: "10px" }}>
                                                <Field name="sendEmail">
                                                    {({ field }) => (
                                                        <CheckBox
                                                            name="sendEmail"
                                                            defaultChecked={field.value}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div>Send Email</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-5"></div>

                                <div className="col-xl-12">
                                    {<FormSubmissionButton isActive={true} label={values.sendEmail ? "Send Email" : "Download"} />}
                                </div>
                            </Form>

                        )}

                    </Formik>

                </div>
            </div>


            <div className='d-flex row'>
                <h3>Applicant Credit Proposal Notification Log</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                </div>
            </div>

            {
                processing ? <LoadingScreen /> : ""
            }


        </>
    )
}

export default CreditProposal;