import SingleContact from './SingleContact';

const ListContacts = ({ contacts, callback }) => {
    return (
        <>
            <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                <thead>
                    <tr className="fw-bolder fs-6 bg-light">
                        <th>Name</th>
                        <th>Role</th>
                        <th>Contact #</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className="fw-bold  fw-bold text-gray-600">
                    {
                        Array.isArray(contacts) ?
                        contacts.map(contact => (
                                <SingleContact key={contact.id} contact={contact} callback={callback} />
                            ))
                            : <tr></tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default ListContacts;