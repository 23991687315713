import axios from './axios';


const getApplicantAccessTokensByApplicationId = async(token, applicationId) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.get(`applicantAccessToken/applicantTokensByApplicationId/${applicationId}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

const createApplicantAccessToken = async(token , payload) => {
    try{
        console.log("TOKEN: " + token) ;
        console.log(payload);
        const response = await axios.post(`applicantAccessToken`,  payload , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : response.data.message }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
         }
    }
}

const deleteApplicantAccessTokens = async(token , accessTokenId) => {
    try{
        const response = await axios.delete(`applicantAccessToken/${accessTokenId}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : response.data.message }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
         }
    }
}

export{ createApplicantAccessToken, getApplicantAccessTokensByApplicationId, deleteApplicantAccessTokens}