import { IconButton } from "../HtmlComponents";

const InsuranceCompaniesList = ({ heading, type, companies, callback , updateInsuranceCompanyAllowed , deleteInsuranceCompanyAllowed }) => {

    return (
        <>
            <div className="col-sm-12 col-md-12">
                <div className="page-title mt-6 d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{heading}</h1>
                </div>
                <div className="card mt-3" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th className="">Name</th>
                                    <th className="">BDM</th>
                                    <th className="">Contact #</th>
                                    <th className="">Email</th>
                                    <th className="">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="fw-bold text-gray-600">
                                {
                                    companies && Array.isArray(companies) && companies.length > 0 ?
                                        companies.map((company, index) => {
                                            if (company.type == type) {
                                                return (<tr key={index}>
                                                    <td className="text-gray-800 text-hover-primary mb-1">{company.name}</td>
                                                    <td>{company.repName}</td>
                                                    <td>{company.contactNumber}</td>
                                                    <td>{company.email}</td>
                                                    <td>
                                                        {updateInsuranceCompanyAllowed ? <IconButton iconType="edit" tooltip="Edit" buttonClassName="" buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "#009ef7" }} buttonOnClick={() => { callback("updateInsuranceCompany" , {id: company.id}); }} uniqueId="edit" /> : "" }
                                                        <IconButton iconType="files" tooltip="Files" buttonClassName="" buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "#009ef7" }} buttonOnClick={() => { callback("files" , {id: company.id}); }} uniqueId="editFiles" /> 
                                                        { deleteInsuranceCompanyAllowed ? <IconButton iconType="delete" tooltip="Delete" buttonClassName="" buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "red" }} buttonOnClick={() => {callback("deleteInsuranceCompany" , {id: company.id}); }} uniqueId="delete" /> : "" }
                                                    </td>
                                                </tr>)
                                            }
                                        }) : <tr>
                                            <td colSpan={5}> <p> No Company found, Please create one</p> </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InsuranceCompaniesList;