import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox } from '../../HtmlComponents';
import { formatDate, getCurrentDate, calculateMonthlyAmount } from '../../../helpers';
import Notifications from "../../global/Notifications";
import { performApplicationAdditionalActions, getReferrer } from "../../../api";
import { useToken, useFullName } from '../../../hooks/useAuth';
import LoadingScreen from '../../global/LoadingScreen';
import PreliminaryAssessmentSchema from '../schema/PreliminaryAssessmentSchema.json';

const PreliminaryAssessment = ({ application, applicants, applicant, applicationId, callback }) => {
    const [preliminaryAssessment, setPreliminaryAssessment] = useState(PreliminaryAssessmentSchema);
    const [processing, setProcessing] = useState(false);

    const formRef = useRef();
    const token = useToken();
    const loggedInUserFullName = useFullName();

    //TOTAL EXPENSES FIELD
    const TotalExpense = (props) => {

        const {
            values: { capacity: { expenses: { totalFixedPayment, household, onGoingRent, childSupport, proposedNewCommitment } } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = totalFixedPayment !== undefined && (!isNaN(parseFloat(totalFixedPayment))) ? parseFloat(totalFixedPayment) : 0;
            totalAmount += household !== undefined && (!isNaN(parseFloat(household))) ? parseFloat(household) : 0;
            totalAmount += onGoingRent !== undefined && (!isNaN(parseFloat(onGoingRent))) ? parseFloat(onGoingRent) : 0;
            totalAmount += childSupport !== undefined && (!isNaN(parseFloat(childSupport))) ? parseFloat(childSupport) : 0;
            totalAmount += proposedNewCommitment !== undefined && (!isNaN(parseFloat(proposedNewCommitment))) ? parseFloat(proposedNewCommitment) : 0;

            setFieldValue(props.name, totalAmount);
        }, [totalFixedPayment, household, onGoingRent, onGoingRent, childSupport, proposedNewCommitment,
            touched.totalFixedPayment, touched.household, touched.onGoingRent, touched.childSupport, touched.proposedNewCommitment, setFieldValue, props.name]);
        return (
            <>
                <InputField
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };

    //TOTAL INCOEM FIELD
    const TotalIncome = (props) => {
        const {
            values: { capacity: { income: { netIncomeApplicant1, netIncomeApplicant2, familyAllowance, childSupport, otherIncome } } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = netIncomeApplicant1 !== undefined && (!isNaN(parseFloat(netIncomeApplicant1))) ? parseFloat(netIncomeApplicant1) : 0;
            totalAmount += netIncomeApplicant2 !== undefined && (!isNaN(parseFloat(netIncomeApplicant2))) ? parseFloat(netIncomeApplicant2) : 0;
            totalAmount += familyAllowance !== undefined && (!isNaN(parseFloat(familyAllowance))) ? parseFloat(familyAllowance) : 0;
            totalAmount += childSupport !== undefined && (!isNaN(parseFloat(childSupport))) ? parseFloat(childSupport) : 0;
            totalAmount += otherIncome !== undefined && (!isNaN(parseFloat(otherIncome))) ? parseFloat(otherIncome) : 0;

            setFieldValue(props.name, totalAmount);
        }, [netIncomeApplicant1, netIncomeApplicant2, familyAllowance, childSupport, otherIncome,
            touched.netIncomeApplicant1, touched.netIncomeApplicant2, touched.familyAllowance, touched.childSupport, touched.otherIncome, setFieldValue, props.name]);
        return (
            <>
                <InputField
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };


    //SURPLUS | DEFICIENY FIELD
    const SurplusDeficiencyField = (props) => {
        const {
            values: { capacity: { income: { totalIncome }, expenses: { totalMonthlyExpenses } } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = totalIncome !== undefined && (!isNaN(parseFloat(totalIncome))) ? parseFloat(totalIncome) : 0;
            totalAmount -= totalMonthlyExpenses !== undefined && (!isNaN(parseFloat(totalMonthlyExpenses))) ? parseFloat(totalMonthlyExpenses) : 0;

            setFieldValue(props.name, totalAmount);
        }, [totalIncome, totalMonthlyExpenses,
            touched.totalIncome, touched.totalMonthlyExpenses, setFieldValue, props.name]);
        return (
            <>
                <InputField
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };


    const updateInitialValues = async () => {
        //UPDAET INITIAL VALUES

        var applicant1 = applicants[0] ? applicants[0] : {}
        var applicant2 = applicants[1] ? applicants[1] : {}

        var personalDetailsApplicant1 = applicant1["personalDetail"] !== undefined ? applicant1["personalDetail"] : {}
        var personalDetailsApplicant2 = applicant2["personalDetail"] !== undefined ? applicant2["personalDetail"] : {}

        var applicant1FirstName = personalDetailsApplicant1["firstName"] !== undefined ? personalDetailsApplicant1["firstName"] : "";
        var applicant1Surname = personalDetailsApplicant1["surname"] !== undefined ? personalDetailsApplicant1["surname"] : "";
        var applicant1CreatedAt = applicant1["createdAt"] !== undefined ? formatDate(applicant1["createdAt"], "unix", "DD/MM/YYYY") : "";

        var applicant2FirstName = personalDetailsApplicant2["firstName"] !== undefined ? personalDetailsApplicant2["firstName"] : "";
        var applicant2Surname = personalDetailsApplicant2["surname"] !== undefined ? personalDetailsApplicant2["surname"] : "";
        var applicant2CreatedAt = applicant2["createdAt"] !== undefined ? formatDate(applicant2["createdAt"], "unix", "DD/MM/YYYY") : "";


        var initialValues = PreliminaryAssessmentSchema;

        initialValues["dateIn"] = dateIn;

        initialValues["applicants"][0]["surname"] = applicant1Surname;
        initialValues["applicants"][0]["givenName"] = applicant1FirstName;
        initialValues["applicants"][0]["interviewDate"] = applicant1CreatedAt;

        initialValues["applicants"][1]["surname"] = applicant2Surname;
        initialValues["applicants"][1]["givenName"] = applicant2FirstName;
        initialValues["applicants"][1]["interviewDate"] = applicant2CreatedAt;

        //OBJECTIVES
        var objectives = {
            "description": application.purpose !== undefined ? application.purpose : "",
            "amountFinanced": application.loan !== undefined && application.loan.totalFinancedAmountIncludingInsurances !== undefined ? application.loan.totalFinancedAmountIncludingInsurances : 0,
            "repayment":  application.repayments !== undefined && application.repayments.includingInsurance !== undefined && application.repayments.includingInsurance.monthly !== undefined ? application.repayments.includingInsurance.monthly : 0,
            "repaymentFrequency": {
                "weekly": false,
                "fortnightly": false,
                "monthly": true
            },
            "rate": application.loan !== undefined && application.loan.interestRate !== undefined ? application.loan.interestRate : 0,
            "term": application.loan !== undefined && application.loan.loanTerm !== undefined ? application.loan.loanTerm : 0,
            "balloon": application.loan !== undefined && application.loan.balloon !== undefined && application.loan.balloon.balloonAmount !== undefined ? application.loan.balloon.balloonAmount : 0
        }

        initialValues["objectives"] = objectives;

        //EXPENSES
        var applicant1Expenses = applicant1["expenses"] !== undefined ? applicant1["expenses"] : []
        var applicant2Expenses = applicant2["expenses"] !== undefined ? applicant2["expenses"] : []

        var childSupport = 0;
        var otherExpenses = 0;


        var applicant1ExpensesPromises = applicant1Expenses.map(async (singleExpense) => {
            var type = singleExpense["type"];
            var amount = singleExpense["amount"];
            var frequency = singleExpense["frequency"];

            if (type == "childSupport") { childSupport = parseFloat(await calculateMonthlyAmount(frequency, amount)) + parseFloat(childSupport); }
            else { otherExpenses = parseFloat(await calculateMonthlyAmount(frequency, amount)) + parseFloat(otherExpenses); }
            return "";
        })

        var applicant2ExpensesPromises = applicant2Expenses.map(async (singleExpense) => {
            var type = singleExpense["type"];
            var amount = singleExpense["amount"];
            var frequency = singleExpense["frequency"];

            if (type == "childSupport") { childSupport = parseFloat(await calculateMonthlyAmount(frequency, amount)) + parseFloat(childSupport); }
            else { otherExpenses = parseFloat(await calculateMonthlyAmount(frequency, amount)) + parseFloat(otherExpenses); }
            return "";
        })
        await Promise.all(applicant1ExpensesPromises, applicant2ExpensesPromises).then(() => { })


        //RENT  --  FIRST CHECK IF PAYING RENT OTHERWISE CHECK FOR MORTGAGE
        var totalRentAmount = 0;

        //APPLICANT 1 RENT AMOUNT 
        var applicant1CurrentAddressObj = personalDetailsApplicant1.residentialHistory !== undefined && personalDetailsApplicant1.residentialHistory.current !== undefined
            ? personalDetailsApplicant1.residentialHistory.current : {};

        var applicant1RresidentialStatus = applicant1CurrentAddressObj.status !== undefined ? applicant1CurrentAddressObj.status : "";
        if (["rentingAgent", "rentingPrivate", "boardingOther", "boardingLivingWithFamily", "caravanPark", "hotelOrHostel"].includes(applicant1RresidentialStatus)) {
            let rentObj = applicant1CurrentAddressObj.rent !== undefined ? applicant1CurrentAddressObj.rent : {};
            totalRentAmount = rentObj.applicantShareOfMonthlyRent !== undefined && !isNaN(parseFloat(rentObj.applicantShareOfMonthlyRent)) ?
                parseFloat(rentObj.applicantShareOfMonthlyRent) : 0;
        }

        //APPLICANT 2 RENT AMOUNT
        var applicant2CurrentAddressObj = personalDetailsApplicant2.residentialHistory !== undefined && personalDetailsApplicant2.residentialHistory.current !== undefined
            ? personalDetailsApplicant2.residentialHistory.current : {};

        var applicant2RresidentialStatus = applicant2CurrentAddressObj.status !== undefined ? applicant2CurrentAddressObj.status : "";
        if (["rentingAgent", "rentingPrivate", "boardingOther", "boardingLivingWithFamily", "caravanPark", "hotelOrHostel"].includes(applicant2RresidentialStatus)) {
            let rentObj = applicant2CurrentAddressObj.rent !== undefined ? applicant2CurrentAddressObj.rent : {};
            totalRentAmount = rentObj.applicantShareOfMonthlyRent !== undefined && !isNaN(parseFloat(rentObj.applicantShareOfMonthlyRent)) ?
                parseFloat(rentObj.applicantShareOfMonthlyRent) + parseFloat(totalRentAmount) : totalRentAmount;
        }

        //FIXED PAYMENTS - CREDIT CARDS ETC 
        var totalFixedExpense = 0;
        //APPLICANT 1 DEBTS
        var applicant1Debts = applicant1.debts !== undefined ? applicant1.debts : [];

        var applicant1DebtsPromises = applicant1Debts.map(async (singleDebt) => {
            if (["creditCard", "storeCard", "chargeCard", "car" , "motorBike" , "boat" , "caravan" , "jetSki" , "truck" , "investmentProperties" , "hirePurchase" , "buyNowPayLater" , "other"].includes(singleDebt.debtType)) {
                var repaymentAmount = singleDebt.repaymentAmount !== undefined ? singleDebt.repaymentAmount : 0;
                var frequency = singleDebt.repaymentFrequency !== undefined ? singleDebt.repaymentFrequency : "";
                totalFixedExpense = parseFloat(await calculateMonthlyAmount(frequency, repaymentAmount)) + parseFloat(totalFixedExpense);
            }
            else if(["house", "land"].includes(singleDebt.debtType)){
                let repaymentAmount = singleDebt.applicantShareOfTotalMonthlyRepayment !== undefined ? singleDebt.applicantShareOfTotalMonthlyRepayment : 0;
                let frequency = singleDebt.repaymentFrequency !== undefined ? singleDebt.repaymentFrequency : "";
                totalFixedExpense = parseFloat(await calculateMonthlyAmount(frequency, repaymentAmount)) + parseFloat(totalFixedExpense);
            }
            else if(["mortgage"].includes(singleDebt.debtType)){            
                let applicantShareOfTotalMonthlyRepayment = singleDebt.applicantShareOfTotalMonthlyRepayment !== undefined ? singleDebt.applicantShareOfTotalMonthlyRepayment: 0;
                totalRentAmount = parseFloat(applicantShareOfTotalMonthlyRepayment) + parseFloat(totalRentAmount);
            }
            return "";
        })

        var applicant2Debts = applicant2.debts !== undefined ? applicant2.debts : [];

        var applicant2DebtsPromises = applicant2Debts.map(async (singleDebt) => {
            if (["creditCard", "storeCard", "chargeCard", "car" , "motorBike" , "boat" , "caravan" , "jetSki" , "truck" , "investmentProperties" , "hirePurchase" , "buyNowPayLater" , "other"].includes(singleDebt.debtType)) {
                var repaymentAmount = singleDebt.repaymentAmount !== undefined ? singleDebt.repaymentAmount : 0;
                var frequency = singleDebt.repaymentFrequency !== undefined ? singleDebt.repaymentFrequency : "";
                totalFixedExpense = parseFloat(await calculateMonthlyAmount(frequency, repaymentAmount)) + parseFloat(totalFixedExpense);
            }
            else if(["house", "land"].includes(singleDebt.debtType)){
                let repaymentAmount = singleDebt.applicantShareOfTotalMonthlyRepayment !== undefined ? singleDebt.applicantShareOfTotalMonthlyRepayment : 0;
                let frequency = singleDebt.repaymentFrequency !== undefined ? singleDebt.repaymentFrequency : "";
                totalFixedExpense = parseFloat(await calculateMonthlyAmount(frequency, repaymentAmount)) + parseFloat(totalFixedExpense);
            }
            else if(["mortgage"].includes(singleDebt.debtType)){            
                let applicantShareOfTotalMonthlyRepayment = singleDebt.applicantShareOfTotalMonthlyRepayment !== undefined ? singleDebt.applicantShareOfTotalMonthlyRepayment: 0;
                totalRentAmount = parseFloat(applicantShareOfTotalMonthlyRepayment) + parseFloat(totalRentAmount);
            }
            return "";
        })

        await Promise.all(applicant1DebtsPromises, applicant2DebtsPromises).then(() => { })



        //INCOME 
        var familyAllowanceIncome = 0;   //familyAllowance
        var childSupportIncome = 0;         //court ordered childs support
        var otherIncome = 0;               //other
        var netIncomeApplicant1 = 0;
        var netIncomeApplicant2 = 0;


        //APPLICANT 1 INCOME
        var applicant1Income = applicant1.income !== undefined ? applicant1.income : {}
        var applicant1PrimaryIncome = applicant1Income.primaryIncome !== undefined ? applicant1Income.primaryIncome : []
        var applicant1SecondaryIncome = applicant1Income.secondaryIncome !== undefined ? applicant1Income.secondaryIncome : [];
        var applicant1CurrentIncome = applicant1PrimaryIncome.concat(applicant1SecondaryIncome);

        var applicant1IncoemPromises = applicant1CurrentIncome.map(async (singleIncome) => {
            console.log("single income", singleIncome);
            var incomeType = singleIncome.incomeType !== undefined ? singleIncome.incomeType : "";
            var frequency = singleIncome.frequencey !== undefined ? singleIncome.frequencey : "";
            var netIncome = singleIncome.netIncome !== undefined ? singleIncome.netIncome : 0;

            if (["governmentBenefits"].includes(incomeType)) { familyAllowanceIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(familyAllowanceIncome); }
            else if (["courtOrderedChildSupport"].includes(incomeType)) { childSupportIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(childSupportIncome); }
            else if (["other"].includes(incomeType)) { otherIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(otherIncome); }
            else { netIncomeApplicant1 = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(netIncomeApplicant1); }
        })

        //APPLICANT 2 INCOME
        var applicant2Income = applicant2.income !== undefined ? applicant2.income : {}
        var applicant2PrimaryIncome = applicant2Income.primaryIncome !== undefined ? applicant2Income.primaryIncome : []
        var applicant2SecondaryIncome = applicant2Income.secondaryIncome !== undefined ? applicant2Income.secondaryIncome : [];
        var applicant2CurrentIncome = applicant2PrimaryIncome.concat(applicant2SecondaryIncome);

        var applicant2IncoemPromises = applicant2CurrentIncome.map(async (singleIncome) => {
            console.log("single income", singleIncome);
            var incomeType = singleIncome.incomeType !== undefined ? singleIncome.incomeType : "";
            var frequency = singleIncome.frequencey !== undefined ? singleIncome.frequencey : "";
            var netIncome = singleIncome.netIncome !== undefined ? singleIncome.netIncome : 0;

            if (["governmentBenefits"].includes(incomeType)) { familyAllowanceIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(familyAllowanceIncome); }
            else if (["courtOrderedChildSupport"].includes(incomeType)) { childSupportIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(childSupportIncome); }
            else if (["other"].includes(incomeType)) { otherIncome = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(otherIncome); }
            else { netIncomeApplicant2 = parseFloat(await calculateMonthlyAmount(frequency, netIncome)) + parseFloat(netIncomeApplicant2); }
        })


        await Promise.all(applicant1IncoemPromises, applicant2IncoemPromises).then((data) => {

        })

        var capacity = {
            "income": {
                "netIncomeApplicant1": netIncomeApplicant1.toFixed(2),
                "netIncomeApplicant2": netIncomeApplicant2.toFixed(2),
                "familyAllowance": familyAllowanceIncome.toFixed(2),
                "childSupport": childSupportIncome.toFixed(2),
                "otherIncome": otherIncome.toFixed(2),
                "totalIncome": 0
            },
            "expenses": {
                "totalFixedPayment": totalFixedExpense.toFixed(2),
                "household": otherExpenses.toFixed(2),
                "onGoingRent": totalRentAmount.toFixed(2),
                "childSupport": childSupport.toFixed(2),
                "proposedNewCommitment":  application.repayments !== undefined && application.repayments.includingInsurance !== undefined && application.repayments.includingInsurance.monthly !== undefined ? application.repayments.includingInsurance.monthly : 0,
                "totalMonthlyExpenses": ""
            },
            "surplusDeficiency": 0
        }
        initialValues["capacity"] = capacity;

        //NALYST DETAILS
        var dateIn = application["createdAt"] !== undefined && application["createdAt"] !== 0 ? formatDate(application["createdAt"], "unix", "DD/MM/YYYY") : "";

        var analyst = {
            "name": loggedInUserFullName,
            "date": dateIn
        };

        initialValues["analyst"] = analyst;

        console.log("INITIALVALUES:::::::::::::::::::::: ", initialValues);
        setPreliminaryAssessment({ ...initialValues });

    }

    useEffect(() => {
        updateInitialValues();
    }, [application])

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={preliminaryAssessment}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validateOnBlur={false}

                        onSubmit={async (values) => {
                            console.log("VALUES READY FOR SUBMISSION S:::::", values);

                            //MAKE AN API CALL 
                            setProcessing(true);
                            var response = await performApplicationAdditionalActions(token, "preliminaryAssessment", applicationId, values);
                            var responseResult = response.data !== undefined ? response.data : {};
                            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {

                                //DOWNLOAD FILE
                                var pdfBytes = responseResult["pdfBytes"];
                                //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                                const bytes = new Uint8Array(Object.values(pdfBytes));
                                const blob = new Blob([bytes], { type: "application/pdf" });
                                const docUrl = URL.createObjectURL(blob);

                                let alink = document.createElement('a');
                                alink.href = docUrl;
                                alink.download = 'Preliminary.pdf';
                                alink.click();
                                Notifications("success", responseResult["message"] || "Downloaded Successfully. ")
                                callback("closeDrawer");

                            }
                            else {
                                Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error")
                            }
                            setProcessing(false);
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table style={{  width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr style={{ background: "lightgray" }}>
                                                    <th colSpan={11} style={{ textAlign: "center" }}><h3>Client Details</h3></th>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Surname</td>
                                                    <td colSpan={6}>Given Name</td>
                                                    <td colSpan={2}>Interview Date</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><Field name="applicants[0].surname" className="form-control formField2" /></td>
                                                    <td colSpan={6}><Field name="applicants[0].givenName" className="form-control formField2" /></td>
                                                    <td colSpan={2}><Field name="applicants[0].interviewDate" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><Field name="applicants[1].surname" className="form-control formField2" /></td>
                                                    <td colSpan={6}><Field name="applicants[1].givenName" className="form-control formField2" /></td>
                                                    <td colSpan={2}><Field name="applicants[1].interviewDate" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={9}><p>Is this assessment being conducted within 90 days prior to "credit assistance" being provided ?</p></td>
                                                    <td colSpan={2}>
                                                        <Field name="assessmentConductedWith90Days">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="assessmentConductedWith90Days"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        { /**************************OBJECTIVES TABLE*************************** */}
                                        <table style={{  width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr style={{ background: "lightgray" }}>
                                                    <th colSpan={11} style={{ textAlign: "center" }}><h3>Objectives</h3></th>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Client Objectives</td>
                                                    <td colSpan={9}><Field name="objectives.description" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><p>Amount Financed</p></td>
                                                    <td colSpan={2}><p>Repayment</p></td>
                                                    <td colSpan={3}><p>Repayment Frequency</p></td>
                                                    <td colSpan={1}><p>Rate</p></td>
                                                    <td colSpan={1}><p>Term(Mths)</p></td>
                                                    <td colSpan={2}><p> Balloon</p></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}><Field name="objectives.amountFinanced" className="form-control formField2" /></td>
                                                    <td colSpan={2}><Field name="objectives.repayment" className="form-control formField2" /></td>
                                                    <td colSpan={3}>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                            <div>
                                                                <span>wk</span>
                                                                <Field name="objectives.repaymentFrequency.weekly">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            fieldName="objectives.repaymentFrequency.weekly"
                                                                            setFieldValue={setFieldValue}
                                                                            defaultChecked={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div>
                                                                <span>F/night</span>
                                                                <Field name="objectives.repaymentFrequency.fortnightly">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            fieldName="objectives.repaymentFrequency.fortnightly"
                                                                            setFieldValue={setFieldValue}
                                                                            defaultChecked={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div>
                                                                <span>Mth</span>
                                                                <Field name="objectives.repaymentFrequency.monthly">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            fieldName="objectives.repaymentFrequency.monthly"
                                                                            setFieldValue={setFieldValue}
                                                                            defaultChecked={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>

                                                        </div>





                                                    </td>
                                                    <td colSpan={1}><Field name="objectives.rate" className="form-control formField2" /></td>
                                                    <td colSpan={1}><Field name="objectives.term" className="form-control formField2" /></td>
                                                    <td colSpan={2}><Field name="objectives.balloon" className="form-control formField2" /></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        { /**************************CAPACITY ASSESSMENT *************************** */}
                                        <table style={{  width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr style={{ background: "lightgray" }}>
                                                    <th colSpan={10} style={{ textAlign: "center" }}><h3>Capacity Assessment</h3></th>
                                                </tr>
                                                <tr><td colSpan={10}><p>(Monthly Income/ Expenditure Details Supplied by the Client)</p></td> </tr>
                                                <tr>
                                                    <td colSpan={5} style={{ border: "0" }}>
                                                        <table style={{ width: "100%", border: "0px" }}>
                                                            <tbody>
                                                                <tr><td colSpan={5} style={{ textAlign: "center", fontWeight: "bold" }}>Monthly Expenditure</td></tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Total Fixed Payments <span style={{ fontSize: "9px" }}>(loans, credit cards etc.)</span></p></td>
                                                                    <td colSpan={2}> <Field name="capacity.expenses.totalFixedPayment" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Household + Other Living Expenses</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.expenses.household" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Ongoing Rent/ Mortgage or equivalent</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.expenses.onGoingRent" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Child Support</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.expenses.childSupport" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Proposed New commitment</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.expenses.proposedNewCommitment" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Total Monthly Expenses</p></td>
                                                                    <td colSpan={2}>
                                                                        <TotalExpense name="capacity.expenses.totalMonthlyExpenses" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td colSpan={5} style={{ border: "0" }}>
                                                        <table style={{ width: "100%", border: "0px" }}>
                                                            <tbody>
                                                                <tr><td colSpan={5} style={{ textAlign: "center", fontWeight: "bold" }}>Monthly Income</td> </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Net Income Applicant 1</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.income.netIncomeApplicant1" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Net Income Applicant 2</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.income.netIncomeApplicant2" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Family Allowance</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.income.familyAllowance" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Child Support (Court Ordered/ CSA)</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.income.childSupport" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Other Income</p></td>
                                                                    <td colSpan={2}> <Field name="capacity.income.otherIncome" className="form-control formField2" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}><p>Total Income</p></td>
                                                                    <td colSpan={2}>
                                                                        <TotalIncome name="capacity.income.totalIncome" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}> <p>Surplus / Deficiency</p></td>
                                                    <td colSpan={7}> <SurplusDeficiencyField name="capacity.surplusDeficiency" /></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/*************************CAPACITY TO PAY AND VERIFICATION **************** */}
                                        <table style={{  width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr style={{ background: "lightgray" }}>
                                                    <th colSpan={11} style={{ textAlign: "center" }}><h3>Capacity to Pay & Verification</h3></th>
                                                </tr>

                                                <tr>
                                                    <td colSpan={1}>Q1</td>
                                                    <td colSpan={7}><p>Given the client's objectives, is the proposed facility "NOT unsuitable " ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q1">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q1"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q2</td>
                                                    <td colSpan={7}><p>Is a surplus evident based on the information supplied by the client ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q2">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q2"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q3</td>
                                                    <td colSpan={7}><p>Are there reasonable prospects for the client's level of income to continue ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q3">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q3"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1} rowSpan={5}>Q4</td>
                                                    <td colSpan={7}><p>a) Was a credit report requested?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q4a">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q4a"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><p>b) If a requested, was the credit report/s reconciled with disclosed commitments ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q4b">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q4b"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><p>c) Are there adverse recordings ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q4c">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q4c"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><p>d) If Yes at 4c, have the adverse recordings been reviewed and explained ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q4d">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q4d"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={10}>
                                                        <p>e) If No at 4b & 4c, please provide reason/s why:</p>
                                                        <Field name="questions.q4e" className="form-control formField2" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q5</td>
                                                    <td colSpan={7}><p>Taking into account the above information regarding the client's capacity and commitments, is the purposed facility"NOT" unsuitable ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q5">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q5"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q6</td>
                                                    <td colSpan={7}><p>Does the client/s fully understand all aspects of the proposed transaction and has the client/s confirmed that the proposed payments are within their financial capabilities to meet from normal income ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q6">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q6"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q7</td>
                                                    <td colSpan={7}><p>Is there any evidence to suggest that the client may have difficulty meeting their new commitments and face substantual hardship ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q7">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q7"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={1}>Q8</td>
                                                    <td colSpan={7}><p>Do you believe that the information provided by the applicants to be true ?</p></td>
                                                    <td colSpan={3}>
                                                        <Field name="questions.q8">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="questions.q8"
                                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={11}>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <p>What inquiries and verifications support this ?</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Income</label>
                                                                <Field name="questions.incomeDescription" className="form-control formField2" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Commitments</label>
                                                                <Field name="questions.commitmentsDescription" className="form-control formField2" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Assets</label>
                                                                <Field name="questions.assetsDescription" className="form-control formField2" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Other</label>
                                                                <Field name="questions.otherDescription" className="form-control formField2" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p style={{ fontSize: "10px" }}>
                                            NB: If any of the answers for Q1, Q2, Q3, Q5 and Q6 are "NO" then the application cannot progress further and this should immediately be discussed with the client. If all answers to Q1, Q2, Q3, Q5 and Q6 are "YES" then the application may be progressed to an appropriate credit provider to consideration.
                                        </p>

                                        { /**********************ANALYST SIGNATURE AND NAME ******************* */}
                                        <table style={{  width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr style={{ background: "lightgray" }}>
                                                    <th colSpan={11} style={{ textAlign: "center" }}><h3>Acknowledgement by Assessor</h3></th>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12}><p>I confirm that the information in the application was provided directly by the client/s and I have reached the conclusion in this assessment based upon the information supplied by the client/s.</p></td>
                                                </tr>
                                                <tr>
                                                    <td><p>Name</p></td>
                                                    <td><p>Signature</p></td>
                                                    <td><p>Date</p></td>
                                                </tr>
                                                <tr>
                                                    <td><Field name="analyst.name" className="form-control formField2" /></td>
                                                    <td></td>
                                                    <td><Field name="analyst.date" className="form-control formField2" /></td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>


                                </div>
                                <div className="separator separator-dashed my-5"></div>

                                <div className="col-xl-12">
                                    {<FormSubmissionButton isActive={true} />}
                                </div>
                            </Form>

                        )}

                    </Formik>

                </div>
            </div>

        {processing ? <LoadingScreen /> : ""}

        </>
    )

}

export default PreliminaryAssessment;