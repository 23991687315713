import axios from '../api/axios';


//ADD REFERRER GROUP MANAGER
const addReferrerGroupManager = async(token , referrerGroupManager) => {
    try{
        const response = await axios.post(`referrerGroupManager`,  referrerGroupManager , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : "Created Successfully", referrerGroupManager:response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data ||{}}
         }
    }
}


//UPDATE REFERRER GROUP MANAGER
const updateReferrerGroupManager = async(token, referrerGroupManagerId, referrerGroupManager) => {
    try{
        const response = await axios.put(`referrerGroupManager/${referrerGroupManagerId}`,  referrerGroupManager , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : "Updated Successfully", referrerGroupManager: response.data || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

//DELETE REFERRER GROUP MANAGER
const deleteReferrerGroupManager = async(token, referrerGroupManagerId) => {
    try{
        const response = await axios.delete(`referrerGroupManager/${referrerGroupManagerId}`, { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : "Deleted Successfully", referrerGroupManager: response.data || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

//GET SINGLE REFERRER GROUP MANAGER
const getReferrerGroupManager = async(token, referrerGroupManagerId) => {
    try{
        const response = await axios.get(`referrerGroupManager/${referrerGroupManagerId}` , { headers: { Authorization: "Bearer " + token } });
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

//GET LIST OF REFERRER GROUP MANAGERS
const getReferrerGroupManagers = async(token,) => {
    try{
        console.log("   endpoint ----------------  ", `users`);
        console.log("   token ----------------  ", token);

        const response = await axios.get(`referrerGroupManager`, { headers: { Authorization: "Bearer " + token , "Content-Type" : "application/json"} });
        return { error: 0, httpCode: response.status, message: "",  data: response.data ? response.data : {}};

    }
    catch(e){
        if (e.response) {
            return {"error" : 1 ,  httpCode:e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data , data: {}}
         }
    }
}



export{addReferrerGroupManager, updateReferrerGroupManager, getReferrerGroupManager, getReferrerGroupManagers , deleteReferrerGroupManager}