import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import CreditRepListItem from './CreditRepListItem';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { getCreditRepList } from '../../api/creditRepApi';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom'


const ListCreditReps = () => {

    const [creditRepsList, setCreditRepsList] = useState([]);
    const token = useToken();
    const entityId = useEntityId();
    let navigate = useNavigate();

    useEffect(() => {
        fetchCreditReps();
    }, []);


    const fetchCreditReps = async () => {
        const list = await getCreditRepList(entityId, token);
        setCreditRepsList(list);
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                    <div className='cols-m-12'>
                        <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { navigate("/user/createCreditRep") }} buttonText="Create" />
                    </div>
                </div>
                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>Trading Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Entity Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }}>Structure</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold text-gray-600">
                                        {
                                            creditRepsList && Array.isArray(creditRepsList) ?
                                                creditRepsList.map(singleCreditRep => (
                                                    <CreditRepListItem key={singleCreditRep.id} singleCreditRep={singleCreditRep} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListCreditReps;