//AuthoriyConsentFormValidationschema.js

import * as Yup from 'yup';

const SubmitToLenderValidationSchema =  Yup.object().shape({
    lender : Yup.string().required("Required"),
    productType : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required") , otherwise: Yup.string().notRequired() }),
    frequency : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required"), otherwise: Yup.string().notRequired() }),
    repaymentAmount : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required"), otherwise: Yup.string().notRequired() }),
    paymentMethod : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required"), otherwise: Yup.string().notRequired() }),
    applicationType : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required"), otherwise: Yup.string().notRequired() }),
    creditHistory : Yup.string().when("lender", { is: "financeOne", then: Yup.string().required("Required"), otherwise: Yup.string().notRequired() })
});
 
export default SubmitToLenderValidationSchema ;