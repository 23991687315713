import axios from './axios';


const getNotes = async (token, referrerId) => {

    try {
      const response = await axios.get(`notes/${referrerId}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const createNote = async (token,  noteObj) => {
  try{
    const response = await axios.post(`notes`, noteObj , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}

 
const deleteNote = async(token, id) => {
  try {
    const response = await axios.delete(`notes/${id}`, { headers: { Authorization: "Bearer " + token } });
    console.log("response::: " , response)
    return  { "error": 0, message : response.data.message ? response.data.message : "" , httpCode : response.httpCode}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

export { createNote, getNotes , deleteNote};