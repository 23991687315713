
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, InputField } from '../../HtmlComponents';
import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';
import { Formik, Form, Field, FieldArray } from 'formik';
import ErrorMessage from '../../global/errors/Error';
import Notifications from '../../global/Notifications';
import LoadingScreen from '../../global/LoadingScreen';
import { getOfficeContact, createRegister } from "../../../api";


const CreateComplaint = ({ callback }) => {
    const [complaint, setComplaint] = useState({ date: '', payload: { applicantName: "", analystName: "", description: "" }, type: "complaint" });
    const [processing, setProcessing] = useState(false);

    const token = useToken();

    return (
        <>
            <Formik
                initialValues={complaint}
                enableReinitialize={false}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    var data = JSON.parse(JSON.stringify(values));
                    data["date"] =  convertDateintoUnix(data["date"]);
                    console.log("values::::: ", values);
                     setProcessing(true);
                     const response = await createRegister(data , token);
                     if (response["error"]) {
                         Notifications("error", response["message"] || "Error");
                         setComplaint(values);
                     } else {
                         console.log("response.id : " + response.id);
                         Notifications("success", response["message"] || "Created Successfully")
                         //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                         console.log(response);
                         if (response["error"] !== undefined && response["error"] == 0  && response["data"] !== undefined) { 
                            if(response["data"]["payload"] !== undefined ){response["data"]["payload"] = JSON.stringify(response["data"]["payload"]);}                            
                             callback("updatePayloadOnComplaingCreation", response["data"]);
                         }
                     }
                     setProcessing(false);
                }}
            >
                {({ errors, values, touched, setFieldValue, handleChange }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Date</div>
                                                <Field type="text" name="date" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                                    {({ field }) => (
                                                        <MaskedField fieldName="date" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                                    )}
                                                </Field>
                                            </div>
                                            {errors.date ? (<div>{errors.date}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Client/Applicant Name</div>
                                                <Field name="payload.applicantName" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.applicantName ? (<div>{errors.payload.applicantName}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Broker/Staff Involved</div>
                                                <Field name="payload.analystName" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.analystName ? (<div>{errors.payload.analystName}</div>) : null}


                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Description</div>
                                                <Field name="payload.description" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.description ? (<div>{errors.payload.description}</div>) : null}


                                        </div>
                                    </div>
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default CreateComplaint;