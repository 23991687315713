import { useEffect, useState } from "react";
import { SelectField, CustomButton, TextEditor, IconButton, InputField } from "../HtmlComponents";
import { formatDate, getCurrentDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth } from '../../helpers';
import { getReferrersList, createBulkEmailRequest, getCreditRepList } from '../../api';
import { useToken, useEntityType, useEntityId } from "../../hooks/useAuth";
import SingleRebate from './components/SingleRebate';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';

const ReferrerPaymentNotification = () => {
    const [referrers, setReferrers] = useState([]);
    const [selectedCReditRep, setSelectedCReditRep] = useState("");
    const [creditReps, setCreditReps] = useState([]);
    const [month, setMonth] = useState("");     //current, previous
    const [duration, setDuration] = useState({ fromMonth: 0, fromYear: 0, toMonth: 0, toYear: 0 });

    const [processing, setProcessing] = useState(false);
    const userEntityType = useEntityType();
    const entityType = useEntityType();


    //DATA FOR RCTI EMAIL
    const [singleReferrerUsers, setSingleReferrerUsers] = useState([]);
    const [sendEmailScreen, setSendEmailScreen] = useState(false);
    const [payload, setPayload] = useState({ subject: "", message: "", creditRepId: 0 })


    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "july" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    var token = useToken();
    var entityId = useEntityId();


    const updateMonthsAndYears = () => {
        var localYears = [];

        for (var i = 2018; i <= getCurrentDate("YYYY"); i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    useEffect(() => {
        updateMonthsAndYears();
        fetchCreditReps();
    }, [])

    useEffect(() => {
        if (duration["fromMonth"] > 0 && duration["fromYear"] > 0 && duration["toMonth"] > 0 && duration["toYear"] > 0) {
            setAllowSubmission(true);
        }
        else if (month !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [duration, month])

    //FETCH CREDIT REP LIST
    const fetchCreditReps = async () => {
        // /getCreditRepList
        //setCreditReps

        if(!["company"].includes(entityType)){return;} //IF LOGGED IN USER IS NOT COMPANY ENTITY THEN RETURN

        var creditRepOptions = [{ "value": 0, "label": "Select" }];
        try{
            var creditRepsResult = await getCreditRepList(entityId, token);
            if ( Array.isArray(creditRepsResult) && creditRepsResult.length > 0) {
                creditRepsResult.map(singleCreditRep => {
                    creditRepOptions.push({ "value": singleCreditRep["id"], "label": singleCreditRep["tradingName"] })
                })
                setCreditReps(creditRepOptions); 
            }
        }
        catch(e){ }
        console.log("CREDIT REPS LIST:: ", creditRepsResult);

         


    }


    const handler = async (name, value) => {
        switch (name) {
            case "monthSelection":
                setMonth(value);
                break;
            case "fromMonth":
                setDuration({ ...duration, fromMonth: value })
                break;
            case "fromYear":
                setDuration({ ...duration, fromYear: value })
                break;
            case "toMonth":
                setDuration({ ...duration, toMonth: value })
                break;
            case "toYear":
                setDuration({ ...duration, toYear: value })
                break;
            case "creditRepId":
                console.log("credit rep ::: ", value);
                setPayload({ ...payload, creditRepId: value });
                setSelectedCReditRep(value);
                 
                break;
            case "clearFilters":
                setDuration({ ...duration, fromMonth: "", fromYear: "", toMonth: "", toYear: "" });
                setMonth("");
                
                break;

            case "submit":
                var startDate = 0;
                var endDate = 0;
                setProcessing(true);

                if (month.length > 0) {
                    if (month === "currentMonth") {
                        startDate = convertDateintoUnix(getCurrentMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    } else {
                        startDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getPreviousMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    }
                }
                else {
                    //DURATION IS SELECTED
                    startDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
                    endDate = convertDateintoUnix(getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`) + " 23:59:59", "YYYY-MM-DD H:mm:ss");
                }

                var localPaylaod = {
                    startDate: startDate,
                    endDate: endDate,
                    creditRepId: payload["creditRepId"],
                    subject: payload["subject"],
                    message: payload["message"],
                    recipientType: "referrer"
                };
                console.log(localPaylaod);
console.log("entityType :::: ", entityType);
                //CHECK IF CREDITREP IS 0 OR SUBJECT AND MESSAGE IS EMPTY THEN DO NOT EXECUTE 
                if ( ["company"].includes(entityType) && (localPaylaod["creditRepId"] == 0 || localPaylaod["subject"].length == 0 || localPaylaod["message"].length == 0)) {
                    Notifications("error", "Please select credit rep and enter subject and message. ");
                    setProcessing(false);
                    return;
                }
                if ( ["creditRep"].includes(entityType) && (localPaylaod["subject"].length == 0 || localPaylaod["message"].length == 0)) {
                    Notifications("error", "Please enter subject and message. ");
                    setProcessing(false);
                    return;
                }
                
                //MAKE API REQUEST
                //paylaod[""]
                console.log("API REQUEST PAYLOAD:: ", localPaylaod);
                 
                var resp = await createBulkEmailRequest(token, localPaylaod);
                console.log("API resp:: ", resp);
                if (resp["error"]) { Notifications("error", resp["message"] || "Unable to process the request. ")  }
                else { Notifications("success", resp["message"] || "Created Successfully. ") } 
                setProcessing(false);
                break;
        }
    }




    return (<>

        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                    <div className="card-body">
                        <div className="row">
                            {
                                ['company']. includes(entityType) ? <div className="col-sm-12 mb-5" style={{ padding: "0px" }} >
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Credit Rep</label>
                                <SelectField
                                    fieldName="creditRepId"
                                    defaultSelectedOption={{ value: selectedCReditRep, label: "" }}
                                    options={creditReps}
                                    setFieldValue={handler}
                                />
                            </div> : ""
                            }
                             

                            { /** SEARCH TYPE 1 **/}
                            <div className="col-sm-2 fieldSet" >
                                <div className="heading">Select</div>
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Month</label>
                                <SelectField
                                    fieldName="monthSelection"
                                    defaultSelectedOption={{ value: month, label: "" }}
                                    options={[{ "value": "", "label": "Select Month" }, { "value": "currentMonth", "label": "Current Month" }, { "value": "previousMonth", "label": "Previous Month" }]}
                                    setFieldValue={handler}
                                />
                            </div>

                            { /** SEARCH TYPE 2 **/}
                            <div className="col-sm-10 fieldSet">
                                <div className="heading">Or Search</div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Month</label>
                                        <SelectField
                                            fieldName="fromMonth"
                                            defaultSelectedOption={{ value: duration['fromMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Year</label>
                                        <SelectField
                                            fieldName="fromYear"
                                            defaultSelectedOption={{ value: duration['fromYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Month</label>
                                        <SelectField
                                            fieldName="toMonth"
                                            defaultSelectedOption={{ value: duration['toMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Year</label>
                                        <SelectField
                                            fieldName="toYear"
                                            defaultSelectedOption={{ value: duration['toYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 mt-3" style={{ padding: "0px" }}>
                                <div className="mt-5">
                                    <InputField
                                        fieldName="subject"
                                        label="Subject"
                                        placeholder="Enter Subject"
                                        setFieldValue={(name, value) => { setPayload({ ...payload, subject: value }) }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 mt-3" style={{ padding: "0px" }}>
                                <div className="mt-5">
                                    <TextEditor
                                        fieldName="message"
                                        initialValue={""}
                                        height={300}
                                        setFieldValue={(name, value) => { setPayload({ ...payload, message: value }) }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 mt-2 mb-3">
                                Supported Tags:  { " <name> <firstName> <surname> <referrerTradingName> " }
                            </div>

                            <div className="col-sm-12 mt-4">
                                <CustomButton
                                    buttonClassName="btn btn-sm btn-primary "
                                    buttonStyle={{ marginTop: "10px", float: "right" }}
                                    buttonOnClick={() => { handler("submit", "") }}
                                    buttonText="Send Email"
                                    isActive={allowSubmission}
                                />

                                <CustomButton
                                    buttonClassName="btn btn-sm btn-secondary "
                                    buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                                    buttonOnClick={() => { handler("clearFilters", "") }}
                                    buttonText="Clear Filters"
                                    isActive={true}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
        {processing ? <LoadingScreen /> : ""}

    </>)
}

export default ReferrerPaymentNotification;