import { FloatField, SelectField } from '../../HtmlComponents';
import { Field } from 'formik';

const GiftCardReceiver = ({ index, referrerUsers, setFieldValue }) => {

    return (
        <>
            <div className="col-md-3 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                <label className=" fs-8 fw-bold mb-2">Gift Card Receiver {parseInt(index)+1}</label>
                <Field name={`giftCardReceivers[${index}].userId`}>
                    {({ field }) => (
                        <SelectField
                            fieldName={`giftCardReceivers[${index}].userId`}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={referrerUsers}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-md-3 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                <label className=" fs-8 fw-bold mb-2">Gift Card Amount</label>
                <Field name={`giftCardReceivers[${index}].amount`}  >
                    {({ field }) => (
                        <FloatField
                            fieldName={`giftCardReceivers[${index}].amount`}
                            fieldValue={field.value !== undefined ? field.value : 0.00}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

        </>

    )
}




export default GiftCardReceiver;