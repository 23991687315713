//AuthoriyConsentFormValidationschema.js

import * as Yup from 'yup';

const ApplicantTokenValidationSchema = Yup.object().shape({
    applicationId: Yup.string().required("Required"),
    applicantId: Yup.string().required("Required"),
    updateApplicationPermission: Yup.string().required("Required"),
    uploadFilesPermission: Yup.string().required("Required"),
});

export default ApplicantTokenValidationSchema;