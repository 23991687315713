import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

const Overdraft = ({prefix , setFieldValue , frequencyList }) => {
    return (
        <>
            <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Financial Institution</label>
                <Field name= { prefix+"financialInstitution"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"financialInstitution"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Credit Limit</label>
                <Field name= { prefix+"creditLimit"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"creditLimit"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>             
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Balance Remaining</label>
                <Field name= { prefix+"balanceRemaining"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"balanceRemaining"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Rate</label>
                <Field name= { prefix+"rate"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"rate"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>             
        </>
    )
}

export default Overdraft;