import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, TextEditor, SelectField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import Notifications from '../global/Notifications';
import LoadingScreen from '../global/LoadingScreen';
import { createEmailTemplate } from "../../api";
import constants from "../global/Constants";


const CreateEmailTemplate = ({ callback }) => {
    const [emailTemplate, setEmailTemplate] = useState({ title: '',  subject: "", recipientType: "", body: "", type: "emailTemplate" });
    const [processing, setProcessing] = useState(false);
    const [recipientTypeOptions, setRecipientTypeOptions] = useState([]);
    const token = useToken();
    
    useEffect(() => {
        //LOAD RECIPIENT TYPE OPTIONS
        const fetchRecipientTypeOptions = async () => {
            let tmpRecipientTypeOptions = [];
            Object.keys(constants.EMAIL_TEMPLATE_RECIPIENT_TYPES).map((key) => {
                tmpRecipientTypeOptions.push({ value: key, label: constants.EMAIL_TEMPLATE_RECIPIENT_TYPES[key] });
            });
            setRecipientTypeOptions(tmpRecipientTypeOptions);
        }
        fetchRecipientTypeOptions();

    }, []);

     

    return (
        <>
            <Formik
                initialValues={emailTemplate}
                enableReinitialize={false}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    var data = JSON.parse(JSON.stringify(values));                   
                    setProcessing(true);
                     const response = await createEmailTemplate(token, data);
                     if(response["error"] === 0 && response["data"] !== undefined && response["data"]["emailTemplate"] !== undefined && response["data"]["emailTemplate"]["id"] !== undefined){
                         callback("updateListingOnEmailTemplateCreation", response["data"]["emailTemplate"]);
                     }
                     else if(response["error"] === 1 && response["errorMessage"] !== undefined && response["errorMessage"].length > 0){
                        //CONVERT ARRAY INTO STRING WITH LINE BREAK
                        let errorMessage = response["errorMessage"].join("\n");
                         Notifications("error", errorMessage || "Error"); 
                     }
                    setProcessing(false);
                }}
            >
                {({ errors, values, touched, setFieldValue, handleChange }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Title</div>
                                                <Field type="text" name="title" placeholder="" className="form-control form-control-solid" onChange={handleChange} />
                                            </div>
                                            {errors.title ? (<div>{errors.title}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Subject</div>
                                                <Field name="subject" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors  && errors.subject ? (<div>{errors.subject}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Recipient Type</div>
                                                <Field name="recipientType">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="recipientType"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={recipientTypeOptions}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            {errors && errors.recipientType ? (<div>{errors.recipientType}</div>) : null}


                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Body</div>
                                                <TextEditor fieldName="body" height={300} setFieldValue={setFieldValue} />
                                            </div>
                                            {errors && errors.body ? (<div>{errors.body}</div>) : null}
                                            <div> <p>Supported Tags: {'{recipientUserName}'} {'{recipientFirstName}'} {'{recipientDefaultPassword}'} {'{recipientEmail}'} {'{dayPeriodType}'} </p> </div>              

                                        </div>
                                    </div>
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default CreateEmailTemplate;