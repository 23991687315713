import axios from './axios';

/*GET SINGLE CREDIT REP*/
const getLender = async (id, token) => {
  const response = await axios.get(`lenders/${id}`, { headers: { Authorization: "Bearer " + token } });
  return response.data ? response.data : {};
}

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getLendersList = async (token) => {
  try{
    const response = await axios.get(`lenders`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Success", lenders : response.data ? response.data : [] }
  }
  catch(e){
    return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
  } 


}

//CREATE A NEW LENDER
const createLender = async (lender, token) => {
  try {
    const response = await axios.post("lenders", lender, { headers: { Authorization: "Bearer " + token } });
    console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
    return { "error": 0, httpCode: response.status, "message": "Created successfully", lender: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}


const updateLender = async (lender, token) => {

  //UPDATE LENDER
  const id = lender.id;
 
  try {
    const response = await axios.put(`lenders/${id}`, lender, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Updated successfully" }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }


} 

const deleteLender = async(token, lenderId) => {
  try{
      const response = await axios.delete(`lenders/${lenderId}` , { headers: { Authorization: "Bearer " + token } });
      return response.data ? response.data : {};
  }
  catch(e){
      if (e.response) { 
          return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
       }
  }
}



//LIST ALL INVOICES THAT ARE NOT PUSHED TO XERO YET
const listAllLenderInvocies = async(token, year, month) => {
  try {
    const response = await axios.get(`lenders/listAllInvocies/${year}/${month}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

//submitInvociesToXero
const submitInvociesToXero = async(token, year, month) => {
  try {
    const response = await axios.post(`lenders/submitInvociesToXero/${year}/${month}`,  {},  { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

export { getLender, getLendersList, updateLender, createLender, deleteLender,  listAllLenderInvocies, submitInvociesToXero };