//TotalSettledReferralReport


import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getTotalSettledReferralReporting } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";

const TotalSettledReferralReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [report, setReport] = useState([]);
    const token = useToken();

    const fetchReport = async() => {
        setProcessing(true);
        var response = await getTotalSettledReferralReporting(token, startDate, endDate);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            setReport(response["data"]);
        }
        setProcessing(false);
    }

    useEffect(() => {
        fetchReport();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                <div className="bold mb-4">Settled Referrals*</div>
                    {processing ? <LoadingBlock /> :
                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3  text-start">Analyst </th>
                                        <th className="p-0 pb-3  text-start">Total </th>
                                        <th className="p-0 pb-3  text-start">Branch %</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                    {
                                        Array.isArray(report) ?
                                        report.map(singleRecord => {
                                                return <>
                                                    <tr >
                                                        <td>{ singleRecord["name"]}</td>
                                                        <td>{ singleRecord["totalSettlements"]}</td>
                                                        <td>{ singleRecord["percentage"]} %</td>
                                                    </tr>
                                                </>
                                            })
                                            : "<tr></tr>"
                                    }
                                    <tr>
                                        <td colSpan={4}> <span>% calculated against total Branch Applications </span></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default TotalSettledReferralReport;