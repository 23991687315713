import axios from './axios';

const createDeclined = async (declined, token) => {
    try {
        const response = await axios.post(`declined`, declined, { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        console.log("CREATION PROCESS COMPLETED");
        return { error: 0, httpCode: response.status, message: "Createds successfully", declined: response.data }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
        }
    }
}

const updateDeclined = async (id, data, token) => {

    try {
        const response = await axios.put(`declined/${id}`, data, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "Updated successfully", settlement: response.data ? response.data : {} }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, settlement: {} }
        }
    }
}
//REINSTATE
const deleteDeclined = async (id, token) => {

    try {
        const response = await axios.delete(`declined/${id}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "Deleted successfully", data: response.data ? response.data : {} }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, settlement: {} }
        }
    }
}


/*GET SINGLE SETTLEMENT*/
const getDeclined = async (id, token) => {
    try {
        const response = await axios.get(`declined/${id}`, { headers: { Authorization: "Bearer " + token } });
        return { error: 0, httpCode: response.status, declined: response.data ? response.data : {} }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, declined: {} }
        }
    }
} 
 

const getDeclinedList = async (token, query) => {
    try {
        const response = await axios.get(`declined/listing/?${query}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "", data: response.data ? response.data : [] }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
        }
    }
}
const getAllDueFollowUp = async (token, query) => {
    try {
        const response = await axios.get(`declined/getAllDueFollowUp/?${query}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "", data: response.data ? response.data : [] }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
        }
    }
}


export { createDeclined, updateDeclined, getDeclined, getDeclinedList, getAllDueFollowUp , deleteDeclined};