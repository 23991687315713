import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken, useEntityType } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, SelectField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { getReferrer, updateReferrer, getReferrerGroupsList } from "../../api";
import Notifications from '../../components/global/Notifications';

//SCHEMA
import { ReferrerValidationSchema } from './schema/ReferrerValidationSchema';

//MODALS
import AddressForm from '../global/AddressForm';
//HELPERS
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
//ERRORS
import LoadingScreen from '../global/LoadingScreen';

//CONSTANTS
const xeroTaxTypes = ["EXCLUSIVE", "INCLUSIVE", "NOTAX"]

const EditReferrer = () => {
    const [singleReferrer, setSingleReferrer] = useState({});
    const [referrerGroups, setReferrerGroups] = useState([]);

    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });


    const taxTypes = [
        { value: "EXCLUSIVE", label: "EXCLUSIVE" },
        { value: "INCLUSIVE", label: "INCLUSIVE" },
        { value: "NOTAX", label: "NO TAX" }
    ];

    let { id } = useParams();
    const token = useToken();
    const entityType = useEntityType();
    let navigate = useNavigate();

    const fetchReferrerGroups = async () => {
        var response = await getReferrerGroupsList(token);
        console.log("RESPONSE", response);
        if (response["error"]) {
            console.log("REFERER GROUPS LIST", response);
        }
        else {
            //
            if (Array.isArray(response["referrerGroups"])) {
                var referrerGroups = response["referrerGroups"];
                var options = [];
                referrerGroups.map((referrerGroup) => {
                    options.push({ value: referrerGroup.id, label: referrerGroup.tradingName });
                })
                setReferrerGroups(options);
            }
        }

    }


    useEffect(() => {
        const fetchSingleReferrer = async () => {
            const result = await getReferrer(id, token);

            if (result["error"] == 0 && result["referrer"]) {
                var referrer = result["referrer"];
                var unresolvedPromises = replaceNullWithString(referrer);

                Promise.all([unresolvedPromises]).then((cleanReferrer) => {
                    //CONVERT DATE INTO DD/MM/YYYY FORMAT || ASSIGN '' IF EMPTY OR NULL
                    console.log("cleanReferrer[0].dateEstablished", cleanReferrer[0].dateEstablished)
                    if (cleanReferrer[0].dateEstablished) { cleanReferrer[0].dateEstablished = formatDate(cleanReferrer[0].dateEstablished, 'unix', 'DD/MM/YYYY'); }
                    if (cleanReferrer[0].joiningDate) { cleanReferrer[0].joiningDate = formatDate(cleanReferrer[0].joiningDate, 'unix', 'DD/MM/YYYY'); }
                    setSingleReferrer(cleanReferrer[0] ? cleanReferrer[0] : {});
                    setLoading(false);
                })
            }
            else {
                Notifications("error", "Fail to load this Referrer Group");
                setLoading(false);
            }
        }
        fetchSingleReferrer();
        if (entityType !== "referrerGroup") { fetchReferrerGroups(); }

    }, []);

    /*  ********************* HTML COMPONENTS OF REFERRER GROUP  - START *****************/


    const summary = (errors, touched, handleChange, setFieldValue) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Referrer Details</h3>
                        </div>
                    </div>

                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">

                                {entityType != "referrerGroup" ? <tr className="even">
                                    <td className="text-start">Referrer Group</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="referrerGroupId">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="referrerGroupId"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={referrerGroups}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr> : ""
                                }
                                 
                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td className="text-start text-gray-600"><Field name="tradingName" placeholder="Trading Name" className="form-control form-control-solid" />
                                        {errors.tradingName && touched.tradingName ? (<div className="error">{errors.tradingName}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name </td>
                                    <td className="text-start text-gray-600"><Field name="entityName" placeholder="Entity Name" className="form-control form-control-solid" />
                                        {errors.entityName && touched.entityName ? (<div className="error">{errors.entityName}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="structure">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="structure"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Company", value: "company" }, { label: "Partnership", value: "partnership" }, { label: "Sole Trader", value: "soleTrader" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN </td>
                                    <td className="text-start text-gray-600"><Field name="abn" placeholder="ABN" className="form-control form-control-solid" />
                                        {errors.abn && touched.abn ? (<div className="error">{errors.abn}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN </td>
                                    <td className="text-start text-gray-600"><Field name="acn" placeholder="ACN" className="form-control form-control-solid" />
                                        {errors.acn && touched.acn ? (<div className="error">{errors.acn}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Joining Date</td>
                                    <td className="text-start text-gray-600">
                                        <Field type="text" name="joiningDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                            {({ field }) => (
                                                <MaskedField fieldName="joiningDate" fieldValue={dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                        {errors.joiningDate && touched.joiningDate ? (<div className="error">{errors.joiningDate}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Date Established </td>
                                    <td className="text-start text-gray-600">
                                        <Field type="text" name="dateEstablished" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                            {({ field }) => (
                                                <MaskedField fieldName="dateEstablished" fieldValue={dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                        {errors.dateEstablished && touched.dateEstablished ? (<div className="error">{errors.dateEstablished}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">contact Number </td>
                                    <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" onChange={handleChange} />
                                        {errors.contactNumber && touched.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax </td>
                                    <td className="text-start text-gray-600"><Field name="fax" placeholder="Fax" className="form-control form-control-solid" />
                                        {errors.fax && touched.fax ? (<div className="error">{errors.fax}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Address</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="address">
                                            {({ field }) => (
                                                <input
                                                    className="form-control form-control-solid"
                                                    type="text"
                                                    value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                    onClick={() => setAddressModal({ visible: true, filedName: "address", callback: setFieldValue })}
                                                    onChange={(e) => { }}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website Url </td>
                                    <td className="text-start text-gray-600"><Field name="websiteUrl" placeholder="Website Url" className="form-control form-control-solid" />
                                        {errors.websiteUrl && touched.websiteUrl ? (<div className="error">{errors.websiteUrl}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                        {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Payment Method</td>
                                    <td className="text-start text-gray-600">

                                        <Field name="paymentMethod">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="paymentMethod"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "CHEQUE", value: "CHEQUE" }, { label: "EFT", value: "EFT" }, { label: "CARD", value: "CARD" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Commission Structure</td>
                                    <td className="text-start text-gray-600"><Field name="commissionStructure" placeholder="Commission Structure" className="form-control form-control-solid" />
                                        {errors.commissionStructure && touched.commissionStructure ? (<div className="error">{errors.commissionStructure}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Gst Registered</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="isGstRegistered">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="isGstRegistered"
                                                    defaultSelectedOption={`${field.value}`}
                                                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.isGstRegistered && touched.isGstRegistered ? (<div className="error">{errors.isGstRegistered}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">CRM - Export customers csv</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="exportingCustomerAllowed">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="exportingCustomerAllowed"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.exportingCustomerAllowed && touched.exportingCustomerAllowed ? (<div className="error">{errors.exportingCustomerAllowed}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">System Access</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="systemAccess">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="systemAccess"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.systemAccess && touched.systemAccess ? (<div className="error">{errors.systemAccess}</div>) : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const BankingDetails = (errors, touched) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.name" placeholder="Bank Name" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.name && touched.bankingDetail.name ? (<div className="error">{errors.bankingDetail.name}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Branch</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.branchName" placeholder="Branch Name" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.branchName && touched.bankingDetail.branchName ? (<div className="error">{errors.bankingDetail.branchName}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.bsb" placeholder="BSB" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.bsb && touched.bankingDetail.bsb ? (<div className="error">{errors.bankingDetail.bsb}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Account Number</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.accountNumber" placeholder="Account Number" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.accountNumber && touched.bankingDetail.accountNumber ? (<div className="error">{errors.bankingDetail.accountNumber}</div>) : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    const XeroConfig = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">XERO Config</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Contact ID</td>
                                    <td className="text-start text-gray-600"><Field name="xeroConfig.xeroContactId" placeholder="CONTACT ID" className="form-control form-control-solid" /></td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Tax Type</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="xeroConfig.xeroTaxType">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="xeroConfig.xeroTaxType"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={taxTypes}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }



    /********************** HTML COMPONENTS OF REFERRER GROUP  - END *****************/


    function LoadView() {
        return (
            <>
                <Formik
                    initialValues={singleReferrer}
                    validationSchema={ReferrerValidationSchema}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        let valuesClone = JSON.parse(JSON.stringify(values));
                        valuesClone["dateEstablished"] = valuesClone["dateEstablished"].includes("/") ? formatDate(valuesClone["dateEstablished"], 'DD/MM/YYYY', 'YYYY-MM-DD') : valuesClone["dateEstablished"];
                        valuesClone["joiningDate"] = valuesClone["joiningDate"] !== 0 && valuesClone["joiningDate"].includes("/") ? formatDate(valuesClone["joiningDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : valuesClone["joiningDate"];

                        //IF REFERRER GROUP UPDATING THIS REFERER THEN ASSIGN THE REFERRER GROUP ID HERE MANUALLY
                        if (entityType === "referrerGroup") { valuesClone["referrerGroupId"] = singleReferrer["referrerGroupId"]; }
                        
                        setProcessing(true);
                        var response = await updateReferrer(valuesClone, id, token)
                        if (response["error"]) {
                             Notifications("error", response["message"] || "Could not update the referrer.")
                        }
                        else {
                            Notifications("success", response["message"] || "Updated Successfully");
                             navigate(`/user/referrerDetail/${id}`);
                        }
                        setProcessing(false);


                    }}
                >
                    {({ errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <div id="kt_content_container" className="container-xxl">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-6">
                                        {summary(errors, touched, handleChange, setFieldValue)}
                                    </div>
                                    <div className="col-xl-6">
                                        {entityType !== "referrerGroup" ? BankingDetails(errors, touched) : ""}
                                        {entityType !== "referrerGroup" ? XeroConfig(errors, touched, setFieldValue) : ""}
                                    </div>
                                    <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                    <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }

    return (
        <>
            {loading || processing ? <LoadingScreen /> : LoadView()}


            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Referrer Group Address">
                    <AddressForm
                        fieldName={addressModal.filedName}
                        closeButtonHandler={() => setAddressModal({ visible: false, callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    );
}

export default EditReferrer;