
import React, { useEffect, useState } from "react";
import SingleDebt from "./SingleDebt";
import Constants from "../../../../../components/global/Constants";

const ListDebts = ({debts , debtsTypeList, setFieldValue, deleteDebt}) => {
    const [frequency, setFrequency] = useState([]);
    const FetchFrequency = () =>{
        var options = [];
        Object.keys(Constants.FREQUENCY).map( key => {
            options.push({value : key , label : Constants.FREQUENCY[key]});
        })
        setFrequency(options);
    }

    useEffect( () => {
        FetchFrequency();                 
    }, [])
    
    return(
        debts.map( ( debt , index ) => {
            return (
                <SingleDebt key={debt.uId !== undefined ? debt.uId : "debt-"+index} index= {index} debtType={debt.debtType} debtsTypeList = {debtsTypeList}  frequencyList= {frequency}  setFieldValue = {setFieldValue} deleteDebt = {deleteDebt} />
           )
       })
    )
}
export default ListDebts;