import { useEffect, useState } from 'react';
import { InputField, FloatField, SelectField, MaskedField } from '../../HtmlComponents';
import { Field } from 'formik';
import { replaceNullWithString, formatDate, dateFilter, capitalizeFirst } from '../../../helpers';

const SingleInsurance = ({ labelPrefix, index, type, insuranceCompanies, setFieldValue, callback }) => {

    const [filterCompanies, setFilterCompanies] = useState([]);  //FILTER THE COMPANIES AGAINST GIVEN TYPE
    const [selectedCompanyName, setSelectedCompanyName] = useState("");  //UPDATE THIS IF COMPANY NAME IS OTHER

    const executeFilterCompanies = () => {
        const options = [];
        insuranceCompanies.map(company => {
            if (company.type == type) {
                options.push({ value: company.id, label: company.name })
            }
        })
        setFilterCompanies(options);
    }

    const updateSelectedCompanyName = (companyId) => {
        var option = { value: 0, label: "Select" };
        insuranceCompanies.map(company => {
            if (company.id == companyId) { option = { value: company.id, label: company.name }; }
        })
        console.log("option::::: ", option);

        if (option["label"].toLocaleLowerCase() === "other") { setSelectedCompanyName("other"); }
        else { setSelectedCompanyName(option["label"]) }
    }

    useEffect(() => {
        executeFilterCompanies();
    }, [])

    useEffect(() => {

    }, [])

    return (
        <>
            <div className='row'>
                <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">{capitalizeFirst(labelPrefix)} Company</label>
                    <Field name={`insurances[${index}].insuranceCompanyId`}>
                        {({ field }) => (
                            <SelectField
                                fieldName={`insurances[${index}].insuranceCompanyId`}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={filterCompanies}
                                setFieldValue={setFieldValue}
                                callback={(name, value, fun) => { updateSelectedCompanyName(value); }}
                            />
                        )}
                    </Field>
                </div>
                {
                    selectedCompanyName === "other" ?
                        <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                            <label className="required fs-8 fw-bold mb-2">Company Name</label>
                            <Field name={`insurances[${index}].extraDetail.companyName`}  >
                                {({ field }) => (
                                    <InputField
                                        fieldName={`insurances[${index}].extraDetail.companyName`}
                                        fieldValue={field.value}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>
                        </div> : ""
                }
                <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Is Financed</label>
                    <Field name={`insurances[${index}].extraDetail.isFinanced`}>
                        {({ field }) => (
                            <SelectField
                                fieldName={`insurances[${index}].extraDetail.isFinanced`}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Commission</label>
                    <Field name={`insurances[${index}].commission`}  >
                        {({ field }) => (
                            <FloatField
                                fieldName={`insurances[${index}].commission`}
                                fieldValue={field.value !== undefined ? field.value : 0.00}
                                setFieldValue={async (name, value) => {
                                    setFieldValue(name, value);
                                    await Promise.resolve()
                                    callback(name, value);
                                }}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Premium</label>
                    <Field name={`insurances[${index}].grossPremium`}  >
                        {({ field }) => (
                            <FloatField
                                fieldName={`insurances[${index}].grossPremium`}
                                fieldValue={field.value !== undefined ? field.value : 0.00}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                {
                    type == "warrenty" ?
                        <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                            <label className=" fs-8 fw-bold mb-2">Net Premium</label>
                            <Field name={`insurances[${index}].netPremium`}  >
                                {({ field }) => (
                                    <FloatField
                                        fieldName={`insurances[${index}].netPremium`}
                                        fieldValue={field.value !== undefined ? field.value : 0.00}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>
                        </div>
                        : ""
                }
                {
                    type == "comprehensive" ?
                        <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                            <label className=" fs-8 fw-bold mb-2">Policy Number</label>
                            <Field name={`insurances[${index}].extraDetail.policyNumber`}  >
                                {({ field }) => (
                                    <InputField
                                        fieldName={`insurances[${index}].extraDetail.policyNumber`}
                                        fieldValue={field.value !== undefined ? field.value : 0.00}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>
                        </div>
                        : ""
                }
                {
                    type == "cci" ?
                        <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                            <label className=" fs-8 fw-bold mb-2">Policy Type</label>
                            <Field name={`insurances[${index}].extraDetail.policyType`}  >
                                {({ field }) => (
                                    <SelectField
                                        fieldName={`insurances[${index}].extraDetail.policyType`}
                                        defaultSelectedOption={{ value: field.value !== undefined ? field.value : "", label: "" }}
                                        options={[{ value: "LDU", label: "LDU" }, { value: "LD", label: "LD" }, { value: "DU", label: "DU" }, { value: "L", label: "L" }, { value: "D", label: "D" }, { value: "U", label: "U" }, { value: "LTI", label: "LTI" }]}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>
                        </div>
                        : ""
                }
                <div className="col-md-2 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Expiry Date</label>
                    <Field type="text" name={`insurances[${index}].expiryDate`}  >
                        {({ field }) => (
                            <MaskedField
                                fieldName={`insurances[${index}].expiryDate`}
                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""}
                                maskRule="date"
                                fieldPlaceholder="dd/mm/yyyy"
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
            </div>
        </>

    )
}




export default SingleInsurance;