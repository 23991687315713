import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton, RightDrawer, TextArea } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";
import SelectFieldGroup from '../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getLendersList, getLender, getComments, getFolders, getFiles, } from "../../../api";
import { useToken } from '../../../hooks/useAuth';
import LoadingScreen from '../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../schema/SubmitToLenderValidationSchema';

import SubmitFilesToLender from './SubmitFilesToLender';


//LOAD LENDERS
import Plenti from "./submitToLenderComponents/Plenti";
import Wisr from "./submitToLenderComponents/Wisr";
import FinanceOne from "./submitToLenderComponents/FinanceOne";
import Money3 from "./submitToLenderComponents/Money3";
import PepperMoney from "./submitToLenderComponents/PepperMoney";

const SubmitToLender = ({ applicationId, callback }) => {
    const [selectedLender, setSelectedLender] = useState("");
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
   
    const lenders = [
        { value: "pepperMoney", label: "Pepper Money" },
        { value: "financeOne", label: "Finance One" },
        { value: "money3", label: "Money 3" },
        { value: "plenti", label: "Plenti" },
        { value: "wisr", label: "Wisr" }
    ];

    const handler = (name, value) => {
        switch (name) {
            case "lender":
                setSelectedLender(value);
                break;
        }
    }

    //drawerHandler
    const drawerHandler = (action, payload = {}) => {
        //openSubmitFilesToLender
        switch (action) {
            case "openSubmitFilesToLender":
                setDrawerSettings({ visible: true, heading: "Submit Files To Lender", childName: "submitFilesToLender", meta: { applicationId: applicationId } });
                break;
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">

                    <div>
                        {
                            ["money3", "financeOne"].includes(selectedLender) ?
                                <CustomButton
                                    buttonClassName=" btn-primary btn-sm btn-nochange-on-hover"
                                    buttonStyle={{ fontSize: "12px", color: "#009ef7", backgroundColor: "transparent", textDecoration: "underline", padding: "0px", paddingLeft: "0px" }}
                                    buttonText="Upload Files"
                                    isActive={true}
                                    buttonOnClick={() => { drawerHandler("openSubmitFilesToLender") }}
                                /> : ""
                        }
                        {
                            selectedLender === "money3" ? <p>You can submit the file(s) only after you've submitted the application.</p> : ""
                        }
                        {
                            selectedLender === "financeOne" ? <p>Please upload the file(s) before submitting the application.</p> : ""
                        }
                    </div>


                    {/**CREATE A DROP DOWN FOR LENDER SELECTION  **/}
                    <SelectField
                        fieldName="lender"
                        defaultSelectedOption={{ value: selectedLender, label: "" }}
                        options={lenders}
                        setFieldValue={handler}
                    />

                    {selectedLender === "plenti" ? <Plenti applicationId={applicationId} /> : ""}
                    {selectedLender === "wisr" ? <Wisr applicationId={applicationId} /> : ""}
                    {selectedLender === "financeOne" ? <FinanceOne applicationId={applicationId} /> : ""}
                    {selectedLender === "money3" ? <Money3 applicationId={applicationId} /> : ""}
                    {selectedLender === "pepperMoney" ? <PepperMoney applicationId={applicationId} /> : ""}
                </div>
            </div>



            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawerHandler}>
                    {drawerSettings["childName"] === "submitFilesToLender" ? <SubmitFilesToLender applicationId={applicationId} lenderName={selectedLender} callback={drawerHandler} /> : ""}
                </RightDrawer> : ""
            }

        </>
    )
}


export default SubmitToLender;