

const Error404 = () => {

    return(
        <>
         <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <img src="/media/illustrations/dozzy-1/18-dark.png" alt="" className="h-lg-450px" />
            </div>
        </>
    )
}

export default Error404;