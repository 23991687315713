//officeContact

import axios from '../api/axios';

const getOfficeContact = async(token, officeContactId) => {
    try{
        const response = await axios.get(`officeContact/${officeContactId}` , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", officeContact: response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}


const getAllOfficeContacts = async(token) => {
    try{
        const response = await axios.get(`officeContact` , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

const createOfficeContact = async(token , officeContact) => {
    try{
        const response = await axios.post(`officeContact`,  officeContact , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", officeContact: response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
         }
    }
}


const updateOfficeContact = async(token, officeContactId, officeContact) => {
    try{
        const response = await axios.put(`officeContact/${officeContactId}`,  officeContact , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", officeContact: response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

export {getOfficeContact,  getAllOfficeContacts, createOfficeContact, updateOfficeContact};