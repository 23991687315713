//bulkEmailRequest
import axios from './axios';

 
//CREATE
const createBulkEmailRequest = async (token,  bulkEmailRequest) => {
  try{
    const response = await axios.post(`bulkEmailRequest`, bulkEmailRequest , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}
 
  

export { createBulkEmailRequest };