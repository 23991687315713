
import { useState, useEffect } from 'react';
import { FormSubmissionButton, TextEditor, CustomButton, Modal, MaskedField, RadioGroup, SelectField, InputField } from '../../HtmlComponents';
import NmbEmailTemplate from './emailTemplates/NmbEmailTemplate';
import MotorDealersTemplate from './emailTemplates/MotorDealersTemplate';
import{sendEmail , getUsers , getEmailTemplates, getEmailTemplate} from '../../../api';
import Notifications from '../../global/Notifications';
import LoadingScreen from '../../global/LoadingScreen';
import { isEmailValid, removeSpace , getdayPeriodType} from '../../../helpers';
import { useToken, useFullName, useRole } from '../../../hooks/useAuth';

const ReferrerWelcomeEmail = ({ payload, recipientType, calllback }) => {   
//    var recipientType = 'referrerUser'; //IT INDICATES THE NATURE OF THE RECIPIENTS REFERRERuSER | LENDERuSER ETC
    var recipientFirstName = payload["recipientFirstName"] !== undefined ? payload["recipientFirstName"] : "";
    var recipientUserName = payload["recipientUserName"] !== undefined ? payload["recipientUserName"] : "";
    var recipientDefaultPassword = payload["recipientDefaultPassword"] !== undefined ? payload["recipientDefaultPassword"] : "";
    var recipientEmail = payload["recipientEmail"] !== undefined ? payload["recipientEmail"] : "";
    var dayPeriodType = getdayPeriodType();

    var cc = payload["cc"] !== undefined ? payload["cc"] : "";
    
    const [emailTemplatesOptions, setEmailTemplatesOptions] = useState([]);
    const [selectedEmailTemplates, setSelectedEmailTemplates] = useState({});
    const [file, setFile] = useState({});
    const [senderOptions, setSenderOptions] = useState([]);

    const [emailpayload, setEmailpaylaod] = useState({ email: recipientEmail, cc: cc,  subject: "", emailBody: "", emailtemplateId: "" , senderLabel: "NFAL Onboarding", senderId: "", fileData: {name: "" , base64 : "", isExist: 0} });
    const [emailBodyInitialValue, setEmailBodyInitialValue] = useState("");     //THIS IS EMAIL BODY - OLD 
    const [processing, setProcessing]  = useState(false);

    const token = useToken();
    const userFullName = useFullName();
    const userRole  = useRole();

    const handler = (name, value) => {
        switch (name) {
            case "templateChanged":
                /*switch (value) {
                    case "nmb":
                        const nmbTemplate = NmbEmailTemplate({ firstName: referrerFirstName, userName: userName, defaultPassword: defaultPassword })
                        setEmailpaylaod({ ...emailpayload, subject : "National Finance + Loans Asset Finance Referrals", emailBody: nmbTemplate , emailtemplate: value, });
                        setEmailBodyInitialValue(nmbTemplate);
                        break;
                    case "motorDealers":
                        const motorDealersTemplate =  MotorDealersTemplate( { firstName: referrerFirstName , userName: userName , defaultPassword: defaultPassword})  
                        setEmailpaylaod({ ...emailpayload, subject: "National Finance + Loans Asset Finance Referrals",  emailtemplate: value, emailBody: "" });
                        setEmailBodyInitialValue(motorDealersTemplate);
                        break;
                }*/
                break;
            case "email":
                setEmailpaylaod({ ...emailpayload, email: value });
                break;
            case "cc":
                setEmailpaylaod({ ...emailpayload, cc: value });
                break;
            case "subject":
                setEmailpaylaod({ ...emailpayload, subject: value });
                break;
            case "emailBody":
                setEmailpaylaod({ ...emailpayload, emailBody: value });
                break;
            case "senderId":
                setEmailpaylaod({ ...emailpayload, senderId: value });
                break;

        }
    }

    const loadEmailTemplates = async () => {
        var response = await getEmailTemplates(token); //LOAD ALL RECORDS OF COMPLAINT TYPE
        console.log("response:::::::::::  ", response);
        if(response["error"] !== undefined && response["error"] == 0 
        && response["data"] !== undefined && response["data"]["emailTemplates"] !== undefined
          && Array.isArray(response["data"]["emailTemplates"]) && response["data"]["emailTemplates"].length > 0){

            //PREPARE OPTIONS FOR SELECT FIELD -- GET ID AS VALUE AND TITLE AS LABEL
            let tmpEmailTemplateOptions = [];
            response["data"]["emailTemplates"].map( template => { 
                if(template["recipientType"] == recipientType){
                    tmpEmailTemplateOptions.push({ value: template["id"], label: template["title"] });
                }
            });
            setEmailTemplatesOptions(tmpEmailTemplateOptions);
        }
    }

    //LOAD SINGLE EMAIL TEMPLATE
    const loadSelectedEmailTemplate = async (emailTemplateId) => {
        const response = await getEmailTemplate(token, emailTemplateId);
        console.log("response:::::::::::  ", response);
        if (response["error"] === 0 && response["data"] !== undefined && response["data"]["emailTemplate"] !== undefined) {

            //REPLACE THE EMAIL TAGS WITH VALUES
            let replaceTagsResponse = await replaceTags(response["data"]["emailTemplate"]["body"]);
            let body = replaceTagsResponse["error"] == 0 && replaceTagsResponse["emailBody"] !== undefined  ? replaceTagsResponse["emailBody"]: "";

            setEmailpaylaod({ 
                ...emailpayload, 
                emailtemplateId: emailTemplateId,
                emailBody: body,
                subject: response["data"]["emailTemplate"]["subject"],
             }); 
        }
    }

    //REPLACE THE TAGS WITH VALUES
    const replaceTags = async (emailBody) => {
        //FIND TAGS {recipientUserName} {recipientFirstName} {recipientDefaultPassword} {recipientEmail} {dayPeriodType} IN THE EMAIL BODY AND REPLACE THEM WITH VALUES            
        return await new Promise((resolve, reject) => {
                emailBody = emailBody.replaceAll("{recipientUserName}", recipientUserName);
                emailBody = emailBody.replaceAll("{recipientFirstName}", recipientFirstName);
                emailBody = emailBody.replaceAll("{recipientDefaultPassword}", recipientDefaultPassword);
                emailBody = emailBody.replaceAll("{recipientEmail}", recipientEmail);
                emailBody = emailBody.replaceAll("{dayPeriodType}", dayPeriodType);
                resolve({ "error": 0, "emailBody": emailBody});
            }            
        )

    }


    useEffect(() => {
        const fetchUsers = async() => {
            if(["admin"].includes(userRole)){
                const response = await getUsers(token, "referrerGroup", 0);
                var referrersUsers = response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"]) ? response["data"] : [];
    
                var usersList = [];
                usersList = referrersUsers.map(user => { return { "value": user["id"], "label": user["firstName"] } })
    
                usersList.unshift({ "value": 0, "label": userFullName});
                setSenderOptions(usersList);   
            }
            else{
                setSenderOptions([{ "value": 0, "label": userFullName}]);   
            }
        }
        fetchUsers();

        loadEmailTemplates();
    }, [])


    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
          }
    }
    const convertFileToBase64 = () => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
        reader.onerror = reject;
    });





    const handleEmailSending = async() => {
        //CHECK IF VALID EMAIL AND SUBJECT IS PROVIDED AND BODY IS NOT EMPTY
        var error = 0;
        if(!isEmailValid(emailpayload["email"])){Notifications("error" ,  "Invalid Email Address");  error= 1; }

        //CHECK IF SUBJECT IS PROVIDED
        var tmpSubject = removeSpace(emailpayload["subject"]); 
        if(tmpSubject.length < 1){  Notifications("error" , " Subject is missing");  error= 1;  }
         
        //EMAIL BODY
        var tmpEmailBody = removeSpace(emailpayload["emailBody"]);
        if(tmpEmailBody.length < 1){  Notifications("error" , " Email Body missing");  error= 1;  }

        if(error === 0){  
            var tmpEmailPayload = JSON.parse(JSON.stringify(emailpayload));

            try{
                let fileName = file && file.name !== undefined ? file.name: 'File.pdf';
                let fileSize = file && file.size !== undefined ? file.size: 0;

                //IF FILE SIZE > 0 THEN CONVERT THE FILE IN BASE64 STRING
                if(fileSize > 0 ){
                    var base64String = await convertFileToBase64();                    
                    console.log("rsp line 103 ::: ", base64String);
                    tmpEmailPayload["fileData"]["name"] = fileName;
                    tmpEmailPayload["fileData"]["isExist"] = 1;
                    tmpEmailPayload["fileData"]["base64"] = base64String;
                }
            }
            catch(e){}
             
            setProcessing(true);
            var response = await sendEmail(token, tmpEmailPayload );

            if(response["data"]["error"] !== undefined && response["data"]["error"] == 0){
                Notifications("success" , response["data"]["message"] || "sent Successfully");
            }
            else{ Notifications("error", response["data"]["message"] || "Error" ) }
            setProcessing(false);
            
         }

    }
 

    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <InputField fieldName='email' placeholder="Receiver Email Address" fieldValue={emailpayload["email"]} setFieldValue={handler} />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <InputField fieldName='cc' placeholder="CC" fieldValue={emailpayload["cc"]} setFieldValue={handler} />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <label className='mb-2'>Select Template</label>
                            <SelectField fieldName='emailtemplateId'
                                /*options={[{ value: "nmb", label: "NMB" }, { value: "motorDealers", label: "Motor Dealers" }]} */
                                options={emailTemplatesOptions}
                                defaultSelectedOption={{ value: emailpayload["emailtemplateId"], label: "" }}
                                setFieldValue={(fieldName, fieldValue) => {  loadSelectedEmailTemplate(fieldValue);  /*handler("templateChanged", fieldValue)*/  }}
                                 
                            />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <label className='mb-2'>Select Sender</label>
                            <SelectField fieldnName='senderId'
                                options={ senderOptions }
                                defaultSelectedOption={{ value: emailpayload["senderId"], label: "" }}
                                setFieldValue={(fieldName, fieldValue) => { handler("senderId", fieldValue) }}
                            />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <InputField fieldName="subject"  placeholder="Email Subject" fieldValue={emailpayload["subject"]}  setFieldValue={handler}  />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <TextEditor  fieldName="emailBody" initialValue={emailpayload["emailBody"]} height={300} setFieldValue={handler} />
                        </div>

                        <div className='col-sm-12 mt-4'>
                            <input type="file" id="avatar" name="avatar" accept="application/pdf"   onChange={handleFileChange}  />
                            <div>{file && file.name !== undefined && file.type !== undefined ? `${file.name} - ${file.type}` : "" }</div>
                        </div>

                        <div className='col-sm-12 mt-5'  >
                            <div className="col-xl-12"> 
                            <CustomButton 
                            buttonClassName= "btn btn-sm btn-primary btn-hover-rise" 
                            isActive={true} 
                            buttonStyle = {{ float: "right" }}
                            buttonOnClick= { () => { handleEmailSending() }}
                            buttonText = "Send Email"
                            />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>

            {
                processing ? <LoadingScreen /> :""
            }
        </>
    )
}

export default ReferrerWelcomeEmail;