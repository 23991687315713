//AuthoriyConsentFormValidationschema.js

import * as Yup from 'yup';

const BankStatementRequestValidationSchema =  Yup.object().shape({
    bankStatementLinkId : Yup.string().required("Required"),
    receiver: Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email("Invalid email").required("Email required")
     })

});
 
export default BankStatementRequestValidationSchema ;