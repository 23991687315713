import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import { calculateMonthlyAmount } from '../../../../helpers';
import { useRole } from "../../../../hooks/useAuth";

const Expenses = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, openByDefault }) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [totalExpense, setTotalExpense] = useState(0);
    const [lowExpensesMessage, setLowExpensesMessage] = useState("");

    const userRole = useRole();

    const handleChange = () => { setIsOpen(isOpen === "none" ? "block" : "none"); }


    const fetchFrequencyList = () => {
        var options = [];
        Object.keys(Constants.FREQUENCY).map((index) => {
            options.push({ value: index, label: Constants.FREQUENCY[index] })
        });
        setFrequencyOptions(options);
    }

    useEffect(() => {
        fetchFrequencyList();
        console.log("PAGE LOADED AGAIN --------------------- line 24");
    }, []);
    //FIELD GROUP CONATINS 1 TEXT AFIELD AND 1 DROPDOWN BOX
    const ComboBox = ({ fieldName, setFieldValue }) => {
        return (
            <>
                <div className="input-group input-group-solid mb-5">
                    <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                    <Field name={fieldName + ".amount"}>
                        {({ field }) => (
                            <InputField fieldName={fieldName + ".amount"} placeholder="Amount" fieldValue={field.value} setFieldValue={setFieldValue} />
                        )}
                    </Field>

                    <Field name={fieldName + ".frequency"} style={{ width: " 150px" }}>
                        {({ field }) => (
                            <SelectField
                                fieldName={fieldName + ".frequency"}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={frequencyOptions}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

            </>
        )

    }

    const TotalExpenseField = async () => {
        /*
        console.log("total expense called");
        let values = formRef !== null && formRef.current !== undefined && formRef.current !== null && formRef.current.values !== undefined &&
            formRef.current.values !== null && formRef.current.values.expenses !== undefined && formRef.current.values.expenses !== null ?
            formRef.current.values.expenses : [];

        if (Array.isArray(values)) {
            let totalAmount = 0
            for (var i = 0; i < values.length; i++) {
                var amount = values[i]["amount"];
                var frequency = values[i]["frequency"];
                var monthlyAmount = calculateMonthlyAmount(frequency, amount);
                console.log("monthlyAmount", monthlyAmount);
                totalAmount += parseFloat(monthlyAmount);
            }
            setTotalExpense(totalAmount);
        }
        */

    }

    const TotalExpenses = (props) => {
        const {
            values: {
                expenses: [
                    { amount: utilityAmount, frequency: utilityFrequency },
                    { amount: householdAmount, frequency: householdFrequency },
                    { amount: communicaitonAmount, frequency: communicaitonFrequency },
                    { amount: basicAmount, frequency: basicFrequency },
                    { amount: clothingAmount, frequency: clothingFrequency },
                    { amount: personalAndLifeInsuranceAmount, frequency: personalAndLifeInsuranceFrequency },
                    { amount: transportAndTravelAmount, frequency: transportAndTravelFrequency },
                    { amount: homeInsuranceAmount, frequency: homeInsuranceFrequency },
                    { amount: childSupportAmount, frequency: childSupportFrequency },
                    { amount: vehicleInsuranceAmount, frequency: vehicleInsuranceFrequency },
                    { amount: childrenEducationAmount, frequency: childrenEducationFrequency },
                    { amount: additionalVehicleExpensesAmount, frequency: additionalVehicleExpensesFrequency },
                    { amount: privateSchoolFeeAmount, frequency: privateSchoolFeeFrequency },
                    { amount: investmentExpensesAmount, frequency: investmentExpensesFrequency },
                    { amount: educationLoansAmount, frequency: educationLoansFrequency },
                    { amount: otherExpensesAmount, frequency: otherExpensesFrequency },
                    { amount: medicalAmount, frequency: medicalFrequency }
                ]
            },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            let totalAmount = 0;

            let values = formRef !== null && formRef.current !== undefined && formRef.current !== null && formRef.current.values !== undefined &&
                formRef.current.values !== null && formRef.current.values.expenses !== undefined && formRef.current.values.expenses !== null ?
                formRef.current.values.expenses : [];

            if (Array.isArray(values)) {
                for (var i = 0; i < values.length; i++) {
                    var amount = values[i]["amount"];
                    var frequency = values[i]["frequency"];
                    var monthlyAmount = calculateMonthlyAmount(frequency, amount);
                    totalAmount += parseFloat(monthlyAmount);
                }
            }

            setFieldValue(props.name, totalAmount.toFixed(2));
            updateHEMWarning(totalAmount.toFixed(2));

        }, [
            utilityAmount, touched.utilityAmount, utilityFrequency, touched.utilityFrequency,
            householdAmount, touched.householdAmount, householdFrequency, touched.householdFrequency,
            communicaitonAmount, touched.communicaitonAmount, communicaitonFrequency, touched.communicaitonFrequency,
            basicAmount, touched.basicAmount, basicFrequency, touched.basicFrequency,
            clothingAmount, touched.clothingAmount, clothingFrequency, touched.clothingFrequency,
            personalAndLifeInsuranceAmount, touched.personalAndLifeInsuranceAmount, personalAndLifeInsuranceFrequency, touched.personalAndLifeInsuranceFrequency,
            transportAndTravelAmount, touched.transportAndTravelAmount, transportAndTravelFrequency, touched.transportAndTravelFrequency,
            homeInsuranceAmount, touched.homeInsuranceAmount, homeInsuranceFrequency, touched.homeInsuranceFrequency,
            childSupportAmount, touched.childSupportAmount, childSupportFrequency, touched.childSupportFrequency,
            vehicleInsuranceAmount, touched.vehicleInsuranceAmount, vehicleInsuranceFrequency, touched.vehicleInsuranceFrequency,
            childrenEducationAmount, touched.childrenEducationAmount, childrenEducationFrequency, touched.childrenEducationFrequency,
            additionalVehicleExpensesAmount, touched.additionalVehicleExpensesAmount, additionalVehicleExpensesFrequency, touched.additionalVehicleExpensesFrequency,
            privateSchoolFeeAmount, touched.privateSchoolFeeAmount, privateSchoolFeeFrequency, touched.privateSchoolFeeFrequency,
            investmentExpensesAmount, touched.investmentExpensesAmount, investmentExpensesFrequency, touched.investmentExpensesFrequency,
            educationLoansAmount, touched.educationLoansAmount, educationLoansFrequency, touched.educationLoansFrequency,
            otherExpensesAmount, touched.otherExpensesAmount, otherExpensesFrequency, touched.otherExpensesFrequency,
            medicalAmount, touched.medicalAmount, medicalFrequency, touched.medicalFrequency,
            setFieldValue, props.name]);
        return (field.value);
    }


    //IF TOTAL EXPENSE IS LESS THAN HEM BENCHMARK THEN SET WRNING
    const updateHEMWarning = (totalExpenses) => {
        //if(totalAmount < 1800){ setLowExpensesMessage("Please provide a reason why your expenses are low.") }else{setLowExpensesMessage("")}

        let relationshipStatus =  initialValues !== undefined && initialValues.personalDetail !== undefined && initialValues.personalDetail.relationshipStatus !== undefined ? initialValues.personalDetail.relationshipStatus: "";
        let totalDependants=  initialValues !== undefined && initialValues.personalDetail !== undefined && initialValues.personalDetail.totalDependants !== undefined ? initialValues.personalDetail.totalDependants: 0;

        let baseExpenses = 0;
        switch(relationshipStatus){           
            case "married" : baseExpenses = 2500; break;
            case "defacto" : baseExpenses = 2500;break;
            //IF APPLICANT IS NOT MARRIED OR DEFACTO THEN ONE COULD BE SINGLE|DIVORCED|WIDOWED|SEPRATED
            default:  baseExpenses = 1600; break;
        }
        let dependantExpenses = totalDependants * 600;
        let hem = parseInt(baseExpenses) + parseInt(dependantExpenses);
        if(totalExpenses < hem){ setLowExpensesMessage( `The amount calculated for expenses is below HEM/Lender average of ${hem} . Please make relevant notes`); }
        else{ setLowExpensesMessage(""); }
 
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="expensesBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Expenses</h3>
                        <DownArrowIcon />
                    </div>

                    { /** personal infort=mation block start  */}
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            initialValues={{
                                "expenses": initialValues.expenses
                            }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("expenses");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form onChange={TotalExpenseField}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="row">



                                                <div className="col-md-12 mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[0].type]}  </label>

                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[0].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[0].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[0].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>


                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[2].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[2].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[2].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[2].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[4].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[4].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[4].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[4].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[6].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[6].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[6].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[6].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[8].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[8].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[8].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[8].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[10].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[10].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[10].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[10].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[12].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[12].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[12].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[12].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[14].type]}  </label>
                                                    <div className="input-group input-group-solid mb-5">
                                                        <span className="input-group-text" style={{ height: "35px" }} > $ </span>
                                                        <Field name={"expenses[14].amount"} className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                                        <Field name={"expenses[14].frequency"} style={{ width: " 150px" }}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={"expenses[14].frequency"}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={frequencyOptions}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[16].type]}  </label>
                                                    <ComboBox fieldName="expenses[16]" setFieldValue={setFieldValue} />
                                                </div>


                                            </div>

                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="row">
                                                <div className="col-md-12  mb-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[1].type]}  </label>
                                                    <ComboBox fieldName="expenses[1]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[3].type]}  </label>
                                                    <ComboBox fieldName="expenses[3]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[5].type]}  </label>
                                                    <ComboBox fieldName="expenses[5]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[7].type]}  </label>
                                                    <ComboBox fieldName="expenses[7]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[9].type]}  </label>
                                                    <ComboBox fieldName="expenses[9]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[11].type]}  </label>
                                                    <ComboBox fieldName="expenses[11]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[13].type]}  </label>
                                                    <ComboBox fieldName="expenses[13]" setFieldValue={setFieldValue} />
                                                </div>
                                                <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2"> {Constants.EXPENSES_TYPES[initialValues.expenses[15].type]}  </label>
                                                    <ComboBox fieldName="expenses[15]" setFieldValue={setFieldValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4  fv-row fv-plugins-icon-container">
                                            <label className=" fs-8 fw-bold mb-2"> <span style={{ fontSize: '16px' }}>Applicant share of household and other living expenses: $ <TotalExpenses name="totalExpenses" />  </span> </label>
                                            <div><label style={{color: "red" , fontSize: "16px"}}>{lowExpensesMessage} </label></div>
                                        </div>
                                        <div className="col-xl-12">
                                            { userRole !== "auditor" ? <FormSubmissionButton isActive={applicationId != 0 ? true : false} /> : ""}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Expenses;