

import { useEffect, useState } from "react";
import { useToken } from "../../../hooks/useAuth";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, RightDrawer } from '../../HtmlComponents';
import { getApplication, updateApplication, getLendersList, getLender } from "../../../api";
import { useRef } from "react";
import { formatDate } from "../../../helpers";
import Notifications from "../../global/Notifications";
import LenderSelectionShema from '../schema/LenderSelectionShema.json';

const LenderSelection = ({ applicationId, callback }) => {

    const [application, setApplication] = useState(LenderSelectionShema);
    const [processing, setProcessing] = useState(false);
    const [lenders, setLenders] = useState([]);
    const [selectedLenderName, setSelectedLenderName] = useState("");
    const formRef = useRef();
    const token = useToken();

    //FETCHING APPLICATION FROM SERVER 
    const fetchApplication = async () => {
        const response = await getApplication(applicationId, token);
        if (response["httpCode"]) {
            if (response["httpCode"] == 200) {
                var quoteData = response.data;
                delete quoteData.applicants;
                setApplication(response.data);
                setProcessing(false);
            }
            else { setProcessing(false); }
        }
    }

    //CALL LENDER TO LOAD DEFAULT FEE BASED ON THE SELECTED CRITERIA - IT LETS SAVE/ASSIGN CUSTOM VALUE TO FEES + CHARGES
    const lenderFeesHandler = (lenderId, loanType, callFrom, setFieldValue) => {
        var refValues = formRef.current.values;
        if (refValues !== undefined && refValues !== null) {

            var tmpLenderId = refValues["lenderId"];
            var tmpLoanType = refValues["type"];

            switch (callFrom) {
                case "lenderUpdated":
                    fetchSingleLender(lenderId, tmpLoanType, setFieldValue)
                    break;
                case "loanTypeUpdated":
                    fetchSingleLender(tmpLenderId, loanType, setFieldValue)
                    break;
            }

        }
    }

    //LOAD SINGLE LENDER AND LOAD THE LENDERS FESS AND OTHER CHARGES INTO QUOTE UPON LENDER CHANGE
    const fetchSingleLender = async (id, type, setFieldValue) => {
        if (id != 0) {
            var response = await getLender(id, token);
            setSelectedLenderName(response["name"] !== undefined ? response["name"] : "");    //UPDATE SELECTED LENDER NAME TO PASS IT BACK TO THE UPDATE PROGRESS STATUS TAB

            if (response["feeStructure"] !== undefined) {
                var feeStructure = response["feeStructure"];
                var commercialFee = feeStructure["commercialFee"] !== undefined ? feeStructure["commercialFee"] : 0;
                var consumerFee = feeStructure["consumerFee"] !== undefined ? feeStructure["consumerFee"] : 0;
                var creditSearchFee = feeStructure["creditSearchFee"] !== undefined ? feeStructure["creditSearchFee"] : 0;
                var monthlyFee = feeStructure["monthlyFee"] !== undefined ? feeStructure["monthlyFee"] : 0;

                //CHECK IF INITIAL VALUES LENDER AND THIS LENDER IS SAME THEN DO NOT LOAD THE VALUES
                setFieldValue("financial.lenderFee", type == "commercial" ? commercialFee : consumerFee);
            }
            else { setFieldValue("financial.lenderFee", 0); }
        }
    }

    async function fetchLendersList() {
        const response = await getLendersList(token);

        if (response["error"] === 0 && response["lenders"]) {
            var lenders = response["lenders"];
            var lendersList = [];
            lendersList.push({ "value": 0, "label": 'Select Lender' }); //FIRST OPTION SO USER CAN DESELECT THE LEDER IF REQUIRED(INSURANC ONLY CASE)

            if (lenders !== undefined && Array.isArray(lenders)) {
                lenders.map(singleLender => { lendersList.push({ "value": singleLender["id"], "label": singleLender["name"] }) })
                setLenders(lendersList)
            }
        }
        else {
            //ERROR OCCURED
        }

    }

    useEffect(() => {
        fetchApplication();
        fetchLendersList();

    }, []);


    /**************************************** OBSERVERS (TO MONITOR AND UPDATE TOTAL FINANCED AMOUNT ) START *********************************** */

    /***** INCLUDING INSURNCE AMOUNT EXLUDING INSURANCE AMOUNT *****/
    const TotalFinancedAmountExcludingInsurances = (props) => {

        const {
            values: { financial: {
                assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee,
                tradeIn: { amount, isTradingIn, finance: { financed, financier: { amountOutstanding } } }
            } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = assetPrice !== undefined && (!isNaN(parseFloat(assetPrice))) ? parseFloat(assetPrice) : 0;
            totalAmount -= cashDeposit !== undefined && (!isNaN(parseFloat(cashDeposit))) ? parseFloat(cashDeposit) : 0;
            totalAmount += lenderFee !== undefined && (!isNaN(parseFloat(lenderFee))) ? parseFloat(lenderFee) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            totalAmount += lenderRiskFee !== undefined && (!isNaN(parseFloat(lenderRiskFee))) ? parseFloat(lenderRiskFee) : 0;
            totalAmount += lenderEqualisationFee !== undefined && (!isNaN(parseFloat(lenderEqualisationFee))) ? parseFloat(lenderEqualisationFee) : 0;
            totalAmount += lenderPrivateSaleAdminFee !== undefined && (!isNaN(parseFloat(lenderPrivateSaleAdminFee))) ? parseFloat(lenderPrivateSaleAdminFee) : 0;
            totalAmount -= amount !== undefined && (!isNaN(parseFloat(amount))) && isTradingIn == true ? parseFloat(amount) : 0;
            totalAmount += amountOutstanding !== undefined && (!isNaN(parseFloat(amountOutstanding))) && isTradingIn == true && financed == true ? parseFloat(amountOutstanding) : 0;

            setFieldValue(props.name, totalAmount.toFixed(2));

        }, [assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee, amount, isTradingIn, financed, amountOutstanding,
            touched.assetPrice, touched.cashDeposit, touched.lenderFee, touched.originationFee, touched.brokerageFee, touched.lenderRiskFee, touched.lenderEqualisationFee, touched.lenderPrivateSaleAdminFee, touched.amount, touched.isTradingIn,
            touched.financed, touched.amountOutstanding, setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };

    /***** INCLUDING INSURNCE AMOUNT INCLUDING INSURANCE AMOUNT *****/
    const TotalFinancedAmountIncludingInsurances = (props) => {
        var refValues = formRef !== undefined && formRef.current !== undefined && formRef.current.values !== undefined ? formRef.current.values: undefined;
        var totalInsuranceGrossPremium = 0;  //ADD ONLY THOSE INSURANCES WHERE 
        if (refValues !== undefined && refValues !== null) {
            var insurances = refValues.insurances;
            console.log("insurance:::: ", insurances);

            if(Array.isArray(insurances)){
                insurances.map( singleInsurance => {
                    let extraDetail = singleInsurance["extraDetail"] !== undefined ? singleInsurance["extraDetail"]: {};;
                    let isFinanced = extraDetail["isFinanced"] !== undefined ? extraDetail["isFinanced"]: false;
                    
                    let grossPremium = isFinanced && singleInsurance["grossPremium"] !== undefined && !isNaN(parseFloat(singleInsurance["grossPremium"] )) ? singleInsurance["grossPremium"] : 0;
                    totalInsuranceGrossPremium = parseFloat(totalInsuranceGrossPremium) + parseFloat(grossPremium);
                })
            }
        }
        const {
            values: {
                financial: {
                    assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee,
                    tradeIn: { amount, isTradingIn, finance: { financed, financier: { amountOutstanding } } }
                }
            },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = assetPrice !== undefined && (!isNaN(parseFloat(assetPrice))) ? parseFloat(assetPrice) : 0;
            totalAmount -= cashDeposit !== undefined && (!isNaN(parseFloat(cashDeposit))) ? parseFloat(cashDeposit) : 0;
            totalAmount += lenderFee !== undefined && (!isNaN(parseFloat(lenderFee))) ? parseFloat(lenderFee) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            totalAmount += lenderRiskFee !== undefined && (!isNaN(parseFloat(lenderRiskFee))) ? parseFloat(lenderRiskFee) : 0;
            totalAmount += lenderEqualisationFee !== undefined && (!isNaN(parseFloat(lenderEqualisationFee))) ? parseFloat(lenderEqualisationFee) : 0;
            totalAmount += lenderPrivateSaleAdminFee !== undefined && (!isNaN(parseFloat(lenderPrivateSaleAdminFee))) ? parseFloat(lenderPrivateSaleAdminFee) : 0;
            totalAmount -= amount !== undefined && (!isNaN(parseFloat(amount))) && isTradingIn == true ? parseFloat(amount) : 0;
            totalAmount += amountOutstanding !== undefined && (!isNaN(parseFloat(amountOutstanding))) && isTradingIn == true && financed == true ? parseFloat(amountOutstanding) : 0;

            setFieldValue(props.name, (totalAmount + parseFloat(totalInsuranceGrossPremium)).toFixed(2));


        }, [assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee, amount, isTradingIn, financed, amountOutstanding,
            touched.assetPrice, touched.cashDeposit, touched.lenderFee, touched.originationFee, touched.brokerageFee, touched.lenderRiskFee, touched.lenderEqualisationFee, touched.lenderPrivateSaleAdminFee, touched.amount, touched.isTradingIn,
            touched.financed, touched.amountOutstanding,
            setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    }

    //REPAYMENT CALCULATION 
    const RepaymentObserve = (props) => {
        const {
            values: { loan: { totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloon: { balloonAmount } }, repayments: { repaymentType } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            calculateRepayments(setFieldValue);

        }, [totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloonAmount, repaymentType,
            touched.totalFinancedAmountIncludingInsurances, touched.totalFinancedAmountExcludingInsurances, touched.loanTerm, touched.interestRate, touched.balloonAmount, touched.repaymentType, setFieldValue, props.name]);
        return ("");

    }
    const calculateRepayments = async (setFieldValue) => {
        var currentValues = formRef !== undefined && formRef.current !== undefined && formRef.current !== null && formRef.current.values !== undefined && formRef.current.values !== null ? formRef.current.values : {}
        let loan = currentValues !== undefined && currentValues.loan !== undefined ? currentValues.loan : {};

        let totalFinancedAmountExcludingInsurances = currentValues.loan !== undefined && loan.totalFinancedAmountExcludingInsurances !== undefined ? loan.totalFinancedAmountExcludingInsurances : 0;
        let totalFinancedAmountIncludingInsurances = loan !== undefined && loan.totalFinancedAmountIncludingInsurances !== undefined ? loan.totalFinancedAmountIncludingInsurances : 0;
        let interestRate = loan !== undefined && loan.interestRate !== undefined ? loan.interestRate : 0;
        interestRate = interestRate / 100;
        interestRate = interestRate / 12;

        let loanTerm = loan !== undefined && loan.loanTerm !== undefined ? loan.loanTerm : 0;
        let balloonAmount = loan !== undefined && loan.balloon !== undefined && loan.balloon.balloonAmount !== undefined ? loan.balloon.balloonAmount : 0;
        let repaymentType = currentValues !== undefined && currentValues.repayments !== undefined && currentValues.repayments.repaymentType ? currentValues.repayments.repaymentType : "";

        //CALCULATE PAYMENT - WITH INSURANCE
        let monthlyRepaymentIncludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountIncludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.includingInsurance.weekly", ((monthlyRepaymentIncludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.includingInsurance.fortnightly", ((monthlyRepaymentIncludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.includingInsurance.monthly", (monthlyRepaymentIncludingInsurance).toFixed(2))


        //CALCULATE PAYMENT WITHOUT INSURANCES
        let monthlyRepaymentExcludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountExcludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.excludingInsurance.weekly", ((monthlyRepaymentExcludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.excludingInsurance.fortnightly", ((monthlyRepaymentExcludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.excludingInsurance.monthly", (monthlyRepaymentExcludingInsurance).toFixed(2))


    }
    const PMT = async (interestRate, loanTerm, present_value, balloonAmount, repaymentType) => {
        try {
            var when = repaymentType == "arrears" ? 0 : 1; 
            if (interestRate == 0) { return - (parseFloat(balloonAmount) + parseFloat(present_value)) / loanTerm; }
            else { return - (parseFloat(balloonAmount) + parseFloat((present_value * Math.pow((1 + parseFloat(interestRate)), loanTerm)))) / ((1 + parseFloat(interestRate * when)) / interestRate * (Math.pow((1 + parseFloat(interestRate)), loanTerm) - 1)); }
        }
        catch (e) { return 0; }
    }

    /**************************************** OBSERVERS (TO MONITOR AND UPDATE TOTAL FINANCED AMOUNT ) END *********************************** */

    return (
        <>
            <Formik
                initialValues={application}
                innerRef={formRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    console.log("values:::::::::::::::  ", values);
                    var tempValues = JSON.parse(JSON.stringify(values));
                    var activeInsurances = tempValues["insurances"] !== undefined && Array.isArray(tempValues["insurances"]) ? tempValues["insurances"] : [];

                    if (Array.isArray(activeInsurances)) {
                        var modifiedInsurances = activeInsurances.map((singleInsurance) => {
                            singleInsurance["expiryDate"] = singleInsurance["expiryDate"] !== 0 ? formatDate(singleInsurance["expiryDate"], "unix", 'YYYY-MM-DD') : singleInsurance["expiryDate"];
                            return singleInsurance;
                        })
                        tempValues["insurances"] = modifiedInsurances;
                    }

                    var response = await updateApplication(applicationId, tempValues, token);
                    if (response.httpCode == 200) {
                        Notifications("success", response.message || "Updated Successfully.");

                        //CHECK IF LENDER NAME STATE IS EMPTY THEN CHECK LENDERs list against id AND FIND THE NAME FROM LENDER-LIST 
                        var tmpSelectdLenderName = '';
                        if(selectedLenderName == ""){
                            var  selectedLenderId = values.lenderId;   
                            lenders.map(singleLenderOption => {
                                tmpSelectdLenderName =   singleLenderOption.value == selectedLenderId ? singleLenderOption.label : tmpSelectdLenderName;
                            });
                        }
                        callback("openCreditProposal", { lenderName: selectedLenderName !== "" ? selectedLenderName : tmpSelectdLenderName  });
                    }
                    else {
                        Notifications("error", response.message || "Error")
                    }
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>

                        <div className="row mb-5">
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender</label>
                                <Field name="lenderId">
                                    {({ field }) => (
                                        <SelectField
                                            fieldName="lenderId"
                                            defaultSelectedOption={{ value: field.value, label: '' }}
                                            options={lenders}
                                            setFieldValue={setFieldValue}
                                            callback={
                                                (name, value, setFieldValue) => {
                                                    lenderFeesHandler(value, null, "lenderUpdated", setFieldValue);
                                                }
                                            }
                                        />
                                    )}
                                </Field>
                                { /*<LenderObserver name="lenderValueObserver" /> */}
                            </div>

                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender Fees + Charges</label>
                                <Field name="financial.lenderFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.lenderFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Origination Fee</label>
                                <Field name="financial.originationFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.originationFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            listName="originationFeeList"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                <datalist id="originationFeeList">
                                    <option key="origination0" value="770" />
                                    <option key="origination1" value="990" />
                                </datalist>
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Brokerage Fee</label>
                                <Field name="financial.brokerageFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.brokerageFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender Risk Fee</label>
                                <Field name="financial.lenderRiskFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.lenderRiskFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender Equalisation Fee</label>
                                <Field name="financial.lenderEqualisationFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.lenderEqualisationFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender Private Sale Admin Fee</label>
                                <Field name="financial.lenderPrivateSaleAdminFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financial.lenderPrivateSaleAdminFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Total Financed Amount (excl Insurances)</label>
                                <TotalFinancedAmountExcludingInsurances name="loan.totalFinancedAmountExcludingInsurances" />
                            </div>
                            
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Total Financed Amount (incl Insurances)</label>
                                <TotalFinancedAmountIncludingInsurances name="loan.totalFinancedAmountIncludingInsurances" />
                            </div>
                        </div>
                        <div className="col-xl-12">
                            {<FormSubmissionButton isActive={true} />}
                        </div>
                        {/** row end **/}
                    </Form>
                )}
            </Formik>
            {
                //JSON.stringify(application)
            }
        </>
    )


}

export default LenderSelection;
