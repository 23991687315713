import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton, RightDrawer, TextArea } from '../../../HtmlComponents';
import { formatDate, getCurrentDate } from '../../../../helpers';
import Notifications from "../../../global/Notifications";
import SelectFieldGroup from '../../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getLendersList, getLender, getComments, getFolders, getFiles, } from "../../../../api";
import { useToken } from '../../../../hooks/useAuth';
import LoadingScreen from '../../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../../schema/SubmitToLenderValidationSchema';


const Wisr = ({ applicationId }) => {
    const [isQuoteSelected, setIsQuoteSelected] = useState(true);
    const [quotesOptions, setQuotesOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({ lender: "wisr", productType: "", frequency: "", repaymentAmount: "", paymentMethod: "" });
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const [loanId, setLoanId] = useState(0);

    //WISR OPTIONS
    const wisrOptions = {
        "otherDetail": "",
        requestSecuredRate: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        ownsInvestmentProperty: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        investmentPropertyMortgaged: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        bankStatementPreference: [{ value: "", label: "Select" }, { value: "credit-sense", label: "Credit Sense (An email with instructions will be sent to the customer immediately after loan submission is complete and the application is not automatically declined.)" }, { value: "illion", label: "Illion (Wisr will follow-up analyst|broker for illion document id or you can provide using the below field)" }, { value: "pdf", label: "PDF" }],
        useESignatureContract: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        hasSignificantPlannedChanges: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        expenseIncreaseShortTerm: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        expenseIncreaseLongTerm: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        incomeDecreaseShortTerm: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        incomeDecreaseLongTerm: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
    }

    const token = useToken();
    const formRef = useRef();



    const handler = (name, value) => {
        switch (name) {

        }
    }

    const ApplicationFields = ({ errors, setFieldValue }) => {
        return (
            <>
                <div className="col-sm-12"><p><b>Select Indicative Quote to submit the application</b></p></div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">

                    <Field name="indicativeQuoteId">
                        {({ field }) => (
                            <RadioGroup
                                fieldName="indicativeQuoteId"
                                defaultSelectedOption={field.value}
                                options={quotesOptions}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Owns Investment Property</label>
                    <Field name="ownsInvestmentProperty">
                        {({ field }) => (
                            <SelectField
                                fieldName="ownsInvestmentProperty"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["ownsInvestmentProperty"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Investment Property Mortgaged</label>
                    <Field name="investmentPropertyMortgaged">
                        {({ field }) => (
                            <SelectField
                                fieldName="investmentPropertyMortgaged"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["investmentPropertyMortgaged"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Bank Statement Preference</label>
                    <Field name="bankStatementPreference">
                        {({ field }) => (
                            <SelectField
                                fieldName="bankStatementPreference"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["bankStatementPreference"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Illion Document Id</label>
                    <Field name="illionDocumentId" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Whether the broker wishes to use eSign contracts (automatically sent to the customer on loan approval) or PDF contracts (sent only to the broker on loan approval).</label>
                    <Field name="useESignatureContract">
                        {({ field }) => (
                            <SelectField
                                fieldName="useESignatureContract"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["useESignatureContract"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Has Significant Planned Changes</label>
                    <Field name="hasSignificantPlannedChanges">
                        {({ field }) => (
                            <SelectField
                                fieldName="hasSignificantPlannedChanges"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["hasSignificantPlannedChanges"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>


                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Expense Increase Short Term (Indicates that the significant planned changes are a short-term increase in expenses. Examples may include a medical procedure, housing renovations, a wedding, etc.)</label>
                    <Field name="expenseIncreaseShortTerm">
                        {({ field }) => (
                            <SelectField
                                fieldName="expenseIncreaseShortTerm"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["expenseIncreaseShortTerm"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Expense Increase Long Term (Indicates that the significant planned changes are a long-term increase in expenses. Examples may include having a child, etc.)</label>
                    <Field name="expenseIncreaseLongTerm">
                        {({ field }) => (
                            <SelectField
                                fieldName="expenseIncreaseLongTerm"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["expenseIncreaseLongTerm"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Income Decrease Short Term (Indicates that the significant planned changes are a short-term decrease in income. Examples may include having a child, taking a career break, etc.)</label>
                    <Field name="incomeDecreaseShortTerm">
                        {({ field }) => (
                            <SelectField
                                fieldName="incomeDecreaseShortTerm"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["incomeDecreaseShortTerm"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Income Decrease Long Term(Indicates that the significant planned changes are a long-term decrease in income. Examples may include retirement, etc.)</label>
                    <Field name="incomeDecreaseLongTerm">
                        {({ field }) => (
                            <SelectField
                                fieldName="incomeDecreaseLongTerm"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["incomeDecreaseLongTerm"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>


                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Submission Method</label>
                    <Field name="submissionMethod">
                        {({ field }) => (
                            <SelectField
                                fieldName="submissionMethod"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={[{ value: "saveAsDraft", label: "Save as Draft" }, { value: "sendApplication", label: "Send Application" }]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                    <p className="mt-3" style={{ marginTop: "10px" }}>It is better if you save it as a draft first, log-in to your wisr account, double check the data and then submit from here</p>
                </div>


            </>

        )

    }

    const QuoteFields = ({ errors, setFieldValue }) => {
        return (
            <>
                <div className="col-sm-12"><p><b>Create draft first</b></p></div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Other Detail</label>
                    <Field name="otherDetail">
                        {({ field }) => (
                            <TextArea
                                fieldName="otherDetail"
                                fieldValue={field.value}
                                placeholder={""}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Loan Additional Notes</label>
                    <Field name="loanAdditionalNotes">
                        {({ field }) => (
                            <TextArea
                                fieldName="loanAdditionalNotes"
                                fieldValue={field.value}
                                placeholder={""}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Loan Requirements and Objectives</label>
                    <Field name="loanRequirementsAndObjectives">
                        {({ field }) => (
                            <TextArea
                                fieldName="loanRequirementsAndObjectives"
                                fieldValue={field.value}
                                placeholder={""}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Request Secured Rate</label>
                    <Field name="requestSecuredRate">
                        {({ field }) => (
                            <SelectField
                                fieldName="requestSecuredRate"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={wisrOptions["requestSecuredRate"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
            </>
        )
    }

    const updateWisrIndicativeQuotes = (data) => {
        let indicativeQuotesArray = data["indicativeQuotes"] != undefined ? data["indicativeQuotes"] : [];
        let loanId = data["loanId"] != undefined ? data["loanId"] : undefined;
        let type = data["type"] != undefined ? data["type"] : undefined;
        let description = data["description"] != undefined ? data["description"] : null;
        let wisrApplicationId   = data["wisrApplicationId"] != undefined ? data["wisrApplicationId"] : null;
        setMessage(description);
        let indicativeQuotesOptionArray = [];


        if (Array.isArray(indicativeQuotesArray) && indicativeQuotesArray.length > 0) {

            indicativeQuotesArray.map(singleQuote => {

                let isEligible = singleQuote["isEligible"] !== undefined && singleQuote["isEligible"] == true ? "Yes" : "No";
                let term = singleQuote["term"] !== undefined ? singleQuote["term"] : "NA";
                let commissionAmount = singleQuote["commissions"] !== undefined && singleQuote["commissions"]["commissionAmount"] !== undefined ? singleQuote["commissions"]["commissionAmount"] : "NA";
                let commissionPercent = singleQuote["commissions"] !== undefined && singleQuote["commissions"]["commissionPercent"] !== undefined ? singleQuote["commissions"]["commissionPercent"] : "NA";

                indicativeQuotesOptionArray.push({
                    value: singleQuote["id"] !== undefined ? singleQuote["id"] : "",
                    label: `Is eligible: ${isEligible} - Term : ${term}  - Commission Amount: ${commissionAmount}  - Commission Percent: ${commissionPercent}`
                });
            })

            setLoanId(loanId);
            setQuotesOptions(indicativeQuotesOptionArray);
            Notifications("success", "The data has been successfully fetched from Wisr. Please select the 'Submit Application' from service dropdown and then select most suitable quote and submit the application to Wisr.  "); 

        }
        else if(Array.isArray(indicativeQuotesArray) && indicativeQuotesArray.length  == 0 && wisrApplicationId !== null){
            Notifications("success", "Quote request has been submitted to Wisr. Wisr is processing the data, please wait for 5-10 seconds and submit the quote request again.");
        }

        //"indicativeQuotes"
    }


    return (
        <>
            {<Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={SubmitToLenderValidationSchema}
                innerRef={formRef}
                validateOnBlur={false}

                onSubmit={async (values) => {
                    //MAKE AN API CALL 
                    setProcessing(true);
                    let valuesColne = JSON.parse(JSON.stringify(values));
                    console.log("valuesColne", valuesColne)



                    var response = await performApplicationAdditionalActions(token, "submitToLender", applicationId, valuesColne);
                    console.log("   response    LINE 638 ---  ------------------  ", response);
                    if (response["error"]) {
                        var errors = response["message"]["errors"];
                        var warning = response["message"]["warning"];

                        try { 
                            setMessage(errors.join("\n")); 
                            Notifications("error", errors.join("\n"))
                        }
                        catch (e) { console.log("EXCEPTION ", e); setMessage(JSON.stringify(response["message"])); }

                    }
                    else {
                        var responseResult = response.data !== undefined ? response.data : {};
                        var message = responseResult.message !== undefined ? responseResult.message : "";
                        var payload = responseResult.payload !== undefined ? responseResult.payload : {};
                        var applicationNo = payload.application !== undefined && payload.application.applicationNo !== undefined ? " #: " + payload.application.applicationNo : "";
                        Notifications("success", message)
                        setMessage("");
                        //UPDATE LOAN ID AND OTHER DATA THAT IS RETURNED BY QUOTE API
                        updateWisrIndicativeQuotes(responseResult);
                    }
                    setProcessing(false);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div className="row">

                            <div className="col-sm-12 error mb-5" style={{ whiteSpace: "pre-wrap" }}> {message} </div>

                            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Select Service</label>
                                <Field name="service">
                                    {({ field }) => (
                                        <SelectField
                                            fieldName="service"
                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                            options={[{ value: "quote", label: "Get Quote" }, { value: "submitApplication", label: "Submit Application" }]}
                                            setFieldValue={setFieldValue}
                                            callback={handler}
                                        />
                                    )}
                                </Field>
                                {errors.service !== undefined ? <div className="error"> {errors.service} </div> : ""}
                            </div>
                            {values.service === "quote" ? <QuoteFields errors={errors} setFieldValue={setFieldValue} /> : ""}
                            {values.service === "submitApplication" ? <ApplicationFields errors={errors} setFieldValue={setFieldValue} />: ""}
                            
                             
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="col-xl-12">
                            {<FormSubmissionButton isActive={true} label={values.service === "quote" ? "Get Quote" : "Submit To Lender"} />}
                        </div>
                    </Form>

                )}

            </Formik>
            }

            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )


}

export default Wisr;