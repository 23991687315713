module.exports = {

    "reportForOptions": [
        { value: "allNFAL", label: "All NFAL", status: "active" },
        { value: "referrerGroup", label: "Referrer Group", status: "active" },
        { value: "referrer", label: "Referrer", status: "active" },
        { value: "individualReferrer", label: "Individual Referrer/Staff", status: "active" },
        { value: "allBrokersCombinedListed", label: "All Brokers Combined & Listed", status: "active" },
        { value: "creditReps", label: "Credit Reps", status: "active" },
        { value: "state", label: "State (Vic, NSW Etc.)", status: "active" }
    ],


    "period": [
        { value: "currentDay", label: "Current day", status: "active" },
        { value: "currentWeekToDate", label: "Current Week to date", status: "active" },
        { value: "currentMonthToDate", label: "Current Month to date", status: "active" },
        { value: "previousMonth", label: "Previous Month", status: "active" },
        { value: "lastQuarter", label: "Last Quarter", status: "active" },
        { value: "lastSixMonth", label: "Last Six Month", status: "active" },
        { value: "lastTwelveMonths", label: "Last Twelve Months", status: "active" },
        { value: "currentYearToDate", label: "Current year to date", status: "active" },
        { value: "userDefinedPeriod", label: "User Defined Period", status: "active" }
    ],

    "periodPeriodicType": {
        "currentDay": "daily",
        "currentWeekToDate": "daily",
        "currentMonthToDate": "daily",
        "previousMonth": "daily",
        "lastQuarter": "monthly",
        "lastSixMonth": "monthly",
        "currentYearToDate": "monthly",
        "userDefinedPeriod": "monthly",
    },

    "comparePeriod": [
        { value: "none", label: "None", status: "active" },
        { value: "previousMonth", label: "Previous Month", status: "active" },
        { value: "sameMonthLastYear", label: "Same(Current) Month last year", status: "active" },
        { value: "samePeriodLastYear", label: "Same Period Last year", status: "active" },
        { value: "monthByMonth", label: "Month by Month", status: "inActive" },
        { value: "userDefinedPeriod", label: "User Defined Period", status: "active" }
    ],

    stats: [
        { value: "totalReferralsReceivedAscendingOrderBasedOnNumbers", label: "Total Referrals Received", status: "active" },
        { value: "currentApprovedReferralsAccurateBasedOnReceivedDatePercentage", label: "Current Approved Referrals Accurate  based on received date %", status: "active" },
        { value: "currentSettledReferralsAcurateBasedOnReceivedDatePercentage", label: "Current Settled referrals Acurate  based on received date %", status: "active" },
        { value: "currentDeclinedReferralsAccurateBasedOnReceivedDatePercentage", label: "Current Declined Referrals Accurate based on received date %", status: "active" },
        { value: "currentApprovedReferrals", label: "Current Approved Referrals", status: "active" },
        { value: "currentSettledReferrals", label: "Current Settled referrals", status: "active" },
        { value: "currentDeclinedReferrals", label: "Current Declined Referrals", status: "active" },
        { value: "activeReferrals", label: "Active Referrals", status: "active" },
        { value: "settlementRateApplicationsApprovedToSettlementPercentage", label: "Settlement Rate - Applications Approved to Settlement %", status: "active" },
        { value: "didNotProceedApplicationsPercentage", label: "Did not proceed - applications received to did not proceed %", status: "active" },
        { value: "statsForAllIndividualReasonsForDeclinedPercentage", label: "Stats for all individual reasons for declined", status: "active" },
        { value: "daysToDecision", label: "Days to decision i.e. approved or unable to assist From received date",  status: "active" },
        { value: "naf", label: "NAF", status: "active" },
        { value: "averageNAF", label: "Average NAF", status: "active" },
        { value: "averageGrossIncomePerSettlement", label: "Average Gross Income Per settlement", status: "active" },
        { value: "grossIncome", label: "Gross Income", status: "active" },
        { value: "nettIncome", label: "Nett Income", status: "active" },
        { value: "averageNettIncomePerSettlement", label: "Average Nett Income per settlement", status: "active" },
        { value: "dealerCommission", label: "Dealer Commission", status: "active" },
        { value: "averageDealerCommissionPerDeal", label: "Average Dealer Comms per deal", status: "active" },
        { value: "dealerCommissionAsPercentageOfGrossIncome", label: "Dealer comms as percentage of gross income", status: "active" },
        { value: "insuranceIncomeTotal", label: "Insurance Income Total", status: "active" },
        { value: "insuranceIncomeComprehensive", label: "Insurance Income Motor | Comprehensive", status: "active" },
        { value: "insuranceIncomeWarranty", label: "Insurance Income Warranty", status: "active" },
        { value: "insuranceIncomeOther", label: "Insurance Income Other", status: "active" },
        { value: "commercialLoansPercentage", label: "Commercial Loans %", status: "active" },
        { value: "consumerLoansPercentage", label: "Consumer Loans %", status: "active" },
        { value: "individualLoanPurpose", label: "Individual Loan Purpose", status: "active" },
    ],

    STATES: [
        { value: "vic", label: "Victoria" },
        { value: "nsw", label: "New South Wales" },
        { value: "sa", label: "South Australia" },
        { value: "qld", label: "Queensland" },
        { value: "act", label: "Australian Capital Territory" },
        { value: "tas", label: "Tasmania" },
        { value: "nt", label: "Northern Territory" },
        { value: "wa", label: "Western Australia" }
    ],



    //DATA AGAINST EACH STAT - ONLY LOADED FOR THOSE WHERE DATA IS REQUIRED
    STAT_OPTIONS : {
        statsForAllIndividualReasonsForDeclinedPercentage: [
            { value: "adverseCreditFile", label: "Adverse Credit File" },
            { value: "poorCurrentOrPreviousLoanHistory", label: "Poor / Slow Current or Previous Loan History" },
            { value: "bankAccountConduct", label: "Bank Account Conduct (Dishonours etc)" },
            { value: "capacity", label: "Capacity / Serviceability" },
            { value: "employmentStatus", label: "Employment Status" },
            { value: "residencyStatus", label: "Residency Status (Visa / PR etc)" },
            { value: "assetUnacceptable", label: "Asset Unacceptable" },
            { value: "referralWithdrawnByCustomer", label: "Referral Withdrawn by Customer" },
            { value: "referralWithdrawnByReferrer", label: "Referral Withdrawn by Referrer" },
            { value: "referralNotResponding", label: "Referral Not Responding / Referral Filed" },
            { value: "other", label: "Other" }
        ],
        individualLoanPurpose: [
            { value: "carDealership", label: "Car - Dealership" },
            { value: "carPrivate", label: "Car - Private" },
            { value: "caravanTrailer", label: "Caravan / Trailer" },
            { value: "lineOfCredit", label: "Line of Credit" },
            { value: "marine", label: "Marine" },
            { value: "motorcycle", label: "Motorcycle" },
            { value: "personalLoan", label: "Personal Loan" },
            { value: "insuranceOnly", label: "Insurance Only" },
            { value: "equipment", label: "Equipment loans" },
            { value: "farmMachinery", label: "Farm Machinery" },
            { value: "earthMovingHaulage", label: "Earth Moving Haulage" },
            { value: "truck", label: "Truck" },
            { value: "goKart", label: "Go Kart" },
            { value: "debtConsolidation", label: "Debt Consolidation" },
            { value: "refinance", label: "Refinance" },
            { value: "plantandEquipment", label: "Plant and Equipment" },
            { value: "other", label: "Other" }
        ]

    },
    //ADD STATS THAT SUPPORT PIE CHART
    PIE_CHART_SUPPORTED_STATS:["statsForAllIndividualReasonsForDeclinedPercentage" , "individualLoanPurpose"]

}