import { useState } from "react";
import { FormSubmissionButton, SelectField, MaskedField, RadioGroup, InputField, SelectDatePicker, CheckBox } from '../../HtmlComponents/';
import moment from "moment";

const YTD = () => {
    const [payload, setPayload] = useState({ startDate: '', endDate: '', amount: '' })
    const [calculatedPayload, setCalculatedPayload] = useState({});

    const handler = () => {
        var totaldays = 0;
        var start = moment(payload.startDate, "DD/MM/YYYY");
        var end = moment(payload.endDate, "DD/MM/YYYY");

        let days = moment.duration(end.diff(start) ).asDays().toFixed(0); //Difference in number of days
        let weeks = moment.duration(end.diff(start)).asWeeks().toFixed(0);  //Difference in number of weeks
        var per_day = payload.amount/days;

        setCalculatedPayload({
            days:days,
            weeks:weeks,
            weeklyNet: (per_day*7).toFixed(2),
            monthlyNet: ((per_day*7*52)/12).toFixed(2),
            annualNet : (per_day*365).toFixed(2)
        })
    }
    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="row">

                        <div className="col-sm-3">
                            <label className="fs-8 fw-bold mb-2">Start Date</label>
                            <MaskedField
                                fieldName="startDate"
                                fieldValue={payload.startDate}
                                fieldPlaceholder="dd/mm/yyyy"
                                maskRule="date"
                                setFieldValue={(name, value) => {
                                    setPayload({ ...payload, startDate: value })
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label className="fs-8 fw-bold mb-2">End Date</label>
                            <MaskedField
                                fieldName="endDate"
                                fieldValue={payload.endDate}
                                fieldPlaceholder="dd/mm/yyyy"
                                maskRule="date"
                                setFieldValue={(name, value) => {
                                    setPayload({ ...payload, endDate: value })
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label className="fs-8 fw-bold mb-2">YTD</label>
                            <InputField
                                fieldName="amount"
                                placeholder="0"
                                fieldValue={payload.amount}
                                setFieldValue={(name, value) => {
                                    setPayload({ ...payload, amount: value })
                                }} />
                        </div>

                        <div className="col-sm-2">
                            <button type="button" className="btn btn-sm btn-primary btn-hover-rise" style={{ float: "right", marginTop: "24px" }} onClick={handler} > Calculate</button>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <div className="form-group row">
                                <div className="col-6">
                                    <label className="label text-center" style={{ backgroundColor: "navajowhite", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>Days</label>
                                    <p className="label-value text-center" style={{ backgroundColor: "#8ed2d2", color: "white", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>{calculatedPayload["days"] !== undefined ? calculatedPayload["days"]: 0 } days</p>
                                </div>
                                <div className="col-6">
                                    <label className="label  text-center" style={{ backgroundColor: "navajowhite", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>Weeks</label>
                                    <p className="label-value text-center" style={{ backgroundColor: "#8ed2d2", color: "white", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>{calculatedPayload["weeks"] !== undefined ? calculatedPayload["weeks"]: 0 } weeks</p>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-4">
                                    <label className="label text-center" style={{ backgroundColor: "navajowhite", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>Weekly Net</label>
                                    <p className="label-value text-center" style={{ backgroundColor: "#8ed2d2", color: "white", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>{calculatedPayload["weeklyNet"] !== undefined ? calculatedPayload["weeklyNet"]: 0 }</p>
                                </div>
                                <div className="col-4">
                                    <label className="label text-center" style={{ backgroundColor: "navajowhite", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>Monthly Net</label>
                                    <p className="label-value text-center" style={{ backgroundColor: "#8ed2d2", color: "white", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>{calculatedPayload["monthlyNet"] !== undefined ? calculatedPayload["monthlyNet"]: 0 }</p>
                                </div>
                                <div className="col-4">
                                    <label className="label text-center" style={{ backgroundColor: "navajowhite", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>Annual Net</label>
                                    <p className="label-value text-center" style={{ backgroundColor: "#8ed2d2", color: "white", paddingTop: "10px", paddingBottom: "10px", width: "100%" }}>{calculatedPayload["annualNet"] !== undefined ? calculatedPayload["annualNet"]: 0 }</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );


}

export default YTD;