/*************
 * THIS IS NOT IN USE ANYMORE, IT WAS SUPPOSED TO BE ADDED IN QUOTE SECTION BUT DUE TO SOME REASONS 
 * THIS FUNCTIONALITY IS DIRECTLY IPLEMENTED IN QUOTE
 * ************ */

import { useEffect, useState } from 'react';
import { getInsuranceCompaniesList } from '../../../../../api';
import { useToken } from '../../../../../hooks/useAuth';
import { CheckBox } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

import SingleInsurance from './SingleInsurance';

const Insurances = ({ initialValues, formRef }) => {
    const insuranceLabels = { "comprehensive": "Comprehensive", "cci": "CCI", "warrenty": "Warrenty", "gap": "GAP", "lti": "LTI", "rti": "RTI" }
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const token = useToken();

    const [insurances, setInsurances] = useState(
        [
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "comprehensive", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "cci", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "warrenty", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "gap", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "lti", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "rti", active: false }
        ]
    )

    //INSURANCE_TYPES
    const fetchAllInsuranceCompanies = async () => {
        var response = await getInsuranceCompaniesList(token);
        if (response["error"] != undefined && response["error"] == 0 && response["httpCode"] !== undefined && response["httpCode"] == 200) {
            setInsuranceCompanies(response["data"] || []);
        }
    }

    useEffect(() => {
        fetchAllInsuranceCompanies();
    }, [])

    useEffect(() => {
    }, [insuranceCompanies])


    //UPDATE INSURANCE STATE IF CHECKBOX IS CHECKED | UNCHECKED
    const updateHandler = (name, value) => {
        var tempInsurances = JSON.parse(JSON.stringify(formRef.current.values.insurances));

        //if checkbox is selected
        if (value) {
            var foundIndex = tempInsurances.findIndex(x => x.type == name);
            if (foundIndex >= 0) { tempInsurances[foundIndex].active = true; }
            setInsurances(...tempInsurances)
        }
        else {
            var foundIndex = tempInsurances.findIndex(x => x.type == name);
            if (foundIndex >= 0) {
                tempInsurances[foundIndex].insuranceCompanyId = "";
                tempInsurances[foundIndex].commission = "";
                tempInsurances[foundIndex].grossPremium = "";
                tempInsurances[foundIndex].netPremium = "";
                tempInsurances[foundIndex].expiryDate = "";
                tempInsurances[foundIndex].extraDetail = "";
                tempInsurances[foundIndex].active = false;
            }
            setInsurances( ...tempInsurances)
        }
    }

    useEffect(() => {
    }, [insurances])

    //ASSIGN INITIAL VALUES TO insurances STATE, 
    useEffect(() => {
        var tempInsurances = JSON.parse(JSON.stringify(insurances));

        if (Array.isArray(initialValues)) {
            initialValues.map((singleInsurance) => {
                if (singleInsurance.type !== undefined) {
                    var foundIndex = tempInsurances.findIndex(x => x.type == singleInsurance.type);
                    if (foundIndex >= 0) {
                        tempInsurances[foundIndex].insuranceCompanyId = singleInsurance["insuranceCompanyId"];
                        tempInsurances[foundIndex].commission = singleInsurance["commission"];
                        tempInsurances[foundIndex].grossPremium = singleInsurance["grossPremium"];
                        tempInsurances[foundIndex].netPremium = singleInsurance["netPremium"];
                        tempInsurances[foundIndex].expiryDate = singleInsurance["expiryDate"];
                        tempInsurances[foundIndex].extraDetail = singleInsurance["extraDetail"];
                        tempInsurances[foundIndex].active = true;
                    }
                }
            })
            setInsurances(tempInsurances);
        }
    }, [])

    return (
        <>


            <Formik
                initialValues={{
                    "insurances": insurances
                }}
                innerRef={formRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={values => {
                  
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div className='row' style={{ marginBottom: "20px" }}>
                            <div className='col-sm-12'>
                                <div style={{ display: "flex", justifyContent: "start" }}>
                                    <CheckBox name="comprehensive" defaultChecked={insurances[0]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> Comprehensive </span>

                                    <CheckBox name="cci" defaultChecked={insurances[1]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> CCI </span>

                                    <CheckBox name="gap" defaultChecked={insurances[2]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> GAP </span>

                                    <CheckBox name="warrenty" defaultChecked={insurances[3]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> WARRENTY </span>

                                    <CheckBox name="lti" defaultChecked={insurances[4]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> LTI </span>

                                    <CheckBox name="rti" defaultChecked={insurances[5]["active"]} setFieldValue={updateHandler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> RTI </span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {
                                Array.isArray(insurances) && Array.isArray(insuranceCompanies) && insuranceCompanies.length > 0 ?
                                    insurances.map((insurance, index) => {
                                        if (insurance.active) {
                                            return <SingleInsurance labelPrefix={insuranceLabels[insurance.type]} index={index} type={[insurance.type]} insuranceCompanies={insuranceCompanies} setFieldValue={setFieldValue} key={index} />
                                        }

                                    }) :
                                    ""
                            }
                        </div>


                    </Form>
                )}
            </Formik>


        </>
    )
}

export default Insurances;