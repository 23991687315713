
import { useEffect, useState } from 'react';
import SelectField from './SelectField';

const SelectDatePicker = ({ fieldValue, showLabel = false, fieldName, setFieldValue, displayDayField = 1, displayMonthField = 1, displayYearField = 1 }) => {
    

//    console.log("SELECT DATE PICLER------->", ` fieldValue : ${fieldValue}  ,  fieldName: ${fieldName}  ` );

    const [selectedDay, setSelectedDay] = useState("00");
    const [selectedMonth, setSelectedMonth] = useState("00");
    const [selectedYear, setSelectedYear] = useState("0000");
    
    const [days, setDays] = useState("");
    const months = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" }];

    const [years, setYears] = useState("");
 
    const updateYearsList = () => {
        var yearsArray = [];
        for (var i = 1900; i < 2040; i++) {
            yearsArray.push({ value: i.toString(), label: i });
        }
        setYears(yearsArray);
    }

    const updateDaysList = () => {
        var daysArray = [];
        for (var i = 1; i <= 31; i++) {
            var day = i < 10 ? "0"+i : i.toString();
            daysArray.push({ value: day, label: i });
        }
        setDays(daysArray);
    }

    const fillStatesWithDefaultValue = () =>{
        if(fieldValue?.length===10){
            if(fieldValue?.includes('-')){
                setSelectedYear(fieldValue.slice(0, 4));
                setSelectedMonth(fieldValue.slice(5, 7));
                setSelectedDay(fieldValue.slice(8, 10));
            }
            else{
                //SET YEAR TO 2023 IF DATE IS NOT PROVIDED
                setSelectedYear('2023');
            }
        }
        else{
            //SET YEAR TO 2023 IF DATE IS NOT PROVIDED
            setSelectedYear('2023');
        }
    }

    useEffect(() => {
        updateYearsList();
        updateDaysList();
        if(fieldValue !== undefined && fieldValue !== 0){
            fillStatesWithDefaultValue();
        }
    }, [fieldValue])
 

    const handleChange = (name, value) => {
        //FORMAT DATE ON THE BASIS OF VALUE AND ASSIGN TO FUNCTION SETFIELD VALUE
        if (name == "day") { setSelectedDay(value);  callbackHandler(value, selectedMonth, selectedYear); }
        else if (name == "month") { setSelectedMonth(value);  callbackHandler(selectedDay, value, selectedYear);}
        else if (name == "year") { setSelectedYear(value);  callbackHandler(selectedDay, selectedMonth, value);}
    }

    const callbackHandler = (day, month, year) => {
        if(displayDayField == 1 && displayMonthField == 1  && displayYearField == 1 
            && day != "00" &&  month != "00" && year != "0000"
            ){
                setFieldValue(fieldName, year + "-" + month + "-" + day);
        }
        else if(displayDayField == 0 && displayMonthField == 1  && displayYearField == 1 
            && month != "00" && year != "0000"
            ){
                setFieldValue(fieldName, year + "-" + month + "-" + "01");
        }
    }

    //DEDICE THE COL SIZE BASED ON THE NUMBER OF FIELDS NEED TO APPEAR    
    var countDisplayFields = parseInt(displayDayField)+ parseInt(displayMonthField) + parseInt(displayYearField); 
    const colSize =  countDisplayFields  === 3 ? " col-md-4 " : ( countDisplayFields  === 2 ? " col-md-6 " : " col-md-12 " ) ;


    return (
        <>
            <div className="row">
                {displayDayField === 1 ?
                    <div className= {colSize + "  fv-row fv-plugins-icon-container" }>
                        {showLabel !== undefined && showLabel == true ? <label className="required fs-8 fw-bold mb-2">Day</label> : ""}
                        <SelectField
                            fieldName="day"
                            defaultSelectedOption={{ value : selectedDay , label : ""}}
                            options={days}
                            setFieldValue={handleChange}
                        />
                    </div>
                    : ""
                }
                {displayMonthField === 1 ?
                <div className= {colSize + "  fv-row fv-plugins-icon-container" }>
                    {showLabel !== undefined && showLabel == true ? <label className="required fs-8 fw-bold mb-2">Month</label> : ""}
                    <SelectField
                        fieldName="month"
                        defaultSelectedOption={{ value : selectedMonth , label : ""}}
                        options={months}
                        setFieldValue={handleChange}
                    />
                </div>
                : "" }
                {displayYearField === 1 ?
                <div className= {colSize + "  fv-row fv-plugins-icon-container" }>
                    {showLabel !== undefined && showLabel == true ? <label className="required fs-8 fw-bold mb-2">Year</label> : ""}
                    <SelectField
                        fieldName="year"
                        defaultSelectedOption={{ value : selectedYear , label : ""}}
                        options={years}
                        setFieldValue={handleChange}
                    />
                </div>
                : "" }
            </div>
        </>
    )


}

export default SelectDatePicker;