
import { useRole } from '../../../hooks/useAuth';
import { IconButton , CustomButton } from '../../HtmlComponents';
import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';

const SingleComplaint = ({ complaint, callback }) => {

    const userRole = useRole();
    var payload = {};
    try{  payload = complaint.payload !== undefined ? JSON.parse(complaint.payload) : {}}
    catch(e){payload = {}; }

    return (
        <>
        
            <tr>
                <td>
                    <span>{complaint && complaint["date"] !== undefined && complaint["date"] > 0 ? formatDate(complaint["date"], "unix", "DD/MM/YYYY") : ""} </span>
                    {
                        ['superAdmin', 'finance', 'admin'].includes(userRole) ?
                            <IconButton
                                iconType="edit"
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                buttonOnClick={() => { callback("openUpdateComplaintDrawer", { complaintId: complaint.id }) }}
                                tooltipDescription=""
                                buttonStyle={{backgroundColor : "transparent"}}
                            /> : ""
                    }
                </td>
                <td><span>{payload.applicantName !== undefined ? payload.applicantName : ""}</span></td>
                <td><span>{payload.analystName !== undefined ? payload.analystName : ""}</span></td>
                <td><span>{payload.description !== undefined ? payload.description : ""}</span></td>
            </tr>

        </>

    )
}

export default SingleComplaint;

