

const FloatField = ({ fieldName, fieldValue , placeholder, setFieldValue}) => {
    return (
        <>
            <input
                name={fieldName}
                className="form-control"
                style={{ height: "35px", fontWeight: "400" }}
                type="text"
                value= {fieldValue}
                placeholder = {placeholder}
                onChange={
                    (e) => {
                        console.log(e);
                        var value = e.target.value || "";

                        var val = value.replace(/[^0-9\.]/g,'');
                        if(val.split('.').length>2){ val =val.replace(/\.+$/,""); } 
                        setFieldValue(fieldName, val)                             
                    }
                }
                //ACTION WHEN THE FOCUS IS REMOVED THEN ADD .00
                onBlur={
                    (e) => {
                        var value = e.target.value || "";
                        var val = value.replace(/[^0-9\.]/g,'');
                        console.log("val line 28 --------------------------------------- ", val);
                        if(val.split('.').length>2){ val =val.replace(/\.+$/,""); }  
                        console.log("val line 30 --------------------------------------  ", val);

                        //setFieldValue(fieldName,  val)    
                        setFieldValue(fieldName, parseFloat(val).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, "") )                             
                    }
                }
                
                
                />
        </>
    )
}


export default FloatField;
