import { useEffect, useState } from 'react';
import { InputField, SelectField, TextArea, CustomButton } from '../../HtmlComponents';
import Constants from "../../global/Constants";
import { capitalizeFirst, formatDate } from '../../../helpers';

const AddAnotherApplicant = ({ applicationId, callback }) => {
    const [titleList, setTitleList] = useState([]);
    const [basicInfo, setBasicInfo] = useState({ title: '', firstName: '', middleName: '', surname: '' });
    const [readyForSubmission, setReadyForSubmission] = useState(false);    //TO CONTROLL SUBMIT/SAVE BUTTON
    const [processing, setProcessing] = useState(false);        //TO CONTROL CLOSE BUTTON


    const FetchTitleList = () => {
        var titleOptions = [];
        Constants.TITLE.map(item => {
            titleOptions.push({ value: item, label: capitalizeFirst(item) });
        })
        setTitleList(titleOptions);
    }

    const updateHandler = (index, value) => {
        setBasicInfo( {...basicInfo , [index] : value });
    }

    useEffect( () => {
        FetchTitleList();
    } , [])


    
    useEffect( () => {
        if(basicInfo['title'].length > 0 || basicInfo['firstName'].length >0 || basicInfo['surname'].length > 0 ){
            setReadyForSubmission(true);
        }
    } , [basicInfo])
    


    return (
        <>
            <div className='text-muted fw-semibold fs-5 mb-8'>Application # {applicationId} </div>

            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">Title</label>
                <SelectField
                    fieldName="title"
                    defaultSelectedOption={{value: basicInfo.title, label: '' }}
                    options={titleList}
                    setFieldValue={updateHandler}
                />
            </div>
            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">First Name</label>
                <InputField fieldName="firstName" placeholder="First Name" fieldValue={basicInfo.firstName} setFieldValue={updateHandler} capitalizeFirstLetter={true} />
            </div>
            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">Middle Name</label>
                <InputField fieldName="middleName" placeholder="Middle Name"  fieldValue={basicInfo.middleName}  setFieldValue={updateHandler}  capitalizeFirstLetter={true}/>
            </div>
            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">Surname</label>
                <InputField fieldName="surname" placeholder="Surname"   fieldValue={basicInfo.surname}  setFieldValue={updateHandler} capitalizeFirstLetter={true} />
            </div>

            <div className='d-flex  mb-8' style={{ flexDirection: "row-reverse" }}>
                <CustomButton
                    buttonClassName="btn-sm btn-primary"
                    buttonStyle={{ float: "right" }}
                    buttonOnClick={() => { 
                        callback("executeAddAnotherApplicant", basicInfo);
                    }}
                    buttonText="Save"
                    isActive={readyForSubmission ? true : false}
                />
                <CustomButton
                    buttonClassName="btn-sm btn-secondary"
                    buttonStyle={{ float: "right", marginRight: "20px" }}
                    buttonOnClick={() => { callback("closeDrawer", true); }}
                    buttonText="Close"
                    isActive={processing ? false : true}
                />
            </div>

        </>
    )
}

export default AddAnotherApplicant;