import { useState } from "react";
import { CustomButton, InputField, ConfirmDialog, TextArea } from '../HtmlComponents';
import { createNote, getNotes, deleteNote } from '../../api';
import { useToken } from "../../hooks/useAuth";

import LoadingScreen from '../global/LoadingScreen';


const CreateNote = ({referrerId , callback}) => {
    const [note, setNote] = useState({});
    const [processing, setProcessing] = useState(false);
    var token = useToken();

    const handler = async (name , value) => {
        switch(name){
            case "note":
                setNote(value);
                break;                
            case "createNote" : 
                setProcessing(true);
                var response = await createNote(token , {referrerId : referrerId , note : note});
                console.log("response::::::::::" , response)
                setProcessing(false);
                callback("loadNotes")

            
            break;
        }
    }



    return(
        <>
            {processing ? <LoadingScreen /> : ""}
            <div className="row">
                <div className="col-sm-12">
                    <TextArea  
                        fieldName= "note"
                        setFieldValue = { handler}
                    />
                </div>
                <div className="col-sm-12" style={{ marginTop: "20px"}}>
                    <CustomButton
                    buttonClassName = " btn-primary btn-sm "
                    isActive = {note.length > 0 ? true : false}
                    buttonStyle = { {float:"right"} }
                    buttonText = "Save"
                    buttonOnClick = { () => {
                        handler("createNote" , "")
                    }}

                    />
                </div>
            </div>

        </>
    )

}


export default CreateNote;
