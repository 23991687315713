import SingleDirector from './SingleDirector';

const ListDirectors = ({directors, callback}) =>{
        return(
            <>            
                <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                    <thead>
                        <tr className="fw-bolder fs-6 bg-light">                             
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>D License</th>
                            <th>Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="fw-bold  fw-bold text-gray-600">
                        {                            console.log(directors) }
                        {
                            Array.isArray(directors) ?
                            directors.map(director => (
                                <SingleDirector key={director.id} director ={director} callback= {callback} />
                            ))
                            : <tr></tr>
                        }
                    </tbody>
                </table>             
            </>
        )        
}

export default ListDirectors;