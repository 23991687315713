
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';
import { updateDeclined, getDeclined } from "../../api";
import { FormSubmissionButton, TextArea, CheckBox, FloatField, SelectField, InputGroup, InputField, MaskedField } from '../HtmlComponents';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import DeclinedSchema from './schema/DeclinedSchema.json';

import Constants from "../global/Constants";
import DeclinedReason from "./components/DeclinedReason";


const EditDeclined = () => {
    const [singleDeclined, setSingleDeclined] = useState({});
    const declinedRef = useRef();
    let { id } = useParams();
    let navigate = useNavigate();
    const token = useToken();

    const getReasons = () => {
        var reasons = Object.keys(Constants.DECLINED_REASONS).map(index => {
            return { type: index, description: "", isChecked: false }
        })
        return reasons;
    }


    const fetchDeclined = async () => {
        var reasons = getReasons();
        
        
        var data = await getDeclined(id, token,);
        console.log("data  ;;;;;;;;;;;;  ", data);
        if (!data["error"]) {
            //DECLINED DATE
            data["declined"]["declinedDate"] = data["declined"]["declinedDate"] !== undefined && data["declined"]["declinedDate"] !== 0 ? formatDate(data["declined"]["declinedDate"], 'unix', 'DD/MM/YYYY') : "";
            //FOLLOW UP DATE
            data["declined"]["followUpDate"] = data["declined"]["followUpDate"] !== undefined && data["declined"]["followUpDate"] !== 0 ? formatDate(data["declined"]["followUpDate"], 'unix', 'DD/MM/YYYY') : "";

            //SET IS-CHECKED FLAG TRUE FOR REASONS THAT ARE ALREADY SET FOR THIS DEAL
            var selectedReasons = data["declined"]["reasons"];
            var refinedReasons = reasons.map (reason => {
                var type = reason["type"];
                var sr = selectedReasons.filter( selectedReason => selectedReason["type"] == type);
                if(sr.length > 0 ){
                    reason["description"] = sr["description"];
                    reason["isChecked"] = true;
                }
                return reason;
            })

            data["declined"]["reasons"] = refinedReasons;
            setSingleDeclined(data["declined"]);
        }
        else {
            Notifications("error",data["message"]);
        }
        
    }

    
    useEffect(() => {
        fetchDeclined();
    }, [])

 
    const DeclinedReasonsHtml = ({ setFieldValue }) => {

        if (Array.isArray(singleDeclined.reasons)) {
            return (
                singleDeclined.reasons.map((singleReason, index) => {
                    return (
                        <div style={{ display: "flex", justifyContent: "start", marginBottom: "8px" }}>
                            <DeclinedReason
                                name={`reasons[${index}]`}
                                label={Constants.DECLINED_REASONS[singleReason["type"]]}
                                isChecked={singleReason["isChecked"]}
                                setFieldValue={setFieldValue} />
                        </div>
                    )
                })
            )
        }
        else {
            return ("No Reaosn found");
        }
    }

    const DeclinedHtml = (errors, touched, setFieldValue) => {
        return (
            <>
                <div className="card mb-5 mb-xxl-8">
                    <div className="card-body card-body pt-6 pb-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <h3 className="pb-0 mb-0">Edit Declined (Ref # {id}) </h3>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="position-relative z-index-1">

                            <div className="row">
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Declined Date</label>
                                    <Field type="text" name={`declinedDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`declinedDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Follow Up Date</label>
                                    <Field type="text" name={`followUpDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`followUpDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Declined For</h5>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <DeclinedReasonsHtml  setFieldValue= {setFieldValue} />
                                </div>

                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Comments</h5>
                                    </div>
                                    <div>
                                        <Field type="text" name={`comments`}  >
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="comments"
                                                    fieldValue={field.value}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Formik
                initialValues={singleDeclined}
                //validationSchema={}
                innerRef={declinedRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    //CHECK IF ANALYST COMMISSION HAS A RECORD THAT DOES NOT HAVE A ANALYST ID - REMOVE IT
                    console.log("values::::::::::::::::::::", values)

                    var localValues = JSON.parse(JSON.stringify(values));
                    //FORMAT THE DATES
                    localValues["declinedDate"] = localValues["declinedDate"].includes("/") ? formatDate(localValues["declinedDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["declinedDate"];
                    localValues["followUpDate"] = localValues["followUpDate"].includes("/") ? formatDate(localValues["followUpDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["followUpDate"];

                    var reasons = localValues["reasons"];
                    //fetch only reasons having isChecked true
                    var filteredReasons = reasons.filter(reason => reason.isChecked == true);
                    //console.log("filtered reasons::" , filteredReasons);
                    localValues["reasons"] = filteredReasons;

                    console.log("FORM VALUES UPON SUBMISSIONS::: ", localValues)


                    var response = await updateDeclined(id, localValues, token);
                    console.log("declined creation response :: ", response);
                    if (!response["error"]) {
                        Notifications("success", response["message"] || "Created Successfully");
                        navigate(`/user/declined`);
                    }
                    else {
                        Notifications("error", response["message"] || "Error occured");
                    }
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {DeclinedHtml(errors, touched, setFieldValue)}
                                    </div>
                                </div>
                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default EditDeclined;