import { useState } from "react";
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox } from '../../HtmlComponents/';


const TaxCalculator = () => {

    const [payload, setPayload] = useState({ amount: 0, frequency: 'weekly' })
    const [calculatedPayload, setCalculatedPayload] = useState({});

    const handler = () => {
        var grossIncome = parseFloat( payload["amount"]);
        var frequency = payload["frequency"];
        if(frequency == "weekly" ){grossIncome =  parseFloat(grossIncome)*52;}
        if(frequency == "fortnightly" ){grossIncome =  parseFloat(grossIncome)*26; }
        var grossIncome1 = grossIncome;
        var yearlyLeavy = (grossIncome1* 0.02 );
        var totalTax = 0;
        
        var tmpPayload = {
            taxableIncomeWeekly: (grossIncome1/52).toFixed(2),
            taxableIncomeFortnightly: (grossIncome1/26).toFixed(2),
            taxableIncomeMonthly : (grossIncome1/12).toFixed(2),
            taxableIncomeAnnualy: grossIncome1.toFixed(2)

        }
         
        
        /*tax calculation statr */
        var taxRange =[];
        taxRange["range1"] = 0;
        taxRange["range2"] = 0;
        taxRange["range3"] = 0;
        taxRange["range4"] = 0;
        taxRange["range5"] = 0;
         
        if(grossIncome > 180000){taxRange["range5"] =  grossIncome- 180000; grossIncome= 180000; }
        if(grossIncome > 90000){taxRange["range4"] =  grossIncome- 90000; grossIncome = 90000; }
        if(grossIncome > 37000){taxRange["range3"] =  grossIncome- 37000; grossIncome = 37000; }
        if(grossIncome > 18200){taxRange["range2"] =  grossIncome- 18200; grossIncome = 18200; }
        if(grossIncome > 1){taxRange["range1"] =  18200; }
    
        
        totalTax = taxRange["range2"] * 0.19;
        totalTax = parseFloat(totalTax) + parseFloat(parseFloat(taxRange["range3"]) * 0.325);
        totalTax = parseFloat(totalTax) + parseFloat(parseFloat(taxRange["range4"]) * 0.37);
        totalTax = parseFloat(totalTax) + parseFloat(parseFloat(taxRange["range5"]) * 0.45);        
    
        var weeklyTax = ((totalTax/52) + parseFloat(0.99)).toFixed(2);
        /*tax calculation end */
        
       
            
        /*Income tax start*/
        tmpPayload["incomeTaxWeekly"] = weeklyTax;
        tmpPayload["incomeTaxFortnightly"] = ((weeklyTax * 52)/26).toFixed(2);
        tmpPayload["incomeTaxMonthly"] = ((weeklyTax * 52)/12).toFixed(2);
        tmpPayload["incomeTaxAnnualy"] = totalTax.toFixed(2);
        /*Income tax end*/

        
        /*medicare start */
        tmpPayload["medicareWeekly"] = ((grossIncome1*0.02)/52).toFixed(2);
        tmpPayload["medicareFortnightly"] = ((grossIncome1*0.02)/26).toFixed(2);
        tmpPayload["medicareMonthly"] = ((grossIncome1*0.02)/12).toFixed(2);
        tmpPayload["medicareAnnualy"] = ((grossIncome1*0.02)).toFixed(2);
        /*medicare end */        
        
        /*Total Income tax start*/
          var weeklyTotalTax = Math.round(((totalTax/52)+ + parseFloat(0.99) +  parseFloat(yearlyLeavy/52)).toFixed(2));
          var monthlyToalTax = Math.round((weeklyTotalTax*52)/12);
          
          tmpPayload["totalTaxesWeekly"] = weeklyTotalTax;
          tmpPayload["totalTaxesFortnightly"] = ((weeklyTotalTax * 52)/26).toFixed(2);
          tmpPayload["totalTaxesMonthly"] = ((weeklyTotalTax * 52)/12).toFixed(2);
          tmpPayload["totalTaxesAnnualy"] =  (parseFloat(totalTax) + parseFloat(grossIncome1*0.02)).toFixed(2);
        /*Income tax end*/
        
         
        /* pay start*/
        var income = grossIncome1 ;
        var weeklyIncome = ((income/52) - weeklyTotalTax ).toFixed(2);
        
        tmpPayload["payAnnualy"] = (weeklyIncome*52).toFixed(2);
        tmpPayload["payMonthly"] = ( (grossIncome1/12)-( (weeklyTotalTax*52)/12) ).toFixed(2);
        tmpPayload["payFortnightly"] =  ( (weeklyIncome*52)/26).toFixed(2);
        tmpPayload["payWeekly"] =   weeklyIncome ;
        /* pay end*/
        
        
        
        /* calculate offset start */
        var taxOffset = 0;
        var totalAnnualTax = (parseFloat(totalTax) + parseFloat(grossIncome1*0.02)).toFixed(2);
        if(grossIncome1 >90000 && grossIncome1 < 126001){ 
            taxOffset = 1080;
            var amount = grossIncome1-90000;
            amount = amount* 0.03;
            taxOffset = (taxOffset - parseFloat(amount) ).toFixed(2);
        }
        else if(grossIncome1 > 48000 && grossIncome1 < 90001){ taxOffset = 1080; }
        else if(grossIncome1 > 37000 && grossIncome1 < 48001){ 
            taxOffset = 255;
            var amount = grossIncome1-37000;
            amount = amount *0.075;
            taxOffset = (taxOffset + parseFloat(amount) ).toFixed(2);
        }
        else if(grossIncome1 <37001){
            //this offset will subtract from total payable tax, if no tax or less tax is payable then it will reduce
            taxOffset = 255;
            if(totalAnnualTax >255){ taxOffset = 255;}
            else {taxOffset = totalAnnualTax; }
        }
        
        tmpPayload["taxOffsetAnnualy"] =  taxOffset;
        tmpPayload["totalTaxesAnnualy"] = (totalAnnualTax - taxOffset).toFixed(2);
        tmpPayload["payAnnualy"] =  ( parseFloat(grossIncome1-totalAnnualTax) + parseFloat(taxOffset) ).toFixed(2);        
        /* calculate offset end */
       

        setCalculatedPayload(tmpPayload )
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="row">

                        <div className="col-sm-5">
                            <label className="fs-8 fw-bold mb-2">Gross Income</label>
                            <InputField
                                fieldName="amount"
                                placeholder="0"
                                fieldValue={payload.amount}
                                setFieldValue={(name, value) => {
                                    setPayload({ ...payload, amount: value })
                                }} />
                        </div>
                        <div className="col-sm-5">
                            <label className="fs-8 fw-bold mb-2">Frequency</label>
                            <SelectField
                                fieldName="frequency"
                                defaultSelectedOption={{ value: payload.frequency, label: "" }}
                                options={[{ value: "weekly", label: "Weekly" }, { value: "fortnightly", label: "Fortnightly" }, { value: "yearly", label: "Yearly" }]}
                                setFieldValue={(name, value) => {
                                    setPayload({ ...payload, frequency: value })
                                }}
                            />
                        </div>
                        <div className="col-sm-2">
                            <button type="button" className="btn btn-sm btn-primary btn-hover-rise" style={{float:"right", marginTop: "24px"}} onClick = {handler} > Calculate</button>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <table className="table table-striped">
                                <tbody><tr>
                                    <th>Title</th>
                                    <th>Weekly</th>
                                    <th>Fornightly</th>
                                    <th>Monthly</th>
                                    <th>Annually</th>
                                </tr>
                                    <tr className="table-success">
                                        <td>Pay</td>
                                        <td id="payWeekly">{calculatedPayload["payWeekly"] !== undefined ? calculatedPayload["payWeekly"]: "-"}</td>
                                        <td id="payFortnightly">{calculatedPayload["payFortnightly"] !== undefined ? calculatedPayload["payFortnightly"]: "-"}</td>
                                        <td id="payMonthly">{calculatedPayload["payMonthly"] !== undefined ? calculatedPayload["payMonthly"]: "-"}</td>
                                        <td id="payAnnualy">{calculatedPayload["payAnnualy"] !== undefined ? calculatedPayload["payAnnualy"]: "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Taxable Income</td>
                                        <td id="taxableIncomeWeekly">{calculatedPayload["taxableIncomeWeekly"] !== undefined ? calculatedPayload["taxableIncomeWeekly"]: "-"}</td>
                                        <td id="taxableIncomeFortnightly">{calculatedPayload["taxableIncomeFortnightly"] !== undefined ? calculatedPayload["taxableIncomeFortnightly"]: "-"}</td>
                                        <td id="taxableIncomeMonthly">{calculatedPayload["taxableIncomeMonthly"] !== undefined ? calculatedPayload["taxableIncomeMonthly"]: "-"}</td>
                                        <td id="taxableIncomeAnnualy">{calculatedPayload["taxableIncomeAnnualy"] !== undefined ? calculatedPayload["taxableIncomeAnnualy"]: "-"}</td>
                                    </tr>

                                    <tr>
                                        <td>Income Tax</td>
                                        <td id="incomeTaxWeekly">{calculatedPayload["incomeTaxWeekly"] !== undefined ? calculatedPayload["incomeTaxWeekly"]: "-"}</td>
                                        <td id="incomeTaxFortnightly">{calculatedPayload["incomeTaxFortnightly"] !== undefined ? calculatedPayload["incomeTaxFortnightly"]: "-"}</td>
                                        <td id="incomeTaxMonthly">{calculatedPayload["incomeTaxMonthly"] !== undefined ? calculatedPayload["incomeTaxMonthly"]: "-"}</td>
                                        <td id="incomeTaxAnnualy">{calculatedPayload["incomeTaxAnnualy"] !== undefined ? calculatedPayload["incomeTaxAnnualy"]: "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Medicare</td>
                                        <td id="medicareWeekly">{calculatedPayload["medicareWeekly"] !== undefined ? calculatedPayload["medicareWeekly"]: "-"}</td>
                                        <td id="medicareFortnightly">{calculatedPayload["medicareFortnightly"] !== undefined ? calculatedPayload["medicareFortnightly"]: "-"}</td>
                                        <td id="medicareMonthly">{calculatedPayload["medicareMonthly"] !== undefined ? calculatedPayload["medicareMonthly"]: "-"}</td>
                                        <td id="medicareAnnualy">{calculatedPayload["medicareAnnualy"] !== undefined ? calculatedPayload["medicareAnnualy"]: "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax Offset</td>
                                        <td id=""> $ 0</td>
                                        <td id=""> $ 0 </td>
                                        <td id=""> $ 0 </td>
                                        <td id="taxOffsetAnnualy"> $ {calculatedPayload["taxOffsetAnnualy"] !== undefined ? calculatedPayload["taxOffsetAnnualy"]: "0"}</td>
                                    </tr>
                                    <tr className="table-warning">
                                        <td>Total Taxes</td>
                                        <td id="totalTaxesWeekly">{calculatedPayload["totalTaxesWeekly"] !== undefined ? calculatedPayload["totalTaxesWeekly"]: "-"}</td>
                                        <td id="totalTaxesFortnightly">{calculatedPayload["totalTaxesFortnightly"] !== undefined ? calculatedPayload["totalTaxesFortnightly"]: "-"}</td>
                                        <td id="totalTaxesMonthly">{calculatedPayload["totalTaxesMonthly"] !== undefined ? calculatedPayload["totalTaxesMonthly"]: "-"}</td>
                                        <td id="totalTaxesAnnualy">{calculatedPayload["totalTaxesAnnualy"] !== undefined ? calculatedPayload["totalTaxesAnnualy"]: "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <a href="https://www.ato.gov.au/Calculators-and-tools/Simple-tax-calculator/" target="_blank"  >ATO simple tax calculator</a>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );



}

export default TaxCalculator;