
const CheckBox = ({ name, defaultChecked, setFieldValue }) => {
    // / form-check-custom form-check-solid mx-5
    //form-check  <- recently removed
    return (
        <>
            <div className="">
                <input
                    name={name}
                    defaultChecked={defaultChecked}
                    className="form-check-input"
                    type="checkbox"
                    onChange={
                        (e) => {
                            setFieldValue(name, e.target.checked);
                        }
                    } />
            </div>
        </>
    )
}

export default CheckBox;