import axios from '../api/axios';

const getUser = async(token, userId) => {
    try{
        const response = await axios.get(`users/${userId}` , { headers: { Authorization: "Bearer " + token } });
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

const addUser = async(token , user) => {
    try{
        const response = await axios.post(`users`,  user , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : "Created Successfully", user:response.data }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data ||{}}
         }
    }
}


const updateUser = async(token, userId, user) => {
    try{
        const response = await axios.put(`users/${userId}`,  user , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : "Updated Successfully", user: response.data || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}


//USER UPDATE HIS PASSWORD AFTER LOGIN
const updatePassword = async(token, user) => {
    try{
        const response = await axios.put(`users/updatePassword`,  user , { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0, httpCode: response.status, "message" : response.data["message"] || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}
//USER REQUEST TO RESET HIS PASSWORD
const forgotPassword = async(user) => {
    try{
        const response = await axios.post(`/forgotPassword`,  user , { });
        return {"error" : 0, httpCode: response.status, "message" : response.data["message"] || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}
const resetPassword = async(user) => {
    try{
        const response = await axios.post(`/resetPassword`,  user , { });
        return {"error" : 0, httpCode: response.status, "message" : response.data["message"] || {} }
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}



//entityType: creditRep , entityId: id -> e.g get all users of creditRep # 
const getUsers = async(token, entityType, entityId, purpose="default") => {
    try{
        console.log("   endpoint ----------------  ", `users/${entityType}/${entityId}/${purpose}`);
        console.log("   token ----------------  ", token);

        const response = await axios.get(`users/usersForReportingPurpose/${entityType}/${entityId}/${purpose}`, { headers: { Authorization: "Bearer " + token , "Content-Type" : "application/json"} });
        return { error: 0, httpCode: response.status, message: "",  data: response.data ? response.data : {}};

    }
    catch(e){
        if (e.response) {
            return {"error" : 1 ,  httpCode:e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data , data: {}}
         }
    }
}



const deleteUser = async(token, userId) => {
    try{
        const response = await axios.delete(`users/${userId}` , { headers: { Authorization: "Bearer " + token } });
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

export {getUser, addUser, updateUser ,updatePassword , forgotPassword, resetPassword,  getUsers, deleteUser};