import axios from "axios";

axios.interceptors.request.use(function (config) {

  //console.log("AXIOS CONFIG START ");
  //console.log(config);
  // Do something before request is sent
  return config;
}, function (error) {
  //console.log("AXIOS CONFIG ERROR ");
  console.log(error);
  // Do something with request error
  return Promise.reject(error);
});


/**
 * 
 * 
 customAxios.interceptors.response.use(
  response => response,
  error => {
    console.log("ERROR", error);
   // window.location.href = '/login';
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
  });


 */


export default axios.create({
  
  baseURL: process.env.REACT_APP_ENV == "DEVELOPMENT" ? process.env.REACT_APP_DEVELOPMENT_BACKENDENDPOINT : process.env.REACT_APP_BACKENDENDPOINT,
  //timeout: 10000,   //request expires after 10 seconds   - default is `0` (no timeout)

  //IF HTTPS CODE IS 401- IT MEANS PROVIDED TOKEN IS EXPIRED
  validateStatus: function (status) {
    if (status === 401) {  
      localStorage.setItem('activeSession', "false");
      window.location.href = "/login";
    }
    return status >= 200 && status < 300; // default
  },
})


 

 


  //export default customAxios;


//'http://localhost:3000/'
//'https://api.brokahub.com.au/'
//baseURL: process.env.NODE_ENV === "development"?  process.env.REACT_APP_DEVELOPMENT_BACKENDENDPOINT:  process.env.REACT_APP_BACKENDENDPOINT