import { Link } from "react-router-dom";
import { IconButton, ConfirmDialog } from '../HtmlComponents';
import { useRole, useToken } from '../../hooks/useAuth';
import Notifications from '../global/Notifications';
import { deleteLender } from "../../api";

const LenderListItem = ({ lender, callback }) => {

    var token = useToken();
    var userRole = useRole();

    const handler = async (name, lenderId) => {
        switch (name) {
            case "deleteLender":
                ConfirmDialog({ callback: () => { handler("executeDeleteContactOperation", lenderId) }, "purpose": "general", "heading": `Would you like to proceed with archiving this lender?`, "description": "" })
                break;
            case "executeDeleteContactOperation":
                let response = await deleteLender(token, lenderId);
                if (response["error"] !== undefined && response["error"] == 1) { Notifications("error", response["message"] || ""); }
                else { Notifications("success", response["message"] || ""); callback("removeLenderFormList", { id: lenderId }) }
                break;
        }
    }


    return (
        <>
            <tr className="even text-start">
                <td> <Link to={{ pathname: `/user/lenderDetail/${lender.id}` }} className="text-gray-800 text-hover-primary mb-1">{lender.name}</Link> </td>
                <td>{lender.contactNumber}</td>
                <td>{lender.assessmentEmail}</td>
                <td>{lender.settlementEmail}</td>
                <td>{lender.lastLoginAt}</td>

                {
                    userRole === "superAdmin" ?
                        <td>
                            <IconButton
                                iconType="delete"
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                buttonStyle={{ background: "transparent", color: "red" }}
                                buttonOnClick={() => { handler("deleteLender", lender.id) }}
                                tooltipDescription="Delete Lender"
                            />
                        </td>
                        : ""
                }



            </tr>
        </>
    )
}

export default LenderListItem;