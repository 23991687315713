import { useEffect, useState } from "react";
import { InputField, SelectField, CustomButton } from "../HtmlComponents";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

const CreateInsuranceCompany = ({insuranceTypes, callback}) => {

    const [company, setCompany] = useState({ name: "", repName: "", contactNumber: "", email: "", type: "" })
    const [submitReady, setSubmitReady] = useState(false);

    const updaetHandler = (name , value) =>{
        console.log(name, value);
        setCompany({...company , [name]: value});
    }

    useEffect( () =>{
        //if company name and type is selected yhen enable the submission otherwise disable it 
        if(company.name.length >0 && company.type.length >0 && company.repName.length > 0){ setSubmitReady(true);}
        else{setSubmitReady(false); }

    }, [company])
    return (
        <> 
                    <div className="row mb-5">
                        <div className="col-md-12 fv-row fv-plugins-icon-container">
                            <label className="required fs-8 fw-bold mb-2">Name</label>
                             <InputField 
                             fieldName="name"
                             placeholder="Name"
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        <div className="col-md-12 fv-row mt-4 fv-plugins-icon-container ">
                            <label className="fs-8 fw-bold mb-2">Rep Name</label>
                             <InputField 
                             fieldName="repName"
                             placeholder="Name"
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        <div className="col-md-12 fv-row  mt-4  fv-plugins-icon-container">
                            <label className="fs-8 fw-bold mb-2">Contact Number</label>
                             <InputField 
                             fieldName="contactNumber"
                             placeholder=""
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                            <label className="fs-8 fw-bold mb-2">Email</label>
                             <InputField 
                             fieldName="email"
                             placeholder=""
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                            <label className="fs-8 fw-bold mb-2">Xero Contact Id</label>
                             <InputField 
                             fieldName="xeroContactId"
                             placeholder=""
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                            <label className="fs-8 fw-bold mb-2">Xero Account Id</label>
                             <InputField 
                             fieldName="xeroAccountId"
                             placeholder=""
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>
                        
                        <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                            <label className="required fs-8 fw-bold mb-2">Type</label>
                             <SelectField 
                             fieldName="type"
                             defaultSelectedOption = {{value: company["type"]}} 
                             options = {insuranceTypes} 
                             setFieldValue = {updaetHandler}                             
                             />
                        </div>

                        <div className="col-sm-12">
                            <div style={{display : "flex" , justifyContent: "end" , marginTop: "20px"}}>
                            <CustomButton  
                                buttonClassName= "btn btn-secondary btn-sm "
                                isActive = {true}
                                buttonStyle = {{}}
                                buttonOnClick= { () => {callback("closeDraw", {}) } }
                                buttonText = "Close"
                            />
                            <CustomButton  
                                buttonClassName= "btn btn-primary btn-sm "
                                isActive = {submitReady}
                                buttonStyle = {{ marginLeft: "20px"}}
                                buttonOnClick= { () => { callback("createInsuranceCompany" , company) }}
                                buttonText = "Save"
                            />
                            </div>
                            
                        </div>

                    </div>
                 
        </>
    )
}

export default CreateInsuranceCompany;