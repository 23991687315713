import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken } from '../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';
import { createLender } from "../../api";
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal } from '../HtmlComponents';
import AddressForm from '../global/AddressForm';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import lenderSchema from './schema/lenderSchema.json';
import LenderValidationSchema from './schema/LenderValidationSchema';

const AddLender = () => {
    const [singleLender, setSingleLender] = useState(lenderSchema);
    const [submitFLag, setSubmitFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });


    let navigate = useNavigate();
    const token = useToken();


    //UPDATE LENDER - WHEN SUBMIT TRIGGER AND SINGLE LENDER OBJECT GOT UPDATED - DO NOT CALL WHEN DATA LOADED INTO SINGLE LENDER OBJECT FIRST TIME

    const BasicDetail = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Basic Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered  gy-4 align-middle fw-bolder border-gray-900 no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className=" ">Name</td>
                                    <td className="  fw-bold text-gray-600">
                                        <Field name="name" placeholder="Name" className="form-control  " />
                                        <div className="error">{errors.name ? errors.name : ""}</div>
                                    </td>
                                </tr>

                                <tr className="even">
                                    <td className=" ">Assessment Email</td>
                                    <td className="  fw-bold text-gray-600 ">
                                        <Field name="assessmentEmail" placeholder="Assessment Email" className="form-control  " />
                                        <div className="error">{errors.assessmentEmail ? errors.assessmentEmail : ""}</div>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className=" ">Settlement Email</td>
                                    <td className="  fw-bold text-gray-600">
                                        <Field name="settlementEmail" placeholder="Settlement Email" className="form-control  " />
                                        <div className="error">{errors.settlementEmail ? errors.settlementEmail : ""}</div>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className=" ">Portal Web Address</td>
                                    <td className="  fw-bold text-gray-600">
                                        <Field name="websiteUrl" placeholder="Website Url" className="form-control  " />
                                        <div className="error"> {errors.websiteUrl ? errors.websiteUrl : ""}</div>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className=" ">Contact #</td>
                                    <td className="  fw-bold text-gray-600">
                                        <Field name="contactNumber" placeholder="Contact Number" className="form-control  " />
                                        <div className="error"> {errors.contactNumber ? errors.contactNumber : ""}</div>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className=" ">Address</td>
                                    <td className="  fw-bold text-gray-600">
                                        <Field name="address">
                                            {({ field }) => (
                                                <input
                                                    className="form-control  "
                                                    type="text"
                                                    value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                    onClick={() => setAddressModal({ visible: true, fieldName: "address", callback: setFieldValue })}
                                                    onChange={(e) => { }}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const WhySection = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Why {singleLender.name}? </h3>
                        </div>
                    </div>
                    <div className="bg-body   shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <TextEditor fieldName="otherNotes" initialValue={singleLender.otherNotes} height={300} setFieldValue={setFieldValue} />
                    </div>
                </div>
            </div>
        </>)
    }

    const AboutSection = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">About {singleLender.name} </h3>
                        </div>
                    </div>
                    <div className="bg-body   shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <TextEditor fieldName="productNotes" initialValue={singleLender.productNotes} height={300} setFieldValue={setFieldValue} />
                    </div>
                </div>
            </div>
        </>)
    }


    const FeesAndChargesSection = (errors, touched, setFieldValue) => {
        return (
            <>
                <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3"> Lender Fees and Charges  </h3>
                    </div>
                </div>

                <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                    <table className="table table-row-bordered gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody>
                            <tr>
                                <th className=" ">Consumer Fee:       </th>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="feeStructure.consumerFee">
                                        {({ field }) => (
                                            <FloatField
                                                fieldName="feeStructure.consumerFee"
                                                fieldValue={field.value}
                                                placeholder="Consumer Fee"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>

                                <td className=" ">Commercial Fee:     </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="feeStructure.commercialFee">
                                        {({ field }) => (
                                            <FloatField
                                                fieldName="feeStructure.commercialFee"
                                                fieldValue={field.value}
                                                placeholder="Commercial Fee"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Registration Fee:   </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="feeStructure.registrationFee" placeholder="Registration Fee">
                                        {({ field }) => (
                                            <FloatField
                                                fieldName="feeStructure.registrationFee"
                                                fieldValue={field.value}
                                                placeholder="Registration Fee"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>

                                <td className=" ">Credit Search Fee:  </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="feeStructure.creditSearchFee">
                                        {({ field }) => (
                                            <FloatField
                                                fieldName="feeStructure.creditSearchFee"
                                                fieldValue={field.value}
                                                placeholder="Credit Search Fee"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Monthly Fee:        </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="feeStructure.monthlyFee">
                                        {({ field }) => (
                                            <FloatField
                                                fieldName="feeStructure.monthlyFee"
                                                fieldValue={field.value}
                                                placeholder="Monthly Fee"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    const ProductTypeSection = (errors, touched, setFieldValue) => {
        return (
            <>
                <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3"> Lender Product Type </h3>
                    </div>
                </div>
                <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                    <table className="table table-row-bordered gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody>
                            <tr>
                                <td className=" ">Consumer:        </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.consumer">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.consumer" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>

                                <td className=" ">Commercial:      </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.commercial">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.commercial" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Private Sale:    </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.privateSale">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.privateSale" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Imports:         </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.imports">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.imports" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">WOVR:            </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.wovr">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.wovr" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Docusign:        </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.docusign">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.docusign" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Car:             </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.car">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.car" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Motorcycle:      </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.motorcycle">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.motorcycle" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Caravan:         </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.caravan">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.caravan" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Horse Float:     </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.horsefloat">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.horsefloat" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Equipment:       </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.equipment">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.equipment" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                                <td className=" ">Marine:          </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.marine">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.marine" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Novated Lease:   </td>
                                <td className=" " style={{ fontWeight: "normal" }}>
                                    <Field name="supportedProducts.novatedLease">
                                        {({ field }) => (
                                            <CheckBox name="supportedProducts.novatedLease" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </>
        )
    }

    const XeroCOnfig = (errors, touched, setFieldValue) => {
        return (
            <>
                <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3"> Xero Config </h3>
                    </div>
                </div>

                <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                    <table className="table table-row-bordered gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody>
                            <tr>
                                <td className=" ">Origination Payment Method (Consumer):   </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.consumerOriginationPaymentMethod">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="xeroConfig.consumerOriginationPaymentMethod"
                                                defaultSelectedOption={{ value: field.value, label: "" }}
                                                options={[{ value: 'Direct', label: 'Direct' }, { value: 'Fintelignce', label: 'Fintelignce' }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>

                                </td>


                                <td className=" ">Origination Payment Method (Commercial):   </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.commercialOriginationPaymentMethod">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="xeroConfig.commercialOriginationPaymentMethod"
                                                defaultSelectedOption={{ value: field.value, label: "" }}
                                                options={[{ value: 'Direct', label: 'Direct' }, { value: 'Fintelignce', label: 'Fintelignce' }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Finance Payment Method (consumer):   </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.consumerFinanceCommissionPaymentMethod">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="xeroConfig.consumerFinanceCommissionPaymentMethod"
                                                defaultSelectedOption={{ value: field.value, label: "" }}
                                                options={[{ value: 'Direct', label: 'Direct' }, { value: 'Fintelignce', label: 'Fintelignce' }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>

                                <td className=" ">Finance Payment Method (Commercial):   </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.commercialFinanceCommissionPaymentMethod">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="xeroConfig.commercialFinanceCommissionPaymentMethod"
                                                defaultSelectedOption={{ value: field.value, label: "" }}
                                                options={[{ value: 'Direct', label: 'Direct' }, { value: 'Fintelignce', label: 'Fintelignce' }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>

                            <tr>
                                <td className="">Commission Account Id:       </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.xeroAccountId" className="form-control  " />
                                </td>
                                <td className=" ">Contact Id:       </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.xeroContactId" className="form-control  " />
                                </td>
                            </tr>
                            <tr>
                                <td className=" ">Origination Account Id:       </td>
                                <td className="  fw-bold text-gray-600">
                                    <Field name="xeroConfig.xeroOriginationAccountId" className="form-control  " />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>



                        </tbody>
                    </table>
                </div>


            </>
        )
    }

    function LoadView() {
        return (
            <>
                <Formik
                    initialValues={singleLender}
                    validationSchema={LenderValidationSchema}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        // same shape as initial values
                        console.log("values ready for submission ::", values);

                        setProcessing(true);
                        var response = await createLender(values, token)
                        if (response["error"]) {
                            Notifications("error", response["message"] || "Could not create the lender.")
                        }
                        else {
                            Notifications("success", response["message"] || "Created Successfully");
                            let lenderId = response["lender"]["id"];
                            if (lenderId > 0) {
                                navigate(`/user/lenderDetail/${lenderId}`);
                            }
                            else {
                                navigate(`/user/listLenders`);
                            }
                        }

                        setProcessing(false);
                        //console.log(response);

                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div id="kt_content_container" className="container-xxl">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-12">
                                        {BasicDetail(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-6">
                                        {WhySection(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-6">
                                        {AboutSection(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-12">
                                        {FeesAndChargesSection(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-12">
                                        {ProductTypeSection(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-12">
                                        {XeroCOnfig(errors, touched, setFieldValue)}
                                    </div>
                                    <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                    <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }

    return (
        <>
            {loading ? <LoadingScreen /> : LoadView()}

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}
        </>
    )
}

export default AddLender;