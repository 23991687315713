import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken, useEntityType, useRole } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, ConfirmDialog } from '../HtmlComponents';
import { FilledTick2, CancelIcon } from '../HtmlComponents/Icons';

import { getReferrer, deleteUser , getReferrerGroup} from "../../api";
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import { useMethodAllowed } from '../../hooks/usePermission';


import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import Files from '../files/Files';

import CreateDirector from "../directors/CreateDirector";
import UpdateDirector from "../directors/UpdateDirector";
import ListDirectors from "../directors/ListDirectors";

import AddUser from "../users/AddUser";
import UpdateUser from "../users/UpdateUser";
import ListUsers from "../users/ListUsers";

//EMAIL MODULE
import ReferrerWelcomeEmail from "./components/ReferrerWelcomeEmail";
import SendEmail from "../emailTemplates/SendEmail";

//NOTES MODULE
import ListNotes from "../notes/ListNotes"; 

import LoadingScreen from "../global/LoadingScreen";

const ReferrerDetail = () => {
    const [singleReferrer, setSingleReferrer] = useState({});
    const [singleReferrerGroup, setSingleReferrerGroup] = useState({});
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    const [processing, setProcessing] = useState(false);
    let { id } = useParams();
    const token = useToken();
    const entityType = useEntityType();
    const role = useRole();

    const fetchReferrerGroup = async(id) => {
        var referrerGroupResult = await getReferrerGroup(id, token);
        if(referrerGroupResult["error"] == 0 && referrerGroupResult["referrerGroup"] !== undefined){
            setSingleReferrerGroup(referrerGroupResult["referrerGroup"]);
        }
    }

    useEffect(() => {
        const fetchSingleReferrer = async () => {
            const result = await getReferrer(id, token); 
            if (result["error"] == 0 && result["referrer"] !== undefined) {
                setSingleReferrer(result["referrer"]);
                if(result["referrer"] !== undefined && result["referrer"] !== null && result["referrer"]["referrerGroupId"] !== undefined){
                    fetchReferrerGroup(result["referrer"]["referrerGroupId"]);
                }
            }
        }
        fetchSingleReferrer();
    }, []);



    const payloadUpdateHandler = (action, values, meta = {}) => {
        switch (action) {
            case "updatePayloadOnAddUser":
                setProcessing(true);
                var users = singleReferrer.users;
                if (!Array.isArray(users)) { users = []; }
                users.push(values);
                //UPDAET THE STATE                    
                setSingleReferrer({ ...singleReferrer, users: users });
                //handler("closeDrawer");   //CLOSE DRAW
                handler("referrerWelcomeEmail", {firstName: values["firstName"], userName: values["userName"], email: values["email"], defaultPassword : "asdfgh123" });
                setProcessing(false);
                break;
            case "updatePayloadOnUpdateUser":
                var users = singleReferrer.users;
                var newUsers = [];
                users.map((user) => {
                    if (user.id == values.id) {
                        newUsers.push(values);
                    }
                    else {
                        newUsers.push(user);
                    }
                })
                //UPDAET THE STATE                    
                setSingleReferrer({ ...singleReferrer, users: newUsers });
                handler("closeDrawer");   //CLOSE DRAW
                break;
            case "updatePayloadOnDeleteUser":
                var users = singleReferrer.users;
                var newUsers = [];
                users.map((user) => {
                    if (user.id !== values.userId) {
                        newUsers.push(user);
                    }
                })
                //UPDAET THE STATE                    
                setSingleReferrer({ ...singleReferrer, users: newUsers });
                break;

            case "createDirector":
                //ADD THE NEW DIRECTOR TO DIRECTORS ARRAY
                var directors = singleReferrer.directors;
                if (Array.isArray(directors)) { directors.push(values); }
                else {
                    directors = [];
                    directors.push(values);
                }
                //UPDAET THE STATE                    
                setSingleReferrer({ ...singleReferrer, directors: directors });
                handler("closeDrawer");  //CLOSE RIGHT DRAW
                break;
            case "updateDirector":
                //FIND THE EXISTING DIRECTOR AND UPDATE THE DATA
                var directors = singleReferrer.directors;
                var newDirectorsList = [];

                directors.map((director) => {
                    if (director.id == values.id) {  newDirectorsList.push(values); }
                    else { newDirectorsList.push(director); }
                })
                //UPDAET THE STATE                    
                setSingleReferrer({ ...singleReferrer, directors: newDirectorsList });
                handler("closeDrawer");  //CLOSE RIGHT DRAW

                break;
            case "deleteDirector":
                //FIND AND REMOVE 
                break;
        }
    }


    const handler = async(actionName, payload = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openFilesDraw":
                setDrawerSettings({ visible: true, heading: "Files", childName: "files", meta: { width: "800" } })
                break;
            case "openAddUserDraw":
                setDrawerSettings({ visible: true, heading: "Add User", childName: "addUser", meta: { width: "800" } })
                break;
            case "openUpdateUserDraw":
                setDrawerSettings({ visible: true, heading: "Update User", childName: "updateUser", meta: { width: "800", userId: payload["userId"] , userStatus: payload["userStatus"] || "" } })
                break;
            case "openCreateDirectorDraw":
                setDrawerSettings({ visible: true, heading: "Create Director", childName: "CreateDirector", meta: { width: "800" } });
                break;
            case "openUpdateDirectorDraw":
                setDrawerSettings({ visible: true, heading: "Update Director", childName: "UpdateDirector", meta: { width: "800", directorId: payload["directorId"] ? payload["directorId"] : 0 } });
                break;
            case "openNotesDraw":
                setDrawerSettings({ visible: true, heading: "Notes", childName: "notes" });
                break;
            case "referrerWelcomeEmail": 
                setDrawerSettings({ visible: true, heading: "Welcome Email", childName: "referrerWelcomeEmail", meta: {firstName: payload["firstName"] || "" , userName: payload["userName"] || "" , email : payload["email"] ||"",  defaultPassword: payload["defaultPassword"] || "" , cc: singleReferrerGroup["email"] || ""} });
                break;    
        }
    }

    const ReferrerGroupField = (setFieldValue) => {

        if (entityType === "referrerGroup") {
            return "";
        }
        else {
            return (<>
                <tr className="even">
                    <td className="text-start">Referrer Group Name</td>
                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.referrerGroupName}  </td>
                </tr>
            </>)
        }
    }

    const EditButtonHandler = () => {
        if ( ["creditRep" , "company"].includes(entityType) && ["admin", "superAdmin", "finance"].includes(role)) {
            return (<> <EditButton URL={"/user/editReferrer/" + singleReferrer.id} />  </>);
        }
        else if (entityType == "referrerGroup") {
            return (<> <EditButton URL={"/user/editReferrer/" + singleReferrer.id} />  </>);
        }
    }


    const Summary = () => {
        return (<>
            <div className="card mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Details</h3>
                            <EditButtonHandler />
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">

                                <ReferrerGroupField />

                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.tradingName}  </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name</td>
                                    <td className="text-start  fw-bold text-gray-600 "> {singleReferrer.entityName} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.structure} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.abn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.acn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Joining Date</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.joiningDate && singleReferrer.joiningDate !== 0 ? formatDate(singleReferrer.joiningDate, 'unix', 'DD/MM/YYYY') : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Date Established</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.dateEstablished && singleReferrer.dateEstablished !== 0 ? formatDate(singleReferrer.dateEstablished, 'unix', 'DD/MM/YYYY') : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Telephone</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.contactNumber} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.fax} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Business Address</td>
                                    <td className="text-start  fw-bold text-gray-600">  {singleReferrer.address && singleReferrer.address.fullAddress ? singleReferrer.address.fullAddress : ""}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.websiteUrl} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.email}  </td>
                                </tr>
                                {["finance", "admin", "superAdmin"].includes(role) ?
                                    <>
                                        <tr className="even">
                                            <td className="text-start">Payment Method</td>
                                            <td className="text-start  fw-bold text-gray-600"> {singleReferrer.paymentMethod}  </td>
                                        </tr>
                                        <tr className="even">
                                            <td className="text-start">Commission Structure</td>
                                            <td className="text-start  fw-bold text-gray-600"> {singleReferrer.commissionStructure}  </td>
                                        </tr>
                                        <tr className="even">
                                            <td className="text-start">Gst Registered</td>
                                            <td className="text-start  fw-bold text-gray-600"> {singleReferrer.isGstRegistered ? "Yes" : "No"}  </td>
                                        </tr>
                                        <tr className="even">
                                            <td className="text-start">CRM - Export customers csv</td>
                                            <td className="text-start  fw-bold text-gray-600"> {singleReferrer.exportingCustomerAllowed ? "Yes" : "No"}  </td>
                                        </tr>
                                        <tr className="even">
                                            <td className="text-start">System Access</td>
                                            <td className="text-start  fw-bold text-gray-600"> {singleReferrer.systemAccess ? "Yes" : "No"}  </td>
                                        </tr>
                                    </> : ""}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const BankingDetails = () => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.bankingDetail && singleReferrer.bankingDetail.name ? singleReferrer.bankingDetail.name : ""}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Branch</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.bankingDetail && singleReferrer.bankingDetail.branchName ? singleReferrer.bankingDetail.branchName : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.bankingDetail && singleReferrer.bankingDetail.bsb ? singleReferrer.bankingDetail.bsb : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Account Number</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.bankingDetail && singleReferrer.bankingDetail.accountNumber ? singleReferrer.bankingDetail.accountNumber : ""} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const XeroConfig = () => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Xero Config</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Contact Id</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.xeroConfig && singleReferrer.xeroConfig.xeroContactId ? singleReferrer.xeroConfig.xeroContactId : ""}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Tax Type</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrer.xeroConfig && singleReferrer.xeroConfig.xeroTaxType ? singleReferrer.xeroConfig.xeroTaxType : ""} </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    //USER BLOCK - LIST ALL USERS
    const UsersListing = () => {
        return <>
            <div className="card  mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Users</h3>
                            <CustomButton
                                buttonClassName=" btn btn-sm btn-light btn-active-secondary "
                                isActive={true}
                                buttonText="Add New User"
                                buttonOnClick={() => { handler("openAddUserDraw") }}
                            />
                            
                            {/*<CustomButton
                                buttonClassName=" btn btn-sm btn-light btn-active-secondary "
                                isActive={true}
                                buttonText="Send Email"
                                buttonOnClick={() => { 
                                    handler("referrerWelcomeEmail", {firstName: "rana", userName: "rana123", email: "rana@example.com", defaultPassword : "asdfgh123" });
                
                                 }}
                            />*/}


                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 table-responsive" style={{ marginTop: "-100px" }}>
                        <ListUsers users={singleReferrer.users ? singleReferrer.users : []} callback={(method, userId) => {
                            switch (method) {
                                case "updateUser": handler("openUpdateUserDraw", { userId: userId }); break;
                                case "refreshUsersListing": payloadUpdateHandler("updatePayloadOnDeleteUser", { userId: userId }); break;
                            }
                        }} />
                    </div>

                </div>
            </div>
        </>
    }

    //Archived USER BLOCK - LIST ALL Archived USERS
    const ArchivedUsersListing = () => {
        return <>
            <div className="card  mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Archived Users</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 table-responsive" style={{ marginTop: "-100px" }}>
                        <ListUsers users={ singleReferrer.archivedUsers !== undefined && Array.isArray(singleReferrer.archivedUsers) ? singleReferrer.archivedUsers : []} usersStatus="inActive" callback={(method, userId) => {
                            switch (method) {
                                case "updateUser": handler("openUpdateUserDraw", { userId: userId , userStatus : "inActive" }); break;
                                case "refreshUsersListing": payloadUpdateHandler("updatePayloadOnDeleteUser", { userId: userId }); break;
                            }
                        }} />
                    </div>

                </div>
            </div>
        </>
    }


    //DIRECTORS
    const DirectorsListing = () => {
        return (<>

            <div className="card  mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Director/ Partner Details</h3>
                            {<CustomButton
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                isActive={true}
                                buttonText="Add"
                                buttonOnClick={() => { handler("openCreateDirectorDraw") }}
                            />
                            }
                        </div>
                    </div>

                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 table-responsive" style={{ marginTop: "-100px" }}>
                        <div className="row">
                            <ListDirectors directors={singleReferrer && singleReferrer.directors ? singleReferrer.directors : []} callback={(id) => {
                                handler("openUpdateDirectorDraw", { directorId: id })
                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-6">
                        <Summary />
                    </div>
                    <div className="col-xl-6">
                        {entityType !== "referrerGroup" ? BankingDetails() : ""}
                        {entityType !== "referrerGroup" ? XeroConfig() : ""}
                        {entityType === "referrerGroup" ? <UsersListing /> : ""}
                    </div>
                    <div className="col-xl-12">
                        {DirectorsListing()}
                    </div>
                    
                    <div className="col-xl-12">
                        {entityType !== "referrerGroup" ? <UsersListing /> : ""}
                    </div>
                    <div className="col-xl-12">
                        {entityType !== "referrerGroup" ? <ArchivedUsersListing /> : ""}
                    </div>

                </div>

                {(entityType == "creditRep" && role == "admin") || (entityType == "referrerGroup") || entityType == "company" && role == "finance" ?
                    <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" style={{ zIndex: "1" }}>
                        <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { handler("openFilesDraw") }} buttonText="Files" />
                        <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { handler("openNotesDraw") }} buttonText="Notes" />
                    </div>
                    : ""
                }


                {drawerSettings["visible"] ?
                    <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                        {drawerSettings["childName"] === "addUser" ? <AddUser entityType={"referrer"} entityId={singleReferrer.id}
                            callback={(data) => { payloadUpdateHandler("updatePayloadOnAddUser", data) }}
                        /> : ""}
                        {drawerSettings["childName"] === "updateUser" ? <UpdateUser entityType="referrer" userId={drawerSettings["meta"]["userId"] ||  0} additionalParams={{ referrerGroupId: singleReferrer["referrerGroupId"]  || 0 , userStatus: drawerSettings["meta"]["userStatus"] || ""}} callback={(data) => { payloadUpdateHandler("updatePayloadOnUpdateUser", data) }} /> : ""}
                        {drawerSettings["childName"] === "files" ? <Files entityType="referrer" entityId={id} enableUpload={true} /> : ""}
                        {drawerSettings["childName"] === "notes" ? <ListNotes referrerId={id} /> : ""}
                        {drawerSettings["childName"] == "CreateDirector" ? <CreateDirector entityType="referrer" entityId={id} callback={(data) => { payloadUpdateHandler("createDirector", data) }} /> : ""}
                        {drawerSettings["childName"] == "UpdateDirector" ? <UpdateDirector directorId={drawerSettings["meta"]["directorId"]} callback={(id, data) => { payloadUpdateHandler("updateDirector", data) }} /> : ""}
                        {drawerSettings["childName"] == "referrerWelcomeEmail" ? <SendEmail payload={ { recipientFirstName: drawerSettings["meta"]["firstName"] || "" , recipientUserName: drawerSettings["meta"]["userName"] || "", recipientEmail : drawerSettings["meta"]["email"] || "", recipientDefaultPassword: drawerSettings["meta"]["defaultPassword"] || "" , cc: drawerSettings["meta"]["cc"] }} recipientType="referrerUser" callback={(id, data) => { }} /> : ""}

                    </RightDrawer> : ""
                }

            </div>
            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    );

}


export default ReferrerDetail;