import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

const CreditCard = ({ prefix, setFieldValue, frequencyList }) => {
    return (
        <>
            <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Financial Institution</label>
                <Field name={prefix + "financialInstitution"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "financialInstitution"}
                            fieldValue={field.value !== undefined ? field.value : ""}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Credit Limit</label>
                <Field name={prefix + "creditLimit"} className="form-control" style={{ height: "35px", fontWeight: "400" }}
                    onChange={(e) => {
                        var tmpCreditLimit = e.target.value !== undefined?  e.target.value: 0;
                        setFieldValue(prefix + "creditLimit", tmpCreditLimit );
                        //UPDATE REPAYMENT
                        setFieldValue(prefix + "repaymentAmount", (tmpCreditLimit* 0.038).toFixed(2) );                        
                    }}
                />
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Balance Remaining</label>
                <Field name={prefix + "balanceRemaining"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "balanceRemaining"}
                            fieldValue={field.value !== undefined ? field.value : ""}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Repayment</label>
                <Field name={prefix + "repaymentAmount"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "repaymentAmount"}
                            fieldValue={field.value !== undefined ? field.value : 0}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>


            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Frequency</label>
                <Field name={prefix + "repaymentFrequency"}>
                    {({ field }) => (
                        <SelectField
                            fieldName={prefix + "repaymentFrequency"}
                            defaultSelectedOption={{ value: field.value !== undefined ? field.value : "", label: "" }}
                            options={frequencyList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Start Date</label>
                <Field name={prefix + "startDate"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "startDate"}
                            fieldValue={field.value !== undefined ? field.value : ""}
                            placeholder="MM/YYYY"
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
        </>
    )
}

export default CreditCard;