import * as Yup from 'yup';


const ReferrerGroupValidationSchema =  Yup.object().shape({
    referrerGroupName : Yup.string().required('Required'),
    tradingName : Yup.string().required('Required'),
    entityName : Yup.string().required('Required'),
    abn: Yup.string() .matches(/^[0-9]+$/, "Must be only digits").min(11, 'Must be exactly 11 digits').max(11, 'Must be exactly 11 digits'),
    acn: Yup.string() .matches(/^[0-9]+$/, "Must be only digits") .min(9, 'Must be exactly 9 digits') .max(9, 'Must be exactly 9 digits'),
    email : Yup.string().email("Must be valid email").required('Required'),
    contactNumber: Yup.string() .matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),    
    fax: Yup.string().matches(/^[0-9]+$/, "Must be only digits"),        
    email: Yup.string().email("Must be valid email"),
    websiteUrl: Yup.string().url("Must be valid URL"),
});
 
export { ReferrerGroupValidationSchema } ;