

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer, SelectField } from '../../HtmlComponents';
import { getCurrentDate } from '../../../helpers';


import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';
import { getCpd } from "../../../api";

import CreateCpd from './CreateCpd';
import UpdateCpd from './UpdateCpd';
import SingleCpd from "./SingleCpd";


const ListCpd = () => {
    const [cpdList, setCpdList] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    const [year, setYear] = useState(getCurrentDate("YYYY"))

    const userRole = useRole();
    const userEntityType = useEntityType();

    var yearOptions = [{ value: "2020", label: "2020" }, { value: "2021", label: "2021" }, { value: "2022", label: "2022" },
    { value: "2023", label: "2023" }, { value: "2024", label: "2024" }, { value: "2025", label: "2025" }, { value: "2026", label: "2026" },
    { value: "2027", label: "2027" }, { value: "2028", label: "2028" }, { value: "2029", label: "2029" }, { value: "2030", label: "2030" }];

    const token = useToken();

    useEffect(() => {
        loadCpd();
    }, []);

    useEffect(() => {
        loadCpd();
    }, [year]);


    const loadCpd = async () => {
        var response = await getCpd(token, year); //LOAD ALL RECORDS (IF SUPERADMIN/ADMIN THEN ALL USERS OTHERWISE ONLY LOGGED IN USER)
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            var payload = [];
            if (Array.isArray(response["data"])) {
                response["data"].map(async (singleUser) => {
                    let totalHours = 0;
                    if (singleUser["cpd"] !== undefined && Array.isArray(singleUser["cpd"])) {
                        singleUser["cpd"].map(async (singleCpd) => {
                            let hours = singleCpd["hours"] !== undefined ? singleCpd["hours"] : 0;
                            totalHours += hours;
                        })
                    }
                    payload.push({ userId: singleUser["userId"], name: singleUser["name"], creditLicenseDate: singleUser["creditLicenseDate"], cpd: singleUser["cpd"], totalHours: totalHours });
                });
            }
            setCpdList(payload);
        }
    }

    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, payload) => {
        switch (action) {
            case "updatePayloadOnCreation":
                loadCpd();
                drawHandler("closeDrawer");
                break;
            case "updatePayloadOnUpdation":
                loadCpd();
                drawHandler("closeDrawer");
                break;
        }
    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateDrawer":
                setDrawerSettings({ visible: true, heading: "Create CPD", childName: "createCpd", meta: { width: "800" } });
                break;
            case "openUpdateDrawer":
                console.log("meta ::: ", meta);
                var cpdId = meta["cpdId"] || 0;
                var userId = meta["userId"] || 0;
                let singleUser = cpdList.find(singleUser => singleUser.userId === userId);
                if (singleUser !== undefined && singleUser["cpd"] !== undefined && Array.isArray(singleUser["cpd"])) {

                    let singleCpd = singleUser["cpd"].find(singleCpd => singleCpd.id === cpdId);
                    if (singleCpd !== undefined) {
                        let tmpSingleCpd = JSON.parse(JSON.stringify(singleCpd));
                        tmpSingleCpd["date"] = tmpSingleCpd["date"] !== undefined && tmpSingleCpd["date"] > 0 ? formatDate(tmpSingleCpd["date"], "unix", "DD/MM/YYYY") : "";
                        setDrawerSettings({ visible: true, heading: "Update CPD", childName: "updateCpd", meta: { width: "800", cpdId: meta["cpdId"] || 0, singleCpd: tmpSingleCpd } });
                    }
                }

                break;

        }
    }

    const LoadView = () => {
        return (
            <>
                <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                    <div className='row mb-4' style={{ padding: "0rem 2rem" }}>
                        <div className='col-sm-6'>
                            <SelectField
                                fieldName="year"
                                defaultSelectedOption={{ value: year, label: "" }}
                                options={yearOptions}
                                setFieldValue={(name, value) => { setYear(value); }}
                            />
                        </div>
                        <div className='col-sm-6'>
                            {["admin", "analyst"].includes(userRole) || (["user"].includes(userRole) && ["referrerGroup"].includes(userEntityType)) ?
                                <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { drawHandler("openCreateDrawer") }} buttonText="Create" />
                                : ""}
                        </div>
                    </div>
                    <div className="card" style={{ background: "transparent" }}>
                        <div className="card-body pt-0">
                            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                {
                                    Array.isArray(cpdList) ?
                                        cpdList.map(singleUser => {
                                            return (<>
                                                <div className="row"  >
                                                    <div className="col-sm-12">
                                                        <div className="row" style={
                                                            {
                                                                background: singleUser.totalHours !== undefined && singleUser.totalHours >= 20 ? "#d7d5d5" : "red",
                                                                color: singleUser.totalHours !== undefined && singleUser.totalHours >= 20 ? "black" : "white",
                                                                padding: "6px 0px 6px 0px"
                                                            }
                                                        }>
                                                            <div className="col-sm-6"> {singleUser.name !== undefined ? singleUser.name : ""}  </div>
                                                            <div className="col-sm-6"> <span style={{ float: "right" }}>Total Hours: {singleUser.totalHours !== undefined ? singleUser.totalHours : "0"}</span></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12" style={{ background: "#FFFFFF" }}>
                                                        <div className="table-responsive card card-body">
                                                            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"  >
                                                                <thead>
                                                                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                                        <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th>
                                                                        <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Hours</th>
                                                                        <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Provider</th>
                                                                        <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Description</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="fw-bold text-gray-600">

                                                                    {
                                                                        singleUser.cpd !== undefined && Array.isArray(singleUser.cpd) ?
                                                                            singleUser.cpd.map(singleCpd => {
                                                                                return (
                                                                                    <>
                                                                                        <SingleCpd cpd={singleCpd} userId={singleUser.userId} callback={drawHandler} />
                                                                                    </>
                                                                                )
                                                                            })
                                                                            : <tr></tr>

                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })
                                        : ""


                                }


                                { /*
                                <div className="table-responsive">
                                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"  >
                                        <thead>
                                            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th>
                                                <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Hours</th>
                                                <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Provider</th>
                                                <th className="  sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Description</th>

                                            </tr>
                                        </thead>
                                        <tbody className="fw-bold text-gray-600">
                                            {
                                                JSON.stringify(cpdList)
                                            }
                                            {
                                                Array.isArray(cpdList) ?
                                                    cpdList.map(singleCpd => {
                                                        return (
                                                            <>
                                                                <SingleCpd cpd={singleCpd} callback={drawHandler} />
                                                            </>
                                                        )
                                                    })
                                                    : <tr></tr>

                                            }
                                        </tbody>
                                    </table>
                                </div> */ }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>


            <LoadView />


            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "createCpd" ? <CreateCpd callback={payloadUpdateHandler} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "updateCpd" ? <UpdateCpd cpdId={drawerSettings["meta"]["cpdId"]} cpd={drawerSettings["meta"]["singleCpd"]} callback={payloadUpdateHandler} /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default ListCpd;