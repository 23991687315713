import { Link } from "react-router-dom";

const CreditRepListItem = (creditRep) => {
    return (
        <>
        <tr className="even text-start">
            <td> <Link to={{ pathname: `/user/creditRepDetail/${creditRep.singleCreditRep.id}`}}    className="text-gray-800 text-hover-primary mb-1">{creditRep.singleCreditRep.tradingName}</Link> </td>
            <td>{creditRep.singleCreditRep.entityName}</td>
            <td>{creditRep.singleCreditRep.structure}</td>
            <td>{creditRep.singleCreditRep.email}</td>
            
        </tr>
        </>
    )
}

export default CreditRepListItem;