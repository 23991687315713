
import { Field } from 'formik';
import { SelectField, IconButton } from '../../../../../components/HtmlComponents';
import OtherAsset from './OtherAsset';
import VehicleAsset from './VehicleAsset';
import { useState } from 'react';


const SingleAsset = ({ index, assetType, assetsTypeList, states, setFieldValue, assetOwnershipList, deleteAsset }) => {
    const [selectedAssetType, setSelectedAssetTpe] = useState(assetType);

    //HANDLER TO UPDATE THE SELECTED ASSET TYPE - EXECUTES AS A CALLBACK FROM SELECT FIELD
    const decideDetailedBlockOnChange = (fieldName, value, setFieldValue) => { setSelectedAssetTpe(value); }

    return (
        <>
            <div className="row mb-5" style={{ borderBottom: "1px dotted #edebeb" }}>
                <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="fs-8 fw-bold mb-2">Asset Type <IconButton buttonOnClick={() => { deleteAsset(index) }} iconType="-" buttonStyle={{ border: "0px", backgroundColor: "transparent" }} iconColor="orange" /></label>
                    <Field name={"assets[" + index + "].assetType"}>
                        {({ field }) => (
                            <SelectField
                                fieldName={"assets[" + index + "].assetType"}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={assetsTypeList}
                                setFieldValue={setFieldValue}
                                callback={decideDetailedBlockOnChange}
                            />
                        )}
                    </Field>
                </div>

                {
                    ["car", "caravan", "motorBike", "boat", "jetSki", "truck"].includes(selectedAssetType) ?
                        <VehicleAsset prefix={"assets[" + index + "]."} assetOwnershipList={assetOwnershipList} states={states} setFieldValue={setFieldValue} /> :
                        (
                            ["houseContents", "house", "land", "investmentProperties", "superannuation", "cashInBank", "other"].includes(selectedAssetType) ?
                                <OtherAsset prefix={"assets[" + index + "]."} assetOwnershipList={assetOwnershipList} setFieldValue={setFieldValue} selectedAssetType={selectedAssetType} />
                                : ""
                        )
                }
            </div>

        </>
    )

}



export default SingleAsset; 