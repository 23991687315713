import axios from '../api/axios';

/*GET SINGLE CREDIT REP*/ 
const getCreditRep =  async (id , token) => {
  console.log("GETCREDITREP API ENDPOINT IS EXECUTED--------------------------------------->");
  const response = await axios.get(`creditReps/${id}`, { headers: { Authorization: "Bearer " + token } });
  return response.data ? response.data : {};
}

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getCreditRepList = async(entityId, token) => {
  const response = await axios.get(`creditReps/list/${entityId}`, { headers: { Authorization: "Bearer " + token } });
  return response.data ? response.data : [];

}
//CREATE A NEW CREDITREP
const createCreditRep = async(token, creditRep) => {
  try{
    const response = await axios.post(`creditReps` , creditRep ,  {   headers: { Authorization: "Bearer " + token } });
    console.log(response);
    console.log("UPDATION PROCESS COMPLETED");
    return {"error" : 0 , httpCode: response.status, "message" : "Created successfully", data: response.data }        
  }
  catch(e){
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
   }
  } 
}


//UPDATE EXISTING CREDIT REP
const updateCreditRep = async(creditRep , token) => {

    //updateCreditRep
    const id = creditRep.id;
    console.log("CREDIT REP FROM API - CREDIT REP FILE");
    console.log(creditRep);
    console.log(id);
    console.log(token);

    /*
    axios.interceptors.request.use(function (config) {

        console.log( "AXIOS CONFIG START ");    
        console.log(config);
        // Do something before request is sent
        return config;
      }, function (error) {
        console.log( "AXIOS CONFIG ERROR ");
        console.log(error);        
        // Do something with request error
        return Promise.reject(error);
      });
      */

      try{
        const response = await axios.put(`creditReps/${id}` , creditRep ,  {   headers: { Authorization: "Bearer " + token } });
        console.log(response);
        console.log("UPDATION PROCESS COMPLETED");
        return {"error" : 0 , httpCode: response.status, "message" : "Updated successfully" }        
      }
      catch(e){
        if (e.response) {
          console.log(e.response.status)
          console.log(e.response.data)
          return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
       }
      } 
}

//CREATE CREDIT REP RCTI
const createCreditRepRCTI = async (payload, token) => {
  try {
    const response = await axios.post("creditReps/rcti/", payload, { headers: { Authorization: "Bearer " + token } });
    
    return { "error": response.data["error"] || 0 , httpCode: response.status, "message": response.data["message"] || "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

export {getCreditRep , createCreditRep , updateCreditRep , getCreditRepList , createCreditRepRCTI};