import axios from './axios';


const getRctiAdditionalData = async (token, entityId, entityType, rctiDate) => {
    try {
      const response = await axios.get(`rctiAdditionalData/${entityId}/${entityType}/${rctiDate}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  



export { getRctiAdditionalData};