import { useRole } from '../../hooks/useAuth';
import { IconButton } from '../HtmlComponents';
import constants from '../global/Constants';

const SingleEmailTemplate = ({ emailTemplate, callback }) => {

    console.log(emailTemplate)
    const userRole = useRole();
    var payload = {};
    try{  payload = emailTemplate.payload !== undefined ? JSON.parse(JSON.stringify(emailTemplate.payload)) : {}}
    catch(e){payload = {}; }

    return (
        <>
        
            <tr>
                <td>
                    <span>{emailTemplate && emailTemplate["title"] !== undefined ? emailTemplate["title"] : ""} </span>
                    {
                        ['superAdmin', 'finance', 'admin'].includes(userRole) ?
                            <IconButton
                                iconType="edit"
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                buttonOnClick={() => { callback("openUpdateEmailTemplateDrawer", { emailTemplateId: emailTemplate.id }) }}
                                tooltipDescription=""
                                buttonStyle={{backgroundColor : "transparent"}}
                            /> : ""
                    }
                </td>
                
                <td><span>{emailTemplate.subject !== undefined ? emailTemplate.subject : ""}</span></td>
                <td><span>{emailTemplate.recipientType !== undefined && constants["EMAIL_TEMPLATE_RECIPIENT_TYPES"][emailTemplate.recipientType] !== undefined ? constants["EMAIL_TEMPLATE_RECIPIENT_TYPES"][emailTemplate.recipientType] : ""}</span></td>
            </tr>

        </>

    )
}

export default SingleEmailTemplate;
