import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import UpdatePasswordValidationSchema from './schema/UpdatePasswordValidationSchema';
import { Formik, Form, Field } from 'formik';
import{updatePassword} from '../../api';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';

const UpdatePassword = () => {
    const userRef = useRef();
    const [user, setUser] = useState({ "currentPassword": "", 'newPassword': "", 'passwordConfirmation': "" });
    const [formErrors, setFormErrors] = useState({});
    let navigate = useNavigate();

    var token = useToken()
 
 
    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-lg-row-fluid">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                            <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                {/* <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_sign_in_form" onSubmit={submitHandler}> */}
                                <div className="text-center mb-10"> <h3 className="text-dark mb-3">Update Password</h3> </div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <Formik
                                            initialValues={user}
                                            validationSchema={UpdatePasswordValidationSchema}
                                            validateOnBlur={false}

                                            onSubmit={ async(values) => {
                                                console.log("VALUES", values);
                                                const response = await updatePassword(token, values);
                                                if (response["error"]) {
                                                    Notifications("error", response["message"] || "Error")
                                                } else {
                                                    console.log("response.id : " + response.id);
                                                    Notifications("success", response["message"] || "Created Successfully")
                                                    //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                                                    localStorage.setItem("firstTimePasswordChanged" , 1);
                                                }

                                            }}
                                        >
                                            {({ errors, touched, handleChange, setFieldValue }) => (
                                                <Form>
                                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="currentPassword" type="password" placeholder="Current Password" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                            {errors.currentPassword && touched.currentPassword ? (<div className="error">{errors.currentPassword}</div>) : null}
                                                        </div>
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="newPassword" type="password" placeholder="New Password" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                            {errors.newPassword && touched.newPassword ? (<div className="error">{errors.newPassword}</div>) : null}
                                                        </div>
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="passwordConfirmation" type="password" placeholder="Confirm Password" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                            {errors.passwordConfirmation && touched.passwordConfirmation ? (<div className="error">{errors.passwordConfirmation}</div>) : null}
                                                        </div>

                                                    </div>

                                                    <div className="text-center">

                                                        {JSON.stringify(errors).length > 3 ?
                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5" disabled >
                                                                <span className="indicator-label">Update</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button> :

                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5"  >
                                                                <span className="indicator-label">Update</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button>

                                                        }

                                                    </div>

                                                </Form>


                                            )}
                                        </Formik>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )


}
export default UpdatePassword;
