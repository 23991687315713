import { useEffect, useState } from 'react';
import { InputField, SelectField, TextArea, CustomButton } from '../../HtmlComponents';
import { getUsers } from '../../../api';
import { useToken, useEntityId } from '../../../hooks/useAuth';
import { performApplicationAdditionalActions, getComments } from '../../../api';
import Notifications from '../../../components/global//Notifications';
import { formatDate } from '../../../helpers';

const AssignToAnalyst = ({ applicationId, currentAnalystId, closeDrawer }) => {

    const [analystList, setAnalystList] = useState([]);
    const [analystId, setAnalytId] = useState(currentAnalystId);
    const [comment, setComment] = useState("");
    const [logs, setLogs] = useState([]);
    const [readyForSubmission, setReadyForSubmission] = useState(false);    //TO CONTROLL SUBMIT/SAVE BUTTON
    const [processing, setProcessing] = useState(false);        //TO CONTROL CLOSE BUTTON

    var token = useToken();
    var entityId = useEntityId();

    const fetchAnalyst = async () => {
        var response = await getUsers(token, "creditRep", entityId);
        console.log(response);

        if (response["error"] == 0) {
            var users = response["data"];
            if (Array.isArray(users)) {
                var options = users.map(user => {
                    return { value: user.id, label: user.firstName }
                })
                setAnalystList(options);
            }
        }
    }


    //FETCH NOTES 
    const fetchLogs = async () => {
        var response = await getComments(token, "assigningApplication", applicationId);
        if (response["error"] == 0) {
            setLogs(response["data"]);
        }
    }


    useEffect(() => {
        console.log("ASSIGN TO ANALYST USE EFFECT EXEC-");
        fetchAnalyst();
        fetchLogs();
    }, []);

    const updateHandler = async (name, value) => {
        console.log(name, value);
        switch (name) {
            case "analystId": setAnalytId(value); if (currentAnalystId !== value) { setReadyForSubmission(true); } else { setReadyForSubmission(false); } break;
            case "comments": setComment(value); break;
            case "executeUpdateCommand":
                setProcessing(true);
                setReadyForSubmission(false);
                console.log("   EXEC UPDATE COMMAND ");

                var response = await performApplicationAdditionalActions(token, "assignAnalyst", applicationId, { analystId: analystId, comment: comment });
                console.log('ASSIGNING Analsyt enD::', response);
                setProcessing(false);

                if (response["error"] !== undefined && response["error"] == 0) { Notifications("success", "Updated Successfully"); }
                else { Notifications("error", "Error occured") }

                break;
        }
    }

    return (
        <>
            <div className='text-muted fw-semibold fs-5 mb-8'>Application # {applicationId} </div>
            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">Analyst</label>
                <SelectField
                    fieldName="analystId"
                    defaultSelectedOption={{ value: analystId, label: "" }} options={analystList} setFieldValue={updateHandler}
                />
            </div>

            <div className='d-flex flex-column mb-8'>
                <label className="fs-6 fw-semibold mb-2">Comments</label>
                <TextArea
                    fieldName="comments"
                    setFieldValue={updateHandler}
                />
            </div>

            <div>
                <CustomButton
                    buttonClassName="btn-sm btn-primary"
                    buttonStyle={{ float: "right" }}
                    buttonOnClick={() => { updateHandler("executeUpdateCommand") }}
                    buttonText="Save"
                    isActive={readyForSubmission ? true : false}
                />
                <CustomButton
                    buttonClassName="btn-sm btn-secondary"
                    buttonStyle={{ float: "right", marginRight: "20px" }}
                    buttonOnClick={() => { closeDrawer("closeDrawer", true); }}
                    buttonText="Close"
                    isActive={processing ? false : true}
                />
            </div>


            <div>
                <div className='d-flex flex-column mb-8'>
                    <h3>Log</h3>
                    <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {Array.isArray(logs) ? logs.map((singleLog, index) => { return <div key={index} style={{ color: "black" }}> {singleLog.comment}  ({formatDate(singleLog.timeStamp, 'unix', 'DD/MM/yyyy HH:mm')}) </div> }) : ""}
                    </div>

                </div>
            </div>

        </>
    )
}

export default AssignToAnalyst;