//SEND DESKTOP NOTIFICATION TO ADMIN IF APPLICATION IS RECEIVED BUT ITS STATUS HAS NOT CHANGED YET

import { useEffect } from "react";
import { useRole, useToken } from "../../hooks/useAuth";
import { applicationsNotAssignedToAnalystAfterCertainTime } from "../../api";

const SendNotificationToAdmin = () =>{

    const userRole = useRole();
    const token = useToken();

    
    useEffect(() => {
        const interval = setInterval(() => {
          console.log('This will be called every 10 minutes');
          fetchApplicationNotAssignedToAnyOne();
        }, 600000); //600000
      
        return () => clearInterval(interval);
      }, []);
 

      useEffect(() => {
        askForNotificationPermission();
      }, [])

      //CHECK IF NOTIFICATION IS SUPPORTED BY BROWSER AND THEN ASK FOR PERMISSION
      const askForNotificationPermission = () => {
        if(userRole !== "admin"){return;}

        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else {
            console.log("Notifications are supported");
            if(Notification.permission !== 'granted' && userRole == "admin"){
              Notification.requestPermission();
            }
          }
      }

      //LOAD APPLICATIONS THAT ARE RECIVED BUT NOT ASSSIGNED TO ANYONE YET.
      const fetchApplicationNotAssignedToAnyOne = async() => {
        console.log("LINE 43 - FETCH APPLICAITON NOT ASSIGNED TO ANYONE")
        if(Notification.permission !== 'granted' || userRole !== "admin"){return;}

        var response = await applicationsNotAssignedToAnalystAfterCertainTime(token);
        var notificationBody = "";
        if( response["applications"] !== undefined && Array.isArray(response["applications"]) && response["applications"].length > 0 ){
            response["applications"].map(singleApplication => {
                let applicationId = singleApplication["applicationId"] !== undefined ? singleApplication["applicationId"]: "";
                let applicantName = singleApplication["applicantName"] !== undefined ? singleApplication["applicantName"]: "";
                notificationBody += `Application # ${applicationId} - ${applicantName} \n`;
            })
            if(notificationBody.length > 0){ showNotification(notificationBody); }
            else{console.log("THERE ARE NO NOTIFICATIONS LINE-55")}
        }
        else{console.log("THERE ARE NO NOTIFICATIONS LINE 57")}
      } 

      const showNotification = (notificationBody) => {
        var options = {
          body: notificationBody,
          icon: "https://images.pexels.com/photos/879356/pexels-photo-879356.jpeg?auto=compress&cs=tinysrgb&w=500&dpr=1",
          dir: "ltr"
        };
        var notification = new Notification("Unassigned Applications", options);
      }
}

export default SendNotificationToAdmin;