import { Link } from "react-router-dom";
import { CustomButton, IconButton } from '../HtmlComponents/';
import { useEntityType } from '../../hooks/useAuth';


const ReferrerListItem = ({ item, callback, status }) => {
    const entityType = useEntityType();



    return (
        <>
            <tr className="even text-start">
                <td> <Link to={{ pathname: `/user/referrerDetail/${item.id}` }} className="text-gray-800 text-hover-primary mb-1">{item.tradingName}</Link> </td>
                <td>{item.entityName}</td>
                <td>{item.structure}</td>
                <td>{item.email}</td>
                <td> {entityType === "creditRep" && status == "active" ? <IconButton iconType="delete" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Archive" buttonOnClick={() => { callback("archiveReferrer", item.id) }} /> : ""} </td>
            </tr>
        </>
    )
}

export default ReferrerListItem;