import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ConfirmDialog = (props) => {

    const popupPurposes = {
        "deleteFile": {
            "firstButton": "No",
            "secondButton": "Yes, Delete it!",
            "firstButtonClass": "cd-button-gray",
            "secondButtonClass": "cd-button-red",
            "heading": "Are you sure ?",
            "description": "You want to delete this?"
        },
        "general": {
            "firstButton": "No",
            "secondButton": "Continue",
            "firstButtonClass": "cd-button-gray",
            "secondButtonClass": "cd-button-red",
            "heading": "Are you sure ?",
            "description": ""
        },
        "infoMessage": {
            "firstButton": "OK",
            "secondButton": "",
            "firstButtonClass": "cd-button-gray",
            "secondButtonClass": "cd-button-red",
            "heading": "Are you sure ?",
            "description": ""
        }

    };

    const singlePurpose = popupPurposes[props.purpose];

    if (props.heading) { singlePurpose["heading"] = props.heading; }
    if (props.description) { singlePurpose["description"] = props.description; }
    if(props.firstButton){ singlePurpose["firstButton"] = props.firstButton; }
    if(props.secondButton){ singlePurpose["secondButton"] = props.secondButton; }
 
    const AlertBox = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <>
                        <div className="cd-popup" role="alert">
                            <div className="cd-popup-container">
                                <div className='popupTextArea'>
                                    <h1>{singlePurpose["heading"]}</h1>
                                    <p>{singlePurpose["description"]}</p>
                                </div>
                                <button
                                    className={singlePurpose["firstButtonClass"]}
                                    style={{ width: singlePurpose["secondButton"] === "" ? "100%" : " " }}
                                    onClick={() => {
                                        if (props.onCloseHandler !== undefined) { props.onCloseHandler(); }
                                        onClose();
                                    }}
                                >
                                    {singlePurpose["firstButton"]}
                                </button>

                                {
                                    singlePurpose["secondButton"] !== "" ?
                                        <button
                                            className={singlePurpose["secondButtonClass"]}
                                            onClick={() => {
                                                props.callback();
                                                onClose();
                                            }}
                                        >
                                            {singlePurpose["secondButton"]}
                                        </button> : ""
                                }

                            </div>
                        </div>


                    </>
                );
            }
        });

    }
    return (
        <>
            {
                AlertBox()
            }
        </>
    )
}

export default ConfirmDialog;



/**
 * 
 import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ConfirmDialog = (props) => {

    const popupPurposes = {
        "deleteFile" : {"firstButton" : "No" , "secondButton" : "Yes, Delete it!", "heading" : "Are you sure ?" , "description" : "You want to delete this file?" },
        "general" : {"firstButton" : "No" , "secondButton" : "Continue", "heading" : "Are you sure ?" , "description" : "" }
        
    };

    const singlePurpose = popupPurposes[props.purpose];

    if(props.heading){ singlePurpose["heading"] = props.heading; }
    if(props.description){ singlePurpose["description"] = props.description; }

    const AlertBox = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-alert-box'>
                        <h1>{singlePurpose["heading"]}</h1>
                        <p>{singlePurpose["description"]} </p>
                        <button onClick={onClose}>{singlePurpose["firstButton"]} </button>
                        <button
                            onClick={() => {                                
                                props.callback()
                                onClose();
                            }}
                        >
                             {singlePurpose["secondButton"]}
                        </button>
                    </div>
                );
            }
        });
         
    }
    return (
        <>
             {
                AlertBox()
             }
        </>
    )
}

export default ConfirmDialog;

 */