import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup } from '../HtmlComponents';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import { Formik, Form, Field } from 'formik';
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import { DirectorSchema } from './schema/DirectorSchema';
import AddressForm from '../global/AddressForm';
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import{getDirector, updateDirector} from '../../api/directorsApi';
import LoadingScreen from '../../components/global/LoadingScreen';

const UpdateDirector = ({ directorId , callback}) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);  
    const [serverStatusCode, setServerStatusCode] = useState(null);
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });
    const [director, setDirector] = useState({
        name: '', email: '', contactNumber: '', address: {
            "fullAddress": "",
            "unitNumber": "",
            "streetNumber": "",
            "streetTypeLongName": "",
            "streetTypeShortName": "",
            "streetName": "",
            "postCode": "",
            "state": "",
            "suburb": ""
        }, dob: "", drivingLicense: {unlicensed: false, number:""}
    });

    //USE IT TO FECTH THE DIRECTOR DATA FROM SERVER
    const fetchDirector = async() => {
        var response = await getDirector(token, directorId);
        setServerStatusCode(response.httpCode);
        if(response.httpCode == 200){
            response.director["dob"] = response.director["dob"]> 0 ? formatDate(response.director["dob"], 'unix', 'DD/MM/YYYY'): "";
            setDirector(response.director);
            setLoading(false);
        }
        else{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDirector();
    },[])

    useEffect(() => {
    }, [addressModal])



    const token = useToken();

    return (
        <>
        { loading ? "LOADING" : (serverStatusCode != 200 ? <DecideErrorPage errorCode={serverStatusCode} /> :
            <Formik
                initialValues={director}
                validationSchema={DirectorSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    setError("");

                    var data = JSON.parse(JSON.stringify(values));
                    data.dob = data.dob.length > 0 ?  formatDate(values.dob, 'DD/MM/YYYY', 'YYYY-MM-DD') : data.dob;
                    setProcessing(true);
                    const response = await updateDirector(token, directorId,  data);
                    setProcessing(false);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error" )
                        setDirector(values);
                    }else {
                        Notifications("success", response["message"] || "Updated Successfully" )
                        //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                        callback(directorId, response.director);
                    }
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">

                                            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                                                <tbody className="fs-6">
                                                    <tr className="even">
                                                        <td className="text-start">Name</td>
                                                        <td className="text-start text-gray-600">

                                                            <Field name="name" placeholder="Name" className="form-control form-control-solid" />
                                                            {errors.name ? (<div>{errors.name}</div>) : null}

                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Email</td>
                                                        <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                                            {errors.email ? (<div>{errors.email}</div>) : null}</td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Contact Number</td>
                                                        <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" />
                                                            {errors.contactNumber ? (<div>{errors.contactNumber}</div>) : null}</td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">DOB</td>
                                                        <td>
                                                            <Field type="text" name="dob" placeholder="dd/mm/yyyy" className="form-control form-control-solid">
                                                                {({ field }) => (
                                                                    <MaskedField fieldName="dob" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                                                )}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Driving License</td>
                                                        <td className="text-start text-gray-600">
                                                            <Field name="drivingLicense.number" placeholder="" className="form-control form-control-solid" />
                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Address</td>
                                                        <td className="text-start text-gray-600">
                                                            <Field name="address">
                                                                {({ field }) => (
                                                                    <input
                                                                        className="form-control form-control-solid"
                                                                        type="text"
                                                                        value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                                        onClick={() => setAddressModal({ visible: true, fieldName: "address", callback: setFieldValue })}
                                                                        onChange={(e) => { }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div> 
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true: false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            )}

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default UpdateDirector;