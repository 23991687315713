import axios from '../api/axios';

 /* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getReferrerGroupsList = async (token) => {
 
  try {
    const response = await axios.get(`referrerGroup`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrerGroups: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

 /* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
 const getReferrerGroupsListAgainstCreditRep = async (creditRepId, token) => {
 
  try {
    const response = await axios.get(`referrerGroup/creditRep/${creditRepId}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrerGroups: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}



const getReferrerGroup = async (id, token) => {
  try {
    const response = await axios.get(`referrerGroup/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}
 
const createReferrerGroup = async (referrerGroup, token) => {
  try {
    const response = await axios.post("referrerGroup", referrerGroup, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

const updateReferrerGroup = async (referrerGroup, referrerGroupId, token) => {
  try {
    const response = await axios.put(`referrerGroup/${referrerGroupId}`, referrerGroup, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

//DELETE REFERRER
const daleteReferrerGroup = async(referrerGroupId, token) => {
  try {
    const response = await axios.delete(`referrerGroup/${referrerGroupId}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, message: response.data.message ||  "Deleted successfully......."}
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}



const createReferrerGroupRCTI = async (payload, token) => {
  try {
    const response = await axios.post("referrerGroup/rcti/", payload, { headers: { Authorization: "Bearer " + token } });
    
    return { "error": response.data["error"] || 0 , httpCode: response.status, "message": response.data["message"] || "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

const createReferrerGroupCommissionSummary = async (payload, token) => {
  try {
    const response = await axios.post("referrerGroup/commissionSummary/", payload, { headers: { Authorization: "Bearer " + token } });
    
    return { "error": response.data["error"] || 0 , httpCode: response.status, "message": response.data["message"] || "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}


//LIST ALL INVOICES THAT ARE NOT PUSHED TO XERO YET
const listAllReferrerGroupInvocies = async(token, year, month) => {
  try {
    const response = await axios.get(`referrerGroup/listAllInvocies/${year}/${month}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}


//submitInvociesToXero
const submitReferrerGroupInvociesToXero = async(token, year, month) => {
  try {
    const response = await axios.post(`referrerGroup/submitInvociesToXero/${year}/${month}`,  {},  { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}


//GET PARENT REFERRER GROUP COMMISSION % 
const getParentReferrerGroupCommission = async (searchBy, id, token) => {
  try {
    const response = await axios.get(`referrerGroup/parentReferrerGroupCommission/${searchBy}/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Retrieved successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

export { getReferrerGroupsList, getReferrerGroupsListAgainstCreditRep,
  getReferrerGroup , createReferrerGroup, updateReferrerGroup, daleteReferrerGroup,
  createReferrerGroupRCTI, createReferrerGroupCommissionSummary, listAllReferrerGroupInvocies,
  submitReferrerGroupInvociesToXero , getParentReferrerGroupCommission };