import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useRole, useExtraInfo } from '../../hooks/useAuth';
import ApplicationListItem from './ApplicationListItem';
import { ConfirmDialog, CustomButton } from '../HtmlComponents/';
import { getApplicationsList, performApplicationAdditionalActions, getApplicationsCount, getApplicationsCountAgainstGivenProgressStatus } from '../../api';
import LoadingScreen from '../../components/global/LoadingScreen';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import Pagination from '../HtmlComponents/Form/Pagination';
import Notifications from '../../components/global/Notifications';
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '../HtmlComponents/';
import { SortingIcon } from "../HtmlComponents/Icons";

//APPLICATION COMPONENTS
/*import AssignToAnalyst from './appComponents/AssignToAnalyst';
import UpdateProgressStatus from './appComponents/UpdateProgressStatus';
import Comments from './appComponents/Comments';
import AssignedReferrerDetails from './appComponents/AssignedReferrerDetails';
import QuickReferral from './appComponents/QuickReferral';
import PrintApplication from './appComponents/PrintApplication';*/
import AdvanceSearch from './appComponents/AdvanceSearch';
/*import ApplicationsCountAgaintStatusAndAnalyst from './appComponents/ApplicationsCountAgainstStausAndAnalysts';*/

import SingleApplicationActions from './appComponents/SingleApplicationActions';

//CREATE DECLINED 
import CreateDeclined from '../declined/CreateDeclined';
//GLOBAL COMPONENTS
import Lookup from '../global/SearchForms/Lookup';
import ApplicationFiles from '../files/ApplicationFiles';


{/******************************* ALL APPLICATION THAT ARE NOT DELETED*************************************************/ }
const ListApplications = () => {

    const [applicationsList, setApplicationsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [applicationCountStat, setApplicationCountStat] = useState({ today: 0, currentWeek: 0, currentMonth: 0 });
    const [applicationCountStatForUnableToAssist, setApplicationCountStatForUnableToAssist] = useState({ today: 0, currentWeek: 0, currentMonth: 0 });
    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState({ columnName: "id", order: "DESC" })
    const [callSingleApplicationActions, setCallSingleApplicationActions] = useState({active: false, name: "", value: ""});

    const { type } = useParams(); //all, my , deleted
    const token = useToken();
    const entityType = useEntityType();
    const userRole = useRole();
    const userExtraInfo  = useExtraInfo();
    const navigate = useNavigate();

    const fetchApplications = async () => {
        setLoading(true);
        //convert filter obj into query
        var filtersQueryString = new URLSearchParams(filters).toString()
        filtersQueryString = filtersQueryString.length > 0 ? "&" + filtersQueryString : "";

        //SORTING QUERY
        var sortingQueryString = new URLSearchParams(sorting).toString();
        sortingQueryString = sortingQueryString.length > 0 ? "&" + sortingQueryString : "";


        //IF REFERRER IS LOGGED IN THEN PASS THE SELECTED REFERRER ID TOO 
        if (entityType == "referrer") {

        }
        var typeParam = ["all", "my", "deleted"].includes(type) ? type : "all";
        var query = `?page=${currentPageNumber}&type=${typeParam}${filtersQueryString}${sortingQueryString}`;

        console.log("query ::: ", query);

        if (entityType == "referrer") {
            var selectedReferrerId = localStorage.getItem("selectedReferrer");
            query = query + `&referrerId=${selectedReferrerId}`;
        }
        const response = await getApplicationsList(token, query);

        if (response["error"] !== undefined && response["error"] == 1) {
            Notifications("error", response["message"] || "Unable to fetch the applications. Please contact IT")
        }
        else {
            var data = response["data"];
            if (data["totalRecords"] !== undefined) { setTotalRecords(data["totalRecords"]); }
            if (data["records"] !== undefined) { setApplicationsList(data["records"]); }
        }
        setLoading(false);
    }
    //FETCH STAT FOR TOTAL APPLICATION RECEIVED FOR TODAY AND LAST WEEK AND MONTH 
    const fetchApplicationCountStat = async () => {
        let response = await getApplicationsCount(token);
        if (response["error"] !== undefined && response["error"] == 0) {
            let applications = response["data"] !== undefined && response["data"]["applications"] !== undefined ? response["data"]["applications"] : { today: 0, currentWeek: 0, currentMonth: 0 }

            setApplicationCountStat(
                {
                    today: applications["today"] !== undefined ? applications["today"] : 0,
                    currentWeek: applications["currentWeek"] !== undefined ? applications["currentWeek"] : 0,
                    currentMonth: applications["currentMonth"] !== undefined ? applications["currentMonth"] : 0
                }
            )
        }
    }

    //FETCH APPLICATRION COUNT STAT FOR UNABLE TO ASSIST
    const fetchApplicationCountStatForUnableToAssist = async () => {
        let response = await getApplicationsCountAgainstGivenProgressStatus(token, "unableToAssist");
        if (response["error"] !== undefined && response["error"] == 0) {
            let applications = response["data"] !== undefined && response["data"]["applications"] !== undefined ? response["data"]["applications"] : { today: 0, currentWeek: 0, currentMonth: 0 }

            setApplicationCountStatForUnableToAssist(
                {
                    today: applications["today"] !== undefined ? applications["today"] : 0,
                    currentWeek: applications["currentWeek"] !== undefined ? applications["currentWeek"] : 0,
                    currentMonth: applications["currentMonth"] !== undefined ? applications["currentMonth"] : 0
                }
            )
        }
    }

    const updateHandler = async (name, value) => {  
        console.log("updateHandler", name, value);
        switch (name) {
            /*case "applicationFiles":
                setDrawerSettings({ visible: true, heading: `Files (Referral # ${value} )`, childName: "applicationFiles", meta: { width: "800", applicationId: value } })
                break;
            case "copyApplication":
                //THIS WILL OPEN THE POPUP FOR CONFIRMATION , ON CONFORMATION  executeCopyApplicationOperation WILL BE EXECUTED
                ConfirmDialog({ callback: () => { updateHandler("executeCopyApplicationOperation", value) }, "purpose": "general", "heading": `Are you sure you want to copy Ref # ${value}`, "description": "Documents will not be carried over." })
                break;
            case "executeCopyApplicationOperation":
                var response = await performApplicationAdditionalActions(token, "copyApplication", value, {});
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Created Successfully.");
                    var applicationId = response["application"]["id"];
                    navigate(`/user/editConsumerApplication/${applicationId}`);
                }
                break;

            case "deleteApplication":
                ConfirmDialog({ callback: () => { updateHandler("executeDeleteApplicationOperation", value) }, "purpose": "general", "heading": `Are you sure you want to delete Ref # ${value}`, "description": "" })
                break;
            case "executeDeleteApplicationOperation":
                var response = await performApplicationAdditionalActions(token, "deleteApplication", value, {});
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Deleted Successfully.");
                    fetchApplications();
                }
                break;
            case "addAnotherApplicant": setDrawerSettings({ visible: true, heading: "Add another applicant", childName: "assignToAnalyst", meta: { applicationId: value['applicationId'], analystId: value["analystId"] } }); break;
            case "assignToAnalyst": setDrawerSettings({ visible: true, heading: "Assign to analyst", childName: "assignToAnalyst", meta: { applicationId: value['applicationId'], analystId: value["analystId"] } }); break;
            case "updateProgressStatus": setDrawerSettings({ visible: true, heading: "Status", childName: "updateProgressStatus", meta: { applicationId: value['applicationId'], progressStatus: value["progressStatus"], applicationType: value["applicationType"], loanPurpose: value["loanPurpose"] } }); break;
            case "viewComentsOnly": setDrawerSettings({ visible: true, heading: "Comments", childName: "Comments", meta: { applicationId: value['applicationId'], progressStatus: value["progressStatus"] } }); break;

            case "fetchReferrerDetails": setDrawerSettings({ visible: true, heading: "Referrer Details", childName: "fetchReferrerDetails", meta: { applicationId: value['applicationId'] } }); break;
            case "createDeclined": setDrawerSettings({ visible: true, heading: "Decline", childName: "createDeclined", meta: { applicationId: value['applicationId'] } }); break;


            case "quickReferral": setDrawerSettings({ visible: true, heading: "Referral Details", childName: "quickReferral", meta: {} }); break;
            //POP UP ASKING REFERRER IF THEY WANT TO UPLOAD THE DOCUMENT FOR NEWLY CREATED APPLICAITON.
            case "uploadDocumentsPopUp":
                var tmpApplicationId = value;
                updateHandler("closeDrawer");
                ConfirmDialog({ callback: () => { updateHandler("applicationFiles", tmpApplicationId) }, "purpose": "general", "heading": `Would you like to upload any supporting documents such as Retail Buyers Order / Invoice ?`, "description": "Documents can be uploaded any time using upload folder under Actions." })
                break;
            case "printApplication": setDrawerSettings({ visible: true, heading: "Application", childName: "printApplication", meta: { applicationId: value['applicationId'] } }); break;
            case "addComments": break;
            case "applicationsCountAgaintStatusAndAnalyst": setDrawerSettings({ visible: true, heading: "", childName: "applicationsCountAgaintStatusAndAnalyst" }); break;
            case "currentPageNumber": setCurrentPageNumber(value); break;
            case "lookup": if (value !== null && value.length > 2) { setFilters({ lookup: value }); setCurrentPageNumber(0); } else if (value == undefined || value.length == 0) { setFilters({}); setCurrentPageNumber(0); } break;
            case "advanceSearch": setFilters(typeof value === "object" && value !== null ? value : {}); setCurrentPageNumber(0); break;
            case "closeDrawer": setDrawerSettings({ visible: false }); fetchApplications(); break;
            */ 
            case "currentPageNumber": setCurrentPageNumber(value); break;
            case "lookup": if (value !== null && value.length > 2) { setFilters({ lookup: value }); setCurrentPageNumber(0); } else if (value == undefined || value.length == 0) { setFilters({}); setCurrentPageNumber(0); } break;
            case "advanceSearch": setFilters(typeof value === "object" && value !== null ? value : {}); setCurrentPageNumber(0); break;
            case "fetchApplications": fetchApplications();  updateHandler("hideSingleApplicationActions" , ""); break;
            case "hideSingleApplicationActions": setCallSingleApplicationActions(prevState => ({...prevState, active: false, name: "", value: ""})); break; 
        }

        if(["applicationFiles", "copyApplication", "executeCopyApplicationOperation", "deleteApplication", "executeDeleteApplicationOperation", 
        "addAnotherApplicant", "assignToAnalyst", "updateProgressStatus", "viewComentsOnly", "fetchReferrerDetails", "createDeclined", "quickReferral", "uploadDocumentsPopUp",
        "uploadDocumentsPopUp", "printApplication", "addComments", "applicationsCountAgaintStatusAndAnalyst", "currentPageNumber", "lookup", "advanceSearch", 
        "closeDrawer"].includes(name)){
            setCallSingleApplicationActions(prevState => ({...prevState, active: true, name: name, value: value}));
        }
    }

    //FETCH DATA BASED ON USER ROLE OR USER ENTITY TYPE (SOME REPORTING )
    const fetchdataBasedOnUserRole = () => {
        if (entityType == "creditRep") {
            fetchApplicationCountStat();
            fetchApplicationCountStatForUnableToAssist();
        }
    }

    //APPLY SORTING ASC/DESC AGAINST COLUMN NAME TO APPLICAITONS
    const applySortingToApplications = (columnName) => {
        console.log("columnName :: ", columnName);
        let sortingOrder = 'ASC';
        if (sorting.columnName == columnName) { sortingOrder = sorting.order == "ASC" ? "DESC" : "ASC"; }
        setSorting({ columnName: columnName, order: sortingOrder });
    }

    useEffect(() => {
        //fetchApplications();
        fetchdataBasedOnUserRole();
    }, []);

    useEffect(() => {
        fetchApplications();
    }, [currentPageNumber, filters, sorting]);

    //THS METHOD IS CREATED TO RETURN THE RELEVANT FIELDS ONLY BASED ON LOGGED IN USER (E.G REFERRERG ROUP DOES NOT NEED TO SEE LESS COLUMNS)
    const TableHeaderHtml = () => {
        if (["referrerGroup"].includes(entityType) || ["referrerGroupManager"].includes(userRole)) {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Id</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Name</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Amount</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Purpose</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Type</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Assigned</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Referral Status</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Referrer Group</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Referrer</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else if (entityType == "referrer") {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Id</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Name</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Referred</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Purpose</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Assigned</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Status</th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Actions</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("id") }}>Id <SortingIcon iconStyle={{ color: sorting.columnName == "id" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("firstName") }}>Name <SortingIcon iconStyle={{ color: sorting.columnName == "firstName" ? "#000000" : "#c3c2c2" }} /> </th>
                            { /*<th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  onClick={ () => {applySortingToApplications("surname")}}>Surname <SortingIcon iconStyle={{color: sorting.columnName == "surname" ? "#000000" : "#c3c2c2" }}  /> </th> */}
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("createdAt") }}>Date <SortingIcon iconStyle={{ color: sorting.columnName == "createdAt" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("analystName") }}>Assigned <SortingIcon iconStyle={{ color: sorting.columnName == "analystName" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("progressStatus") }}>Referral Status <SortingIcon iconStyle={{ color: sorting.columnName == "progressStatus" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("updatedAt") }}>Time <SortingIcon iconStyle={{ color: sorting.columnName == "updatedAt" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("referrerGroupName") }}>Referrer Group <SortingIcon iconStyle={{ color: sorting.columnName == "referrerGroupName" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("referrerName") }}>Referrer<SortingIcon iconStyle={{ color: sorting.columnName == "referrerName" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" onClick={() => { applySortingToApplications("referrerUserName") }}>Individual Referrer<SortingIcon iconStyle={{ color: sorting.columnName == "referrerUserName" ? "#000000" : "#c3c2c2" }} /> </th>
                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Actions</th>
                        </tr>
                    </thead>
                </>
            )
        }
    }

    //REFERRER CREATION BUTTON - ONLY FOR REFERER
    const QuickReferrerApplicationButton = () => {
        if (entityType == "referrer") {
            //CHECK PERMISSIONS IF REFERRER IS ALLOWED TO CREATE A REFERRAL
            console.log("userExtraInfo:::::::::::: ", userExtraInfo);

            let additionalInfo = userExtraInfo["additionalInfo"] || {};
            let allowToSubmitApplication = additionalInfo["allowToSubmitApplication"] !== undefined && additionalInfo["allowToSubmitApplication"] === false ? false : true;
            if (allowToSubmitApplication) {
                return (
                    <>
                        <div className='row'>
                            <div className='cols-m-12'>
                                <CustomButton
                                    buttonClassName="btn-primary btn-sm"
                                    isActive={true}
                                    buttonStyle={{ float: "right", marginBottom: "10px" }}
                                    buttonOnClick={() => { updateHandler("quickReferral", "") }}
                                    buttonText="Add New Referral"
                                />
                            </div>
                        </div>
                    </>
                )
            } else {
                return "";
            }
        }
        else {
            return "";
        }
    }


    const ApplicationCountStat = () => {
        if (entityType == "creditRep" && userRole == "admin") {
            return (
                <>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div style={{ display: "flex", justifyContent: "right", height: "100%", alignItems: "center" }}>
                                <IconButton
                                    iconType="verticalGraph"
                                    tootltip={true}
                                    tooltipDescription="Applications count against Status and Analysts"
                                    buttonStyle={{ background: "transparent", border: "0px" }}
                                    buttonOnClick={() => {
                                        updateHandler("applicationsCountAgaintStatusAndAnalyst");
                                    }}
                                />
                                <div style={{ display: "flex", justifyContent: "right", height: "100%", alignItems: "center" }}>
                                    <div style={{ paddingLeft: "10px" }}> Today: {applicationCountStat["today"]} </div>
                                    <div style={{ paddingLeft: "10px" }}> Current Week: {applicationCountStat["currentWeek"]} </div>
                                    <div style={{ paddingLeft: "10px" }}> Current Month: {applicationCountStat["currentMonth"]} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div style={{ display: "flex", justifyContent: "right", height: "100%", alignItems: "center" }}>
                                <div style={{ paddingLeft: "10px" }}> Unable To Assist: </div>
                                <div style={{ paddingLeft: "10px" }}> Today: {applicationCountStatForUnableToAssist["today"]} </div>
                                <div style={{ paddingLeft: "10px" }}> Current Week: {applicationCountStatForUnableToAssist["currentWeek"]} </div>
                                <div style={{ paddingLeft: "10px" }}> Current Month: {applicationCountStatForUnableToAssist["currentMonth"]} </div>
                            </div>
                        </div>

                    </div>



                </>
            )
        }
        else {
            return ("")
        }
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <QuickReferrerApplicationButton />

                {type == "all" || type == undefined ? <AdvanceSearch callback={updateHandler} /> : ""}
                <div className='row'>
                    <div className='col-sm-6'>
                        {/**label (Ref #, First Name, Surname, Amount, Purpose, Analyst ) */}
                        <Lookup label="" callback={updateHandler} />
                    </div>
                    <div className='col-sm-6'>
                        {type == "all" || type == undefined ?
                            <ApplicationCountStat /> : ""
                        }
                    </div>
                </div>

                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <TableHeaderHtml />
                                    <tbody className="fw-bold">
                                        {
                                            applicationsList && Array.isArray(applicationsList) ?
                                                applicationsList.map(singleApplication => (
                                                    <ApplicationListItem key={singleApplication.id + "-" + singleApplication.applicantId} singleApplication={singleApplication} type={type} callback={updateHandler} />
                                                )) : <tr>
                                                    <td colSpan={13}> <p> No Application is found, Please create one first</p> </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Pagination totalRecords={totalRecords} currentPageNumber={currentPageNumber} totalRecordsOnpage={10} updateCurrentPageNumber={updateHandler} />
            </div>


            <SingleApplicationActions name={callSingleApplicationActions["name"]} value={callSingleApplicationActions["value"]} callback={updateHandler} /> 
           
            {/*drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={updateHandler}>
                    {drawerSettings["childName"] === "assignToAnalyst" ? <AssignToAnalyst applicationId={drawerSettings["meta"]["applicationId"]} currentAnalystId={drawerSettings["meta"]["analystId"]} closeDrawer={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "updateProgressStatus" ? <UpdateProgressStatus applicationId={drawerSettings["meta"]["applicationId"]} currentProgressStatus={drawerSettings["meta"]["progressStatus"]} applicationType={drawerSettings["meta"]["applicationType"]} loanPurpose={drawerSettings["meta"]["loanPurpose"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "Comments" ? <Comments applicationId={drawerSettings["meta"]["applicationId"]} currentProgressStatus={drawerSettings["meta"]["progressStatus"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "fetchReferrerDetails" ? <AssignedReferrerDetails applicationId={drawerSettings["meta"]["applicationId"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "quickReferral" ? <QuickReferral callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "applicationFiles" ? <ApplicationFiles entityType="application" entityId={drawerSettings["meta"]["applicationId"]} enableUpload={true} /> : ""}
                    {drawerSettings["childName"] === "printApplication" ? <PrintApplication applicationId={drawerSettings["meta"]["applicationId"]} /> : ""}
                    {drawerSettings["childName"] === "applicationsCountAgaintStatusAndAnalyst" ? <ApplicationsCountAgaintStatusAndAnalyst /> : ""}
                    {drawerSettings["childName"] === "createDeclined" ? <CreateDeclined applicationId={drawerSettings["meta"]["applicationId"]} callback={() => { updateHandler("closeDrawer"); }} /> : ""}
                </RightDrawer> : ""
            */}
            {/*
                loading ? <LoadingScreen /> : ""
            */}
        </>
    )
}

export default ListApplications;