import axios from './axios';



/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getGeneralFiles = async (token) => {
  try{
    const response = await axios.get(`generalFiles/`, { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}
//fileType  =>(file, signatures)
const uploadGeneralFile = async(token, fileType, folderId, payload) => {
  try {
    console.log("fileType" , fileType)
    const response = await axios.post(`generalFiles/${fileType}/${folderId}`, payload, { headers: { Authorization: "Bearer " + token } });
    console.log("SERVER RESPONSE::: ", response);
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

const updateGeneralFile = async (token, fileId, fileObj) => {

  try {
    const response = await axios.put(`generalFiles/${fileId}`, fileObj, { headers: { Authorization: "Bearer " + token } });
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

const deleteGeneralFile = async(token, fileId) => {
  try { 
    const response = await axios.delete(`generalFiles/${fileId}`, { headers: { Authorization: "Bearer " + token } });
    console.log("SERVER RESPONSE::: ", response);
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

export { getGeneralFiles,  uploadGeneralFile, updateGeneralFile, deleteGeneralFile};