import { useEffect, useState } from "react";
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, RightDrawer, CustomButton } from '../../HtmlComponents';
import Constants from '../../global/Constants';
import { removeSpace } from '../../../helpers';
import LoadingScreen from '../../global/LoadingScreen';
import { downloadPpsrPdf } from "../../../api";
import { useToken } from "../../../hooks/useAuth";
import Notifications from "../../global/Notifications";

const Ppsr = ({ applicationId, callback }) => {

    const [submissionAllowed, setSubmissionAllowed] = useState(true);
    const [processing, setProcessing] = useState(false);

    const token = useToken();
 

    const makeAnAPICall = async () => {
        setProcessing(true);
        //MAKE AN API CALL
        var response = await downloadPpsrPdf(token, {applicationId: applicationId});

        if (response["error"] === 0 && response["data"]["fileLink"] !== undefined && response["data"]["fileLink"] !== null) {
            Notifications("success",  "PPSR report generated successfully");

            var fileLink = response["data"]["fileLink"];
            //OPEN THE FILE
            let alink = document.createElement('a');
            alink.href = fileLink;
            //alink.download = 'Equifax Report.pdf';
            alink.target = '_blank';
            alink.click();
            callback("closeDrawer", {});
        }
        else {
            //error message 
            Notifications("error", response["message"] !== undefined ? response["message"] : "Unknown Error occured.");
        }
        setProcessing(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="col-sm-12 mt-3">
                        <h2 className="error" style={{ fontSize: "16px" }}>
                            Car info must be fetched via API and saved before performing this PPSR operation.
                        </h2>
                    </div>
                </div>
            </div>

            <div className="row mt-3" >
                <div className="col-xl-12 mt-2">
                    {<CustomButton
                        buttonClassName=" btn btn-primary btn-sm "
                        buttonStyle={{ float: "right" }}
                        isActive={submissionAllowed}
                        buttonOnClick={makeAnAPICall}
                        buttonText="Download PPSR Report"
                    />}
                </div>


                {processing ? < LoadingScreen /> : ""}
            </div>
        </>
    )
}

export default Ppsr;