import axios from '../api/axios';

//LIST ALL INVOICES THAT ARE NOT PUSHED TO XERO YET
const listAllInsuranceInvocies = async(token, year, month) => {
  try {
    const response = await axios.get(`insurance/listAllInvocies/${year}/${month}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
} 

//submitInvociesToXero
const submitInsuranceInvociesToXero = async(token, year, month) => {
  try {
    const response = await axios.post(`insurance/submitInvociesToXero/${year}/${month}`,  {},  { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

export { listAllInsuranceInvocies, submitInsuranceInvociesToXero};