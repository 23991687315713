import { useEffect } from "react";
import { useState } from "react";
import { SelectField, CustomButton } from "../../HtmlComponents";
import { formatDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix , getLastDayOfMonth } from '../../../helpers';


const UserDefinedPeriod = ({ callback }) => {
    //const [month, setMonth] = useState("");     //current, previous
    const [duration, setDuration] = useState({ fromMonth: 0, fromYear: 0, toMonth: 0, toYear: 0 });

    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "july" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);



    const updateMonthsAndYears = () => {
        var localYears = [];
        for (var i = 2018; i < 2030; i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    useEffect(() => {
        updateMonthsAndYears();
    }, [])

    useEffect(() => {
        if (duration["fromMonth"] > 0 && duration["fromYear"] > 0 && duration["toMonth"] > 0 && duration["toYear"] > 0) {
            let startDate = 0;
            let endDate = 0;

            startDate = formatDate(`${duration["fromYear"]}-${duration["fromMonth"]}-01`, "YYYY-MM-DD" , "YYYY-MM-DD  HH:mm:ss" );
            endDate = formatDate( getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`) , "YYYY-MM-DD  HH:mm:ss" , "YYYY-MM-DD  HH:mm:ss");                              
            callback("userDefinedPeriod" , {startDate: startDate, endDate: endDate});
            //setAllowSubmission(true);
        }
       // else if (month !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [duration])

    const handler = (name, value) => {
        switch (name) {
            /*case "monthSelection":
                setMonth(value);
                break;*/
            case "fromMonth":
                setDuration({ ...duration, fromMonth: value })
                break;
            case "fromYear":
                setDuration({ ...duration, fromYear: value })
                break;
            case "toMonth":
                setDuration({ ...duration, toMonth: value })
                break;
            case "toYear":
                setDuration({ ...duration, toYear: value })
                break;
            case "clearFilters":
                setDuration({ ...duration, fromMonth : "", fromYear : "", toMonth: "", toYear:  "" });
                //setMonth("");
                callback("dateFilter" , {startDate: 0, endDate: 0});
                break;    
            case "submit":
                var startDate = 0;
                var endDate = 0;

                /*if (month.length > 0) {
                    if (month === "currentMonth") {
                        startDate = convertDateintoUnix(getCurrentMonth("firstDay") , "YYYY-MM-DD H:mm:ss") ;
                        endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    } else {
                        startDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getPreviousMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    }
                }*/
                
                    //DURATION IS SELECTED
                /*startDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
                endDate = convertDateintoUnix(getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`) , "YYYY-MM-DD H:mm:ss");                   
            
                callback("dateFilter" , {startDate: startDate, endDate: endDate});*/
                break;
        }
    }

    return (<>

                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="row">
                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>From Month</label>
                                <SelectField
                                    fieldName="fromMonth"
                                    defaultSelectedOption={{ value: duration['fromMonth'], label: "" }}
                                    options={months}
                                    setFieldValue={handler}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>From Year</label>
                                <SelectField
                                    fieldName="fromYear"
                                    defaultSelectedOption={{ value: duration['fromYear'], label: "" }}
                                    options={years}
                                    setFieldValue={handler}
                                />
                            </div>

                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>To Month</label>
                                <SelectField
                                    fieldName="toMonth"
                                    defaultSelectedOption={{ value: duration['toMonth'], label: "" }}
                                    options={months}
                                    setFieldValue={handler}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>To Year</label>
                                <SelectField
                                    fieldName="toYear"
                                    defaultSelectedOption={{ value: duration['toYear'], label: "" }}
                                    options={years}
                                    setFieldValue={handler}
                                />
                            </div>

                        </div>
                    </div>
 
                </div>
    </>)
}

export default UserDefinedPeriod;