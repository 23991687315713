

import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, CustomButton } from '../../HtmlComponents';
import { useState } from 'react';

const LoanCalculator = ({ callback }) => {

    const [paylaod, setPayload] = useState({ borrowingAmount: 0, interestRate: 0, monthlyRepayment: 0, loanTerm: 0, balloonAmount: 0, repaymentType: "", whatToCalculate: "" });

    const calculateRepayments = async (setFieldValue) => {

        let interestRate = 0;
        interestRate = interestRate / 100;
        interestRate = interestRate / 12;

        let loanTerm = 80;
        let totalFinancedAmount = 90000;
        let balloonAmount = 0;
        let repaymentType = 0;


        //CALCULATE PAYMENT WITHOUT INSURANCES
        // let monthlyRepayment = await PMT(interestRate, loanTerm, -totalFinancedAmount, balloonAmount, repaymentType);



    }
    const PMT = async () => {

        var borrowingAmount = paylaod["borrowingAmount"]
        var interestRate = paylaod["interestRate"] / 1200;
        var monthlyRepayment = paylaod["monthlyRepayment"];
        var loanTerm = paylaod["loanTerm"]
        var balloonAmount = paylaod["balloonAmount"];
        var repaymentType = paylaod["repaymentType"];


        switch (paylaod["whatToCalculate"]) {
            case "monthlyRepayment":
                var currentBorrowingAmount = -borrowingAmount;
                var when = repaymentType == "arrears" ? 0 : 1;  // 
                if (interestRate == 0) { monthlyRepayment = - (parseFloat(balloonAmount) + parseFloat(currentBorrowingAmount)) / loanTerm; }
                else { monthlyRepayment = - (parseFloat(balloonAmount) + parseFloat((currentBorrowingAmount * Math.pow((1 + parseFloat(interestRate)), loanTerm)))) / ((1 + parseFloat(interestRate * when)) / interestRate * (Math.pow((1 + parseFloat(interestRate)), loanTerm) - 1)); }
                setPayload({ ...paylaod, monthlyRepayment: monthlyRepayment.toFixed(2) });
                break;

            case "loanTerm":
                //Type    Optional. The number 0 or 1 and indicates when payments are due.
                var type = 0;

                // BALLOON AMOUNT
                var future = balloonAmount;

                // Return number of periods
                var num = monthlyRepayment * (1 + monthlyRepayment * type) - future * interestRate;
                var den = (-borrowingAmount * interestRate + monthlyRepayment * (1 + interestRate * type));
                var loanTerm = Math.log(num / den) / Math.log(1 + interestRate);
                setPayload({ ...paylaod, loanTerm: loanTerm.toFixed(2) });
                break;
            case "borrowingAmount":

                if (interestRate == 0) { // Interest rate is 0
                    borrowingAmount = -(balloonAmount + (monthlyRepayment * loanTerm));
                } else {
                    var x = Math.pow(1 + interestRate, -loanTerm);
                    var y = Math.pow(1 + interestRate, loanTerm);
                    borrowingAmount = - (x * (balloonAmount * interestRate - monthlyRepayment + y * monthlyRepayment)) / interestRate;
                }
                setPayload({ ...paylaod, borrowingAmount: -borrowingAmount.toFixed(2) });
                break;
            case "balloonAmount":
                var pow = Math.pow(1 + interestRate, loanTerm);
                var type = 1;
                if (interestRate > 0) {
                    balloonAmount = (monthlyRepayment * (1 + interestRate * type) * (1 - pow) / interestRate) - borrowingAmount * pow;
                } else {
                    balloonAmount = -1 * (borrowingAmount + monthlyRepayment * loanTerm);
                }
                setPayload({ ...paylaod, balloonAmount: -balloonAmount.toFixed(2) });


                 /*

                  if ( interestRate == 0 ) {  
                    balloonAmount = -(borrowingAmount + (monthlyRepayment * loanTerm));
                  } else {
                    let x = Math.pow(1 + interestRate, loanTerm);
                    balloonAmount = - ( -monthlyRepayment + x * monthlyRepayment + interestRate * x * borrowingAmount ) /interestRate;
                  }
                  setPayload({ ...paylaod, balloonAmount: -balloonAmount.toFixed(2) });
                  */
                break;
        }
        try {
        }
        catch (e) { return 0; }

        /*
        { value: "balloonAmount", label: "Balloon Amount" },*/

    }

    const handler = (name, value) => {
        switch (name) {
            case "whatToCalculate":
                setPayload({ ...paylaod, whatToCalculate: value })
                break;
            case "borrowingAmount":
                setPayload({ ...paylaod, borrowingAmount: value })
                break;
            case "interestRate":
                setPayload({ ...paylaod, interestRate: value })
                break;
            case "loanTerm":
                setPayload({ ...paylaod, loanTerm: value })
                break;
            case "monthlyRepayment":
                setPayload({ ...paylaod, monthlyRepayment: value })
                break;
            case "balloonAmount":
                setPayload({ ...paylaod, balloonAmount: value })
                break;
            case "repaymentType":
                setPayload({ ...paylaod, repaymentType: value })
                break;

        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <label className="required fs-8 fw-bold mb-2">Select what do you want to calculate?</label>
                    <SelectField
                        fieldName="whatToCalculate"
                        defaultSelectedOption={{ value: paylaod["whatToCalculate"], label: "" }}
                        options={[
                            { value: "monthlyRepayment", label: "Monthly Repayment" },
                            { value: "loanTerm", label: "Loan Term (Months)" },
                            { value: "borrowingAmount", label: "Borrowing Amount" }
                        ]}
                        setFieldValue={handler}
                    />
                </div>

                <div className='col-sm-6 mt-3'>
                    <label className="required fs-8 fw-bold mb-2">How much would you like to borrow?</label>
                    <InputField
                        fieldName="borrowingAmount"
                        placeholder="Borrowing Amount"
                        fieldValue={paylaod["borrowingAmount"]}
                        setFieldValue={handler} />
                </div>

                <div className='col-sm-6  mt-3'>
                    <label className="required fs-8 fw-bold mb-2">Interest Rate?</label>
                    <InputField
                        fieldName="interestRate"
                        placeholder="Intetrest Rate"
                        fieldValue={paylaod["interestRate"]}
                        setFieldValue={handler} />
                </div>

                <div className='col-sm-6  mt-3'>
                    <label className="required fs-8 fw-bold mb-2">Loan Term (Months)?</label>
                    <InputField
                        fieldName="loanTerm"
                        placeholder="Loan Term (Months)"
                        fieldValue={paylaod["loanTerm"]}
                        setFieldValue={handler} />
                </div>
                <div className='col-sm-6  mt-3'>
                    <label className="required fs-8 fw-bold mb-2">Monthly Repayment?</label>
                    <InputField
                        fieldName="monthlyRepayment"
                        placeholder="Monthly Repayment"
                        fieldValue={paylaod["monthlyRepayment"]}
                        setFieldValue={handler} />
                </div>
                <div className='col-sm-6  mt-3'>
                    <label className="required fs-8 fw-bold mb-2">Balloon Amount?</label>
                    <InputField
                        fieldName="balloonAmount"
                        placeholder="Balloon Amount"
                        fieldValue={paylaod["balloonAmount"]}
                        setFieldValue={handler} />
                </div>

                <div className='col-sm-6 mt-3'>
                    <label className="required fs-8 fw-bold mb-2">Type?</label>
                    <SelectField
                        fieldName="repaymentType"
                        defaultSelectedOption={{ value: paylaod["repaymentType"], label: "" }}
                        options={[
                            { value: "arrears", label: "Arrears" },
                            { value: "advance", label: "Advance" },
                        ]}
                        setFieldValue={handler}
                    />
                </div>

                <div className='col-sm-12 mt-3 '>
                    <CustomButton
                        buttonClassName="btn btn-primary "
                        isActive={true}
                        buttonStyle={{ float: "right" }}
                        buttonOnClick={() => { PMT() }}
                        buttonText="Calculate"
                    />
                </div>

                <div className='col-sm-12 mt-5'>
                    <div className='row'>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">Borrowing Amount: </label></div>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">$ {paylaod["borrowingAmount"]} </label></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">Monthly Repayment Amount: </label></div>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">$ {paylaod["monthlyRepayment"]} </label></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">Interest Rate: </label></div>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2"> {paylaod["interestRate"]} %</label></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">Loan Term: </label></div>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2"> {paylaod["loanTerm"]} months</label></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">Balloon Amount: </label></div>
                        <div className='col-sm-6'> <label className=" fs-8 fw-bold mb-2">$ {paylaod["balloonAmount"]} </label></div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LoanCalculator;