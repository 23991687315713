import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { getNotes , deleteNote } from '../../api';

import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import CreateNote from './CreateNote';
import NoteListItem from './NoteListItem';
import Notifications from '../global/Notifications';

const ListNotes = ({ referrerId }) => {
    const [notes, setNotes] = useState([]);
    const token = useToken();

    //FETCH NOTES FROM API
    const fetchNotes = async () => {
        var response = await getNotes(token, referrerId);
        console.log(response);
        if (response["error"] == 0 && response["data"]) {
            setNotes(response["data"]);
        }
    }

    const handler = async(action, value) =>{
        switch(action){
            case "loadNotes" : 
            fetchNotes();
            break;
            case "deleteNote":
                console.log("DELETE NOTEEEEEEEEEEEEEEEEEE" , value)
                var result = await deleteNote(token, value);
                if(result["error"] == 0){ 
                    Notifications("success" , result["message"])
                    fetchNotes();
                }
                else{
                    Notifications("error" , result["message"])
                }
                break;
        }
    }

    useEffect(() => {
        fetchNotes();
    }, [])

    return (
        <>
            <div className='d-flex row'>
                <CreateNote referrerId={referrerId} callback={handler} />
                <h3>Notes</h3>
                <div className='col-sm-12' style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer'>
                        <tbody>
                            {Array.isArray(notes) ? notes.map((singleNote, index) => { return <NoteListItem singleNote={singleNote} callback={handler}  key={index} /> }) : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )

}

export default ListNotes;