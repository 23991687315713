

const SingleBankStatementLink = ({ singleBankStatementLink }) => {
    return (
        <>
            <tr className="">
                <th>{singleBankStatementLink.subject !== undefined ? singleBankStatementLink.subject: "NA"} </th>
                <th>{singleBankStatementLink.duration !== undefined ? singleBankStatementLink.duration+ " Months": "NA"} </th>
                <th>{singleBankStatementLink.message !== undefined ?  <div dangerouslySetInnerHTML={{ __html: singleBankStatementLink.message }} />: "NA"} </th>
            </tr>
        </>
    )
}

export default SingleBankStatementLink;