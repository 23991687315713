

const Error500 = () => {

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <img src="/media/illustrations/general/500-error.png" alt=""  />
            </div>

        </>
    )
}

export default Error500;