import { useEffect } from "react";
import { useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getAnalystGrossIncomeReport } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";


const AnalystGrossIncomeReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [grossIncomeReport, setGrossIncomeReport] = useState({});
    const [analystCommission, setAnalystCommission] = useState(0);

    const token = useToken();

    const calculateAnalystCommission = (grossIncome) => {
        var commissionPercentage = 0;
        //var grossIncome = grossIncomeReport["grossIncome"] !== undefined ? grossIncomeReport["grossIncome"] : 0 ;

        switch (true) {
            case grossIncome < 13500: setAnalystCommission(0); break;
            case grossIncome >= 13500 && grossIncome < 18000: commissionPercentage = 0.09; break;
            case grossIncome >= 18000 && grossIncome < 27000: commissionPercentage = 0.10; break;
            case grossIncome >= 27000 && grossIncome < 36000: commissionPercentage = 0.11; break;
            case grossIncome >= 36000 && grossIncome < 45000: commissionPercentage = 0.1250; break;
            case grossIncome >= 45000 && grossIncome < 56000: commissionPercentage = 0.1350; break;
            case grossIncome >= 56000: commissionPercentage = 0.15; break;
        }
        if (grossIncome !== undefined && grossIncome !== null) {
            try {
                setAnalystCommission((grossIncome * commissionPercentage).toFixed(2));
            }
            catch (e) { console.log("GROSS INCOME : ", grossIncome); console.log(e); }
        }
    }


    //FETCH ANALYST GROSS INCOME REPORT
    const fetchAnalystGrossIncomeReport = async () => {
        setProcessing(true);
        var response = await getAnalystGrossIncomeReport(token, startDate, endDate);
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined) {
            setGrossIncomeReport(response["data"]);
            //CALCULATE ANALYST COMMISSION
            calculateAnalystCommission(response["data"]["grossIncome"] !== undefined ? response["data"]["grossIncome"] : 0);
        }
        setProcessing(false);
    }


    useEffect(() => {
        fetchAnalystGrossIncomeReport();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="bold mb-4">My Gross Income</div>

                    {processing ? <LoadingBlock /> :

                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-175px text-start">Income Type </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                    <tr>
                                        <td>Insurance Income	</td>
                                        <td>$  {grossIncomeReport["insuranceCommission"] !== undefined ? grossIncomeReport["insuranceCommission"] : 0} </td>
                                    </tr>
                                    <tr>
                                        <td>Origination Fee	</td>
                                        <td>$ {grossIncomeReport["originationFee"] !== undefined ? grossIncomeReport["originationFee"] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Finance Commission	</td>
                                        <td>$ {grossIncomeReport["financeCommission"] !== undefined ? grossIncomeReport["financeCommission"] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Brokerage Fee	</td>
                                        <td>$ {grossIncomeReport["brokerageFee"] !== undefined ? grossIncomeReport["brokerageFee"] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Gross Income	</td>
                                        <td>$ {grossIncomeReport["grossIncome"] !== undefined ? grossIncomeReport["grossIncome"] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Average Gross Income Per Deal	</td>
                                        <td>$ {grossIncomeReport["grossIncome"] !== undefined && grossIncomeReport["grossIncome"] > 0 && grossIncomeReport["totalSettledApplications"] !== undefined && grossIncomeReport["totalSettledApplications"] > 0 ?
                                            (grossIncomeReport["grossIncome"] / grossIncomeReport["totalSettledApplications"]).toFixed(2) : 0
                                        }</td>
                                    </tr>
                                    <tr>
                                        <td>Individual Average Per Deal	</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Business Gross Income Per Deal	</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>NAF	</td>
                                        <td>$ {grossIncomeReport["naf"] !== undefined ? grossIncomeReport["naf"] : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Average NAF	</td>
                                        <td>$ {grossIncomeReport["naf"] !== undefined && grossIncomeReport["naf"] > 0 && grossIncomeReport["totalSettledApplications"] !== undefined && grossIncomeReport["totalSettledApplications"] > 0 ?
                                            (grossIncomeReport["naf"] / grossIncomeReport["totalSettledApplications"]).toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>My Commission	</td>
                                        <td>$ {analystCommission}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AnalystGrossIncomeReport;
