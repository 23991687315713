const ErrorMessage = (props) => {
    const isErrorExist = props.isErrorExist;
    const errorMessage = props.errorMessage;

    if (isErrorExist) {
        return (
            <div className="col-xl-12">
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                            <div className="fs-6 text-gray-700"> {errorMessage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else { return ""; }
}

    export default ErrorMessage;