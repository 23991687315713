import { Editor } from '@tinymce/tinymce-react';


const TextEditor = ({fieldName , initialValue , height , setFieldValue}) => {
    return (
        <>
            <Editor
                apiKey='3jpmykh87bn3qvzlegt0hwgxa2il3pr75pjudotrcbqakoat'
                initialValue= {initialValue}
                onEditorChange={(e) => {
                    setFieldValue( fieldName , e)
                }}
                init={{
                    height: height,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    contextmenu: "paste | link image inserttable | cell row column deletetable",
                    
                }}
            />
        </>
    )
}

export default TextEditor;
