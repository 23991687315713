
import React, { useEffect, useState, useRef } from 'react';
import { useEntityType, useRole, useToken, useEntityId } from '../../hooks/useAuth';
import { FormSubmissionButton, SelectField, CheckBox, MultiSelectField, MaskedField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { addUser, getCreditRepList, getReferrerGroupsList, getReferrerGroupsListAgainstCreditRep, addReferrerGroupManager } from "../../api";
import { AddReferrerGroupManagerSchema } from "./schema/ReferrerGroupManagerSchema";
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../global/LoadingScreen';
import Constants from '../global/Constants';
import { dateFilter, convertDateintoUnix, formatDate } from '../../helpers';

const AddReferrerGroupManager = ({ callback }) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    //let { entityType, entityId } = useParams();
    const [user, setUser] = useState({
        userName: '', firstName: '', surname: '', email: '', password: '', role: "", entityType: '', entityId: 0, contactNumber: '', landlineNumber: '',
        extraInfo: {
            occupations: [],
            additionalInfo: {
                applicationUpdatesForAllSystemUsers: false,
                systemAccessGranted: false,
                exportEmail: false,
                statusNotificationViaEmail: false,
                statusNotificationViaSMS: false
            }
        },
        isTwoFaEnabled: false,
        creditRepId: 0,
        assignedReferrerGroups: []
    });

    const [creditRepList, setCreditRepList] = useState([]);
    const [referrerGroupsList, setReferrerGroups] = useState([]);

    const [creditRepOptions, setCreditRepOptions] = useState([]);   //CREDIT REP OPTIONS FOR DROP DOWN FIELD SO SUPER ADMIN CAN SELECT A CREDIT REP TO LOAD REFERRER GROUPS
    const [referrerGroupOptions, setReferrerGroupOptions] = useState([]); //REFERRER GROUP OPTIONS FOR DROP DOWN FIELD SO SUPER ADMIN | ADMIN CAN SELECT A REFERRER GROUP 
    const [loading, setLoading] = useState(false);


    const userRole = useRole();
    const entityId = useEntityId();
    const entityType = useEntityType(); //ENTITY TYPE OF LOGGED IN USER (COMPANY | CREDITREP)
    const token = useToken();

    //UPDATE THE USER ROLE ACCORDING TO THE ENTITY TYPE
    useEffect(() => {
        const decideToLoadCreditRepsOrReferrerGroups = async () => {
            if (userRole === "superAdmin" && entityType === "company") {
                setLoading(true);
                const creditReps = await getCreditRepList(entityId, token);
                console.log("creditReps ::::::::::::::::::::   ", creditReps);
                if (Array.isArray(creditReps) && creditReps.length > 0) {
                    let tmpCreditRepOptions = creditReps.map((creditRep) => {
                        return { value: creditRep.id, label: creditRep.tradingName }
                    });
                    setCreditRepOptions(tmpCreditRepOptions);
                }
                setLoading(false);
            }
        }
        decideToLoadCreditRepsOrReferrerGroups();
    }, [])

    const loadReferrerGroups = async (creditRepId) => {
        setLoading(true);
        //const referrerGroups = await getReferrerGroupsList(token); 
        const response = await getReferrerGroupsListAgainstCreditRep(creditRepId, token);
        console.log("referrerGroups response ------- ::::::::::::::::::::   ", response);
        if (response["error"] == 0 && response["referrerGroups"] !== undefined && Array.isArray(response["referrerGroups"]) && response["referrerGroups"].length > 0) {
            let tmpReferrerGroupOptions = response["referrerGroups"].map((referrerGroup) => { return { value: referrerGroup.id, label: referrerGroup.tradingName } });
            setReferrerGroupOptions(tmpReferrerGroupOptions);
        }
        setLoading(false);
    }

    return <>
        <Formik
            initialValues={user}
            validationSchema={AddReferrerGroupManagerSchema}
            validateOnBlur={true}

            onSubmit={async (values) => {
                setError("");
                const response = await addReferrerGroupManager(token, values);
                console.log("RESPONSE------------------", response);
                if (response["error"]) {
                    Notifications("error", response["message"] || "Error")
                    setUser(values);
                } else {
                    Notifications("success", response["message"] || "Created Successfully")
                    callback(response.user);
                }
            }}
        >
            {({ errors, touched, setFieldValue, handleChange }) => (
                <Form>
                    <div id="kt_content_container" className="container-xxl">
                        <div className="row g-12 g-xl-12">
                            <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                            <div className="col-xl-12">
                                <div className="card mb-xl-4">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2">UserName</label>
                                                <Field name="userName" placeholder="User Name" className="form-control form-control-solid" autoComplete="off" />
                                                {errors.userName ? (<div className="error">{errors.userName}</div>) : null}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2">First Name </label>
                                                <Field name="firstName" placeholder="First Name" className="form-control form-control-solid" />
                                                {errors.firstName ? (<div className="error">{errors.firstName}</div>) : null}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2 mt-4">Surname </label>
                                                <Field name="surname" placeholder="Surname" className="form-control form-control-solid" />
                                                {errors.surname ? (<div className="error">{errors.surname}</div>) : null}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2  mt-4">Email</label>
                                                <Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                                {errors.email ? (<div className="error">{errors.email}</div>) : null}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2  mt-4">Mobile Number </label>
                                                <Field name="contactNumber" placeholder="Mobile Number" className="form-control form-control-solid" />
                                                {errors.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2  mt-4">Landline Number </label>
                                                <Field name="landlineNumber" placeholder="Landline Number" className="form-control form-control-solid" />
                                                {errors.landlineNumber ? (<div className="error">{errors.landlineNumber}</div>) : null}
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2  mt-4">Password</label>
                                                <Field type="password" name="password" placeholder="Password" className="form-control form-control-solid" />
                                                {errors.password ? (<div className="error">{errors.password}</div>) : null}
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div style={{ marginTop: "44px" }}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-start" style={{ fontWeight: "normal", width: "30px" }}>
                                                                    <Field name="isTwoFaEnabled">
                                                                        {({ field }) => (
                                                                            <CheckBox name="isTwoFaEnabled" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                                        )}
                                                                    </Field>
                                                                </td>
                                                                <td className="text-start">2 FA Eabled</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <label className=" fs-8 fw-bold mb-2  mt-4">Credit License Date </label>
                                                <Field type="text" name="extraInfo.creditLicenseDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                                    {({ field }) => (
                                                        <MaskedField fieldName="extraInfo.creditLicenseDate" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                                    )}
                                                </Field>
                                                {errors.extraInfo && errors.extraInfo.creditLicenseDate ? (<div className="error">{errors.extraInfo.creditLicenseDate}</div>) : null}
                                            </div>

                                            <div className='col-sm-12 col-md-12 mt-3'>
                                                <label>Select Credit Rep to load relevant Referrer Groups</label>
                                                <Field name="creditRepId">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="creditRepId"
                                                            defaultSelectedOption={{ value: field.value, label: "Select Credit Rep" }}
                                                            options={creditRepOptions}
                                                            setFieldValue={setFieldValue}
                                                            callback={(name, value) => { loadReferrerGroups(value); }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className='col-sm-12 col-md-12 mt-3'>
                                                <label>Select Referrer Groups</label>
                                                <Field name="assignedReferrerGroups">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="assignedReferrerGroups"
                                                            options={referrerGroupOptions}
                                                            defaultSelectedOptions={field.value}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div >

                                {<FormSubmissionButton isActive={true} />}
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    </>
}


export default AddReferrerGroupManager;