//AuthoriyConsentFormValidationschema.js

import * as Yup from 'yup';

const AuthoriyConsentFormValidationschema =  Yup.object().shape({
    templateId : Yup.string().required("Required"),
    signer: Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email("Invalid email").required("Email required")
     })

});
 
export default AuthoriyConsentFormValidationschema ;