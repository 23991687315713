import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ConfirmationAlert = (props) => {
    var icon = props.icon || "info";
    var heading = props.heading;
    var html = props.html;
    var showConfirmButton = props.showConfirmButton || false;
    var confirmButtonText = props.confirmButtonText || "Yes";

    var showDenyButton = props.showDenyButton || false;
    var denyButtonText = props.denyButtonText || "No";

    var confirmCallBack = props.confirmCallBack;
    var denyCallBack = props.denyCallBack;



    MySwal.fire({
        title: heading,
        html: html,
        icon: icon,
        showDenyButton: showDenyButton,
        showConfirmButton: showConfirmButton,
        confirmButtonText: confirmButtonText,
        confirmButtonColor : "#009ef7",
        denyButtonText: denyButtonText,
        denyButtonColor: "#e4e6ef",


        showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
    }).then((result) => {
        if (result.isConfirmed) { if(typeof confirmCallBack == "function"){confirmCallBack();}   } 
        else  { if(typeof denyCallBack == "function"){denyCallBack();} }
      })
}

export { ConfirmationAlert }