
import {useIsAuthenticated}  from '../../hooks/useAuth';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';

const DefaultComponent = () =>{
    let navigate = useNavigate();
    const isUSerAuthenticaetd = useIsAuthenticated();

    useEffect(() =>{

        if(isUSerAuthenticaetd){
            navigate("/user");
            console.log("NAVIGATEING TO DASH PAGE - USER LOGGED IN ");
         } 
         else{
            navigate("/login");
            console.log("NAVIGATEING TO LOGIN PAGE");
         }
    })
}

export default DefaultComponent;