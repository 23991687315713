import SingleUser from './SingleUser';

const ListUsers = ({users, usersStatus, callback}) =>{
        return(
            <>             
                <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                    <thead>
                        <tr className="fw-bolder fs-6 bg-light">
                            {usersStatus == "inActive" ? "": <th>User Name</th> } 
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Landline</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="fw-bold  fw-bold text-gray-600">
                        {
                            Array.isArray(users) ?
                            users.map(user => (
                                <SingleUser key={user.id} user ={user} usersStatus={usersStatus} callback= {callback} />
                            ))
                            : <tr></tr>
                        }
                    </tbody>
                </table>
            </>
        )
}

export default ListUsers;