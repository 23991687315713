import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Quote, PersonalDetail, Income, Assets, Debts, Expenses } from '../consumerApplication/components';
import ConsumerApplicationSchema from '../consumerApplication/schema/ConsumerApplicationSchema.json';
import { RightDrawer, SelectField, CustomButton } from '../../components/HtmlComponents';
import AddAnotherApplicant from '../../components/applications/appComponents/AddAnotherApplicant';
import { useToken } from '../../hooks/useAuth';
import { createConsumerApplication, updateApplication, getApplication, createApplicant, updateApplicant, getApplicationMatch, performApplicationAdditionalActions } from "../../api";
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../../components/global/LoadingScreen';
import { useNavigate } from 'react-router-dom'
import { capitalizeFirst, formatDate, calculateMonthlyAmount } from "../../helpers";

//UTILITIES 
import ConsumerApplicationUtilities from './ConsumerApplicationUtilities';

const ConsumerApplication = () => {
    const quoteRef = useRef();
    const personalDetailsRef = useRef();
    const incomeRef = useRef();
    const assetsRef = useRef();
    const debtsRef = useRef();
    const expensesRef = useRef();
    const navigate = useNavigate();

    const [application, setApplication] = useState(ConsumerApplicationSchema);
    const [applicationId, setApplicationId] = useState(0);
    const [applicants, setApplicants] = useState([]);       //list of all applicants against this application    
    const [listApplicants, setListApplicants] = useState([]);       //list of all applicants for dropdown
    const [applicant, setApplicant] = useState(ConsumerApplicationSchema["applicants"][0]); // ALWAYS LOAD FIRST APPLICANT ON APPLICATION LOADING - ConsumerApplicationSchema GOT AN ARRAY OF APPLICANTS
    const [applicantId, setApplicantId] = useState(0);
    const [openTab, setOpenTab] = useState("quote");  // BY DEFAULT QUOTE TAB SHOULD OPEN,  //quote
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [applicationMatchMessage, setApplicationMatchMessage] = useState("");

    const [processing, setProcessing] = useState(true);
    const [serverStatusCode, setServerStatusCode] = useState(null);


    let { id } = useParams();
    const token = useToken();

    //ASSIGN PRIMARY APPLICANT TO APPLICANT STATE ON FIRST LOADING
    const assignPrimaryApplicant = () => {
        var primaryApplicant = applicants.filter(applicant => applicant.isPrimaryApplicant == 1)
        if (primaryApplicant[0] != undefined) {
            setApplicant(primaryApplicant[0]);
            setApplicantId(primaryApplicant[0].id)
        }
    }

    //PARSE THE DATA AND UPDATE THE APPLICATION STATE
    function parseAndUpdateApplicationState(applicationObj) {
        //PARSE JSON STRINGS
        applicationObj.loan = JSON.parse(applicationObj.loan);
        applicationObj.financial = JSON.parse(applicationObj.financial);
        applicationObj.buyingItem = JSON.parse(applicationObj.buyingItem);
        applicationObj.repayments = JSON.parse(applicationObj.repayments);

        // applicationObj.personalDetail = JSON.parse(applicationObj.personalDetail);
        // applicationObj.income = JSON.parse(applicationObj.income);
        // applicationObj.assets = JSON.parse(applicationObj.assets);
        // applicationObj.debts = JSON.parse(applicationObj.debts);
        // applicationObj.expenses = JSON.parse(applicationObj.expenses);

        setApplication(applicationObj);

    }

    //SUBMIT APPLICATION (ONLY QUOTE SECTION ) TO SERVER CREATING NEW ONE | UPDATING EXISTING ONE
    const submitApplicationToServer = async (moduleName, values) => {
        setProcessing(true);
        try {
            let insurances = values["insurances"] !== undefined ? values["insurances"] : []
            let tmpInsurances = await formatInsurances(insurances, "YYYY-MM-DD");

            //IF APPLICATION ID IS 0 IT MEANS WE ARE CREATING A NEW APPLICATION SO ONLY QUOTE COMPONENT WILL PASS THROUGH IN THIS CASE
            if (applicationId === 0 && moduleName === "quote") {
                var response = await createConsumerApplication(values, token);
                if (response.httpCode == 200 && response.application !== undefined && response.application.id !== undefined && response.application.id != 0) {
                    setApplicationId(response.application.id);
                    Notifications("success", response.message || "Created Successfully.")
                    //REDIRECTION | LOAD THE SAME PAGE AGAIN BUT WITH APPLICATION ID IN THE URL
                    navigate(`/user/editConsumerApplication/${response.application.id}`);
                    //parseAndUpdateApplicationState(response.application);
                }
                else { Notifications("error", response.message || "Error"); }
            }
            //IF APPLICATION ID EXISTS , IT MEANS WE DO HAVE AN APPLICATION SO ANY COMPONENT CAN PASS THROUGH NOW LIKE EXPENSES, ASSETS ETC
            else {
                //APPLICATION ID IS NOT 0 => MEANS THAT WE ARE UPDATING EXISTING APPLICATION
                if (applicationId !== 0 && moduleName === "quote") {
                    var response = await updateApplication(applicationId, values, token);
                    if (response.httpCode == 200) {
                        Notifications("success", response.message || "Updated Successfully.")
                    }
                    else {
                        Notifications("error", response.message || "Error")
                    }
                }
            }
            //UPDATE APPLICAITON STATE 
            values.insurances = tmpInsurances;
            setApplication(values);

            //UPDATE DEFAULT OPEN TAB
            setOpenTab("quote");
        }
        catch (e) {
            console.log("Exception :", e)
        }
        setProcessing(false);
    }

    //SUBMIT APPLICANT TO SERVER CREATING NEW ONE | UPDATING EXISTING ONE
    const submitApplicantToServer = async (moduleName) => {
        setProcessing(true);
        //IF APPLICATION ID IS 0 - THROW AN ERROR
        if (applicationId === 0) {
            Notifications("error", "Please create the quote first")
        }
        //IF APPLICATION ID EXISTS , THEN IT MIGHT CREATING OR UPDATING THE EXISTING APPLICANT
        else {
            let tmpValues = {};
            var values = {};
            switch (moduleName) {
                case "personalDetail": 
                tmpValues = personalDetailsRef.current.values;
                //CHECK IF RESIDENTIAL HISTORY PROVIDED, CURRENT RENT PROVIDED AND FREQUENCY IS NOT MONTHLY THEN CONVERT IT INTO MONTHLY
                try{
                    if (tmpValues["residentialHistory"] !== undefined && tmpValues["residentialHistory"]["current"] !== undefined &&  tmpValues["residentialHistory"]["current"]["rent"] !== undefined && !isNaN(parseFloat(tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"])) && tmpValues["residentialHistory"]["current"]["rent"]["frequency"] !== undefined && tmpValues["residentialHistory"]["current"]["rent"]["frequency"] !== "monthly") {
                        tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"] = calculateMonthlyAmount(tmpValues["residentialHistory"]["current"]["rent"]["frequency"], tmpValues["residentialHistory"]["current"]["rent"]["applicantShareOfMonthlyRent"]);
                        tmpValues["residentialHistory"]["current"]["rent"]["frequency"] = "monthly";
                    }
                }
                catch(e){ }
                values = { "personalDetail": tmpValues }; 
                break;                
                case "income":
                    tmpValues = incomeRef.current.values; 
                    if (tmpValues["income"] !== undefined) {
                        //PRIMARY INCOME
                        var primaryIncomeArray = tmpValues["income"]["primaryIncome"];
                        if (primaryIncomeArray !== undefined && Array.isArray(primaryIncomeArray) && primaryIncomeArray.length > 0) {
                            var primaryIncome = primaryIncomeArray[0];
                            if (primaryIncome["netIncome"] !== undefined && !isNaN(parseFloat(primaryIncome["netIncome"])) && primaryIncome["frequencey"] !== undefined && primaryIncome["frequencey"] !== "monthly") {
                                primaryIncome["netIncome"] = calculateMonthlyAmount(primaryIncome["frequencey"], primaryIncome["netIncome"]);
                                primaryIncome["grossIncome"] = calculateMonthlyAmount(primaryIncome["frequencey"], primaryIncome["grossIncome"]);
                                primaryIncome["frequencey"] = "monthly";
                            }

                            primaryIncomeArray[0] = primaryIncome;
                            tmpValues["income"]["primaryIncome"] = primaryIncomeArray;
                        }

                        //SECONDARY INCOME
                        var secondaryIncomeArray = tmpValues["income"]["secondaryIncome"];
                        if(Array.isArray(secondaryIncomeArray) && secondaryIncomeArray.length > 0){
                            var tmpSecondaryIncome = secondaryIncomeArray.map((income) => {
                                var tmpIncome = { ...income };
                                if (tmpIncome["netIncome"] !== undefined && !isNaN(parseFloat(tmpIncome["netIncome"])) && tmpIncome["frequencey"] !== undefined && tmpIncome["frequencey"] !== "monthly") {
                                    tmpIncome["netIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["netIncome"]);
                                    tmpIncome["grossIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["grossIncome"]);
                                    tmpIncome["frequencey"] = "monthly";
                                }
                                return tmpIncome;
                            });
                            tmpValues["income"]["secondaryIncome"] = tmpSecondaryIncome;
                        }

                        //PREVIOUS INCOME
                        var previousIncomeArray = tmpValues["income"]["previousIncome"];
                        if(Array.isArray(previousIncomeArray) && previousIncomeArray.length > 0){
                            var tmpPreviousIncome = previousIncomeArray.map((income) => {
                                var tmpIncome = { ...income };
                                if (tmpIncome["netIncome"] !== undefined && !isNaN(parseFloat(tmpIncome["netIncome"])) && tmpIncome["frequencey"] !== undefined && tmpIncome["frequencey"] !== "monthly") {
                                    tmpIncome["netIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["netIncome"]);
                                    tmpIncome["grossIncome"] = calculateMonthlyAmount(tmpIncome["frequencey"], tmpIncome["grossIncome"]);
                                    tmpIncome["frequencey"] = "monthly";
                                }
                                return tmpIncome;
                            });
                            tmpValues["income"]["previousIncome"] = tmpPreviousIncome;
                        }
                    }

                    values = tmpValues; //incomeRef.current.values;
                    break;
                case "assets": values = assetsRef.current.values; break;
                case "debts":
                    tmpValues = debtsRef.current.values;
                    if (tmpValues["debts"] !== undefined && Array.isArray(tmpValues["debts"]) && tmpValues["debts"].length > 0) {
                        var tmpDebts = tmpValues["debts"].map((debt) => {
                            var tmpDebt = { ...debt };
                            if (tmpDebt["repaymentAmount"] !== undefined && !isNaN(parseFloat(tmpDebt["repaymentAmount"]))
                                && tmpDebt["repaymentFrequency"] !== undefined && tmpDebt["repaymentFrequency"] !== "monthly") {
                                tmpDebt["repaymentAmount"] = calculateMonthlyAmount(tmpDebt["repaymentFrequency"], tmpDebt["repaymentAmount"]);
                                tmpDebt["repaymentFrequency"] = "monthly";
                            }
                            return tmpDebt;
                        });
                        tmpValues["debts"] = tmpDebts;
                    }
                    values = tmpValues; //debtsRef.current.values;

                    break;
                case "expenses":
                    tmpValues = expensesRef.current.values;
                    //UPDATE THE VALUES AND MAKE SURE THAT ALL VALUES ARE FOR MONTHLY IF NOT THEN CONVERT THEM INTO MONTHLY
                    if (tmpValues["expenses"] !== undefined && Array.isArray(tmpValues["expenses"]) && tmpValues["expenses"].length > 0) {
                        var tmpExpenses = tmpValues["expenses"].map((expense) => {
                            var tmpExpense = { ...expense };
                            if (tmpExpense["frequency"] !== undefined && tmpExpense["frequency"] !== "monthly") {
                                tmpExpense["amount"] = calculateMonthlyAmount(tmpExpense["frequency"], tmpExpense["amount"]);
                                tmpExpense["frequency"] = "monthly";
                            }
                            return tmpExpense;
                        });
                        tmpValues["expenses"] = tmpExpenses;
                    }
                    values = tmpValues;
                    break;
            }

            //IF APPLICANT ID 0 THEN CREATING NEW APPLICANT FOR CURRENT APPLICATION
            if (applicantId == 0) {
                var response = await createApplicant(token, applicationId, values);
                if (response["error"] !== undefined) { fetchApplication(); }
                else { Notifications("error", "Unable to create a applicant."); }
            }
            else if (applicantId !== undefined) {
                //UPDATING THE CURENT APPLICANT
                var response = await updateApplicant(token, applicationId, applicantId, values);
                if (response.httpCode == 200 && response.applicant !== undefined) {
                    setApplicant({ ...applicant, [moduleName]: values[moduleName] })
                    //UPDATE THE APPLICANT DATA TIN APPLICANTS
                    var findApplicant = applicants.find(applicant => applicant.id == applicantId)
                    if (findApplicant != undefined) {
                        setApplicants(applicants.map(singleApplicant => (singleApplicant.id === applicantId ? { ...singleApplicant, [moduleName]: values[moduleName] } : singleApplicant)))
                    }
                    Notifications("success", "Successfully Updated");
                }
                else { Notifications("error", response.message || "Error") }
                //UPDATE DEFAULT OPEN TAB
                setOpenTab(moduleName);
            }
            //SERVER RESPONSES
        }
        setProcessing(false);
    }




    //INSURANCE FORMATING - ADD ALL SIX TYPE OF INSURANCES TO THE PAYLOAD 
    const formatInsurances = async (insurances, currentExpiryDateFormat = 'unix') => {
        var tempInsurances = [
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "comprehensive", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "cci", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "gap", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "warrenty", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "lti", active: false },
            { insuranceCompanyId: "", commission: "", grossPremium: "", netPremium: "", expiryDate: "", extraDetail: {}, type: "rti", active: false }
        ]

        if (insurances !== undefined && Array.isArray(insurances)) {
            insurances.map(async (singleInsurance) => {
                if (singleInsurance.type !== undefined) {
                    var foundIndex = tempInsurances.findIndex(x => x.type == singleInsurance.type);
                    if (foundIndex >= 0 && singleInsurance["insuranceCompanyId"] !== "") {
                        tempInsurances[foundIndex].insuranceCompanyId = singleInsurance["insuranceCompanyId"];
                        tempInsurances[foundIndex].commission = singleInsurance["commission"];
                        tempInsurances[foundIndex].grossPremium = singleInsurance["grossPremium"];
                        tempInsurances[foundIndex].netPremium = singleInsurance["netPremium"];
                        tempInsurances[foundIndex].expiryDate = currentExpiryDateFormat == "unix" ? formatDate(singleInsurance["expiryDate"], 'unix', 'DD/MM/YYYY') : formatDate(singleInsurance["expiryDate"], currentExpiryDateFormat, 'DD/MM/YYYY');
                        tempInsurances[foundIndex].extraDetail = singleInsurance["extraDetail"];
                        tempInsurances[foundIndex].active = true;
                    }
                }
            })
            return tempInsurances;
        }
    }

    //FETCHING APPLICATION FROM SERVER IF APPLICATION ID IS PROVIDED IN THE URL
    const fetchApplication = async () => {
        const response = await getApplication(id, token);
        if (response["httpCode"]) {
            setServerStatusCode(response["httpCode"]);
            if (response["httpCode"] == 200) {
                //CHECK IF IT HAS AN APPLICANTS ARRAY AND IT CONTAINS APPLICANTS THEN ASSIGN FIRST APPLICANT TO APPLIANT STATE
                if (response.data.applicants !== undefined && response.data.applicants[0] !== undefined) {
                    setApplicants(response.data.applicants);

                    //UPDATE APPLICANTS LIST - FOR DROPDOWN PURPOSES
                    if (Array.isArray(response.data.applicants)) {
                        var list = response.data.applicants.map((applicant) => {
                            var title = applicant['title'] != undefined ? capitalizeFirst(applicant['title']) : '';
                            var firstName = applicant['firstName'] != undefined ? applicant['firstName'] : '';
                            var middleName = applicant['middleName'] != undefined ? applicant['middleName'] : '';
                            var surname = applicant['surname'] != undefined ? applicant['surname'] : '';

                            let name = title + " " + firstName + ' ' + middleName + ' ' + surname;
                            let applicantId = applicant['id'];
                            return { value: applicantId, label: name };
                        });
                        setListApplicants(list);
                    }
                }
                var quoteData = response.data;
                delete quoteData.applicants;
                //FORMAT INSURANCES HERE 
                let insurances = quoteData.insurances !== undefined ? quoteData.insurances : [];
                var tmpInsurances = await formatInsurances(insurances);
                quoteData.insurances = tmpInsurances;

                setApplication(response.data);
                setProcessing(false);
            }
            else {
                Notifications("error", response.message || "Error")
                setProcessing(false);
            }
        }
    }

    //HANDLER
    const handler = async (action, payload) => {
        switch (action) {
            //EXECUTE WHEN ANOTHER APPLICANT IS SELECTRED FROM DROPDOWN
            case "updateApplicantSelection":
                var findApplicant = applicants.find(applicant => applicant.id == payload["applicantId"])
                if (findApplicant != undefined) {
                    setApplicant(findApplicant);
                    setApplicantId(findApplicant.id)
                }
                break;
            //THIS WILL CALL THE API ENDPOINT TO CREATE AN ADDITIONAL APPLICANT
            case "executeAddAnotherApplicant":
                setProcessing(true);
                setDrawerSettings({ visible: false });

                var primaryApplicant = {};
                var primaryApplicantResidentialHistory = {}

                if (Array.isArray(applicants)) {
                    for (var i = 0; i < applicants.length; i++) {
                        if (applicants[i].isPrimaryApplicant !== undefined && applicants[i].isPrimaryApplicant == 1) {
                            primaryApplicant = applicants[i];
                            console.log("primaryApplicant::: ", primaryApplicant);
                            primaryApplicantResidentialHistory = primaryApplicant.personalDetail !== undefined && primaryApplicant.personalDetail.residentialHistory !== undefined ? primaryApplicant.personalDetail.residentialHistory : {}
                        }
                    }
                }

                payload["residentialHistory"] = primaryApplicantResidentialHistory;

                var response = await createApplicant(token, applicationId, { personalDetail: payload });
                if (response['error'] == 0 && response['httpCode'] == 200) {
                    //CALL THE FUNCTION TO LOAD DATA AGAIN
                    Notifications("success", response.message || "Success")
                    fetchApplication();
                }
                else {
                    //NOTIFICATION
                    Notifications("error", response.message || "Error")
                }
                break;
            //OPENS THE RIGHT DRAW AND TAKE USER INPUT FOR NEW APPLICANT
            case "addAnotherApplicant":
                setDrawerSettings({ visible: true, heading: "Add another applicant", childName: "addAnotherApplicant", meta: { applicationId: applicationId } });
                break;
            //UPDAETE NEWLY ADDED APPLICAITON NOTES  TO QUOTE  
            case "updateApplicationNotes":
                if (quoteRef !== null && quoteRef.current !== undefined && quoteRef.current !== null && quoteRef.current.values !== undefined) {
                    let values = quoteRef.current.values;
                    values["applicationNotes"] = payload;
                    setApplication(values);
                }
                break;
            //LIST OF ACTIONS COMES UNDER COMPLIANCES
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }


    //
    const fetchApplicationMatchDetails = async (applicationId) => {
        var matchResponse = await getApplicationMatch(applicationId, token);
        if (matchResponse !== undefined && matchResponse !== null
            && matchResponse["error"] !== undefined && matchResponse["error"] == 0 && matchResponse["data"] !== undefined) {
            let tmpData = matchResponse["data"];
            if (tmpData !== null && tmpData !== undefined && tmpData["matchFound"] !== undefined && tmpData["matchFound"] == 1 && tmpData["message"]) {
                setApplicationMatchMessage(tmpData["message"]);
            }
        }
    }
    useEffect(() => {
        //IF ID IS UNDEFINED -> NEW APPLICATION OTHERWISE EDITING EXISTING APPLICATION
        if (id === undefined) {
            setServerStatusCode(200);
            setProcessing(false);
        }
        else {
            setApplicationId(id); fetchApplication();
            //FIND MATCH 
            fetchApplicationMatchDetails(id);
        }
    }, []);

    //TO DEAL WITH IF USER CLICK ON CREATE NEW REFERRAL WHILE ON EDIT REFARRAL SCREEN
    //REINITIALIZE THE STATE VALUES - AFTER MAKING CHANGES IN THE LEFT BAR - UPON CREATING NEW REFERRAL IF WE ARE IN EDIT REFERRAL IT DOES NOT REMOVE THE OLD DATA, SO RE-SET HERE FOR THIS USE CASE
    useEffect(() => {
        if (id === undefined) {
            setApplicationId(0);
            setApplication(ConsumerApplicationSchema);

            setApplicants([]);
            setListApplicants([]);
            setApplicant(ConsumerApplicationSchema["applicants"][0]);
            setApplicantId(0);
        }
    }, [id])

    useEffect(() => {
        //CALL THIS FUNCTION ONLY IF NO APPLICANT IS SELECTED
        if (applicantId == 0) {
            assignPrimaryApplicant();
        }
    }, [applicants])

    useEffect(() => {
    }, [processing])

    return (
        <>
            <div className="container-xxl">
                {processing ? <LoadingScreen /> : (serverStatusCode != 200 ? <DecideErrorPage errorCode={serverStatusCode} /> :
                    <>
                        <Quote initialValues={application} formRef={quoteRef} executeApplicationSubmissionHandler={submitApplicationToServer} applicationId={applicationId} openByDefault={openTab == "quote" ? true : false} />
                        {
                            <div className="row mb-5">
                                <div className="col-md-8 fv-row fv-plugins-icon-container">
                                    <CustomButton
                                        buttonClassName=" btn-secondary"
                                        isActive={true}
                                        buttonStyle={{}}
                                        buttonOnClick={() => { handler("addAnotherApplicant"); }}
                                        buttonText="Create Additional Applicant"
                                    />
                                </div>
                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                    <SelectField
                                        fieldName="currentApplicantId"
                                        defaultSelectedOption={{ value: applicantId, label: "" }}
                                        options={listApplicants}
                                        setFieldValue={(key, value) => {
                                            handler("updateApplicantSelection", { applicantId: value });
                                        }}
                                        callback={() => { }}
                                    />
                                </div>
                                {
                                    applicationMatchMessage.length > 0 ?
                                        <div className=' col-md-12  fv-row mt-2' style={{ color: "white", background: "#1a1a27", padding: "10px" }}> {applicationMatchMessage} </div> : ""
                                }

                            </div>
                        }

                        <PersonalDetail initialValues={applicant} formRef={personalDetailsRef} executeApplicationSubmissionHandler={submitApplicantToServer} applicationId={applicationId} applicantId={applicantId} openByDefault={openTab == "personalDetail" ? true : false} />
                        <Income initialValues={applicant} formRef={incomeRef} executeApplicationSubmissionHandler={submitApplicantToServer} applicationId={applicationId} applicantId={applicantId} openByDefault={openTab == "income" ? true : false} />
                        <Assets initialValues={applicant} formRef={assetsRef} executeApplicationSubmissionHandler={submitApplicantToServer} applicationId={applicationId} applicantId={applicantId} openByDefault={openTab == "assets" ? true : false} />
                        <Debts initialValues={applicant} formRef={debtsRef} executeApplicationSubmissionHandler={submitApplicantToServer} applicationId={applicationId} applicantId={applicantId} openByDefault={openTab == "debts" ? true : false} />
                        <Expenses initialValues={applicant} formRef={expensesRef} executeApplicationSubmissionHandler={submitApplicantToServer} applicationId={applicationId} applicantId={applicantId} openByDefault={openTab == "expenses" ? true : false} />

                        <ConsumerApplicationUtilities application={application} applicationId={applicationId} applicants={applicants} applicant={applicant} applicantId={applicantId} callback={handler} />

                        {drawerSettings["visible"] ?
                            <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                                {drawerSettings["childName"] === "addAnotherApplicant" ? <AddAnotherApplicant applicationId={drawerSettings["meta"]["applicationId"]} callback={handler} /> : ""}
                            </RightDrawer> : ""
                        }

                    </>
                )
                }
            </div>
        </>
    )
}

export default ConsumerApplication;



//BETTER APPROACH TO ADD , REMOVE ITEM FROM ARRAY
//https://codesandbox.io/s/react-formik-async-l2cc5?file=/index.js:2721-2726
//https://stackblitz.com/edit/react-formik-fieldarray-5kx4ob?file=src%2FApp.js    FORMIK ARRAY FIELD APPROACH -
//https://stackoverflow.com/questions/64674065/how-to-use-formik-with-dynamicly-generated-lists-e-g-via-ajax-or-setstate

