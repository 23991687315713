import React from 'react';
import { useParams } from "react-router-dom";

import Files from '../files/Files';

 

function ApplicationFiles(props) {
    let { id } = useParams();
     
    return(
        <>
        <Files entityType = "application"  entityId= {id} enableUpload = {true} />
        </>
    )
}

export default ApplicationFiles;