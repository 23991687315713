import * as Yup from 'yup';


const QuickReferralValidationSchema = Yup.object().shape({
    applicants: Yup.array().of(
        Yup.object().shape({
            personalDetail: Yup.object().shape({
                title: Yup.string().required('Required'),
                firstName: Yup.string().required('Required'),
                middleName: Yup.string(),
                surname: Yup.string().required('Required'),
                email: Yup.string().email("Must be valid email").required('Required'),
                mobileNumber: Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
                employerNumber: Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
                homeNumber: Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
            })
        }),
    ),
    loan: Yup.object().shape({
        purpose: Yup.string().required('Required'),
    }),
});

export default QuickReferralValidationSchema;