
import { useEffect, useRef, useState } from 'react';
import { useRole, useToken } from '../../../hooks/useAuth';
import { getApplication, getApplicationReferrerDetails, getUser, getLender } from "../../../api";
import Notification from '../../global/Notifications';
import LoadingScreen from '../../global/LoadingScreen';
import { capitalizeFirstLowercaseRest, calculateMonthlyAmount, getCurrentDate } from '../../../helpers/';
import ReactToPrint from 'react-to-print';
import { Printer } from '../../HtmlComponents/Icons';
import Constants from '../../global/Constants';
import { formatDate } from '../../../helpers';


const PrintApplication = ({ applicationId, applicantId }) => {
    const [application, setApplication] = useState({});
    const [applicants, setApplicants] = useState([]);       //list of all applicants against this application   
    const [applicant, setApplicant] = useState({});
    const [referrerGroup, setReferrerGroup] = useState({});
    const [referrer, setReferrer] = useState({});
    const [referrerUser, setReferrerUser] = useState({});
    const [lender, setLender] = useState({});
    const [processing, setProcessing] = useState(false);
    var token = useToken();
    const componentRef = useRef();

    const userRole = useRole();


    //ASSIGNED REFERRER DETAILS
    const fetchAssignedReferrerDetails = async () => {
        console.log("CALLING REFERRER DETAILS::::::::::::::::::::")
        var response = await getApplicationReferrerDetails(token, applicationId);
        console.log("fetchAssignedReferrerDetails ::::::::::: ", response);
        if (response["error"] !== undefined && response["error"] == 0) {
            console.log("UPDATING REFERRER AND REFERRER GRUP STATE");
            setReferrer(response["data"]["referrer"] || {});
            setReferrerGroup(response["data"]["referrerGroup"] || {});
        }
    }

    //ASSIGNED REFERRER EMPLOYEE DETAILS
    const fetchAssignedReferrerUserDetails = async (referrerUserId) => {
        var tmpUser = await getUser(token, referrerUserId);

        console.log("referrerUserId", referrerUserId)
        console.log("user:::::::", tmpUser);
        if (tmpUser !== undefined) {
            setReferrerUser(tmpUser);
        }
    }

    //LENDER DETAILS
    const fetchLenderDetails = async (lenderId) => {
        let response = await getLender(lenderId, token);
        console.log("response", response);
        if (response !== null && response["id"] !== undefined) {
            setLender(response);
        }

    }


    //FETCHING APPLICATION FROM SERVER IF APPLICATION ID IS PROVIDED IN THE URL
    const fetchApplication = async () => {
        setProcessing(true);
        const response = await getApplication(applicationId, token);
        console.log("RESPONSE FROM SERVER :::::::::::::::::::::::::::::   ", response);

        if (response["httpCode"]) {
            if (response["httpCode"] == 200) {
                //CHECK IF IT HAS AN APPLICANTS ARRAY AND IT CONTAINS APPLICANTS THEN ASSIGN FIRST APPLICANT TO APPLIANT STATE
                if (response.data.applicants !== undefined && response.data.applicants[0] !== undefined) {
                    setApplicants(response.data.applicants);
                    var tmpApplicants = response.data !== undefined && response.data.applicants !== undefined && Array.isArray(response.data.applicants) ? response.data.applicants : [];

                    //FIND APPLICANT
                    if (applicantId == undefined) {
                        //FIND THE PRIMARY APPLICANT AND UPDATE THE APPLICANT STATE                        
                        var tmpApplicant = tmpApplicants.find(singleApplicant => singleApplicant.isPrimaryApplicant == 1);
                        if (tmpApplicant !== undefined) { setApplicant(tmpApplicant); }
                    }
                    else {
                        //FIND THE APPLICANT FROM THE ARRAY - APPLICANT ID IS PROVIDEDs
                        var tmpApplicant = tmpApplicants.find(singleApplicant => singleApplicant.id == applicantId);
                        console.log("tmpApplicant:::::::::::::::::::::::: ", tmpApplicant)
                        if (tmpApplicant !== undefined) { setApplicant(tmpApplicant); }
                    }
                }
                var quoteData = response.data;
                //FETCH REFERRER STAFF ID
                //referrer user id
                var referrerUserId = quoteData["referrerStaffId"] !== undefined ? quoteData["referrerStaffId"] : 0;
                var lenderId = quoteData["lenderId"] !== undefined ? quoteData["lenderId"] : 0;
                if (referrerUserId !== 0) { fetchAssignedReferrerUserDetails(referrerUserId); }
                if (lenderId !== 0) { fetchLenderDetails(lenderId); }

                delete quoteData.applicants;
                setApplication(response.data);
                setProcessing(false);
            }
            else {
                Notification("error", response.message || "Error")
                setProcessing(false);
            }
        }
    }

    useEffect(() => {
        //IF ID IS UNDEFINED -> NEW APPLICATION OTHERWISE EDITING EXISTING APPLICATION
        if (applicationId === undefined) {
            setProcessing(false);
        }
        else {
            fetchApplication();
            fetchAssignedReferrerDetails();
        }
    }, []);


    const GetRefererDetails = () => {
        //Referer Details

        let referrerGroupName = referrerGroup["tradingName"] !== undefined && referrerGroup["tradingName"] !== null ? referrerGroup["tradingName"] : "";
        let referrerName = referrer["tradingName"] !== undefined && referrer["tradingName"] !== null ? referrer["tradingName"] : "";
        let referrerAbn = referrer["abn"] !== undefined && referrer["abn"] !== null ? referrer["abn"] : "";
        let referrerContact = referrer["contactNumber"] !== undefined && referrer["contactNumber"] !== null ? referrer["contactNumber"] : "";
        let referrerEmail = referrer["email"] !== undefined && referrer["email"] !== null ? referrer["email"] : "";
        let referrerAddress = referrer["cashDeposit"] !== undefined && referrer["cashDeposit"] !== null ? referrer["cashDeposit"] : "";
        let referrerAgentName = referrerUser["firstName"] !== undefined && referrerUser["firstName"] !== null ? referrerUser["firstName"] : "";
        let referrerAgentContact = referrerUser["contactNumber"] !== undefined && referrerUser["contactNumber"] !== null ? referrerUser["contactNumber"] : "";
        let referrerAgentEmail = referrerUser["email"] !== undefined && referrerUser["email"] !== null ? referrerUser["email"] : "";
        let currentDate = getCurrentDate();

        return (
            <>

                <div className='printMainHeading'>
                    <div className='row'>
                        <div className='col-print-9'> <span><h3> REFERRER DETAILS  {"(REF # " + applicationId + ")"} </h3></span></div>
                        <div className='col-print-3' style={{ textAlign: 'right' }}> {currentDate} </div>
                    </div>

                </div>
                <div className='custom-row nfal-printing-application-section' style={{ border: "2px #dddddd solid" }}>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Referrer Group : </span> {referrerGroupName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Referrer : </span> {referrerName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>  ABN : </span> {referrerAbn}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Contact # : </span> {referrerContact}</p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'> Email : </span> {referrerEmail}</p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'> Address : </span> {referrerAddress}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Name : </span> {referrerAgentName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Contact # : </span> {referrerAgentContact}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'> Email : </span> {referrerAgentEmail}</p></div>
                </div>
            </>
        )
    }
    const GetQuote = () => {
        //Quote Details

        //--Loan Details
        let loan = application["loan"] !== undefined ? application["loan"] : {};
        let purpose = application["purpose"] !== undefined ? Constants.LOAN_PURPOSES[application["purpose"]] : "";
        let type = application["type"] !== undefined ? capitalizeFirstLowercaseRest(application["type"]) : "";
        let financial = application["financial"] !== undefined && application["financial"] !== null ? application["financial"] : {};
        let assetPrice = financial["assetPrice"] !== undefined && financial["assetPrice"] !== null ? financial["assetPrice"] : "";
        let cashDeposit = financial["cashDeposit"] !== undefined && financial["cashDeposit"] !== null ? financial["cashDeposit"] : "";
        let netLoanAmount = loan["totalFinancedAmountExcludingInsurances"] !== undefined && loan["totalFinancedAmountExcludingInsurances"] !== null ? loan["totalFinancedAmountExcludingInsurances"] : "";
        let totalLoanAmount = loan["totalFinancedAmountIncludingInsurances"] !== undefined && loan["totalFinancedAmountIncludingInsurances"] !== null ? loan["totalFinancedAmountIncludingInsurances"] : "";

        //---loan amount - weekly, monthly,yearly
        let weeklyIncInsurance = application["repayments"] != undefined && application["repayments"]["includingInsurance"] !== undefined && application["repayments"]["includingInsurance"]["weekly"] !== undefined ? application["repayments"]["includingInsurance"]["weekly"] : "";
        let MonthlyIncInsurance = application["repayments"] != undefined && application["repayments"]["includingInsurance"] !== undefined && application["repayments"]["includingInsurance"]["fortnightly"] !== undefined ? application["repayments"]["includingInsurance"]["fortnightly"] : "";
        let yearlyIncInsurance = application["repayments"] != undefined && application["repayments"]["includingInsurance"] !== undefined && application["repayments"]["includingInsurance"]["monthly"] !== undefined ? application["repayments"]["includingInsurance"]["monthly"] : "";
        let weeklyExcInsurance = application["repayments"] != undefined && application["repayments"]["excludingInsurance"] !== undefined && application["repayments"]["excludingInsurance"]["weekly"] !== undefined ? application["repayments"]["excludingInsurance"]["weekly"] : "";
        let MonthlyExcInsurance = application["repayments"] != undefined && application["repayments"]["excludingInsurance"] !== undefined && application["repayments"]["excludingInsurance"]["fortnightly"] !== undefined ? application["repayments"]["excludingInsurance"]["fortnightly"] : "";
        let yearlyExcInsurance = application["repayments"] != undefined && application["repayments"]["excludingInsurance"] !== undefined && application["repayments"]["excludingInsurance"]["monthly"] !== undefined ? application["repayments"]["excludingInsurance"]["monthly"] : "";

        let rate = loan["interestRate"] !== undefined && loan["interestRate"] !== null ? loan["interestRate"] : "";
        let term = loan["loanTerm"] !== undefined && loan["loanTerm"] !== null ? loan["loanTerm"] : "";
        let balloon = loan["balloon"] !== undefined && loan["balloon"] !== null ? loan["balloon"] : {};
        let balloonAmount = balloon["balloonAmount"] !== undefined && balloon["balloonAmount"] !== null ? balloon["balloonAmount"] : "";
        let balloonPercentage = balloon["balloonPercentage"] !== undefined && balloon["balloonPercentage"] !== null ? balloon["balloonPercentage"] : "";
        let customerWeeklyBudget = loan["customerWeeklyBudget"] !== undefined && loan["customerWeeklyBudget"] !== null ? loan["customerWeeklyBudget"] : "0.00";
        let feesCharges = financial["lenderFee"] !== undefined && financial["lenderFee"] !== null ? financial["lenderFee"] : "";
        let originationFee = financial["originationFee"] !== undefined && financial["originationFee"] !== null ? financial["originationFee"] : "";
        let lenderName = lender["name"] !== undefined ? lender["name"] : "";
        //--TradeIn Details
        let tradeIn = financial["tradeIn"] !== undefined && financial["tradeIn"] !== null ? financial["tradeIn"] : {};
        let isTradingIn = tradeIn["isTradingIn"] !== undefined && tradeIn["isTradingIn"] !== null ? tradeIn["isTradingIn"] : false;
        let tradeInValue = tradeIn["amount"] !== undefined && tradeIn["amount"] !== null ? tradeIn["amount"] : "";
        let finance = tradeIn["finance"] !== undefined ? tradeIn["finance"] : {};
        let financed = finance["financed"] !== undefined ? finance["financed"] : false;
        let financier = finance["financier"] !== undefined ? finance["financier"] : {};
        let financierName = financier["name"] !== undefined ? financier["name"] : "";
        let financierAmOutstand = financier["amountOutstanding"] !== undefined ? financier["amountOutstanding"] : "";
        //--Insurances
        let insurances = application !== undefined && application["insurances"] !== undefined ? application["insurances"] : [];


        //Asset Details
        let buyingItem = application["buyingItem"] !== undefined ? application["buyingItem"] : {};
        let motorVehicles = buyingItem["motorVehicles"] !== undefined && buyingItem["motorVehicles"] !== null ? buyingItem["motorVehicles"] : [];
        let motorVehicle1 = motorVehicles[0] !== undefined && motorVehicles[0] !== null ? motorVehicles[0] : {};
        let condition = motorVehicle1["condition"] !== undefined && motorVehicle1["condition"] !== null ? motorVehicle1["condition"] : "";
        let make = motorVehicle1["make"] !== undefined && motorVehicle1["make"] !== null ? motorVehicle1["make"] : "";
        let year = motorVehicle1["year"] !== undefined && motorVehicle1["year"] !== null ? motorVehicle1["year"] : "";
        let model = motorVehicle1["model"] !== undefined && motorVehicle1["model"] !== null ? motorVehicle1["model"] : "";
        let bodyType = motorVehicle1["bodyType"] !== undefined && motorVehicle1["bodyType"] !== null ? motorVehicle1["bodyType"] : "";
        let transmission = motorVehicle1["transmission"] !== undefined && motorVehicle1["transmission"] !== null ? motorVehicle1["transmission"] : "";
        let buildDate = motorVehicle1["buildDate"] !== undefined && motorVehicle1["buildDate"] !== null ? motorVehicle1["buildDate"] : "";
        let complianceDate = motorVehicle1["complianceDate"] !== undefined && motorVehicle1["complianceDate"] !== null ? motorVehicle1["complianceDate"] : "";
        let totalKilometer = motorVehicle1["totalKilometer"] !== undefined && motorVehicle1["totalKilometer"] !== null ? motorVehicle1["totalKilometer"] : "";
        let registrationNumber = motorVehicle1["registrationNumber"] !== undefined && motorVehicle1["registrationNumber"] !== null ? motorVehicle1["registrationNumber"] : "";
        let vin = motorVehicle1["vin"] !== undefined && motorVehicle1["vin"] !== null ? motorVehicle1["vin"] : "";
        let nvic = motorVehicle1["nvic"] !== undefined && motorVehicle1["nvic"] !== null ? motorVehicle1["nvic"] : "";
        let engineNumber = motorVehicle1["engineNumber"] !== undefined && motorVehicle1["engineNumber"] !== null ? motorVehicle1["engineNumber"] : "";
        let color = motorVehicle1["color"] !== undefined && motorVehicle1["color"] !== null ? motorVehicle1["color"] : "";
        let estimatedDeliveryDate = motorVehicle1["estimatedDeliveryDate"] !== undefined && motorVehicle1["estimatedDeliveryDate"] !== null ? motorVehicle1["estimatedDeliveryDate"] : "";
        let description = motorVehicle1["description"] !== undefined && motorVehicle1["description"] !== null ? motorVehicle1["description"] : "";




        //var type = application["type"] !== undefined ? application["type"] : {};  

        // style={{ backgroundColor: '#F0F5F9' }}
        return (
            <>
                <div className='printMainHeading'>
                    <h3> QUOTE DETAILS </h3>
                </div>
                <div className='' style={{ border: "2px #dddddd solid" }}>
                    <div className='custom-row'>
                        <div className='col-print-6'>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Purpose : </span> {purpose}</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Type : </span> {type}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Asset Price : </span>$ {assetPrice}</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Cash Deposit : </span>$ {cashDeposit}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Trade In : </span> {isTradingIn == true ? "Yes" : "No"}</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Value : </span> {tradeInValue}</p></div>
                            </div>
                            <div className='custom-row'>
                                <TradeIn tradeIn={tradeIn} isTradingIn={isTradingIn} financed={financed} financierAmOutstand={financierAmOutstand} financierName={financierName} />
                                <p className='printValue'><span className='printLabel'> Fees + Charges : </span> {feesCharges}</p>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Lender : </span> {lenderName}</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Origination Fee : </span> $ {originationFee}</p></div>
                            </div>

                        </div>
                        <div className='col-print-6'>
                            <div className='custom-row'>
                                <InsuranceCheck insurances={insurances} />

                            </div>
                            <div className='custom-row'>
                                <div className='col-print-12'><p className='printValue'><span className='printLabel'> Net Loan Amount : </span>$  {netLoanAmount}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-12'><p className='printValue'><span className='printLabel'> Total Loan Amount : </span>$  {totalLoanAmount}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Rate : </span> {rate} %</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Term : </span> {term} Months</p></div>
                                <div className='col-print-12'><p className='printValue'><span className='printLabel'> Customer Weekly Budget : </span> ${customerWeeklyBudget}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Balloon %: </span> {balloonPercentage}</p></div>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'> Balloon Amount : </span>$ {balloonAmount}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Weekly (inInsurance) : </span>{weeklyIncInsurance}</p></div>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Fortnightly (inInsurance) : </span>{MonthlyIncInsurance}</p></div>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Monthly (inInsurance) : </span>{yearlyIncInsurance}</p></div>
                            </div>
                            <div className='custom-row'>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Weekly (exInsurance) : </span>{weeklyExcInsurance}</p></div>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Fortnightly (exInsurance) : </span>{MonthlyExcInsurance}</p></div>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'> Monthly (exInsurance) : </span>{yearlyExcInsurance}</p></div>
                            </div>
                        </div>
                    </div>

                    <div className='printMainHeading'>
                        <h3 style={{ textAlign: 'center' }}> ASSET DETAILS </h3>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Condition : </span> {condition} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Year : </span> {year} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Make : </span> {make} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Model : </span>  {model}</p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Body Type : </span> {bodyType}</p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Transmission : </span>   {transmission} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Build Date : </span> {buildDate} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Comp Date : </span>  {complianceDate} </p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>KM's/Hours : </span> {totalKilometer}</p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Registration # : </span> {registrationNumber} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>VIN / HIN # : </span> {vin} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Engine # : </span>  {engineNumber} </p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>NVIC # : </span> {nvic} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Color  : </span> {color}</p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Est. Delivery Date : </span>   {estimatedDeliveryDate} </p></div>
                        <div className='col-print-3'><p className='printValue'><span className='printLabel'>Description : </span> {description} </p></div>
                    </div>
                </div>

            </>
        )

    }
    const GetPersonalDetail = () => {
        //Personal Details
        var personalDetails = applicant["personalDetail"] !== undefined && applicant["personalDetail"] !== null ? applicant["personalDetail"] : {};
        var firstName = personalDetails["firstName"] !== undefined ? personalDetails["firstName"] : "";
        var middleName = personalDetails["middleName"] !== undefined ? personalDetails["middleName"] : "";
        var surname = personalDetails["surname"] !== undefined ? personalDetails["surname"] : "";
        var email = personalDetails["email"] !== undefined ? personalDetails["email"] : "";
        var mobile = personalDetails["mobileNumber"] !== undefined ? personalDetails["mobileNumber"] : "";
        var home = personalDetails["homeNumber"] !== undefined ? personalDetails["homeNumber"] : "";
        var employer = personalDetails["employerNumber"] !== undefined ? personalDetails["employerNumber"] : "";
        var gender = personalDetails["gender"] !== undefined ? personalDetails["gender"] : "";
        var dob = personalDetails["dob"] !== undefined && Number.isInteger(personalDetails["dob"]) ? formatDate(personalDetails["dob"], "unix", "DD/MM/yyyy") : "";
        var relationshipStatus = personalDetails["relationshipStatus"] !== undefined ? Constants.RELATIONSHIP_STATUS[personalDetails["relationshipStatus"]] : "";
        var residencyStatus = personalDetails["residencyStatus"] !== undefined ? Constants.RESIDENCY_STATUS[personalDetails["residencyStatus"]] : "";
        var totalDependants = personalDetails["totalDependants"] !== undefined ? personalDetails["totalDependants"] : 0;
        var dependantsAge = personalDetails["dependantsAge"] !== undefined ? personalDetails["dependantsAge"] : "";



        //License
        var license = personalDetails["license"] !== undefined && personalDetails["license"] !== null ? personalDetails["license"] : {};
        var unlicensed = false; //license["unlicensed"] !== undefined && license["unlicensed"] !== null ? license["unlicensed"] : false;
        let number = license["number"] !== undefined ? license["number"] : "";
        let type = license["type"] !== undefined ? license["type"] : "";
        let state = license["state"] !== undefined ? license["state"] : "";
        var cardNumber = license["cardNumber"] !== undefined ? license["cardNumber"] : "";
        var licenseExpiryDate = license["expiryDate"] !== undefined && Number.isInteger(license["expiryDate"]) && license["expiryDate"] > 0 ? formatDate(license["expiryDate"], "unix", "DD/MM/yyyy") : "";

        //medicare
        var medicare = personalDetails["medicare"] !== undefined && personalDetails["medicare"] !== null ? personalDetails["medicare"] : {};
        var medicareNumber = medicare["number"] !== undefined && medicare["number"] !== null ? medicare["number"] : "";
        var expiryDate = medicare["expiryDate"] !== undefined && medicare["expiryDate"] !== null && Number.isInteger(medicare["expiryDate"]) && medicare["expiryDate"] > 0 ? formatDate(medicare["expiryDate"], "unix", "DD/MM/yyyy") : "";
        var ref = medicare["ref"] !== undefined && medicare["ref"] !== null ? medicare["ref"] : "";


        //address
        var residentialHistory = personalDetails["residentialHistory"] !== undefined && personalDetails["residentialHistory"] !== null ? personalDetails["residentialHistory"] : {};
        var currentResidentialHistory = residentialHistory["current"] !== undefined && residentialHistory["current"] !== null ? residentialHistory["current"] : {};
        var currentResidentialAddress = currentResidentialHistory["address"] !== undefined && currentResidentialHistory["address"] !== null ? currentResidentialHistory["address"] : {};
        var currentFullAddress = currentResidentialAddress["fullAddress"] !== undefined && currentResidentialAddress["fullAddress"] !== null ? currentResidentialAddress["fullAddress"] : "";
        var currentAddressDuration = currentResidentialHistory["duration"] !== undefined && currentResidentialHistory["duration"] !== null ? currentResidentialHistory["duration"] : "";
        var currentAddressStatus = currentResidentialHistory["status"] !== undefined && currentResidentialHistory["status"] !== null ? currentResidentialHistory["status"] : "";
        var previousResidentialHistory = residentialHistory["previous"] !== undefined && residentialHistory["previous"] !== null ? residentialHistory["previous"] : [];


        return (
            <>

                <div className='printMainHeading'>
                    <h3>PERSONAL DETAILS </h3>
                </div>
                <div className='' style={{ border: "2px #dddddd solid" }}>
                    <div className="custom-row">
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>First Name : </span> {firstName} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Middle Name  : </span> {middleName} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Last Name : </span> {surname} </p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Mobile # : </span> {mobile} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Home # : </span> {home} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Employer # : </span> {employer} </p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Email Address : </span> {email} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>DOB : </span> {dob} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Marital Status : </span> {relationshipStatus} </p></div>
                    </div>
                    <div className="custom-row">
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gender : </span> {gender} </p></div>
                        <LicenseDetails unlicensed={unlicensed} number={number} cardNumber={cardNumber} type={type} state={state} licenseExpiryDate={licenseExpiryDate} />
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Medicare # : </span> {medicareNumber} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Expiry Date : </span> {expiryDate} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Ref # : </span> {ref} </p></div>
                        <div className='col-print-4'><p className='printValue'><span className='printLabel'>Dependant : </span> {totalDependants} </p></div>
                        {Array.isArray(dependantsAge) ? dependantsAge.map((dependantAge, index) => (
                            <div className='col-print-4'><p className='printValue'><span className='printLabel'>Dependant {index + 1}  (age): </span> {dependantAge} years </p></div>
                        )) : ""}
                    </div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Residency Status: </span> {residencyStatus} </p></div>
                    <div className="custom-row">
                        <div className='col-print-12'><p className='printValue'><span className='printLabel'>Current Residential Address : </span> {currentFullAddress} </p></div>
                        <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration Living : </span> {currentAddressDuration} Months</p></div>
                        <div className='col-print-6'><p className='printValue'><span className='printLabel'>Residential Status : </span> {Constants.RESIDENTIAL_STATUS[currentAddressStatus]}</p></div>
                        <AddressDetails currentResidentialHistory={currentResidentialHistory} />

                    </div>
                    {previousResidentialHistory.map((previousAddress, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className='col-print-12'><p className='printValue'><span className='printLabel'>Previous Residential Address : </span> {previousAddress.address !== undefined && previousAddress.address.fullAddress !== undefined ? previousAddress.address.fullAddress : ""} </p></div>
                            <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration Living : </span> {previousAddress.duration !== undefined ? previousAddress.duration : ""} Months</p></div>
                            <div className='col-print-6'><p className='printValue'><span className='printLabel'>Residential Status : </span> {previousAddress.status !== undefined ? Constants.RESIDENTIAL_STATUS[previousAddress.status] : ""}</p></div>
                        </div>
                    ))}
                </div>
            </>
        )
    }
    const GetIncomeDetails = () => {

        //Income Details
        var income = applicant["income"] !== undefined && applicant["income"] !== null ? applicant["income"] : {};
        var primaryIncome = income["primaryIncome"] !== undefined && income["primaryIncome"] !== null ? income["primaryIncome"] : [];
        var SecondaryIncome = income["secondaryIncome"] !== undefined && income["secondaryIncome"] !== null ? income["secondaryIncome"] : [];
        var previousIncome = income["previousIncome"] !== undefined && income["previousIncome"] !== null ? income["previousIncome"] : [];

        return (
            <>
                <div className='printMainHeading'>
                    <h3>INCOME DETAILS</h3>
                </div>
                <div className='' style={{ border: "2px #dddddd solid" }}>
                    {primaryIncome.map((income, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className="custom-row" style={{ paddingTop: "8px" }}>
                                <div className='col-print-6' ><p className='printValue'><span className='printLabel'>Primary Income Type : </span> {income.incomeType !== undefined ? Constants.INCOME_TYPES[income.incomeType] : ""} </p></div>
                                <IncomeTypeCheck income={income} incomeType={income.incomeType} />
                            </div>
                        </div>
                    ))}

                    {SecondaryIncome.map((income, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className="custom-row" style={{ paddingTop: "8px" }}>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'>Secondary Income Type : </span> {income.incomeType !== undefined ? Constants.INCOME_TYPES[income.incomeType] : ""} </p></div>
                                <IncomeTypeCheck income={income} incomeType={income.incomeType} />
                            </div>
                        </div>
                    ))}

                    {previousIncome.map((income, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className="custom-row" style={{ paddingTop: "8px" }}>
                                <div className='col-print-6'><p className='printValue'><span className='printLabel'>Previous Income Type : </span> {income.incomeType !== undefined ? Constants.INCOME_TYPES[income.incomeType] : ""} </p></div>
                                <IncomeTypeCheck income={income} incomeType={income.incomeType} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )

    }
    const GetAssetDetails = () => {

        //Asset Details
        var assets = applicant["assets"] !== undefined && applicant["assets"] !== null ? applicant["assets"] : [];


        return (
            <>
                <div className='printMainHeading'>
                    <h3>ASSET DETAILS </h3>
                </div>
                <div className='' style={{ border: "2px #dddddd solid" }}>
                    {assets.map((asset, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset Type : </span> {asset.assetType !== undefined ? Constants.ASSETS_TYPES[asset.assetType] : ""} </p></div>
                            <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset Ownership : </span> {asset.assetOwnership !== undefined ? Constants.ASSET_OWNERSHIP_TYPES[asset.assetOwnership] : ""}</p></div>
                            <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset Worth : </span>$  {asset.assetValue !== undefined ? asset.assetValue : ""}</p></div>
                            <AssetTypeCheck asset={asset} assetType={asset.assetType} />
                        </div>
                    ))}
                </div>

            </>
        )

    }
    const GetDebtDetails = () => {
        //Debt Details
        var debts = applicant["debts"] !== undefined && applicant["debts"] !== null ? applicant["debts"] : [];

        return (
            <>
                <div className='printMainHeading'>
                    <h3>DEBT DETAILS </h3>
                </div>

                <div className='' style={{ border: "2px #dddddd solid" }}>
                    {debts.map((debt, index) => (
                        <div className="custom-row" data-index={index}>
                            <div className="custom-row" style={{ paddingTop: "8px" }}>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'>Debt Type : </span> {debt.debtType !== undefined ? Constants.DEBT_TYPES[debt.debtType] : ""} </p></div>
                                <div className='col-print-4'><p className='printValue'><span className='printLabel'>Financial Institution : </span> {debt.financialInstitution !== undefined ? debt.financialInstitution : ""}</p></div>
                                <DebtTypeCheck debt={debt} debtType={debt.debtType} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )

    }

    const GetExpensesDetails = () => {
        var expenses = applicant["expenses"] !== undefined && applicant["expenses"] !== null ? applicant["expenses"] : [];
        var totalExpenses = 0;

        expenses.map(async (singleExpense) => {
            let monthlyExpense = calculateMonthlyAmount(singleExpense.frequency, singleExpense.amount);
            totalExpenses += parseFloat(monthlyExpense);
        })

        return (
            <>
                <div className='printMainHeading'>
                    <h3>EXPENSES DETAILS</h3>
                </div>
                <div className='' style={{ border: "2px #dddddd solid" }}>
                    <div className='custom-row'>
                        {
                            expenses.map(singleExpense => {
                                return (
                                    <>
                                        <div className='col-print-4'><p className='printValue' key={singleExpense.type + "Label"} > <span className='printLabel'> {singleExpense.type !== undefined ? Constants.EXPENSES_TYPES[singleExpense.type] : ""}</span></p></div>
                                        <div className='col-print-2' style={{ paddingLeft: "15px" }}><p className='printValue' key={singleExpense.type} > {singleExpense.amount !== undefined ? singleExpense.amount : ""} /   {singleExpense.frequency !== undefined ? singleExpense.frequency : ""}</p></div>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className='custom-row'>
                        <div className='col-print-4' style={{ textDecoration: "underline" }}><p className='printValue' >Total</p></div>
                        <div className='col-print-2' style={{ paddingLeft: "15px", textDecoration: "underline" }}><p className='printValue' >$ {totalExpenses} monthly</p></div>
                    </div>
                </div>
            </>
        )
    }

    const GetNotes = () => {        
        let notes = application["applicationNotes"] || { "referrerNotes": "", "quoteNotes": "", "personalDetailNotes": "", "incomeNotes": "", "assetsNotes": "", "debtsNotes": "", "expensesNotes": "" };

        if(["auditor"].includes(userRole)){
            return (
                <>
                    <div className='printMainHeading'>
                        <h3> Notes </h3>
                    </div>
                    <div className='' style={{ border: "2px #dddddd solid" }}>
                        <div className='custom-row'>
                            <div className='col-print-12'>
                                <h5>Referrer Notes</h5>
                                <p>{notes["referrerNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Quote Notes</h5>
                                <p> {notes["quoteNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Personal Detail Notes</h5>
                                <p> {notes["personalDetailNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Income Notes</h5>
                                <p> {notes["incomeNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Assets Notes</h5>
                                <p> {notes["assetsNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Debts Notes</h5>
                                <p> {notes["debtsNotes"] || ""}</p>
                            </div>
                            <div className='col-print-12'>
                                <h5>Expenses Notes</h5>
                                <p> {notes["expensesNotes"] || ""}</p>
                            </div>
                        </div>
                    </div>
    
                </>
            )
        }
        else{
            return "";
        }        
    }


    // -----------------------------------------------       Super Child Functions --------------------------------------------


    const DebtTypeCheck = ({ debt, debtType }) => {

        if (debtType == "house" || debtType == "land" || debtType == "mortgage") {
            let repaymentFrequency = debt["repaymentFrequency"] !== undefined ? debt["repaymentFrequency"] : "";
            let assetValue = debt["assetValue"] !== undefined ? debt["assetValue"] : "";
            let amountBorrowed = debt["amountBorrowed"] !== undefined ? debt["amountBorrowed"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let repaymentAmount = debt["repaymentAmount"] !== undefined ? debt["repaymentAmount"] : "";
            let shareRepaymentAmount = debt["applicantShareOfTotalMonthlyRepayment"] !== undefined ? debt["applicantShareOfTotalMonthlyRepayment"] : "";
            let startDate = debt["startDate"] !== undefined ? debt["startDate"] : "";
            let term = debt["term"] !== undefined ? debt["term"] : "";
            let rate = debt["rate"] !== undefined ? debt["rate"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Frequency : </span> {repaymentFrequency}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset Value : </span> {assetValue} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Amount Borrowed : </span>$ {amountBorrowed}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Amount : </span>$ {repaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Applicant Repayment Amount : </span>$ {shareRepaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Start Date : </span> {startDate}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Loan Term : </span> {term}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Rate : </span> {rate}</p></div>
                </>
            )
        }
        else if (debtType == "personal" || debtType == "car" || debtType == "motorBike" || debtType == "boat" || debtType == "caravan" || debtType == "jetSki" || debtType == "truck" || debtType == "investmentProperties" || debtType == "hirePurchase") {
            let repaymentFrequency = debt["repaymentFrequency"] !== undefined ? debt["repaymentFrequency"] : "";
            let assetValue = debt["assetValue"] !== undefined ? debt["assetValue"] : "";
            let amountBorrowed = debt["amountBorrowed"] !== undefined ? debt["amountBorrowed"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let repaymentAmount = debt["repaymentAmount"] !== undefined ? debt["repaymentAmount"] : "";
            let startDate = debt["startDate"] !== undefined ? debt["startDate"] : "";
            let term = debt["term"] !== undefined ? debt["term"] : "";
            let rate = debt["rate"] !== undefined ? debt["rate"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Frequency : </span> {repaymentFrequency}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset Value : </span> {assetValue} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Amount Borrowed : </span>$ {amountBorrowed}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Amount : </span>$ {repaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Start Date : </span> {startDate}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Loan Term : </span> {term}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Rate : </span> {rate}</p></div>
                </>
            )
        }
        else if (debtType == "creditCard" || debtType == "storeCard" || debtType == "chargeCard") {
            let creditLimit = debt["creditLimit"] !== undefined ? debt["creditLimit"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let repaymentAmount = debt["repaymentAmount"] !== undefined ? debt["repaymentAmount"] : "";
            let repaymentFrequency = debt["repaymentFrequency"] !== undefined ? debt["repaymentFrequency"] : "";
            let startDate = debt["startDate"] !== undefined ? debt["startDate"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Credit Limit : </span> {creditLimit}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Amount : </span>$ {repaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Frequency : </span> {repaymentFrequency}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Start Date : </span> {startDate}</p></div>
                </>
            )
        }
        else if (debtType == "overdraft") {
            let creditLimit = debt["creditLimit"] !== undefined ? debt["creditLimit"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let rate = debt["rate"] !== undefined ? debt["rate"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Credit Limit : </span> {creditLimit}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Rate : </span> {rate}</p></div>
                </>
            )
        }
        else if (debtType == "buyNowPayLater") {
            let creditLimit = debt["creditLimit"] !== undefined ? debt["creditLimit"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let amountBorrowed = debt["amountBorrowed"] !== undefined ? debt["amountBorrowed"] : "";
            let repaymentAmount = debt["repaymentAmount"] !== undefined ? debt["repaymentAmount"] : "";
            let repaymentFrequency = debt["repaymentFrequency"] !== undefined ? debt["repaymentFrequency"] : "";
            let startDate = debt["startDate"] !== undefined ? debt["startDate"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Credit Limit : </span> {creditLimit}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Amount Borrowed : </span>$ {amountBorrowed}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Amount : </span>$ {repaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Frequency : </span> {repaymentFrequency}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Start Date : </span> {startDate}</p></div>
                </>
            )
        }
        else if (debtType == "other") {
            let creditLimit = debt["creditLimit"] !== undefined ? debt["creditLimit"] : "";
            let amountBorrowed = debt["amountBorrowed"] !== undefined ? debt["amountBorrowed"] : "";
            let balanceRemaining = debt["balanceRemaining"] !== undefined ? debt["balanceRemaining"] : "";
            let repaymentAmount = debt["repaymentAmount"] !== undefined ? debt["repaymentAmount"] : "";
            let repaymentFrequency = debt["repaymentFrequency"] !== undefined ? debt["repaymentFrequency"] : "";
            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Credit Limit : </span> {creditLimit}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Amount Borrowed : </span>$ {amountBorrowed}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Remaining Balance : </span>$ {balanceRemaining}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Amount : </span>$ {repaymentAmount} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Repayment Frequency : </span> {repaymentFrequency}</p></div>
                </>
            )
        }
    }


    const IncomeTypeCheck = ({ income, incomeType }) => {
        let incomeTypeLabel = Constants.INCOME_TYPES[incomeType];

        if (incomeType == "permanentFullTimeEmployment" || incomeType == "permanentPartTimeEmploymentOver25HoursPerWeek" || incomeType == "permanentPartTimeEmploymentLessThan25HoursPerWeek" || incomeType == "contractorEmployment" || incomeType == "onCallCasualEmployment" || incomeType == "casualEmployment" || incomeType == "workCoverEmployment") {
            let duration = income["duration"] !== undefined ? income["duration"] : "";
            let grossIncome = income["grossIncome"] !== undefined ? income["grossIncome"] : "";
            let netIncome = income["netIncome"] !== undefined ? income["netIncome"] : "";
            let frequency = income["frequencey"] !== undefined ? income["frequencey"] : "";
            let employerName = income["employer"] !== undefined && income["employer"]["name"] !== undefined ? income["employer"]["name"] : "";
            let employerContactNumber = income["employer"] !== undefined && income["employer"]["contactNumber"] !== undefined ? income["employer"]["contactNumber"] : "";
            let employerAddress = income["employer"] !== undefined && income["employer"]["address"] !== undefined && income["employer"]["address"]["fullAddress"] !== undefined ? income["employer"]["address"]["fullAddress"] : "";
            let occupation = income["occupation"] !== undefined ? income["occupation"] : "";

            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration : </span> {duration} Months</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gross Income (before tax) : </span>$  {grossIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Net Income (after tax) : </span>$  {netIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Frequency : </span> {frequency}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Employer Name : </span> {employerName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Employer # : </span> {employerContactNumber} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Employer Address : </span> {employerAddress}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Occupation : </span> {occupation}</p></div>
                </>
            )
        }
        else if (incomeType == "other") {
            let duration = income["duration"] !== undefined ? income["duration"] : "";
            let grossIncome = income["grossIncome"] !== undefined ? income["grossIncome"] : "";
            let netIncome = income["netIncome"] !== undefined ? income["netIncome"] : "";
            let frequency = income["frequencey"] !== undefined ? income["frequencey"] : "";
            let otherIncomeType = income["otherIncomeType"] !== undefined ? income["otherIncomeType"] : "";
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration : </span> {duration} Months</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gross Income (before tax) : </span>$  {grossIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Net Income (after tax) : </span>$  {netIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Frequency # : </span> {frequency} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Other Income Type : </span> {otherIncomeType}</p></div>
                </>
            )
        }
        else if (incomeType == "homeDuties") {
            let duration = income["duration"] !== undefined ? income["duration"] : "";
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration : </span> {duration} Months</p></div>
                </>
            )
        }
        else if (incomeType == "courtOrderedChildSupport" || incomeType == "governmentBenefits" || incomeType == "tac") {
            let duration = income["duration"] !== undefined ? income["duration"] : "";
            let grossIncome = income["grossIncome"] !== undefined ? income["grossIncome"] : "";
            let netIncome = income["netIncome"] !== undefined ? income["netIncome"] : "";
            let frequency = income["frequencey"] !== undefined ? income["frequencey"] : "";
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration : </span> {duration} Months</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gross Income (before tax) : </span>$  {grossIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Net Income (after tax) : </span>$  {netIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Frequency # : </span> {frequency} </p></div>
                </>
            )
        }
        else if (incomeType == "investmentPropertyRentalIncome") {
            let duration = income["duration"] !== undefined ? income["duration"] : "";
            let grossIncome = income["grossIncome"] !== undefined ? income["grossIncome"] : "";
            let netIncome = income["netIncome"] !== undefined ? income["netIncome"] : "";
            let frequency = income["frequencey"] !== undefined ? income["frequencey"] : "";
            let agentName = income["agentName"] !== undefined ? income["agentName"] : "";
            let agentContactNumber = income["agentContactNumber"] !== undefined ? income["agentContactNumber"] : "";
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Duration : </span> {duration} Months</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gross Income (before tax) : </span>$  {grossIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Net Income (after tax) : </span>$  {netIncome}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Frequency # : </span> {frequency} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Managing Agent Name : </span>{agentName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Managing Agent Number : </span> {agentContactNumber} </p></div>
                </>
            )
        }
        else if (incomeType == "abnOrSelfEmployment") {
            let abn = income["abn"] !== undefined ? income["abn"] : "";
            let acn = income["acn"] !== undefined ? income["acn"] : "";
            let businessName = income["businessName"] !== undefined ? income["businessName"] : "";
            let organisationName = income["organisationName"] !== undefined ? income["organisationName"] : "";
            let effectiveSince = income["effectiveSince"] !== undefined ? income["effectiveSince"] : "";
            let gstRegisteredSince = income["gstRegisteredSince"] !== undefined ? income["gstRegisteredSince"] : "";
            let bunsinessTradingAddress = income["bunsinessTradingAddress"] !== undefined && income["bunsinessTradingAddress"]["fullAddress"] !== undefined ? income["bunsinessTradingAddress"]["fullAddress"] : "";
            let accountantName = income["accountantName"] !== undefined ? income["accountantName"] : "";
            let accountantContactNumber = income["accountantContactNumber"] !== undefined ? income["accountantContactNumber"] : "";
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>ABN : </span> {abn} Months</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>ACN : </span>{acn}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Business Name : </span>  {businessName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Organisation Name : </span> {organisationName} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Effective Since : </span>{effectiveSince}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Gst Registered Since : </span> {gstRegisteredSince} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Bunsiness Trading Address : </span> {bunsinessTradingAddress} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Accountant Name : </span> {accountantName} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Accountant Contact Number # : </span> {accountantContactNumber} </p></div>
                </>
            )
        }
    }

    const InsuranceCheck = ({ insurances }) => {

        var cciInsurance = insurances.find(singleInsurance => singleInsurance.type == "cci");
        var comprehensiveInsurance = insurances.find(singleInsurance => singleInsurance.type == "comprehensive");
        var gapInsurance = insurances.find(singleInsurance => singleInsurance.type == "gap");
        var ltiInsurance = insurances.find(singleInsurance => singleInsurance.type == "lti");
        var rtiInsurance = insurances.find(singleInsurance => singleInsurance.type == "rti");
        var warrentyInsurance = insurances.find(singleInsurance => singleInsurance.type == "warrenty");
        let content = [];
        console.log("comprehensiveInsurance", comprehensiveInsurance);
        if (cciInsurance != undefined || comprehensiveInsurance != undefined || gapInsurance != undefined || ltiInsurance != undefined || rtiInsurance != undefined || warrentyInsurance != undefined) {
            content.push(<div className='col-print-12'><p className='printValue'><span className='printLabel'> Insurance : </span> yes</p></div>);
        }
        else {
            content.push(<div className='col-print-12'><p className='printValue'><span className='printLabel'> Insurance : </span> No</p></div>);
        }

        if (comprehensiveInsurance != undefined) {
            var compCompany = comprehensiveInsurance["insuranceCompanyId"] !== undefined ? comprehensiveInsurance["insuranceCompanyId"] : "";
            var compPremium = comprehensiveInsurance["grossPremium"] !== undefined ? comprehensiveInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>Comp Company : </span> {compCompany} </p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>Comp Premium : $</span> {compPremium} </p></div>);
        }
        if (cciInsurance != undefined) {
            var cciCompany = cciInsurance["insuranceCompanyId"] !== undefined ? cciInsurance["insuranceCompanyId"] : "";
            var cciPremium = cciInsurance["grossPremium"] !== undefined ? cciInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>CCI Company : </span> {cciCompany} </p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>CCI Premium : $</span> {cciPremium} </p></div>);
        }
        if (gapInsurance != undefined) {
            var gapCompany = gapInsurance["insuranceCompanyId"] !== undefined ? gapInsurance["insuranceCompanyId"] : "";
            var gapPremium = gapInsurance["grossPremium"] !== undefined ? gapInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>GAP Company : </span> {gapCompany} </p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>GAP Premium : $</span> {gapPremium} </p></div>);
        }
        if (warrentyInsurance != undefined) {
            var warrentyCompany = warrentyInsurance["insuranceCompanyId"] !== undefined ? warrentyInsurance["insuranceCompanyId"] : "";
            var warrentyPremium = warrentyInsurance["grossPremium"] !== undefined ? warrentyInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>WTY Company : </span> {warrentyCompany}</p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>Gross Premium : $</span> {warrentyPremium} </p></div>);
        }
        if (ltiInsurance != undefined) {
            var ltiCompany = ltiInsurance["insuranceCompanyId"] !== undefined ? ltiInsurance["insuranceCompanyId"] : "";
            var ltiPremium = ltiInsurance["grossPremium"] !== undefined ? ltiInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>LTI Company : </span> {ltiCompany}</p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>LTI Premium  : $</span> {ltiPremium} </p></div>);
        }
        if (rtiInsurance != undefined) {
            var rtiCompany = rtiInsurance["insuranceCompanyId"] !== undefined ? rtiInsurance["insuranceCompanyId"] : "";
            var rtiPremium = rtiInsurance["grossPremium"] !== undefined ? rtiInsurance["grossPremium"] : "";
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>RTI Company : </span> {rtiCompany}</p></div>);
            content.push(<div className='col-print-6'><p className='printValue'><span className='printLabel'>RTI Premium  : $</span> {rtiPremium} </p></div>);
        }

        return content;
    }


    const AssetTypeCheck = ({ asset, assetType }) => {

        //var make = vehicle["make"] !== undefined ? vehicle["make"] : "";
        if (assetType == "car" || assetType == "boat" || assetType == "truck" || assetType == "caravan" || assetType == "motorBike" || assetType == "jetSki") {
            let year = asset["year"] !== undefined ? asset["year"] : "";
            let make = asset["make"] !== undefined ? asset["make"] : "";
            let model = asset["model"] !== undefined ? asset["model"] : "";
            let state = asset["state"] !== undefined ? asset["state"] : "";
            let vin = asset["vin"] !== undefined ? asset["vin"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Year : </span> {year} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Make : </span> {make} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Model : </span> {model} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Asset State : </span>  {state}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>VIN/Rego : </span> {vin} </p></div>

                </>
            )
        }
        else if (assetType == "cashInBank") {
            let bankName = asset["description"] !== undefined ? asset["description"] : "";
            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Bank Name : </span> {bankName} </p></div>
                </>
            )
        }
        else if (assetType == "other") {
            let other = asset["description"] !== undefined ? asset["description"] : "";
            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Description : </span> {other} </p></div>
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    const LicenseDetails = ({ unlicensed, number, cardNumber, type, state, licenseExpiryDate }) => {

        if (unlicensed == true) {
            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Unlicensed : </span> Yes  </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>License # : </span>  </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Card Number: </span>  </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Type : </span> </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Issued By : </span>  </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Expiry Date : </span>  </p></div>
                </>
            )
        }
        else {
            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>License # : </span> {number} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Card Number: </span> {cardNumber} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Type : </span> {type} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Issued By : </span> {state} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Expiry Date : </span> {licenseExpiryDate} </p></div>
                </>
            )
        }
    }

    const CheckItemFiananced = ({ financed, financierName, financierAmOutstand }) => {

        if (financed == true) {
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'> Financed : </span> Yes </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'> Financier : </span> {financierName} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>   Amount Outstanding : </span>$ {financierAmOutstand} </p></div>
                </>
            )
        }
        else {
            return (
                <>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'> Trade In Item Financed : </span> No </p></div>
                </>
            )
        }
    }

    const TradeIn = ({ tradeIn, isTradingIn, financed, financierAmOutstand, financierName }) => {

        if (isTradingIn == true) {

            let vehicle = tradeIn["vehicle"] !== undefined ? tradeIn["vehicle"] : {};
            let year = vehicle["year"] !== undefined ? vehicle["year"] : "";
            let make = vehicle["make"] !== undefined ? vehicle["make"] : "";
            let model = vehicle["model"] !== undefined ? vehicle["model"] : "";
            let transmission = vehicle["transmission"] !== undefined ? vehicle["transmission"] : "";
            let registration = vehicle["registrationNumber"] !== undefined ? vehicle["registrationNumber"] : "";
            let bodyType = vehicle["bodyType"] !== undefined ? vehicle["bodyType"] : "";
            let vin = vehicle["vin"] !== undefined ? vehicle["vin"] : "";
            let engineNumber = vehicle["engineNumber"] !== undefined ? vehicle["engineNumber"] : "";
            let color = vehicle["color"] !== undefined ? vehicle["color"] : "";

            //  var worth = vehicle["worth"] !== undefined ? vehicle["worth"] : "";
            //   var currentMileage = vehicle["currentMileage"] !== undefined ? vehicle["currentMileage"] : "";

            return (
                <>


                    <div className='col-print-12' style={{ marginTop: "6px", marginLeft: "2px", marginBottom: "4px", textDecoration: "underline" }}><h5>Trade-In Details</h5></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Asset Year : </span> {year} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Make : </span> {make} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Model : </span> {model} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Body Type : </span>  {bodyType}</p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Transmission : </span> {transmission}</p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Registration # : </span>   {registration} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>VIN/HIN : </span> {vin} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Engine # : </span>  {engineNumber} </p></div>
                    <div className='col-print-6'><p className='printValue'><span className='printLabel'>Color : </span>   {color} </p></div>
                    <CheckItemFiananced financed={financed} financierName={financierName} financierAmOutstand={financierAmOutstand} />
                </>
            )
        }
        else {
            return (
                <>
                    <CheckItemFiananced financed={financed} financierName={financierName} financierAmOutstand={financierAmOutstand} />

                </>
            )
        }

    }

    const AddressDetails = ({ currentResidentialHistory }) => {
        let residentialStatus = currentResidentialHistory["status"] !== undefined ? currentResidentialHistory["status"] : "";

        if (residentialStatus == "rentingAgent" || residentialStatus == "rentingPrivate" || residentialStatus == "boardingOther" || residentialStatus == "boardingLivingWithFamily" || residentialStatus == "caravanPark" || residentialStatus == "hotelOrHostel") {
            let landlord = currentResidentialHistory["landlord"] !== undefined ? currentResidentialHistory["landlord"] : "";
            let rent = currentResidentialHistory["rent"] !== undefined ? currentResidentialHistory["rent"] : "";
            let individualOnLease = rent["totalIndividualsOnLease"] !== undefined ? rent["totalIndividualsOnLease"] : "";
            let totalMonthlyRent = rent["totalMonthlyRent"] !== undefined ? rent["totalMonthlyRent"] : "";
            let yourShareRent = rent["applicantShareOfMonthlyRent"] !== undefined ? rent["applicantShareOfMonthlyRent"] : "";
            let landlordName = landlord["name"] !== undefined ? landlord["name"] : "";
            let landlordContact = landlord["contactNumber"] !== undefined ? landlord["contactNumber"] : "";
            let landlordAddress = landlord["address"] !== undefined && landlord["address"]["fullAddress"] !== undefined ? landlord["address"]["fullAddress"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Number of Individuals on Lease : </span> {individualOnLease} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Total Monthly Rent Amount : </span> {totalMonthlyRent} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Your share of Total Rent Amount : </span> {yourShareRent} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Name : </span>  {landlordName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Contact # : </span> {landlordContact} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Address : </span> {landlordAddress} </p></div>
                </>
            )
        }
        else if (residentialStatus == "suppliedByEmployer") {
            let landlord = currentResidentialHistory["landlord"] !== undefined ? currentResidentialHistory["landlord"] : "";
            let landlordName = landlord["name"] !== undefined ? landlord["name"] : "";
            let landlordContact = landlord["contactNumber"] !== undefined ? landlord["contactNumber"] : "";
            let landlordAddress = landlord["address"] !== undefined && landlord["address"]["fullAddress"] !== undefined ? landlord["address"]["fullAddress"] : "";

            return (
                <>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Name : </span>  {landlordName}</p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Contact # : </span> {landlordContact} </p></div>
                    <div className='col-print-4'><p className='printValue'><span className='printLabel'>Landlord Address : </span> {landlordAddress} </p></div>

                </>
            )
        }
        else {

            return (
                <>
                </>
            )
        }
    }

    //------------------------------------------------------end super child functions

    return (
        <>
            <div style={{ float: "right" }}>
                <ReactToPrint
                    trigger={() => <button style={{ border: "0px", background: "transparent" }}> <Printer /></button>}
                    content={() => componentRef.current}
                />
            </div>
            <div ref={componentRef}>
                <GetRefererDetails />
                <GetQuote />
                <GetPersonalDetail />
                <GetIncomeDetails />
                <GetAssetDetails />
                <GetDebtDetails />
                <GetExpensesDetails />
                <GetNotes />
            </div>
        </>
    )
}

export default PrintApplication;


{

    /*
    
                       
            { JSON.stringify(referrerGroup) }
            { JSON.stringify(referrer) }
            { JSON.stringify(referrerUser) }


            <GetRefererDetails />
            <GetQuote />
            <GetPersonalDetail />
            <GetIncomeDetails />
            <GetAssetDetails />
            <GetDebtDetails />
            <GetExpensesDetails />

    
    */
}