import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import ReferrerSignupValidationSchema from './schema/ReferrerSignupValidationSchema';
import { Formik, Form, Field } from 'formik';
import { newReferrerSignupRequest } from '../../api';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';
import { CheckBox } from '../HtmlComponents';



const ReferrerSignup = () => {
    const userRef = useRef();
    const [user, setUser] = useState({ "name": "", 'companyName': "", 'email': "", "contactNumber": "", authorized:false });
    const [formErrors, setFormErrors] = useState({});
    let navigate = useNavigate();
    const [sentSuccessfully, setSentSuccessfully] = useState(false);

    var token = useToken();

    const SignUpForm = () => {
        return (
            <>
                <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                    <div className="d-flex flex-row-fluid flex-column text-center">
                                        <a href="#" className="py-9 mb-5">
                                            <img alt="Logo" src="/media/logos/brokahub-logo-horizontal-black.svg" className="h-75px" />
                                        </a>
                                    </div>
                                    <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                    <div className='card'>
                                        <div className='card-body'>
                                            <Formik
                                                initialValues={user}
                                                validationSchema={ReferrerSignupValidationSchema}
                                                validateOnBlur={false}

                                                onSubmit={async (values) => {
                                                    console.log("VALUES", values);
                                                    const response = await newReferrerSignupRequest(values);
                                                    if (response["error"]) {
                                                        Notifications("error", response["message"] || "Error")
                                                    } else {
                                                        console.log("response.id : " + response.id);
                                                        Notifications("success", response["message"] || "Created Successfully");

                                                        setSentSuccessfully(true);
                                                        //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                                                        console.log(response);
                                                    }

                                                }}
                                            >
                                                {({ errors, touched, handleChange, setFieldValue }) => (
                                                    <Form>
                                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                                            <div style={{ marginBottom: "12px" }}>
                                                                <Field name="name" placeholder="Name" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                                {errors.name && touched.name ? (<div className="error">{errors.name}</div>) : null}
                                                            </div>
                                                            <div style={{ marginBottom: "12px" }}>
                                                                <Field name="companyName" placeholder="Company Name" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                                {errors.companyName && touched.companyName ? (<div className="error">{errors.companyName}</div>) : null}
                                                            </div>
                                                            <div style={{ marginBottom: "12px" }}>
                                                                <Field name="email" type="email" placeholder="Email" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                                {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}
                                                            </div>
                                                            <div style={{ marginBottom: "12px" }}>
                                                                <Field name="contactNumber" placeholder="Phone Number" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                                {errors.contactNumber && touched.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}
                                                            </div>
                                                            <div style={{ marginBottom: "12px" }}>
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{paddingRight: "12px"}}>
                                                                    <Field name="authorized">
                                                                        {({ field }) => (
                                                                            <CheckBox name="authorized" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                                        )}
                                                                    </Field>
                                                                    </div>
                                                                    <label>I authorise NFAL Pty Ltd to contact me</label>
                                                                </div>
                                                                {errors.authorized && touched.authorized ? (<div className="error">{errors.authorized}</div>) : null}
                                                            </div>


                                                        </div>

                                                        <div className="text-center">

                                                            {JSON.stringify(errors).length > 3 ?
                                                                <button type="submit" className="btn btn-lg custom-btn w-100 mb-5" disabled >
                                                                    <span className="indicator-label">Send</span>
                                                                    <span className="indicator-progress">Please wait...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                </button> :

                                                                <button type="submit" className="btn btn-lg custom-btn w-100 mb-5"  >
                                                                    <span className="indicator-label">Send</span>
                                                                    <span className="indicator-progress">Please wait...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                </button>

                                                            }
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

    const SentSuccessfullyForm = () => {
        return (
            <>
                <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                    <div className="d-flex flex-row-fluid flex-column text-center">
                                        <a href="#" className="py-9 mb-5">
                                            <img alt="Logo" src="/media/logos/brokahub-logo-horizontal-black.svg" className="h-75px" />
                                        </a>
                                    </div>
                                    <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                    <div className='card'>
                                        <div className='card-body'>
                                            <h2>Submitted Successfully</h2>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                sentSuccessfully ? <SentSuccessfullyForm /> : <SignUpForm />
            }
        </>
    )

}

export default ReferrerSignup;