import axios from '../api/axios';

 /* LIST ALL REFERRERS AGAINST THE USER PARENT ID ( CREDIT REP ID | COMPANY ID) - FILTER => REFERRER NAME OR REFERRER EMPLOYEE NAME */
const getRegister = async (token, type) => {
  try{
    const response = await axios.get(`register/${type}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Fetched successfully", data: response.data }
  }
  catch(e){
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" , register: [] }
    }
  }
   

}

const getSingleRegister = async (token, type, id) => {
      
  
    try{
        const response = await axios.get(`register/${type}/${id}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "Fetched successfully", data: response.data }
      }
      catch(e){
        if (e.response) {
          return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" , register: {} }
        }
      }
  }
   

//CREATE REFERRER
const createRegister = async (registerObj, token) => {
  try {
    const response = await axios.post("register", registerObj, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}
 

//CREATE REFERRER
const updateRegister = async (registerObj, registerId, token) => {
  try { 
    const response = await axios.put(`register/${registerId}`, registerObj, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}
 

export {  createRegister , updateRegister , getRegister , getSingleRegister };