
import Error404 from "./Error404";
import Error403 from "./Error403";
import Error500 from "./Error500";
import ErrorDefault from './ErrorDefault';


const DecideErrorPage = ({ errorCode }) => {
    switch (errorCode) {
        case 404:
            return <Error404 />
            break;
        case 403:
            return <Error403 />
            break;
        case 500:
            return <Error500 />
            break;
        default:
            return <ErrorDefault/>;
            break;
    }
}

export default DecideErrorPage;