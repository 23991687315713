import React, { useEffect, useState, useRef } from 'react';
import { useEntityId, useEntityType, useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, InputField, SelectDatePicker } from '../HtmlComponents';
import { replaceNullWithString, formatDate, getCurrentDate, dateFilter } from '../../helpers';
import { Formik, Form, Field, FieldArray } from 'formik';
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../../components/global/LoadingScreen';
import { getUsers, createStaffBudget } from "../../api";
import { StaffBudgetValidationSchema } from './schema/StaffBudgetValidationSchema';


const CreateStaffBudget = ({ callback }) => {
    const [processing, setProcessing] = useState(false);
    const [budget, setBudget] = useState({ usersBudget: [] });

    const entityType = useEntityType();
    const entityId = useEntityId();
    const currentDate = getCurrentDate("YYYY-MM-DD");

    useEffect(() => {
        fetchUsers();
    }, [])

    const fetchUsers = async () => {

        //MAKE API REQUEST
        setProcessing(true);
        var response = await getUsers(token, entityType, entityId);
        if (response["error"]) { }
        else {
            var tmpUsersList = [];

            if (response != undefined && response["data"] != undefined && Array.isArray(response["data"])) {
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].role == "analyst" || response.data[i].role == "admin") {
                        var firstName = response.data[i].firstName != undefined ? response.data[i].firstName : "";
                        var surname = response.data[i].surname != undefined ? response.data[i].surname : "";
                        tmpUsersList.push({ userId:response.data[i].id , name: firstName + " " + surname, amount: 0 });
                    }
                }
                setBudget({ 
                    usersBudget: tmpUsersList,
                    budgetDate : currentDate
                 })

            }
        }
        setProcessing(false);
    }

    const token = useToken();

    return (
        <>
            <Formik
                initialValues={budget}
                validationSchema={StaffBudgetValidationSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                onSubmit={async (values) => {

                    var data = JSON.parse(JSON.stringify(values));
                    console.log("dataaaaaaaaaaa", values)
                    console.log("dataaaaaaaaaaa", data)


              
                    const response = await createStaffBudget(token, data);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error")
                        //setOfficeContact(values);
                    } else {
                        console.log("response.id : " + response.id);
                        Notifications("success", response["message"] || "Created Successfully")
                        callback("newBudgetCreated");
                    }                    
                    setProcessing(false);  
                }}
            >
                {({ errors, values, handleChange, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className='col-sm-12 mb-5'>
                                                <div className='col'>Date</div>
                                                <Field name="budgetDate">
                                                    {({ field }) => (
                                                        <SelectDatePicker
                                                            fieldName="budgetDate"
                                                            fieldValue={Number.isInteger(field.value) && field.value !== 0 ? formatDate(field.value, "unix", "YYYY-MM-DD") : (field.value == 0 ? currentDate : field.value)}
                                                            setFieldValue={setFieldValue}
                                                            displayDayField={0}
                                                            displayMonthField={1}
                                                            displayYearField={1}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.budgetDate && touched.budgetDate ? (<div className="error">{errors.budgetDate}</div>) : null}
                                            </div>

                                            <FieldArray
                                                name="usersBudget"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.usersBudget.map((value, index) => (
                                                            <div key={index}>
                                                                <div className='col-sm-12 mb-5' style={{ display: "flex", gap: "1rem" }}>

                                                                    <input
                                                                        name={`usersBudget[${index}].name`}
                                                                        value={values.usersBudget[index].name}
                                                                        className="form-control"
                                                                        style={{ height: "35px", fontWeight: "400" }}
                                                                        onChange={handleChange} 
                                                                        readOnly
                                                                    />
                                                                    <input
                                                                        name={`usersBudget[${index}].amount`}
                                                                        value={values.usersBudget[index].amount}
                                                                        type='number'
                                                                        className="form-control"
                                                                        style={{ height: "35px", fontWeight: "400" }}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            />

                                        </div>
                                    </div>
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    </Form>

                )}

            </Formik>

            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default CreateStaffBudget;