import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";
import SelectFieldGroup from '../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getFolders, getFiles, } from "../../../api";
import { useToken } from '../../../hooks/useAuth';
import LoadingScreen from '../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../schema/SubmitToLenderValidationSchema';

const SubmitFilesToLender = ({ applicationId, lenderName, callback }) => {
    const [selectedLender, setSelectedLender] = useState("");
    const [initialValues, setInitialValues] = useState({ lender: "", productType: "", frequency: "", repaymentAmount: "", paymentMethod: "" });
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");

    const [files, setFiles] = useState([]);
    const [filteredFiles, setFilteredFiles] = useState([]);  //FILTER FILES IF FOLDER IS SELECTED
    const [selectedFiles, setSelectedFiles] = useState({}); //CONTAINS THE APPLICATION IDS WHICHA RE SELECTED TO PUSH ALONG THE MONEY 3 APPLICATION
    const [folders, setFolders] = useState([]);
    const [filesGroup, setFilesGroup] = useState([]);
    const [selectedFolderId, setSelectedFolderId] = useState(0);

    const token = useToken();
    const formRef = useRef();



    const handler = async (name, value) => {
        let fileId =  value !== undefined && value["fileId"] !== undefined ? value["fileId"]: "";

        switch (name) {
            case "selectedFolderId": setSelectedFolderId(value); break;
            case "selectedApplicationFiles":
                //let fileId = value["fileId"];
                let isSelected = value["isSelected"]; 

                if (!isSelected) {  
                    let selectedFilesClone = JSON.parse(JSON.stringify(selectedFiles));
                    delete selectedFilesClone[fileId];
                    setSelectedFiles({...selectedFilesClone});
                }  //REMOVE THE FILE FROM THE ARRAY
                else { setSelectedFiles( prevSelectedFiles => ({...prevSelectedFiles, [fileId]: { fileId: fileId, documentType: "", notes: "" } }) ) }
                break;
            case "documentType":
                let documentType = value["value"];

                let selectedFilesClone1 = JSON.parse(JSON.stringify(selectedFiles));
                selectedFilesClone1[fileId]["documentType"] = documentType;
                setSelectedFiles({...selectedFilesClone1});
                break;
            case "documentNotes":
                let documentNotes = value["value"];

                let selectedFilesClone2 = JSON.parse(JSON.stringify(selectedFiles));
                selectedFilesClone2[fileId]["notes"] = documentNotes;
                setSelectedFiles({...selectedFilesClone2});

                break;

            case "submitToLender":
                setProcessing(true);
                
                /*let filesClone = [];
                Object.keys(selectedFiles).map(singleFileIndex => { 
                    filesClone.push(selectedFiles[singleFileIndex])
                })*/
                console.log("selectedFiles::: ", selectedFiles);
                var response = await performApplicationAdditionalActions(token, "submitFilesToLender", applicationId, {
                    files: selectedFiles,
                    lender: lenderName
                });
                console.log("response::", response)
                if(response["error"] !== undefined && response["error"] == 0){
                    Notifications("success", response["message"] !== undefined ? response["message"]: "Submitted successfully");
                }
                else{  Notifications("error", response["message"] !== undefined ? response["message"]: "Error"); }
                setProcessing(false);
                break;
        }
    }

    /************************* FILE HANDLING FOR MONEY 3 - START *************************************/
    const fetchFiles = async () => {
        //FETCH ONLY IF FILE STATE IS EMPTY
        if (files.length !== 0) { return; }

        //ADD QUERY PARAMS TO LAST PARAM IF REFERRER USER 
        var thirdParam = applicationId;

        var response = await getFiles(token, 'application', thirdParam);
        let tmpFiles = {};
        if (Array.isArray(response["data"])) {
            response["data"].map(singleRecord => {
                if (tmpFiles["parentFolder" + singleRecord["parentFolderId"]]) {
                    tmpFiles["parentFolder" + singleRecord["parentFolderId"]]["files"].push(singleRecord);
                }
                else {
                    tmpFiles["parentFolder" + singleRecord["parentFolderId"]] = { parentFolderLabel: singleRecord["parentFolderLabel"], files: [] }
                    tmpFiles["parentFolder" + singleRecord["parentFolderId"]]["files"].push(singleRecord);
                }
            });
        }
        setFilesGroup(tmpFiles);
    }

    const fetchFolders = async () => {
        if (folders.length !== 0) { return; }

        var tmpFolderGroups = [];
        var foldersResponse = await getFolders(token, "application");
        if (foldersResponse["error"] == 0) {
            //DEFAULT GROUP WITH ALL FILES OPTION
            tmpFolderGroups.push({ label: "Default", options: [{ label: "All Files", value: 0 }] });

            Object.keys(foldersResponse["folders"]).map(singleFolderGroupIndex => {
                let tmpFolderGroup = { label: foldersResponse["folders"][singleFolderGroupIndex]["label"], options: [] };
                var singleFolderGroupOptions = foldersResponse["folders"][singleFolderGroupIndex]["children"];

                if (Array.isArray(singleFolderGroupOptions)) {
                    singleFolderGroupOptions.map(singleOption => {
                        tmpFolderGroup["options"].push({ label: singleOption["label"], value: singleOption["id"] })
                    })
                }
                tmpFolderGroups.push(tmpFolderGroup);
            });
            setFolders(tmpFolderGroups);
        }
    }
 

    useEffect(() => {
        //FILTER FILES AGAINST SELECTED FOLDER
        fetchFiles(); fetchFolders();
    }, [])



    /************************* FILE HANDLING FOR MONEY 3 - END *************************************/

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="row">
                        <>
                            <div className="col-sm-8"></div>
                            <div className="col-sm-4">
                                <SelectFieldGroup
                                    fieldName="selectedFolderId"
                                    options={folders}
                                    defaultSelectedOption={{ value: selectedFolderId, label: "" }}
                                    setFieldValue={handler}
                                />
                            </div>
                            <div className="col-sm-12 mt-3"><p><b>Select the files</b></p></div>
                            <div className="table-responsive" style={{ overflow: "unset" }}>
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <tbody>
                                        {
                                            Object.keys(filesGroup).map(singleFileGroupIndex => {
                                                return (<>
                                                    <tr><td colSpan={4}>{selectedFolderId === 0? filesGroup[singleFileGroupIndex]["parentFolderLabel"] : "" }</td></tr>
                                                    {
                                                        filesGroup[singleFileGroupIndex]["files"] !== undefined && Array.isArray(filesGroup[singleFileGroupIndex]["files"]) ?
                                                            filesGroup[singleFileGroupIndex]["files"].map(singleFile => (
                                                                <> 
                                                                {
                                                                    selectedFolderId === 0 || singleFile.folderId === selectedFolderId ?
                                                                
                                                                <tr>
                                                                    <td>
                                                                        <div style={{ display: "flex" }}>
                                                                            <div style={{ marginRight: "10px" }}>
                                                                                <CheckBox name={singleFile.id} setFieldValue={(name, value) => { handler("selectedApplicationFiles", { fileId: name, isSelected: value }); }} />
                                                                            </div>
                                                                            <div> <a href={singleFile.preSignedURL} target={"_blank"}>{singleFile.label}</a> </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <InputField
                                                                                fieldName={singleFile.id}
                                                                                placeholder="Document Type"
                                                                                fieldValue= {selectedFiles[singleFile.id] !== undefined && selectedFiles[singleFile.id]["documentType"] !== undefined ? selectedFiles[singleFile.id]["documentType"]: ""}
                                                                                setFieldValue={(fieldName, inputString) => { handler("documentType", { fileId: fieldName, value: inputString }); } }
                                                                                capitalizeFirstLetter={true}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <InputField
                                                                                fieldName={singleFile.id}
                                                                                placeholder="Additional Notes"
                                                                                fieldValue={selectedFiles[singleFile.id] !== undefined && selectedFiles[singleFile.id]["notes"] !== undefined ? selectedFiles[singleFile.id]["notes"]: ""}
                                                                                setFieldValue={(fieldName, inputString) => { handler("documentNotes", { fileId: fieldName, value: inputString }); } }
                                                                                capitalizeFirstLetter={false}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr> : "" } </>
                                                            )) : <tr>
                                                                <td colSpan={5}> <p> No File found, Please upload</p> </td>
                                                            </tr>
                                                    }
                                                </>)
                                            })  
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </div>
                    <div className="separator separator-dashed my-5"></div>
                    <div className="col-xl-12">
                        <div style={{ float: "right" }}>
                            <CustomButton
                                buttonClassName=" btn-primary btn-sm btn-nochange-on-hover"
                                buttonStyle={{ fontSize: "12px", }}
                                buttonText="Submit to Lender"
                                isActive={true}
                                buttonOnClick={() => { handler("submitToLender", {}) }}
                            />
                        </div>

                    </div>



                </div>
            </div>


            {/*
            <div className='d-flex row'>
                <h3>Logs</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                </div>
            </div>
            */}

            {
                processing ? <LoadingScreen /> : ""
            }


        </>
    )
}


export default SubmitFilesToLender;