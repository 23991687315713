import { useEffect, useRef, useState } from 'react';
import { getCurrentDate , formatDate} from "../../../helpers";
import Constants from "../../global/Constants";
import ReactToPrint from 'react-to-print';
import { Printer } from '../../HtmlComponents/Icons';

const AssetsAndLiabilitiesStatement = ({ application, applicant }) => {


    const [assets, setAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [quickInfo, setQuickInfo] = useState({
        name: "",
        date: "",
        signingDate: "",
        totalAssets: 0,
        totalLiabilities: 0,
        netValue: 0  //    toal assets - total liabilities
    })
    const componentRef = useRef();

    useEffect(() => { calculateValues(); }, []);

    console.log("application", application)

    //LOAD VALUES INTO STATES
    const calculateValues = async () => {
        try {
            var tmpAssets = applicant["assets"] !== undefined && Array.isArray(applicant["assets"]) ? applicant["assets"] : [];
            var tmpDebts = applicant["debts"] !== undefined && Array.isArray(applicant["debts"]) ? applicant["debts"] : [];

            var tmpTotalAssets = 0;
            var tmpTotalDebts = 0;

            await new Promise((resolve) => {
                var tmpAssetsArray = [];
                tmpAssets.map((singleAsset) => {
                    tmpAssetsArray.push({ name: Constants["ASSETS_TYPES"][singleAsset["assetType"]], value: singleAsset["assetValue"] });
                    tmpTotalAssets = !isNaN(parseFloat(singleAsset["assetValue"])) ? (parseFloat(tmpTotalAssets) + parseFloat(singleAsset["assetValue"])).toFixed(2) : tmpTotalAssets;
                })
                setAssets(tmpAssetsArray)
                resolve({});
            });

            //DEBTS 
            await new Promise((resolve) => {
                var tmpDebtsArray = [];
                tmpDebts.map((singleDebt) => {
                    tmpDebtsArray.push({ name: Constants["DEBT_TYPES"][singleDebt["debtType"]], value: singleDebt["balanceRemaining"] });
                    tmpTotalDebts = !isNaN(parseFloat(singleDebt["assetValue"])) ? (parseFloat(tmpTotalDebts) + parseFloat(singleDebt["balanceRemaining"])).toFixed(2) : tmpTotalDebts;
                });
                setLiabilities(tmpDebtsArray);
                console.log("tmpDebts:: ", tmpDebts);
                console.log("tmpDebtsArray:: ", tmpDebtsArray);
                resolve({});
            });


            var tmpInfo = {
                name: applicant["personalDetail"] ? (applicant["personalDetail"]["firstName"] + "" || "") + (applicant["personalDetail"]["middleName"] + " " || "") + applicant["personalDetail"]["surname"] : "",
                date: application["createdAt"] !== undefined ? formatDate(application["createdAt"], "unix"  , "DD/MM/YYYY") : "",
                signingDate: getCurrentDate(),
                totalAssets: tmpTotalAssets,
                totalLiabilities: tmpTotalDebts,
                netValue: (tmpTotalAssets - tmpTotalDebts).toFixed(2)
            }

            setQuickInfo(tmpInfo);
        }
        catch (e) {

        }
    }


    return (
        <>

            <div style={{ float: "right" }}>
                <ReactToPrint
                    trigger={() => <button style={{ border: "0px", background: "transparent" }}> <Printer /></button>}
                    content={() => componentRef.current}
                />
            </div>

            <div ref={componentRef}>
                <div className='printMainHeading' style={{ textAlign: "center", background: "white" }}  >
                    <h3> Statement of Assets and Liabilities</h3>
                </div>
                <div className='assetsAndLiabilitiesStatementContainer'  >
                    <div className='custom-row printMainHeading' >
                        <div className='col-print-6'> <h3>Customer Name: {quickInfo.name}</h3> </div>
                        <div className='col-print-6' style={{ textAlign: "right" }}> <h3>Referral Date: {quickInfo.date}</h3> </div>
                    </div>

                    <div className='custom-row printMainHeading' style={{ marginBottom: "0px" }} > <div className='col-print-12'> <h3>Assets</h3> </div> </div>
                    <div className=" custom-row stripped">
                        {
                            Array.isArray(assets) ?
                                assets.map(singleAsset => {
                                    return (
                                        <>
                                            <div className="col-sm-12">
                                                <div className="custom-row">
                                                    <div className='col-print-6' style={{ paddingLeft: "20px" }}><p>{singleAsset.name || ""}</p>  </div>
                                                    <div className='col-print-6' style={{ textAlign: "right" }}> <p>$ {singleAsset.value || ""}</p> </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                : ""
                        }

                        <div className="col-sm-12">
                            <div className="custom-row" style={{ marginBottom: "0px" }}>
                                <div className='col-print-6' style={{ paddingLeft: "20px" }}><b><p>Total Assets</p></b>  </div>
                                <div className='col-print-6' style={{ textAlign: "right" }}> <b><p>$ {quickInfo.totalAssets || ""}</p></b> </div>
                            </div>
                        </div>

                    </div>



                    {/*********************************LIABILITIES*********************** */}

                    <div className='custom-row printMainHeading' style={{ marginBottom: "0px", marginTop: "0px" }}> <div className='col-print-12'> <h3>Liabilities</h3> </div> </div>
                    <div className=" custom-row stripped">
                        {
                            Array.isArray(liabilities) ?
                                liabilities.map(singleLiability => {
                                    return (
                                        <>
                                            <div className="col-sm-12">
                                                <div className="custom-row">
                                                    <div className='col-print-6' style={{ paddingLeft: "20px" }}><p>{singleLiability.name || ""}</p>  </div>
                                                    <div className='col-print-6' style={{ textAlign: "right" }}> <p>$ {singleLiability.value || ""}</p> </div>
                                                </div>
                                            </div>


                                        </>
                                    )
                                })
                                : ""
                        }
                        <div className="col-sm-12">
                            <div className="custom-row" style={{ marginBottom: "0px" }}>
                                <div className='col-print-6' style={{ paddingLeft: "20px" }}><b><p>Total Liabilities</p></b>  </div>
                                <div className='col-print-6' style={{ textAlign: "right" }}> <b><p>$ {quickInfo.totalLiabilities || ""}</p></b> </div>
                            </div>
                        </div>
                    </div>
                    {/*********************************QUICK INFO*********************** */}
                    <div className='custom-row' >
                        <div className='col-print-6'><b><p>Net Asset/Liability Value</p></b> </div>
                        <div className='col-print-6' style={{ textAlign: "right" }}> <b><p>$ {quickInfo.netValue || ""}</p></b> </div>
                    </div>

                </div>

                {/*********************************SIGNATURE SECTION*********************** */}
                <div className="custom-row" style={{ marginTop: "40px" }}>
                    <div className="col-sm-12"><span style={{ fontWeight: "bold" }}>Name :</span> <span>{quickInfo.name}</span>  </div>
                    <div className="col-sm-12" style={{ marginTop: "10px", marginBottom: "10px" }}><span style={{ fontWeight: "bold" }}>Signature :</span>    </div>
                    <div className="col-sm-12"><span style={{ fontWeight: "bold" }}>Date :</span> <span>{quickInfo.signingDate}</span>  </div>

                </div>

            </div>
        </>
    )
}

export default AssetsAndLiabilitiesStatement;
