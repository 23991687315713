import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import Employer from './Employer';
import Bank from './Bank';

const StandardIncome = ({ prefix, setFieldValue, frequencyList }) => {
    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Months</label>
                <Field name={prefix + "duration"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "duration"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Gross Income(before tax)</label>
                <Field name={prefix + "grossIncome"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "grossIncome"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Net Income (after tax)</label>
                <Field name={prefix + "netIncome"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "netIncome"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Frequency</label>
                <Field name={prefix + "frequencey"}>
                    {({ field }) => (
                        <SelectField
                            fieldName={prefix + "frequencey"}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={frequencyList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-8 fv-row fv-plugins-icon-container" >
                <Employer prefix={prefix + "employer."} setFieldValue={setFieldValue} />
            </div>

            <div className="col-sm-3 col-md-4 mb-5 fv-row fv-plugins-icon-container" >
                <label className=" fs-8 fw-bold mb-2">Occupation</label>
                <Field name={prefix + "occupation"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "occupation"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            {/*
                <div className="col-sm-3 col-md-4 fv-row fv-plugins-icon-container" >     
                    <Bank prefix ={prefix+"bank."} setFieldValue = {setFieldValue} />
                </div>
            */}


        </>
    )
}

export default StandardIncome;