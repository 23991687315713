
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../HtmlComponents';
import SearchBySingleDate from '../global/SearchForms/SearchBySingleDate';
import { listAllReferrerInvocies, submitReferrerInvociesToXero } from '../../api';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';


const ReferrerInvoices = () => {
    const [payload, setPayload] = useState([]); //collection of lenders and each lender will contain his own invoices
    const [date, setDate] = useState({year: "", month: ""})
    const [processing, setProcessing] = useState(false);
    const token = useToken();


    const updateHandler = (name, value) => {
        switch (name) {
            case "dateFilter":
                fetchInvoices(value);
                break;
        }
    }

    const fetchInvoices = async (searchPayload) => {
        var year = searchPayload["year"];
        var month = searchPayload["month"];
        setDate({year: year, month: month});
        var response = await listAllReferrerInvocies(token, year, month);
        if (response["error"] == 0) { setPayload(response["data"]); }
    }

    const submitToXero = async() => {
        var year = date["year"];
        var month = date["month"];
        //SHOW LAODING SCREEN
        setProcessing(true);
        var response = await submitReferrerInvociesToXero(token, year, month);
        if(response["error"] !== undefined && response["error"] == 0){
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            fetchInvoices({year: date["year"], month: date["month"]});
            Notifications("success", message);
            console.log("errorMessages:: ",errorMessages );
        }
        else{
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            Notifications("error", message);
            console.log("errorMessages:: ",errorMessages );
        }
        setProcessing(false);
    }

    return (
        <>

            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <SearchBySingleDate callback={updateHandler} />
                    <div className="col-sm-12">
                        {
                            payload !== undefined && Object.keys(payload).length > 0 ?
                                Object.keys(payload).map(index => {
                                    return (
                                        <>
                                            <div className="row xeroHeading1">
                                                <div className="col-sm-6 "> <span>Referrer Name: {payload[index]["name"]} </span>  </div>
                                                <div className="col-sm-6 "> <span style={{ float: "right", paddingRight: "20px" }}>  Xero Contact Id:  {payload[index]["xeroContactId"]} </span>  </div>
                                            </div>


                                            <div className="row xeroSubHeading2">
                                                <div className="col-sm-4"> <span> Application Id </span> </div>
                                                <div className="col-sm-4"> <span> Applicant Name </span> </div>
                                                <div className="col-sm-4"> <span style={{ float: "right", paddingRight: "20px" }}>  Amount </span> </div>
                                            </div>

                                            {
                                                payload[index]["invoices"] !== undefined && Array.isArray(payload[index]["invoices"]) ?
                                                    payload[index]["invoices"].map(singleInvoice => {
                                                        return (
                                                            <>
                                                                <div className="row xeroInvoice">
                                                                    <div className="col-sm-4"><span>{singleInvoice["applicationId"]}</span></div>
                                                                    <div className="col-sm-4"><span>{singleInvoice["applicantName"]}</span></div>
                                                                    <div className="col-sm-4"><span style={{ float: "right", paddingRight: "20px" }}> $ {singleInvoice["amount"]}</span></div>
                                                                </div>
                                                            </>
                                                        )
                                                    }) : ""
                                            }
                                        </>
                                    )
                                })
                                : ""
                        }
                    </div>
                    {/**************************************** SEND THESE INVOICES TO XERO  ********************/}
                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={submitToXero }
                            buttonText="Send to Xero"
                            isActive={true}
                        />
                    </div>
                </div>
            </div >
            {processing ? <LoadingScreen /> :  "" }
        </>
    )


}

export default ReferrerInvoices;