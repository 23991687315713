import {getdayPeriodType} from '../../../../helpers';

const MotorDealersTemplate = (  paylaod ) => {

    var referrerFirstName = paylaod["firstName"] !== undefined ? paylaod["firstName"] : "";
    var userName = paylaod["userName"] !== undefined ? paylaod["userName"] : "";
    var defaultPassword = paylaod["defaultPassword"] !== undefined ? paylaod["defaultPassword"] : "";
    var dayPeriodType = getdayPeriodType();

    var initialValues = `Good ${dayPeriodType} ${referrerFirstName},</p>`

    +`<p>Thank you for becoming a referral partner of NFAL.</p>`
    
    +`<p>Our process is a very simple referral/client introduction via our portal, Brokahub.</p>`
    
    +`<p>All we need is:</p>`
    +'<ul>'
    +`<li>For you to introduce us and obtain permission from the customer for us to call them. (By entering the referral into Brokahub, we assume this has been done),</li>`
    +`<li>Client Name</li>`
    +`<li>Their contact number (usually mobile), and</li>`
    +`<li>A good time to call them.</li>`
    +`<li>Notes always help, but they’re not necessary.</li>`
    +'</ul>'

    +`<p>Furthermore, you can:</p>`
    +'<ul>'
    +`<li>Upload any supporting documentation</li>`
    +`<li>Receive updates via email and/or SMS – you can have either or both. Default is both. Please let us know if you prefer one or the other.</li>`
    +`<li>Talk to our brokers at any time to workshop deals (we love this)</li>`
    +`<li>See active, archived, and settled deals, and</li>`
    +`<li>Print your RCTI each month or as required to view your referral fees.</li>`
    + `</ul>`
    
    +`<p>Also confirming that our referral fee is 50% of the scheduled upfront commission NFAL receive from the financier, plus a $100 payment onto your NFAL reloadable Mastercard. </p>`
   
    +`<p>Commissions are paid by the 15th of the following month.</p>`

    +`<p>To obtain access to our portal please go to <a href="https://brokahub.com.au">https://brokahub.com.au</a></p>`
    +`<ul>`
    +`<li>Your username is ${userName}</li>`    
    +`<li>Your initial password is ${defaultPassword} which you will be prompted to change once you have logged in.</li>`
    + `</ul>`

    +`<p>Please have a look around and contact us if there are any issues.</p>`
    
    +`<p>Our lender panel consists of 46 asset lenders, providing solutions for the following scenarios:</p>`
    
    +`<ul>`
    +`<li>Motor Vehicle Finance (No restriction on age)</li>`
    +`<li>Varying Capacity guidelines form various lenders</li>`
    +`<li>Motor Cycle, Caravan, Boat loans, Go Karts & Jet Ski’s</li>`
    +`<li>Short Term ABN holder car loans</li>`
    +`<li>Heavy Commercial Vehicle and Equipment Finance</li>`
    +`<li>Forklifts and material handling equipment</li>`
    +`<li>Uber & Ride Share Finance (including start-ups)</li>`
    +`<li>Unsecured Personal Loans (including deposit gap and minus equity shortfalls)</li>`
    +`<li>Private sales</li>`
    +`<li>Small ticket equipment finance (no financials required)</li>`
    +`<li>Cash Flow finance</li>`
    +`<li>Adverse credit history </li>`
    +`</ul>`

    +`<p>I have also attached your Referral Agreement for your records. Please reach out if you have any questions at all.</p>`

    +`<p>Kind Regards and Welcome!</p>`;


    return( initialValues )
}

export default MotorDealersTemplate;