import { Link } from "react-router-dom";
import { formatDate, calculateTimeDifference, capitalizeFirstLowercaseRest } from '../../helpers';
import { CustomButton, IconButton } from '../HtmlComponents/';
import constants from '../../components/global/Constants';
import { useEntityType, useRole } from '../../hooks/useAuth';

const SettlementListItem = ({ singleSettlement, callback , reinstateDate = false}) => {
    const entityType = useEntityType();
    const role = useRole();
console.log(
    "singleSettlement",singleSettlement
)
    //RETURN THE NUMBER OF COLUMNS BASED ON LOGGED IN USER ENTITY TYPE
    const TableRow = () => {
        if (["finance", "superAdmin"].includes(role)) {
            return (
                <>
                    <tr className="even text-start">
                        <td>  <Link to={{ pathname: `/user/editConsumerApplication/${singleSettlement.applicationId}` }} className=" applicationId text-hover-primary mb-1">{singleSettlement.applicationId}</Link>  </td>
                        <td>
                            {singleSettlement.firstName} {singleSettlement.surname}
                            {singleSettlement.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleSettlement.companyName}</span></> : ''}
                        </td>
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        {
                            reinstateDate ? <td>{formatDate(singleSettlement.reinstateDate, "unix", "DD/MM/YYYY")}</td> : null
                        }
                        <td>{singleSettlement.amount}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>{singleSettlement.interestRate}</td>
                        <td>{singleSettlement.term}</td>
                        <td>{singleSettlement.lenderName}</td>
                        <td>{singleSettlement.referrerGroup}  </td>
                        <td>{singleSettlement.referrer}  </td>
                        <td>{singleSettlement.referrerUserName}  </td>
                        <td>
                            <IconButton iconType="notes" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Application status and comments" buttonOnClick={() => {
                                callback("updateProgressStatus", { "applicationId": singleSettlement.applicationId, "progressStatus": 'settled', applicationType: '', loanPurpose: '' })
                            }} />
                            <IconButton iconType="view" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="View Settlement" buttonOnClick={() => { callback("viewSettlement", singleSettlement.id) }} />
                            <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="View Application" buttonOnClick={() => { callback("printApplication", { "applicationId": singleSettlement.applicationId }) }} />
                            <IconButton iconType="edit" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Edit Settlement" buttonOnClick={() => { window.location.href = `/user/settlement/${singleSettlement.id}`; }} />
                            <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("files", singleSettlement.applicationId) }} />
                            { !reinstateDate ? <IconButton iconType="deleteBin" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" buttonOnClick={() => { callback("deleteSettlement", singleSettlement.id) }} /> : null }
                        </td>
                    </tr>
                </>
            )
        }
        else if (["auditor"].includes(role)) {
            return (
                <>
                    <tr className="even text-start">
                        <td>  <Link to={{ pathname: `/user/editConsumerApplication/${singleSettlement.applicationId}` }} className=" applicationId text-hover-primary mb-1">{singleSettlement.applicationId}</Link>  </td>
                        <td>
                            {singleSettlement.firstName} {singleSettlement.surname}
                            {singleSettlement.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleSettlement.companyName}</span></> : ''}
                        </td>
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        {
                            reinstateDate ? <td>{formatDate(singleSettlement.reinstateDate, "unix", "DD/MM/YYYY")}</td> : null
                        }
                        <td>{singleSettlement.amount}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>{singleSettlement.interestRate}</td>
                        <td>{singleSettlement.term}</td>
                        <td>{singleSettlement.lenderName}</td>
                        <td>{singleSettlement.referrerGroup}  </td>
                        <td>{singleSettlement.referrer}  </td>
                        <td>{singleSettlement.referrerUserName}  </td>
                        <td>
                            <IconButton iconType="notes" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Application status and comments" buttonOnClick={() => {
                                callback("updateProgressStatus", { "applicationId": singleSettlement.applicationId, "progressStatus": 'settled', applicationType: '', loanPurpose: '' })
                            }} />
                            <IconButton iconType="view" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="View Settlement" buttonOnClick={() => { callback("viewSettlement", singleSettlement.id) }} />
                            <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="View Application" buttonOnClick={() => { callback("printApplication", { "applicationId": singleSettlement.applicationId }) }} />
                            <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("files", singleSettlement.applicationId) }} />
                        </td>
                    </tr>
                </>
            )
        }
        else if (["admin", "analyst"].includes(role)) {
            return (
                <>
                    <tr className="even text-start">
                        <td>  <Link to={{ pathname: `/user/editConsumerApplication/${singleSettlement.applicationId}` }} className=" applicationId text-hover-primary mb-1">{singleSettlement.applicationId}</Link>  </td>
                        <td>
                            {singleSettlement.firstName} {singleSettlement.surname}
                            {singleSettlement.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleSettlement.companyName}</span></> : ''}
                        </td>
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        {
                            reinstateDate ? <td>{formatDate(singleSettlement.reinstateDate, "unix", "DD/MM/YYYY")}</td> : null
                        }
                        <td>{singleSettlement.amount}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>{singleSettlement.interestRate}</td>
                        <td>{singleSettlement.term}</td>
                        <td>{singleSettlement.lenderName}</td>
                        <td>{singleSettlement.referrerGroup}  </td>
                        <td>{singleSettlement.referrer}  </td>
                        <td>{singleSettlement.referrerUserName}  </td>
                        <td>
                            <IconButton iconType="notes" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Application status and comments" buttonOnClick={() => {
                                callback("updateProgressStatus", { "applicationId": singleSettlement.applicationId, "progressStatus": 'settled', applicationType: '', loanPurpose: '' })
                            }} />
                            <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("files", singleSettlement.applicationId) }} />
                            <IconButton iconType="copy" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Copy" buttonOnClick={() => { callback("copyApplication", singleSettlement.applicationId) }} />

                        </td>
                    </tr>
                </>
                //<td> <span className="applicationStatus" onClick={() => {   }}>   </span></td>

            )
        }
        else if (entityType === "referrerGroup") {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleSettlement.applicationId}  </td>
                        <td>
                            {singleSettlement.firstName} {singleSettlement.surname}
                            {singleSettlement.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleSettlement.companyName}</span></> : ''}
                        </td>
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleSettlement.amount}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>{singleSettlement.referrer}  </td>
                        <td>$ {singleSettlement.referrerCommission}</td>
                        <td>$ {singleSettlement.referrerGroupCommission}</td>
                    </tr>
                </>
            )
        }
        else if (entityType == "referrer") {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleSettlement.applicationId}  </td>
                        <td>
                            {singleSettlement.firstName} {singleSettlement.surname}
                            {singleSettlement.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleSettlement.companyName}</span></> : ''}
                        </td>
                        <td>{singleSettlement.amount}</td>
                        {/*<td>{constants.LOAN_PURPOSES[singleSettlement.purpose]}</td>*/}
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>$ {singleSettlement.referrerCommission}</td>
                    </tr>
                </>
            )
        }
        else {
            return (
                <>
                    <tr className="even text-start">
                        <td>{singleSettlement.applicationId}  </td>
                        <td>{singleSettlement.referrer}  </td>
                        <td>{singleSettlement.firstName}</td>
                        <td>{singleSettlement.surname}</td>
                        <td>{singleSettlement.amount}</td>
                        <td>{constants.LOAN_PURPOSES[singleSettlement.purpose]}</td>
                        <td>{formatDate(singleSettlement.settlementDate, "unix", "DD/MM/YYYY")}</td>
                        <td>{singleSettlement.analyst}</td>
                        <td>{singleSettlement.lenderName}</td>
                    </tr>
                </>
            )
        }
    }


    /** editConsumerApplication => use path based on applicaiton type , FOR CONSUMER APPLICATIONS USE editConsumerApplication, OTHERWISE USE COMMERCIAL ONE */
    return (
        <>
            <TableRow />
        </>
    )
}

export default SettlementListItem;