import React from 'react';
import { useParams } from "react-router-dom";

import Files from '../files/Files';

 

function InsuranceCompanyFiles(props) {
    return(
        <>
        <Files entityType = "insuranceCompany"  entityId= {props.companyId} enableUpload = {true} />
        </>
    )
}

export default InsuranceCompanyFiles;