
import { useRole } from '../../../hooks/useAuth';
import { IconButton , CustomButton } from '../../HtmlComponents';
import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';

const SingleConflictOfInterest = ({ singleConflictOfInterest, callback }) => {

    const userRole = useRole();
    var payload = {};
    try{  payload = singleConflictOfInterest.payload !== undefined ? JSON.parse(singleConflictOfInterest.payload) : {}}
    catch(e){payload = {}; }

    //structure : { date: '', payload: { impactOnClient: "", remedy: "", description: "" }, type: "conflictOfInterest" }
    return (
        <>
        
            <tr>
                <td>
                    <span>{singleConflictOfInterest && singleConflictOfInterest["date"] !== undefined && singleConflictOfInterest["date"] > 0 ? formatDate(singleConflictOfInterest["date"], "unix", "DD/MM/YYYY") : ""} </span>
                    {
                        ['superAdmin', 'finance', 'admin'].includes(userRole) ?
                            <IconButton
                                iconType="edit"
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                buttonOnClick={() => { callback("openUpdateDrawer", { conflictOfInterestId: singleConflictOfInterest.id }) }}
                                tooltipDescription=""
                                buttonStyle={{backgroundColor : "transparent"}}
                            /> : ""
                    }
                </td>
                <td><span>{payload.description !== undefined ? payload.description : ""}</span></td>
                <td><span>{payload.impactOnClient !== undefined ? payload.impactOnClient : ""}</span></td>
                <td><span>{payload.remedy !== undefined ? payload.remedy : ""}</span></td>
            </tr>

        </>

    )
}

export default SingleConflictOfInterest;

