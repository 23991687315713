import * as Yup from 'yup';


const ReferrerSignupValidationSchema =  Yup.object().shape({
    name : Yup.string().required('Required'),
    companyName  : Yup.string().required('Required'),
    email : Yup.string().email("Must be valid email").required('Required'),
    contactNumber: Yup.string() .matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),    
    authorized : Yup.boolean().oneOf([true],'Must be selected')
});
 
export  default ReferrerSignupValidationSchema  ;