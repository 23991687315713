
import { useEffect, useState } from 'react';
import { MultiSelectField, InputField, SelectField, TextArea, CustomButton } from '../../HtmlComponents';
import { getUsers } from '../../../api';
import { useToken, useEntityId } from '../../../hooks/useAuth';
import { getApplicationsCountAgaintStatusAndAnalyst } from '../../../api';


const ApplicationsCountAgaintStatusAndAnalyst = () => {
    const [analystsReport, setAnalystsReport] = useState([]);
    const [filters, setFilters] = useState([]);
    const [reset, setreset] = useState(false);
    const [referralStatusList, setReferralStatusList] = useState([
        { value: "referred", label: "Referred" },
        { value: "assignedtToAnalyst", label: "Assigned to Analyst" },
        { value: "insurancePolicyOnly", label: "Insurance Policy Only" },
        { value: "assignedToConcierge", label: "Assigned to Concierge" },
        { value: "attemptedToContactCustomer", label: "Attempted to contact customer" },
        { value: 'customerContacted-WaitingOnPrivacyConsents', label: "Customer contacted -Waiting on Privacy Consents" },
        //{ value: "privacyConsentsReceived", label: "Privacy Consents Received" },
        { value: 'loanApplicationTaken-Assessing', label: "Privacy Consents Received - Assessing" },
        { value: "loanApplicationSubmitted", label: "Loan Application Submitted" },
        { value: "pendingAdditionalInformation", label: "Waiting on Supporting Documentation" },
        { value: 'referredToMicroLender-Direct', label: "Referred to Micro Lender - Direct" },
        { value: 'approved-WaitingOnGoodsToArrive', label: "Approved - Waiting on Goods to Arrive" },
        { value: "loanContractsSentForSigning", label: "Loan Contracts Sent For Signing" },
        { value: "loanContractsSigned", label: "Loan Contracts Signed" },
        { value: "loanContractsSubmittedForSettlement", label: "Loan Contracts Submitted for Settlement" },
        { value: "loanContractsSettled", label: "Loan Contracts Settled" },
        { value: "declinedPendingFinalReview", label: "Declined Pending Final Review" },
        { value: "unableToAssist", label: "Unable to Assist" },
        { value: 'customerNotResponding-UnableToAssist', label: "Customer Not Responding - Unable to Assist" },
        { value: 'withdrawnByCustomer-UnableToAssist', label: "Withdrawn by Customer - Unable to Assist" },
        { value: 'withdrawnByReferrer-UnableToAssist', label: "Withdrawn by Referrer - Unable to Assist" },
        { value: "settlementComplied", label: "Settlement Complied" },
        { value: "settled", label: "Settled" },
        { value: "approved", label: "Approved" },
        { value: "deferred", label: "Deferred" }
    ]);


    var token = useToken();

    const fetchReport = async () => {
        var response = await getApplicationsCountAgaintStatusAndAnalyst(token);
        console.log("response", response);
        if (response["error"] !== undefined && response["error"] == 0) {
            console.log("response[data]", response["data"]["data"]);
            setAnalystsReport(response["data"]["data"]);
        }
    }

    useEffect(() => {
        fetchReport();
    }, [])

    /*
    useEffect( () => {
        PrepareReport();
    }, [analystsReport])
    */


    const PrepareReport = () => {
        var html = '';
        var inactiveStatus = ['unableToAssist', 'withdrawnByReferrer-UnableToAssist', 'customerNotResponding-UnableToAssist', 'withdrawnByCustomer-UnableToAssist',
            'assignedToConcierge', 'declinedPendingFinalReview', 'insurancePolicyOnly', 'loanContractsSettled', 'settlementComplied', 'deferred'];
        var approvedStatus = ['approved', 'approved-WaitingOnGoodsToArrive'];  //“Approved”  and “Approved Waiting on Goods to arrive”
        var assessingStatus = ['assignedtToAnalyst', 'attemptedToContactCustomer', 'loanApplicationTaken-Assessing', 'pendingAdditionalInformation'];  //“Assigned to Analyst” “Attempted to contact customer” “Privacy Consent received and assessing” and “Waiting on Supporting documents”

        var branchTotalActiveRecords = 0;
        var branchTotalInActiveRecords = 0;
        var branchTotalApprovedRecords = 0;
        var branchTotalAssessingRecords = 0;

        if (Array.isArray(analystsReport)) {
            var result = analystsReport.map(singleAnalystReport => {
                var totalActiveRecords = 0;
                var totalInactiveRecords = 0;
                var totalApprovedRecords = 0;
                var totalAssessingRecords = 0;

                var analystName = singleAnalystReport["analystName"];
                var report = singleAnalystReport["report"];

                Object.keys(report).map((singleStatus) => {
                    if (Array.isArray(filters) && filters.length > 0) {
                        if(filters.includes(singleStatus)){
                            switch (true) {
                                case inactiveStatus.includes(singleStatus): console.log("singleStatus  from inactive bloco line 89 :: ", singleStatus); totalInactiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                                case approvedStatus.includes(singleStatus): totalApprovedRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                                case assessingStatus.includes(singleStatus): totalAssessingRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                                default: totalActiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                            }
                        }
                    }
                    else {
                        switch (true) {
                            case inactiveStatus.includes(singleStatus): console.log("singleStatus  from inactive bloco line 89 :: ", singleStatus); totalInactiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                            case approvedStatus.includes(singleStatus): totalApprovedRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                            case assessingStatus.includes(singleStatus): totalAssessingRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                            default: totalActiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; break;
                        }
                    }

                    /*
                    if (Array.isArray(filters) && filters.length > 0) {
                        if (filters.includes(singleStatus)) {
                            if (inactiveStatus.includes(singleStatus)) { totalInactiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; }
                            else { totalActiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; }
                        }
                    }
                    else {
                        if (inactiveStatus.includes(singleStatus)) { totalInactiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; }
                        else { totalActiveRecords += !isNaN(report[singleStatus]["count"]) ? report[singleStatus]["count"] : 0; }
                    }*/
                })
                branchTotalActiveRecords += totalActiveRecords;
                branchTotalInActiveRecords += totalInactiveRecords;
                branchTotalApprovedRecords += totalApprovedRecords;
                branchTotalAssessingRecords += totalAssessingRecords;


                //RETUNR THE HTML FOR SINGLE ANALYST
                return (
                    <>
                        <tr>
                            <td>{analystName}</td>
                            <td>{totalActiveRecords}</td>
                            <td>{totalInactiveRecords}</td>
                            <td>{totalApprovedRecords}</td>
                            <td>{totalAssessingRecords}</td>
                            <td>{ parseInt(totalActiveRecords) + parseInt(totalInactiveRecords)+ parseInt(totalApprovedRecords)+parseInt(totalAssessingRecords) }</td>
                        </tr>
                    </>
                )
            })

            return (
                <>
                    {result}
                    <tr>
                        <td>Branch</td>
                        <td>{branchTotalActiveRecords} </td>
                        <td> {branchTotalInActiveRecords}</td>
                        <td> {branchTotalApprovedRecords}</td>
                        <td> {branchTotalAssessingRecords}</td>
                        <td>{ parseInt(branchTotalActiveRecords) + parseInt(branchTotalInActiveRecords)+ parseInt(branchTotalApprovedRecords)+parseInt(branchTotalAssessingRecords) }</td>
                    </tr>
                </>
            );
        }
        else {
            return "";

        }
    }

    const FiltersResults = () => {

        return (
            <>
                <div className='col-sm-8'>
                    <MultiSelectField
                        fieldName="referralStatusList"
                        options={referralStatusList}
                        defaultSelectedOptions={filters}
                        setFieldValue={(name, value) => { setFilters(value); }}
                        callback={() => { }}
                    />
                </div>
                <div className='col-sm-4'>
                    <CustomButton buttonClassName="btn-secondary btn-sm" isActive={true} buttonStyle={{}} buttonOnClick={() => { setFilters([]); }} buttonText={"Reset"} />
                </div>

            </>
        )
    }
    return (
        <>
            <div className='row'>
                <FiltersResults />
                <div className='col-sm-12 mt-5'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                        <thead>
                            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th>Analyst</th>
                                <th>Active</th>
                                <th>In Active</th>
                                <th>Approved</th>
                                <th>Assessing</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody className="fw-bold text-gray-600">
                            <PrepareReport />
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

export default ApplicationsCountAgaintStatusAndAnalyst; 