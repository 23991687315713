 

import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

const OtherIncome = ({prefix , setFieldValue , frequencyList }) => {
    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Months</label>
                <Field name= { prefix+"duration"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"duration"} 
                            fieldValue = {field.value} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Gross Income(before tax)</label>
                <Field name= { prefix+"grossIncome"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"grossIncome"} 
                            fieldValue = {field.value} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Net Income (after tax)</label>
                <Field name= { prefix+"netIncome"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"netIncome"} 
                            fieldValue = {field.value} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Frequency</label>
                <Field name=  {prefix+"frequencey"}>
                    {({ field }) => (
                        <SelectField
                            fieldName = {prefix+"frequencey"}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={frequencyList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>  
            <div className="col-sm-3 col-md-4 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Other Income Type</label>
                <Field name= { prefix+"otherIncomeType"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"otherIncomeType"} 
                            fieldValue = {field.value} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>                                
        </>
    )
}

export default OtherIncome;