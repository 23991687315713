import {useRef, useLayoutEffect} from 'react';

const Modal = ({heading, children , modalVisibility }) => {

    const headerStyleRef = useRef(null);

    useLayoutEffect(() => {
        headerStyleRef.current.style.setProperty('border-bottom', '1px solid #eff2f5', 'important');
      }, []);
    

    return (
        <>
            <div className={  "show  modal fade  "} tabIndex="-1" style= {{ display: "block" }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-750px">
                    <div className="modal-content rounded">

                        <div className="modal-header pb-0 border-0" ref={headerStyleRef}  >
                            <div className="justify-content-start">
                                <h3>{heading}</h3>
                            </div>
                            <div className="justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" onClick = {  (e) =>{ modalVisibility(false) }}>
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body scroll-y px-10  pt-0 pb-2">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );

}

export default Modal;