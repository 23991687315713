import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken } from '../../hooks/useAuth';
import { CustomButton, RightDrawer } from '../HtmlComponents';
import CreateEmailTemplate from './CreateEmailTemplate';
import UpdateEmailTemplate from './UpdateEmailTemplate';
import SingleEmailTemplate from "./SingleEmailTemplate";

import { getEmailTemplates } from "../../api";

const ListEmailTemplates = () => {
    const [emailTemplates, setEmailTemplates] = useState([ ]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });



    let { id } = useParams();
    const token = useToken();

    useEffect(() => {
        loadEmailTemplates();
    }, []);

    const loadEmailTemplates = async () => {
        
        var response = await getEmailTemplates(token); //LOAD ALL RECORDS OF COMPLAINT TYPE
        console.log("response:::::::::::  ", response);
        if(response["error"] !== undefined && response["error"] == 0 
        && response["data"] !== undefined && response["data"]["emailTemplates"] !== undefined
          && Array.isArray(response["data"]["emailTemplates"]) && response["data"]["emailTemplates"].length > 0){
            setEmailTemplates(response["data"]["emailTemplates"]);
        }
    }


    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, payload) => {
        switch (action) {
            case "updateListingOnEmailTemplateCreation": 
                loadEmailTemplates();
                drawHandler("closeDrawer");
                break;
            case "updateListingOnEmailTemplateUpdation":
                loadEmailTemplates();
                drawHandler("closeDrawer");
                break;

        }
    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer": 
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateEmailTemplateDrawer":
                setDrawerSettings({ visible: true, heading: "Create Email Template", childName: "createEmailTemplate", meta: { width: "800" } });
                break;
            case "openUpdateEmailTemplateDrawer": 
                var emailTemplateId = meta["emailTemplateId"] || 0;
                if (emailTemplateId !== 0) {
                    setDrawerSettings({ visible: true, heading: "Update Email Template", childName: "updateEmailTemplate", meta: { width: "800", emailTemplateId: emailTemplateId || 0 } });
                }

                break;

        }
    }

    const LoadView = () => {
        return(
            <>
                <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                    <div className='cols-m-12'>
                        <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { drawHandler("openCreateEmailTemplateDrawer") }} buttonText="Create" />
                    </div>
                </div>


                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>Title</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Subject</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }}>Recipient Type</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold text-gray-600">
                                        {
                                            Array.isArray(emailTemplates) ?
                                                emailTemplates.map(singleEmailTemplate => {
                                                    return (
                                                        <>
                                                            <SingleEmailTemplate emailTemplate={singleEmailTemplate} callback={drawHandler} />
                                                        </>
                                                    )
                                                })
                                                : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    return (
        <>


             <LoadView />


            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "createEmailTemplate" ? <CreateEmailTemplate callback={payloadUpdateHandler} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "updateEmailTemplate" ? <UpdateEmailTemplate emailTemplateId={drawerSettings["meta"]["emailTemplateId"]}  callback={payloadUpdateHandler} /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default ListEmailTemplates;