import axios from './axios';

  

/* SEARCH CAR INFO*/
const searchCarInfo = async (token,  payload) => {
  try{
    const response = await axios.post(`iAppraise/searchCarInfo/`, payload , { headers: { Authorization: "Bearer " + token } });
    return  { "error": response.data["error"] !== undefined && response.data["error"]  === 1 ? 1 : 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response.data !== undefined &&  response.data !== undefined ? response.data: {}}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }
}

/* CAR LIVE PRICING */
const liveCarPricing = async (token,  payload) => {
  try{
    const response = await axios.post(`iAppraise/liveCarPricing/`, payload , { headers: { Authorization: "Bearer " + token } });
    return  { "error": response.data["error"] !== undefined && response.data["error"]  === 1 ? 1 : 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response.data !== undefined &&  response.data !== undefined ? response.data: {}}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }
}



/* DOWNLOAD PPSR PDF */
const downloadPpsrPdf = async (token,  payload) => {
  try{
    const response = await axios.post(`iAppraise/downlaodPpsrPdf/`, payload , { headers: { Authorization: "Bearer " + token } });
    return  { "error": response.data["error"] !== undefined && response.data["error"]  === 1 ? 1 : 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response.data  }
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}


export { searchCarInfo , downloadPpsrPdf, liveCarPricing };