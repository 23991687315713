//getBranchIncomeReporting

import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getBranchIncomeReporting } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";

const BranchIncomeReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [report, setReport] = useState({});
    const token = useToken();

    const fetchReport = async() => {
        setProcessing(true);
        var response = await getBranchIncomeReporting(token, startDate, endDate);

        console.log("response::: ", response);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined ) {
            setReport(response["data"]);
        }
        setProcessing(false);
    }

    useEffect(() => {
        fetchReport();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                <div className="bold mb-4">Branch Income</div>
                    {processing ? <LoadingBlock /> :
                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3  text-start">Income </th>
                                        <th className="p-0 pb-3  text-start">Amount </th>
                                        <th className="p-0 pb-3  text-start">%</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                        <tr>
                                            <td>Origination Fee</td>
                                            <td>$ { report["originationFee"] !== undefined ? report["originationFee"] : 0 }</td>
                                            <td>{ report["originationFeePercentage"] !== undefined ? report["originationFeePercentage"] : 0 } %</td>
                                        </tr>
                                        <tr>
                                            <td>Finance Commission</td>
                                            <td>$ { report["financeCommission"] !== undefined ? report["financeCommission"] : 0 }</td>
                                            <td>{ report["financeCommissionPercentage"] !== undefined ? report["financeCommissionPercentage"] : 0 } %</td>
                                        </tr>
                                        <tr>
                                            <td>Insurance Income</td>
                                            <td>$ { report["insuranceCommission"] !== undefined ? report["insuranceCommission"] : 0 }</td>
                                            <td>{ report["insuranceCommissionPercentage"] !== undefined ? report["insuranceCommissionPercentage"] : 0 } %</td>                                            
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>$ { report["totalCommission"] !== undefined ? report["totalCommission"] : 0 }</td>
                                            <td>100 %</td>                                            
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default BranchIncomeReport;