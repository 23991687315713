import axios from '../api/axios';

const getDirector = async(token, directorId) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.get(`director/${directorId}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", director: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

const createDirector = async(token , director) => {
    try{
        console.log("TOKEN: " + token) ;
        console.log(director);
        const response = await axios.post(`director`,  director , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", director: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
         }
    }
}


const updateDirector = async(token, directorId, director) => {
    try{
        console.log("TOKEN: " + token) ;
        console.log(director);
        const response = await axios.put(`director/${directorId}`,  director , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", director: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

export {getDirector, createDirector, updateDirector};