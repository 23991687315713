import InputMask from 'react-input-mask';

const MaskedField = ({ fieldName, fieldValue, fieldPlaceholder, maskRule, setFieldValue }) => {

    const maskRules = { "date": "99/99/9999" , "floatNumber" : /([1-99999])/ }
    var mask = maskRules[maskRule]

    //CHECK IF VALUE FOLLOW THE MASK RULE OTHERWISE SET EMPTY VALUE

    return (<>
        <InputMask
            className="form-control"
            style={{ height: "35px", fontWeight: "400" }}
            value={fieldValue}
            mask={mask}
            maskPlaceholder={null}
            placeholder =  {fieldPlaceholder}
            onChange={(e) => {
                const value = e.target.value || '';
                setFieldValue(fieldName, value);
            }
            }
        />
    </>)    
}

export default MaskedField;