import axios from '../api/axios';

const getContact = async(token, contactId) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.get(`contact/${contactId}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", contact: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

const createContact = async(token , contact) => {
    try{
        console.log("TOKEN: " + token) ;
        console.log(contact);
        const response = await axios.post(`contact`,  contact , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", contact: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , httpCode: e.response.status, "message" : e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
         }
    }
}


const updateContact = async(token, contactId, contact) => {
    try{
        console.log("TOKEN: " + token) ;
        console.log("UPDATE CONTACT API METHOD INSIDE BODY::::", contact);
        const response = await axios.put(`contact/${contactId}`,  contact , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", contact: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}


const deleteContact = async(token, contactId) => {
    try{
        const response = await axios.delete(`contact/${contactId}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}


export {getContact, createContact, updateContact, deleteContact};