

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../../HtmlComponents';

import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';

import CreateComplaint from './CreateComplaint';
import UpdateComplaint from './UpdateComplaint';
import SingleComplaint from "./SingleComplaint";

import { createRegister, getRegister, getSingleRegister } from "../../../api";

const ListComplaints = () => {
    const [complaints, setComplaints] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });

    const token = useToken();

    useEffect(() => {
        loadComplaints();
    }, []);

    const loadComplaints = async () => {
        var response = await getRegister(token, "complaint"); //LOAD ALL RECORDS OF COMPLAINT TYPE
        console.log("response:::: ", response);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            setComplaints(response["data"]);
        }
    }


    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, payload) => {
        switch (action) {
            case "updatePayloadOnComplaingCreation":
                console.log("payload on addition ::::   ", payload)
                setComplaints([...complaints, payload]);
                drawHandler("closeDrawer");
                break;
            case "updatePayloadOnComplainUpdation":
                console.log("payload::::::::::  ", payload);
                let recordId = payload["id"] !== undefined ? payload["id"] : 0;
                let date = payload["date"] !== undefined ? payload["date"] : 0;
                let localPayload = payload["payload"] !== undefined ? payload["payload"] : {};
                console.log("localPayload::::::::::  ", localPayload);

                if (recordId !== 0) {
                    setComplaints(
                        complaints.map(singleRecord => singleRecord.id == recordId ? { ...singleRecord, date: date, payload: JSON.stringify(localPayload)  } : singleRecord)
                    )
                }
                drawHandler("closeDrawer");
                break;

        }
    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateComplaintDrawer":
                setDrawerSettings({ visible: true, heading: "Create Complaint", childName: "createComplaint", meta: { width: "800" } });
                break;
            case "openUpdateComplaintDrawer":
                console.log("meta ::: ", meta);
                var complaintId = meta["complaintId"] || 0;
                let singleComplaint = complaints.find(singleComplaint => singleComplaint.id === complaintId);
                let tmpSingleComplaint = JSON.parse(JSON.stringify(singleComplaint));
                if (tmpSingleComplaint !== undefined) {
                    tmpSingleComplaint["date"] = tmpSingleComplaint["date"] !== undefined && tmpSingleComplaint["date"] > 0 ? formatDate(tmpSingleComplaint["date"], "unix", "DD/MM/YYYY") : "";
                    tmpSingleComplaint["payload"] = JSON.parse(tmpSingleComplaint["payload"]);
                    setDrawerSettings({ visible: true, heading: "Update Complaint", childName: "updateComplaint", meta: { width: "800", complaintId: meta["complaintId"] || 0, singleComplaint: tmpSingleComplaint } });
                }

                break;

        }
    }

    const LoadView = () => {
        return(
            <>
                <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                    <div className='cols-m-12'>
                        <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { drawHandler("openCreateComplaintDrawer") }} buttonText="Create" />
                    </div>
                </div>
                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>Date</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Client/Applicant Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }}>Analyst Involved</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold text-gray-600">
                                        {
                                            Array.isArray(complaints) ?
                                                complaints.map(singleComplaint => {
                                                    return (
                                                        <>
                                                            <SingleComplaint complaint={singleComplaint} callback={drawHandler} />
                                                        </>
                                                    )
                                                })
                                                : <tr></tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    return (
        <>


             <LoadView />


            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "createComplaint" ? <CreateComplaint callback={payloadUpdateHandler} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "updateComplaint" ? <UpdateComplaint complaintId={drawerSettings["meta"]["complaintId"]} complaint={drawerSettings["meta"]["singleComplaint"]} callback={payloadUpdateHandler} /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default ListComplaints;