/*
APPLICATION CREATED BY REFERRER - THIS ASKS BASIC DETAILS ONLY SO 
ANALYST CAN LOOK INTO THIS AND CALL CUSTOMER TO GRAP FURTHER INFORMATION 
*/

import Constants from "../../global/Constants";
import QuickReferralValidationSchema from "../schema/QuickReferralValidationSchema";
import QuickReferralSchema from "../schema/QuickReferralSchema.json";
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../../hooks/useAuth';
import { capitalizeFirst, formatDate } from '../../../helpers';
import { SearchLocationInput } from '../../../api/googleApi';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, SelectField, InputField, TextArea } from '../../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { createConsumerApplication, createApplicant } from "../../../api";
import Notifications from '../../global/Notifications';

const QuickReferral = ({callback}) => {

    const [referral, setReferral] = useState({});
    const [loanPurposes, setLoanPurposes] = useState({});
    const [titleList, setTitleList] = useState([]);
    const [contactHours, setContactHours] = useState([
        { value: "Anytime", label: "Anytime" }, { value: "9 AM", label: "9 AM" }, { value: "10 AM", label: "10 AM" }, { value: "11 AM", label: "11 AM" },
        { value: "12 AM", label: "12 AM" }, { value: "01 PM", label: "01 PM" }, { value: "02 PM", label: "02 PM" }, { value: "03 PM", label: "03 PM" }, { value: "04 PM", label: "04 PM" },
        { value: "05 PM", label: "05 PM" }
    ]);

    var token = useToken();

    const loadLoanPurposes = () => {
        var loanPurposeOptions = [];
        Object.keys(Constants.LOAN_PURPOSES).map(function (key, index) {
            loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] })
        })
        setLoanPurposes(loanPurposeOptions);
    }

    const fetchTitleList = () => {
        var titleOptions = [];
        var titleOptions = Constants.TITLE.map(item => { return { value: item, label: capitalizeFirst(item) }; })
        setTitleList(titleOptions);
    }

    useEffect(() => {
        loadLoanPurposes();
        fetchTitleList();
    }, [])

    const QuickreferralHtml = (errors, touched, handleChange, setFieldValue) => {
        return (
            <>
                <div className="row mb-5">
                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Title</label>
                        <Field name="applicants[0][personalDetail].title">
                            {({ field }) => (
                                <SelectField
                                    fieldName="applicants[0][personalDetail].title"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={titleList}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>      
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.title !== undefined ? <div className="error">{errors.applicants[0].personalDetail.title}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">First Name</label>
                        <Field name="applicants[0][personalDetail].firstName">
                            {({ field }) => (
                                <InputField fieldName="applicants[0][personalDetail].firstName" placeholder="First Name" fieldValue={field.value} setFieldValue={setFieldValue}  capitalizeFirstLetter= {true}/>
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.firstName !== undefined ? <div className="error">{errors.applicants[0].personalDetail.firstName}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="fs-8 fw-bold mb-2">Middle Name</label>
                        <Field name="applicants[0][personalDetail].middleName">
                            {({ field }) => (
                                <InputField fieldName="applicants[0][personalDetail].middleName" placeholder="Middle Name" fieldValue={field.value} setFieldValue={setFieldValue}  capitalizeFirstLetter= {true} />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.middleName !== undefined ? <div className="error">{errors.applicants[0].personalDetail.middleName}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Last Name</label>
                        <Field name="applicants[0][personalDetail].surname">
                            {({ field }) => (
                                <InputField fieldName="applicants[0][personalDetail].surname" placeholder="Last Name" fieldValue={field.value} setFieldValue={setFieldValue}  capitalizeFirstLetter= {true} />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.surname !== undefined ? <div className="error">{errors.applicants[0].personalDetail.surname}</div>: "" }                 
                    </div>

                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                        <label className="fs-8 fw-bold mb-2">Current Residential Address</label>
                        <SearchLocationInput setAddressModule={(value) => { setFieldValue("applicants[0][personalDetail][residentialHistory][current].address", value) }} />
                    </div>


                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Preferred Email</label>
                        <Field name="applicants[0][personalDetail].email">
                            {({ field }) => (
                                <InputField
                                    fieldName="applicants[0][personalDetail].email"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.email !== undefined ? <div className="error">{errors.applicants[0].personalDetail.email}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Mobile #</label>
                        <Field name="applicants[0][personalDetail].mobileNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="applicants[0][personalDetail].mobileNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.mobileNumber !== undefined ? <div className="error">{errors.applicants[0].personalDetail.mobileNumber}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Home #</label>
                        <Field name="applicants[0][personalDetail].homeNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="applicants[0][personalDetail].homeNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.homeNumber !== undefined ? <div className="error">{errors.applicants[0].personalDetail.homeNumber}</div>: "" }                 
                    </div>
                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Employer #</label>
                        <Field name="applicants[0][personalDetail].employerNumber">
                            {({ field }) => (
                                <InputField
                                    fieldName="applicants[0][personalDetail].employerNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined && errors.applicants !== undefined && errors.applicants[0] !== undefined && errors.applicants[0].personalDetail !== undefined && errors.applicants[0].personalDetail.employerNumber !== undefined ? <div className="error">{errors.applicants[0].personalDetail.employerNumber}</div>: "" }                 
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Preferred Contact Method</label>
                        <Field name="extra.preferredContactMethod">
                            {({ field }) => (

                                <SelectField
                                    fieldName="extra.preferredContactMethod"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={[{ value: "Phone", label: "Phone" }, { value: "SMS", label: "SMS" }, { value: "Email", label: "Email" }]}
                                    setFieldValue={setFieldValue}
                                />

                            )}
                        </Field>
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Preferred Contact Time From</label>
                        <Field name="extra.preferredContactTimeFrom">
                            {({ field }) => (

                                <SelectField
                                    fieldName="extra.preferredContactTimeFrom"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={contactHours}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div>
                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Preferred Contact Time To</label>
                        <Field name="extra.preferredContactTimeTo">
                            {({ field }) => (

                                <SelectField
                                    fieldName="extra.preferredContactTimeTo"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={contactHours}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div>

                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Purpose</label>
                        <Field name="loan.purpose">
                            {({ field }) => (
                                <SelectField
                                    fieldName="loan.purpose"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={loanPurposes}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined && errors.loan !== undefined && errors.loan.purpose !== undefined  ? <div className="error">{errors.loan.purpose}</div>: "" }                 
                    </div>
                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Amount</label>
                        <Field name="loan.totalFinancedAmountIncludingInsurances">
                            {({ field }) => (
                                <InputField
                                    fieldName="loan.totalFinancedAmountIncludingInsurances"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div>

                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                        <label className="  fs-8 fw-bold mb-2">Helpful Notes</label>
                        <Field name="applicationNotes.referrerNotes">
                            {({ field }) => (
                                <TextArea
                                    fieldName="applicationNotes.referrerNotes"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div>


                </div>

            </>
        )
    }
    return (
        <>
            <Formik
                initialValues={QuickReferralSchema}
                validationSchema={QuickReferralValidationSchema}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    let valuesClone = JSON.parse(JSON.stringify(values));
                    var selectedReferrerId = !isNaN(parseInt(localStorage.getItem("selectedReferrer"))) ? parseInt(localStorage.getItem("selectedReferrer")): 0 ;

                    valuesClone["applicationNotes"]["referrerNotes"] = valuesClone["applicationNotes"]["referrerNotes"] + "  -   Preferred Contact Method : " + valuesClone["extra"]["preferredContactMethod"] + " -   Preferred Contact Time From: " + valuesClone["extra"]["preferredContactTimeFrom"] + " <br>  Preferred Contact Time To : " + valuesClone["extra"]["preferredContactTimeTo"]
                    valuesClone["referrerId"]  = selectedReferrerId;
                    valuesClone["referrerStaffId"]  = "";
                    valuesClone["financial"]["assetPrice"] = valuesClone["loan"]["totalFinancedAmountIncludingInsurances"];

                    //CREATE APPLICATION 
                    try {
                        var result = await createConsumerApplication(valuesClone, token, `?referrerId=${selectedReferrerId}`);
                        //console.log("RESULT::::::::  ", result);
                        if (result["error"]) {
                            Notifications("error", result["message"] || "Error occured")
                        }
                        else {
                            //CREATE APPLICANT
                            var applicationId = result["application"]["id"];

                            var applicantResult = await createApplicant(token, `${applicationId}?referrerId=${selectedReferrerId}`, valuesClone["applicants"][0]);
                            //console.log("applicant result:::: ", applicantResult);
                            if (applicantResult["error"]) { Notifications("error", applicantResult["message"]);  }
                            else {
                                Notifications("success", "Submitted Successfully");
                                callback("uploadDocumentsPopUp", applicationId)
                            }
                        }
                    }
                    catch (e) {
                        Notifications("error", e);
                    }





                    //UPON SUCCESS RELOAD THE PAGE

                    //UPON ERROR SHOW MESSAGE
                }}
            >
                {({ errors, touched, handleChange, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="col-xl-12">
                                <pre>
                                    { /*JSON.stringify(singleCreditRep , undefined, 4) */}
                                </pre>
                            </div>
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    {QuickreferralHtml(errors, touched, handleChange, setFieldValue)}
                                </div>
                                <div className="col-sm-12">
                                    <p> Submission acknowledges your referral has given consent for their contact details to be passed on for the purpose for which finance may be required to NFAL</p>
                                </div>
                               {/*} <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div> */}
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default QuickReferral;