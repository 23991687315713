import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton, RightDrawer, TextArea } from '../../../HtmlComponents';
import { formatDate, getCurrentDate } from '../../../../helpers';
import Notifications from "../../../global/Notifications";
import SelectFieldGroup from '../../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getLendersList, getLender, getComments, getFolders, getFiles, } from "../../../../api";
import { useToken } from '../../../../hooks/useAuth';
import LoadingScreen from '../../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../../schema/SubmitToLenderValidationSchema';


const Plenti = ({ applicationId }) => {
    const [isQuoteSelected, setIsQuoteSelected] = useState(true);

    const [initialValues, setInitialValues] = useState({ lender: "plenti", productType: "", frequency: "", repaymentAmount: "", paymentMethod: "" });
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");

    const [quotes, setQuotes] = useState( []);


    const token = useToken();
    const formRef = useRef();

    const plentiOptions = {
        financeType: [{ value: "Purchase", label: "Purchase" }, { value: "Refinance", label: "Refinance" }],
        saleType: [{ value: "Private", label: "Private" }, { value: "Dealer", label: "Dealer" }],
        privacyAgreement: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        creditReportingAgreement: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        creditGuideConfirmation: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        balloonPaymentConsentConfirmation: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
        customerReason: [{ value: "", label: "Select" }, { value: "LowerMonthlyPayment", label: "Lower Monthly Payment" }, { value: "PlannedUpgradeOfVehicle", label: "Planned Upgrade Of Vehicle" }, { value: "Other", label: "Other" }],
        customerPaidMethodWhenLoanDue: [{ value: "", label: "Select" }, { value: "TradeIn", label: "TradeIn" }, { value: "Cash", label: "Cash" }, { value: "Refinance", label: "Refinance" }],
        securedLoan: [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }],
    }


    const handler = (name, value) => {
        switch (name) {

        }
    }

    const PlentiApplicationFields = ({ errors, setFieldValue }) => {
        return (
            <>
                <div className="col-sm-12"><p><b>Does not accept draft version</b></p></div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Finance Type</label>
                    <Field name="financeType">
                        {({ field }) => (
                            <SelectField
                                fieldName="financeType"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["financeType"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                    {errors.productType !== undefined ? <div className="error"> {errors.salesType} </div> : ""}
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Product Type</label>
                    <Field name="saleType">
                        {({ field }) => (
                            <SelectField
                                fieldName="saleType"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["saleType"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                    {errors.productType !== undefined ? <div className="error"> {errors.salesType} </div> : ""}
                </div>

                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Privacy Agreement</label>
                    <Field name="privacyAgreement">
                        {({ field }) => (
                            <SelectField
                                fieldName="privacyAgreement"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["privacyAgreement"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Credit Reporting Agreement</label>
                    <Field name="creditReportingAgreement">
                        {({ field }) => (
                            <SelectField
                                fieldName="creditReportingAgreement"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["creditReportingAgreement"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Credit Guide Confirmation</label>
                    <Field name="creditGuideConfirmation">
                        {({ field }) => (
                            <SelectField
                                fieldName="creditGuideConfirmation"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["creditGuideConfirmation"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Balloon Payment Consent Confirmation</label>
                    <Field name="balloonPaymentConsentConfirmation">
                        {({ field }) => (
                            <SelectField
                                fieldName="balloonPaymentConsentConfirmation"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["balloonPaymentConsentConfirmation"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>


                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Customer Reason (required only if ballon is enabled)</label>
                    <Field name="customerReason">
                        {({ field }) => (
                            <SelectField
                                fieldName="customerReason"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["customerReason"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Customer Reason Explanation (required only if ballon is enabled)</label>
                    <Field name="customerReasonExplanation">
                        {({ field }) => (
                            <TextArea
                                fieldName="customerReasonExplanation"
                                fieldValue={field.value}
                                placeholder={""}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Customer Paid Method When Loan Due (required only if ballon is enabled)</label>
                    <Field name="customerPaidMethodWhenLoanDue">
                        {({ field }) => (
                            <SelectField
                                fieldName="customerPaidMethodWhenLoanDue"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["customerPaidMethodWhenLoanDue"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Additional Comments</label>
                    <Field name="additionalComments">
                        {({ field }) => (
                            <TextArea
                                fieldName="additionalComments"
                                fieldValue={field.value}
                                placeholder={""}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>




            </>
        )

    }

    const PlentiQuoteFields = ({ errors, setFieldValue }) => {
        return (
            <>
            <h4 className="error mb-3">Kindly be informed that Plenti Quote exclusively considers primary income and previous income. Any secondary income of the applicant will not be forwarded to Plenti.</h4>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Rate Discount (0 - 200)</label>
                    <Field name="rateDiscount">
                        {({ field }) => (
                            <InputField
                                fieldName="rateDiscount"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Applicant Credit Score </label>
                    <Field name="creditScore">
                        {({ field }) => (
                            <InputField
                                fieldName="creditScore"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Secured Loan</label>
                    <Field name="securedLoan">
                        {({ field }) => (
                            <SelectField
                                fieldName="securedLoan"
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={plentiOptions["securedLoan"]}
                                setFieldValue={setFieldValue}
                                callback={handler}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Vehicle Addon </label>
                    <Field name="vehicleAddon">
                        {({ field }) => (
                            <InputField
                                fieldName="vehicleAddon"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Vehicle Comprehensive Insurance </label>
                    <Field name="vehicleComprehensiveInsurance">
                        {({ field }) => (
                            <InputField
                                fieldName="vehicleComprehensiveInsurance"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

            </>
        )
    }


    //PRINT RETRIEVED QUOTES
    const PrintQuotes = () => {
        if (Array.isArray(quotes) && quotes.length > 0) {
            return quotes.map((quote, index) => {
                return (<>
                    <h4>{quote["name"]}</h4>
                    <table className="table table-bordered" style={{ border: "1px solid black" }}>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Description</td>
                            <td>{quote["description"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Term</td>
                            <td>{quote["term"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Loan Amount</td>
                            <td>{quote["loanAmount"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Annual Rate</td>
                            <td>{quote["annualRate"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Financier Rate</td>
                            <td>{quote["financierRate"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Rate Discount</td>
                            <td>{quote["rateDiscount"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Rate Type</td>
                            <td>{quote["rateType"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Repayment</td>
                            <td>{quote["repayment"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Repayment Frequency</td>
                            <td>{quote["repaymentFrequency"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Total Repaid</td>
                            <td>{quote["totalRepaid"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Effective Rate</td>
                            <td>{quote["effectiveRate"]}</td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td>Asic Comparison Rate</td>
                            <td>{quote["asicComparisonRate"]}</td>
                        </tr>

                        <tr style={{ border: "1px solid black" }}>
                            <td colSpan={2}>
                                <h5>Upfront Fees</h5>
                                <div className="row">
                                    {
                                        Array.isArray(quote["upfrontFees"]) && quote["upfrontFees"].length > 0 ? quote["upfrontFees"].map((record, index) => {
                                            return (
                                                <div className="col-sm-6 col-md-6" style={{ borderLeft: "1px solid black" }}>
                                                    <p>{record["name"]}</p>
                                                    <p>Fee Type: {record["feeType"]}</p>
                                                    <p>Amount: $ {record["amount"]}</p>
                                                    <p>Fee Payer: {record["feePayer"]}</p>
                                                    <p>Fee Recipient: {record["feeRecipient"]}</p>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid black" }}>
                            <td colSpan={2}>
                                <h5>Repayment Fees</h5>
                                <div className="row">
                                    {
                                        Array.isArray(quote["repaymentFees"]) && quote["repaymentFees"].length > 0 ? quote["repaymentFees"].map((record, index) => {
                                            return (
                                                <div className="col-sm-6 col-md-6" style={{ borderLeft:  "1px solid black" }}>
                                                     <p>Name: {record["name"]}</p>
                                                    <p>Amount: $ {record["amount"]}</p>
                                                    <p>Fee Type: {record["feeType"]}</p>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                            </td>
                        </tr>


                    </table>
                </>)
            })
        }
        else {
            return "";
        }
    }

    return (
        <>
            {<Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={SubmitToLenderValidationSchema}
                innerRef={formRef}
                validateOnBlur={false}

                onSubmit={async (values) => {
                    //MAKE AN API CALL 
                    setProcessing(true);
                    let valuesColne = JSON.parse(JSON.stringify(values));
                    console.log("valuesColne", valuesColne)



                    var response = await performApplicationAdditionalActions(token, "submitToLender", applicationId, valuesColne);
                    console.log("   response    LINE 638 ---  ------------------  ", response);
                    if (response["error"]) {
                        var errors = response["message"]["errors"];
                        var warning = response["message"]["warning"];

                        try { setMessage(errors.join("\n")); }
                        catch (e) { setMessage(JSON.stringify(response["message"])); }

                    }
                    else {
                        var responseResult = response.data !== undefined ? response.data : {};
                        if (valuesColne.service === "quote") {
                            var message = responseResult.message !== undefined ? responseResult.message : "Quote created Successully";
                            Notifications("success", message)

                            //FETCH QUOTE AND LIST DOWN
                            let newQuotes = responseResult.quotes !== undefined ? responseResult.quotes : [];
                            console.log("quote", newQuotes)
                            console.log("responseResult", responseResult)

                            setQuotes(newQuotes);

                        }
                        else {
                            var message = responseResult.message !== undefined ? responseResult.message : "";
                            var payload = responseResult.payload !== undefined ? responseResult.payload : {};
                            var applicationNo = payload.application !== undefined && payload.application.applicationNo !== undefined ? " #: " + payload.application.applicationNo : "";
                            Notifications("success", message)
                        }
                        setMessage("");
                    }
                    setProcessing(false);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div className="row">

                            <div className="col-sm-12 error mb-5" style={{ whiteSpace: "pre-wrap" }}> {message} </div>

                            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Select Service</label>
                                <Field name="service">
                                    {({ field }) => (
                                        <SelectField
                                            fieldName="service"
                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                            options={[{ value: "quote", label: "Get Quote" }, { value: "submitApplication", label: "Submit Application" }]}
                                            setFieldValue={setFieldValue}
                                            callback={handler}
                                        />
                                    )}
                                </Field>
                                {errors.service !== undefined ? <div className="error"> {errors.service} </div> : ""}
                            </div>
                            {values.service === "quote" ? <PlentiQuoteFields errors={errors} setFieldValue={setFieldValue} /> : ""}
                            {values.service === "submitApplication" ? <PlentiApplicationFields errors={errors} setFieldValue={setFieldValue} /> : ""}
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="col-xl-12">
                            {<FormSubmissionButton isActive={true} label={values.service === "quote" ? "Get Quote" : "Submit To Lender"} />}
                        </div>
                    </Form>

                )}

            </Formik>
            }

            {
                processing ? <LoadingScreen /> : ""
            }
            <PrintQuotes />
        </>
    )


}

export default Plenti;