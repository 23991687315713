import React, { useEffect, useState } from 'react';
import { SearchLocationInput } from '../../api/googleApi';
import { CustomButton, CheckBox } from '../HtmlComponents';

const AddressForm = (props) => {
    const [autocomplete, setAutocomplete] = useState({ "fullAddress": "", "unitNumber": "", "streetNumber": "", "streetTypeShortName": "", "streetTypeLongName": "", "streetName": "", "state": "", "postCode": "", "suburb": "" });
    const [address, setAddress] = useState({ "fullAddress": "", "unitNumber": "", "streetNumber": "", "streetTypeShortName": "", "streetTypeLongName": "", "streetName": "", "state": "", "postCode": "", "suburb": "" });
    const [addressNotFound, setAddressNotFound] = useState(false);

    useEffect(() => {
        //CHECKING IF OBJECT HAS THE KEYS AND NOT BLANK ON FIRST RENDER
        if (autocomplete.hasOwnProperty("fullAddress")) { setAddress(autocomplete) }
    }, [autocomplete])

    const handleChange = e => {
        setAddress({ ...address, [e.target.name]: e.target.value })  //UPDATE THE PROPERTY OF LOCAL ADDRESS STATE          
    };

    const assignAndCloseModal = () => {
        props.addressHandler(props.fieldName, address);         //UPDATE THE ADDRESS STATE OF CALLER MODULE, addressHandler IS A PROP THAT IS PASSED FROM PARENT/CALLER
        props.closeButtonHandler()                              //CLOSE THE MODAL
    }

    //IF ENTERING ADDRESS MANUALLY THEN UPDATE THE FULL ADDRESS WHEN USER ENTERS THE OTHER COMPONENT VALUES
    const updateManuallyEnteredFullAddress = () => {        
        if (addressNotFound) {
            var streetType = address["streetTypeLongName"] !== undefined && address["streetTypeLongName"].length > 0 ? address["streetTypeLongName"] : address["streetTypeShortName"];
            setAddress({
                ...address,
                fullAddress: address["unitNumber"] + " " + address["streetNumber"] + " " + address["streetName"] + " " + streetType + " " + address["suburb"] + " " + address["state"] + " " + address["postCode"]
            })
        }
    }
    useEffect( () => {
        updateManuallyEnteredFullAddress();
    }, [address])

    return (
        <>
            <div>
                <div id="kt_content_container" className="container-xxl">
                    <div className="row g-5 g-xl-8">

                        <div className="card card-xl-stretch mb-xl-8">
                            <div className="card-body p-0">

                                <div className="d-flex flex-column mb-4 mt-8 fv-row fv-plugins-icon-container ">
                                    <label className="d-flex align-items-center fs-7 mb-2">
                                        <span className="">Please type and select from the dropdown</span>
                                    </label>
                                    {
                                        addressNotFound ? <>
                                            <input type="text"
                                                className="form-control form-control-solid"
                                                name="fullAddress"
                                                value={address.fullAddress}
                                                onChange={ () => {}}
                                                disabled= {true}
                                                 /> 
                                        </> : <SearchLocationInput setAddressModule={setAutocomplete} />
                                    }
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>

                                <div className="col-sm-12">
                                    <div className='d-flex flex-column mb-2'>
                                        <div className='d-flex'>
                                            <CheckBox name="addressNotFound" defaultChecked={addressNotFound} setFieldValue={(name, value) => { setAddressNotFound(value); }} />
                                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Address Not Found</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row g-9 mb-8">
                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span>Unit #</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="unitNumber"
                                            value={address.unitNumber}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Street #</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="streetNumber"
                                            value={address.streetNumber}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Street Type (Short Name)</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="streetTypeShortName"
                                            value={address.streetTypeShortName}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>

                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Street Type (Long Name)</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="streetTypeLongName"
                                            value={address.streetTypeLongName}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>

                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Street Name</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="streetName"
                                            value={address.streetName}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>

                                    </div>

                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Post Code</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="postCode"
                                            value={address.postCode}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>

                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">State</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="state"
                                            value={address.state}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>

                                    <div className="col-md-4 fv-row fv-plugins-icon-container">
                                        <label className="d-flex align-items-center fs-7 mb-2">
                                            <span className="">Suburb</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-solid"
                                            name="suburb"
                                            value={address.suburb}
                                            onChange={handleChange} />
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>


                                </div>

                                <div className="row g-9 mb-8">
                                    <div className="col-md-12 fv-row fv-plugins-icon-container">
                                        <CustomButton buttonClassName="btn-sm btn-danger " buttonStyle={{ float: "right" }} buttonText="Close" buttonOnClick={props.closeButtonHandler} isActive={true} />
                                        <CustomButton buttonClassName="btn-sm btn-primary " buttonStyle={{ float: "right", marginRight: "20px" }} buttonText="Save" buttonOnClick={assignAndCloseModal} isActive={true} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
}
export default AddressForm;