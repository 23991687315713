import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../../HtmlComponents';

import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';

import CreateConflictOfInterests from './CreateConflictOfInterests';
import UpdateConflictOfInterests from './UpdateConflictOfInterests';
import SingleConflictOfInterests from "./SingleConflictOfInterests";

import { createRegister, getRegister, getSingleRegister } from "../../../api";

const ListConflictOfInterests = () => {
    const [conflictOfInterests, setConflictOfInterests] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });

    const token = useToken();

    useEffect(() => {
        loadComplaints();
    }, []);

    const loadComplaints = async () => {
        var response = await getRegister(token, "conflictOfInterest"); //LOAD ALL RECORDS OF COMPLAINT TYPE
        console.log("response:::: ", response);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            setConflictOfInterests(response["data"]);
        }
    }


    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, payload) => {
        switch (action) {
            case "updatePayloadOnCreation":
                console.log("payload on addition ::::   ", payload)
                setConflictOfInterests([...conflictOfInterests, payload]);
                drawHandler("closeDrawer");
                break;
            case "updatePayloadOnUpdation":
                console.log("payload::::::::::  ", payload);
                let recordId = payload["id"] !== undefined ? payload["id"] : 0;
                let date = payload["date"] !== undefined ? payload["date"] : 0;
                let localPayload = payload["payload"] !== undefined ? payload["payload"] : {};
                console.log("localPayload::::::::::  ", localPayload);

                if (recordId !== 0) {
                    setConflictOfInterests(
                        conflictOfInterests.map(singleRecord => singleRecord.id == recordId ? { ...singleRecord, date: date, payload: JSON.stringify(localPayload) } : singleRecord)
                    )
                }
                drawHandler("closeDrawer");
                break;

        }
    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateDrawer":
                setDrawerSettings({ visible: true, heading: "Create Conflict Of Interest", childName: "createConflictOfInterests", meta: { width: "800" } });
                break;
            case "openUpdateDrawer":
                console.log("meta ::: ", meta);
                var conflictOfInterestId = meta["conflictOfInterestId"] || 0;
                let singleConflictOfInterest = conflictOfInterests.find(singleConflictOfInterest => singleConflictOfInterest.id === conflictOfInterestId);
                let tmpSingleConflictOfInterest = JSON.parse(JSON.stringify(singleConflictOfInterest));
                if (tmpSingleConflictOfInterest !== undefined) {
                    tmpSingleConflictOfInterest["date"] = tmpSingleConflictOfInterest["date"] !== undefined && tmpSingleConflictOfInterest["date"] > 0 ? formatDate(tmpSingleConflictOfInterest["date"], "unix", "DD/MM/YYYY") : "";
                    tmpSingleConflictOfInterest["payload"] = JSON.parse(tmpSingleConflictOfInterest["payload"]);
                    setDrawerSettings({ visible: true, heading: "Update Conflict Of Interest", childName: "updateConflictOfInterests", meta: { width: "800", conflictOfInterestId: meta["conflictOfInterestId"] || 0, singleConflictOfInterest: tmpSingleConflictOfInterest } });
                }

                break;

        }
    }

    const LoadView = () => {
        return (
            <>
                <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                    <div className='row'>
                        <div className='cols-m-12'>
                            <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { drawHandler("openCreateDrawer") }} buttonText="Create" />
                        </div>
                    </div>
                    <div className="card" data-select2-id="select2-data-132-dpd0">
                        <div className="card-body pt-0">
                            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="table-responsive">
                                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                        <thead>
                                            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th>
                                                <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Description</th>
                                                <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Impact On Client</th>
                                                <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Remedy</th>
                                            </tr>
                                        </thead>
                                        <tbody className="fw-bold text-gray-600">
                                            {
                                                Array.isArray(conflictOfInterests) ?
                                                    conflictOfInterests.map(singleConflictOfInterest => {
                                                        return (
                                                            <>
                                                                <SingleConflictOfInterests singleConflictOfInterest={singleConflictOfInterest} callback={drawHandler} />
                                                            </>
                                                        )
                                                    })
                                                    : <tr></tr>

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>


            <LoadView />


            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "createConflictOfInterests" ? <CreateConflictOfInterests callback={payloadUpdateHandler} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "updateConflictOfInterests" ? <UpdateConflictOfInterests conflictOfInterestId={drawerSettings["meta"]["conflictOfInterestId"]} singleConflictOfInterest={drawerSettings["meta"]["singleConflictOfInterest"]} callback={payloadUpdateHandler} /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default ListConflictOfInterests;