/*
accessibleBy => if this property is used in admin and analyst then it will represent credit rep id
*/
   const MenuItems  = {
        "company" : {
            "superAdmin": {
                "dashboard": {
                    name: 'Dashboard', link: "/user/dashboard", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "creditRep": {
                    name: 'Credit Rep', link: "/user/listCreditReps", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "lenders" : {
                    name: 'Lenders', link: "/user/listLenders", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "Insurances" : {
                    name: 'Insurances', link: "/user/listInsurances", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : false
    
                },
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "" , active : false},
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active:true },
                        "myActiveReferrals": { name: "Active Referrals", link: "/user/listApplications/my", icon: "" , active : false},
                        "deletedReferrals": { name: "Delete Referrals", link: "/user/listApplications/deleted", icon: "" , active : true},
                    },
                    active: true
                },
                "referrers": {
                    name: 'Referrers', link: "/user/addReferrer", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "listReferrers": { name: 'List Referrers', link: "/user/listReferrers",icon: "" , active: true },                        
                        "archivedReferrers": { name: 'Archived Referrers', link: "/user/archivedReferrers",icon: "" , active: true },                        
                    },
                    active : true
                },
                "referrerGroups": {
                    name: 'Referrer Groups', link: "/user/listReferrerGroups", icon: (<><span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                        <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                    </svg>
                </span> </>),
                    hasSubMenu: false,
                    subMenuNavigation: {
                        "createReferrerGroup": { name: 'Add Referrer Group', link: "/user/createReferrerGroup", icon: "",active : true },
                        "listReferrerGroups": { name: 'List Referrer Groups', link: "/user/listReferrerGroups", icon: "", active : true },
                        "referrersGroupRcti": { name: 'Referrer Group RCTI', link: "", icon: "" },
                    },
                    active : true
                },
                "referrerGroupsManager": {
                    name: 'RG Managers', link: "/user/listReferrerGroupManagers", icon: (<><span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                        <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                    </svg>
                </span> </>),
                    hasSubMenu: false,
                    subMenuNavigation: {
                        "createReferrerGroupManager": { name: 'Add Referrer Group Manager', link: "/user/createReferrerGroupManager", icon: "",active : true },
                        "listReferrerGroupManagers": { name: 'List Referrer Group Managers', link: "/user/listReferrerGroupManagers", icon: "", active : true },
                    },
                    active : true
                },
                "insuranceCompanies" : {
                    name: "Insurers" , link : "/user/insurers" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    hasSubMenu: false,
                    active:true
                },
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
                "declined" : {
                    name: 'Declined', link: "/user/declined", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },                
                "utilities" : {
                    name: 'Utilities', link: "", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "creditRepRCTI": { name: "Credit Rep RCTI", link: "/user/creditRepRCTI", icon: "" , active : true},
                        "referrerGroupRCTI": { name: "Referrer Group RCTI", link: "/user/referrerGroupRCTI", icon: "" , active : true},
                        "referrerRCTI": { name: "Referrer RCTI", link: "/user/referrerRCTI", icon: "", active:true },
                        "referrerPaymentNotification": { name: "Referrer Payment Notification", link: "/user/referrerPaymentNotification", icon: "", active:true },
                        "giftCardRecipients": { name: "Gift Card Report", link: "/user/giftCardRecipients", icon: "" , active : true},
                        "referrerGroupCommissionSummary": { name: "Referrer Group Commission Summary", link: "/user/referrerGroupCommissionSummary", icon: "" , active : true},
                        "listOfficeContacts": { name: "List Office Contacts", link: "/user/listOfficeContacts", icon: "",active : true },
                        "complaintRegister": { name: "Complaint Register", link: "/user/complaintRegister", icon: "",active : true },
                        "conflictOfInterestRegister": { name: "Conflict Of Interest Register", link: "/user/conflictOfInterestRegister", icon: "",active : true },
                        "cpd": { name: "NCCP", link: "/user/cpd", icon: "",active : true },
                        "emailTemplates": { name: "Email Templates", link: "/user/emailTemplates", icon: "",active : true },
                    },
                    active: true   
                },
            },
            "finance":{
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "" , active : false},
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active:true },
                        "myActiveReferrals": { name: "Active Referrals", link: "/user/listApplications/my", icon: "" , active : false},
                        "deletedReferrals": { name: "Delete Referrals", link: "/user/listApplications/deleted", icon: "" , active : true},
                    },
                    active: true
                },
                "settlements" : {
                    name: 'Settlements', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "listSettlements": { name: "List Settlements", link: "/user/settlements", icon: "" , active : true},
                        "listReinstatedSettlements": { name: "Reinstated Settlements", link: "/user/listReinstatedSettlements", icon: "", active:true },                   
                    },
                    active : true      
                },
                "declined" : {
                    name: 'Declined', link: "/user/declined", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },      
                "referrers": {
                    name: 'Referrers', link: "/user/listReferrers", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: false,
                    subMenuNavigation: {
                        "referrers": { name: 'Referres', link: "", icon: "" },
                        "referrerGroup": { name: 'Referre Group', link: "", icon: "" },
                        "referrersGroupRcti": { name: 'Referre Group RCTI', link: "", icon: "" },
                    },
                    active : true,
                },
                "referrerGroups": {
                    name: 'Referrer Groups', link: "/user/listReferrerGroups", icon: (<><span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                        <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                    </svg>
                </span> </>),
                    hasSubMenu: false,
                    subMenuNavigation: {
                        "createReferrerGroup": { name: 'Add Referrer Group', link: "/user/createReferrerGroup", icon: "",active : true },
                        "listReferrerGroups": { name: 'List Referrer Groups', link: "/user/listReferrerGroups", icon: "", active : true },
                        "referrersGroupRcti": { name: 'Referrer Group RCTI', link: "", icon: "" },
                    },
                    active : true
                },
                "lenders" : {
                    name: 'Lenders', link: "/user/listLenders", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "insuranceCompanies" : {
                    name: "Insurers" , link : "/user/insurers" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    hasSubMenu: false,
                    active:true
                },
                "utilities" : {
                    name: 'Utilities', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "creditRepRCTI": { name: "Credit Rep RCTI", link: "/user/creditRepRCTI", icon: "" , active : true},
                        "referrerGroupRCTI": { name: "Referrer Group RCTI", link: "/user/referrerGroupRCTI", icon: "" , active : true},
                        "referrerRCTI": { name: "Referrer RCTI", link: "/user/referrerRCTI", icon: "", active:true },
                        "referrerPaymentNotification": { name: "Referrer Payment Notification", link: "/user/referrerPaymentNotification", icon: "", active:true },
                        "giftCardRecipients": { name: "Gift Card Report", link: "/user/giftCardRecipients", icon: "" , active : true},
                        "lendersXero": { name: "Lenders Xero", link: "/user/lendersXero", icon: "" , active : true},
                        "referrerXero": { name: "Referrer Xero", link: "/user/referrerXero", icon: "" , active : true},
                        "InsuranceInvoices": { name: "Insurance Xero", link: "/user/InsuranceInvoices", icon: "" , active : true},
                        "referrerGroupXero": { name: "Referrer Group Xero", link: "/user/referrerGroupXero", icon: "" , active : true},                        
                        "bankStatementLinks": { name: "Bank Statement Links", link: "/user/listBankStatementLinks", icon: "",active : true },
                        "generalFiles": { name: "General Files", link: "/user/generalFiles", icon: "",active : true },
                        "listOfficeContacts": { name: "List Office Contacts", link: "/user/listOfficeContacts", icon: "",active : true },                   
                    },
                    active : true    
                },
            },
            "auditor":{
                "referrals": {
                    name: "Referrals", link: "/user/listApplications", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: false,
                    subMenuNavigation: {},
                    active: true
                },
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
            }
        },
        "creditRep" : {
            "admin": {
                "dashboard": {
                    name: 'Dashboard', link: "/user/dashboard", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "lenders" : {
                    name: 'Lenders', link: "/user/listLenders", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "Insurances" : {
                    name: 'Insurances', link: "/user/listInsurances", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    accessibleBy: [21],
                    hasSubMenu: false,
                    active : false
                },            
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "",active : true },
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active : true },
                        "myActiveReferrals": { name: "Active Referrals", link: "/user/listApplications/my", icon: "", active : true },
                        "deletedReferrals": { name: "Delete Referrals", link: "/user/listApplications/deleted", icon: "", active : true },
                    },
                    active : true
                },
                "referrerGroups": {
                    name: 'Referrer Groups', link: "", icon: (<><span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                        <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                    </svg>
                </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "createReferrerGroup": { name: 'Add Referrer Group', link: "/user/createReferrerGroup", icon: "",active : true },
                        "listReferrerGroups": { name: 'List Referrer Groups', link: "/user/listReferrerGroups", icon: "", active : true },
                        "referrersGroupRcti": { name: 'Referrer Group RCTI', link: "", icon: "" },
                    },
                    active : true
                },
                "referrers": {
                    name: 'Referrers', link: "/user/addReferrer", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addReferrer": { name: 'Create Referrer', link: "/user/createReferrer" , icon: "" , active: true },
                        "listReferrers": { name: 'List Referrers', link: "/user/listReferrers",icon: "" , active: true },                        
                        "archivedReferrers": { name: 'Archived Referrers', link: "/user/archivedReferrers",icon: "" , active: true },                        
                    },
                    active : true
                },
                "insuranceCompanies" : {
                    name: "Insurers" , link : "/user/insurers" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    accessibleBy: [21],
                    active : true
                },
                "settlements" : {
                    name: 'Settlements', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "listSettlements": { name: "List Settlements", link: "/user/settlements", icon: "" , active : true},
                        "listReinstatedSettlements": { name: "Reinstated Settlements", link: "/user/listReinstatedSettlements", icon: "", active:true },                   
                    },
                    active : true    
                },
                "declined" : {
                    name: 'Declined', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "listDeclined": { name: "List Declined", link: "/user/declined", icon: "" , active : true},
                        "listDueFollowUp": { name: "Due Follow Up", link: "/user/dueFollowUp", icon: "", active:true },                   
                    },
                    active : true    
                },
                "utilities" : {
                    name: 'Utilities', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "referrerGroupRCTI": { name: "Referrer Group RCTI", link: "/user/referrerGroupRCTI", icon: "" , active : true},
                        "referrerRCTI": { name: "Referrer RCTI", link: "/user/referrerRCTI", icon: "", active:true },
                        "referrerPaymentNotification": { name: "Referrer Payment Notification", link: "/user/referrerPaymentNotification", icon: "", active:true },
                        "giftCardRecipients": { name: "Gift Card Report", link: "/user/giftCardRecipients", icon: "" , active : true , accessibleBy: [21] },
                        "giftCardRecipientsText": { name: "Gift Card SMS", link: "/user/giftCardRecipientsText", icon: "" , active : true , accessibleBy: [21] },
                        "bankStatementLinks": { name: "Bank Statement Links", link: "/user/listBankStatementLinks", icon: "",active : true },
                        "generalFiles": { name: "General Files", link: "/user/generalFiles", icon: "",active : true },
                        "listUsers": { name: "Staff", link: "/user/listUsers", icon: "",active : false },
                        "listOfficeContacts": { name: "List Office Contacts", link: "/user/listOfficeContacts", icon: "",active : true , accessibleBy: [21] },
                        "staffBudget": { name: "Staff Budget", link: "/user/staffBudget", icon: "",active : true },
                        "crm": { name: "CRM", link: "/user/crm", icon: "",active : true , accessibleBy: [21] },
                        "cpd": { name: "NCCP", link: "/user/cpd", icon: "",active : true },
                        "emailTemplates": { name: "Email Templates", link: "/user/emailTemplates", icon: "",active : true },                   
                    },
                    active : true    
                },
            },
            "analyst": {
                "dashboard": {
                    name: 'Dashboard', link: "/user/dashboard", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "lenders" : {
                    name: 'Lenders', link: "/user/listLenders", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "Insurances" : {
                    name: 'Insurances', link: "/user/listInsurances", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    accessibleBy: [21],
                    hasSubMenu: false,
                    active : false
                },            
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "",active : true },
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active : true },
                        "myActiveReferrals": { name: "Active Referrals", link: "/user/listApplications/my", icon: "", active : true },
                    },
                    active : true
                },                
                "referrers": {
                    name: 'Referrers', link: "/user/listReferrers", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: false,
                    active : true
                },
                "insuranceCompanies" : {
                    name: "Insurers" , link : "/user/insurers" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    accessibleBy: [21],
                    active : true
                },
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
                "declined" : {
                    name: 'Declined', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "listDeclined": { name: "List Declined", link: "/user/declined", icon: "" , active : true},
                        "listDueFollowUp": { name: "Due Follow Up", link: "/user/dueFollowUp", icon: "", active:true },                   
                    },
                    active : true    
                },
                "utilities" : {
                    name: 'Utilities', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "generalFiles": { name: "General Files", link: "/user/generalFiles", icon: "",active : true },                 
                        "listOfficeContacts": { name: "List Office Contacts", link: "/user/listOfficeContacts", icon: "",active : true , accessibleBy: [21] },
                        "cpd": { name: "NCCP", link: "/user/cpd", icon: "",active : true },
                    },                    
                    active : true    
                },
            },
            "referrerGroupManager": {
                "dashboard": {
                    name: 'Dashboard', link: "/user/dashboard", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "",active : true },
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active : true },
                        "myActiveReferrals": { name: "Active Referrals", link: "/user/listApplications/my", icon: "", active : false },
                        "deletedReferrals": { name: "Delete Referrals", link: "/user/listApplications/deleted", icon: "", active : false },
                    },
                    active : true
                },
                "referrerGroups": {
                    name: 'Referrer Groups', link: "", icon: (<><span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                        <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                    </svg>
                </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "createReferrerGroup": { name: 'Add Referrer Group', link: "/user/createReferrerGroup", icon: "",active : false },
                        "listReferrerGroups": { name: 'List Referrer Groups', link: "/user/listReferrerGroups", icon: "", active : true },
                        "referrersGroupRcti": { name: 'Referrer Group RCTI', link: "", icon: "", active: false },
                    },
                    active : true
                },
                "referrers": {
                    name: 'Referrers', link: "/user/addReferrer", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addReferrer": { name: 'Create Referrer', link: "/user/createReferrer" , icon: "" , active: false },
                        "listReferrers": { name: 'List Referrers', link: "/user/listReferrers",icon: "" , active: true },                        
                        "archivedReferrers": { name: 'Archived Referrers', link: "/user/archivedReferrers",icon: "" , active: true },                        
                    },
                    active : true
                },
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
                "declined" : {
                    name: 'Declined', link: "/user/declined", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                }, 
                "lenders" : {
                    name: 'Lenders', link: "/user/listLenders", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true
                },   
                "utilities" : {
                    name: 'Utilities', link: "#", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "referrerGroupRCTI": { name: "Referrer Group RCTI", link: "/user/referrerGroupRCTI", icon: "" , active : true},                   
                        "referrerRCTI": { name: "Referrer RCTI", link: "/user/referrerRCTI", icon: "" , active : true},                   
                    },
                    active : true    
                },                          
            },
        },
        "referrerGroup" : {
            "user" : { 
                "referrals": {
                    name: "Referrals", link: "", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: true,
                    subMenuNavigation: {
                        "addNewReferrals": { name: "Add New Referral", link: "/user/consumerApplication", icon: "",active : true },
                        "allReferrals": { name: "All Referrals", link: "/user/listApplications", icon: "", active : true }
                    },
                    active : true
                },
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
                "declined" : {
                    name: 'Archive', link: "/user/declined", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },           
                "rcti" : {
                    name: "RCTI" , link: "/user/referrerRCTI" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    hasSubMenu: false,
                    active : true
                },
                "cpd" : { 
                    name: "NCCP", link: "/user/cpd" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    hasSubMenu: false,
                    active : true
                },
                "referrers": {
                    name: 'Referrers', link: "/user/listReferrers", icon: (<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </>),
                    hasSubMenu: false,                     
                    active : true
                },

            }
        },
        "referrer":{
            "user" : { 
                "referrals": {
                    name: "Active Referrals", link: "/user/listApplications", icon: (<><span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                    </span> </>),
                    hasSubMenu: false,
                    active : true
                },                 
                "settlements" : {
                    name: 'Settlements', link: "/user/settlements", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },
                "declined" : {
                    name: 'Archive', link: "/user/declined", icon: (<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                    </svg></>),
                    hasSubMenu: false,
                    active : true    
                },                
                "rcti" : {
                    name: "RCTI" , link : "/user/referrerRCTI" , icon: (
                        <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="currentColor"></path>
                            <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="currentColor"></path>
                        </svg>
                        </>
                    ),
                    hasSubMenu: false,
                    active : true
                },

            }
        }

    }

    export default MenuItems;