

const Error403 = () => {

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <img src="/media/illustrations/general/403-error.gif" alt="" />
            </div>

        </>
    )
}

export default Error403;