import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

const Header = () => {
    return (
        <>
            <div className="toolbar mobileHeader" id="kt_toolbar">
                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1"></h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;