import { useEffect, useState } from 'react';
import { InputField, SelectField, TextArea, CustomButton, CheckBox } from '../../HtmlComponents';
import { useToken, useEntityId, useEntityType } from '../../../hooks/useAuth';
import { getApplicationReferrerDetails } from '../../../api';
import Notifications from '../../../components/global//Notifications';

const AssignedReferrerDetails = ({ applicationId, callback }) => {

    const [referrerGroup, setReferrerGroup] = useState(null);
    const [referrer, setReferrer] = useState(null);
    const [referrerUsers, setReferrerUsers] = useState([]);
    const [referrerStaffId, setReferrerStaffId] = useState(0);


    const [processing, setProcessing] = useState(false);

    var token = useToken();
    var entityType = useEntityType();

    const fetchAssignedReferrerDetails = async () => {
        var response = await getApplicationReferrerDetails(token, applicationId);
        console.log("FETCH ASSIGNED REFERRE GROUP AND REFERRER DETAILS", response);
        if (response["error"] == 0) {
            setReferrer(response["data"]["referrer"] || {});
            setReferrerGroup(response["data"]["referrerGroup"] || {});
            setReferrerUsers(response["data"]["referrerUsers"] || []);
            setReferrerStaffId(response["data"]["referrerStaffId"] || 0);
        }
    }

    useEffect(() => {
        fetchAssignedReferrerDetails();
    }, []);

    return (
        <>
            {entityType !== "referrerGroup" ? <>
                <div className='row' style={{ borderBottom: "1px solid #00000014", marginBottom: "10px" }}>
                    <div className='col-sm-12'><div className='fs-5 mb-3'> <span className="card-label fw-bold text-gray-800">Referrer Group</span></div> </div>
                    <div className='col-sm-4'>
                        <div className='fs-5 mb-3'>
                            <span className="fs-6 fw-bold mb-5">Name</span>
                            <div> <span className='fs-6 fw-semibold text-gray-600'>{referrerGroup !== null && referrerGroup.referrerGroupName != null ? referrerGroup.referrerGroupName : ""}</span> </div>
                        </div>
                    </div>

                    <div className='col-sm-4'>
                        <div className='fs-5 mb-3'>
                            <span className="fs-6 fw-bold mb-5">Address</span>
                            <div><span className='fs-6 fw-semibold text-gray-600'>{referrerGroup !== null && referrerGroup.address != null && referrerGroup.address.fullAddress != null ? referrerGroup.address.fullAddress : ""}</span></div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='fs-5 mb-3'>
                            <span className="fs-6 fw-bold mb-5">Website URL</span>
                            <div> <span className='fs-6 fw-semibold text-gray-600'>{referrerGroup !== null && referrerGroup.websiteUrl != null ? referrerGroup.websiteUrl : ""}</span> </div>
                        </div>
                    </div>
                </div>
            </>
                : ""}

            <div className='row' style={{ borderBottom: "1px solid #00000014", marginBottom: "10px" }}>
                <div className='col-sm-12'><div className='fs-5 mb-3'> <span className="card-label fw-bold text-gray-800">Referrer</span></div> </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">Name</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.tradingName != null ? referrer.tradingName : ""}</span> </div>
                    </div>
                </div>

                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">Email</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.email != null ? referrer.email : ""}</span> </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">Contact Number</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.contactNumber != null ? referrer.contactNumber : ""}</span> </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">ABN</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.abn != null ? referrer.abn : ""}</span> </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">ACN</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.acn != null ? referrer.acn : ""}</span> </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">Address</span>
                        <div> <span className='fs-6 fw-semibold text-gray-600'>{referrer !== null && referrer.address != null && referrer.address.fullAddress != null ? referrer.address.fullAddress : ""}</span> </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3  '>
                        <span className="fs-6 fw-bold mb-5">Website</span>
                        <div> <a className='fs-6 fw-semibold text-gray-600' target={"_blank"} href={referrer !== null && referrer.websiteUrl != null ? referrer.websiteUrl : "NA"}> {referrer !== null && referrer.websiteUrl != null ? referrer.websiteUrl : "NA"}  </a> </div>
                    </div>
                </div>
            </div>

            <div className='row' style={{ borderBottom: "1px solid #00000014", marginBottom: "10px" }}>
                <div className='col-sm-12'><div className='fs-5 mb-3'> <span className="card-label fw-bold text-gray-800">Staff</span></div> </div>

                <div className='col-sm-4'>
                    <div className='fs-5 mb-3'>
                        <span className="fs-6 fw-bold mb-5">Name</span>
                    </div>
                </div>

                <div className='col-sm-4'>
                    <div className='fs-5 mb-3'>
                        <span className="fs-6 fw-bold mb-5">Contact #</span>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='fs-5 mb-3'>
                        <span className="fs-6 fw-bold mb-5">Email</span>
                    </div>
                </div>
                <div className='col-sm-12'>
                    {
                        Array.isArray(referrerUsers) ?
                            referrerUsers.map(singleReferrerUser => {
                                let style = singleReferrerUser.id === referrerStaffId ? { color: "green" } : { color: "black" };
                                return (
                                    <>
                                        <div className='row' style={style}>
                                            <div className='col-sm-4'>
                                                <div className='fs-5 mb-3'>
                                                    <span className="fs-6 fw-bold mb-5">{singleReferrerUser.firstName + " " + singleReferrerUser.surname}</span>
                                                </div>
                                            </div>

                                            <div className='col-sm-4'>
                                                <div className='fs-5 mb-3'>
                                                    <span className="fs-6 fw-bold mb-5"> {singleReferrerUser.contactNumber}</span>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='fs-5 mb-3'>
                                                    <span className="fs-6 fw-bold mb-5">{singleReferrerUser.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }) : ""
                    }

                </div>
            </div>



            <div className='d-flex  mb-3' style={{ flexDirection: "row-reverse" }}>

                <CustomButton
                    buttonClassName="btn-sm btn-secondary"
                    buttonStyle={{ float: "right", marginRight: "20px" }}
                    buttonOnClick={() => { callback("closeDrawer", true); }}
                    buttonText="Close"
                    isActive={true}
                />
            </div>

            <div className='d-flex row'>



            </div>
        </>
    )
}

export default AssignedReferrerDetails;