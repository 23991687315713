import{capitalizeFirstLowercaseRest , capitalizeFirstOfEachWord } from '../../../helpers'
const InputField = ({ fieldName, placeholder , fieldValue, setFieldValue, capitalizeFirstLetter = false }) => {
    return (
        <>
            <input
                type="text"
                className="form-control"
                style={{ height: "35px", fontWeight: "400" }}
                placeholder={placeholder}
                aria-label={placeholder}
                value= {fieldValue}
                onChange={(e) => {
                    var inputString = e.target.value;
                    //CAPITALIZE FIRST LETTER
                    if(capitalizeFirstLetter && inputString.length > 0){ inputString = capitalizeFirstOfEachWord(inputString) }
                    setFieldValue(fieldName, inputString) 
                }}
            />
        </>
    )
}

export default InputField; 