import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ResetPasswordValidationSchema from './schema/ResetPasswordValidationSchema';
import { Formik, Form, Field } from 'formik';
import { resetPassword } from '../../api';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';
import { FormSubmissionButton, CustomButton } from '../HtmlComponents';

const ResetPassword = () => {
    const userRef = useRef();
    const [user, setUser] = useState({ password: "", passwordConfirmation: "" , token:""});
    const [searchParams, setSearchParams] = useSearchParams();
    const [formErrors, setFormErrors] = useState({});
    let navigate = useNavigate();

     

    useEffect(() => {
        setUser({ ...user, token: searchParams.get('token') });
    }, []);

    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-lg-row-fluid">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                            <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                <div className="d-flex flex-row-fluid flex-column text-center">
                                    <a href="#" className="py-9 mb-5">
                                        <img alt="Logo" src="/media/logos/brokahub-logo-horizontal-black.svg" className="h-75px" />
                                    </a>
                                </div>

                                <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <Formik
                                            initialValues={user}
                                            validationSchema={ResetPasswordValidationSchema}
                                            validateOnBlur={false}

                                            onSubmit={async (values) => {
                                                let token = user.token;
                                                values = { ...values, token: token };
                                                
                                                console.log("VALUES", values);
                                                //CHECK IF USER NAME IS PROVIDED                                                
                                                if (values["password"] !== undefined && values["password"].length > 6) {
                                                    const response = await resetPassword(values);
                                                    if (response["error"]) {  Notifications("error", response["message"] || "Error"); } 
                                                    else { Notifications("success", response["message"] || "" );  }
                                                }
                                                else { Notifications("error", "Please provide a valid username"); }
                                            }}
                                        >
                                            {({ errors, touched, handleChange, setFieldValue, values }) => (
                                                <Form>
                                                    <div>
                                                        <div className="  mb-10"> <h3 className="text-dark mb-3">Reset Your Password</h3> </div>
                                                        <p className='mb-10'>Please enter a new password.</p>
                                                    </div>

                                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="password" type="password" placeholder="New Password" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                            {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}                                                        
                                                        </div>
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="passwordConfirmation" type="password" placeholder="Confirm Password" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                            {errors.passwordConfirmation && touched.passwordConfirmation ? (<div className="error">{errors.passwordConfirmation}</div>) : null}
                                                        </div>

                                                    </div>

                                                    <div className="text-center">

                                                        {JSON.stringify(errors).length > 3 ?
                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5" disabled >
                                                                <span className="indicator-label">Send</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button> :



                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5"  >
                                                                <span className="indicator-label">Forgot Password</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button>
                                                        }
                                                    </div>

                                                </Form>


                                            )}
                                        </Formik>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )


}
export default ResetPassword;
