import { react, useEffect, useState } from 'react';
import { Field } from 'formik';
import { SelectField, CheckBox, Modal, InputField } from '../../../../../components/HtmlComponents';
import Constants from "../../../../../components/global/Constants";
import ResidentialStayDuration from "./ResidentialStayDuration";
import AddressForm from '../../../../../components/global/AddressForm';
import LandLordDetail from './LandlordDetail';
import RentDetail from './RentDetail';

//preResidentialStatus  it is temp and can be removed after a new approach is established to deal with 
//trigger action upon default value selection of residential status

const ResidentialAddressDetail = ({ prefix, setFieldValue, residentialStatusList, totalLivingDurationCallback, preResidentialStatus }) => {
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });
    const [residentialStatus, setResidentialStatus] = useState(null);
    
    const handleResidentialStatusChange = (name, value) => {
        setResidentialStatus(value);
    }

    useEffect( () => {
        setResidentialStatus(preResidentialStatus);
    }, [preResidentialStatus])

    useEffect( () => {
    }, [residentialStatus])



    return (
        <>
            <div className="row">
                <div className="col-sm-6 col-md-5 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2"> { prefix.includes("current") ? "Current" : "Previous" } Residential Address </label>
                    <Field name={prefix + "address"} >
                        {({ field }) => (
                            <input
                                className="form-control form-control-solid"
                                type="text"
                                value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                onClick={() => setAddressModal({ visible: true, fieldName: `${prefix}address`, callback: setFieldValue })}
                                onChange={(e) => { }}
                                style={{ height: Constants.TEXTFIELD_HEIGHT }}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-6 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Time at this address</label>
                    <div className="row">
                        <div className="col-sm-4 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                            <Field name={prefix + "duration"} >
                                {({ field }) => (                                    
                                    <ResidentialStayDuration fieldName={prefix + "duration"} fieldValue={field.value} setFieldValue={setFieldValue}  totalLivingDurationCallback = {totalLivingDurationCallback}  />
                                )}
                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Residential Status</label>
                    <div className="row">
                        <div className="col-sm-4 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                            <Field name={prefix + "status"} >
                                {({ field }) => (
                                    <SelectField
                                        fieldName={prefix + "status"}
                                        defaultSelectedOption={{ value: field.value, label: "" }}
                                        options={residentialStatusList}
                                        setFieldValue={setFieldValue}
                                        callback={handleResidentialStatusChange}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                </div>
            </div>

            {["rentingAgent", "rentingPrivate", "boardingOther", "boardingLivingWithFamily", "caravanPark", "hotelOrHostel"].includes(residentialStatus) 
            && prefix.includes("current") ?              
            <RentDetail prefix=  { prefix + "rent." } setFieldValue={setFieldValue} /> 
            : ""}

            {["rentingAgent", "rentingPrivate", "boardingOther", "boardingLivingWithFamily", "suppliedByEmployer", "caravanPark", "hotelOrHostel"].includes(residentialStatus) 
            && prefix.includes("current")             
            ? <LandLordDetail prefix=  { prefix + "landlord." } setFieldValue={setFieldValue} /> 
            : ""}

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Residential Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    )


}

export default ResidentialAddressDetail;