import React from "react";
import { Outlet, Link } from 'react-router-dom';
import { SwitchOff, LogOut } from '../HtmlComponents/Icons/index';



const LenderLayout = ({ children }) => {
    return (
        <React.Fragment>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" style={{ paddingTop: "0px", paddingLeft: "0px" }}>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">


                    { /*************************** HEADER START ******************************** */}


                    <div id="kt_header" class="header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{ left: "0px" }}  >
                        <div class=" container-xxl  d-flex align-items-center">
                            <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
                                <a href="javascript:void(0)">
                                    <img alt="Logo" src="/media/logos/brokahub-logo-1.svg" className="h-35px logo" />
                                </a>
                            </div>
                            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                                <div class="d-flex align-items-stretch" id="kt_header_nav">
                                </div>
                                <div class="topbar d-flex align-items-stretch flex-shrink-0">
                                    <div class="d-flex align-items-center ms-1 ms-lg-3">
                                        <a href="#" class=" " data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                            <Link to="/logout" className="btn btn-sm btn-secondary  btn  w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="" data-bs-original-title="200+ in-house components and 3rd-party plugins">
                                                <span className="btn-label">Logout</span>
                                            </Link> 
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*************************** HEADER END ******************************** */}

                    <div style={{ marginTop: "60px" }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </React.Fragment>);
}

export default LenderLayout;



/**
 * 
 */