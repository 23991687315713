import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox } from '../../HtmlComponents';
import { isValidDate, formatDate, getCurrentDate } from '../../../helpers';
import { getApplicationProgressStatus, performApplicationAdditionalActions, getComments, getApplicationReferrerDetails, getApplication } from '../../../api';
//import CreditProposalValidationSchema from '../schema/CreditProposalValidationSchema';
import Notifications from "../../global/Notifications";
import { useToken } from "../../../hooks/useAuth";
import LoadingScreen from "../../global/LoadingScreen";


const SubmitToAwnInsurance = ({ applicationId, callback }) => {
    const [application, setApplication] = useState({});
    const [applicants, setApplicants] = useState([]);
    const [applicant, setApplicant] = useState({});
    const [listApplicants, setListApplicants] = useState([]);

    const [payload, setPayload] = useState({
        customer: { firstName: "", surname: "", contactNumber: "", email: "", fullAddress: "", state: "", postCode: "", suburb: "" },
        vehicle: { engineNumber: "", nvic: "", odometer: "", purchaseDate: "", registrationNumber: "", vin: "", vehiclePrice: "" },
        product: { code: "", id: "", retailCost: "", startDate: "" }
    });
    const [processing, setProcessing] = useState(false);
    const states = [{ value: "VIC", label: "Victoria" }, { value: "NSW", label: "New South Wales" }, { value: "SA", label: "South Australia" }, { value: "QLD", label: "Queensland " }, { value: "ACT", label: "Australian Capital Territory " }, { value: "TAS", label: "Tasmania " }, { value: "NT", label: "Northern Territory " }, { value: "WA", label: "Western Australia " }]

    const formRef = useRef()
    const token = useToken();


    //LOAD APPLICATION 
    const loadApplication = async () => {
        const response = await getApplication(applicationId, token);
        if (response["httpCode"]) {
            if (response["httpCode"] == 200) {
                var quoteData = response.data;

                var tmpApplicants = JSON.parse(JSON.stringify(quoteData.applicants));
                console.log("applicants::::::::::: ", tmpApplicants);
                var tmpApplicantsQuickInfo = [];
                var tmpListApplicants = [];         //USE FOR DROPDOWN OPTIONS
                if (Array.isArray(tmpApplicants)) {
                    tmpApplicants.map(singleApplicant => {
                        let applicantCurrentAddress = singleApplicant.personalDetail !== undefined && singleApplicant.personalDetail.residentialHistory !== undefined && singleApplicant.personalDetail.residentialHistory.current !== undefined &&
                            singleApplicant.personalDetail.residentialHistory.current.address !== undefined ? singleApplicant.personalDetail.residentialHistory.current.address : {};

                        tmpApplicantsQuickInfo.push({
                            id: singleApplicant.id,
                            firstName: singleApplicant.firstName,
                            middleName: singleApplicant.middleName,
                            surname: singleApplicant.surname,
                            contactNumber: singleApplicant.contactNumber,
                            email: singleApplicant.email,
                            address: applicantCurrentAddress
                        });

                        //OPTIONS
                        tmpListApplicants.push({ value: singleApplicant.id, label: singleApplicant.firstName })
                    })
                }

                setListApplicants(tmpListApplicants);
                setApplicants(tmpApplicantsQuickInfo);
                setApplicant(tmpApplicantsQuickInfo[0] !== undefined ? tmpApplicantsQuickInfo[0] : {});
                delete quoteData.applicants;

                setApplication(response.data);
                setProcessing(false);
            }
            else {

                setProcessing(false);
            }
        }
    }

    useEffect(() => {
        loadApplication();
    }, [])

    useEffect(() => {
        var buyingItem = application.buyingItem !== undefined && application.buyingItem.motorVehicles !== undefined && application.buyingItem.motorVehicles[0] !== undefined ? application.buyingItem.motorVehicles[0] : {};
        var financial = application.financial !== undefined ? application.financial : {};
        console.log("buyingItem :: ", buyingItem)
        setPayload((prevState) => {
            return {
                ...prevState,
                vehicle: {
                    engineNumber: buyingItem.engineNumber !== undefined ? buyingItem.engineNumber : "",
                    nvic: buyingItem.nvic !== undefined ? buyingItem.nvic : "",
                    odometer: buyingItem.totalKilometer !== undefined ? buyingItem.totalKilometer : "",
                    purchaseDate: "",
                    registrationNumber: buyingItem.registrationNumber !== undefined ? buyingItem.registrationNumber : "",
                    vin: buyingItem.vin !== undefined ? buyingItem.vin : "",
                    vehiclePrice: financial.assetPrice !== undefined ? financial.assetPrice : ""
                }
            }
        });
    }, [application])



    useEffect(() => {
        var applicantCurrentAddress = applicant.address !== undefined ? applicant.address : {};
        var firstName = applicant.firstName !== undefined ? applicant.firstName : "";
        var surname = applicant.surname !== undefined ? applicant.surname : "";
        var middleName = applicant.middleName !== undefined ? applicant.middleName : "";

        setPayload((prevState) => {
            return {
                ...prevState,
                customer: {
                    firstName: firstName,
                    surname: surname,
                    contactNumber: applicant.contactNumber !== undefined ? applicant.contactNumber : "",
                    email: applicant.email !== undefined ? applicant.email : "",
                    fullAddress: applicantCurrentAddress.fullAddress !== undefined ? applicantCurrentAddress.fullAddress : "",
                    postCode: applicantCurrentAddress.postCode !== undefined ? applicantCurrentAddress.postCode : "",
                    suburb: applicantCurrentAddress.suburb !== undefined ? applicantCurrentAddress.suburb : "",
                    state: applicantCurrentAddress.state !== undefined && applicantCurrentAddress.state.length > 0 ? applicantCurrentAddress.state.toUpperCase() : "",
                }

            }
        });

    }, [applicant])

    const handler = (name, value) => {
        switch (name) {
            case "selectApplicant":
                var findApplicant = applicants.find(applicant => applicant.id === value);
                console.log("findApplicant:::: ", findApplicant)
                if (findApplicant !== undefined) {
                    setApplicant({
                        ...applicant,
                        firstName: findApplicant.firstName !== undefined ? findApplicant.firstName : "",
                        middleName: findApplicant.middleName !== undefined ? findApplicant.middleName : "",
                        surname: findApplicant.surname !== undefined ? findApplicant.surname : "",
                        contactNumber: findApplicant.contactNumber !== undefined ? findApplicant.contactNumber : "",
                        email: findApplicant.email !== undefined ? findApplicant.email : "",
                        address: findApplicant.address !== undefined ? findApplicant.address : "",
                    });
                }
                else { setApplicant({ ...applicant, firstName: "", middleName: "", surname: "", contactNumber: "", email: "", address: {} }); }
                break;

        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={payload}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validateOnBlur={false}

                        onSubmit={async (values) => {
                            var valuesClone = JSON.parse(JSON.stringify(values));
                            //CHECK IF CAR PURCHASE DATE IS PROVIDED OTHERWISE RETURN ERROR
                            var purchaseDate = valuesClone["vehicle"]["purchaseDate"];

                            var isDateValid = isValidDate(purchaseDate, "DD/MM/YYYY"); console.log("isDateValid::: ", isDateValid);
                            //CHECK IF DATE IS VALID
                            if (!isDateValid) { Notifications("error", "Please provide a valid purchase date...."); }
                            //MAKE AN API CALL 
                            else {
                                valuesClone["vehicle"]["purchaseDate"] = formatDate(purchaseDate, "DD/MM/YYYY", "YYYY-MM-DD");
                                setProcessing(true);
                                var response = await performApplicationAdditionalActions(token, "submitToAwnInsurance", applicationId, valuesClone);

                                var responseResult = response.data !== undefined ? response.data : {};
                                if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                                    var message = responseResult["message"] !== undefined ? responseResult["message"] : "";
                                    Notifications("success", message);
                                }
                                else {
                                    Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error")
                                }
                                setProcessing(false);
                            }

                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Select Applicant</label>
                                        <SelectField
                                            fieldName="selectApplicant"
                                            defaultSelectedOption={{ value: "", label: "" }}
                                            options={listApplicants}
                                            setFieldValue={() => { }}
                                            callback={handler}

                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">First Name</label>
                                        <Field name="customer.firstName" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Surname</label>
                                        <Field name="customer.surname" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Contact #</label>
                                        <Field name="customer.contactNumber" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Email</label>
                                        <Field name="customer.email" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Address</label>
                                        <Field name="customer.fullAddress" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Postcode</label>
                                        <Field name="customer.postCode" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Suburb</label>
                                        <Field name="customer.suburb" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">State</label>
                                        <Field name="customer.state">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="customer.state"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={states}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>

                                    </div>

                                </div>
                                <div className="separator separator-dashed my-5"></div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Enginer #</label>
                                        <Field name="vehicle.engineNumber" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">NVIC</label>
                                        <Field name="vehicle.nvic" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Odometer</label>
                                        <Field name="vehicle.odometer" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Purchase Date</label>
                                        <Field name="vehicle.purchaseDate" className="form-control" style={{ height: "35px", fontWeight: "400" }} placeholder="dd/mm/yyyy" />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Registration Number</label>
                                        <Field name="vehicle.registrationNumber" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">VIN</label>
                                        <Field name="vehicle.vin" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>

                                    <div className="col-sm-12 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Vehicle Price</label>
                                        <Field name="vehicle.vehiclePrice" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                                    </div>
                                </div>

                                <div className="col-xl-12">
                                    {<FormSubmissionButton isActive={true} label="Send to AWN" />}
                                </div>
                            </Form>

                        )}

                    </Formik>

                </div>
            </div>
            {
                processing ? <LoadingScreen /> : ""
            }


        </>
    )
}

export default SubmitToAwnInsurance;