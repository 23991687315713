import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";

import { getTemplates, createESignatureRequest, getComments } from "../../../api";
import { useToken } from '../../../hooks/useAuth';

import LoadingScreen from '../../global/LoadingScreen';


import AuthorityConsentFormSchema from '../schema/AuthorityConsentFormSchema.json';
import AuthoriyConsentFormValidationschema from '../schema/AuthoriyConsentFormValidationschema';

const AuthorityConsentForm = ({ applicationId, application, applicant, callback }) => {
    const [templates, setTemplates] = useState([]);
    const [comments, setComments] = useState([]);
    const [initialValues, setInitialValues] = useState(AuthorityConsentFormSchema);
    const [processing, setProcessing] = useState(false);

    const token = useToken();
    const applicationCreditRepId = application["creditReportId"] !== undefined ? application["creditReportId"] : 0;
    const applicationReferrerGroupId = application["referrerGroupId"] !== undefined ? application["referrerGroupId"] : 0;
    const applicationType = application["applicationType"] !== undefined && ["consumer", "commercial"].includes(application["applicationType"]) ? application["applicationType"] : "";
    const applicationCategory = application["category"] !== undefined && ["individual", "joint" , "company"].includes(application["category"]) ? application["category"] : "";

    //FETCH ELECTRONIC SIGNATURES TEMPLATES
    const fetchTemplates = async () => {
        var response = await getTemplates(token);
        console.log("TMEPLATE RESPONSE:::::::::::::::::::  ", response);
        if (response["error"] !== undefined && response["error"] == 0) {
            var templates = response["data"];
            var tmpOptions = [];
            templates.map(singleTemplate => {
                if(applicationReferrerGroupId !== 0){
                    if(singleTemplate["referrerGroupId"] == applicationReferrerGroupId  || singleTemplate["referrerGroupId"] === 0 ){
                        tmpOptions.push({ label: singleTemplate["name"], value: singleTemplate["templateId"] });
                    }
                }
                else{
                    tmpOptions.push({ label: singleTemplate["name"], value: singleTemplate["templateId"] });
                }

            });
            setTemplates(tmpOptions);
        }

        console.log("TMEPLATE RESPONSE:::::::::::::::::::  ", response["data"])
    }

    //FETCH NOTES /COMMENTS
    const fetchComments = async () => {
        var response = await getComments(token, "eSignatureRequest", applicationId);
        if (response["error"] == 0) {
            setComments(response["data"]);
        }
    }

    useEffect(() => {
        fetchComments();
        fetchTemplates();
    }, []);

    useEffect(() => {
        var personalDetails = applicant["personalDetail"] !== undefined ? applicant["personalDetail"] : {};
        var firstName = personalDetails["firstName"] !== undefined ? personalDetails["firstName"] : ""
        var middleName = personalDetails["middleName"] !== undefined ? personalDetails["middleName"] : ""
        var surname = personalDetails["surname"] !== undefined ? personalDetails["surname"] : ""
        var email = personalDetails["email"] !== undefined ? personalDetails["email"] : ""

        var tmpInitialValues = {
            applicationId: applicationId,
            applicantId: applicant.id !== undefined ? applicant.id: 0,
            "templateId": "",
            "signer": {
                "name": firstName + " " + middleName + " " + surname,
                "email": email
            }
        }
        setInitialValues({...tmpInitialValues })
    }, [applicant])


    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validateOnBlur={false}
                        validationSchema={AuthoriyConsentFormValidationschema}
                        onSubmit={async (values) => {
                            //MAKE AN API CALL 
                            setProcessing(true);
                            console.log("values before sending ::: ", values);

                            var response = await createESignatureRequest(token, values);
                            console.log('authority cpnosent form ::::: ::', response);
                            setProcessing(false);

                            if(response["error"] == 0){ Notifications("success", response["message"]); }
                            else{ Notifications("error", response["message"]); }
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-6"> 
                                        <label className="fs-8 fw-bold mb-2">Customer Name </label>
                                        <Field name="signer.name" className="form-control  " />
                                        <div className="error">{errors !== undefined && errors.signer !== undefined && errors.signer.name ? errors.signer.name : ""}</div>
                                    </div>

                                    <div className="col-sm-6">
                                        <label className="fs-8 fw-bold mb-2">Customer email </label>
                                        <Field name="signer.email" className="form-control  " />
                                        <div className="error">{errors !== undefined && errors.signer !== undefined && errors.signer.email ? errors.signer.email : ""}</div>
                                    </div>

                                    <div className="col-sm-6 mt-5">
                                        <label className="required fs-8 fw-bold mb-2">Privacy Type </label>
                                        <Field name="templateId">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="templateId"
                                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                                    options={templates}
                                                    setFieldValue={setFieldValue}
                                                    callback={() => { }}
                                                />

                                            )}
                                        </Field>
                                        <div className="error">{errors.templateId ? errors.templateId : ""}</div>
                                    </div>
                                    <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <div className='d-flex row'>
                        <h3>Applicant Privacy Consent Form Log</h3>
                        <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                            {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                        </div>
                    </div>
                </div>
            </div>
            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default AuthorityConsentForm;
