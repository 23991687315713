
import { useState, useEffect } from 'react';
import { RightDrawer, SelectField, CustomButton } from '../../HtmlComponents/';
import { getGeneralFiles } from "../../../api";
import { useRole, useToken, useEntityType, useEntityId } from '../../../hooks/useAuth';

import EquifaxEnquiry from './EquifaxEnquiry';
import EquifaxTitleSearch from './EquifaxTitleSearch';
import CreditProposal from './CreditProposal';
import TaxInvoice from './TaxInvoice';
import PreliminaryAssessment from './PreliminaryAssessment';
import CoverSheet from './CoverSheet';
import AuthorityConsentForm from './AuthorityConsentForm';
import ReferralIndicativeQuote from './ReferralIndicativeQuote';
import BankStatementRequestForm from './BankStatementRequestForm';
import SubmitToLender from './SubmitToLender';
import AssetsAndLiabilitiesStatement from './AssetsAndLiabilitiesStatement';

//PPSR
import Ppsr from '../Utilities/Ppsr';
import CarLivePricing from '../Utilities/CarLivePricing';

import CreateApplicantAccessToken from './CreateApplicantAccessToken';


const ComplianceTab = ({ application, applicationId, applicant, applicants, applicantId, callback }) => {
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    //const [generalFiles, setGeneralFiles] = useState([]);
    const [processing, setProcessing] = useState(true);        //IF UPDATING OR DELETING A FILE

    const token = useToken();
    const userRole = useRole();
    const userEntityType = useEntityType();

    /*const loadGeneralFiles = async () => {
        var response = await getGeneralFiles(token);
        console.log(response);
        setProcessing(false);
        setGeneralFiles(response["data"]);

    }

    useEffect(() => {
        loadGeneralFiles();
    }, [])*/

    const handler = (action) => {
        switch (action) {
            case "openSubmitToLender":
                setDrawerSettings({ visible: true, heading: "Submit To Lender", childName: "submitToLender", meta: { applicationId: applicationId } });
                break;
            case "openEquifaxEnquiry":
                setDrawerSettings({ visible: true, heading: "Equifax Enquiry", childName: "equifaxEnquiry", meta: { applicationId: applicationId } });
                break;
            case "openEquifaxTitleSearch":
                setDrawerSettings({ visible: true, heading: "Equifax Title Search", childName: "equifaxTitleSearch", meta: { applicationId: applicationId } });
                break;
            case "openCredirProposal":
                setDrawerSettings({ visible: true, heading: "Credit Proposal", childName: "creditProposal", meta: { applicationId: applicationId } });
                break;
            case "openTaxInvoice":
                setDrawerSettings({ visible: true, heading: "Tax Invoice", childName: "taxInvoice", meta: { applicationId: applicationId } });
                break;
            case "openPreliminaryAssessment":
                setDrawerSettings({ visible: true, heading: "Preliminary Assessment", childName: "preliminaryAssessment", meta: { applicationId: applicationId } });
                break;
            case "openCoverSheet":
                setDrawerSettings({ visible: true, heading: "Cover Sheet", childName: "coverSheet", meta: { applicationId: applicationId } });
                break;
            case "openAuthorityConsentForm":
                setDrawerSettings({ visible: true, heading: "Authority Consent Form", childName: "authorityConsentForm", meta: { applicationId: applicationId } });
                break;
            case "openReferralIndicativeQuote":
                setDrawerSettings({ visible: true, heading: "Referral Indicative Quote", childName: "referralIndicativeQuote", meta: { applicationId: applicationId } });
                break;
            case "openBankStatementRequestForm":
                setDrawerSettings({ visible: true, heading: "Bank Statement Request", childName: "bankStatementRequestForm", meta: { applicationId: applicationId } });
                break;
            case "assetsAndLiabilitiesStatement":
                setDrawerSettings({ visible: true, heading: "Statement of Assets and Liabilities", childName: "assetsAndLiabilitiesStatement", meta: { applicationId: applicationId } });
                break;
            case "openPpsr":
                setDrawerSettings({ visible: true, heading: "Download PPSR Report", childName: "ppsr", meta: { applicationId: applicationId } });
                break;
            case "openCarLivePricing":
                setDrawerSettings({ visible: true, heading: "Car Live Pricing", childName: "carLivePricing", meta: { applicationId: applicationId } });
                break;
            case "openCreateApplicantAccessToken":
                setDrawerSettings({ visible: true, heading: "Create Applicant Token", childName: "createApplicantAccessToken", meta: { applicationId: applicationId } });
                break;

            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }


    const GetComplianceOptionsBasedOnRole = () => {
        if (["admin", "analyst", "superAdmin", "finance"].includes(userRole)) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-7" onClick={() => { handler("openSubmitToLender"); }}>
                                Submit to lender
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openCredirProposal"); }}>
                                Credit Proposal
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openPreliminaryAssessment"); }}>
                                Preliminary Assessment
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openCoverSheet"); }}>
                                Application Cover Sheet
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openTaxInvoice"); }}>
                                Tax Invoice Request
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openReferralIndicativeQuote"); }}>
                                Referral Indicative Quote
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("assetsAndLiabilitiesStatement"); }}>
                                Assets and Liabilities Statement
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openAuthorityConsentForm"); }}>
                                Authority Consent Forms
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openBankStatementRequestForm"); }}>
                                Bank Statement Request
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openEquifaxEnquiry"); }}>
                                Equifax Enquiry
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openEquifaxTitleSearch"); }}>
                                Equifax Title Search
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openPpsr"); }}>
                                PPSR
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openCarLivePricing"); }}>
                                Car Live Pricing
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openCreateApplicantAccessToken"); }}>
                                Create Applicant Token
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (userEntityType === "referrerGroup") {
            return (
                <>

                    <div className="row">


                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7" onClick={() => { handler("openReferralIndicativeQuote"); }}>
                                Referral Indicative Quote
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openAuthorityConsentForm"); }}>
                                Authority Consent Forms
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openBankStatementRequestForm"); }}>
                                Bank Statement Request
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="d-flex align-items-center bg-light-info  rounded p-5 mb-7" onClick={() => { handler("openEquifaxEnquiry"); }}>
                                    Equifax Enquiry
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )
        }
        else{
            return null;
        }
    }

    //openEquifaxEnquiry
    return (
        <>


            <GetComplianceOptionsBasedOnRole />
            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                    {drawerSettings["childName"] === "submitToLender" ? <SubmitToLender applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "equifaxEnquiry" ? <EquifaxEnquiry applicationId={applicationId} applicant={applicant} applicantId={applicantId} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "equifaxTitleSearch" ? <EquifaxTitleSearch applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "creditProposal" ? <CreditProposal application={application} applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "taxInvoice" ? <TaxInvoice application={application} applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "preliminaryAssessment" ? <PreliminaryAssessment application={application} applicationId={applicationId} applicants={applicants} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "coverSheet" ? <CoverSheet application={application} applicationId={applicationId} applicants={applicants} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "authorityConsentForm" ? <AuthorityConsentForm applicationId={applicationId} application={application} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "referralIndicativeQuote" ? <ReferralIndicativeQuote applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "bankStatementRequestForm" ? <BankStatementRequestForm applicationId={applicationId} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "assetsAndLiabilitiesStatement" ? <AssetsAndLiabilitiesStatement applicationId={applicationId} application={application} applicant={applicant} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "ppsr" ? <Ppsr applicationId={applicationId} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "carLivePricing" ? <CarLivePricing applicationId={applicationId} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "createApplicantAccessToken" ? <CreateApplicantAccessToken applicationId={applicationId} applicants={applicants} callback={handler} /> : ""}
                </RightDrawer> : ""
            }


        </>
    )

}

export default ComplianceTab;