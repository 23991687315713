import { useEffect } from 'react';
import { useState } from 'react';
import { CheckBox, InputField } from '../../HtmlComponents';
import { Field } from 'formik';

const DeclinedReason = ({ name, label, isChecked, setFieldValue }) => {
    const [visibleDescriptionBlock, setVisibleDescriptionBlock] = useState(false);

    const updatHandler = (localName, value) => {
        if (localName !== "description") {
            if (value === true) { setFieldValue(`${localName}["isChecked"]`, true); }
            else { setFieldValue(`${localName}["isChecked"]`, false); }

            //CHECK IF OTHER IS MARKED
            if (localName == "reasons[10]") { value == true ? setVisibleDescriptionBlock(true) : setVisibleDescriptionBlock(false); }
        }
//        else if (localName == "description") { setFieldValue(`${name}["description"]`, value); }
    }
    useEffect(() => {
        if (name == "reasons[10]" && isChecked == true) {
            setVisibleDescriptionBlock(true);


        }
    }, [])

    return (<>

        <CheckBox
            name={name}
            defaultChecked={isChecked == true ? true : false}
            setFieldValue={updatHandler}
        />
        <span style={{ marginLeft: "10px" }}>{label}</span>
        {
            visibleDescriptionBlock == true ?
                <div style={{ marginLeft: "20px" }}>
                    <Field type="text" name={`${name}["description"]`}  >
                        {({ field }) => (
                            console.log("FIELD VALUE DESCRIPTIONS:: ", field.value),
                            
                            <InputField
                                fieldName={`${name}["description"]`}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue} />
                        )}
                    </Field>
                </div>
                : ""
        }
    </>)
}

export default DeclinedReason;