import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox } from '../HtmlComponents';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import { Formik, Form, Field } from 'formik';
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import { ContactSchema } from './schema/ContactSchema';
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import{getContact, updateContact} from '../../api';
import LoadingScreen from '../../components/global/LoadingScreen';

const UpdateContact = ({ contactId , callback}) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);  
    const [serverStatusCode, setServerStatusCode] = useState(null);

    const [contact, setContact] = useState({
        firstName: '', email: '', contactNumber: '',  role : "", emailSubscribed: true
    });

    //USE IT TO FECTH THE DIRECTOR DATA FROM SERVER
    const fetchContact = async() => {
        var response = await getContact(token, contactId);
        setServerStatusCode(response.httpCode);
        if(response.httpCode == 200){           
            setContact(response.contact);
            setLoading(false);
            console.log("CONTACT FROM SERVER", response.contact);
        }
        else{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchContact();
    },[])

    const token = useToken();

    return (
        <>
        { loading ? "LOADING" : (serverStatusCode != 200 ? <DecideErrorPage errorCode={serverStatusCode} /> :
            <Formik
                initialValues={contact}
                validationSchema={ContactSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    console.log("values..................", values);
                    setError("");

                    var data = JSON.parse(JSON.stringify(values));
                    setProcessing(true);
                    const response = await updateContact(token,contactId, data);
                    setProcessing(false);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error" )
                        setContact(values);
                    }else {
                        console.log( "response.id : " +  response.id);
                        Notifications("success", response["message"] || "Updated Successfully" )
                        //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                        console.log(response);
                        callback(contactId, response.contact);
                    }
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                   <div id="kt_content_container" className="container-xxl">
                   <div className="row g-12 g-xl-12">
                       <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                       <div className="col-xl-12">
                           <div className="card mb-xl-8">
                               <div className="card-body p-0">

                                   <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                                       <tbody className="fs-6">
                                           <tr className="even">
                                               <td className="text-start">Name</td>
                                               <td className="text-start text-gray-600">
                                                   <Field name="firstName" placeholder="Name" className="form-control form-control-solid" />
                                                   {errors.firstName ? (<div>{errors.firstName}</div>) : null}
                                               </td>
                                           </tr>
                                           <tr className="even">
                                               <td className="text-start">Email</td>
                                               <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                                   {errors.email ? (<div>{errors.email}</div>) : null}</td>
                                           </tr>
                                           <tr className="even">
                                               <td className="text-start">Contact Number</td>
                                               <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" />
                                                   {errors.contactNumber ? (<div>{errors.contactNumber}</div>) : null}</td>
                                           </tr>
                                           <tr className="even">
                                               <td className="text-start">Role</td>
                                               <td className="text-start text-gray-600">
                                                   <Field name="role" placeholder="Role" className="form-control form-control-solid" />
                                               </td>
                                           </tr>
                                           <tr className="even">
                                               <td className="text-start">Email Subscribed</td>
                                               <td className="text-start text-gray-600">
                                                   <Field name="emailSubscribed">
                                                   {({ field }) => (
                                                       <CheckBox name= "emailSubscribed"  defaultChecked = {field.value}  setFieldValue = {setFieldValue} />
                                                   )}
                                                   </Field>
                                               </td>
                                           </tr> 
                                            
                                       </tbody>
                                   </table>

                               </div>
                           </div> 
                           {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true: false} />}
                       </div>
                   </div>
               </div>
                    </Form>
                )}
            </Formik>
            )}
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default UpdateContact;