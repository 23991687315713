//IGNORE THE ENABLE UPLAOD PARAM AND GET THE VALUE FROM PERMISSION | USE ALLOWED HOOK
//NOT LIVE YET, COMPLETE THE FUNCTIONALITY AND MAKE IT LIVE LATER

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { getGeneralFiles, uploadGeneralFile, deleteGeneralFile, getGeneralFolders , updateGeneralFile } from "../../api";
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { SelectField } from "../HtmlComponents";

import UploadFile from "./UploadFile";
import FileListItem from './FileListItem';
import LoadingScreen from '../../components/global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import SelectFieldGroup from '../HtmlComponents/Fields/SelectFieldGroup';


const GenralFiles = () => {
     const [files, setFiles] = useState([]);     //ALL FILES FROM ALL FOLDERS (UNDER SAME ENTITY TYPE)
     const [processing, setProcessing] = useState(true);        //IF UPDATING OR DELETING A FILE
     //const [foldersGroup, setFoldersGroup] = useState([]);
     const [selectedFolderId, setSelectedFolderId] = useState(0);

     const entityType = useEntityType();
     const entityId = useEntityId();
     const userRole = useRole();
     const token = useToken();

/*
     const fetchGeneralFolders = async () => {
          var tmpFolderGroups = [];
          var generalFolders = await getGeneralFolders(token);
          if (generalFolders["error"] == 0) {
               Object.keys(generalFolders["folders"]).map(singleFolderGroupIndex => {

                    let tmpFolderGroup = { label: generalFolders["folders"][singleFolderGroupIndex]["label"], options: [] };
                    var singleFolderGroupOptions = generalFolders["folders"][singleFolderGroupIndex]["children"];

                    if (Array.isArray(singleFolderGroupOptions)) {
                         singleFolderGroupOptions.map(singleOption => {
                              tmpFolderGroup["options"].push({ label: singleOption["label"], value: singleOption["id"] })
                         })
                    }
                    tmpFolderGroups.push(tmpFolderGroup);
               });
          }
          setFoldersGroup(tmpFolderGroups);
     }*/

     const fetchFiles = async () => {
          var response = await getGeneralFiles(token);
          setProcessing(false);
          setFiles(response["data"]);
     }

     const updateHandler = async (name, value) => {
          switch (name) {
               case "updateFileFolder":
                setProcessing(true);
                var currentFolderId = value.currentFolderId;
                var fileObj = value.file;
                var response = await updateGeneralFile(token, fileObj.id, fileObj)

                //FOLDER IS UPDATED SUCCESSFULLY   
                if (response["error"] !== undefined && response["error"] === 0) {
                    //UPDATE THE FILES STATE
                    var updatedFilesState = files.map(file => {
                        if (file.id === fileObj.id) {
                            return { ...file, generalFolderId: fileObj.folderId }
                        }
                        return file;
                    })
                    setFiles(updatedFilesState);
                    Notifications("success", "Updated Successfully")
                }
                else {
                    //SOME ERROR OCCURED ABD FOLDER COULD NOT UPDATED
                    Notifications("error", response["message"] || "Could not update the folder.")
                }
                setProcessing(false);
                break;
               case "deleteFile":
                    setProcessing(true);
                    var fileObj = value.file;

                    var response = await deleteGeneralFile(token, fileObj.id)
                    if (response["error"] == undefined) {
                         //UPDATE THE FILES STATE
                         fetchFiles();
                         Notifications("success", "Deleted Successfully")
                    }
                    else {
                         //SOME ERROR OCCURED ABD FOLDER COULD NOT UPDATED
                         Notifications("error", response["message"] || "Could not perform this action.")
                    }
                    setProcessing(false);
                    break;
               case "refreshFileListing":
                    fetchFiles();
                    break;
          }
     }

     useEffect(() => {
          fetchFiles();
          //fetchGeneralFolders();
     }, [])

     useEffect( () => {
          console.log("USE EFFECT EXECUTED FORM GENERAL FILE JS LINE 85 selectedFolderId::  ", selectedFolderId);
     })

     
     useEffect( () => {
          console.log("USE EFFECT EXECUTED FORM GENERAL FILE JS LINE 90 selectedFolderId  TIGGED WHEN IT CHANGES ::  ", selectedFolderId);
     }, [selectedFolderId])

     return (
          <>
               <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                    {userRole == "admin" ? <>

                         { /*
                         <div style={{ marginBottom: "20px" }}>
                              <SelectFieldGroup
                                   fieldName="folder"
                                   options={foldersGroup}
                                   defaultSelectedOption={ { value: 5, label: "" }  }
                                   setFieldValue={(name, value) => { setSelectedFolderId(value) }}
                              />
                         </div> */ }
                         <UploadFile entityId={entityId} entityType={entityType} callback={updateHandler} generalFiles={true} selectedFolderId={0} fileType="file" folders={[]} />
                    </>
                         : ""}
                    <div className="card" data-select2-id="select2-data-132-dpd0">
                         <div>
                              <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer card-body">
                                   <div className="table-responsive" style={{ overflow: "unset" }}>
                                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                             <thead>
                                                  <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                       <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Name</th>
                                                       <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th> 
                                                       <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Actions</th>
                                                  </tr>
                                             </thead>
                                             <tbody className="fw-bold text-gray-600">
                                                  {
                                                       files && Array.isArray(files) && files.length > 0 ?
                                                            files.map(singleFile => (
                                                                 <FileListItem key={singleFile.id} singleFile={singleFile} filesCallback={updateHandler} updateFilesAllowed={  false} deleteFilesAllowed={userRole == "admin" ? true : false} fileType="generalFile" />
                                                            )) : <tr>
                                                                 <td colSpan={5}> <p> No File found, Please upload</p> </td>
                                                            </tr>
                                                  }
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

          </>

     )
}


export default GenralFiles;
