import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getReferrerGroupManagers } from '../../api';
import ReferrerGroupManagerListItem from './ReferrerGroupManagerListItem';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom';
import Notifications from '../global/Notifications';

import AddReferrerGroupManager from './AddReferrerGroupManager';
import UpdateReferrerGroupManager from './UpdateReferrerGroupManager';
import AddUser from '../users/AddUser';

import RightDrawer from "../HtmlComponents/modals/RightDrawer";


const ListReferrerGroupManagers = () => {

    const [referrerGroupManagersList, setReferrerGroupManagersList] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });

    const token = useToken();
    const entityId = useEntityId();
    const entityType = useEntityType();
    const role = useRole();

    let navigate = useNavigate();

    useEffect(() => {
        fetchReferrerGroupManagersList();
    }, []);

    //FETCH LIST OF REFERRER GROUP MANAGERS
    const fetchReferrerGroupManagersList = async () => {
        const result = await getReferrerGroupManagers(token);
        console.log("LIST REFERRER GROUPS managers", result);
        if (result["error"] == 0 && result["data"] && result["data"]["referrerGroupManagers"] && Array.isArray(result["data"]["referrerGroupManagers"])) {
            setReferrerGroupManagersList(result["data"]["referrerGroupManagers"]);
        }
        else {  Notifications("error", result["message"] !== undefined ? result["message"] : "Request failed.");  }
    }

    const CreateReferrerGroupManagerButtonHTML = () => {
        if ( (entityType == "creditRep" && role == "admin") || role === "superAdmin") {
            return (
                <>
                <div className='row'>
                    <div className='cols-m-12'>
                    <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => {  handler("openAddreferrerGroupmanagerDraw") }} buttonText="Create" />
                    </div>
                </div>
                </>
            )
        }
        else {
            return "";
        }
    }

    const handler = (actionName, payload = {}) => {
        switch (actionName) {
            case "openAddreferrerGroupmanagerDraw":
                setDrawerSettings({ visible: true, heading: "Add Referrer Group Manager", childName: "addReferrerGroupManager", meta: { width: "800" } })
                break;
            case "openUpdatereferrerGroupManagerDraw":
                setDrawerSettings({ visible: true, heading: "Update Referrer Group Manager", childName: "updateReferrerGroupManager", meta: { width: "800", referrerGroupManagerId: payload["referrerGroupManagerId"] } })
                break;
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "updatePayloadOnAddreferrerGroupManager":
                fetchReferrerGroupManagersList();
                setDrawerSettings({ ...drawerSettings, visible: false });
                break;

        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <CreateReferrerGroupManagerButtonHTML />

                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>User Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Email</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Mobile #</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }}>Assigned Referrer Groups</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            referrerGroupManagersList && Array.isArray(referrerGroupManagersList) ?
                                            referrerGroupManagersList.map(singleReferrerGroupManager => (
                                                    <ReferrerGroupManagerListItem key={singleReferrerGroupManager.id} referrerGroupManager={singleReferrerGroupManager}  callback={handler} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {drawerSettings["visible"] ?
                    <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={handler}>
                        {drawerSettings["childName"] === "addReferrerGroupManager" ? <AddReferrerGroupManager   callback={(data) => { handler("updatePayloadOnAddreferrerGroupManager", data) }} /> : ""}
                        {drawerSettings["childName"] === "updateReferrerGroupManager" ? <UpdateReferrerGroupManager  referrerGroupManagerId={drawerSettings["meta"]["referrerGroupManagerId"]} callback={(data) => { handler("updatePayloadOnAddreferrerGroupManager", data) }} /> : ""}

                    </RightDrawer> : ""
                }

        </>
    )
}

export default ListReferrerGroupManagers;