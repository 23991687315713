import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFile, uploadGeneralFile } from "../../api";
import { useToken, useEntityType } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

/**
 * generalFiles  => if it is undefined then call methods from fileApi otherwisegeneralFileApi
 * 
 */
function UploadFile({ entityType, entityId, generalFiles, folderIdRequired, selectedFolderId, fileType, callback }) {
    let token = useToken();
    const [files, setFiles] = useState([]);
    const userEntityType = useEntityType();
    const fileLimit = 1; //generalFiles ? 1 : 10;
    const [localSelectedFolderId, setLocalSelectedFolderId] = useState(-1);

    useEffect(() => {
    }, [localSelectedFolderId])

    const uploadFileToServer = async (fileObj) => {
        //EXECUTE IF GENERAL FILE IS SELECTED
        if (generalFiles !== undefined) {
            const response = await uploadGeneralFile(token, fileType, selectedFolderId, fileObj);
            if (response["error"] !== undefined && response["error"] == 1) { Notifications("error", response["message"] || "Error occoured uploading this file."); }
            callback("refreshFileListing", "");
        }
        //IF folderIdRequired SET TO TRUE
        else if (folderIdRequired) {
            if (selectedFolderId !== undefined && selectedFolderId !== null && !isNaN(parseInt(selectedFolderId)) && parseInt(selectedFolderId) > 0) {
                var fourthParam = selectedFolderId;
                if (userEntityType == "referrer") {
                    var selectedReferrerId = localStorage.getItem("selectedReferrer");
                    //thirdParam = entityId + `?referrerId=${selectedReferrerId}`;
                    fourthParam = selectedFolderId + `?referrerId=${selectedReferrerId}`;
                }

                const response = await uploadFile(token, entityType, entityId, fourthParam, fileObj);
                if (response["error"] !== undefined && response["error"] == 1) { Notifications("error", response["message"] || "Error occoured uploading this file."); }
                callback("refreshFileListing", "");
            }
            else {
                let message = 'Please select the folder first.';
                Notifications("error", message, message)
            }
        }
        else {
            //ADD QUERY PARAMS TO LAST PARAM IF REFERRER USER 
            var fourthParam = 0;
            if (userEntityType == "referrer") {
                var selectedReferrerId = localStorage.getItem("selectedReferrer");
                //thirdParam = entityId + `?referrerId=${selectedReferrerId}`;
                fourthParam = selectedFolderId + `?referrerId=${selectedReferrerId}`;
            }

            const response = await uploadFile(token, entityType, entityId, fourthParam, fileObj);
            if (response["error"] !== undefined && response["error"] == 1) { Notifications("error", response["message"] || "Error occoured uploading this file."); }
            callback("refreshFileListing", "");
        }
    }

    //USE CALLBACK 
    const onDrop = useCallback((acceptedFiles) => {
        setLocalSelectedFolderId(selectedFolderId);
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            const data = new FormData()
            data.append('file', file);

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (event) => {
                uploadFileToServer(data);
            }
            reader.readAsDataURL(file);
        })

    }, [selectedFolderId])

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        maxFiles: fileLimit,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/pdf': [],
            'application/msword': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-powerpoint': [], 'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
        },
        onDrop
    });
    const style = useMemo(() => ({ ...baseStyle, ...(isFocused ? focusedStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {}) }), [isFocused, isDragAccept, isDragReject]);


    const acceptedFilesListing = acceptedFiles.map(file => (
        <div key={file.path} className="uploadFileListing">
            {file.path} - {file.size / (1000 * 1000) < 1 ? (file.size / 1000) + "KB" : file.size / (1000 * 1000) + "MB"}
        </div>
    ));


    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div className=" uploadFileContainer">
                        <div><i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i></div>
                        <div >
                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                            <span className="fs-7 fw-semibold text-gray-400">Upload up to {fileLimit} files synchronously</span>
                        </div>
                    </div>
                </div>
                <div>
                    {acceptedFilesListing}
                </div>
            </div>

        </>
    );
}

export default UploadFile;