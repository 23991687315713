

const AnalystCommissionStructure = () => {

    
    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="bold mb-4">My Commission Structure</div>
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">Gross Income </th>
                                    <th className="p-0 pb-3 min-w-175px text-start">% of Gross Paid</th>
                                </tr>
                            </thead>
                            <tbody className="fw-bold">
                                <tr>
                                    <td>$ -</td>
                                    <td>0.00 %</td>
                                </tr>
                                <tr>
                                    <td>$ 13,500</td>
                                    <td>9.00 %</td>
                                </tr>
                                <tr>
                                    <td>$ 18,000</td>
                                    <td>10.00 %</td>
                                </tr>
                                <tr>
                                    <td>$ 27,000</td>
                                    <td>11.00 %</td>
                                </tr>
                                <tr>
                                    <td>$ 36,000</td>
                                    <td>12.50 %</td>
                                </tr>
                                <tr>
                                    <td>$ 45,000</td>
                                    <td>13.50 %</td>
                                </tr>
                                <tr>
                                    <td>$ 56,000</td>
                                    <td>15.00 %</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalystCommissionStructure;
