import axios from './axios';

 
 
/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getFolders = async (token, entityType ) => {
  const response = await axios.get(`folder/${entityType}`, { headers: { Authorization: "Bearer " + token } });
  return response.data ? response.data : [];

}
 

export { getFolders };