import axios from '../api/axios';

const createEmailTemplate = async(token, emailTemplate) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.post(`emailTemplate` , emailTemplate, { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data, errorMessage: e.response.data.errorMessage || [] }
         }
    }
}

const updateEmailTemplate = async(token, emailTemplate, emailTemplateId) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.put(`emailTemplate/${emailTemplateId}` , emailTemplate, { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0 , httpCode: response.status, "message" : "", emailTemplate: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data,  errorMessage: e.response.data.errorMessage || [] }
         }
    }
}


//GET EMAIL TEMPLATES
const getEmailTemplate = async(token, emailTemplateId) => {
    try{
        const response = await axios.get(`emailTemplate/${emailTemplateId}`, { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

//GET EMAIL TEMPLATES
const getEmailTemplates = async(token) => {
    try{
        const response = await axios.get(`emailTemplate`, { headers: { Authorization: "Bearer " + token } });
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

export {createEmailTemplate , updateEmailTemplate,  getEmailTemplate, getEmailTemplates}