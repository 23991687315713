import { useRef, useLayoutEffect } from 'react';

/*
MODAL BACKDROP Z-INDEX = 101
DRAW Z-INDEX  = 102
REACT CONFIRM ALERT OVERLAY = 103
*/

const RightDrawer = ({ heading, children, width, callback }) => {
    const headerStyleRef = useRef(null);
    //const drawWidth = width !== undefined ? width + "px" : "500px"; CUSTOM WITH REMOVED AND CSS CLASS SHOULD DEAL THIS NOW

    return (
        <>
            <div className="bg-body drawer mobile-drawer drawer-end drawer-on" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close" style={{ zIndex: "102" }} >
                <div className="card w-100 rounded-0 border-0">

                    <div className="card-header">
                        <h3 className="card-title fw-bold text-gray-700">{heading}</h3>
                        <div className="card-toolbar">
                            <button type="button" className="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6" id="kt_engage_demos_close" onClick = {  (e) =>{ callback("closeDrawer", {}) }}>
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className='card-body rightDraw-body'>
                        <div className=''>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>

        </>
    );
}

export default RightDrawer;
