import axios from '../api/axios';

 /* LIST ALL REFERRERS AGAINST THE USER PARENT ID ( CREDIT REP ID | COMPANY ID) - FILTER => REFERRER NAME OR REFERRER EMPLOYEE NAME */
const getReferrersList = async (token, status, filter = "") => {
  try{
    const response = await axios.get(`referrer/filters/${status}/${filter}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Fetched successfully", referrers: response.data }
  }
  catch(e){
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" , referrers: [] }
    }
  }
}

const getReferrersListAgainstReferrerGroup = async (token, referrerGroupId) => {
    const response = await axios.get(`referrer/referrerGroup/${referrerGroupId}`, { headers: { Authorization: "Bearer " + token } });
    return response.data ? response.data : [];
  
  }
  
//GET SINGLE REFERRER
const getReferrer = async (referrerId, token) => {
  try {
    const response = await axios.get(`referrer/${referrerId}`, { headers: { Authorization: "Bearer " + token } });
    console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrer: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

//CREATE REFERRER
const createReferrer = async (referrer, token) => {
  try {
    const response = await axios.post("referrer", referrer, { headers: { Authorization: "Bearer " + token } });
    console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrer: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

const createReferrerRCTI = async (payload, token, query="") => {
  try {
    const response = await axios.post(`referrer/rcti/${query}`, payload, { headers: { Authorization: "Bearer " + token } });
    console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
    return { "error": response.data["error"] || 0, httpCode: response.status, "message": response.data["message"] || "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

//MAKE A POST CALL TO GET PDF FILE THAT OCNTAINS GIFT CARD RECIPIENTS, AMOUNT, AND REFERRER ASSIGNED TO EACH RECIPIENT
const createReferrerGiftCardReport = async (payload, token) => {
  try {
    const response = await axios.post("referrer/giftCardRecipients/", payload, { headers: { Authorization: "Bearer " + token } });
    console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
    return { "error": response.data["error"] || 0, httpCode: response.status, "message": response.data["message"] || "Created successfully", referrerGroup: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

//UPDAET REFERRER
const updateReferrer = async (referrer, referrerId, token) => {
  try {
    const response = await axios.put(`referrer/${referrerId}`, referrer, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", referrer: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

//DELETE REFERRER
const deleteReferrer = async(token, referrerId) => {
  try {
    const response = await axios.delete(`referrer/${referrerId}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, message: response.data.message ||  "Deleted successfully......."}
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}


//LIST ALL INVOICES THAT ARE NOT PUSHED TO XERO YET
const listAllReferrerInvocies = async(token, year, month) => {
  try {
    const response = await axios.get(`referrer/listAllInvocies/${year}/${month}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
} 

//submitInvociesToXero
const submitReferrerInvociesToXero = async(token, year, month) => {
  try {
    const response = await axios.post(`referrer/submitInvociesToXero/${year}/${month}`,  {},  { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

export { getReferrersList, getReferrersListAgainstReferrerGroup , getReferrer, createReferrer,  
  updateReferrer , deleteReferrer,  createReferrerRCTI , createReferrerGiftCardReport,
  listAllReferrerInvocies, submitReferrerInvociesToXero};