import * as Yup from 'yup';


const AddReferrerGroupManagerSchema =  Yup.object().shape({
    userName : Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Required'),
    firstName : Yup.string().required('Required'),
    surname : Yup.string().required('Required'),
    email : Yup.string().email("Must be valid email").required('Required'),
    password :  Yup.string().required('No password provided.').min(8, 'Password is too short - should be 8 chars minimum.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    contactNumber :  Yup.string().matches(/^[0-9 ]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
    landlineNumber :  Yup.string().matches(/^[0-9 ]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
});

const UpdateReferrerGroupManagerSchema =  Yup.object().shape({
    firstName : Yup.string().required('Required'),
    surname : Yup.string().required('Required'),
    email : Yup.string().email("Must be valid email").required('Required'),
    password :  Yup.string().min(8, 'Password is too short - should be 8 chars minimum.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.').nullable(),
    contactNumber :  Yup.string().matches(/^[0-9 ]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
    landlineNumber :  Yup.string().matches(/^[0-9 ]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
});

export { AddReferrerGroupManagerSchema ,  UpdateReferrerGroupManagerSchema} ;