

const TextArea = ({fieldName, placeholder , fieldValue, setFieldValue}) => {
    return (
        <>
            <textarea 
                type="text"
                className="form-control form-control-solid"
                rows = {3}
                style={{ height: "90px", fontWeight: "400", border : "1px solid #e2d9d9", backgroundColor: "white" }}
                placeholder={placeholder}
                aria-label={placeholder}
                value= {fieldValue}
                onChange={(e) => {
                    setFieldValue(fieldName, e.target.value) 
                }}
            />
        </>
    )
}

export default TextArea;