

import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getAnalystBudgetReport , getAnalystGrossIncomeReport } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";
import { formatDate , countMonthDays , countMonthDaysElapsed} from "../../../../helpers";

const AnalystBudgetReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [budgetReport, setBudgetReport] = useState([]);
    const [grossIncomeReport, setGrossIncomeReport] = useState({});
    const token = useToken();

    const fetchBudgetReport = async() => {
        setProcessing(true);
        var response = await getAnalystBudgetReport(token, startDate, endDate);
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            setBudgetReport(response["data"]);
        }
        setProcessing(false);
    }

    const fecthAnalystGrossIncome =  async() => {
        var response = await getAnalystGrossIncomeReport(token, startDate, endDate);
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined) {
            setGrossIncomeReport(response["data"]);
        }

    }

    useEffect(() => {
        fetchBudgetReport();
        fecthAnalystGrossIncome();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                <div className="bold mb-4">Budget Target</div>
                    {processing ? <LoadingBlock /> :
                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-175px text-start">Date </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Budget Target </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Req Avg/Day </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Cur Avg/Day(Gross) </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Month Total/Curr Rate</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                    {
                                        Array.isArray(budgetReport) ?
                                        budgetReport.map(singleRecord => {
                                                return <>
                                                    <tr >
                                                        <td>{ formatDate(singleRecord["date"], "unix" , "MMM YYYY")  }</td>
                                                        <td>$ {singleRecord["amount"]}</td>
                                                        <td>$ { (singleRecord["amount"]/countMonthDays(formatDate(singleRecord["date"], "unix" , "YYYY-MM"))).toFixed(2)}</td>
                                                        <td>$ {  
                                                            grossIncomeReport["grossIncome"] !== undefined ? 
                                                            (grossIncomeReport["grossIncome"] /countMonthDaysElapsed(formatDate(singleRecord["date"], "unix" , "YYYY-MM"))).toFixed(2)
                                                             : 0
                                                        } </td>

                                                        <td>
                                                        $ {  
                                                            grossIncomeReport["grossIncome"] !== undefined ? 
                                                            ((grossIncomeReport["grossIncome"] /countMonthDaysElapsed(formatDate(singleRecord["date"], "unix" , "YYYY-MM")))* countMonthDays(formatDate(singleRecord["date"], "unix" , "YYYY-MM")) ).toFixed(2)
                                                             : 0
                                                        }
                                                        </td>
                                                    </tr>
                                                </>
                                            })
                                            : "<tr></tr>"
                                    }
                                </tbody>

                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AnalystBudgetReport;