import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { getSettlement, getSignleApplicationOverview, getInsuranceCompaniesList, getUsers } from "../../api";
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';

const ViewSettlement = ({ settlementId }) => {
    const [singleSettlement, setSingleSettlement] = useState({});
    const [applicantOverview, setApplicantOverview] = useState({});

    const [analysts, setAnalysts] = useState([]);
    const [referrerUsers, setReferrerUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const [insurancesCheckboxes, setInsurancesCheckboxes] = useState({ comprehensive: false, cci: false, warrenty: false, gap: false, lti: false, rti: false });
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const token = useToken();

    useEffect(() => {
        //fetch insurance companies
        fetchSettlement();
        fetchInsuranceCompanies();
    }, []);


    //FETCH SETTLEMENT FROM API - API CALL
    const fetchSettlement = async () => {
        const data = await getSettlement(settlementId, token);
        if (data["error"] !== undefined && data["error"] == 0) {

            fetchApplicationOverview(data["settlement"]["applicationId"] !== undefined ? data["settlement"]["applicationId"] : 0);
            //SETTLEMENT DATE
            data["settlement"]["settlementDate"] = data["settlement"]["settlementDate"] !== undefined && data["settlement"]["settlementDate"] !== 0 ? formatDate(data["settlement"]["settlementDate"], 'unix', 'DD/MM/YYYY') : "";

            //INSURANCE START
            var settlementInsurances = data["settlement"]["insurances"];
            var localInsurances = JSON.parse(JSON.stringify(insurancesCheckboxes));

            //ADDING UNIQUE KEY TO EACH INSURANCE
            if (Array.isArray(settlementInsurances)) {
                data["settlement"]["insurances"] = settlementInsurances.map((insurance) => {
                    localInsurances[insurance["type"]] = true;
                    //CONVERT INSURANCE UNIX DATE INTO DD/MM/YYYY FORMAT AND UPDATE THE SETTLEMENT PAYLOAD AND THEN LOAD THAT INTO SINGLE-SETTLEMENT STATE
                    insurance["expiryDate"] = formatDate(insurance["expiryDate"], 'unix', 'DD/MM/YYYY');
                    //insurance["uid"] = uuid();
                    return insurance;
                })
            }
            //INSURANCE END
            var creditRepId = data["settlement"]["additionalInfo"]["creditRepId"];
            var referrerId = data["settlement"]["additionalInfo"]["referrerId"];

            //CHECK IF IT HAS analystCommission BLOCK AND IF IT HAS LESS THAN 2 RECORDS THEN UPDATE IT TO MAKE SURE THAT IT HOLDS MIN 2 RECORDS
            var analystCommission = data["settlement"]["analystCommission"];
            var analystCommissionRecordsCount = analystCommission.length;

            if (analystCommissionRecordsCount === 0) { analystCommission = [{ "analystId": 0, "commission": "0.00" }, { "analystId": 0, "commission": "0.00" }]; }
            else if (analystCommissionRecordsCount === 1) { analystCommission.push({ "analystId": 0, "commission": "0.00" }); }

            //ASSIGN THE ANALYST COMMISSION BACK TO DATA
            data["settlement"]["analystCommission"] = analystCommission;


            //GIFT CARDS - CHECK IF IT HAS GIFT CARDS BLOCK AND IF IT HAS LESS THAN 2 RECORDS THEN UPDATE IT TO MAKE SURE THAT IT HOLDS MIN 2 RECORDS
            var giftCardReceivers = data["settlement"]["giftCardReceivers"];
            var giftCardReceiversRecordsCount = giftCardReceivers.length;
            if (giftCardReceiversRecordsCount === 0) { giftCardReceivers = [{ "userId": 0, "amount": 0 }, { "userId": 0, "amount": 0 }]; }
            else if (giftCardReceiversRecordsCount === 1) { giftCardReceivers.push({ "userId": 0, "amount": 0 }); }
            data["settlement"]["giftCardReceivers"] = giftCardReceivers;


            //UPDATE SETTLEMENT AND INSURANCE STATE
            setInsurancesCheckboxes({
                ...insurancesCheckboxes, comprehensive: localInsurances["comprehensive"], cci: localInsurances["cci"],
                warrenty: localInsurances["warrenty"], gap: localInsurances["gap"], lti: localInsurances["lti"], rti: localInsurances["rti"]
            });
            setSingleSettlement(data["settlement"]);

            //FETCH BROKERS AND REFERRER USERS
            fetchAnalysts(creditRepId);
            fetchReferrerUsers(referrerId);

        } else {
            Notifications("error", data["message"])
        }
        setLoading(false);
    }

    //FETCH APPLICATION OVERVIEW - PRIMARY APPLICANT NAME, LENDER, REFERRER, REFERRER GROUP NAME
    const fetchApplicationOverview = async (applicationId) => {
        if (applicationId !== 0) {
            var response = await getSignleApplicationOverview(applicationId, token);
            if (response["error"] != undefined && response["error"] == 0) {
                setApplicantOverview(response["data"]);
            }
        }
        else { setApplicantOverview({}); }

    }

    //FETCH INSURANCE COMPANIES - API CALL
    const fetchInsuranceCompanies = async () => {
        var response = await getInsuranceCompaniesList(token);
        if (response["error"] != undefined && response["error"] == 0 && response["httpCode"] !== undefined && response["httpCode"] == 200) {
            setInsuranceCompanies(response["data"] || []);
        }
    }

    //FETCH BROKERS
    const fetchAnalysts = async (creditRepId) => {
        var response = await getUsers(token, "creditRep", creditRepId);
        if (response["error"] === 0) {
            var data = response["data"];
            if (Array.isArray(data)) {
                var options = data.map(singleUSer => {
                    return { value: singleUSer["id"], label: singleUSer["firstName"] + " " + singleUSer["surname"] }
                })
                setAnalysts(options);
            }
        }
    }

    //FETCH REFERRER STAFF FOR GIFT CARD RECEIVER
    const fetchReferrerUsers = async (referrerId) => {
        var response = await getUsers(token, "referrer", referrerId);
        if (response["error"] === 0) {
            var data = response["data"];
            if (Array.isArray(data)) {
                var options = data.map(singleUSer => {
                    return { value: singleUSer["id"], label: singleUSer["firstName"] + " " + singleUSer["surname"] }
                })
                setReferrerUsers(options);
            }
        }
    }



    //SETTLEMENT BASIC DETAIL BLOCK
    const BasicDetails = () => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div style={{ display: "flex" }}>
                        <h3 className="pb-0 mb-0">Settlement Details</h3>
                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> {singleSettlement !== undefined && singleSettlement["insuranceOnly"] !== undefined && singleSettlement["insuranceOnly"] == 1 ? "Insurance Only" : ""} </span>
                    </div>
                </div>
                <div className="separator separator-dashed my-5"></div>
                <div className="position-relative z-index-1">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Settlement Date</label>
                            <div><span>{singleSettlement["settlementDate"] !== undefined ? singleSettlement["settlementDate"] : ""}</span></div>
                        </div>
                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Loan Contract #</label>
                            <div><span>{singleSettlement["loanContractNumber"] !== undefined ? singleSettlement["loanContractNumber"] : ""}</span></div>
                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Total Financed Amount</label>
                            <div> <span>{singleSettlement["totalFinancedAmount"] !== undefined ? "$ " + singleSettlement["totalFinancedAmount"] : ""}</span></div>
                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Interest Rate %</label>
                            <div><span>{singleSettlement["interestRate"] !== undefined ? singleSettlement["interestRate"] : ""}</span></div>
                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Term(Months)</label>
                            <div> <span>{singleSettlement["term"] !== undefined ? singleSettlement["term"] : ""}</span></div>
                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Finance Commission</label>
                            <div> <span>{singleSettlement["financeCommission"] !== undefined ? "$ " + singleSettlement["financeCommission"] : ""}</span></div>

                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Origination Fee</label>
                            <div> <span>{singleSettlement["originationFee"] !== undefined ? "$ " + singleSettlement["originationFee"] : ""}</span></div>
                        </div>

                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Brokerage Fee</label>
                            <div> <span>{singleSettlement["brokerageFee"] !== undefined ? "$ " + singleSettlement["brokerageFee"] : ""}</span></div>
                        </div>
                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Referrer Group Commission</label>
                            <div> <span>{singleSettlement["referrerGroupCommission"] !== undefined ? "$ " + singleSettlement["referrerGroupCommission"] : ""}</span></div>
                        </div>
                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="  fs-8 fw-bold mb-2">Referrer Commission</label>
                            <div> <span>{singleSettlement["referrerCommission"] !== undefined ? "$ " + singleSettlement["referrerCommission"] : ""}</span></div>
                        </div>
                        {GiftCardReceiverHtmlBlock()}
                        <div className="col-sm-12"></div>
                        {BrokersHtmlBlock()}
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                            <label className="fs-8 fw-bold mb-2">Total Commission</label>
                            <div> <span>{singleSettlement["totalCommission"] !== undefined ? singleSettlement["totalCommission"] : ""}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }


    const GetInsuranceCompanyName = ({ companyId, type }) => {
        if (Array.isArray(insuranceCompanies)) {
            var foundCompany = insuranceCompanies.find(singleInsuranceCompany => singleInsuranceCompany.id == companyId && singleInsuranceCompany.type == type);
            var name = foundCompany !== undefined && foundCompany["name"] !== undefined ? foundCompany["name"] : "NA";
            return name;
        }
        else { return "NA"; }
    }
    //INSURANCES HTML BLOCK
    const Insurances = () => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <h3 className="pb-0 mb-0">Insurances</h3>
                </div>
                <div className="separator separator-dashed my-5"></div>
                <div className="position-relative z-index-1">
                    <div className='row mb-5'>
                        {
                            singleSettlement !== undefined && singleSettlement.insurances !== undefined && Array.isArray(singleSettlement.insurances) ?
                                singleSettlement.insurances.map(singleInsurance => {
                                    return (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-sm-3">
                                                    <label className=" fs-8 fw-bold mb-2"> {singleInsurance["type"].toUpperCase()} Company </label>
                                                    <div><span> <GetInsuranceCompanyName companyId={singleInsurance["insuranceCompanyId"]} type={singleInsurance["type"]} /> </span></div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className=" fs-8 fw-bold mb-2"> Commission </label>
                                                    <div><span>$ {singleInsurance["commission"]}</span></div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className=" fs-8 fw-bold mb-2"> Premium </label>
                                                    <div><span>$ {singleInsurance["grossPremium"]}</span></div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className=" fs-8 fw-bold mb-2"> Expiry Date </label>
                                                    <div><span>{singleInsurance["expiryDate"]}</span></div>
                                                </div>
                                                {
                                                    singleInsurance["type"] == "warrenty" ?
                                                        <div className="col-sm-3">
                                                            <label className=" fs-8 fw-bold mb-2"> Net Premium </label>
                                                            <div><span>$ {singleInsurance["netPremium"]}</span></div>
                                                        </div>
                                                        : ""
                                                }
                                                <div className="separator separator-dashed my-5"></div>
                                            </div>
                                        </>
                                    )
                                })

                                : ""

                        }





                    </div>
                </div>
            </div>
        </>)
    }

    const GetAssignedGiftCardUser = ({ giftCarRecipientId }) => {

        if (Array.isArray(referrerUsers)) {
            var foundUser = referrerUsers.find(singleUser => singleUser.value == giftCarRecipientId);
            var label = foundUser !== undefined && foundUser["label"] !== undefined ? foundUser["label"] : "NA";
            return label;
        }
        else { return "NA"; }
    }
    //GIFT CAR RECEIVERS
    const GiftCardReceiverHtmlBlock = () => {
        return (<>
            {
                singleSettlement !== undefined && singleSettlement.giftCardReceivers !== undefined ?
                    singleSettlement.giftCardReceivers.map((giftCardReceiver, index) => {
                        return (
                            <>
                                <div className="col-md-4 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                                    <label className=" fs-8 fw-bold mb-2">Gift Card Receiver {parseInt(index) + 1}</label>
                                    <div> <span>
                                        <GetAssignedGiftCardUser giftCarRecipientId={giftCardReceiver["userId"] !== undefined ? giftCardReceiver["userId"] : 0} />
                                    </span></div>
                                </div>
                                <div className="col-md-4 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                                    <label className=" fs-8 fw-bold mb-2">Gift Card Amount</label>
                                    <div> <span>{giftCardReceiver["amount"] !== undefined ? "$ " + giftCardReceiver["amount"] : "$ 0"}</span></div>
                                </div>
                            </>
                        )
                    }) :
                    ""
            }
        </>)
    }



    const GetAssignedAnalystUser = ({ analystId }) => {

        if (Array.isArray(analysts)) {
            var foundUser = analysts.find(singleUser => singleUser.value == analystId);
            var label = foundUser !== undefined && foundUser["label"] !== undefined ? foundUser["label"] : "NA";
            return label;
        }
        else { return "NA"; }
    }
    //BROKERS | ANALYSTS
    const BrokersHtmlBlock = () => {
        return (<>
            {
                singleSettlement !== undefined && singleSettlement.analystCommission !== undefined ?
                    singleSettlement.analystCommission.map((singleAnalystCommission, index) => {
                        return (
                            <>
                                <div className="col-md-4 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                                    <label className=" fs-8 fw-bold mb-2">Analyst {parseInt(index) + 1}</label>
                                    <div> <span>
                                        <GetAssignedAnalystUser analystId={singleAnalystCommission["analystId"] !== undefined ? singleAnalystCommission["analystId"] : 0} />
                                    </span></div>
                                </div>
                                <div className="col-md-4 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                                    <label className=" fs-8 fw-bold mb-2">Analyst {parseInt(index) + 1} Finance Commission</label>
                                    <div> <span>{singleAnalystCommission["commission"] !== undefined ? "$ " + singleAnalystCommission["commission"] : "$ 0"}</span></div>
                                </div>
                                <div className='col-sm-12'></div>
                            </>
                        )
                    }) :
                    ""
            }
        </>)
    }

    const CommentSection = () => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <h3 className="pb-0 mb-0">Comments</h3>
                </div>
                <div className="separator separator-dashed my-5"></div>
                <div className="position-relative z-index-1">
                    {singleSettlement["comments"] !== undefined ? singleSettlement["comments"] : ""}
                </div>
            </div>
        </>)
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="card mb-5 mb-xxl-8">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div style={{ display: "flex" }}>
                            <h3 className="pb-0 mb-0">Overview</h3>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="position-relative z-index-1">
                            <div className="row">

                                <div className='col-sm-4'>
                                    <label className="  fs-8 fw-bold mb-2">First Name</label>
                                    <div><span>{applicantOverview.firstname !== undefined ? applicantOverview.firstname : ""}</span></div>
                                </div>
                                <div className='col-sm-4'>
                                    <label className="  fs-8 fw-bold mb-2">Surname</label>
                                    <div><span>{applicantOverview.surname !== undefined ? applicantOverview.surname : ""}</span></div>
                                </div>
                                <div className='col-sm-4'>
                                    <label className="  fs-8 fw-bold mb-2">Referrer Group</label>
                                    <div><span>{applicantOverview.referrerGroupName !== undefined ? applicantOverview.referrerGroupName : ""}</span></div>
                                </div>

                                <div className='col-sm-4'>
                                    <label className="  fs-8 fw-bold mb-2">Referrer</label>
                                    <div><span>{applicantOverview.referrerTradingName !== undefined ? applicantOverview.referrerTradingName : ""}</span></div>
                                </div>
                                <div className='col-sm-4'>
                                    <label className="  fs-8 fw-bold mb-2">Lender</label>
                                    <div><span>{applicantOverview.lenderName !== undefined ? applicantOverview.lenderName : ""}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-12">
                        <div className="row">
                            {BasicDetails()}
                        </div>
                        <div className="row">
                            <Insurances />
                        </div>
                    </div>
                    <div className="col-xl-12">
                        {CommentSection()}
                    </div>
                </div>
            </div>

            {loading ? <LoadingScreen /> : ""}
        </>
    )
}
export default ViewSettlement;