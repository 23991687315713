import { SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Field } from 'formik';

const LandDebt = ({prefix , setFieldValue , frequencyList }) => {
    return (
        <>
            <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Financial Institution</label>
                <Field name= { prefix+"financialInstitution"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"financialInstitution"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Asset Value</label>
                <Field name= { prefix+"assetValue"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"assetValue"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Amount Borrowed</label>
                <Field name= { prefix+"amountBorrowed"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"amountBorrowed"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Balance Remaining</label>
                <Field name= { prefix+"balanceRemaining"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"balanceRemaining"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Total Monthly Repayment</label>
                <Field name= { prefix+"totalMonthlyRepaymentAmount"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"totalMonthlyRepaymentAmount"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Your share of total monthly repayment </label>
                <Field name= { prefix+"applicantShareOfTotalMonthlyRepayment"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"applicantShareOfTotalMonthlyRepayment"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Frequency</label>
                <Field name=  {prefix+"repaymentFrequency"}>
                    {({ field }) => (
                        <SelectField
                            fieldName = {prefix+"repaymentFrequency"}
                            defaultSelectedOption={{ value: field.value !== undefined ? field.value : "", label: "" }}
                            options={frequencyList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Start Date</label>
                <Field name= { prefix+"startDate"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"startDate"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "MM/YYYY" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>   
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Term</label>
                <Field name= { prefix+"term"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"term"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Rate</label>
                <Field name= { prefix+"rate"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"rate"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
            </div>    
               
        </>
    )
}

export default LandDebt;