

module.exports = {
    ACCREDITATION_FORM_REFERRERS_LIST : {
        "NFAL": "NFAL",
        "mrMentor" : "Mr Mentor",
        "financeAndCoffee" : "Finance and Coffee",
        "tradiesFinance" : "Tradies Finance",
        "blankFinancial" : "Blank Financial",

        
    }
}