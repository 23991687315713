import * as Yup from 'yup';


const SettlementValidationSchema = Yup.object().shape({
    insuranceOnly: Yup.boolean(),
    loanContractNumber: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().trim('').required('Required'),
    }),
    totalFinancedAmount: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    }),
    interestRate: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(7, 'Must not be more than 7 digits').required('Required'),
    }),
    term: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(3, 'Must not be more than 3 digits').required('Required'),
    }),
    financeCommission: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    }),
    originationFee: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    }),
    brokerageFee: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    }),
    settlementDate: Yup.string().required("Required"),
    reinstateDate: Yup.string().when('insuranceOnly', {
        is: false,
        then: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, "Invalid date format (dd/mm/yyyy)").nullable(),
    })
});

export default SettlementValidationSchema;

/**
 * 
 *     totalFinancedAmount :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    financeCommission :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    originationFee :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    brokerageFee :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    referrerGroupCommission :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    referrerCommission :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    giftCardAmount :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits').required('Required'),
    interestRate :  Yup.string().matches(/^[0-9.]+$/, "Must be only digits").max(7, 'Must not be more than 7 digits').required('Required'),
    term :  Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(3, 'Must not be more than 3 digits').required('Required'),

 */