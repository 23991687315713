
import { useRole } from '../../hooks/useAuth';
import { formatDate } from '../../helpers';
import { useState } from 'react';
import { useEffect } from 'react';
import { IconButton } from '../HtmlComponents';

const SingleStaffBudget = ({ singleBudget, users, callback }) => {

    const userRole = useRole();
    const [usersSingleMonthBudget, setUsersSingleMonthBudget] = useState({})


    useEffect(() => {
        updateUsersSingleMonthBudget();
    }, [singleBudget])


    const updateUsersSingleMonthBudget = () => {
        if (singleBudget.usersBudget !== undefined && Array.isArray(JSON.parse(singleBudget.usersBudget))) {
            setUsersSingleMonthBudget(JSON.parse(singleBudget.usersBudget));
        }
    }

    return (
        <>
            <div className="col-sm-12 col-md-6">
                <div className="px-9 pt-7 card-rounded pb-4" style={{backgroundColor: "#f6f6f6"}} >
                    <div className="">
                        <div className='row'>
                            <div className='col-sm-12 mb-2'> 
                            <div style={{display: "flex", justifyContent:"space-between" }}>
                                <h4>Budget For: {formatDate(singleBudget.budgetDate, "unix", "MM/YYYY")} </h4>
                                <IconButton iconType="edit" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Edit Budget" buttonOnClick={() => {  callback("openUpdateStaffBudgetDrawer" , {budgetId:singleBudget.id }) }} />
                            </div>                            
                            </div>
                            
                            <div className='col-sm-12'>
                                    {
                                        Array.isArray(usersSingleMonthBudget) ?
                                            usersSingleMonthBudget.map((singleUserBudget) => {
                                                return (
                                                    <>
                                                    <div className='row'>
                                                        <div className='col-sm-6'> {users["userId" + singleUserBudget.userId] !== undefined && users["userId" + singleUserBudget.userId].name !== undefined ? users["userId" + singleUserBudget.userId].name : "NA"} </div>
                                                        <div className='col-sm-6'> {singleUserBudget.amount}</div>
                                                        </div>
                                                    </>
                                                )
                                            }) : ""
                                    }
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default SingleStaffBudget;