
import { useRole } from '../../../hooks/useAuth';
import { IconButton , CustomButton } from '../../HtmlComponents';
import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';

const SingleCpd = ({ cpd, userId , callback }) => {
 
    const userRole = useRole();
    return (
        <>
            <tr>
                <td>
                    <span>{ cpd["date"] !== undefined && cpd["date"] > 0 ? formatDate(cpd["date"], "unix", "DD/MM/YYYY") : ""} </span>
                    {
                        ['superAdmin', 'admin' , 'analyst'].includes(userRole) ?
                            <IconButton
                                iconType="edit"
                                buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                buttonOnClick={() => { callback("openUpdateDrawer", { cpdId: cpd.id , userId: userId }) }}
                                tooltipDescription=""
                                buttonStyle={{backgroundColor : "transparent"}}
                            /> : ""
                    }
                </td>
                <td><span>{ cpd.hours !== undefined ? cpd.hours  : ""}</span></td>
                <td><span>{ cpd.providerName !== undefined ? cpd.providerName  : ""}</span></td>
                <td><span>{ cpd.description !== undefined ? cpd.description  : ""}</span></td> 
            </tr> 
        </>
    )
}

export default SingleCpd;

