import { useRole } from './useAuth';
import PermissionRule from '../permissions/PermissionsRule.json';

/**
 * moduleName: (e.g lender , application, creditRep etc)
 * methodName: (e.g create, delete, update, view)
 * 
 * IN PERMISSION RULE JSON FILE, MAKE SURE THAT CHILD OF ROLE REPRESENTS THE ENTITY TYPE
 */
const useMethodAllowed = (moduleName, nestedModuleName, methodName) => {
    var isAllowed = false;
    let loggedInUserRole = useRole();

    //IF 3RD PARAM IS NOT PASSED , IT MEANS WE DO NOT NEED TO DIG INTO 2ND LEVEL, JUST CHECK AT 1ST LEVEL
    if (methodName === undefined) {
        methodName = nestedModuleName;
        nestedModuleName = undefined;
    }

    if (nestedModuleName !== undefined) {
        //CHECK METHOD VALUE FROM THE NESTED CHILD 
        //CHECK METHOD VALUE FROM THE 2ND LEVEL IN THE JSON (E.G [SUPERADMIN][LENDER][CONTACTS][CREATE])
        isAllowed = PermissionRule[loggedInUserRole] !== undefined && 
            PermissionRule[loggedInUserRole][moduleName] !== undefined &&            
            PermissionRule[loggedInUserRole][moduleName][nestedModuleName] !== undefined &&
            PermissionRule[loggedInUserRole][moduleName][nestedModuleName][methodName] !== undefined ?
            PermissionRule[loggedInUserRole][moduleName][nestedModuleName][methodName] : false;
    }
    else {
        //CHECK ONLY FIRST LEVEL CHILD IN THE JSON (E.G [SUPERADMIN][LENDER][CREATE])
        isAllowed = PermissionRule[loggedInUserRole] !== undefined && PermissionRule[loggedInUserRole][moduleName] !== undefined &&
            PermissionRule[loggedInUserRole][moduleName][methodName] !== undefined ?
            PermissionRule[loggedInUserRole][moduleName][methodName] : false;
    }

    /*
    console.log("USE METHOD ALOWED {role} {moduleName} --  {nestedModuleName} -- {methodName}  {isAllowed}:::", ` ${loggedInUserRole} -- ${moduleName} --  ${nestedModuleName} -- ${methodName}   --- ${isAllowed}`);
    console.log("USE ROLE:::", loggedInUserRole);*/

    return isAllowed;
}

export { useMethodAllowed }