
import { useState, useEffect } from 'react';
import { FormSubmissionButton, TextEditor, CustomButton, Modal, MaskedField, RadioGroup, SelectField, InputField } from '../../HtmlComponents';
import StandardTemplate from './emailTemplates/StandardTemplate';
import{sendEmail} from '../../../api';
import Notifications from '../../global/Notifications';
import LoadingScreen from '../../global/LoadingScreen';
import { isEmailValid, removeSpace } from '../../../helpers';
import { useToken } from '../../../hooks/useAuth';

const LenderWelcomeEmail = ({ payload, calllback }) => {   
    var referrerFirstName = payload["firstName"] !== undefined ? payload["firstName"] : "";
    var userName = payload["userName"] !== undefined ? payload["userName"] : "";
    var defaultPassword = payload["defaultPassword"] !== undefined ? payload["defaultPassword"] : "";
    var email = payload["email"] !== undefined ? payload["email"] : "";
    var cc = payload["cc"] !== undefined ? payload["cc"] : "";
    
    const [file, setFile] = useState({});

    const [emailpayload, setEmailpaylaod] = useState({ email: email, cc: cc,  subject: "", emailBody: "", emailtemplate: "" , senderLabel: "National Finance + Loans",  fileData: {name: "" , base64 : "", isExist: 0} });
    const [emailBodyInitialValue, setEmailBodyInitialValue] = useState("");
    const [processing, setProcessing]  = useState(false);

    const token = useToken();

    const handler = (name, value) => {
        switch (name) {
            case "templateChanged":
                switch (value) {
                    case "standardTemplate":
                        const nmbTemplate = StandardTemplate({ firstName: referrerFirstName, userName: userName, defaultPassword: defaultPassword })
                        setEmailpaylaod({ ...emailpayload, subject : "National Finance + Loans", emailBody: nmbTemplate , emailtemplate: value, });
                        setEmailBodyInitialValue(nmbTemplate);
                        break;
                        
                }
                break;
            case "email":
                setEmailpaylaod({ ...emailpayload, email: value });
                break;
            case "cc":
                setEmailpaylaod({ ...emailpayload, cc: value });
                break;
            case "subject":
                setEmailpaylaod({ ...emailpayload, subject: value });
                break;
            case "emailBody":
                setEmailpaylaod({ ...emailpayload, emailBody: value });
                break;

        }
    }




    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
          }
    }
    const convertFileToBase64 = () => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
        reader.onerror = reject;
    });





    const handleEmailSending = async() => {
        //CHECK IF VALID EMAIL AND SUBJECT IS PROVIDED AND BODY IS NOT EMPTY
        var error = 0;
        if(!isEmailValid(emailpayload["email"])){Notifications("error" ,  "Invalid Email Address");  error= 1; }

        //CHECK IF SUBJECT IS PROVIDED
        var tmpSubject = removeSpace(emailpayload["subject"]); 
        if(tmpSubject.length < 1){  Notifications("error" , " Subject is missing");  error= 1;  }
         
        //EMAIL BODY
        var tmpEmailBody = removeSpace(emailpayload["emailBody"]);
        if(tmpEmailBody.length < 1){  Notifications("error" , " Email Body missing");  error= 1;  }

        if(error === 0){  
            var tmpEmailPayload = JSON.parse(JSON.stringify(emailpayload));

            try{
                let fileName = file && file.name !== undefined ? file.name: 'File.pdf';
                let fileSize = file && file.size !== undefined ? file.size: 0;

                //IF FILE SIZE > 0 THEN CONVERT THE FILE IN BASE64 STRING
                if(fileSize > 0 ){
                    var base64String = await convertFileToBase64();    
                    tmpEmailPayload["fileData"]["name"] = fileName;
                    tmpEmailPayload["fileData"]["isExist"] = 1;
                    tmpEmailPayload["fileData"]["base64"] = base64String;
                }
            }
            catch(e){}
             
            setProcessing(true);
            var response = await sendEmail(token, tmpEmailPayload );

            if(response["data"]["error"] !== undefined && response["data"]["error"] == 0){
                Notifications("success" , response["data"]["message"] || "sent Successfully");
            }
            else{ Notifications("error", response["data"]["message"] || "Error" ) }
            setProcessing(false);
            
         }

    }
 

    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <InputField fieldName='email' placeholder="Receiver Email Address" fieldValue={emailpayload["email"]} setFieldValue={handler} />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <InputField fieldName='cc' placeholder="CC" fieldValue={emailpayload["cc"]} setFieldValue={handler} />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <SelectField fieldnName='templateSelection'
                                options={[ { value: "standardTemplate", label: "Standard Template" }]}
                                defaultSelectedOption={{ value: emailpayload["emailtemplate"], label: "" }}
                                setFieldValue={(fieldName, fieldValue) => { handler("templateChanged", fieldValue) }}
                            />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <InputField fieldName="subject"  placeholder="Email Subject" fieldValue={emailpayload["subject"]}  setFieldValue={handler}  />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <TextEditor  fieldName="emailBody" initialValue={emailBodyInitialValue} height={300} setFieldValue={handler} />
                        </div>

                        <div className='col-sm-12 mt-4'>
                            <input type="file" id="avatar" name="avatar" accept="application/pdf"   onChange={handleFileChange}  />
                            <div>{file && file.name !== undefined && file.type !== undefined ? `${file.name} - ${file.type}` : "" }</div>
                        </div>

                        <div className='col-sm-12 mt-5'  >
                            <div className="col-xl-12"> 
                            <CustomButton 
                            buttonClassName= "btn btn-sm btn-primary btn-hover-rise" 
                            isActive={true} 
                            buttonStyle = {{ float: "right" }}
                            buttonOnClick= { () => { handleEmailSending() }}
                            buttonText = "Send Email"
                            />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>

            {  processing ? <LoadingScreen /> :"" }
        </>
    )
}

export default LenderWelcomeEmail;