
import { IconButton, ConfirmDialog } from '../HtmlComponents';
import { deleteUser } from "../../api";
import { useToken } from '../../hooks/useAuth';
import Notifications from '../global/Notifications';

const SingleUser = ({ user, usersStatus, callback }) => {

    var token = useToken();
    const handler = async (name, userId) => {
        switch (name) {
            case "deleteUser":
                ConfirmDialog({ callback: () => { handler("executeDeleteUserOperation", userId) }, "purpose": "general", "heading": `Are you sure you want to archive user?`, "description": "" })
                break;
            case "executeDeleteUserOperation":
                let response = await deleteUser(token, userId);
                Notifications("success", response["message"] || "");
                callback("refreshUsersListing", userId)
                break;
        }
    }
    return (
        <>
            <tr>
                {usersStatus === "inActive" ? "" : <td> {user && user.userName ? user.userName : ""} </td>}
                <td> {user && user.firstName ? user.firstName : ""} </td>
                <td>{user && user.email ? user.email : ""}</td>
                <td>{user && user.contactNumber ? user.contactNumber : ""}</td>
                <td>{user && user.landlineNumber ? user.landlineNumber : ""}</td>
                <td>
                    <IconButton
                        iconType="edit"
                        buttonClassName="btn btn-sm btn-light btn-active-secondary"
                        buttonStyle={{ background: "transparent" }}
                        buttonOnClick={() => { callback("updateUser", user.id) }}
                        tooltipDescription="Update"
                    />
                    {usersStatus === "inActive" ? "" :
                        <IconButton
                            iconType="delete"
                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                            buttonStyle={{ background: "transparent", color: "red" }}
                            buttonOnClick={() => { handler("deleteUser", user.id) }}
                            tooltipDescription="Delete User"
                        />
                    }
                </td>
            </tr>
        </>

    )
}

export default SingleUser;