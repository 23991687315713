import { IoCloseCircle, IoCopySharp, IoPersonAdd, IoDocumentSharp } from "react-icons/io5";
import { MdDeleteForever, MdGroupAdd , MdModeEditOutline, MdThumbDown} from "react-icons/md";
import { AiFillPrinter, AiOutlineSearch , AiOutlineLink} from "react-icons/ai";
import{TiTick} from "react-icons/ti";
import { TbNotes } from "react-icons/tb";
import{VscGraph} from "react-icons/vsc"

import ReactTooltip from "react-tooltip";
import { getUniqueId } from "../../../helpers";

import { RiListOrdered } from "react-icons/ri";
import { GrView , GrRefresh} from "react-icons/gr";

//tb, ai, ri
const GetIcon = () =>{

    return(
        <>
        <AiOutlineLink />
        <RiListOrdered />
        </>
    )
}

const EditButton = (data) => {
    const url = data.URL;
    const label = 'LABEL' in data ? data.LABEL : "Edit";       //CHECK IF PROP EXISTS 

    return (
        <>
            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="">
                <a href={url} className="btn btn-sm btn-light btn-active-secondary" >
                    <i className="bi bi-pencil-fill fs-7"></i> {label}
                </a>
            </div>
        </>
    )
}


const AddButton = (data) => {
    const url = data.URL;

    return (
        <>
            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="">
                <a href={url} className="btn btn-sm btn-light btn-active-secondary" >
                    <i className="fas fa-solid fa-plus"></i>
                    Add
                </a>
            </div>
        </>
    )
}

/* CUSTOM SUBMIT BUTTON - HITTING THIS BUTTON WILL CALL THE CUSTOM ACTION METHOD PROVIDED TO THIS BUTTON*/
const SubmitButton = (data) => {
    const actionMethod = data.actionMethod;

    return (
        <>
            <input type="submit" onClick={actionMethod} className="btn btn-sm btn-primary btn-hover-rise me-5" style={{ float: "right" }} value="Submit" />
        </>
    )
}



//isActive prop : INDICATE IF BUTTON IS ACTIVE OR NOT
const FormSubmissionButton = (props) => {
    var label = "Save";
    label = props.label !== undefined && props.label.length> 0 ? props.label : label;
    return (
        <>
            <button type="submit" className="btn btn-sm btn-primary btn-hover-rise" disabled={props.isActive === true ? false : true} style={{ float: "right" }}   > {label}</button>
        </>
    )
}


/* CUSTOM BUTTON */
const CustomButton = (props) => {
    return (
        <>
            <button
                className={props.buttonClassName + " btn "}
                disabled={props.isActive === true ? false : true} 
                style={props.buttonStyle}
                onClick={props.buttonOnClick} >
                {props.buttonText}
            </button>
        </>
    )
}

/*Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
const IconButton = (props) => {
    const iconType = props.iconType ? props.iconType : "";
    const iconColor = props.iconColor ? props.iconColor : "black";
    const tootltip = props.tootltip ? true : false;
    var icon = '';

    const uniqueId = getUniqueId();

    switch (iconType) {
        case "+": icon = <i className="fas fa-solid fa-circle-plus"></i>; break;
        case "-": icon = <i className="fa fa-minus-circle" aria-hidden="true" style={{ color: iconColor }}></i>; break;
        case "delete": icon = <IoCloseCircle style={{ color: iconColor }} />; break;
        case "edit": icon = <MdModeEditOutline />; break;
        case "view": icon = <GrView />; break;
        case "deleteBin": icon = <MdDeleteForever />; break;
        case "copy": icon = <IoCopySharp />; break;
        case "files": icon = <IoDocumentSharp />; break;
        case "printer": icon = <AiFillPrinter />; break;
        case "addPerson": icon = <IoPersonAdd />; break;
        case "addGroup": icon = <MdGroupAdd />; break;
        case "thumbDown": icon = <MdThumbDown  />; break;
        case "search": icon = <AiOutlineSearch  />; break;
        case "tick": icon = <TiTick  />; break;
        case "notes": icon = <TbNotes  />; break;
        case "verticalGraph": icon = <VscGraph  />; break;
        case "refresh": icon = <GrRefresh  />; break;


        default:
            icon = <i className="fas fa-solid fa-circle-plus"></i>
            break;
    }


    return (
        <>
            <button type="button" className={props.buttonClassName} style={props.buttonStyle} onClick={props.buttonOnClick} data-tip data-for={uniqueId} >
                {icon}
            </button>
            {
                tootltip ?
                    <ReactTooltip id={uniqueId} place="top" effect="solid" className="customTooltip">
                        {props.tooltipDescription ? props.tooltipDescription : "NA"}
                    </ReactTooltip> : ""
            }
        </>
    )
}


export { AddButton, EditButton, SubmitButton, CustomButton, FormSubmissionButton, IconButton, GetIcon } 