import axios from '../api/axios';

 /* LIST ALL REFERRERS AGAINST THE USER PARENT ID ( CREDIT REP ID | COMPANY ID) - FILTER => REFERRER NAME OR REFERRER EMPLOYEE NAME */
const getCpd = async (token , year) => {
  try{
    const response = await axios.get(`cpd/year/${year}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Fetched successfully", data: response.data }
  }
  catch(e){
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" , cpd: [] }
    }
  }
   

}

const getSingleCpd = async (token, id) => {
    try{
        const response = await axios.get(`cpd/${id}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "Fetched successfully", data: response.data }
      }
      catch(e){
        if (e.response) {
          return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" , cpd: {} }
        }
      }
  }
   

//CREATE REFERRER
const createCpd = async (cpdObj, token) => {
  try {
    const response = await axios.post("cpd", cpdObj, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}
 

//CREATE REFERRER
const updateCpd = async (cpdObj, cpdId, token) => {
  try { 
    const response = await axios.put(`cpd/${cpdId}`, cpdObj, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Created successfully", data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}
 

export {  createCpd , updateCpd , getSingleCpd , getCpd };