import * as Yup from 'yup';


const ReferrerAccreditationFormValidationSchema = Yup.object().shape({
    referrer: Yup.object().shape({
        referredBy: Yup.string().required('Required'),
        tradingName: Yup.string().required('Required'),
        entityName: Yup.string().required('Required'),
        structure: Yup.string().required('Required'),
        abn: Yup.string().required('Required'),
        acn: Yup.string().when('structure', {
            is: 'company', // condition
            then: Yup.string().required('Required'), // schema to apply if condition is met
            otherwise: Yup.string(), // schema to apply if condition is not met
        }),
        dateEstablished: Yup.string().required('Required'),
        contactNumber: Yup.string().required('Required'),
        address: Yup.object().required('Required'),
        email: Yup.string().email('Must be valid email').required('Required'),
    }),
    directors: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Required'),
            contactNumber: Yup.string().required('Required'),
            email: Yup.string().email('Must be valid email').required('Required'),
        })
    ),
    bankingDetail: Yup.object().shape({
        name: Yup.string().required('Required'),
        branchName: Yup.string().required('Required'),
        bsb: Yup.string().required('Required'),
        accountNumber: Yup.string().required('Required'),
    }),
    acreditatedStaff: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Must be valid email').required('Required'),
            contactNumber: Yup.string().required('Required'),
            role: Yup.string().required('Required'),
            dob: Yup.string().required('Required'),
        })
    ),
});

export default ReferrerAccreditationFormValidationSchema;
 