import axios from '../api/axios';


const createSettlement = async (settlement, token) => {
    try {
        const response = await axios.post(`settlements`, settlement, { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        console.log("CREATION PROCESS COMPLETED");
        return { error: 0, httpCode: response.status, message: "Createds successfully", application: response.data }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
        }
    }
}

const updateSettlement = async (id, data, token) => {

    try {
        const response = await axios.put(`settlements/${id}`, data, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "Updated successfully", settlement: response.data ? response.data : {} }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, settlement: {} }
        }
    }
}


/*GET SINGLE SETTLEMENT*/
const getSettlement = async (id, token) => {
    try {
        const response = await axios.get(`settlements/${id}`, { headers: { Authorization: "Bearer " + token } });
        return { error: 0, httpCode: response.status, settlement: response.data ? response.data : {} }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, settlement: {} }
        }
    }
}
 

const getSettlementsList = async (token, query) => {
    try {
        const response = await axios.get(`settlements/listing/?${query}`, { headers: { Authorization: "Bearer " + token } });
        return { "error": 0, httpCode: response.status, "message": "", data: response.data ? response.data : [] }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
        }
    }
}



export { createSettlement, updateSettlement, getSettlement, getSettlementsList };