import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, FieldArray } from 'formik';
import { FormSubmissionButton } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import ListDebts from "./components/ListDebts";
import { getUniqueId } from "../../../../helpers";
import { v4 as uuid } from 'uuid';
import { useRole } from "../../../../hooks/useAuth";


const Debts = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault }) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [debtsTypeList, setDebtsTypeList] = useState([]);
    const [debts, setDebts] = useState([]);
    const userRole = useRole();

    const FetchDebtsType = () => {
        var options = [];
        Object.keys(Constants.DEBT_TYPES).map(key => { options.push({ value: key, label: Constants.DEBT_TYPES[key] }); })
        setDebtsTypeList(options);
    }
    const handleChange = () => { setIsOpen(isOpen === "none" ? "block" : "none"); }

    useEffect(() => {
        FetchDebtsType();
    }, []);



    useEffect(() => {

        var assets = initialValues.assets;

        if (Array.isArray(initialValues.debts) && initialValues.debts.length > 0) {
            setDebts(initialValues.debts);
        }
        else if (Array.isArray(initialValues.debts) && initialValues.debts.length == 0 && Array.isArray(assets) && assets.length > 0) {
            //setDebts([]);
            var tempDebts = [];

            assets.map(singleAsset => {
                if(singleAsset.assetType !== undefined && singleAsset.assetOwnership !== undefined && ["house", "land", "car", "motorBike", "boat", "caravan", "jetSki", "truck", "investmentProperties"].includes(singleAsset.assetType) && singleAsset.assetOwnership == "oweMoneyOn"){
                    tempDebts.push({ "debtType": singleAsset.assetType, assetValue: singleAsset.assetValue !== undefined ? singleAsset.assetValue: 0,  "financialInstitution": '', "repaymentFrequency": '' })
                }
            })
            setDebts(tempDebts);
        }
        else {
            setDebts([]);
        }


    }, [applicantId]);

    const CreateDebt = () => {
        const newDebt = { "debtType": "", "financialInstitution": '', "repaymentFrequency": '', uId: uuid() }

        if (Array.isArray(debts) && debts.length == 0) {
            var debtsList = [];
            debtsList.push(newDebt);
            setDebts(debtsList);
        }
        else if (Array.isArray(debts) && debts.length > 0) {
            var tempDebts = [];
            if (formRef.current.values.debts.length > 0) { tempDebts = JSON.parse(JSON.stringify(formRef.current.values.debts)); }
            tempDebts.push(newDebt);
            setDebts(tempDebts);
        }
    }


    //DELETE ASSET
    const DeleteDebt = (index) => {
        //FIRST CREATE DEEP COPY OF ASSETS - FORMIK VALUES
        var tempDebts = [];
        if (formRef.current.values.debts.length > 0) { tempDebts = JSON.parse(JSON.stringify(formRef.current.values.debts)); }
        tempDebts.splice(index, 1);
        setDebts(tempDebts);
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="debtBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Liabilities</h3>
                        <DownArrowIcon />
                    </div>

                    { /** personal infort=mation block start  */}
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            initialValues={{ "debts": debts }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("debts");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>                                
                                    {<FieldArray name="debts">
                                        {({ remove, push }) => (
                                            <>
                                                <ListDebts debts={values.debts} debtsTypeList={debtsTypeList} setFieldValue={setFieldValue} deleteDebt={remove} />
                                                <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                    push({ "debtType": "", "financialInstitution": '', "repaymentFrequency": '' })
                                                }}>
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Add Liability
                                                </button>
                                            </>
                                        )}
                                    </FieldArray>}

                                    <div className="col-xl-12">
                                        { userRole !== "auditor" ? <FormSubmissionButton isActive={applicationId != 0 ? true : false} /> : ""}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Debts;