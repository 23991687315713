
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';
import { createDeclined } from "../../api";
import { FormSubmissionButton, TextArea, CheckBox, FloatField, SelectField, InputGroup, InputField, MaskedField } from '../HtmlComponents';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { replaceNullWithString, formatDate, dateFilter, getCurrentDate } from '../../helpers';
import DeclinedSchema from './schema/DeclinedSchema.json';
import DeclinedValidationSchema from './schema/DeclinedValidationSchema';

import Constants from "../global/Constants";
import DeclinedReason from "./components/DeclinedReason";


//BEING OPENED IN RIGHT DRAWER AS A COMPONENT AND ALSO HAVE A INDEPENTANT EXISTANCE
const CreateDeclined = ({applicationId, callback}) => {
    const [singleDeclined, setSingleDeclined] = useState(DeclinedSchema);
    const [singleApplicationId, setSingleApplicationId] = useState(0);
    const [navigation, setNavigation] = useState(false); //IF OPEN IN A MODE/WINDOW THEN DO NOT NAVIGATE
    const [isChildWindow, setIsChildWindow] = useState(false);
    const declinedRef = useRef();
    let { id } = useParams();
 
    let navigate = useNavigate();
    const token = useToken();

    const getReasons = () => {
        var reasons = Object.keys(Constants.DECLINED_REASONS).map(index => {
            return { type: index, description: "", isChecked: false }
        })
        return reasons;
    }

    //UPDATE SCHEMA AND APPLICATION ID 
    const updateSchema = () => {
        var tmpApplicationId = 0;
        if(id !== undefined){
            setSingleApplicationId(id);
            tmpApplicationId = id;
            setNavigation(true);
        }
        else if(applicationId !== undefined){
            setSingleApplicationId(applicationId)
            setIsChildWindow(true);
            tmpApplicationId = applicationId; 
        }
        DeclinedSchema["applicationId"] = tmpApplicationId;
        var reasons = getReasons();
        DeclinedSchema["reasons"] = reasons;
        DeclinedSchema["declinedDate"] = getCurrentDate();
        setSingleDeclined(DeclinedSchema);
    }

    useEffect(() => {
        updateSchema();
        console.log("USE EFFECT RUNNING");
    }, [])


    const DeclinedReasonsHtml = ({ setFieldValue }) => {

        if (Array.isArray(singleDeclined.reasons)) {
            return (
                singleDeclined.reasons.map((singleReason, index) => {
                    return (
                        <div style={{ display: "flex", justifyContent: "start", marginBottom: "8px" }}>
                            <DeclinedReason
                                name={`reasons[${index}]`}
                                label={Constants.DECLINED_REASONS[singleReason["type"]]}
                                isChecked={singleReason["isChecked"]}
                                setFieldValue={setFieldValue} />
                        </div>
                    )
                })
            )
        }
        else {
            return ("No Reaosn found");
        }
    }



    const DeclinedHtml = (errors, touched, setFieldValue) => {
        return (
            <>
                <div className="card mb-5 mb-xxl-8">
                    <div className="card-body card-body pt-6 pb-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <h3 className="pb-0 mb-0">Create Declined (Ref # {singleApplicationId}) </h3>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="position-relative z-index-1">

                            <div className="row">
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Declined Date</label>
                                    <Field type="text" name={`declinedDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`declinedDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    {errors.declinedDate ? (<div className="error">{errors.declinedDate}</div>) : null}
                                </div>
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Follow Up Date</label>
                                    <Field type="text" name={`followUpDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`followUpDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    {errors.followUpDate ? (<div className="error">{errors.followUpDate}</div>) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Declined For</h5>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <DeclinedReasonsHtml setFieldValue={setFieldValue} />
                                </div>

                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Comments</h5>
                                    </div>
                                    <div>
                                        <Field type="text" name={`comments`}  >
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="comments"
                                                    fieldValue={field.value}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Formik
                initialValues={singleDeclined}
                validationSchema={DeclinedValidationSchema}
                innerRef={declinedRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    var localValues = JSON.parse(JSON.stringify(values));
                    //FORMAT THE DATES
                    localValues["declinedDate"] = localValues["declinedDate"].includes("/") ? formatDate(localValues["declinedDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["declinedDate"];
                    localValues["followUpDate"] = localValues["followUpDate"].includes("/") ? formatDate(localValues["followUpDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["followUpDate"];

                    var reasons = localValues["reasons"];
                    //fetch only reasons having isChecked true
                    var filteredReasons = reasons.filter(reason => reason.isChecked == true);
                    //console.log("filtered reasons::" , filteredReasons);
                    localValues["reasons"] = filteredReasons;

                    var response = await createDeclined(localValues, token);
                    console.log("declined creation response :: ", response);
                    if (!response["error"]) {
                        Notifications("success", response["message"] || "Created Successfully");
                        if(navigation){navigate(`/user/declined`);}
                        else if(isChildWindow){ callback("")}
                    }
                    else {
                        Notifications("error", response["message"] || "Error occured");
                    }
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {DeclinedHtml(errors, touched, setFieldValue)}
                                    </div>
                                </div>
                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default CreateDeclined;