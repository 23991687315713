import * as Yup from 'yup';


const UpdatePasswordValidationSchema =  Yup.object({
  currentPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string().required('Required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Required')
});

export default UpdatePasswordValidationSchema;