import { useEffect, useState } from 'react';
import { RightDrawer, InputField, SelectField, MaskedField, TextArea, CustomButton, IconButton, CheckBox, ConfirmationAlert } from '../../HtmlComponents';
import { useToken, useEntityType, useRole } from '../../../hooks/useAuth';
import { getApplicationProgressStatus, performApplicationAdditionalActions, getComments, getApplicationReferrerDetails, getApplication } from '../../../api';
import Notifications from '../../../components/global//Notifications';
import { formatDate } from '../../../helpers';
import { useNavigate } from "react-router-dom";
import { removeSpace , dateFilter , convertDateintoUnix , getCurrentDate} from "../../../helpers";


import LenderSelection from './LenderSelection';
import CreditProposal from './CreditProposal';
import SubmitToAwnInsurance from '../Utilities/SubmitToAwnInsurance';

const UpdateProgressStatus = ({ applicationId, currentProgressStatus, loanPurpose, applicationType, callback }) => {

    const [progressStatusList, setProgressStatusList] = useState([]);
    const [values, setValues] = useState({ sharedFlag: false, comment: "", progressStatus: currentProgressStatus, lenderName: "" , messageForApplicant: "" , triggerActionAt: ''});
    const [selectedLenderName, setSelectedLenderName] = useState("");
    const [comments, setComments] = useState([]);
    const [readyForSubmission, setReadyForSubmission] = useState(false);    //TO CONTROLL SUBMIT/SAVE BUTTON
    const [processing, setProcessing] = useState(false);        //TO CONTROL CLOSE BUTTON
    const [addDeclinedButton, setAddDeclinedButton] = useState(false);
    const [isCreditProposalFound, setIsCreditProposalFound] = useState(false);
    const [comprehensiveInsurance, setComprehensiveInsurance] = useState({ isFound: false, isExpiryDateFound: false, required: false });
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    
    //STATES TO MAKE SURE THAT WE DO NOT REPEAT THE ACTIONS THAT IS JUST TO MEANT TO RUN FORONE (I.G OPENIGN THE AWN TAB WHEN STATUS CHANGES )
    const [awnActionPerformed , setAwnActionPerformed] = useState(false);

    const [checkList, setCheckList] = useState({
        amlCompleted: false,
        financeCommissionConfirmationUploaded: false,
        financeDocumentsUploaded: false,
        incomeVerification: false,
        insuranceCommissionUploaded: false,
        insuranceConfirmation: false,
        remittanceAdvices: false,
        taxInvoice: false
    })
    const [referrer, setReferrer] = useState(null);
    const [assignedReferrerUser, setAssignedReferrerUser] = useState({});

    const userRole = useRole();
    let navigate = useNavigate();

    var token = useToken();
    const entityType = useEntityType();

    //FETCH ALL PROGRESS STATUS
    const fetchProgressStatus = async () => {
        var response = await getApplicationProgressStatus(token);
        if (response["error"] == 0) {
            var progressStatusArray = response["data"];
            if (Array.isArray(progressStatusArray)) {
                var options = progressStatusArray.map(singleProgressStatus => {
                    return { value: singleProgressStatus.name, label: singleProgressStatus.label }
                })
                setProgressStatusList(options);
            }
        }
    }

    //FIND THE CURRENT PROGRESS STATUS AGAINST LABEL - APPLICATION IS PASSING STATUS LABEL
    const filterProgressStatus = () => {
        var currentProgressStatusArray = progressStatusList.filter(singleProgressStatus => singleProgressStatus.label == currentProgressStatus);
        if (Array.isArray(currentProgressStatusArray) && currentProgressStatusArray[0] !== undefined) {
            setValues({ ...values, progressStatus: currentProgressStatusArray[0].value });

            //IF CURRENT STATUS IS loanContractsSettled THEN SET CHECKLIST TO TRUE
            if (currentProgressStatusArray[0].value == "loanContractsSettled") {
                setCheckList({
                    amlCompleted: true,
                    financeCommissionConfirmationUploaded: true,
                    financeDocumentsUploaded: true,
                    incomeVerification: true,
                    insuranceCommissionUploaded: true,
                    insuranceConfirmation: true,
                    remittanceAdvices: true,
                    taxInvoice: true
                })
            }
        }
    }

    //FETCH NOTES 
    const fetchComments = async () => {
        if (["admin", "analyst", "finance", "superAdmin", "auditor", "referrerGroupManager"].includes(userRole)) {
            /*var progressCommentsRresponse = await getComments(token, "progress", applicationId);
            console.log("progressCommentsRresponse  has been exec ---- ", progressCommentsRresponse);
            var assigningApplicationResponse = await getComments(token, "assigningApplication", applicationId);  
            console.log("assigningApplicationResponse has been exec :: : ", assigningApplicationResponse);
            */

            var progressCommentsPromise = new Promise(async (resolve) => {
                var progressCommentsRresponse = await getComments(token, "progress", applicationId);
                if (progressCommentsRresponse["error"] == 0) { resolve(progressCommentsRresponse["data"]); }
                else { resolve([]) }
            })

            var assignApplicationsPromise = new Promise(async (resolve) => {
                var assigningApplicationResponse = await getComments(token, "assigningApplication", applicationId);
                if (assigningApplicationResponse["error"] == 0) { resolve(assigningApplicationResponse["data"]); }
                else { resolve([]) }
            })

            await Promise.all([progressCommentsPromise, assignApplicationsPromise]).then(data => {
                console.log("data :::::::::  ", data);
                var progressComments = data[0] !== undefined && Array.isArray(data[0]) ? data[0] : [];
                var assigningApplicationComments = data[1] !== undefined && Array.isArray(data[1]) ? data[1] : [];

                var mergeAllComments = [...progressComments, ...assigningApplicationComments];

                //SORTING
                if (Array.isArray(mergeAllComments) && mergeAllComments.length > 0) {
                    var newArr = mergeAllComments.sort((a, b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0))
                }
                setComments(mergeAllComments);
            })
            /*
            if (progressCommentsRresponse["error"] == 0) {
                setComments(progressCommentsRresponse["data"]);
            }            */
        }
        else {
            var progressCommentsRresponse = await getComments(token, "progress", applicationId);
            if (progressCommentsRresponse["error"] == 0) { setComments(progressCommentsRresponse["data"]); }
        }

    }

    //FETCH THE REFERRER DETAIL FOR THE ONE ASSIGNED TO THIS APPLICATION
    const fetchAssignedReferrerDetails = async () => {
        var response = await getApplicationReferrerDetails(token, applicationId);
        if (response["error"] == 0) {
            setReferrer(response["data"]["referrer"] || {});
            //setReferrerGroup(response["data"]["referrerGroup"] || {});
            //FIND THE ASSIGNED REFERRER USER FROM THE LIST OF ALL USERS OF ASSIGNED REFERRER
            let referrerStaffId = response["data"]["referrerStaffId"] || 0;
            let referrerUsers = response["data"]["referrerUsers"] || [];

            if (Array.isArray(referrerUsers)) {
                var findAssignedReferrerUser = referrerUsers.find(singleReferrerUser => singleReferrerUser.id == referrerStaffId);
                setAssignedReferrerUser(findAssignedReferrerUser);
            }
        }
    }

    //FETCH INDIVIDUAL REFERRER (USER) DETAILS ASSIGNED TO THIS APPLICAITON
    const AssignedReferrerUserDetails = () => {
        if (assignedReferrerUser !== null && assignedReferrerUser !== undefined) {
            return (
                <>
                    <div className='row mt-3' style={{ color: "green" }}>
                        <div className='col-sm-4'>
                            <div className='fs-5 mb-3'>
                                <span className="fs-6 fw-bold mb-5">Referrer: {assignedReferrerUser.firstName + " " + assignedReferrerUser.surname}</span>
                            </div>
                        </div>

                        <div className='col-sm-4'>
                            <div className='fs-5 mb-3'>
                                <span className="fs-6 fw-bold mb-5"> {assignedReferrerUser.contactNumber}</span>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='fs-5 mb-3'>
                                <span className="fs-6 fw-bold mb-5">{assignedReferrerUser.email}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return ""
        }
    }

    //RETURN HTML FOR THUMB DOWN BUTTON
    const DeclinedButton = () => {
        if (["admin", 'superAdmin', 'analyst'].includes(userRole) && (addDeclinedButton || ["unableToAssist", "customerNotResponding-UnableToAssist", "withdrawnByCustomer-UnableToAssist", "withdrawnByReferrer-UnableToAssist"].includes(currentProgressStatus))) {
            return (
                <>
                    { /*<Link to={{ pathname: `/user/createDeclined/${applicationId}` }} className=" applicationId text-hover-primary mb-1"><IconButton iconType="thumbDown" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Unable to Assist" buttonOnClick={() => { callback("declineApplication", applicationId) }} /> </Link> */}
                    <IconButton iconType="thumbDown" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Unable to Assist" buttonOnClick={() => { callback("createDeclined", { applicationId: applicationId }) }} />
                </>
            )
        }
        else {
            return ""
        }

    }

    //RETURN HTML FOR CREDIT PROPOSAL BUTTON
    const CreditProposalButton = () => {
        if (["admin", 'analyst', 'superAdmin'].includes(userRole) && values["progressStatus"] === "loanApplicationSubmitted" && applicationType !== "commercial") {
            return (
                <>
                    <CustomButton
                        buttonClassName=" btn-primary btn-sm btn-nochange-on-hover"
                        buttonStyle={{ fontSize: "12px", color: "#009ef7", backgroundColor: "transparent", textDecoration: "underline", padding: "0px", paddingLeft: "10px" }}
                        buttonText="Credit Proposal"
                        isActive={true}
                        buttonOnClick={() => { console.log("CREDIT PROPOSAL BUTTON CLICKED"); drawerHandler("openCreditProposal") }}
                    />
                </>
            )
        }
        else {
            return ""
        }

    }

    //AWN BUTTON
    const AWNButton = () => {
        if (["admin", 'analyst', 'superAdmin'].includes(userRole) && values["progressStatus"] === "loanContractsSettled" && ["carDealership" , "carPrivate" , "caravanTrailer" , "marine"].includes(loanPurpose)) {
            return (
                <>
                    <CustomButton
                        buttonClassName=" btn-primary btn-sm btn-nochange-on-hover"
                        buttonStyle={{ fontSize: "12px", color: "#009ef7", backgroundColor: "transparent", textDecoration: "underline", padding: "0px", paddingLeft: "10px" }}
                        buttonText="AWN Insurance"
                        isActive={true}
                        buttonOnClick={() => { console.log("CREDIT PROPOSAL BUTTON CLICKED"); drawerHandler("openSubmitToAwnInsurance") }}
                    />
                </>
            )
        }
        else {
            return ""
        }

    }


    //REFRESH BUTTON TO CHECK IF CREDIT PROPOSAL EXISTS OR NOT - ANALYST CAN TRIGGER THIS MANUALLY TO CHECK 
    const RefreshCreditProposalExistanceCheckButton = () => {
        if (["admin", 'analyst', 'superAdmin'].includes(userRole) && values["progressStatus"] === "loanApplicationSubmitted" && applicationType !== "commercial") {
            return (
                <>
                    <IconButton iconType="refresh" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Check again if credit proposal exist" buttonOnClick={() => { isCreditProposalSentForSelectedLender(false) }} />
                </>
            )
        }
        else {
            return ""
        }
    }

    //UPDATE LENDER BUTTON
    const UpdateLenderButton = () => {
        if (["admin", 'analyst', 'superAdmin'].includes(userRole) && values["progressStatus"] === "loanApplicationSubmitted" ) {
            return (
                <>
                    <CustomButton
                        buttonClassName=" btn-primary btn-sm btn-nochange-on-hover"
                        buttonStyle={{ fontSize: "12px", color: "#009ef7", backgroundColor: "transparent", textDecoration: "underline", padding: "0px", paddingLeft: "10px" }}
                        buttonText="Update Lender"
                        isActive={true}
                        buttonOnClick={() => {  drawerHandler("openLenderSelection") }}
                    />
                </>
            )
        }
        else {
            return ""
        }

    }


    //LOAD APPLICATION ONLY WHEN STATUS IS CHANGED TO loanContractsSubmittedForSettlement AND UPDATE STATE comprehensiveInsurance - CHECK IF COMP EXIST OR NOT
    const loadApplication = async () => {

        var applicationResponse = await getApplication(applicationId, token);
        if (applicationResponse["error"] === 0 && applicationResponse["data"]) {
            try {
                var purpose = applicationResponse["data"]["purpose"];
                if (["debtConsolidation", "insuranceOnly", "personalLoan"].includes(purpose)) { return; }

                //IF INSURANCE ARE 
                var insurances = Array.isArray(applicationResponse["data"]["insurances"]) ? applicationResponse["data"]["insurances"] : [];
                var tmpComprehensiveInsurance = insurances.find(singleInsurance => singleInsurance.type == "comprehensive");

                if (tmpComprehensiveInsurance !== undefined && tmpComprehensiveInsurance["expiryDate"] > 0
                    && tmpComprehensiveInsurance["extraDetail"]["policyNumber"] !== undefined && tmpComprehensiveInsurance["extraDetail"]["policyNumber"].length > 0
                ) { setComprehensiveInsurance({ ...comprehensiveInsurance, required: false }); }
                else if (tmpComprehensiveInsurance !== undefined && tmpComprehensiveInsurance["expiryDate"] == 0) {
                    setComprehensiveInsurance({ ...comprehensiveInsurance, required: true });
                    Notifications("error", "Expiry Date required for comprehensive insurance.");
                }
                else if (tmpComprehensiveInsurance !== undefined && tmpComprehensiveInsurance["extraDetail"]["policyNumber"] !== undefined && removeSpace(tmpComprehensiveInsurance["extraDetail"]["policyNumber"]).length == 0) {
                    setComprehensiveInsurance({ ...comprehensiveInsurance, required: true });
                    Notifications("error", "Comprehensive policy number is required.");
                }
                else {
                    Notifications("error", "Comprehensive Insurance required.");
                    setComprehensiveInsurance({ ...comprehensiveInsurance, required: true });
                }
            }
            catch (e) { Notifications("error", "Exception - Comprehensive Insurance required."); }
        }

    }

    useEffect(() => {
        fetchProgressStatus();
        fetchComments();
        fetchAssignedReferrerDetails();
    }, []);

    useEffect(() => {
        filterProgressStatus();
    }, [progressStatusList])

    useEffect(() => {
        //EXECUTE EITHER CONSUMER OR COMMERCIAL - OPEN THE SCREEN TO UPDATE LENDER INFORMATION - IF CONSUMER THEN CREDIT PROPOSAL OPENS AS WELL
        if ((!["Loan Application Submitted", "loanApplicationSubmitted"].includes(currentProgressStatus)) && values["progressStatus"] === "loanApplicationSubmitted" && isCreditProposalFound == false) {
            setReadyForSubmission(false);
            drawerHandler("openLenderSelection");  //OPEN THE LENDER SELECTION WINDOW
        }
        //OPEN AWN INSURANCE SCREEN TO SSUBMIT THE ICUSTOMER DETAILS IF FOLLOWING CONMDITION MEET
        else if(!["Loan Contracts Settled" , "loanContractsSettled"].includes(currentProgressStatus) && values["progressStatus"] === "loanContractsSettled" && ["carDealership" , "carPrivate" , "caravanTrailer" , "marine"].includes(loanPurpose)  && awnActionPerformed === false){
            drawerHandler("openSubmitToAwnInsurance");
            setAwnActionPerformed(true);
        }
        else if (values["comment"].length > 0 || values["progressStatus"] !== currentProgressStatus) {
            //CHECK IF STATUS IS THEN SET IT FALSE UNTILL WHOLE CHECKLIST IS MARKED
            var isAllCheckListTrue = Object.keys(checkList).every(function (key) { return checkList[key] === true; });
            if (values["progressStatus"] === "loanContractsSettled" && isAllCheckListTrue == false) { setReadyForSubmission(false); }
            else { setReadyForSubmission(true); }
        }
        else { setReadyForSubmission(false); }

        //CHECK IF "loanContractsSubmittedForSettlement"
        if (values["progressStatus"] === "loanContractsSubmittedForSettlement" && !["other"].includes(loanPurpose) ) { loadApplication(); }
        else {
            setComprehensiveInsurance({ ...comprehensiveInsurance, required: false });
        }
    }, [values, checkList]);

    //CHECK FROM SERVER IF THE CREDIT PROPOSAL SENT TO CUSTOMER FOR THIS APPLIATION AGAINST THE SELECTED LENDER 
    const isCreditProposalSentForSelectedLender = async (popup = false) => {
        if (!popup) {
            var response = await performApplicationAdditionalActions(token, "isCreditProposalExists", applicationId);
            if (response["data"] && response["data"]["isCreditProposalExists"]) {
                setIsCreditProposalFound(true);
                setReadyForSubmission(true);
                let message = "Credit Proposal found";
                Notifications("success", message, message);
            }
            else {
                setIsCreditProposalFound(false);
                setReadyForSubmission(false);
                let message = "Credit Proposal Not found";
                Notifications("error", message, message);
            }
        }
        else {
            ConfirmationAlert({
                icon: "warning",
                heading: "",
                html: "1)   Has a credit proposal been sent to the customer for this lender? <br> 2)    Please ensure the lender submitted to is in notes.",
                showDenyButton: true,
                showConfirmButton: true,
                confirmButtonText: " Yes ",
                denyButtonText: " No ",
                confirmCallBack: async () => {
                    //CHECK IF CREDIT PROPOSAL EXISTS OR NOT
                    var response = await performApplicationAdditionalActions(token, "isCreditProposalExists", applicationId);
                    if (response["data"] && response["data"]["isCreditProposalExists"]) {
                        setIsCreditProposalFound(true);
                        setReadyForSubmission(true);
                    }
                    else {
                        let message = "Credit Proposal Not found";
                        Notifications("error", message, message);
                    }
                },
                denyCallBack: () => {
                    drawerHandler("openCreditProposal");
                    //navigate(`/user/editConsumerApplication/${applicationId}`);
                }
            });
        }
    }

    //UPDATE PROGRESS STATUS
    const updateHandler = async (name, value) => {
        switch (name) {
            case "executeUpdateCommand":
                //CHECK PROGRESS STATUS IF 'Submitted to Lender' IS SELECTED THEN ASK IF CREDIT PROPOSAL IS SENT OR NOT FIRST
                setProcessing(true);
                setReadyForSubmission(false);
                var valuesClone = JSON.parse(JSON.stringify(values));
                valuesClone["lenderName"] = selectedLenderName;
                var error = 0;
                var errorMessage = '';
                //CHECK IF PROGRESS STATUS IS DEFERRED THEN triggerActionAt IS REQUIRED AND IT SHOULD HAVE FURUTRE VALUE
                if(["deferred"].includes(valuesClone["progressStatus"]) &&  !["deferred"].includes(currentProgressStatus) ){
                    let triggerActionAt = valuesClone["triggerActionAt"];
                    valuesClone["comment"] = valuesClone["comment"].length > 0 ?  valuesClone["comment"]+ ` Deferred to ${triggerActionAt}` : `Deferred to ${triggerActionAt}`;

                    //GET TODAY DATE
                    let todayDateUnix = convertDateintoUnix(getCurrentDate());  //UNIX DATE
                    let triggerActionAtUnix = triggerActionAt.length ==10 ? convertDateintoUnix(triggerActionAt) : 0;
                    
                    //CHANGE THE triggerActionAt TO UNIX
                    valuesClone["triggerActionAt"] = triggerActionAtUnix;

                    if(todayDateUnix >= triggerActionAtUnix){
                        error = 1;
                        errorMessage = "The Deferred Date must be a future date.";
                    }
                    else{ }
                }


                //IF NO ERROR THEN PROCEED TO SUBMIT
                if(!error){
                    var response = await performApplicationAdditionalActions(token, "updateProgressStatus", applicationId, valuesClone);
                    fetchComments(); //FETCH THE COMMENTS AGAIN
                    setProcessing(false);
                    if (response["error"] !== undefined && response["error"] == 0) {
                        if (["admin", 'superAdmin', 'analyst'].includes(userRole) && ['withdrawnByCustomer-UnableToAssist', 'customerNotResponding-UnableToAssist', 'withdrawnByReferrer-UnableToAssist', 'unableToAssist'].includes(values["progressStatus"])) {
                            callback("createDeclined", { applicationId: applicationId });
                            //setAddDeclinedButton(true);
                        }
                        else if (["admin","superAdmin", "analyst"].includes(userRole) && ["approved"].includes(values["progressStatus"])){callback("taxInvoice", { applicationId: applicationId });}
                        Notifications("success", response["message"] || "Updated Successfully");
                    }
                    else { Notifications("error", response["message"] || "Error occurred."); }                    
                }
                else{
                    Notifications("error",  errorMessage);
                }
                 
                break;
            default:
                /*setValues( prevValues => { 
                    return {...prevValues, [name]: value}
                })*/
                setValues({ ...values, [name]: value });
                break;
        }
    }

    //UPDATE  CHECKLIST VALUES FOR LOAN CONTRACT SETTLED
    const updateChecklist = (name, value) => {
        setCheckList({ ...checkList, [name]: value });
    }

    //RETURN CHECKLIST HTML IF STATUS loanContractsSettled
    const CheckListHtml = () => {
        if (values["progressStatus"] === "loanContractsSettled") {
            return (<>
                <div style={{ border: "1px solid #e8dddd", padding: "16px 20px 10px 20px", marginBottom: "15px" }}>
                    <div>
                        <h4>Checklist</h4>
                        <p>All boxes should be marked in order to save this status</p>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="amlCompleted" defaultChecked={checkList["amlCompleted"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>AML Completed Including Selfie/ Certified ID if Required</label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="financeCommissionConfirmationUploaded" defaultChecked={checkList["financeCommissionConfirmationUploaded"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Finance Commission Confirmation Uploaded</label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="financeDocumentsUploaded" defaultChecked={checkList["financeDocumentsUploaded"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Finance Documents Uploaded </label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="incomeVerification" defaultChecked={checkList["incomeVerification"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Income Verification </label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="insuranceCommissionUploaded" defaultChecked={checkList["insuranceCommissionUploaded"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Insurance Commission Uploaded</label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="insuranceConfirmation" defaultChecked={checkList["insuranceConfirmation"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Insurance Confirmation</label>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-2'>
                        <div className='d-flex'>
                            <CheckBox name="remittanceAdvices" defaultChecked={checkList["remittanceAdvices"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Remittance Advices</label>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='d-flex'>
                            <CheckBox name="taxInvoice" defaultChecked={checkList["taxInvoice"]} setFieldValue={updateChecklist} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}>Tax Invoice</label>
                        </div>
                    </div>
                </div>
            </>)
        }
        else {
            return ("");
        }
    }


    /**HANDLER TO DEAL WITH OPENING NEW RIGHT WINDOW (FOR LENDER SELECTION , CREDIT PROPOSAL ETC) */
    const drawerHandler = (action, payload= {}) => {
        switch (action) {
            case "openLenderSelection":
                setDrawerSettings({ visible: true, heading: "Lender Selection", childName: "lenderSelection", meta: { applicationId: applicationId } });
                break;
            case "openSubmitToAwnInsurance":
                setDrawerSettings({ visible: true, heading: "AWN Insurance", childName: "submitToAwnInsurance", meta: { applicationId: applicationId } });
                break;
            case "openCreditProposal":                 
                //CHECK LENDER NAME IN THE PAYLAOD - IF IT IS CALLED BY LENDER SELECTION CALLBACK THEN IT MUST HAVE LENDERNAME IN THE PAYLAOD
                console.log("payload::::::::::: ", payload);
                var lenderName = payload["lenderName"] !== undefined ? payload["lenderName"]: "";
                if(lenderName.length > 0 ){ setSelectedLenderName(lenderName);} //UPDAET LENDER NAME 
                //IF LOAN TYPE IF CONSUMER THEN OPEN THIS ON OTHERWISE DO NOT
                if(applicationType !== "commercial" ){ setDrawerSettings({ visible: true, heading: "Credit Proposal", childName: "creditProposal", meta: { applicationId: applicationId } }); }
                else{
                    //CLOSE THE DRAWER AND MARK CREDIT PROPOSAL FOUND AS CREDIT PROPOSAL NOT REQUIRED FOR COMMERCIAL ONE
                    setIsCreditProposalFound(true);
                    setReadyForSubmission(true);
                    drawerHandler("closeDrawer");
                }
                break;
            case "checkCreditProposalExist":
                isCreditProposalSentForSelectedLender();
                drawerHandler("closeDrawer");
                break;
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

    return (
        <>
            { ["analyst" , "admin", "superAdmin", "finance"].includes(userRole) ?  
                <>
                    <div className='d-flex flex-column mb-8'>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <label className="fs-6 fw-semibold mb-2">Ref #: {applicationId}  <DeclinedButton /> </label>
                            </div>

                            <div className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-sm-6'> <label className="fs-6 fw-semibold mb-2">Status  </label> </div>
                                    <div className='col-sm-6'> <div style={{ float: "right" }}> <UpdateLenderButton/> <CreditProposalButton />  <AWNButton /> <RefreshCreditProposalExistanceCheckButton /> </div> </div>
                                </div>

                                <SelectField
                                    fieldName="progressStatus"
                                    defaultSelectedOption={{ value: values["progressStatus"], label: "" }} options={progressStatusList} setFieldValue={updateHandler}
                                />
                            </div>
                        </div>
                        <AssignedReferrerUserDetails />

                    </div>
                    <CheckListHtml />
                    <div className='d-flex flex-column mb-8'>
                        <div className='d-flex'>
                            <CheckBox name="sharedFlag" defaultChecked={values["sharedFlag"]} setFieldValue={updateHandler} />
                            <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: "12px" }}> Make Comments Visible to Referrer </label>
                        </div>
                    </div>

                    <div className='d-flex flex-column mb-8'>
                        <label className="fs-6 fw-semibold mb-2">Comments</label>
                        <TextArea
                            fieldName="comment"
                            fieldValue={ values.comment}
                            setFieldValue={updateHandler}
                        />
                    </div>

                    {/** CUSTOM MESSAGE FOR APPLICANTS **/}
                    {
                        values !== undefined && values["progressStatus"] !== undefined && ['deferred', 'pendingAdditionalInformation'].includes(values["progressStatus"]) && currentProgressStatus !== values["progressStatus"] ?
                            <div className='d-flex flex-column mb-8'>
                                <label className="fs-6 fw-semibold mb-2">Custom Message For Applicant</label>
                                <TextArea
                                    fieldName="messageForApplicant"
                                    fieldValue={values.messageForApplicant}
                                    setFieldValue={updateHandler}
                                />
                            </div> : ""
                    }

                     {/** TRIGGER ACTION AT DATE FIELD **/}
                     {
                        values !== undefined && values["progressStatus"] !== undefined && ['deferred'].includes(values["progressStatus"]) && currentProgressStatus !== values["progressStatus"] ?
                            <div className='d-flex flex-column mb-8'>
                                <label className="fs-6 fw-semibold mb-2">Deffered Till</label>                                
                                <MaskedField fieldName="triggerActionAt" fieldValue={dateFilter(values.triggerActionAt)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={updateHandler} />
                            </div> : ""
                    }

                    
                     

                    <div className='d-flex  mb-8' style={{ flexDirection: "row-reverse" }}>
                        <CustomButton
                            buttonClassName="btn-sm btn-primary"
                            buttonStyle={{ float: "right" }}
                            buttonOnClick={() => { updateHandler("executeUpdateCommand", true) }}
                            buttonText="Save"
                            isActive={readyForSubmission && (!comprehensiveInsurance["required"]) ? true : false}
                        />
                        <CustomButton
                            buttonClassName="btn-sm btn-secondary"
                            buttonStyle={{ float: "right", marginRight: "20px" }}
                            buttonOnClick={() => { callback("closeDrawer", true); }}
                            buttonText="Close"
                            isActive={processing ? false : true}
                        />
                    </div>
                </>  : ""
            }
            <div className='d-flex row'>
                <h3>Comments</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index} style={{ color: singleComment.sharedFlag ? "green" : "black" }}> {singleComment.comment}  ({formatDate(singleComment.timeStamp, 'unix', 'DD/MM/yyyy HH:mm')}) </div> }) : ""}
                </div>

            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawerHandler}>
                    {drawerSettings["childName"] === "lenderSelection" ? <LenderSelection applicationId={applicationId} callback={drawerHandler} /> : ""}
                    {drawerSettings["childName"] === "creditProposal" ? <CreditProposal applicationId={applicationId} callback={drawerHandler} /> : ""}
                    {drawerSettings["childName"] === "submitToAwnInsurance" ? <SubmitToAwnInsurance applicationId={applicationId} callback={drawerHandler} /> : ""}
                </RightDrawer> : ""
            }

        </>
    )
}

export default UpdateProgressStatus;

/**
 * PROGRESS STATUS: loanApplicationSubmitted => APPLICATION MUST HAVE CREDIT PROPOSAL SENT FOR THE SELECTED LENDER OTHERWISE STAUS SHULD NOT BE SAVED IF ITS CONSUMER TYPE
 * PROGRESS STATUS: loanContractsSettled AND LOAN PURPOSE ONE OF "carDealership" , "carPrivate" , "caravanTrailer" , "marine" THEN ASK TO SUBMIT AWN INSURANCE
 * PROGRESS STATUS: loanContractsSubmittedForSettlement, AND STATUS IS NOT IN [OTHER] THEN MAKE SURE THAT COMPREHENSIVE INSURANCE EXIST OTHERWISE DO NOT LET CHANGE THE STATUS
 * 
 */