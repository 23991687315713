import { useEffect, useState } from 'react';
import { InputField, FloatField, SelectField, MaskedField } from '../../../../../components/HtmlComponents';
import { replaceNullWithString, formatDate, dateFilter, capitalizeFirst } from '../../../../../helpers';
import { Field } from 'formik';

const SingleInsurance = ({ labelPrefix, index, type, insuranceCompanies, setFieldValue }) => {

    const [filterCompanies, setFilterCompanies] = useState([]);  //FILTER THE COMPANIES AGAINST GIVEN TYPE
    const [selectedCompanyName, setSelectedCompanyName] = useState("");  //UPDATE THIS IF COMPANY NAME IS OTHER


    //FILTER COMPANIES (FIND THAT ARE RELEVANT TO SELECTED INSURANCE TYPE I.E CMOPREHENSIVE COMPANIES ETC) AND ADD THEM TO FILTER COMPANIES STATE 
    const executeFilterCompanies = () => {
        const options = [{ value: 0, label: "Select" }];
        insuranceCompanies.map(company => {
            if (company.type == type) {
                options.push({ value: company.id, label: company.name })
            }
        })
        setFilterCompanies(options);
    }

    useEffect(() => {
        executeFilterCompanies();
    }, [])

    const updateSelectedCompanyName = (companyId) => {
        var option = { value: 0, label: "Select" };
        insuranceCompanies.map(company => {
            if (company.id == companyId) { option = { value: company.id, label: company.name }; }
        })
        if (option["label"].toLocaleLowerCase() === "other") { setSelectedCompanyName("other"); }
        else { setSelectedCompanyName(option["label"]) }
    }

    return (
        <>
            <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">{labelPrefix} Company</label>
                <Field name={`insurances[${index}].insuranceCompanyId`}>
                    {({ field }) => (
                        <SelectField
                            fieldName={`insurances[${index}].insuranceCompanyId`}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={filterCompanies}
                            setFieldValue={setFieldValue}
                            callback={(name, value, fun) => { updateSelectedCompanyName(value); }}
                        />
                    )}
                </Field>
            </div>

            {
                selectedCompanyName === "other" ?
                    <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Company Name</label>
                        <Field name={`insurances[${index}].extraDetail.companyName`}  >
                            {({ field }) => (
                                <InputField
                                    fieldName={`insurances[${index}].extraDetail.companyName`}
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div> : ""
            }

            <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Is Financed</label>
                <Field name={`insurances[${index}].extraDetail.isFinanced`}>
                    {({ field }) => (
                        <SelectField
                            fieldName={`insurances[${index}].extraDetail.isFinanced`}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">{labelPrefix} Premium</label>
                <Field name={`insurances[${index}].grossPremium`}  >
                    {({ field }) => (
                        <FloatField
                            fieldName={`insurances[${index}].grossPremium`}
                            fieldValue={field.value}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            {
                type == "comprehensive" ?
                    <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Policy Number</label>
                        <Field name={`insurances[${index}].extraDetail.policyNumber`}  >
                            {({ field }) => (
                                <InputField
                                    fieldName={`insurances[${index}].extraDetail.policyNumber`}
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div> : ""
            }

            <div className="col-md-2 col-sm-4  mb-4 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Expiry Date</label>
                {<Field type="text" name={`insurances[${index}].expiryDate`}  >
                    {({ field }) => (
                        <MaskedField
                            fieldName={`insurances[${index}].expiryDate`}
                            fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""}
                            maskRule="date"
                            fieldPlaceholder="dd/mm/yyyy"
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>}
            </div>

            <div className='col-sm-12'></div>

        </>

    )
}




export default SingleInsurance;

/*
field.value !== undefined && field.value.length === 10 && field.value.includes("/") && dateFilter(field.value) !== 0 ?
                                 dateFilter(field.value) 
                                 : formatDate(field.value, 'unix', 'DD/MM/YYYY')
*/
