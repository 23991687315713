import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getAnalystInsuranceReport } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";

const AnalystinsuranceReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [insurances, setInsurances] = useState([]);
    const [totalSettlements, setTotalSettlements] = useState(0);
    const [insurancesCount, setInsurancesCount] = useState(0);   //AVERAGE PRODUCTS PER DEAL 

    const token = useToken();

    //FETCH ANALYST INSURANCE REPORT
    const fetchAnalystinsuranceReport = async () => {
        setProcessing(true);
        var response = await getAnalystInsuranceReport(token, startDate, endDate);
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined) {
            var records = response["data"];

            if (records !== null && records["insurances"] !== undefined && Array.isArray(records["insurances"])) {
                setInsurances(records["insurances"]);

                var tmpTotalInsurances = 0;
                records["insurances"].map(singleInsurance => {
                    var tmpSingleTotalInsurances = singleInsurance["totalInsurances"] !== undefined && (!isNaN(parseInt(singleInsurance["totalInsurances"]))) ? singleInsurance["totalInsurances"] : 0;
                    tmpTotalInsurances += parseInt(tmpSingleTotalInsurances);
                })
                setInsurancesCount(tmpTotalInsurances);
            }
            else { setInsurances([]); }

            //UPDATE TOTAL SETTLEMENTS
            if (records !== null && records["totalSettlements"] !== undefined && (!isNaN(parseInt(records["totalSettlements"])))) { setTotalSettlements(records["totalSettlements"]); }
            else { setTotalSettlements(0); }
        }
        setProcessing(false);
    }

    useEffect(() => {
        fetchAnalystinsuranceReport()
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="bold mb-4">My Insurance</div>
                    {
                        processing ? <LoadingBlock />
                            :
                            <div className="table-responsive">
                                <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                    <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                            <th className="p-0 pb-3 text-start">Type</th>
                                            <th className="p-0 pb-3 th-center-aligh text-start" >PPD</th>
                                            <th className="p-0 pb-3 th-center-aligh text-start" >Count</th>
                                            <th className="p-0 pb-3 th-center-aligh text-start" > %</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            Array.isArray(insurances) ?
                                                insurances.map(singleInsurance => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{singleInsurance["type"]} </td>
                                                                <td>{totalSettlements > 0 ? (singleInsurance["totalInsurances"] / totalSettlements).toFixed(2) : 0} </td>
                                                                <td>{singleInsurance["totalInsurances"]} </td>
                                                                <td>{totalSettlements > 0 ? ((singleInsurance["totalInsurances"] / totalSettlements) * 100).toFixed(2) : 0} </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                                : "<tr><tr>"
                                        }
                                        <tr>
                                            <td><span className="bold">Average Products Per Deal</span></td>
                                            <td><span className="bold">{totalSettlements > 0 ? ((insurancesCount / totalSettlements)).toFixed(2) : 0} </span></td>
                                            <td><span className="bold">{insurancesCount} </span></td>
                                            <td><span className="bold">{totalSettlements > 0 ? ((insurancesCount / totalSettlements) * 100).toFixed(2) : 0} </span> </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                    }
                </div>
            </div>
        </>
    )

}

export default AnalystinsuranceReport;