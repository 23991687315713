import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";

import { performApplicationAdditionalActions, getReferrer, getReferrersList, getUsers } from "../../../api";
import { useToken } from '../../../hooks/useAuth';

import LoadingScreen from '../../global/LoadingScreen';
import CoverSheetSchema from '../schema/CoverSheet.json';

const CoverSheet = ({ application, applicants, applicant, applicationId, callback }) => {

    const [coverSheet, setCoverSheet] = useState(CoverSheetSchema);
    const [referrers, setReferrers] = useState([]);
    const [referrersOptions, setReferrersOptions] = useState([]);
    const [selectedReferrer, setSelectedReferrer] = useState({});

    const [referrerUsers, setReferrerUsers] = useState([]);
    const [referrerUsersOptions, setReferrerUsersOptions] = useState([]);
    const [selectedReferrerUser, setSelectedReferrerUser] = useState({});

    const [processing, setProcessing] = useState(false)

    var token = useToken();

    //FECTH REFERRERS
    const fetchReferrers = async () => {
        var response = await getReferrersList(token);
        console.log("response", response);
        if (response["error"] !== undefined && response["error"] == 0 && response["referrers"] !== undefined && Array.isArray(response["referrers"])) {
            setReferrers(response["referrers"]);
        }
    }

    //FETCH REFERRER USERS
    const fetchReferrerUsers = async (referrerId) => {
        const response = await getUsers(token, "referrer", referrerId);
        var tmpReferrersUsers = response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"]) ? response["data"] : [];
        if (Array.isArray(tmpReferrersUsers)) { setReferrerUsers(tmpReferrersUsers); }
    }

    //CREATE DROPDOWN OPTIONS LIST FOR USERS
    const createReferrerUsersOptions = async () => {
        if (Array.isArray(referrers)) {
            var optionsPromises = referrerUsers.map(async (singleReferrerUser) => {
                var firstName = singleReferrerUser["firstName"] !== undefined ? singleReferrerUser["firstName"] : "";
                var surname = singleReferrerUser["surname"] !== undefined ? singleReferrerUser["surname"] : "";
                var name = firstName + " " + surname;
                return { value: singleReferrerUser["id"], label: name }
            })
            await Promise.all(optionsPromises).then(options => {
                var localOptins = options;
                localOptins.unshift({ "value": 0, "label": "Select" });
                setReferrerUsersOptions(localOptins);
            })
        }
    }


    //CREATA DROPDOWN OPTION LIST FOR REFERRER WHEN REFERRER STATE CHANGES
    const createReferrerOptions = async () => {
        if (Array.isArray(referrers)) {
            var optionsPromises = referrers.map(async (singleReferrer) => {
                return { value: singleReferrer["id"], label: singleReferrer["tradingName"] }
            })
            await Promise.all(optionsPromises).then(options => {
                setReferrersOptions(options);
            })
        }

    }

    const handler = async (name, value, setFieldValue) => {
        switch (name) {
            case "referrer.name":
                //FIND SELECTED REFERRERF FROM REFERRERS STATE BASED ON THE REFERRER ID
                var tmpSelectedReferrer = referrers.find(singleReferrer => singleReferrer.id == value);
                if (tmpSelectedReferrer !== undefined) {
                    setFieldValue("referrer.contactNumber", tmpSelectedReferrer.contactNumber !== undefined ? tmpSelectedReferrer.contactNumber : "")
                    //CALL TO RETRIEVE USERS
                    fetchReferrerUsers(value);
                    setSelectedReferrer(tmpSelectedReferrer);
                }
                else {
                    setSelectedReferrer({})
                }
                break;
            case "referrer.employeeName":
                //FIND SELECTED REFFER USER AND UPDATE THE OCNTACT NUMBER
                var tmpSelectedReferrerUser = referrerUsers.find(singleReferrerUser => singleReferrerUser.id == value);
                if (tmpSelectedReferrerUser !== undefined) {
                    setFieldValue("referrer.contactNumber", tmpSelectedReferrerUser.contactNumber !== undefined ? tmpSelectedReferrerUser.contactNumber : "");
                    setSelectedReferrerUser(tmpSelectedReferrerUser);
                }
                else {
                    setSelectedReferrerUser({})
                }
                break;

        }

    }

    //FETCH REFERRER UPON FIRST RENDER
    useEffect(() => {
        fetchReferrers();
    }, [])

    //CREATE REFERRER OPTIONS FOR DROPDOWN IF REFERRERS STATE CHANGES
    useEffect(() => {
        createReferrerOptions();
    }, [referrers])

    //CREATE REFERRER USERS OPTIONS FOR DROPDOWN IF REFERRERS USERS STATE CHANGES
    useEffect(() => {
        createReferrerUsersOptions();
    }, [referrerUsers])


    //EXTRACT APPLICANT DETAILS WHEN application CHANGES
    useEffect(() => {
        console.log("application", application)
        //UPDAET INITIAL VALUES
        var dateIn = application["createdAt"] !== undefined && application["createdAt"] !== 0 ? formatDate(application["createdAt"], "unix", "DD/MM/YYYY") : "";

        var applicant1 = applicants[0] ? applicants[0] : {}
        var applicant2 = applicants[1] ? applicants[1] : {}

        var personalDetailsApplicant1 = applicant1["personalDetail"] !== undefined ? applicant1["personalDetail"] : {}
        var personalDetailsApplicant2 = applicant2["personalDetail"] !== undefined ? applicant2["personalDetail"] : {}

        var applicant1FirstName = personalDetailsApplicant1["firstName"] !== undefined ? personalDetailsApplicant1["firstName"] : "";
        var applicant1Surname = personalDetailsApplicant1["surname"] !== undefined ? personalDetailsApplicant1["surname"] : "";
        var applicant1ContactNumber = personalDetailsApplicant1["mobileNumber"] !== undefined ? personalDetailsApplicant1["mobileNumber"] : "";

        var applicant2FirstName = personalDetailsApplicant2["firstName"] !== undefined ? personalDetailsApplicant2["firstName"] : "";
        var applicant2Surname = personalDetailsApplicant2["surname"] !== undefined ? personalDetailsApplicant2["surname"] : "";
        var applicant2ContactNumber = personalDetailsApplicant2["mobileNumber"] !== undefined ? personalDetailsApplicant2["mobileNumber"] : "";


        var initialValues = CoverSheetSchema;

        initialValues["dateIn"] = dateIn;

        initialValues["applicants"][0]["surname"] = applicant1Surname;
        initialValues["applicants"][0]["givenName"] = applicant1FirstName;
        initialValues["applicants"][0]["contactNumber"] = applicant1ContactNumber;

        initialValues["applicants"][1]["surname"] = applicant2Surname;
        initialValues["applicants"][1]["givenName"] = applicant2FirstName;
        initialValues["applicants"][1]["contactNumber"] = applicant2ContactNumber;

        setCoverSheet({...initialValues });

    }, [application])

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={coverSheet}
                        enableReinitialize={true}
                        validateOnBlur={false}

                        onSubmit={async (values) => {
                            var valuesClone = JSON.parse(JSON.stringify(values));


                            //REPLACE REFERRER NAME AND USER NAME 
                            valuesClone["referrer"]["name"] = selectedReferrer["tradingName"] !== undefined ? selectedReferrer["tradingName"] : "";

                            var firstName = selectedReferrerUser["firstName"] !== undefined ? selectedReferrerUser["firstName"] : "";
                            var surname = selectedReferrerUser["surname"] !== undefined ? selectedReferrerUser["surname"] : "";
                            var name = firstName + " " + surname;
                            valuesClone["referrer"]["employeeName"] = name;
                            //coverSheet
                            //MAKE AN API CALL 
                            setProcessing(true);
                            var response = await performApplicationAdditionalActions(token, "coverSheet", applicationId, valuesClone);
                           
                            var responseResult = response.data !== undefined ? response.data : {};
                            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                                //DOWNLOAD FILE
                                var pdfBytes = responseResult["pdfBytes"];
                                //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                                const bytes = new Uint8Array(Object.values(pdfBytes));
                                const blob = new Blob([bytes], { type: "application/pdf" });
                                const docUrl = URL.createObjectURL(blob);

                                let alink = document.createElement('a');
                                alink.href = docUrl;
                                alink.download = 'Cover Sheet.pdf';
                                alink.click();
                                Notifications("success", responseResult["message"] || "Downloaded Successfully. ")
                                callback("closeDrawer");

                            }
                            else {
                                Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error")
                            }
                            setProcessing(false);
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table style={{ minWidth: "800px", width: "100%" }} className="borderTable">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}><Field name="applicants[0].givenName" className="form-control formField2" /></td>
                                                    <td colSpan={1}><Field name="applicants[0].surname" className="form-control formField2" /></td>
                                                    <td colSpan={2}>Customer</td>
                                                    <td colSpan={2}>Contact #</td>
                                                    <td colSpan={3}><Field name="applicants[0].contactNumber" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><Field name="applicants[1].givenName" className="form-control formField2" /></td>
                                                    <td colSpan={1}><Field name="applicants[1].surname" className="form-control formField2" /></td>
                                                    <td colSpan={2}>Customer</td>
                                                    <td colSpan={2}>Contact #</td>
                                                    <td colSpan={3}><Field name="applicants[1].contactNumber" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <Field name="referrer.name">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="referrer.name"
                                                                    defaultSelectedOption={{ value: field.value, label: field.value }}
                                                                    options={referrersOptions}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={(name, value) => { handler(name, value, setFieldValue) }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan={2}>Referrer</td>
                                                    <td colSpan={2}>Contact #</td>
                                                    <td colSpan={3}><Field name="referrer.contactNumber" className="form-control formField2" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <Field name="referrer.employeeName">
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName="referrer.employeeName"
                                                                    defaultSelectedOption={{ value: field.value, label: field.value }}
                                                                    options={referrerUsersOptions}
                                                                    setFieldValue={setFieldValue}
                                                                    callback={(name, value) => { handler(name, value, setFieldValue) }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan={2}>Contact/Employee</td>
                                                    <td colSpan={2}>Date In</td>
                                                    <td colSpan={3}><Field name="dateIn" className="form-control formField2" /></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        {/********************DECLINED REASONS **************************** */}
                                        <table style={{ minWidth: "800px", width: "100%" }} className="borderTable">
                                            <tbody><tr><th colSpan="16" className="th-no-border"></th></tr>
                                                <tr>
                                                    <td colSpan="5" className="field-color"><p style={{ float: "right" }}><b>DATE APPROVED</b></p></td>
                                                    <td colSpan="2"><Field name="approvedDate" className="form-control formField2" /> </td>
                                                    <td colSpan="9" className="field-color"> <h3 className="text-center">REASONS FOR APPLICATION DECLINED</h3></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="field-color"><p style={{ float: "right" }}> <b>APPROVED THROUGH</b></p></td>
                                                    <td colSpan="2"> <Field name="approvedThrough" className="form-control formField2" /> </td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.adverseVeda">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.adverseVeda"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="3"> <p>Adverse Veda</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.vehicleType">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.vehicleType"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="4"> <p>Vehicle Type</p> </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="field-color"><p style={{ float: "right" }}><b>General Advice Warning Provided</b></p></td>
                                                    <td colSpan="2"><Field name="generalAdvice" className="form-control formField2" />  </td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.capacity">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.capacity"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="3"> <p>Capacity</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.adverseCredit">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.adverseCredit"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="4"> <p>Adverse Credit</p> </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="field-color"></td>
                                                    <td colSpan="2">  </td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.employmentStatus">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.employmentStatus"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="3"> <p>Employment Status</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.customerDeclined">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.customerDeclined"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="4"> <p>Customer Declined</p> </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="5" className="field-color"></td>
                                                    <td colSpan="2"> </td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.vehicleBooking">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.vehicleBooking"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="3"> <p>Vehicle Booking</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="declinedReasons.other.active">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="declinedReasons.other.active"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="4"><Field name="declinedReasons.other.description" className="form-control formField2" /> </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="7" style={{ borderRightStyle: "hidden", borderBottomStyle: "hidden", borderLeftStyle: "hidden" }}></td>
                                                    <td colSpan="7" style={{ borderBottomStyle: "hidden" }}> <h3>IF DECLINED, DATE FOR FOLLOW UP</h3></td>
                                                    <td colSpan="2"><Field name="followupDate" className="form-control formField2" /> </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="2" className="field-color" style={{ borderStyle: "hidden" }}><h3>Source of Business</h3></td>
                                                    <td colSpan="2" style={{ borderStyle: "hidden" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>Dealer of Referral</div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Field name="businessSource.dealerReferal">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            name="businessSource.dealerReferal"
                                                                            defaultChecked={field.value}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan="2" style={{ borderStyle: "hidden" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>Direct/Repeat</div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Field name="businessSource.direct">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            name="businessSource.direct"
                                                                            defaultChecked={field.value}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colSpan="2" style={{ borderStyle: "hidden" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>Money Bunny</div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Field name="businessSource.moneyBunny">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            name="businessSource.moneyBunny"
                                                                            defaultChecked={field.value}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div></td>
                                                    <td colSpan="2" style={{ borderStyle: "hidden" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>NfAL Website</div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <Field name="businessSource.nfalWebsite">
                                                                    {({ field }) => (
                                                                        <CheckBox
                                                                            name="businessSource.nfalWebsite"
                                                                            defaultChecked={field.value}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        {/*************************NOTES SECTION******************* */}
                                        <table style={{ minWidth: "800px", width: "100%", marginTop: "10px" }} className="borderTable">
                                            <tbody>

                                                <tr>
                                                    <td colSpan="12" className=" text-center " style={{ backgroundColor: "lightgreen" }}>
                                                        <h3>SUPPLIERS TO BE UPDATED ON APPLICATION STATUS DAILY</h3></td>
                                                </tr>
                                                <tr className="big-fields">
                                                    <td className="field-color text-center " colSpan="2" style={{ backgroundColor: "lightgreen" }}> <h3>DATE</h3></td>
                                                    <td className="field-color text-center" colSpan="2" style={{ backgroundColor: "lightgreen" }}> <h3>TIME</h3></td>
                                                    <td className="field-color text-center" colSpan="2" style={{ backgroundColor: "lightgreen" }}> <h3>ACTION</h3></td>
                                                    <td className="field-color text-center" colSpan="1" style={{ backgroundColor: "lightgreen" }}><h3>✔</h3></td>
                                                    <td className="field-color text-center" colSpan="5" style={{ backgroundColor: "lightgreen" }}> <h3>COMMENTS</h3></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="2"><Field name="notes[0].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[0].time" className="form-control formField2" />   </td>
                                                    <td colSpan="2"> <p>Veda Check</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="actions.vedaCheck">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="actions.vedaCheck"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="5"> <h3><Field name="notes[0].comment" className="form-control formField2" />  </h3></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[1].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[1].time" className="form-control formField2" />   </td>
                                                    <td colSpan="2"> <p>Book Vehicle</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="actions.bookVehicle">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="actions.bookVehicle"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="5"> <h3><Field name="notes[1].comment" className="form-control formField2" />  </h3></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[2].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[2].time" className="form-control formField2" />   </td>
                                                    <td colSpan="2"> <p>Call back Supplier</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="actions.callBackSupplier">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="actions.callBackSupplier"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="5"> <h3><Field name="notes[2].comment" className="form-control formField2" />  </h3></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[3].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[3].time" className="form-control formField2" />   </td>
                                                    <td colSpan="2"> <p>Credit Guide/Quote emailed to Customer</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="actions.creditGuideEmailed">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="actions.creditGuideEmailed"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="5"> <h3><Field name="notes[3].comment" className="form-control formField2" />  </h3></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="2"><Field name="notes[4].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[4].time" className="form-control formField2" />   </td>
                                                    <td colSpan="2"> <p>Prelim. Completed</p></td>
                                                    <td colSpan="1" className="text-center">
                                                        <Field name="actions.prelimCompleted">
                                                            {({ field }) => (
                                                                <CheckBox
                                                                    name="actions.prelimCompleted"
                                                                    defaultChecked={field.value}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td colSpan="5"> <h3><Field name="notes[4].comment" className="form-control formField2" />  </h3></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[5].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[5].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[5].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[6].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[6].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[6].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[7].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[7].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[7].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[8].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[8].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[8].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[9].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[9].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[9].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[10].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[10].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[10].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[11].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[11].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[11].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[12].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[12].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[12].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[13].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[13].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[13].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[14].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[14].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[14].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[15].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[15].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[15].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[16].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[16].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[16].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[17].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[17].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[17].comment" className="form-control formField2" />   </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><Field name="notes[18].date" className="form-control formField2" />   </td>
                                                    <td colSpan="2"><Field name="notes[18].time" className="form-control formField2" />   </td>
                                                    <td colSpan="8"><Field name="notes[18].comment" className="form-control formField2" />   </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="separator separator-dashed my-5"></div>

                                <div className="col-xl-12">
                                    {Object.keys(errors).length == 0 ? "" : "Please make sure that you have filled all required fields."}
                                    {<FormSubmissionButton isActive={true} />}
                                </div>
                            </Form>

                        )}

                    </Formik>

                </div>
            </div>
            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default CoverSheet;