import react from 'react';
import Notifications from '../../components/global/Notifications';
 
const Dashboard = () => {
    return (
        <>          
            <Notifications notificationType = {"success"} message={"Welcome"} />
        </>
    )
}

export default Dashboard;

/*
MODULES: 

CREDIT REP
REFERRER GROUP
REFERRER
LENDER

APPLICATIONS
SETTLEMENTS
INSURANCE

*/