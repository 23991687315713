import { useEffect, useState } from "react";
import { InputField, SelectField, CustomButton } from "../HtmlComponents";
import { capitalizeFirstLowercaseRest } from "../../helpers";

const UpdateInsuranceCompany = ({ insuranceTypes, callback, singleCompany }) => {
    const [company, setCompany] = useState(singleCompany);  //{ name: "", repName: "", contactNumber: "", email: "", type: "" }
    const [submitReady, setSubmitReady] = useState(false);

    const updaetHandler = (name, value) => {
        setCompany({ ...company, [name]: value });
    }

    useEffect(() => {
        //if company name and type is selected then enable the submission otherwise disable it 
        if (company.name.length > 0 && company.type.length > 0 && company.repName.length > 0) { setSubmitReady(true); }
        else { setSubmitReady(false); }

    }, [company])
    return (
        <>
            <div className="row mb-5">
                <div className="col-md-12 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Name</label>
                    <InputField
                        fieldName="name"
                        fieldValue={company.name}
                        placeholder="Name"
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row mt-4 fv-plugins-icon-container ">
                    <label className="fs-8 fw-bold mb-2">Rep Name</label>
                    <InputField
                        fieldName="repName"
                        fieldValue={company.repName}
                        placeholder="Name"
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row  mt-4  fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Contact Number</label>
                    <InputField
                        fieldName="contactNumber"
                        fieldValue={company.contactNumber}
                        placeholder=""
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Email</label>
                    <InputField
                        fieldName="email"
                        fieldValue={company.email}
                        placeholder=""
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Xero Contact Id</label>
                    <InputField
                        fieldName="xeroContactId"
                        fieldValue={company.xeroContactId}
                        placeholder=""
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Xero Account Id</label>
                    <InputField
                        fieldName="xeroAccountId"
                        fieldValue={company.xeroAccountId}
                        placeholder=""
                        setFieldValue={updaetHandler}
                    />
                </div>
                <div className="col-md-12 fv-row  mt-4 fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2">Type</label>
                    <div style={{ width: "100%", height: "35px", backgroundColor: "#2d2b2b0f", paddingLeft: "10px", paddingTop: "7px", fontWeight: "bold", borderRadius: "5px" }}> {capitalizeFirstLowercaseRest(company["type"])} </div>
                </div>

                <div className="col-sm-12">
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                        <CustomButton
                            buttonClassName="btn btn-primary btn-sm "
                            isActive={submitReady}
                            buttonStyle={{ marginLeft: "20px" }}
                            buttonOnClick={() => { callback("executeUpdateInsuranceCompany", company) }}
                            buttonText="Save"
                        />
                    </div>

                </div>

            </div>

        </>
    )
}

export default UpdateInsuranceCompany;