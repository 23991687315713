import * as Yup from 'yup';

const EquifaxEnquiryValidationSchema =  Yup.object().shape({
    
    addresses: Yup.object().shape({
        currentAddress :Yup.object().shape({
            state : Yup.string().required('Required'),
            streetType : Yup.string().required('Required'),
        })
    }),
});
 
export default EquifaxEnquiryValidationSchema ;