import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton } from '../HtmlComponents';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import { getCreditRep } from "../../api/creditRepApi";
import Files from '../files/Files';
import ListUsers from "../users/ListUsers";
import ListDirectors from "../directors/ListDirectors";
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';

import AddUser from "../users/AddUser";
import UpdateUser from "../users/UpdateUser";

import CreateDirector from "../directors/CreateDirector";
import UpdateDirector from "../directors/UpdateDirector";

const CreditRepDetail = () => {
    const [singleCreditRep, setSingleCreditRep] = useState({});
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "CreditRep Files", childName: "", meta: { width: "800" } });

    let { id } = useParams();
    const token = useToken();

    useEffect(() => {
        fetchCreditRepDetail();
    }, []);


    const fetchCreditRepDetail = async () => {
        const creditRep = await getCreditRep(id, token);
        console.log("SINGLE CREDIT REP DETAIL");
        console.log(creditRep)
        setSingleCreditRep(creditRep);
    }

    const payloadUpdateHandler = (action, values, meta = {}) =>{
        try{
            switch(action){
                case "addUser":
                    var users = singleCreditRep.users;
                     if(!Array.isArray(users)){users = [];}
                     users.push(values);                     
                    setSingleCreditRep({...singleCreditRep, users: users });  //UPDAET THE STATE    
                    drawHandler("closeDrawer");  //CLOSE RIGHT DRAW
                    break;                    
                case "updateUser":
                    var users = singleCreditRep.users;
                    var newUsers = [];
                    users.map( (user) =>{
                        if(user.id == values.id){  newUsers.push(values); }
                        else{ newUsers.push(user); }
                    });                    
                    setSingleCreditRep({...singleCreditRep, users: newUsers }); //UPDAET THE STATE 
                    drawHandler("closeDrawer");  //CLOSE RIGHT DRAW
                    break;
                case "deleteUser":
                        var users = singleCreditRep.users;
                        var newUsers = [];
                        users.map((user) => { if (user.id !== values.id) {  newUsers.push(user); } })
                        setSingleCreditRep({...singleCreditRep, users: newUsers }); //UPDAET THE STATE
                        break;    
                case "createDirector":
                    //ADD THE NEW DIRECTOR TO DIRECTORS ARRAY
                    var directors = singleCreditRep.directors;
                    if(Array.isArray(directors)){ directors.push(values); }
                    else{
                        directors = [];
                        directors.push(values);                           
                    }
                     //UPDAET THE STATE                    
                     setSingleCreditRep({...singleCreditRep, directors: directors });
                     drawHandler("closeDrawer");  //CLOSE RIGHT DRAW
                    break;
                case "updateDirector":
                    //FIND THE EXISTING DIRECTOR AND UPDATE THE DATA
                    var directors = singleCreditRep.directors;
                    var newDirectorsList = [];
                    directors.map( (director) =>{
                        if(director.id == values.id){
                            newDirectorsList.push(values);
                        }
                        else{
                            newDirectorsList.push(director);
                        }
                    })
                    //UPDAET THE STATE                    
                    setSingleCreditRep({...singleCreditRep, directors: newDirectorsList });
                    drawHandler("closeDrawer");  //CLOSE RIGHT DRAW

                    break;
                case "deleteDirector":
                    //FIND AND REMOVE 
                    break;        
            }
        }
        catch(e){
            console.log(e.message);
        }

    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "openDraw":
                setDrawerSettings({ ...drawerSettings, visible: true })
                break;
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openFilesDraw":
                setDrawerSettings({ visible: true, heading: "CreditRep Files", childName: "Files", meta: { width: "800" } });
                break;    
            case "openCreateAccreditedStaffDraw":
                setDrawerSettings({ visible: true, heading: "Add User", childName: "AddUser", meta: { width: "800" } });
                break;
            case "openUpdateAccreditedStaffDraw":
                    setDrawerSettings({ visible: true, heading: "Update User", childName: "UpdateUser", meta: { width: "800", userId : meta.userId ? meta.userId : 0 } });
                break;
            case "openCreateDirectorDraw":
                    setDrawerSettings({ visible: true, heading: "Create Director", childName: "CreateDirector", meta: { width: "800" } });
                break;
            case "openUpdateDirectorDraw":
                setDrawerSettings({ visible: true, heading: "Update Director", childName: "UpdateDirector", meta: { width: "800", directorId : meta.directorId ? meta.directorId : 0 } });
                break;        
        }
    }

    const BrokerageDetail = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Brokerage Details</h3>
                            {<EditButton URL={"/user/editCreditRep/" + singleCreditRep.id} />}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.tradingName}  </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name</td>
                                    <td className="text-start  fw-bold text-gray-600 "> {singleCreditRep.entityName} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.structure} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.abn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.acn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Date Established</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.dateEstablished && singleCreditRep.dateEstablished !== 0 ? formatDate(singleCreditRep.dateEstablished, 'unix', 'DD/MM/YYYY') : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Telephone</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.contactNumber} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.fax} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Business Address</td>
                                    <td className="text-start  fw-bold text-gray-600">  {singleCreditRep.address && singleCreditRep.address.fullAddress ? singleCreditRep.address.fullAddress : ""}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.websiteUrl} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.email}  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const AssociationAndMemberships = () => {
        return (<>

            <div className="card mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Associations & Memberships</h3>
                            { /*<EditButton URL=  {"/user/editCreditRep/" + singleCreditRep.id} />*/}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Insurer</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.associations && singleCreditRep.associations.insurer && singleCreditRep.associations.insurer.name ? singleCreditRep.associations.insurer.name : ""}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Policy Number</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.associations && singleCreditRep.associations.insurer && singleCreditRep.associations.insurer.policyNumber ? singleCreditRep.associations.insurer.policyNumber : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Expiry Date</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.associations && singleCreditRep.associations.insurer && singleCreditRep.associations.insurer.expiryDate ? formatDate(singleCreditRep.associations.insurer.expiryDate, 'unix', 'DD/MM/YYYY') : ""}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">AFCA Membership #</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.associations && singleCreditRep.associations.afcaMembership && singleCreditRep.associations.afcaMembership.membershipNumber ? singleCreditRep.associations.afcaMembership.membershipNumber : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">AFCA Expiry Date</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.associations && singleCreditRep.associations.afcaMembership && singleCreditRep.associations.afcaMembership.expiryDate ? formatDate(singleCreditRep.associations.afcaMembership.expiryDate, 'unix', 'DD/MM/YYYY') : ""} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>)
    }

    const BankingDetails = () => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                            {/* <EditButton URL=  {"/user/editCreditRep/" + singleCreditRep.id} />*/}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.bankingDetail && singleCreditRep.bankingDetail.name ? singleCreditRep.bankingDetail.name : ""}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Branch</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.bankingDetail && singleCreditRep.bankingDetail.branchName ? singleCreditRep.bankingDetail.branchName : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.bankingDetail && singleCreditRep.bankingDetail.bsb ? singleCreditRep.bankingDetail.bsb : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Account Number</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleCreditRep.bankingDetail && singleCreditRep.bankingDetail.accountNumber ? singleCreditRep.bankingDetail.accountNumber : ""} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const AccreditedStaff = () => {
        return (<>

            <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                <div className="d-flex flex-stack">
                    <h3 className="m-0 text-white fw-bolder fs-3">Staff Accredation</h3>
                    {
                        <CustomButton
                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                            isActive={true}
                            buttonText="Add"
                            buttonOnClick={() => { drawHandler("openCreateAccreditedStaffDraw") }}
                        />
                    }
                </div>
            </div>
            <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                <ListUsers users={singleCreditRep && singleCreditRep.users ? singleCreditRep.users : []}  callback = { (action, id) => {
                    console.log("USER ID: ", id);
                    switch (action) {
                        case "updateUser":
                            drawHandler("openUpdateAccreditedStaffDraw" , {userId : id})
                            break;
                        case "refreshUsersListing":
                            payloadUpdateHandler("deleteUser", {id: id})
                            break;
                    }
                }} />
            </div>
        </>)
    }

    const DirectorsListing = () => {
        return (<>
            <div className="px-9 pt-3 card-rounded  w-100 bg-primary  pb-3" style={{ "marginTop": "30px" }}>
                <div className="d-flex flex-stack">
                    <h3 className="m-0 text-white fw-bolder fs-3">Director/ Partner Details</h3>
                    { <CustomButton
                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                            isActive={true}
                            buttonText="Add"
                            buttonOnClick={() => { drawHandler("openCreateDirectorDraw") }}
                        />
                    }
                </div>
            </div>
            <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                <div className="row">
                    <ListDirectors directors={singleCreditRep && singleCreditRep.directors ? singleCreditRep.directors : []}  callback = { (id) => {
                    console.log("Director ID: ", id);
                    drawHandler("openUpdateDirectorDraw" , {directorId : id})
                }} />
                </div>
            </div>
        </>)
    }


    const SingleDirector = (data) => {
        return (
            <div className="col-xl-6">
                <div className="card card-body">
                    <div className="bg-body row shadow-sm card-rounded px-6 py-9" >
                        <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Home Address</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">D/License #</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">DOB</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Mobile #</td>
                                    <td className="text-start  fw-bold text-gray-600"> {data.director && data.director.firstName ? data.director.firstName : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }




    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-6">
                        {BrokerageDetail()}
                    </div>
                    <div className="col-xl-6">
                        {AssociationAndMemberships()}
                        {BankingDetails()}
                    </div>
                </div>

                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {AccreditedStaff()}
                    </div>
                </div>

                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {DirectorsListing()}
                    </div>
                </div>
            </div>
            <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" style={{ zIndex: "1" }}>
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { drawHandler("openFilesDraw") }} buttonText="Files" />
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "Files" ? <Files entityType="creditRep" entityId={id} enableUpload={true} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "AddUser" ? <AddUser entityType="creditRep" entityId={id} callback= { (data) => { payloadUpdateHandler("addUser" , data ) }}  /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateUser" ? <UpdateUser entityType="creditRep" entityId={id} userId = {drawerSettings["meta"]["userId"]} callback= { (data) => { payloadUpdateHandler("updateUser" , data ) }}  /> : ""
                    }
                    {
                        drawerSettings["childName"] == "CreateDirector" ? <CreateDirector entityType="creditRep" entityId={id} callback= { (data) => { payloadUpdateHandler("createDirector" , data ) }}  /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateDirector" ? <UpdateDirector directorId={drawerSettings["meta"]["directorId"]} callback= { (id, data) => { payloadUpdateHandler("updateDirector" , data ) }}  /> : ""
                    }
                </RightDrawer> : ""
            }

        </>
    );

}


export default CreditRepDetail;