import React, { useEffect, useState } from 'react';
import Select from 'react-select';

/* 
    defaultSelectedOption FORMAT : {value : "" , label : "" }
    OPTIONS FORMAT : [ {value: "" , label : "" } , {value: "" , label : "" } ]
    setFieldValue  : REFERENCE OF setFieldValue() FUNCTION

*/
const SelectFieldGroup = ({fieldName  , defaultSelectedOption , options, setFieldValue,  callback}) => {
     
    const [selectedOption, setSelectedOption] = useState(null);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#cccccc',
            minHeight: '35px',
            height: '35px',
            boxShadow: state.isFocused ? null : null,
          }),
      
          valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            padding: '0 6px'
          }),
      
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
          }),

          menuPortal : (provided, state) => ({
            ...provided,
            zIndex: 9999,
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 9999,
          }), 
      }

  
      useEffect( () => {
      }, [])
  
      
    useEffect( () => {


        let findSelectedOption = {};
        if(defaultSelectedOption !== undefined && defaultSelectedOption.value !== undefined){
        if(Array.isArray(options)){
          options.map(optionGroup => {
            if(optionGroup.options !== undefined && Array.isArray(optionGroup.options)){
              optionGroup.options.map(singleOption => {
                if(singleOption.value === defaultSelectedOption.value ){
                  findSelectedOption = singleOption;
                }
              })
            }
          })
        }
        setSelectedOption(findSelectedOption);
      }

    }, [defaultSelectedOption])  

    return (    
        <Select
          maxMenuHeight={220}
          menuPlacement="auto"
          styles={customStyles}
          name = {fieldName}  
          //value={ selectedOption}
          value={ selectedOption}
          options={options}
          onChange={
                async (selectedOption) => { 
                    await setSelectedOption(selectedOption)                  
                    await setFieldValue(fieldName, selectedOption.value)
                    if(typeof callback === "function") {callback(fieldName, selectedOption.value , setFieldValue); }
                }  
        }   
        />
      );

}
export default SelectFieldGroup;


 