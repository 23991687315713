import axios from '../api/axios';

/*GET SINGLE BOOKMARK*/
const getReportingBookmark = async (token, id) => {
    try {
      const response = await axios.get(`reportingBookmark/${id}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

/*GET ALL BOOKMARKS AGAINST LOGGED IN USER*/
const getReportingBookmarks = async (token) => {
    try {
      const response = await axios.get(`reportingBookmark`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  //
const createReportingBookmark = async (token,  payload) => {
    try {
      const response = await axios.post(`reportingBookmark/`, payload , { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  const deleteReportingBookmark = async (token,  id) => {
    try {
      const response = await axios.delete(`reportingBookmark/${id}` , { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }



  export{
    getReportingBookmark, getReportingBookmarks, createReportingBookmark , deleteReportingBookmark
  }