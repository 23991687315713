import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton, RightDrawer, TextArea } from '../../../HtmlComponents';
import { formatDate, getCurrentDate } from '../../../../helpers';
import Notifications from "../../../global/Notifications";
import SelectFieldGroup from '../../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getLendersList, getLender, getComments, getFolders, getFiles, } from "../../../../api";
import { useToken } from '../../../../hooks/useAuth';
import LoadingScreen from '../../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../../schema/SubmitToLenderValidationSchema';


const FinanceOne = ({ applicationId }) => {

    const [initialValues, setInitialValues] = useState({ lender: "financeOne", applicationType: "consumer", productType: "", frequency: "", repaymentAmount: "", paymentMethod: "", creditHistory: "" });
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");

    const token = useToken();
    const formRef = useRef();

    const financeOneOptoins = {
        "productType": [
            { value: "CON.S.BRO", label: "Bronze  -  Lend amount : 8000 - 25,000, Term : 36 - 60 Months, Interest : 26.5 - 28.5", type: "consumer" },
            { value: "CON.S.ECO", label: "Economy  -  Lend amount : 5000 - 12,000, Term : 36 - 48 Months, Interest : 24 - 24", type: "consumer" },
            { value: "CON.S.GOL", label: "Gold  -  Lend amount : 8000 - 75,000, Term : 36 - 84 Months, Interest : 17.5 - 19.5", type: "consumer" },
            { value: "CON.S.PLAT", label: "Platinum  -  Lend amount : 8000 - 75,000, Term : 36 - 84 Months, Interest : 14.5 - 16.5", type: "consumer" },
            { value: "CON.S.SIL", label: "Silver  -  Lend amount : 8000 - 50,000, Term : 36 - 72 Months, Interest : 23.5 - 25.5", type: "consumer" },
            { value: "COM.S.GOL", label: "Gold  -  Lend amount : 8000 - 125,000, Term : 36 - 84 Months, Interest : NA", type: "commercial" },
            { value: "COM.S.SILV", label: "Silver  -  Lend amount : 8000 - 50,000, Term : 36 - 60 Months, Interest : NA ", type: "commercial" },
        ],
        "frequency": [{ value: "weekly", label: "Weekly" }, { value: "fortnightly", label: "Fortnightly" }, { value: "monthly", label: "Monthly" }],
        "repaymentAmount": "",
        "paymentMethod": [
            { value: "finance_under_contract", label: "The applicant wishes to finance the fees under the loan contract." },
            { value: "pay_upfront", label: "The applicant wishes to pay all their fees upfront." }
        ],
        "applicationType": [{ value: "", label: "Select" }, { value: "consumer", label: "Consumer" }, { value: "commercialCompany", label: "Commercial - Company" }]
    }


    const FinanceOneFields = ({ values, errors, setFieldValue }) => (
        <>
            <div className="col-sm-12"><p><b>Does not accept draft version</b></p></div>
            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Application Type</label>
                <Field name="applicationType">
                    {({ field }) => (
                        <SelectField
                            fieldName="applicationType"
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={financeOneOptoins["applicationType"]}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                {errors.applicationType !== undefined ? <div className="error"> {errors.applicationType} </div> : ""}
            </div>
            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Product Type</label>
                <Field name="productType">
                    {({ field }) => (
                        <SelectField
                            fieldName="productType"
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={values !== undefined && values.applicationType !== undefined && values.applicationType === "commercialCompany" ? financeOneOptoins["productType"].filter(item => item.type === "commercial") : financeOneOptoins["productType"].filter(item => item.type === "consumer")}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                {errors.productType !== undefined ? <div className="error"> {errors.productType} </div> : ""}
            </div>

            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Frequency</label>
                <Field name="frequency">
                    {({ field }) => (
                        <SelectField
                            fieldName="frequency"
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={financeOneOptoins["frequency"]}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                {errors.frequency !== undefined ? <div className="error"> {errors.frequency} </div> : ""}
            </div>
            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">The estimated repayment amount the applicant is capable of.</label>
                <Field name="repaymentAmount" className="form-control" style={{ height: "35px", fontWeight: "400" }} />
                {errors.repaymentAmount !== undefined ? <div className="error"> {errors.repaymentAmount} </div> : ""}
            </div>
            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Payment Method</label>
                <Field name="paymentMethod">
                    {({ field }) => (
                        <SelectField
                            fieldName="paymentMethod"
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={financeOneOptoins["paymentMethod"]}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                {errors.paymentMethod !== undefined ? <div className="error"> {errors.paymentMethod} </div> : ""}
            </div>
            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Credit History</label>
                <Field name="creditHistory">
                    {({ field }) => (
                        <TextArea
                            fieldName="creditHistory"
                            fieldValue={field.value}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                {errors.creditHistory !== undefined ? <div className="error"> {errors.creditHistory} </div> : ""}
            </div>

            <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2">Additional Notes</label>
                <Field name="notes">
                    {({ field }) => (
                        <TextArea
                            fieldName="notes"
                            fieldValue={field.value}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>



        </>
    )


    return (
        <>
            {<Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={SubmitToLenderValidationSchema}
                innerRef={formRef}
                validateOnBlur={false}

                onSubmit={async (values) => {
                    //MAKE AN API CALL 
                    setProcessing(true);
                    let valuesColne = JSON.parse(JSON.stringify(values));
                    console.log("valuesColne", valuesColne)



                    var response = await performApplicationAdditionalActions(token, "submitToLender", applicationId, valuesColne);
                    console.log("   response    LINE 638 ---  ------------------  ", response);
                    if (response["error"]) {
                        var errors = response["message"]["errors"];
                        var warning = response["message"]["warning"];

                        try {
                            setMessage(errors.join("\n"));
                            Notifications("error", errors.join("\n"))
                        }
                        catch (e) { console.log("EXCEPTION ", e); setMessage(JSON.stringify(response["message"])); }

                    }
                    else {
                        var responseResult = response.data !== undefined ? response.data : {};
                        var message = responseResult.message !== undefined ? responseResult.message : "";
                        var payload = responseResult.payload !== undefined ? responseResult.payload : {};
                        var applicationNo = payload.application !== undefined && payload.application.applicationNo !== undefined ? " #: " + payload.application.applicationNo : "";
                        Notifications("success", message)
                        setMessage("");
                    }
                    setProcessing(false);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div className="row">

                            <div className="col-sm-12 error mb-5" style={{ whiteSpace: "pre-wrap" }}> {message} </div>



                            <FinanceOneFields values={values} errors={errors} setFieldValue={setFieldValue} />
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="col-xl-12">
                            {<FormSubmissionButton isActive={true} label={"Submit To Lender"} />}
                        </div>
                    </Form>

                )}

            </Formik>
            }

            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default FinanceOne;