

const LoadingBlock = () => {

    return (
        <>
            <div style={{ display: "block", backgroundColor : "rgb(235 235 235 / 50%)", cursor: "wait" }}>
                <div style={{ textAlign: "center" }}>
                    <img src="/media/loading/loading1.gif" style={{ height: "210px" }} />
                </div>
            </div>
        </>
    )

}
export default LoadingBlock;