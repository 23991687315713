
import { useEffect, useState } from "react";
import SingleAsset from "./SingleAsset";
import Constants from "../../../../../components/global/Constants";

const ListAssets = ({ assets, assetsTypeList, setFieldValue, deleteAsset }) => {

    const [assetOwnershipList, setAssetOwnershipList] = useState([]);
    const [states, setStates] = useState([]);

    const FetchStates = () => {
        var options = [];
        Object.keys(Constants.STATES).map(key => {
            options.push({ value: key, label: Constants.STATES[key] });
        })
        setStates(options);
    }
    const FetchAssetOwnershipTYpes = () => {
        var options = [];
        Object.keys(Constants.ASSET_OWNERSHIP_TYPES).map(key => {
            options.push({ value: key, label: Constants.ASSET_OWNERSHIP_TYPES[key] });
        });
        setAssetOwnershipList(options);
    }

    useEffect(() => {
        FetchStates();
        FetchAssetOwnershipTYpes();
    }, [])

    return (
        assets.map((asset, index) => {
            return (
                <SingleAsset key={asset.uId !== undefined ? asset.uId : index}  index= {index} assetType={asset.assetType} assetsTypeList = {assetsTypeList}  states= {states}  setFieldValue = {setFieldValue} assetOwnershipList = {assetOwnershipList} deleteAsset = {deleteAsset} />
            )
        })
    )
}

export default ListAssets;