import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox, TextArea } from '../../HtmlComponents';
import { useToken } from '../../../hooks/useAuth';
import LoadingScreen from '../../global/LoadingScreen';


const ApplicationNotes = ({ initialValues, callback }) => {

    const [notes, setNotes] = useState({ referrerNotes: "", quoteNotes: "", personalDetailNotes: "", incomeNotes: "", assetsNotes: "", debtsNotes: "", expensesNotes: "" })
    const [existingNotes, setExistingNotesNotes] = useState({ referrerNotes: "", quoteNotes: "", personalDetailNotes: "", incomeNotes: "", assetsNotes: "", debtsNotes: "", expensesNotes: "" })
    const [processing, setProcessing] = useState(false);

    const token = useToken();

    useEffect(() => {
        var applicationNotes = initialValues["applicationNotes"] !== undefined && initialValues["applicationNotes"] !== null ? initialValues["applicationNotes"] : notes;
        setExistingNotesNotes(applicationNotes);
    }, [initialValues])



    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={notes}
                        enableReinitialize={true}
                        validateOnBlur={false}
                        onSubmit={async (values) => {
                            //MAKE AN API CALL 
                            callback("executeApplicationNotes", { oldNotes: existingNotes, newNotes: values });
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="fs-8 fw-bold mb-2">Referrers Notes </label>
                                        <Field name="referrerNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="referrerNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.referrerNotes !== undefined ? existingNotes.referrerNotes : ""} </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="fs-8 fw-bold mb-2">Quote Notes </label>
                                        <Field name="quoteNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="quoteNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.quoteNotes !== undefined ? existingNotes.quoteNotes : ""} </div>
                                    </div>

                                    <div className="col-sm-12 mt-5">
                                        <label className="fs-8 fw-bold mb-2">Personal Details Notes </label>
                                        <Field name="personalDetailNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="personalDetailNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.personalDetailNotes !== undefined ? existingNotes.personalDetailNotes : ""} </div>
                                    </div>

                                    <div className="col-sm-12 mt-5">
                                        <label className="fs-8 fw-bold mb-2">Income Notes </label>
                                        <Field name="incomeNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="incomeNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.incomeNotes !== undefined ? existingNotes.incomeNotes : ""} </div>
                                    </div>

                                    <div className="col-sm-12  mt-5">
                                        <label className="fs-8 fw-bold mb-2">Assets Notes </label>
                                        <Field name="assetsNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="assetsNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.assetsNotes !== undefined ? existingNotes.assetsNotes : ""} </div>
                                    </div>

                                    <div className="col-sm-12  mt-5">
                                        <label className="fs-8 fw-bold mb-2">Liability Notes </label>
                                        <Field name="debtsNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="debtsNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.debtsNotes !== undefined ? existingNotes.debtsNotes : ""} </div>
                                    </div>

                                    <div className="col-sm-12  mt-5">
                                        <label className="fs-8 fw-bold mb-2">Expenses Notes </label>
                                        <Field name="expensesNotes">
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="expensesNotes"
                                                    fieldValue={field.value}
                                                    placeholder={""}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                        <div className="mt-2"> {existingNotes !== undefined && existingNotes.expensesNotes !== undefined ? existingNotes.expensesNotes : ""} </div>
                                    </div>

                                    <div className="col-xl-12  mt-5"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default ApplicationNotes;