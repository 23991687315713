

import moment from "moment";

import { formatDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth, getStartAndEndDateForGivenPeriod, removeSpace } from '../../../../../helpers';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



export const months = { "1": "January", "2": "February", "3": "March", "4": "April", "5": "May", "6": "June", "7": "July", "8": "August", "9": "September", "10": "October", "11": "November", "12": "December" }
export const colors = { "1": "#8884d8", "2": "#82ca9d", "3": "#ffc658", "4": "#ff0000", "5": "#00ff00", "6": "#0000ff", "7": "#ff00ff", "8": "#00bcd4", "9": "#b35923", "10": "#95a5a6", "11": "#00897b", "12": "#556B2F" }


//GET ARRAY of PERIOD NAME FOR GIVEN DATE RANGE (STEP 2)
export const getPeriodNameArray = (startDateR, endDateR, periodicType) => {
    let startDate = moment(formatDate(startDateR, "unix", "YYYY-MM-DD HH:mm:ss"));
    let endDate = moment(formatDate(endDateR, "unix", "YYYY-MM-DD HH:mm:ss"));
    var betweenMonths = [];

    var dateParam = '';
    switch (periodicType) {
        case "daily": dateParam = 'day'; break;
        case "weekly": dateParam = 'week'; break;
        case "monthly": dateParam = 'month'; break;
        default: dateParam = 'month'; break;
    }

    if (startDate < endDate) {
        var date = startDate.startOf(dateParam);

        while (date < endDate.endOf(dateParam)) {
            switch (dateParam) {
                case "day":
                    betweenMonths.push(date.format('DD MMMM YYYY'));
                    date.add(1, 'day');
                    break;
                case "week":
                    betweenMonths.push("Week " + date.week() + date.format(' MMMM YYYY'));
                    date.add(1, 'week');
                    break;
                case "month":
                    betweenMonths.push(date.format('MMMM YYYY'));
                    date.add(1, 'month');
                    break;
            }

        }
    }
    return betweenMonths;
}


//GET FORMATTED DATA FOR PERIOD
export const getFormattedDataForPeriod = (entities, reportData, searchFilters) => {
    //STEP 1 - CREATE AN ARRAY OF PERIOD NAME
    let periodNameArray = getPeriodNameArray(searchFilters["period"]["startDate"], searchFilters["period"]["endDate"], searchFilters["periodicType"]);

    //STEP 2 - CREATE AN ARRAY OF ENTITIES NAME IN FORM OF OBJECTS
    let entitiesName = {};
    if(Array.isArray(entities) && entities.length > 0){
        entities.map(obj => {
            let tradingName = (obj.tradingName);
            entitiesName[tradingName] = 0;
            return obj.tradingName
        });
    }
 

    //STEP 3 - CREATE 2D ARRAY CONSISTS OF PERIOD NAME AND ENTITIES NAME
    let periodEntityArray = {};
    periodNameArray.map(singlePeriodName => {
        periodEntityArray[singlePeriodName] = JSON.parse(JSON.stringify(entitiesName));
    })

    //STEP 4 - UPDATE THE VALUES OF 2D ARRAY
    //let reportdata = data["report"] !== undefined && Array.isArray(data["report"]) ? data["report"] : [];

    if(!Array.isArray(reportData)){ return periodEntityArray;}
    
    reportData.map(singleRecord => {
        let week = singleRecord["week"];        //WEEK IS ONLY PROVIDED IF PERIODIC TYPE IS WEEKLY
        let month = singleRecord["month"];      //MONTH IS ALWAYS PROVIDED FOR EACH RECORD EXCEPT WHEN IT IS DAILY
        let year = singleRecord["year"];        //YEAR IS ALWAYS PROVIDED FOR EACH RECORD
        let date = singleRecord["date"];        //DATE IS ONLY PROVIDED IF PERIODIC TYPE IS DAILY
        let entityName = (singleRecord["entityName"]);
        //let totalRecords = singleRecord["totalRecords"];
        let value = singleRecord["value"];

        let periodName = "";
        switch (searchFilters["periodicType"]) {
            case "daily": periodName = date; break;
            case "weekly": periodName = `Week ${week} ${months[month]} ${year}`; break;
            default: periodName = months[month] + " " + year; break;
        }

        if (periodEntityArray[periodName] !== undefined && periodEntityArray[periodName][entityName] !== undefined) {
            periodEntityArray[periodName][entityName] = value;
        }
        else { }
    })

    return periodEntityArray;
}

//GET FORMATTED DATE FRO COMPARE PERIOD
export const getFormattedDataForComparePeriod = (entities, reportData, searchFilters) => {
    // console.log("data FOR COMPARE PERIOD LINE 338 ----------------------------   ", data);
    //STEP 1 - CREATE AN ARRAY OF PERIOD NAME
    let periodNameArray = getPeriodNameArray(searchFilters["comparePeriod"]["startDate"], searchFilters["comparePeriod"]["endDate"], searchFilters["periodicType"]);

    //STEP 2 - CREATE AN ARRAY OF ENTITIES NAME IN FORM OF OBJECTS
    let entitiesName = {};
    entities.map(obj => {
        let tradingName = (obj.tradingName);
        entitiesName[tradingName + " --"] = 0;
        return obj.tradingName
    });

    //STEP 3 - CREATE 2D ARRAY CONSISTS OF PERIOD NAME AND ENTITIES NAME
    let periodEntityArray = {};
    periodNameArray.map(singlePeriodName => {
        periodEntityArray[singlePeriodName] = JSON.parse(JSON.stringify(entitiesName));
    })

    //STEP 4 - UPDATE THE VALUES OF 2D ARRAY
    //let reportdata = data["report"] !== undefined && Array.isArray(data["report"]) ? data["report"] : [];

    reportData.map(singleRecord => {
        let week = singleRecord["week"];        //WEEK IS ONLY PROVIDED IF PERIODIC TYPE IS WEEKLY
        let month = singleRecord["month"];      //MONTH IS ALWAYS PROVIDED FOR EACH RECORD EXCEPT WHEN IT IS DAILY
        let year = singleRecord["year"];        //YEAR IS ALWAYS PROVIDED FOR EACH RECORD
        let date = singleRecord["date"];        //DATE IS ONLY PROVIDED IF PERIODIC TYPE IS DAILY
        let entityName = (singleRecord["entityName"]);
        //let totalRecords = singleRecord["totalRecords"];
        let value = singleRecord["value"];

        let periodName = "";
        switch (searchFilters["periodicType"]) {
            case "daily": periodName = date; break;
            case "weekly": periodName = `Week ${week} ${months[month]} ${year}`; break;
            default: periodName = months[month] + " " + year; break;
        }

        if (periodEntityArray[periodName] !== undefined && periodEntityArray[periodName][entityName + " --"] !== undefined) {
            periodEntityArray[periodName][entityName + " --"] = value;
        }
        else { }
    })
    // console.log("periodEntityArray for compare period data LINE 381:::::: ", periodEntityArray);

    return periodEntityArray;
}






//CREATE CUSTOM TOOLTIP FOT GRAPH 
function CustomTooltip({ payload, label, active }) {
    if (active) {
        let periodName = payload[0] !== undefined && payload[0].payload !== undefined && payload[0].payload["periodName"] !== undefined ? payload[0].payload["periodName"] : "Period";
        let comparePeriodName = payload[0] !== undefined && payload[0].payload !== undefined && payload[0].payload["periodName"] !== undefined ? payload[0].payload["comparePeriodName"] : "Compare Period";
        /*
        REAL ONE
        const customData = Object.keys(payload[0].payload).map(singleKey => {
            if (!["name", "periodName", "comparePeriodName"].includes(singleKey) && singleKey.indexOf(" --") === -1) {
                //console.log('payload[0]  -------------------------------------  ' , payload[0]);
                let color = payload[0].color;
                
                return (
                    <tr key={singleKey}>
                        <td style={{ color: color }}> {singleKey} </td>
                        <td> <div style={{ minWidth: "30px", paddingBottom: "4px", paddingLeft: "10px", color: color }}>{payload[0].payload[singleKey]}</div></td>
                        <td> <div style={{ paddingLeft: "10px", paddingBottom: "4px", color: color }}>{payload[0].payload[singleKey + " --"]} </div></td>
                    </tr>
                );
    
            }
            return null; // Return null for unsuccessful iterations (or handle differently if needed)
        });
        */
        const customData = payload.map(singlePaylaod => {

            //console.log('payload[0]  -------------------------------------  ' , payload[0]);
            let color = singlePaylaod.color;
            let dataKey = singlePaylaod.dataKey;
            if (dataKey.indexOf(" --") !== -1) { return null; }

            return (
                <tr key={dataKey}>
                    <td style={{ color: color }}> {dataKey} </td>
                    <td> <div style={{ minWidth: "30px", paddingBottom: "4px", paddingLeft: "10px", color: color }}>{payload[0].payload[dataKey]}</div></td>
                    <td> <div style={{ paddingLeft: "10px", paddingBottom: "4px", color: color }}>{payload[0].payload[dataKey + " --"]} </div></td>
                </tr>
            );
        });

        return (
            <div className="custom-tooltip" style={{ backgroundColor: "white", margin: "10px", padding: "10px", zIndex: "999" }}>
                <>
                    <table bord>
                        <tr><th colSpan={3}>{`${label}`}</th></tr>
                        <tr>
                            <th>Entity Name</th>
                            <th><div style={{ minWidth: "30px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "10px" }}>{periodName}</div></th>
                            <th><div>{comparePeriodName}</div></th>
                        </tr>
                        {customData}</table>
                </>
            </div>
        );
    }

    return null;
}

//GENERATE GRAPH IF COMPARE PERIOD IS ALSO ACTIVE  
export const DrawGraphForComparePeriod = ({ lineChartDataComparePeriod, additionalInfoForYAxis }) => {
    var lineCount = 0;
    //CHECK IF ADDITIONAL INFO IS PROVIDED FOR y AXIS (E.G ADD % | $ SIGN AND POSITIONS)
    var signPosition = additionalInfoForYAxis !== undefined && additionalInfoForYAxis["signPosition"] !== undefined ? additionalInfoForYAxis["signPosition"] : "left";
    var sign = additionalInfoForYAxis !== undefined && additionalInfoForYAxis["sign"] !== undefined ? additionalInfoForYAxis["sign"] : "";


    if (Array.isArray(lineChartDataComparePeriod) && lineChartDataComparePeriod.length > 0) {


        return (
            <>

                <LineChart
                    width={1000}
                    height={350}
                    data={lineChartDataComparePeriod}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(value) => sign.length > 0 ? (signPosition === "left" ? `${sign} ${value}` : `${value} ${sign}`) : `${value}`} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {
                        Object.keys(lineChartDataComparePeriod[0]).map(index => {
                            //lineCount++;
                            if (!["name", "periodName", "comparePeriodName"].includes(index)) {
                                lineCount++;
                                return <>
                                    {
                                        index.indexOf(" --") > 0 ? "" :
                                            <>
                                                <Line type="monotone" dataKey={index} stroke={colors[lineCount + ""]} />
                                                <Line type="monotone" dataKey={index + " --"} stroke={colors[lineCount + ""]} strokeDasharray="3 4 5 2" />

                                            </>
                                    }
                                </>
                            }
                        })
                    }
                </LineChart>
            </>
        )
    }
    else {
        return ""
    }
}



//RETURN GRAPH HTML - ORIGNAL FUNCTION FOR SINGLE PERIOD - NO COMAPRISION
export const DrawGraph = ({ lineChartData, lineChartDataComparePeriod, additionalInfoForYAxis }) => {
    var lineCount = 0;

    //CHECK IF ADDITIONAL INFO IS PROVIDED FOR y AXIS (E.G ADD % | $ SIGN AND POSITIONS)
    var signPosition = additionalInfoForYAxis !== undefined && additionalInfoForYAxis["signPosition"] !== undefined ? additionalInfoForYAxis["signPosition"] : "left";
    var sign = additionalInfoForYAxis !== undefined && additionalInfoForYAxis["sign"] !== undefined ? additionalInfoForYAxis["sign"] : "";

    if (Array.isArray(lineChartData) && lineChartData.length > 0) {

        return (
            <>
                <LineChart width={1000} height={350} data={lineChartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(value) => sign.length > 0 ? (signPosition === "left" ? `${sign} ${value}` : `${value} ${sign}`) : `${value}`} />
                    <Tooltip />
                    <Legend />
                    {
                        Object.keys(lineChartData[0]).map(index => {
                            lineCount++;
                            if (index !== "name") {
                                return <>
                                    <Line type="monotone" dataKey={index} stroke={colors[lineCount + ""]} />
                                    {/*lineChartDataComparePeriod[index] !== undefined ? <Line type="monotone" dataKey={index} stroke="#82ca9d" strokeDasharray="3 4 5 2" /> : ""*/}
                                </>
                            } else {
                                return null;
                            }
                        })
                    }
                </LineChart>
            </>
        )
    }
    else {
        return ""
    }
}



const getCustomizedYAxis = (props) => {

}