import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getAnalystDetailedCommissionReport } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";



const AnalystDetailedCommissionReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [detailedCommissionReport, setDetailedCommissionReport] = useState([]);
    const [totalOriginationFee, setTotalOriginationFee] = useState(0);
    const [totalFinanceCommission, setTotalFinanceCommission] = useState(0);
    const [totalBrokerageFee, setTotalBrokerageFee] = useState(0);
    const [totalInsuranceIncome, setTotalInsuranceIncome] = useState(0);
    const [totalGrossIncome, setTotalGrossIncome] = useState(0);

    const token = useToken();

    const calculateSum = (records) => {
        var tmpTotalOriginationFee = 0;
        var tmpTotalFinanceCommission = 0;
        var tmpTotalBrokerageFee = 0;
        var tmpTotalInsuranceIncome = 0;
        var tmpTotalGrossIncome = 0;

        if (Array.isArray(records)) {
            records.map(singleRecord => {
                tmpTotalOriginationFee += singleRecord["originationFee"] !== 0 ? parseFloat(singleRecord["originationFee"]) : 0;
                tmpTotalFinanceCommission += singleRecord["financeCommission"] !== 0 ? parseFloat(singleRecord["financeCommission"]) : 0;
                tmpTotalBrokerageFee += singleRecord["brokerageFee"] !== 0 ? parseFloat(singleRecord["brokerageFee"]) : 0;
                tmpTotalInsuranceIncome += singleRecord["insuranceCommission"] !== 0 ? parseFloat(singleRecord["insuranceCommission"]) : 0;
                tmpTotalGrossIncome += singleRecord["grossIncome"] !== 0 ? parseFloat(singleRecord["grossIncome"]) : 0;
            })
        }
        setTotalOriginationFee(tmpTotalOriginationFee.toFixed(2));
        setTotalFinanceCommission(tmpTotalFinanceCommission.toFixed(2));
        setTotalBrokerageFee(tmpTotalBrokerageFee.toFixed(2));
        setTotalInsuranceIncome(tmpTotalInsuranceIncome.toFixed(2));
        setTotalGrossIncome(tmpTotalGrossIncome.toFixed(2));
    }


    //FETCH ANALYST DETAILED COMMISSION REPORT
    const fetchAnalystDetailedCommissionReport = async () => {
        setProcessing(true);
        var response = await getAnalystDetailedCommissionReport(token, startDate, endDate);
        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])) {
            setDetailedCommissionReport(response["data"]);
            //CALCULATE SUM OF EACH RECORD
            calculateSum(response["data"]);
        }
        setProcessing(false);
    }


    useEffect(() => {
        fetchAnalystDetailedCommissionReport();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    {processing ? <LoadingBlock /> :
                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-175px text-start">Referral # </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Referral Name </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Origination Fee	 </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Finance Commission	 </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Brokerage Fee	 </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Insurance Income	 </th>
                                        <th className="p-0 pb-3 min-w-175px text-start">Gross Income </th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                    {
                                        Array.isArray(detailedCommissionReport) ?
                                            detailedCommissionReport.map(singleRecord => {
                                                return <>
                                                    <tr >
                                                        <td>{singleRecord["applicationId"]}</td>
                                                        <td>{singleRecord["applicantName"]}</td>
                                                        <td>$ {singleRecord["originationFee"]}</td>
                                                        <td>$ {singleRecord["financeCommission"]}</td>
                                                        <td>$ {singleRecord["brokerageFee"]}</td>
                                                        <td>$ {singleRecord["insuranceCommission"]} {singleRecord["groupInsuranceCommission"] !== null && singleRecord["groupInsuranceCommission"].length > 0 ? "[" + singleRecord["groupInsuranceCommission"] + "]" : "" }</td>
                                                        <td>$ {singleRecord["grossIncome"]}</td>
                                                    </tr>
                                                </>
                                            })
                                            : ""
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><span className="bold">$ {totalOriginationFee}</span></td>
                                        <td><span className="bold">$ {totalFinanceCommission}</span></td>
                                        <td><span className="bold">$ {totalBrokerageFee}</span></td>
                                        <td><span className="bold">$ {totalInsuranceIncome}</span></td>
                                        <td><span className="bold">$ {totalGrossIncome}</span></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AnalystDetailedCommissionReport;