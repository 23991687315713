import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import CreditProposalValidationSchema from '../schema/CreditProposalValidationSchema';
import Notifications from "../../global/Notifications";

import { performApplicationAdditionalActions, getReferrer, getComments } from "../../../api";
import { useToken } from '../../../hooks/useAuth';

import LoadingScreen from '../../global/LoadingScreen';
import TaxInvoiceSchema from '../schema/TaxInvoiceSchema.json';



const TaxInvoice = ({ application, applicant, applicationId, callback }) => {

    const [taxInvoiceDetails, setTaxInvoiceDetails] = useState(TaxInvoiceSchema);
    const [comments, setComments] = useState([]);
    const [total, setTotal] = useState(0);
    const [processing, setProcessing] = useState(false);

    const formRef = useRef();
    const token = useToken();

    const referrerId = application.referrerId !== undefined ? application.referrerId : 0;
    const referrerStaffId =  application.referrerStaffId !== undefined ? application.referrerStaffId : 0;


    const fetchReferrer = async () => {
        var referrerName = '';
        var referrerEmail = '';

        if(referrerId == 0){ Notifications("error", "Referrer Details not found"); }
        else{            
            var response = await getReferrer(referrerId, token);
            if (response["error"] == 0) {
                var referrer = response["referrer"] !== undefined ? response["referrer"] : {}
                if (referrer["users"] !== undefined && Array.isArray(referrer["users"])) {
                    var referrerStaffUser = referrer["users"].find(singleUser => singleUser.id == referrerStaffId);
                    referrerEmail = referrerStaffUser["email"] !== undefined ? referrerStaffUser["email"] : "";
                    referrerName = referrerStaffUser["firstName"] !== undefined ? referrerStaffUser["firstName"] : "";
                }
            }
            else{  Notifications("error", response["message"] !== undefined ? response["message"] : "Referrer Details not found"); }
        }




        //FILL INDIVIDUALDETAILS 
        var personalDetail = applicant.personalDetail !== undefined ? applicant.personalDetail : {};

        var firstName = personalDetail.firstName !== undefined ? personalDetail.firstName : "";
        var surname = personalDetail.surname !== undefined ? personalDetail.surname : "";
        var middleName = personalDetail.middleName !== undefined ? personalDetail.middleName : "";

        var fullName = firstName + " " + middleName + " " + surname;

        //ADDRESS
        var applicantCurrentAddress = personalDetail.residentialHistory !== undefined && personalDetail.residentialHistory.current !== undefined &&
            personalDetail.residentialHistory.current.address !== undefined ? personalDetail.residentialHistory.current.address : {};

        var streetName = applicantCurrentAddress["streetName"] !== undefined ? applicantCurrentAddress["streetName"] : "";
        var streetNumber = applicantCurrentAddress["streetNumber"] !== undefined ? applicantCurrentAddress["streetNumber"] : "";
        var streetTypeLongName = applicantCurrentAddress["streetTypeLongName"] !== undefined ? applicantCurrentAddress["streetTypeLongName"] : "";

        var suburb = applicantCurrentAddress["suburb"] !== undefined ? applicantCurrentAddress["suburb"] : "";
        var state = applicantCurrentAddress["state"] !== undefined ? applicantCurrentAddress["state"] : "";
        var postcode = applicantCurrentAddress["postCode"] !== undefined ? applicantCurrentAddress["postCode"] : "";

        var addressLine1 = streetNumber + " " + streetName + " " + streetTypeLongName;
        var addressLine2 = suburb + " " + state + " " + postcode;

        console.log("applicantCurrentAddress :::  ", applicantCurrentAddress);

        var tmpTaxInvoiceDetails = {
            referrerName: referrerName,
            referrerEmail: referrerEmail,
            recipientTitle: "",
            referralName: `Hi ${referrerName}`,
            textLine1: `Great News, The finance for ${fullName} is now conditionally approved.`,
            textLine2: "To enable the preparation of loan documents, can you please provide an invoice as follows via email details below:",
            invoiceTo: {
                name: fullName,
                addressLine1: addressLine1,
                addressLine2: addressLine2
            },
            deliveryTo: {
                name: fullName,
                addressLine1: addressLine1,
                addressLine2: addressLine2
            },
            customDescription: "",
            financials: {
                assetPrice: application.financial !== undefined && application.financial.assetPrice !== undefined ? application.financial.assetPrice : 0,
                cashDeposit: application.financial !== undefined && application.financial.cashDeposit !== undefined ? application.financial.cashDeposit : 0,
                tradeInAmount: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.amount ? application.financial.tradeIn.amount : 0,
                amountOutstanding: application.financial !== undefined && application.financial.tradeIn !== undefined && application.financial.tradeIn.isTradingIn === true &&
                    application.financial.tradeIn.finance !== undefined && application.financial.tradeIn.finance.financed === true && application.financial.tradeIn.finance.financier !== undefined && application.financial.tradeIn.finance.financier.amountOutstanding !== undefined ? application.financial.tradeIn.finance.financier.amountOutstanding : 0,
                other: {
                    description: "",
                    amount: 0,
                },
                total: 0
            },
        }

        setTaxInvoiceDetails(tmpTaxInvoiceDetails);
    }

    //FETCH NOTES /cCOMMENTS
    const fetchComments = async () => {
        var response = await getComments(token, "taxInvoice", applicationId);
        if (response["error"] == 0) {
            setComments(response["data"]);
        }
    }
    useEffect(() => {
        console.log("taxInvoiceDetails HAS BEEN CHANGED -----------------------------", taxInvoiceDetails)
    }, [taxInvoiceDetails])

    useEffect(() => {
        fetchReferrer();
    }, [application])

    useEffect(() => {
        fetchComments();
    }, [])


    const TotalAmount = (props) => {

        const {
            values: { financials: { assetPrice, cashDeposit, tradeInAmount, amountOutstanding, other: { amount } } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = assetPrice !== undefined && (!isNaN(parseFloat(assetPrice))) ? parseFloat(assetPrice) : 0;
            totalAmount += amountOutstanding !== undefined && (!isNaN(parseFloat(amountOutstanding))) ? parseFloat(amountOutstanding) : 0;
            totalAmount += amount !== undefined && (!isNaN(parseFloat(amount))) ? parseFloat(amount) : 0;
            totalAmount -= cashDeposit !== undefined && (!isNaN(parseFloat(cashDeposit))) ? parseFloat(cashDeposit) : 0;
            totalAmount -= tradeInAmount !== undefined && (!isNaN(parseFloat(tradeInAmount))) ? parseFloat(tradeInAmount) : 0;

            setFieldValue(props.name, totalAmount);
        }, [assetPrice, cashDeposit, tradeInAmount, amountOutstanding, amount,
            touched.assetPrice, touched.cashDeposit, touched.tradeInAmount, touched.amountOutstanding, touched.amount,
            setFieldValue, props.name]);
        return (
            <>
                <input
                    type="text"
                    name="total"
                    className="form-control formField2"
                    value={field.value !== undefined ? field.value.toFixed(2) : 0}
                    readOnly={true}
                />

                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={taxInvoiceDetails}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validateOnBlur={false}
                        //validationSchema={}

                        onSubmit={async (values) => {

                            console.log("VALUES READY FOR SUBMISSION S:::::", values);
                            //MAKE AN API CALL 

                            setProcessing(true);
                            var response = await performApplicationAdditionalActions(token, "taxInvoice", applicationId, values);

                            var responseResult = response.data !== undefined ? response.data : {};
                            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                                Notifications("success", responseResult["message"] !== undefined ? responseResult["message"] : "Sent");
                                callback("closeDrawer");
                            }
                            else {
                                Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error");
                            }
                            fetchComments();
                            setProcessing(false);

                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referrer Name</label>
                                        <Field name="referrerName" className="form-control formField2" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referrer Email</label>
                                        <Field name="referrerEmail" className="form-control formField2" />
                                    </div>
                                    <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                                        <Field name="referralName" placehodler="Referral Name" className="form-control formField2" />
                                    </div>
                                    <div className="col-sm-12"></div>

                                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                        <Field name="textLine1" placehodler="Text Line 1" className="form-control formField2" />
                                    </div>
                                    <div className="col-sm-12"></div>
                                    <div className="col-sm-12 col-md-12 mb-5 fv-row fv-plugins-icon-container">
                                        <Field name="textLine2" placehodler="Text Line 2" className="form-control formField2" />
                                    </div>

                                    <div className="col-sm-12" style={{ border: "1px solid", maxWidth: "800px", paddingBottom: "20px" }}>

                                        <div className="row">
                                            <div className="col-sm-12" style={{ backgroundColor: "orange", color: "black", paddingTop: "8px", paddingBottom: "8px" }}>
                                                <p style={{ textAlign: "center", fontSize: "19px", fontWeight: "bold" }}>Tax Invoice</p>
                                            </div>
                                            {/****************************INVOICE TO ******************** */}
                                            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "30%" }}>Invoice To:</div>
                                                    <div style={{ width: "60%" }}>
                                                        <Field name="invoiceTo.name" placehodler="Text Line 2" className="form-control formField2" />
                                                        <Field name="invoiceTo.addressLine1" placehodler="Text Line 2" className="form-control formField2" />
                                                        <Field name="invoiceTo.addressLine2" placehodler="Text Line 2" className="form-control formField2" />
                                                    </div>
                                                </div>

                                            </div>

                                            { /******************************INVOICE FROM ******************************* */}
                                            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "30%" }}>For Delivery To::</div>
                                                    <div style={{ width: "60%" }}>
                                                        <Field name="deliveryTo.name" placehodler="Text Line 2" className="form-control formField2" />
                                                        <Field name="deliveryTo.addressLine1" placehodler="Text Line 2" className="form-control formField2" />
                                                        <Field name="deliveryTo.addressLine2" placehodler="Text Line 2" className="form-control formField2" />
                                                    </div>
                                                </div>
                                            </div>

                                            {/******************************DESCRIPTION*************************************** */}
                                            <div className="col-sm-12">
                                                <div className="taxInvoiceDescription" style={{ marginLeft: "20px", marginTop: "40px" }}>
                                                    <p className="bold">  -	Full vehicle description including </p>
                                                    <p className="bold">  -	VIN</p>
                                                    <p className="bold">  -	Engine</p>
                                                    <p className="bold">  -	Rego Number</p>
                                                    <p className="bold">  -	Compliance and Build Date</p>
                                                    <p className="bold">  -	Colour</p>
                                                    <p className="bold">  -	Break up of Vehicle Price (GST, stamp duty etc.)</p>
                                                    <Field name="customDescription" placehodler="Custom Description" className="form-control formField2" />
                                                </div>
                                            </div>

                                            { /**********************************FINANCIALS ***************************************** */}

                                            <div className="col-sm-12" style={{ marginTop: "20px" }}>
                                                {/***************Vehicle Price (Including Break Up):************ */}
                                                <div style={{ width: "70%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}> Vehicle Price (Including Break Up):  </p>
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <Field name="financials.assetPrice" placehodler="" className="form-control formField2" />
                                                </div>
                                                {/***************Less Cash Deposit::************ */}
                                                <div style={{ width: "70%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}> Less Cash Deposit:  </p>
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <Field name="financials.cashDeposit" placehodler="" className="form-control formField2" />
                                                </div>
                                                {/***************Less Trade Allowance::************ */}
                                                <div style={{ width: "70%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}> Less Trade Allowance:  </p>
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <Field name="financials.tradeInAmount" placehodler="" className="form-control formField2" />
                                                </div>
                                                {/***************Plus Payouts to other financiers / banks:************ */}
                                                <div style={{ width: "70%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}> Plus Payouts to other financiers / banks:  </p>
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <Field name="financials.amountOutstanding" placehodler="" className="form-control formField2" />
                                                </div>

                                                {/***************OTHER - WITH CUSTOM DESCRIPTION :************ */}
                                                <div style={{ width: "70%", display: "inline", float: "left" }}>
                                                    <Field name="financials.other.description" placehodler="" className="form-control formField2" />
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <Field name="financials.other.amount" placehodler="" className="form-control formField2" />
                                                </div>

                                                {/*********************************TOTAL ********************** */}
                                                <div style={{ width: "70%", display: "inline", float: "left", fontWeight: "bold" }}>
                                                    <p style={{ float: "right" }}> Total</p>
                                                </div>
                                                <div style={{ width: "8%", display: "inline", float: "left" }}>
                                                    <p style={{ float: "right" }}>$</p>
                                                </div>
                                                <div style={{ width: "20%", display: "inline", float: "left" }}>
                                                    <TotalAmount name="total" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-5"></div>

                                <div className="col-xl-12">
                                    {<FormSubmissionButton isActive={true} />}
                                </div>
                            </Form>

                        )}

                    </Formik>

                </div>
            </div>
            <div className='d-flex row'>
                <h3>Applicant Tax Invoice Notification Log</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                </div>
            </div>

            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default TaxInvoice;