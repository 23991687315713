import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getSettlementsList, performApplicationAdditionalActions } from '../../api';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom'
import Notifications from '../global/Notifications';
import SettlementListItem from './SettlementListItem';
import Pagination from '../HtmlComponents/Form/Pagination';
import UpdateProgressStatus from '../applications/appComponents/UpdateProgressStatus';

import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import Files from '../files/Files';
import ViewSettlement from './ViewSettlement';
import PrintApplication from '../applications/appComponents/PrintApplication';

import Lookup from '../global/SearchForms/Lookup';
import SearchByDate from '../global/SearchForms/SearchByDate';

const ListReinstatedSettlements = () => {
    const [settlementsList, setSettlementsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    //const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [filter, setFilter] = useState({ currentPageNumber: 1, startDate: 0, endDate: 0, q: "", referrerGroupId: "", referrerId: "", reinstated: true });
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    const [loading, setLoading] = useState(true);

    const token = useToken();
    const role = useRole();
    const entityType = useEntityType();
    const navigate = useNavigate();

    const fetchSettlements = async () => {
        setLoading(true);

        var query = "";
        if (filter["currentPageNumber"]) { query = `pageNumber=${filter["currentPageNumber"]}`; }
        if (filter["startDate"] > 0) { query += `&&startDate=${filter["startDate"]}`; }
        if (filter["endDate"] > 0) { query += `&&endDate=${filter["endDate"]}`; }
        if (filter["q"].length > 2) { query += `&&q=${filter["q"]}`; }

        query += "&&reinstated=true";

        if(entityType == "referrer"){
            var selectedReferrerId = localStorage.getItem("selectedReferrer");
            query = query+`&referrerId=${selectedReferrerId}`;
        }

        const response = await getSettlementsList(token, query);

        if (response["error"] !== undefined && response["error"] == 1) {
            Notifications("error", response["message"] || "Unable to fetch the settlements. Please contact IT")
        }
        else {
            var data = response["data"];
            if (data["totalRecords"] !== undefined) { setTotalRecords(data["totalRecords"]); }
            if (data["records"] !== undefined) { setSettlementsList(data["records"]); }
        }
        setLoading(false);
    }

    const updateHandler = async (name, value) => {
        switch (name) {
            case "currentPageNumber": setFilter({ ...filter, "currentPageNumber": value }); break;
            case "lookup":
                if (value.length > 2) { setFilter({ ...filter, "q": value, currentPageNumber: 1 }) }
                else if (value.length == 0) { setFilter({ ...filter, "q": "", currentPageNumber: 1 }) }
                break;
            case "dateFilter":
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"], q: "", currentPageNumber: 1 });
                break;
            case "files":
                setDrawerSettings({ visible: true, heading: `Files (Referral # ${value} )`, childName: "files", meta: { width: "800", applicationId: value } })
                break;    
            case "viewSettlement":
                setDrawerSettings({ visible: true, heading: `Settlement`, childName: "viewSettlement", meta: { width: "800", settlementId: value } })
                break;    
            case "printApplication":
                setDrawerSettings({ visible: true, heading: "Application", childName: "printApplication", meta: { applicationId: value['applicationId'] } })
                break;    
            case "copyApplication":
                ConfirmDialog({ callback: () => { updateHandler("executeCopyApplicationOperation", value) }, "purpose": "general", "heading": `Are you sure you want to copy Ref # ${value}`, "description": "Documents will not be carried over." })
                break;
            case "updateProgressStatus": setDrawerSettings({ visible: true, heading: "Status", childName: "updateProgressStatus", meta: { applicationId: value['applicationId'], progressStatus: value["progressStatus"] , applicationType: value["applicationType"] , loanPurpose: value["loanPurpose"]} }); break;
            
            case "executeCopyApplicationOperation":
                var response = await performApplicationAdditionalActions(token, "copyApplication", value, {reinstated: true});
                console.log(response);
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Created Successfully.");
                    var applicationId = response["application"]["id"];
                    navigate(`/user/editConsumerApplication/${applicationId}`);
                }
                break;
            case "closeDrawer":
                setDrawerSettings({ visible: false, heading: "Files", childName: "files", meta: { width: "800", applicationId: 0 } })
                break;    
        }
    }

    useEffect(() => {
        fetchSettlements();
    }, []);

    useEffect(() => {
        fetchSettlements();

        console.log("filter", filter);
    }, [filter]);


    const CreateSettlementButton = () => {
        if (["finance", "superAdmin"].includes(role)) {
            return (
                <>
                    <div className='row'>
                        <div className='cols-m-12'>
                            <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { navigate("/user/createSettlement") }} buttonText="Create" />
                        </div>
                    </div>

                </>
            );
        }
        else {
            return (<></>)
        }
    }

    const TableHeader = () => {
        if (["finance", "superAdmin", "auditor"].includes(role)) {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Name</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Settlement Date</th>                            
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Reinstating Date</th>                            
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>                            
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Rate %</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Term</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Financier</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer Group</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Individual Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{width: "125px"}}  >Actions</th>
                        </tr>
                    </thead>
                </>
            )
        }
        if (["admin",  "analyst"].includes(role)) {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Name</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Settlement Date</th>  
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Reinstating Date</th>                           
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>                            
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Rate %</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Term</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Financier</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer Group</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Individual Referrer</th>                            
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  style={{width: "125px"}} >Actions</th>                            
                        </tr>
                    </thead>
                </>
            )
        }
        else if(entityType == "referrerGroup"){
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Name</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Settlement Date</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer Commission (Excl GST)</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Commission (Excl GST)</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else if(entityType == "referrer"){
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Name</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Settlement</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Assigned</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Commission (Excl GST)</th>
                        </tr>
                    </thead>
                </>
            )
        }
        else {
            return (
                <>
                    <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Ref #</th>                             
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Name</th> 
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Amount</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Settlement Date</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Analyst</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Lender</th>
                            <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1"  >Referrer</th>
                        </tr>
                    </thead>
                </>
            )
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">

                <SearchByDate callback={updateHandler} />

                <div className='row'>
                    <div className='col-sm-9'>
                        {/**label (Ref #, First Name, Surname, Amount, Purpose, Analyst ) */}
                        <Lookup label="" callback={updateHandler} />
                    </div> 
                </div>



                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <TableHeader />
                                    <tbody className="fw-bold">
                                        {
                                            settlementsList && Array.isArray(settlementsList) ?
                                                settlementsList.map(singleSettlement => (
                                                    <SettlementListItem key={singleSettlement.id} singleSettlement={singleSettlement} callback={updateHandler} reinstateDate={true} />
                                                )) : <tr>
                                                    <td colSpan={13}> <p> No Settlement found</p> </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination totalRecords={totalRecords} currentPageNumber={filter["currentPageNumber"]} totalRecordsOnpage={10} updateCurrentPageNumber={updateHandler} />
            </div>

            {drawerSettings["visible"] ?
                    <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={updateHandler}>
                        {drawerSettings["childName"] === "files" ? <Files entityType="application" entityId={drawerSettings["meta"]["applicationId"]} enableUpload={true} /> : ""}
                        {drawerSettings["childName"] === "viewSettlement" ? <ViewSettlement settlementId={drawerSettings["meta"]["settlementId"]}   /> : ""}
                        {drawerSettings["childName"] === "printApplication" ? <PrintApplication applicationId={drawerSettings["meta"]["applicationId"]} /> : ""}                    
                        {/**THIS WILL NOT REALLU UPDATE THE STATUS AS NSATUS OR NEW COMMENTRS CAN NOT BE MADE TO SETTLED APPLICATION - WE ARE USING THIS COMPONENT HERE JUST TO DISPLAY THE LOGS */}
                        {drawerSettings["childName"] === "updateProgressStatus" ? <UpdateProgressStatus applicationId={drawerSettings["meta"]["applicationId"]} currentProgressStatus={drawerSettings["meta"]["progressStatus"]}  applicationType = {drawerSettings["meta"]["applicationType"]} loanPurpose= {drawerSettings["meta"]["loanPurpose"]} callback={updateHandler} /> : ""}
                    
                    </RightDrawer> : ""
                }
        </>
    )
}

export default ListReinstatedSettlements;