
import { react, useState } from 'react';
import { Field } from 'formik';
import { CheckBox, InputField, Modal } from '../../../../../components/HtmlComponents';
import Constants from "../../../../../components/global/Constants";
import AddressForm from '../../../../../components/global/AddressForm';

const LandLordDetail = ({ prefix, setFieldValue }) => {
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });

    return (
        <>
            <div className="row" >
                <div className="col-sm-4 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Landlord Name</label>
                    <Field name={prefix + "name"} >
                        {({ field }) => (
                            <InputField
                                fieldName={prefix + "name"}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-4 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Landlord Contact #</label>
                    <Field name={prefix + "contactNumber"} >
                        {({ field }) => (
                            <InputField
                                fieldName={prefix + "contactNumber"}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-4 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Landlord Address</label>
                    <Field name={ prefix + "address" }>
                        {({ field }) => (
                            <input
                                className="form-control form-control-solid"
                                type="text"
                                value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                onClick={() => setAddressModal({ visible: true, fieldName: `${prefix}address` , callback: setFieldValue })}
                                onChange={(e) => { }}
                                style={{ height: Constants.TEXTFIELD_HEIGHT }}
                            />
                        )}
                    </Field>
                </div>
            </div>

            {addressModal.visible === true ?
                        <Modal modalVisibility={setAddressModal} heading="Residential Address">
                            <AddressForm
                                fieldName={addressModal.fieldName}
                                closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                                addressHandler={addressModal.callback}
                            />
                        </Modal>
                        : ""}
        </>
    )

}

export default LandLordDetail;