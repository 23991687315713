import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UpdatePasswordValidationSchema from './schema/UpdatePasswordValidationSchema';
import { Formik, Form, Field } from 'formik';
import { forgotPassword } from '../../api';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';
import { FormSubmissionButton, CustomButton  } from '../HtmlComponents';

const ForgotPassword = () => {
    const userRef = useRef();
    const [user, setUser] = useState({ "userName": "" });
    const [formErrors, setFormErrors] = useState({});
    let navigate = useNavigate();

    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-lg-row-fluid">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                            <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                <div className="d-flex flex-row-fluid flex-column text-center">
                                    <a href="#" className="py-9 mb-5">
                                        <img alt="Logo" src="/media/logos/brokahub-logo-horizontal-black.svg" className="h-75px" />
                                    </a>
                                </div>

                                <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <Formik
                                            initialValues={user}
                                            validateOnBlur={false}

                                            onSubmit={async (values) => {
                                                //console.log("VALUES", values);
                                                //CHECK IF USER NAME IS PROVIDED                                                
                                                if (values["userName"] !== undefined && values["userName"].length > 3) {
                                                    const response = await forgotPassword(values);
                                                    if (response["error"]) {
                                                        Notifications("error", response["message"] || "Error")
                                                    } else {
                                                        console.log("response.id : " + response.id);
                                                        Notifications("success", response["message"] || "If your email is registered, a message containing a reset link has been sent to your email address.")
                                                    }
                                                }
                                                else {
                                                    Notifications("error", "Please provide a valid username")
                                                }
                                            }}
                                        >
                                            {({ errors, touched, handleChange, setFieldValue, values }) => (
                                                <Form>
                                                    <div>
                                                        <div className="  mb-10"> <h3 className="text-dark mb-3">Forgot Your Password</h3> </div>
                                                        <p className='mb-10'>Please provide your username, and we will send you an email containing a reset link or please call 1300 844 970 for assistance.</p>
                                                    </div>

                                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                                        <div>
                                                            <label>Please enter your username</label>
                                                        </div>
                                                        <div style={{ marginBottom: "12px" }}>
                                                            <Field name="userName" type="text" placeholder="User Name" className="form-control form-control-solid" style={{ borderColor: "#ccccd1" }} />
                                                        </div>

                                                    </div>

                                                    <div className="text-center">

                                                        {JSON.stringify(errors).length > 3 ?
                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5" disabled >
                                                                <span className="indicator-label">Send</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button> :



                                                            <button type="submit" className="btn btn-lg custom-btn w-100 mb-5"  >
                                                                <span className="indicator-label">Forgot Password</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button>

                                                        }

                                                        <Link to="/login" className="text-muted text-hover-primary fs-6">Back to login</Link>

                                                    </div>

                                                </Form>


                                            )}
                                        </Formik>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )


}
export default ForgotPassword;
