import { useEffect, useState } from "react"
import ResidentialAddressDetail from "./ResidentialAddressDetail"
const ResidentialHistory = ({ prefix, setFieldValue, residentialStatusList, residentialHistory }) => {

    const [totalLivingDuration, setTotalLivingDuration] = useState(0);
    const [residentialAddressList, setResidentialAddressList] = useState([
        { prefix: "residentialHistory.current.", name: "residentialHistory.current.duration", active: true, "duration": 0 },
        { prefix: "residentialHistory.previous[0].", name: "residentialHistory.previous[0].duration", active: false, "duration": 0 },
        { prefix: "residentialHistory.previous[1].", name: "residentialHistory.previous[1].duration", active: false, "duration": 0 },

    ]);
    const [currentPreResidentialStatus, setCurrentPreResidentialStatus] = useState(null);


    useEffect(() => {
        var preResidentialStatus = residentialHistory["current"] !== undefined && residentialHistory["current"]["status"] !== undefined ? residentialHistory["current"]["status"] : "";
        setCurrentPreResidentialStatus(preResidentialStatus);
        activatePreviousResidentialAddressBlocks();
    }, [residentialHistory])

    //DECIDES IF NEED TO ADD ANOTHER RESIDENTIAL BLOCK - DECISION BASED ON THE NUMBER OF MONTHS
    const totalLivingDurationCallback = (name, value) => {
        switch (name) {
            case "residentialHistory.current.duration":
                var tempList = [...residentialAddressList];
                tempList[0].duration = value;
                if (value < 36 && value > 0) {
                    //CALCULATE DURATIN OF CURRENT AND PREVIOUS[0] AND DECIDE THE VISIBILITY OF PREVIOUS[1]- TEST CASE (USER UPDATE THE CURRENT AND THEN PREVIOUS BUT THEN CHNAGE THE CURRENT AGAIN )
                    tempList[2].active = parseInt(value) + parseInt(tempList[1].duration) < 36 && tempList[1].active ? true : false;

                    tempList[1].active = true;
                }
                else {
                    tempList[1].active = false;
                    tempList[2].active = false;
                }
                setResidentialAddressList(tempList)
                break;
            case "residentialHistory.previous[0].duration":
                var tempList = [...residentialAddressList];
                tempList[1].duration = value;
                var totalMonths = parseInt(value) + parseInt(tempList[0].duration);
                if (totalMonths < 36 && value > 0) { tempList[2].active = true; } else { tempList[2].active = false; }
                setResidentialAddressList(tempList)
                break;
            case "residentialHistory.previous[1].duration":
                var tempList = [...residentialAddressList];
                tempList[2].duration = value;
                setResidentialAddressList(tempList)
                break;

        }
    }

    //ACTIVATE PREVIOUS ADDRESS BLOCK IF PROVIDED IN HISTORY PARAM
    const activatePreviousResidentialAddressBlocks = () => {
        //IF PREVIOUS BLOCK EXISTS IN RESIDENTIAL ADDRESS PARAM AND 0 INDEX EXISTS THEN ACTIVATE FIRST PREVIOUS ADDRESS
        if (residentialHistory["previous"] != undefined &&
            Array.isArray(residentialHistory["previous"]) && residentialHistory["previous"][0]) {
            var tempList = [...residentialAddressList];
            tempList[1].duration = 10;
            tempList[1].active = true;
            setResidentialAddressList(tempList)
        }
    }

    useEffect(() => {

    }, [residentialAddressList])


    //EXECUTE ON FIRST RENDER
    useEffect(() => {
        activatePreviousResidentialAddressBlocks();
    }, [])



    return (
        <>
        
            <ResidentialAddressDetail
                prefix="residentialHistory.current."
                setFieldValue={setFieldValue}
                residentialStatusList={residentialStatusList}
                totalLivingDurationCallback={totalLivingDurationCallback}
                preResidentialStatus={currentPreResidentialStatus}
            />
                         
            {
                residentialAddressList[1].active ?
                    <ResidentialAddressDetail
                        prefix="residentialHistory.previous[0]."
                        setFieldValue={setFieldValue}
                        residentialStatusList={residentialStatusList}
                        totalLivingDurationCallback={totalLivingDurationCallback}
                        preResidentialStatus=""
                    />
                    : ""
            }
            {
                residentialAddressList[2].active ?
                    <ResidentialAddressDetail
                        prefix="residentialHistory.previous[1]."
                        setFieldValue={setFieldValue}
                        residentialStatusList={residentialStatusList}
                        totalLivingDurationCallback={totalLivingDurationCallback}
                        preResidentialStatus=""
                    />
                    : ""
            }
        </>
    )

}

export default ResidentialHistory;