import { useEffect, useRef } from "react";
import { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton, RightDrawer, TextArea } from '../../../HtmlComponents';
import { formatDate, getCurrentDate } from '../../../../helpers';
import Notifications from "../../../global/Notifications";
import SelectFieldGroup from '../../../HtmlComponents/Fields/SelectFieldGroup';

import { performApplicationAdditionalActions, getLendersList, getLender, getComments, getFolders, getFiles, } from "../../../../api";
import { useToken } from '../../../../hooks/useAuth';
import LoadingScreen from '../../../global/LoadingScreen';
import SubmitToLenderValidationSchema from '../../schema/SubmitToLenderValidationSchema';


const PepperMoney = ({ applicationId }) => {

    const [initialValues, setInitialValues] = useState({ lender: "pepperMoney", service: "submitApplication", productType: "", frequency: "", repaymentAmount: "", paymentMethod: "" });
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");

    const token = useToken();
    const formRef = useRef();
 
  
    return (
        <>
            {<Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={SubmitToLenderValidationSchema}
                innerRef={formRef}
                validateOnBlur={false}

                onSubmit={async (values) => {
                    //MAKE AN API CALL 
                    setProcessing(true);
                    let valuesColne = JSON.parse(JSON.stringify(values));
                    console.log("valuesColne", valuesColne)

                    var response = await performApplicationAdditionalActions(token, "submitToLender", applicationId, valuesColne);
                    console.log("   response    LINE 638 ---  ------------------  ", response);
                    if (response["error"]) {
                        var errors = response["message"]["errors"];
                        var warning = response["message"]["warning"];

                        try { 
                            setMessage(errors.join("\n")); 
                            Notifications("error", errors.join("\n"))
                        }
                        catch (e) { console.log("EXCEPTION ", e); setMessage(JSON.stringify(response["message"])); }

                    }
                    else {
                        var responseResult = response.data !== undefined ? response.data : {};
                        var message = responseResult.message !== undefined ? responseResult.message : "";
                        var payload = responseResult.payload !== undefined ? responseResult.payload : {};
                        var applicationNo = payload.application !== undefined && payload.application.applicationNo !== undefined ? " #: " + payload.application.applicationNo : "";
                        Notifications("success", message)
                        setMessage("");
                    }
                    setProcessing(false);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div className="row"> 
                            <div className="col-sm-12 error mb-5" style={{ whiteSpace: "pre-wrap" }}> {message} </div>                            
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="col-xl-12">
                            {<FormSubmissionButton isActive={true} label={"Submit To Lender"} />}
                        </div>
                    </Form>
                )}

            </Formik>
            }

            {
                processing ? <LoadingScreen /> : ""
            }
        </>
    )
}

export default PepperMoney;