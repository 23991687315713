import { useState } from 'react';
import { CustomButton } from "../../HtmlComponents";

const Lookup = ({ label, callback }) => {

    const [lookup, setLookup] = useState("");

    const handler = (name, value) => {
        switch (name) {
            case "lookup":
                setLookup(value);
                callback("lookup", value);
                break;
            case "clearFilters":
                setLookup("");
                callback("lookup", "");
                break;
        }

    }
    return (
        <>
            <div className='row'>
                <div className='col-sm-12 mb-2'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>

                        <div style={{ width: "300px", minWidth: "200px" }}>
                            <input
                                type="text"
                                className="form-control"
                                style={{ height: "35px", fontWeight: "400" }}
                                placeholder="Lookup"
                                value={lookup}
                                onChange={(e) => {
                                    handler("lookup", e.target.value)
                                }}
                            />
                        </div>
                        <div>
                            <CustomButton
                                buttonClassName="btn btn-sm btn-primary "
                                buttonStyle={{ float: "right", marginLeft: "10px" }}
                                buttonOnClick={() => { handler("clearFilters", "") }}
                                buttonText="Clear"
                                isActive={lookup.length > 0 ? true : false}
                            />
                        </div>

                        <div>
                            <span style={{ fontWeight: "normal", fontSize: "12px", paddingLeft: "10px" }}>
                                <span style={{ fontWeight: "500" }}>{/*Lookup*/}</span> {label}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}
export default Lookup;