import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup } from '../HtmlComponents';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import { Formik, Form, Field } from 'formik';
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import { DirectorSchema } from './schema/DirectorSchema';
import AddressForm from '../global/AddressForm';

import{createDirector} from '../../api/directorsApi';

const CreateDirector = ({ entityType, entityId , callback}) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });
    const [director, setDirector] = useState({
        name: '', email: '', contactNumber: '', address: {
            "fullAddress": "",
            "unitNumber": "",
            "streetNumber": "",
            "streetTypeLongName": "",
            "streetTypeShortName": "",
            "streetName": "",
            "postCode": "",
            "state": "",
            "suburb": ""
        }, dob: "", drivingLicense: {unlicensed: false, number:""}, entityType: entityType, entityId: entityId
    });

    useEffect(() => {
    }, [addressModal])
    const token = useToken();



    return (
        <>
            <Formik
                initialValues={director}
                validationSchema={DirectorSchema}
                enableReinitialize={false}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    setError("");

                    var data = JSON.parse(JSON.stringify(values));
                    data.dob = data.dob.length > 0 ?  formatDate(values.dob, 'DD/MM/YYYY', 'YYYY-MM-DD') : data.dob;
                    const response = await createDirector(token, data);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error" )
                        //setError({ "exist": true, "message": response["message"] });
                        setDirector(values);
                    }else {
                        Notifications("success", response["message"] || "Created Successfully" )
                        callback(response.director);
                    }
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">

                                            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                                                <tbody className="fs-6">
                                                    <tr className="even">
                                                        <td className="text-start">Name</td>
                                                        <td className="text-start text-gray-600">

                                                            <Field name="name" placeholder="Name" className="form-control form-control-solid" />
                                                            {errors.name ? (<div className="error">{errors.name}</div>) : null}

                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Email</td>
                                                        <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                                            {errors.email ? (<div className="error">{errors.email}</div>) : null}</td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Contact Number</td>
                                                        <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" />
                                                            {errors.contactNumber ? (<div  className="error">{errors.contactNumber}</div>) : null}</td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">DOB</td>
                                                        <td>
                                                            <Field type="text" name="dob" placeholder="dd/mm/yyyy" className="form-control form-control-solid">
                                                                {({ field }) => (
                                                                    <MaskedField fieldName="dob" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                                                )}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Driving License</td>
                                                        <td className="text-start text-gray-600">
                                                            <Field name="drivingLicense.number" placeholder="" className="form-control form-control-solid" />
                                                        </td>
                                                    </tr>
                                                    <tr className="even">
                                                        <td className="text-start">Address</td>
                                                        <td className="text-start text-gray-600">
                                                            <Field name="address">
                                                                {({ field }) => (
                                                                    <input
                                                                        className="form-control form-control-solid"
                                                                        type="text"
                                                                        value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                                        onClick={() => setAddressModal({ visible: true, fieldName: "address", callback: setFieldValue })}
                                                                        onChange={(e) => { }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div> 
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true: false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    );
}


export default CreateDirector;