

const LoadingScreen = () => {

    return(
        <>
            <div id="overlay" style={{display:"block"}}>
                <div id="overlay-text">
                <img src="/media/loading/loading1.gif" className="h-200px" />
                </div>
            </div>
        </>
    )

}
export default LoadingScreen;