
import { type } from '@testing-library/user-event/dist/type';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

/* 
    defaultSelectedOption FORMAT : {value : "" , label : "" }
    OPTIONS FORMAT : [ {value: "" , label : "" } , {value: "" , label : "" } ]
    setFieldValue  : REFERENCE OF setFieldValue() FUNCTION

*/
const MultiSelectField = ({ fieldName, defaultSelectedOptions, options, setFieldValue, callback }) => {

    const [selectedOption, setSelectedOption] = useState(null);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#cccccc',
            minHeight: '35px',
            height: '35px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),

        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999,
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 9999,
        }),
    }


    useEffect(() => {
        //console.log("SELECT FIELD RENDERED");
    }, [])

    useEffect(() => {
        setSelectedOption(defaultSelectedOptions)
        //FILTER
        var selectedOPtoins = options.filter(option => {
            if(Array.isArray(defaultSelectedOptions) &&  defaultSelectedOptions.includes(option["value"])){
                return option;
            }
        })        
        var rr = options.filter(option =>  Array.isArray(defaultSelectedOptions) &&  defaultSelectedOptions.includes(option["value"] )  )
    }, [defaultSelectedOptions])


    return (
        <Select
            closeMenuOnSelect={false}
            maxMenuHeight={220}
            menuPlacement="auto"
            styles={customStyles}
            name={fieldName}           
            isMulti
            options={options}
            value= {options.filter(option =>  Array.isArray(defaultSelectedOptions) &&  defaultSelectedOptions.includes(option["value"] )  )}
            defaultValue={options.filter(option =>  Array.isArray(defaultSelectedOptions) &&  defaultSelectedOptions.includes(option["value"] )  )}
            onChange={
                async (selectedOptions) => {
                    var optionKeys = [];
                    if(Array.isArray(selectedOptions)){
                        selectedOptions.map(selectedOption => {
                            optionKeys.push(selectedOption.value);
                        })
                    }
                    await setSelectedOption(optionKeys)
                    await setFieldValue(fieldName, optionKeys)
                    if (typeof callback === "function") { callback(fieldName, selectedOption.value, setFieldValue); }
                }
            }
        />
    );

}
export default MultiSelectField;


