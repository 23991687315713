import { useState } from 'react';
import { FormSubmissionButton, CustomButton, Modal, SelectField, InputField, FloatField, CheckBox } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import AddressForm from '../../../../../components/global/AddressForm';
import Constants from '../../../../../components/global/Constants';


const Employer = ({ prefix, setFieldValue }) => {
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });

    return (
        <>
            <div className='row'>
                <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="  fs-8 fw-bold mb-2">Employer Name</label>
                    <Field name={prefix+"name"}>
                        {({ field }) => (
                            <InputField
                                fieldName={prefix+"name"}
                                fieldValue={field.value}
                                placeholder=""
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="  fs-8 fw-bold mb-2">Employer #</label>
                    <Field name={prefix+"contactNumber"}>
                        {({ field }) => (
                            <InputField
                                fieldName={prefix+"contactNumber"}
                                fieldValue={field.value}
                                placeholder=""
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-3 col-md-6 mb-5 fv-row fv-plugins-icon-container" >

                    <div style={{ textAlign: "center", display: "flex" }}>
                        <div style={{ display: "inline-block" }}> <label className="  fs-8 fw-bold mb-2">Employer Address </label></div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex" }}>
                                <span style={{marginRight: "5px"}}>( Unknown </span>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Field name={prefix+"addressUnknown"}>
                                    {({ field }) => (
                                        <CheckBox
                                            name={prefix+"addressUnknown"}
                                            setFieldValue={setFieldValue}
                                            defaultChecked={field.value ? field.value : false}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span style={{marginLeft: "5px"}}> )</span>
                            </div>

                        </div>
                    </div>
                    <Field name={prefix + "address"}>
                        {({ field }) => (
                            <input
                                className="form-control"
                                style={{ height: Constants.TEXTFIELD_HEIGHT }}
                                type="text"
                                value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                onClick={() => setAddressModal({ visible: true, fieldName: `${prefix}address`, callback: setFieldValue })}
                                onChange={(e) => { }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Creditrep Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    )

}

export default Employer;