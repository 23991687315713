//ConflictOfInterests


import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, InputField } from '../../HtmlComponents';
import { replaceNullWithString, formatDate, convertDateintoUnix, dateFilter } from '../../../helpers';
import { Formik, Form, Field, FieldArray } from 'formik';
import ErrorMessage from '../../global/errors/Error';
import Notifications from '../../global/Notifications';
import LoadingScreen from '../../global/LoadingScreen';
import { getOfficeContact, createRegister } from "../../../api";


const CreateConflictOfInterest = ({ callback }) => { 
    const [conflictOfInterests, setConflictOfInterests] =useState({ date: '', payload: { impactOnClient: "", remedy: "", description: "" }, type: "conflictOfInterest" });
    const [processing, setProcessing] = useState(false);

    const token = useToken();

    return (
        <>
            <Formik
                initialValues={conflictOfInterests}
                enableReinitialize={false}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    var data = JSON.parse(JSON.stringify(values));
                    data["date"] =  convertDateintoUnix(data["date"]);
                    console.log("values::::: ", values);
                     setProcessing(true);
                     const response = await createRegister(data , token);
                     if (response["error"]) {
                         Notifications("error", response["message"] || "Error");
                         setConflictOfInterests(values);
                     } else {
                         console.log("response.id : " + response.id);
                         Notifications("success", response["message"] || "Created Successfully")
                         //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                         console.log(response);
                         if (response["error"] !== undefined && response["error"] == 0  && response["data"] !== undefined) { 
                            if(response["data"]["payload"] !== undefined ){response["data"]["payload"] = JSON.stringify(response["data"]["payload"]);}                            
                            callback("updatePayloadOnCreation", response["data"]);
                         }
                     }
                     setProcessing(false);
                }}
            >
                {({ errors, values, touched, setFieldValue, handleChange }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Date</div>
                                                <Field type="text" name="date" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                                    {({ field }) => (
                                                        <MaskedField fieldName="date" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                                    )}
                                                </Field>
                                            </div>
                                            {errors.date ? (<div>{errors.date}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Conflict of Interest description e.g. person, area, the cause of conflict</div>
                                                <Field name="payload.description" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.description ? (<div>{errors.payload.description}</div>) : null}

                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Impact on client</div>
                                                <Field name="payload.impactOnClient" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.impactOnClient ? (<div>{errors.payload.impactOnClient}</div>) : null}


                                            <div className='col-sm-12 mb-5'>
                                                <div className='col mb-2'>Remedy e.g method to manage, action taken</div>
                                                <Field name="payload.remedy" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.payload && errors.payload.remedy ? (<div>{errors.payload.remedy}</div>) : null}


                                        </div>
                                    </div>
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default CreateConflictOfInterest;