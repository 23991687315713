
import { useEffect, useState } from "react";
import SingleIncome from "./SingleIncome";
import Constants from "../../../../../components/global/Constants";

//incomeClass => primaryIncome, secondaryIncome, previousIncome
//income =>ARRAY
const ListIncome = ({income , incomeClass, incomeTypeList, setFieldValue, callback , deleteIncome,  applicationId }) => {

    const [frequency, setFrequency] = useState([]);

    const FetchFrequency = () =>{
        var options = [];
        Object.keys(Constants.FREQUENCY).map( key => {
            options.push({value : key , label : Constants.FREQUENCY[key]});
        })
        setFrequency(options);
    }

    useEffect( () => {
        FetchFrequency();               
    }, [])
    
    
    /*
    return (
        <>
        {JSON.stringify(income)}
        </>
    )
    */
    
    return( 
        Array.isArray(income) ? income.map( ( singleIncome , index ) => {
            return (
                <SingleIncome key={index.toString()} index= {index} incomeType = {singleIncome.incomeType !== undefined ? singleIncome.incomeType : null} incomeClass= {incomeClass}  incomeTypeList = {incomeTypeList}  frequencyList= {frequency}  setFieldValue = {setFieldValue} callback = {callback} deleteIncome={deleteIncome}  applicationId={applicationId} />
            )
        }) : ""
    )
}

export default ListIncome;