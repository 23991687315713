import {getdayPeriodType} from '../../../../helpers';

const StandardTemplate = (  paylaod ) => {

    var userFirstName = paylaod["firstName"] !== undefined ? paylaod["firstName"] : "";
    var userName = paylaod["userName"] !== undefined ? paylaod["userName"] : "";
    var defaultPassword = paylaod["defaultPassword"] !== undefined ? paylaod["defaultPassword"] : "";
    var dayPeriodType = getdayPeriodType();

    var initialValues = `Good ${dayPeriodType} ${userFirstName},</p>`
    
    +`<p>On our internal NFAL portal Brokahub, we have pages that our brokers and credit reps have access to with which they can view each individual lenders rates, policies and procedures, fees and charges and also the ability for you to promote the virtues of your company and the reasons why the broker should use your products over your competitors.</p>`
    
    +`<p>To enable this part of our portal to be kept up to date, we have engineered the system so as our lender partners can login direct to THEIR page only, to view and update as they release new rates, promotions, contacts or policies/procedures.</p>`
    
    +`<p>The site address for access is <a href="https://brokahub.com.au">https://brokahub.com.au</a></p>`

    +`<ul>`
    +`<li>Your username and password is:</li>`    
    +`<li>Username: ${userName}</li>`    
    +`<li>Password: ${defaultPassword}</li>`    
    + `</ul>`

    +`<p>I would be grateful if you could find the time to login and check what we currently have loaded. If it is incorrect, feel free to delete and replace with current information. Feel free to promote your business in any way you see fit.</p>`
    +`<p>You have our assurance that you and other lenders will only have access to view your own page. </p>`
    +`<p>If you have any issues or questions, please feel free to call me.</p>`
    +`<p>Kind Regards,</p>`

    return( initialValues )
}

export default StandardTemplate;