import { useEffect, useRef, useState } from 'react';
import { RightDrawer, SelectField, CustomButton, IconButton } from '../../components/HtmlComponents';
import { Printer, FileIcon} from '../../components/HtmlComponents/Icons';
import AddAnotherApplicant from '../../components/applications/appComponents/AddAnotherApplicant';
import PrintApplication from '../../components/applications/appComponents/PrintApplication';

import { useToken } from '../../hooks/useAuth';
import { performApplicationAdditionalActions } from "../../api";

import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../../components/global/LoadingScreen';

//UTILITIES 
import ComplianceTab from '../../components/applications/appComponents/ComplianceTab';
import ApplicationNotes from '../../components/applications/appComponents/ApplicationNotes';
//import Files from '../../components/files/Files';
import ApplicationFiles from '../../components/files/ApplicationFiles';

import TaxCalculator from '../../components/applications/appComponents/TaxCalculator';
import YTD from '../../components/applications/appComponents/YTD';
import LoanCalculator from '../../components/applications/appComponents/LoanCalculator';

const ConsumerApplicationUtilities = ({application, applicationId , applicants, applicant, applicantId, callback}) => {

    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [processing, setProcessing] = useState(false);
    const token = useToken();

    const handler = async (action, payload) => {
        switch (action) {
            //LIST OF ACTIONS COMES UNDER COMPLIANCES
            case "compliance":
                setDrawerSettings({ visible: true, heading: "Compliance", childName: "compliance", meta: { applicationId: applicationId } });
                break;
            case "executeApplicationNotes":
                if(applicationId !== 0){ updateApplicationNotes(payload); }
                else{ Notifications("error", "Please create the application first.") /* NOTIFICATION */}
                break;    
            case "applicationNotes":
                setDrawerSettings({ visible: true, heading: "Applicant Notes", childName: "applicationNotes", meta: { applicationId: applicationId } });
                break;
            case "applicationFiles":
                setDrawerSettings({ visible: true, heading: `Files`, childName: "applicationFiles", meta: { width: "800", applicationId: applicationId } })
                break;
                
            case "printApplication":
                setDrawerSettings({ visible: true, heading: `Application`, childName: "printApplication", meta: { width: "800", applicationId: applicationId  } })
                break;
                
            case "taxCalculator":
                setDrawerSettings({ visible: true, heading: `Tax Calculator`, childName: "taxCalculator", meta: { width: "800", applicationId: applicationId  } })
                break;
                
            case "ytd":
                setDrawerSettings({ visible: true, heading: `YTD`, childName: "ytd", meta: { width: "800", applicationId: applicationId  } })
                break;
                
            case "loanCalculator":
                setDrawerSettings({ visible: true, heading: `Loan Calculator`, childName: "loanCalculator", meta: { width: "800", applicationId: applicationId  } })
                break;
                

            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

        //UPDAET APPLICATION NOTES - UPDATE APPLICATION STATE UPON SUCCESSFUL UPDATE
        const updateApplicationNotes = async(payload) =>{
            setProcessing(true);
            var oldNotes = payload["oldNotes"];
            var newNotes = payload["newNotes"];
            var response = await performApplicationAdditionalActions(token, "updateApplicationNotes", applicationId, {applicationNotes: newNotes});
            var responseResult = response.data !== undefined ? response.data : {};
            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                Notifications("success", responseResult["message"] !== undefined ? responseResult["message"] : "Saved")
                //referrerNotes: "" , quoteNotes: "", personalDetailNotes: "", incomeNotes: "", assetsNotes: "", debtsNotes: "", expenseNotes: ""
                var combinedNotes = {
                    referrerNotes : (oldNotes["referrerNotes"] !== undefined ? oldNotes["referrerNotes"] : "" ) + " " +(newNotes["referrerNotes"] !== undefined ? newNotes["referrerNotes"] : "" ),
                    quoteNotes : (oldNotes["quoteNotes"] !== undefined ? oldNotes["quoteNotes"] : "" ) + " " +(newNotes["quoteNotes"] !== undefined ? newNotes["quoteNotes"] : "" ),
                    personalDetailNotes : (oldNotes["personalDetailNotes"] !== undefined ? oldNotes["personalDetailNotes"] : "" ) + " " +(newNotes["personalDetailNotes"] !== undefined ? newNotes["personalDetailNotes"] : "" ),
                    incomeNotes : (oldNotes["incomeNotes"] !== undefined ? oldNotes["incomeNotes"] : "" ) + " " +(newNotes["incomeNotes"] !== undefined ? newNotes["incomeNotes"] : "" ),
                    assetsNotes : (oldNotes["assetsNotes"] !== undefined ? oldNotes["assetsNotes"] : "" ) + " " +(newNotes["assetsNotes"] !== undefined ? newNotes["assetsNotes"] : "" ),
                    debtsNotes : (oldNotes["debtsNotes"] !== undefined ? oldNotes["debtsNotes"] : "" ) + " " +(newNotes["debtsNotes"] !== undefined ? newNotes["debtsNotes"] : "" ),
                    expensesNotes : (oldNotes["expensesNotes"] !== undefined ? oldNotes["expensesNotes"] : "" ) + " " +(newNotes["expensesNotes"] !== undefined ? newNotes["expensesNotes"] : "" ),
                }
                callback("updateApplicationNotes",combinedNotes );
                handler("closeDrawer");

            }
            else {
                Notifications("error", response["message"] !== undefined ? response["message"] : "Error")
            }
            setProcessing(false);
        }

    return (
        <>
            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                    {drawerSettings["childName"] === "addAnotherApplicant" ? <AddAnotherApplicant applicationId={drawerSettings["meta"]["applicationId"]} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "compliance" ? <ComplianceTab application={application} applicationId={applicationId} applicants={applicants} applicant={applicant} applicantId={applicantId} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "applicationNotes" ? <ApplicationNotes initialValues={application} applicationId={applicationId} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "applicationFiles" ? <ApplicationFiles entityType="application" entityId={applicationId} enableUpload={true} callback={handler} /> : ""}
                    {drawerSettings["childName"] === "printApplication" ? <PrintApplication applicationId={applicationId}  applicantId={applicantId} /> : ""}
                    {drawerSettings["childName"] === "taxCalculator" ? <TaxCalculator /> : ""}
                    {drawerSettings["childName"] === "ytd" ? <YTD /> : ""}
                    {drawerSettings["childName"] === "loanCalculator" ? <LoanCalculator /> : ""}
                </RightDrawer> : ""
            }
    
            <div className="engage-toolbar d-flex position-fixed zindex-2 top-75 end-0 transform-90  gap-2" style={{ zIndex: "1", background:"transparent" }}>
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { handler("applicationNotes") }} buttonText="Notes" />            
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { handler("compliance") }} buttonText="Compliance" />
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "40px" }} isActive={true} buttonOnClick={() => { handler("applicationFiles") }} buttonText={<FileIcon />} />
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "40px" }} isActive={true} buttonOnClick={() => { handler("printApplication") }} buttonText={<Printer />} />
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "40px" }} isActive={true} buttonOnClick={() => { handler("taxCalculator") }} buttonText={"Tax"} />
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "40px" }} isActive={true} buttonOnClick={() => { handler("ytd") }} buttonText={"YTD"} />
                <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "40px" }} isActive={true} buttonOnClick={() => { handler("loanCalculator") }} buttonText={"LC"} />
            </div>

            { processing ? <LoadingScreen /> : "" }
        </>
    )

}

export default ConsumerApplicationUtilities;