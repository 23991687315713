import { useEffect, useState } from "react";
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, RightDrawer, CustomButton } from '../../HtmlComponents';
import Constants from '../../global/Constants';
import { removeSpace } from '../../../helpers';
import LoadingScreen from '../../global/LoadingScreen';
import { searchCarInfo } from "../../../api";
import { useToken } from "../../../hooks/useAuth";


const SearchForCarInfo = ({ payload, callback }) => {

    const [searchValues, setSearchValues] = useState({
        applicationId: 0,
        searchBy: "",
        vin: "",
        registrationNumber: "",
        registrationState: ""
    })
    const [submissionAllowed, setSubmissionAllowed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [states, setStates] = useState([]);

    const token = useToken();

    useEffect(() => {
        var firstVehicle = payload !== undefined && payload.buyingItem !== undefined && payload.buyingItem.motorVehicles !== undefined && payload.buyingItem.motorVehicles[0] ? payload.buyingItem.motorVehicles[0] : {};

        setSearchValues({
            ...searchValues,
            applicationId: payload["id"] !== undefined ? payload["id"] : 0,
            vin: firstVehicle.vin !== undefined ? firstVehicle.vin : "",
            registrationNumber: firstVehicle.registrationNumber !== undefined ? firstVehicle.registrationNumber : "",
            registrationState: firstVehicle.registrationState !== undefined ? firstVehicle.registrationState : "",
        })

        //STATE (ETC VIC , NSW ETC)
        var stateOptions = [];
        Object.keys(Constants.STATES).map(index => {
            stateOptions.push({ value: index, label: Constants.STATES[index] })
        })
        setStates(stateOptions);

    }, [])


    //UPDATE SUBMISSION FLAG 
    useEffect(() => {
        if (searchValues["searchBy"] === "vin" && removeSpace(searchValues["vin"]).length === 17) { setSubmissionAllowed(true); }
        else if (searchValues["searchBy"] === "registrationNumber" && removeSpace(searchValues.registrationNumber).length > 0 && removeSpace(searchValues.registrationState).length > 0) { setSubmissionAllowed(true); }
        else { setSubmissionAllowed(false); }
    }, [searchValues])


    const makeAnAPICall = async () => {
        setProcessing(true);
        //MAKE AN API CALL
        var response = await searchCarInfo(token, searchValues); 
        if (response["error"] === 0 && response["data"] !== undefined && response["data"]["payload"] !== undefined && response["data"]["payload"] !== null) {
            callback("carInfoReceived", response["data"]["payload"]);
        }
        else {
            //error message 
        }
        setProcessing(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <RadioGroup
                        fieldName="searchBy"
                        defaultSelectedOption={searchValues.searchBy}
                        options={[{ label: "VIN", value: "vin" }, { label: "Registration #", value: "registrationNumber" }]}
                        setFieldValue={(name, value) => {
                            setSearchValues({ ...searchValues, searchBy: value })
                        }}
                        layoutStyle="horizontal"
                    />
                </div>
            </div>

            <div className="row mt-3" >
                {searchValues.searchBy === "vin" ?
                    <div className="col-sm-6">
                        <label className="required fs-8 fw-bold mb-2">VIN #</label>
                        <InputField
                            fieldName="vin"
                            fieldValue={searchValues.vin}
                            setFieldValue={(name, value) => { setSearchValues({ ...searchValues, vin: value }) }}
                        />
                    </div>
                    : ""
                }
                {searchValues.searchBy === "registrationNumber" ? <>
                    <div className="col-sm-6">
                        <label className="required fs-8 fw-bold mb-2">Registration #</label>
                        <InputField
                            fieldName="registrationNumber"
                            fieldValue={searchValues.registrationNumber}
                            setFieldValue={(name, value) => { setSearchValues({ ...searchValues, registrationNumber: value }) }}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label className="required fs-8 fw-bold mb-2">Registration State</label>
                        <SelectField
                            fieldName="registrationState"
                            defaultSelectedOption={{ value: searchValues.registrationState, label: '' }}
                            options={states}
                            setFieldValue={(name, value) => { setSearchValues({ ...searchValues, registrationState: value }) }}
                        />
                    </div>
                </> : ""}


                <div className="col-sm-12 mt-3"><p style={{ float: "right" }} className="error">Please not this will erase existing values for buying item.</p></div>
                <div className="col-xl-12 mt-2">
                    {<CustomButton
                        buttonClassName=" btn btn-primary btn-sm "
                        buttonStyle={{ float: "right" }}
                        isActive={submissionAllowed}
                        buttonOnClick={makeAnAPICall}
                        buttonText="Load Car Info"
                    />}
                </div>


                {processing ? < LoadingScreen /> : ""}
            </div>
        </>
    )
}

export default SearchForCarInfo;