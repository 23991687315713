
import {formatDate} from '../../helpers';
import {IconButton} from '../HtmlComponents';
const SingleDirector = ({director, callback}) =>{
    console.log("directr from single:::::::", director);
    return(
        <>
         <tr>
            <td> {director && director.name !== undefined ? director.name : ""} </td>
            <td> {director && director.email  !== undefined  ? director.email : ""} </td>
            <td> {director && director.contactNumber  !== undefined  ? director.contactNumber : ""}</td>
            <td> {director && director.drivingLicense.number  !== undefined  ? director.drivingLicense.number : ""}</td>
            <td> {director && director.address.fullAddress  !== undefined  ? director.address.fullAddress : ""}</td>
            <td> <IconButton 
                    iconType = "edit"
                    buttonClassName = "btn btn-sm btn-light btn-active-secondary"
                    buttonOnClick ={() => {callback(director.id)}}
                    tooltipDescription = "Update"
                    /> 
            </td>
        </tr>
        </>

    )
}
export default SingleDirector;