import * as Yup from 'yup';


const LenderValidationSchema =  Yup.object().shape({
    name : Yup.string().required('Required'),
    assessmentEmail : Yup.string().email("Must be valid email").required('Required'),
    settlementEmail : Yup.string().email("Must be valid email").required('Required'),
    contactNumber :  Yup.string().matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),
});
 
export default LenderValidationSchema ;