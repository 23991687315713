import { useState } from 'react';
import { FormSubmissionButton, CustomButton, Modal, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import AddressForm from '../../../../../components/global/AddressForm';
import Constants from '../../../../../components/global/Constants';

const Bank = ({ prefix, setFieldValue }) => {

    return (
        <>
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="  fs-8 fw-bold mb-2">BSB</label>
                    <Field name={prefix + "bsb"}>
                        {({ field }) => (
                            <InputField
                                fieldName={prefix + "bsb"}
                                fieldValue={field.value}
                                placeholder=""
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="  fs-8 fw-bold mb-2">Account #</label>
                    <Field name={prefix + "accountNumber"}>
                        {({ field }) => (
                            <InputField
                                fieldName={prefix + "accountNumber"}
                                fieldValue={field.value}
                                placeholder=""
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
            </div>
        </>
    )
}

export default Bank;