import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import LenderListItem from './LenderListItem';
import { getLendersList } from '../../api/lenderApi';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { useNavigate } from 'react-router-dom'
import {useMethodAllowed} from '../../hooks/usePermission';

const ListCreditReps = () => {

    const [lendersList, setLendersList] = useState([]);
    const token = useToken();
    const entityId = useEntityId();
    let navigate = useNavigate();
    const createLenderAllowed = useMethodAllowed("lender", "create");

    const userRole = useRole();

    useEffect(() => {
        fetchLenders();
    }, []);


    const fetchLenders = async () => {
        const response  = await getLendersList(token);
        if(response["error"] === 0 && response["lenders"]){
            setLendersList(response["lenders"]);
        }
        else{
            //ERROR
        }
    }

    //UPDATE HANDLER 
    const updatesHandler = (name, payload) => {
        switch(name){
            case "removeLenderFormList":
                setLendersList( prevLenders =>  prevLenders.filter( singleLender => singleLender.id !== payload.id))
                break;
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                    <div className='cols-m-12'>
                        { createLenderAllowed ?
                            <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { navigate("/user/addLender") }} buttonText="Create" />
                            : ""
                        }
                    </div>
                </div>

                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "167.863px" }}>Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }} >Contact Number</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "196.337px" }}>Assessment Email</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Settlement Email</th>
                                            <th className="   " tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Last Login At</th>
                                            { userRole === "superAdmin" ? <th className="   " tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "166.337px" }}>Actions</th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            lendersList && Array.isArray(lendersList) ?
                                                lendersList.map(singleLender => (
                                                    <LenderListItem key={singleLender.id} lender={singleLender} callback={updatesHandler} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListCreditReps;