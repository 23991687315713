
//href={subMenuItems[subMenuNavigationKey].link} 
const SubMenu = ({ subMenuItems, currentEntityId, callbak }) => {
    var subMenuNavigationLinks = '';


    const SubMenuHandler = ({ subMenuItem, subMenuNavigationKey }) => {

        if (typeof subMenuItem === "object" && Object.hasOwn(subMenuItem, "accessibleBy") && Array.isArray(subMenuItem.accessibleBy)) {
            //CHECK IF PARENT ID EXISTS 
            if (subMenuItem.accessibleBy.includes(currentEntityId)) {
                return (<SubMenuHtml subMenuItem={subMenuItem} subMenuNavigationKey={subMenuNavigationKey} />)
            }
            else {
                return "";
            }
        }
        else {
            return (<SubMenuHtml subMenuItem={subMenuItem} subMenuNavigationKey={subMenuNavigationKey} />)
        }
    }

    const SubMenuHtml = ({ subMenuItem, subMenuNavigationKey }) => {

        return (
            <>
                <div className={subMenuItem.isSelected !== undefined && subMenuItem.isSelected == true ? " menu-link active" : "menu-link"} onClick={() => { callbak(subMenuNavigationKey) }}>
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">{subMenuItem.name}</span>
                </div>
            </>
        )
    }

    for (const subMenuNavigationKey in subMenuItems) {
        subMenuNavigationLinks = (<> {subMenuNavigationLinks}
            <div className="menu-item">
                { 
                    subMenuItems[subMenuNavigationKey].active ? <SubMenuHandler  subMenuItem= {subMenuItems[subMenuNavigationKey]}  subMenuNavigationKey={subMenuNavigationKey}  /> : ""
                         
                }

            </div>
        </>);
    }

    return (<><div className="menu-sub menu-sub-accordion  menu-active-bg"> {subMenuNavigationLinks} </div></>)
}


export default SubMenu;

/**
 * 
 * <div className={subMenuItems[subMenuNavigationKey].isSelected !== undefined && subMenuItems[subMenuNavigationKey].isSelected == true ? " menu-link active" : "menu-link"} onClick={() => { callbak(subMenuNavigationKey) }}>
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">{subMenuItems[subMenuNavigationKey].name}</span>
                        </div> : ""

 */