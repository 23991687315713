

import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getAnalystApplicationsCountByProgressStatus } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";



const AnalystApplicationsCountByProgressStatusReport = ({ startDate, endDate }) => {
    const [processing, setProcessing] = useState(false);
    const [analystApplicationsCountByProgressStatus, setAnalystApplicationsCountByProgressStatus] = useState({});

    const token = useToken();

    //FETCH ANALYST APPLICATIONS COUNT BY PROGRESS STATUS
    const fetchAnalystApplicationsCountByProgressStatus = async () => {
        setProcessing(true);
        console.log("CALLING AnalystApplicationsCountByProgressStatusReport  API - START  ");
        var response = await getAnalystApplicationsCountByProgressStatus(token, startDate, endDate);

        console.log("CALLING AnalystApplicationsCountByProgressStatusReport  API - END  ");


        console.log("response:::::::::::::::::::::::::::     ", response);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined) {
            setAnalystApplicationsCountByProgressStatus(response["data"]);
        }
        setProcessing(false);
    }


    useEffect(() => {
        fetchAnalystApplicationsCountByProgressStatus();
    }, [startDate, endDate])

    return (
        <>
            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="bold mb-4">My Referrals</div>
                    {
                        JSON.stringify(analystApplicationsCountByProgressStatus)
                    }
                    {processing ? <LoadingBlock /> :
                        <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                <thead className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 text-start">Status</th>
                                        <th className="p-0 pb-3 th-center-aligh text-start" >Total</th>
                                        <th className="p-0 pb-3 th-center-aligh text-start" >My %</th>
                                        <th className="p-0 pb-3 th-center-aligh text-start" >Branch %</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bold">
                                    {
                                        Array.isArray(analystApplicationsCountByProgressStatus) ?
                                            analystApplicationsCountByProgressStatus.map(singleRecord => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td> {singleRecord["analystApplicationsProgressStatus"]} </td>
                                                            <td> {singleRecord["analystTotalApplications"]} </td>
                                                            <td> {singleRecord["analystApplicationPercentage"]} </td>
                                                            <td> {singleRecord["companyApplicationPercentage"]} </td>
                                                        </tr>
                                                    </>
                                                )
                                            }): "<tr><td colSpan='4'></td></tr>"
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AnalystApplicationsCountByProgressStatusReport;