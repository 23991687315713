import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, InputField } from '../HtmlComponents';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import { Formik, Form, Field, FieldArray } from 'formik';
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../../components/global/LoadingScreen';
import { getOfficeContact, createOfficeContact } from "../../api";


const CreateOfficeContact = ({ callback }) => {
    const [officeContact, setOfficeContact] = useState({ name: '', properties: [] });
    const [processing, setProcessing] = useState(false);

    const token = useToken();

    return (
        <>
            <Formik
                initialValues={officeContact}
                enableReinitialize={false}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    var data = JSON.parse(JSON.stringify(values));

                    setProcessing(true);
                    const response = await createOfficeContact(token, data);
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error")
                        setOfficeContact(values);
                    } else {
                        console.log("response.id : " + response.id);
                        Notifications("success", response["message"] || "Created Successfully")
                        //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE                                       
                        console.log(response);
                        if(response["error"] !== undefined && response["error"] == 0 && response["officeContact"] !== undefined){
                            callback("updatePayloadOnOfficeContactCreation", response["officeContact"]);

                        }
                    }
                }}
            >
                {({ errors, values, touched, setFieldValue, handleChange }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="card mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className='col-sm-12 mb-5'>
                                                <div className='col'>Business Contact</div>
                                                <Field name="name" placeholder="" className="form-control form-control-solid" />
                                            </div>
                                            {errors.name ? (<div>{errors.name}</div>) : null}

                                            <FieldArray
                                                name="properties"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.properties.map((value, index) => (
                                                            <div key={index}>
                                                                <div className='col-sm-12 mb-5' style={{ display: "flex", gap: "1rem" }}>

                                                                    <input
                                                                        name={`properties[${index}].name`}
                                                                        value={values.properties[index].name}
                                                                        className="form-control"
                                                                        style={{ height: "35px", fontWeight: "400" }}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <input
                                                                        name={`properties[${index}].value`}
                                                                        value={values.properties[index].value}
                                                                        className="form-control"
                                                                        style={{ height: "35px", fontWeight: "400" }}
                                                                        onChange={handleChange}
                                                                    />

                                                                    <button
                                                                        disabled={values.properties?.length === 1}
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                        className="deleteButton btn btn-sm btn-light btn-active-secondary btn "
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.push({ name: "", value: "" })}
                                                            className=' btn btn-sm btn-flex btn-light-primary'
                                                        >
                                                            Add a Field
                                                        </button>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                    </div>
                                    {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {processing ? <LoadingScreen /> : ""}
        </>
    );
}


export default CreateOfficeContact;