import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import CreditRepValidationSchema from './schema/CreditRepValidationSchema';
import { updateCreditRep, getCreditRep } from "../../api";
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import Notifications from '../../components/global/Notifications';
//ERRORS
import ErrorMessage from '../global/errors/Error';



import AddressForm from '../global/AddressForm';

const EditCreditRep = () => {
  const [singleCreditRep, setSingleCreditRep] = useState({});
  const [error, setError] = useState({ "exist": false, "message": "" });
  const [loading, setLoading] = useState(true);
  const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });

  const [submitFlag, setSubmitFlag] = useState(false);

  let { id } = useParams();
  let navigate = useNavigate();
  const token = useToken();

  useEffect(() => {
    const fetchCReditRep = async () => {
      const creditRep = await getCreditRep(id, token);
      console.log(creditRep)
      var unresolvedPromises = replaceNullWithString(creditRep);

      Promise.all([unresolvedPromises]).then((cleanCreditRep) => {
        //CONVERT DATE INTO DD/MM/YYYY FORMAT || ASSIGN '' IF EMPTY OR NULL
        if (cleanCreditRep[0].dateEstablished) { cleanCreditRep[0].dateEstablished = formatDate(cleanCreditRep[0].dateEstablished, 'unix', 'YYYY-MM-DD'); }
        if (cleanCreditRep[0].associations.insurer.expiryDate) { cleanCreditRep[0].associations.insurer.expiryDate = formatDate(cleanCreditRep[0].associations.insurer.expiryDate, "unix", 'YYYY-MM-DD'); }
        if (cleanCreditRep[0].associations.afcaMembership.expiryDate) { cleanCreditRep[0].associations.afcaMembership.expiryDate = formatDate(cleanCreditRep[0].associations.afcaMembership.expiryDate, "unix", 'YYYY-MM-DD'); }
        setSingleCreditRep(cleanCreditRep[0] ? cleanCreditRep[0] : {});
        setLoading(false);
      })
    }
    fetchCReditRep();
  }, []);


  useEffect(() => {
    console.log(singleCreditRep);
    if (submitFlag === true) {
      UpdateCreditRepDetail();
    }
  }, [singleCreditRep]);

  const addressHandler = (newAddress) => {
    return setSingleCreditRep({ ...singleCreditRep, address: newAddress })
  }
  /*  ********************* HTML COMPONENTS OF CREDIT REP  - START *****************/


  const BrokerageDetail = (errors, touched, handleChange, setFieldValue) => {
    return (<>
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-body p-0">
          <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
            <div className="d-flex flex-stack">
              <h3 className="m-0 text-white fw-bolder fs-3">Brokerage Details</h3>
            </div>
          </div>

          <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
              <tbody className="fs-6">
                <tr className="even">
                  <td className="text-start">Trading Name</td>
                  <td className="text-start text-gray-600"><Field name="tradingName" placeholder="Trading Name" className="form-control form-control-solid" />
                    {errors.tradingName && touched.tradingName ? (<div className="error">{errors.tradingName}</div>) : null}</td>
                </tr>

                <tr className="even">
                  <td className="text-start">Entity Name </td>
                  <td className="text-start text-gray-600"><Field name="entityName" placeholder="Entity Name" className="form-control form-control-solid" />
                    {errors.entityName && touched.entityName ? (<div className="error">{errors.entityName}</div>) : null}</td>
                </tr>

                <tr className="even">
                  <td className="text-start">Structure</td>
                  <td className="text-start text-gray-600">
                    <Field name="structure">
                      {({ field }) => (
                        <RadioGroup
                          fieldName="structure"
                          defaultSelectedOption={field.value}
                          options={[{ label: "Company", value: "company" }, { label: "Partnership", value: "partnership" }, { label: "Sole Trader", value: "soleTrader" }]}
                          setFieldValue={setFieldValue}
                          layoutStyle="vertical"
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr className="even">
                  <td className="text-start">ABN </td>
                  <td className="text-start text-gray-600"><Field name="abn" placeholder="ABN" className="form-control form-control-solid" />
                    {errors.abn && touched.abn ? (<div className="error">{errors.abn}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">ACN </td>
                  <td className="text-start text-gray-600"><Field name="acn" placeholder="ACN" className="form-control form-control-solid" />
                    {errors.acn && touched.acn ? (<div className="error">{errors.acn}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">Date Established </td>
                  <td className="text-start text-gray-600">
                    <Field type="text" name="dateEstablished" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                      {({ field }) => (
                        <MaskedField fieldName="dateEstablished" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                      )}
                    </Field>
                    {errors.dateEstablished && touched.dateEstablished ? (<div className="error">{errors.dateEstablished}</div>) : null}
                  </td>
                </tr>
                <tr className="even">
                  <td className="text-start">contact Number </td>
                  <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" onChange={handleChange} />
                    {errors.contactNumber && touched.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">Fax </td>
                  <td className="text-start text-gray-600"><Field name="fax" placeholder="Fax" className="form-control form-control-solid" />
                    {errors.fax && touched.fax ? (<div className="error">{errors.fax}</div>) : null}</td>
                </tr>

                <tr className="even">
                  <td className="text-start">Address</td>
                  <td className="text-start text-gray-600">
                    <Field name="address">
                      {({ field }) => (
                        <input
                          className="form-control form-control-solid"
                          type="text"
                          value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                          onClick={() => setAddressModal({ visible: true, fieldName: "address", callback: setFieldValue })}
                          onChange={(e) => { }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr className="even">
                  <td className="text-start">Website Url </td>
                  <td className="text-start text-gray-600"><Field name="websiteUrl" placeholder="Website Url" className="form-control form-control-solid" />
                    {errors.websiteUrl && touched.websiteUrl ? (<div className="error">{errors.websiteUrl}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">Email</td>
                  <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                    {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>)
  }

  const AssociationAndMemberships = (errors, touched, setFieldValue) => {
    return (<>

      <div className="card mb-xl-8">
        <div className="card-body p-0">
          <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
            <div className="d-flex flex-stack">
              <h3 className="m-0 text-white fw-bolder fs-3">Associations & Memberships</h3>
            </div>
          </div>
          <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
              <tbody className="fs-6">
                <tr className="even">
                  <td className="text-start">Insurer</td>
                  <td className="text-start text-gray-600"><Field name="associations.insurer.name" placeholder="Name" className="form-control form-control-solid" />
                    {errors.associations && errors.associations.insurer && errors.associations.insurer.name && touched.associations.insurer.name ? (<div className="error">{errors.associations.insurer.name}</div>) : null}</td>
                </tr>

                <tr className="even">
                  <td className="text-start">Policy Number</td>
                  <td className="text-start text-gray-600"><Field name="associations.insurer.policyNumber" placeholder="Policy Number" className="form-control form-control-solid" />
                    {errors.associations && errors.associations.insurer && errors.associations.insurer.policyNumber && touched.associations.insurer.policyNumber ? (<div className="error">{errors.associations.insurer.policyNumber}</div>) : null}
                  </td>
                </tr>
                <tr className="even">
                  <td className="text-start">Expiry Date</td>
                  <td className="text-start text-gray-600">
                    <Field type="text" name="associations.insurer.expiryDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid">
                      {({ field }) => (
                        <MaskedField fieldName="associations.insurer.expiryDate" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                      )}
                    </Field>
                    {errors.associations && errors.associations.insurer && errors.associations.insurer.expiryDate ? (<div className="error">{errors.associations.insurer.expiryDate}</div>) : null}
                  </td>
                </tr>
                <tr className="even">
                  <td className="text-start">AFCA Membership #</td>
                  <td className="text-start text-gray-600"><Field name="associations.afcaMembership.membershipNumber" placeholder="Membership #" className="form-control form-control-solid" />
                    {errors.associations && errors.associations.afcaMembership && errors.associations.afcaMembership.membershipNumber ? (<div className="error">{errors.associations.afcaMembership.membershipNumber}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">AFCA Expiry Date</td>
                  <td className="text-start text-gray-600">
                    <Field type="text" name="associations.afcaMembership.expiryDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid">
                      {({ field }) => (
                        <MaskedField fieldName="associations.afcaMembership.expiryDate" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                      )}
                    </Field>
                    {errors.associations && errors.associations.afcaMembership && errors.associations.afcaMembership.expiryDate ? (<div className="error">{errors.associations.afcaMembership.expiryDate}</div>) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>)
  }

  const BankingDetails = (errors, touched) => {
    return (<>
      <div className="card   mb-xl-8">
        <div className="card-body p-0">
          <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
            <div className="d-flex flex-stack">
              <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
            </div>
          </div>
          <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
              <tbody className="fs-6">
                <tr className="even">
                  <td className="text-start">Bank Name</td>
                  <td className="text-start text-gray-600"><Field name="bankingDetail.name" placeholder="Bank Name" className="form-control form-control-solid" />
                    {errors.bankingDetail && errors.bankingDetail.name && touched.bankingDetail.name ? (<div className="error">{errors.bankingDetail.name}</div>) : null}</td>
                </tr>

                <tr className="even">
                  <td className="text-start">Branch</td>
                  <td className="text-start text-gray-600"><Field name="bankingDetail.branchName" placeholder="Branch Name" className="form-control form-control-solid" />
                    {errors.bankingDetail && errors.bankingDetail.branchName && touched.bankingDetail.branchName ? (<div className="error">{errors.bankingDetail.branchName}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">BSB</td>
                  <td className="text-start text-gray-600"><Field name="bankingDetail.bsb" placeholder="BSB" className="form-control form-control-solid" />
                    {errors.bankingDetail && errors.bankingDetail.bsb && touched.bankingDetail.bsb ? (<div className="error">{errors.bankingDetail.bsb}</div>) : null}</td>
                </tr>
                <tr className="even">
                  <td className="text-start">Account Number</td>
                  <td className="text-start text-gray-600"><Field name="bankingDetail.accountNumber" placeholder="Account Name" className="form-control form-control-solid" />
                    {errors.bankingDetail && errors.bankingDetail.accountNumber && touched.bankingDetail.accountNumber ? (<div className="error">{errors.bankingDetail.accountNumber}</div>) : null}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>)
  }



  /********************** HTML COMPONENTS OF CREDIT REP  - END *****************/

  const UpdateCreditRepDetail = async () => {
    setError("");
    console.log("UPDATE CREDITRP FUNCTION START --------------", singleCreditRep);

    const response = await updateCreditRep(singleCreditRep, token);
    console.log("RESPONSE IN THE EDIT PAGE");
    console.log(response);
    if (response["error"]) {
      Notifications("error", response["message"] || "Could not create the creditRep.")
    }
    else {
      Notifications("success", response["message"] || "");
      //REDIRECT TO DETIAL PAGE
      navigate(`/user/creditRepDetail/${id}`);

    }
  }

  function LoadEditCreditRepView() {
    return (
      <>
        <Formik
          initialValues={singleCreditRep}
          validationSchema={CreditRepValidationSchema}
          validateOnBlur={false}

          onSubmit={values => {
            // same shape as initial values
            //console.log(values);

            setSingleCreditRep({
              ...values,
              dateEstablished: values.dateEstablished.includes("/") ? formatDate(values.dateEstablished, 'DD/MM/YYYY', 'YYYY-MM-DD') : singleCreditRep.dateEstablished,

              associations: {
                ...values.associations,
                insurer: {
                  ...values.associations.insurer,
                  expiryDate: values.associations.insurer.expiryDate.includes("/") ? formatDate(values.associations.insurer.expiryDate, 'DD/MM/YYYY', 'YYYY-MM-DD') : singleCreditRep.associations.insurer.expiryDate
                },
                afcaMembership: {
                  ...values.associations.afcaMembership,
                  expiryDate: values.associations.afcaMembership.expiryDate.includes("/") ? formatDate(values.associations.afcaMembership.expiryDate, 'DD/MM/YYYY', 'YYYY-MM-DD') : singleCreditRep.associations.afcaMembership.expiryDate
                }
              },
            });
            setSubmitFlag(true);
          }}
        >
          {({ errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div id="kt_content_container" className="container-xxl">
                <div className="col-xl-12">
                  <pre>
                    { /*JSON.stringify(singleCreditRep , undefined, 4) */}
                  </pre>
                </div>
                <div className="row g-5 g-xl-8">
                  <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                  <div className="col-xl-6">
                    {BrokerageDetail(errors, touched, handleChange, setFieldValue)}
                  </div>
                  <div className="col-xl-6">
                    {AssociationAndMemberships(errors, touched, setFieldValue)}
                    {BankingDetails(errors, touched)}
                  </div>
                  <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{float: "right"}}>Please fix the above errors first. </div> : ""}</div>
                  <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    )
  }

  return (
    <>
      {loading ? "Loading..." : LoadEditCreditRepView()}


      {addressModal.visible === true ?
        <Modal modalVisibility={setAddressModal} heading="Creditrep Address">
          <AddressForm
            fieldName={addressModal.fieldName}
            closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
            addressHandler={addressModal.callback}
          />
        </Modal>
        : ""}

    </>
  );
}

export default EditCreditRep;