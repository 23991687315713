
import { useEffect, useState } from "react";
import { IconButton, InputField } from "../../HtmlComponents";

const SingleRebate = ({ rebate, index, callback }) => {

    const handler = (name, value) => {
        switch(name){
            case "description":
                callback("updateRebateValue", { index: index, name: name, value: value });
            break;
            case "amount":
                callback("updateRebateValue", { index: index, name: name, value: value });
                break;
            case "deleteRebate":
                callback("deleteRebate", { index: index });
                break;        

        }         
    }

    return (
        <>
            <div className="row mt-5">
                <div className="col-sm-4">
                    <label>Description
                        <IconButton
                            iconType="-"
                            iconColor=""
                            buttonClassName= " "
                            buttonStyle =  {{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} 
                            tootltip={true} 
                            tooltipDescription="Delete"
                            buttonOnClick = { () => { handler("deleteRebate", "") }}
                        />
                    </label>
                    <InputField
                        fieldName="description"
                        placeholder=""
                        fieldValue={rebate.description}
                        setFieldValue={handler}
                    />
                </div>
                <div className="col-sm-4">
                    <label>Amount</label>
                    <InputField
                        fieldName="amount"
                        placeholder=""
                        fieldValue={rebate.amount}
                        setFieldValue={handler}
                    />
                </div>
            </div>
        </>

    )

}

export default SingleRebate;