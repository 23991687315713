import { useEffect, useState } from 'react';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { createInsuranceCompany, updateInsuranceCompany, getInsuranceCompany, getInsuranceCompaniesList, deleteInsuranceCompany } from '../../api';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../global/Notifications';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";

import InsuranceCompaniesList from './InsuranceCompaniesList';
import CreateInsuranceCompany from './CreateInsuranceCompany';
import UpdateInsuranceCompany from './UpdateInsuranceCompany';
import InsuranceCompanyFiles from './InsuranceCompanyFiles';
import constants from '../global/Constants';
import LoadingScreen from '../global/LoadingScreen';
import { useMethodAllowed } from '../../hooks/usePermission';


const InsuranceCompanies = () => {

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({});            //SINGLR COMPANY - WILL BE FILLE DUP ON EDIT/UPDATE ACTION
    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });

    const token = useToken();
    const createInsuranceCompanyAllowed = useMethodAllowed("insuranceCompany", "create");
    const updateInsuranceCompanyAllowed = useMethodAllowed("insuranceCompany", "edit");
    const deleteInsuranceCompanyAllowed = useMethodAllowed("insuranceCompany", "delete");


    const fetchAllInsuranceCompanies = async () => {
        var response = await getInsuranceCompaniesList(token);
        console.log("INSURANCE COMP", response);

        if (response["error"] != undefined && response["error"] == 0 && response["httpCode"] !== undefined && response["httpCode"] == 200) {
            setCompanies(response["data"] || []);
        }
        else {
            Notifications("error", response["message"] !== undefined ? response["message"] : "Unable to list Insurers. Please contact IT Team")
        }
    }

    const fetchInsuranceTypes = () => {
        const options = [];
        Object.keys(constants.INSURANCE_TYPES).map(type => {
            options.push({ value: type, label: constants.INSURANCE_TYPES[type] });
        })
        setInsuranceTypes(options);
    }

    useEffect(() => {
        fetchInsuranceTypes();
        fetchAllInsuranceCompanies();
        setLoading(false);
    }, [])

    useEffect(() => {
    }, [companies])


    const updateHandler = async (name, value) => {
        switch (name) {
            case "openDraw":
                setDrawerSettings({ visible: true, heading: value["heading"], childName: value["componentName"], meta: value.meta || {} });
                break;
            case "closeDrawer":
                setDrawerSettings({ visible: false, heading: "", childName: "", meta: {} });
                break;
            case "createInsuranceCompany":
                setLoading(true);
                var response = await createInsuranceCompany(token, value);
                if (response["error"] !== undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Error occured")
                }
                else {
                    Notifications("success", response["message"])
                    fetchAllInsuranceCompanies();
                    updateHandler("closeDrawer", {});
                }
                setLoading(false);
                break;
            case "updateInsuranceCompany":
                companies.map((company) => {
                    if (company.id == value.id) {
                        setCompany(company);
                        updateHandler("openDraw", { heading: "Update", componentName: "updateInsuranceCompany" })
                    }
                });
                break;
            case "executeUpdateInsuranceCompany":
                setLoading(true);
                var response = await updateInsuranceCompany(token, value.id, value);
                if (response["error"] !== undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Error occured")
                }
                else {
                    Notifications("success", response["message"])
                    fetchAllInsuranceCompanies();
                    updateHandler("closeDrawer", { heading: "", componentName: "", meta: {} });
                }
                setLoading(false);
                break;
            case "files":
                updateHandler("openDraw", { heading: "Files", componentName: "insuranceCompanyFiles", meta: { companyId: value.id, width: 800 } });

                break;
            case "deleteInsuranceCompany":
                ConfirmDialog({ callback: () => { updateHandler("executeDeleteInsuranceCompany", value) }, "purpose": "general", "heading": `Are you sure you want to delete?`, "description": "This action can not be reverted!!!" })
                break;
            case "executeDeleteInsuranceCompany":
                setLoading(true);
                var response = await deleteInsuranceCompany(token, value.id);
                if (response["error"] !== undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Error occured")
                }
                else {
                    Notifications("success", response["message"])
                    fetchAllInsuranceCompanies();
                }
                setLoading(false);
                break;
        }
    }



    return (
        <>

            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                    <div className='cols-m-12'>
                        {
                            createInsuranceCompanyAllowed ?
                                <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right" }} buttonOnClick={() => { updateHandler("openDraw", { heading: "Create Insurance Company", componentName: "createInsuranceCompany" }) }} buttonText="Create" />
                                : ""
                        }
                    </div>
                </div>
                <div className="row">
                    <InsuranceCompaniesList heading="Comprehensive" type="comprehensive" companies={companies} callback={updateHandler}  updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                    <InsuranceCompaniesList heading="CCI" type="cci" companies={companies} callback={updateHandler} updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                    <InsuranceCompaniesList heading="GAP" type="gap" companies={companies} callback={updateHandler} updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                    <InsuranceCompaniesList heading="Warranty" type="warrenty" companies={companies} callback={updateHandler} updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                    <InsuranceCompaniesList heading="LTI" type="lti" companies={companies} callback={updateHandler} updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                    <InsuranceCompaniesList heading="RTI" type="rti" companies={companies} callback={updateHandler} updateInsuranceCompanyAllowed={updateInsuranceCompanyAllowed}  deleteInsuranceCompanyAllowed={deleteInsuranceCompanyAllowed}/>
                </div>
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={updateHandler}>
                    {drawerSettings["childName"] === "createInsuranceCompany" ? <CreateInsuranceCompany insuranceTypes={insuranceTypes} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "updateInsuranceCompany" ? <UpdateInsuranceCompany insuranceTypes={insuranceTypes} callback={updateHandler} singleCompany={company} /> : ""}
                    {drawerSettings["childName"] === "insuranceCompanyFiles" ? <InsuranceCompanyFiles callback={updateHandler} companyId={drawerSettings["meta"]["companyId"]} /> : ""}

                </RightDrawer> : ""
            }


            {loading ? <LoadingScreen /> : ""}

        </>
    )
}

export default InsuranceCompanies;