import { useEffect, useState } from "react";
import { useToken } from "../../../../../hooks/useAuth";
import { loadReporting } from '../../../../../api';
import ReportingConstants from '../../ReportingConstants';
import { months, colors, getPeriodNameArray, getFormattedDataForPeriod, getFormattedDataForComparePeriod, DrawGraphForComparePeriod, DrawGraph } from "./GraphHelpers";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar } from 'recharts';

const MultibarGraph = ({ data }) => {
    return (
        <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" stackId="a" fill="#8884d8" />
            <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
        </BarChart>
    );
}
export default MultibarGraph;