import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, FieldArray } from 'formik';
import { FormSubmissionButton } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import {PlusIcon} from '../../../../components/HtmlComponents/Icons'

import ListIncome from "./components/ListIncome";
import { v4 as uuid } from 'uuid';
import { useRole } from "../../../../hooks/useAuth";

const Income = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault }) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [income, setIncome] = useState({
        "primaryIncome": [{ "incomeType": "" }],
        "secondaryIncome": [],
        "previousIncome": []
    });
    const userRole = useRole()

    const FetchIncomeTypes = () => {
        var options = [];
        Object.keys(Constants.INCOME_TYPES).map(key => {
            options.push({ value: key, label: Constants.INCOME_TYPES[key] });
        })
        setIncomeTypeList(options);
    }
    const handleChange = () => { setIsOpen(isOpen === "none" ? "block" : "none"); }


    //ADD UNIQUE KEY TO EACH INCOME 
    async function addUniqueKeyToEachIncome(incomeClass) {
        var localIncome = [];
        var localIncomeSection = [];  //WILL CONTAINS ONLY 1 INCOME TYPE AT A TIME
        switch (incomeClass) {
            case "primaryIncome":
                localIncomeSection = initialValues.income.primaryIncome !== undefined && Array.isArray(initialValues.income.primaryIncome) && initialValues.income.primaryIncome.length > 0 ? initialValues.income.primaryIncome : [];
                break;
            case "secondaryIncome":
                localIncomeSection = initialValues.income.secondaryIncome !== undefined && Array.isArray(initialValues.income.secondaryIncome) && initialValues.income.secondaryIncome.length > 0 ? initialValues.income.secondaryIncome : [];
                break;
            case "previousIncome":
                localIncomeSection = initialValues.income.previousIncome !== undefined && Array.isArray(initialValues.income.previousIncome) && initialValues.income.previousIncome.length > 0 ? initialValues.income.previousIncome : [];
                break;
        }

        var localIncome = await localIncomeSection.map((income) => {
            income["uId"] = uuid();
            return income;
        })
        //setIncome( {...income, [incomeClass] :  localIncome }); 
        //setIncome(initialValues.income);

    }



    useEffect(() => {
        FetchIncomeTypes();
    }, []);

    useEffect(() => {
    }, [income])



    //RUN ONLY ONCE - handled with condition inside
    /*
    useEffect(() => {
        //LOAD INCOME FROM INITIALVALUES INTO INCOME - DO IT ONLY ON FIRST RENDER SO IF INCOME(S) ARE EMPTY THEN ASSIGN VALUES FROM 
        // INITIAL VALUES OTHERWISE LEAVE IT 
        if (initialValues.income !== undefined
            && initialValues.income.primaryIncome !== undefined && Array.isArray(initialValues.income.primaryIncome) && initialValues.income.primaryIncome.length > 0
            && initialValues.income.secondaryIncome !== undefined && Array.isArray(initialValues.income.secondaryIncome)
            && initialValues.income.previousIncome !== undefined && Array.isArray(initialValues.income.previousIncome)
        ) {
            //addUniqueKeyToEachIncome("primaryIncome");
            setIncome(initialValues.income);
        }
    }, [initialValues])
    */

    //UPDATE ASSETS STATE ONLI APPLICANT CHANGES
    useEffect(() => {
        if (initialValues.income !== undefined
            && initialValues.income.primaryIncome !== undefined && Array.isArray(initialValues.income.primaryIncome) && initialValues.income.primaryIncome.length > 0
            && initialValues.income.secondaryIncome !== undefined && Array.isArray(initialValues.income.secondaryIncome)
            && initialValues.income.previousIncome !== undefined && Array.isArray(initialValues.income.previousIncome)
        ) {
            setIncome(initialValues.income);
        }
        else {
            setIncome(previousState => ({
                "primaryIncome": [{ "incomeType": "" }],
                "secondaryIncome": [],
                "previousIncome": [],
            }));
        }
    }, [applicantId])


    useEffect(() => {
    }, [income])

    const Createincome = (incomeClass) => {
        switch (incomeClass) {
            case "secondaryIncome":
                var tmpIncome = income["secondaryIncome"];
                tmpIncome.push({ "incomeType": "" });
                setIncome(prevIncome => ({ ...prevIncome, secondaryIncome: tmpIncome }));
                break;
            case "previousIncome":
                var tmpIncome = income["previousIncome"];
                tmpIncome.push({ "incomeType": "" });
                setIncome(prevIncome => ({ ...prevIncome, previousIncome: tmpIncome }));
                break;
        }
    }

    {/***********************************DELETE SECONDARY OR PREVIOUS INCOME  *******************/ }
    const deleteIncome = (incomeClass, index) => {
        var currentValues = JSON.parse(JSON.stringify(formRef.current.values));

        switch (incomeClass) {
            case "secondaryIncome":
                var secondaryIncome = currentValues["income"]["secondaryIncome"];
                secondaryIncome.splice(index, 1);
                setIncome(prevState => ({ ...prevState, secondaryIncome: secondaryIncome }));
                break;
            case "previousIncome":
                var previousIncome = currentValues["income"]["previousIncome"];
                previousIncome.splice(index, 1);
                setIncome(prevState => ({ ...prevState, previousIncome: previousIncome }));
                break;
        }
    }

    const handler = (purpose, payload) => { }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="incoemBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Income</h3>
                        <DownArrowIcon />
                    </div>

                    { /** personal infort=mation block start  */}
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            initialValues={{
                                "income": income
                            }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("income");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    { /* PRIMARY INCOME */}
                                    <ListIncome key="primaryIncome" income={income["primaryIncome"]} incomeClass={"primaryIncome"} incomeTypeList={incomeTypeList} setFieldValue={setFieldValue} callback={handler} applicationId={applicationId} />

                                    { /* PREVIOUS INCOME */}
                                    <div className="mt-5">
                                        {<FieldArray name="income.previousIncome">
                                            {({ remove, push }) => (
                                                <>
                                                    <ListIncome income={values.income.previousIncome} incomeClass={"previousIncome"} incomeTypeList={incomeTypeList} setFieldValue={setFieldValue} deleteIncome={remove} callback={(method, payload) => { }} applicationId={applicationId} />
                                                    <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                        push({ "incomeType": "" })
                                                    }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        Add Previous Income
                                                    </button>
                                                </>
                                            )}
                                        </FieldArray>}
                                    </div>

                                    { /* SECONDARY INCOME */}
                                    <div className="mt-5">
                                        {<FieldArray name="income.secondaryIncome">
                                            {({ remove, push }) => (
                                                <>
                                                    <ListIncome income={values.income.secondaryIncome} incomeClass={"secondaryIncome"} incomeTypeList={incomeTypeList} setFieldValue={setFieldValue} deleteIncome={remove} callback={(method, payload) => {
                                                        /*switch (method) {
                                                            case "deleteIncome": remove(payload["index"]); break;
                                                        }*/
                                                    }} applicationId={applicationId} />
                                                    <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                        push({ "incomeType": "" })
                                                    }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        Add Secondary Income
                                                    </button>
                                                </>
                                            )}
                                        </FieldArray>}

                                    </div>

                                    {
                                        // <ListIncome income={income["previousIncome"]} incomeClass={"previousIncome"} incomeTypeList={incomeTypeList} setFieldValue={setFieldValue} callback={handler}  applicationId={applicationId} />
                                    }
                                    {/*<button className=" btn btn-sm btn-flex btn-light-primary" style={{ marginBottom: "20px" }} type="button" onClick={() => { Createincome("previousIncome") }}>
                                        <span className="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                        Add Previous Primary Income
                                    </button>*/
                                    }

                                    { /* SECONDARY INCOME */}
                                    {
                                        //<ListIncome key="secondaryIncome" income={income["secondaryIncome"]} incomeClass={"secondaryIncome"} incomeTypeList={incomeTypeList} setFieldValue={setFieldValue} callback={handler}  applicationId={applicationId} />
                                    }
                                    {/*<div>
                                        <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => { Createincome("secondaryIncome") }} style={{ marginTop: "20px" }}>
                                            <span className="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                </svg>
                                            </span>
                                            Add Secondary Income
                                        </button>
                                    </div>
                                    */}

                                    <div className="col-xl-12">
                                        { userRole !== "auditor" ? <FormSubmissionButton isActive={applicationId != 0 ? true : false} /> : "" }
                                    </div>
                                </Form>
                            )}
                        </Formik>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Income;