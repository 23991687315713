import { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from 'formik';
import { FormSubmissionButton, SelectField, MultiSelectField, RadioGroup, InputField, SelectDatePicker, Modal, FloatField } from '../HtmlComponents';
import Constants from "../global/Constants";
import Notifications from "../global/Notifications";
import { capitalizeFirst, formatDate } from '../../helpers';
import { useEntityId, useToken } from "../../hooks/useAuth";
import { getReferrerGroupsList, getReferrersListAgainstReferrerGroup, getLendersList, getUsers, getCrmCsvFile } from "../../api";
import SearchByDateWithoutActions from "../global/SearchForms/SearchByDateWithoutActions";
import crmSchema from "./schema/crmSchema.json";
import exportFromJSON from 'export-from-json'


const Crm = () => {

    const [RelationshipStatusList, setRelationshipStatusList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [residencyStatusList, setResidencyStatusList] = useState([]);
    const [referrerGroups, setReferrerGroups] = useState([]);
    const [referrers, setReferrers] = useState([]);
    const [lenders, setLenders] = useState([]);
    const [loanPurposes, setLoanPurposes] = useState([]);
    const [analystList, setAnalystList] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [date, setDate] = useState({ startDate: 0, endDate: 0 });

    const token = useToken();
    const entityId = useEntityId();
    const initialValues = {}
    //interest rate

    const fetchRelationshipStatusList = () => {
        var relationshipStatusOptions = [];
        Object.keys(Constants.RELATIONSHIP_STATUS).map(key => {
            relationshipStatusOptions.push({ value: key, label: Constants.RELATIONSHIP_STATUS[key] });
        })
        setRelationshipStatusList(relationshipStatusOptions);
    }

    //RESIDENCY STATUS
    const fetchResidencyStatus = () => {
        var residencyStatusOptions = [];
        Object.keys(Constants.RESIDENCY_STATUS).map(key => {
            residencyStatusOptions.push({ value: key, label: Constants.RESIDENCY_STATUS[key] });
        })
        setResidencyStatusList(residencyStatusOptions);
    }

    const fetchGenderList = () => {
        var genderOptions = [];
        Constants.GENDER.map(item => {
            genderOptions.push({ value: item, label: capitalizeFirst(item) });
        })
        setGenderList(genderOptions);
    }

    //FETCH STATES
    const fetchStates = () => {
        var stateOptions = [];
        Object.keys(Constants.STATES).map(key => {
            stateOptions.push({ value: key, label: Constants.STATES[key] });
        })
        setStateList(stateOptions);
    }


    async function fetchReferrerGroupsList() {
        const result = await getReferrerGroupsList(token);

        var tmpReferrerGrousps = [];
        if (result["error"] == 0 && result["referrerGroups"] != undefined) { tmpReferrerGrousps = result["referrerGroups"]; }

        var referrerGroupList = [];
        referrerGroupList.push({ "value": 0, "label": "Select" });
        if (tmpReferrerGrousps !== undefined && Array.isArray(tmpReferrerGrousps)) {
            tmpReferrerGrousps.map(singleReferrerGroup => { referrerGroupList.push({ "value": singleReferrerGroup["id"], "label": singleReferrerGroup["tradingName"] }) })
            setReferrerGroups(referrerGroupList)
        }
    }

    //FETCH REFERRER LIST
    async function fetchReferresList(referrerGroupId) {
        if (referrerGroupId === 0) {
            var referrersList = [];
            referrersList.push({ "value": 0, "label": "Select" });
            setReferrers(referrersList);
        }
        else {
            //referrersSelectInputRef.current.select.clearValue();
            const referrers = await getReferrersListAgainstReferrerGroup(token, referrerGroupId);
            var referrersList = [];
            referrersList.push({ "value": 0, "label": "Select" });
            if (referrers !== undefined && Array.isArray(referrers)) {
                referrers.map(singleReferrer => { referrersList.push({ "value": singleReferrer["id"], "label": singleReferrer["tradingName"] }) })
                setReferrers(referrersList)
            }
        }
    }

    //LOAN PURPOSE
    const fetchLoanPurposes = () => {
        var loanPurposeOptions = [];
        Object.keys(Constants.LOAN_PURPOSES).map(function (key, index) {
            loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] })
        })
        setLoanPurposes(loanPurposeOptions);
    }

    //FETCH LENDERS
    const fetchLendersList = async () => {
        const response = await getLendersList(token);
        if (response["error"] === 0 && response["lenders"]) {
            var lenders = response["lenders"];
            var lendersList = [];
            lendersList.push({ "value": 0, "label": 'Select Lender' }); //FIRST OPTION SO USER CAN DESELECT THE LEDER IF REQUIRED(INSURANC ONLY CASE)

            if (lenders !== undefined && Array.isArray(lenders)) {
                lenders.map(singleLender => { lendersList.push({ "value": singleLender["id"], "label": singleLender["name"] }) })
                setLenders(lendersList)
            }
        }
        else {
            //ERROR OCCURED
        }

    }

    //FETCH ANALYSTS
    const fetchAnalyst = async () => {
        var response = await getUsers(token, "creditRep", entityId);
        console.log(response);
        if (response["error"] == 0) {
            var users = response["data"];
            if (Array.isArray(users)) {
                var options = users.map(user => {
                    return { value: user.id, label: user.firstName }
                })
                setAnalystList(options);
            }
        }
    }

    //SET REPORTING TYPES
    const updateReportingTypes = () =>{
        var tmpReportTypes = [
            {value: "allReferrerActiveUsers" , label: "All Referrer Active Users"},
            {value: "allReferrerArchivedUsers" , label: "All Referrer Archived Users"},
            {value: "referrerGroupActiveUsers" , label: "Referrer Group Active Users"},
            {value: "referrerGroupArchivedUsers" , label: "Referrer Group Archived Users"},
            {value: "homeBuyers" , label: "Home Buyers"},
            {value: "allCustomers" , label: "All Customers"},
            {value: "commercialCustomers" , label: "Commercial Customers"},
            {value: "consumerCustomers" , label: "Consumer Customers"},
            {value: "additionalEDMContacts" , label: "Additional EDM Contacts"},
            {value: "lenderUsers" , label: "Lender Users"},
        
    ];
        setReportTypes(tmpReportTypes);

    }

    useEffect(() => {
        fetchRelationshipStatusList();
        fetchResidencyStatus();
        fetchGenderList();
        fetchLoanPurposes();

        fetchReferrerGroupsList();
        fetchLendersList();
        fetchAnalyst();

        fetchStates();
        updateReportingTypes();
    }, [])


    //UPDATE THE DATE FILTER
    const handler = (name, value) => {
        switch (name) {
            case "dateFilter":
                setDate({ ...value });
                break;
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className="card mb-5 mb-xxl-8">
                    <div className="card-body card-body pt-6 pb-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap" >
                            <h3 className="pb-0 mb-0">Export CSV File</h3>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        <div className="mt-5">
                            <SearchByDateWithoutActions callback={handler} />
                        </div>

                        <div>
                            <Formik
                                initialValues={crmSchema}
                                enableReinitialize={true}
                                validateOnBlur={false}
                                onSubmit={async (values) => {
                                    var localValues = JSON.parse(JSON.stringify(values));
                                    localValues["date"] = date;

                                    var response = await getCrmCsvFile(token, localValues);
                                    console.log("RESPONSE FORM CRM CSV FILE GENERATION ", response);
                                    if(response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"])){                                        
                                        exportFromJSON({
                                            data: response["data"],
                                            fileName: 'CRM',
                                            exportType: exportFromJSON.types.csv                                           
                                        })
                                        Notifications("success", "Ready to download");
                                    }
                                    else{
                                        Notifications("error", "Unable to create CSV");
                                    }
                                }}
                            >
                                {({ errors, touched, setFieldValue, values }) => (
                                    <Form>
                                        <div className="row">
                                            
                                        <div className="col-sm-3  mt-5">
                                                <label className="fs-8 fw-bold mb-2">Report Type</label>
                                                <Field name="reportType">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="reportType"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={reportTypes}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            
                                            <div className="col-sm-3  mt-5">
                                                <label className="fs-8 fw-bold mb-2">Relationship Status</label>
                                                <Field name="relationshipStatus">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="relationshipStatus"
                                                            defaultSelectedOptions={field.value}
                                                            options={RelationshipStatusList}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-sm-3  mt-5">
                                                <label className="fs-8 fw-bold mb-2">Residency Status</label>
                                                <Field name="residencyStatus">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="residencyStatus"
                                                            defaultSelectedOptions={field.value}
                                                            options={residencyStatusList}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-sm-3  mt-5">
                                                <label className="fs-8 fw-bold mb-2">Gender</label>
                                                <Field name="gender">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="gender"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={genderList}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3  mt-5">
                                                <label className="fs-8 fw-bold mb-2">Referrer Group</label>
                                                <Field name="referrerGroup">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="referrerGroup"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={referrerGroups}
                                                            setFieldValue={setFieldValue}
                                                            callback={ (fieldName, value) => {fetchReferresList(value);} }
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Referrer</label>
                                                <Field name="referrer">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="referrer"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={referrers}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Lender</label>
                                                <Field name="lender">
                                                    {({ field }) => (
                                                        <SelectField
                                                            fieldName="lender"
                                                            defaultSelectedOption={{ value: field.value, label: "" }}
                                                            options={lenders}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Loan Purpose</label>
                                                <Field name="loanPurpose">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="loanPurpose"
                                                            defaultSelectedOptions={field.value}
                                                            options={loanPurposes}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Analyst</label>
                                                <Field name="analysts">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="analysts"
                                                            defaultSelectedOptions={field.value}
                                                            options={analystList}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">State</label>
                                                <Field name="state">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="state"
                                                            defaultSelectedOptions={field.value}
                                                            options={stateList}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Interest Rate (equal and above than)</label>
                                                <br></br>
                                                <Field name="interestRate" className="form-control " /> 
                                            </div>
                                            <div className="col-sm-3 mt-5">
                                                <label className="fs-8 fw-bold mb-2">Age shuold be more than - not active yet</label>
                                                <br></br>
                                                <Field name="age" className="form-control " disabled /> 
                                            </div>


                                            <div className="col-xl-12">
                                                {<FormSubmissionButton isActive={true} label="Download CSV" />}
                                            </div>
                                        </div>
                                    </Form>

                                )}

                            </Formik>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Crm;
