import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormSubmissionButton, Modal, MaskedField, RadioGroup, SelectField } from '../HtmlComponents';
import { Formik, Form, Field, FieldArray } from 'formik';
import { referrerAccreditationRequest } from "../../api";
import Notifications from '../../components/global/Notifications';

//SCHEMA
import ReferrerAccreditationFormSchema from './schema/ReferrerAccreditationFormSchema.json';
import ReferrerAccreditationFormValidationSchema from './schema/ReferrerAccreditationFormValidationSchema';

//MODALS
import AddressForm from '../global/AddressForm';
//HELPERS
import { dateFilter } from '../../helpers';
//ERRORS
import LoadingScreen from '../global/LoadingScreen';

const referrerConstants = require('./components/Constants');


const ReferrerAccreditationForm = () => {
    const [processing, setProcessing] = useState(false);
    const [sentSuccessfully, setSentSuccessfully] = useState(false);
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });

    const [referrerAccreditationFormValues, setReferrerAccreditationFormValues] = useState(ReferrerAccreditationFormSchema);

    const { referrerName } = useParams();

    console.log("Referrer Name", referrerName);
    console.log("Referrer Constants - ACCREDITATION_FORM_REFERRERS_LIST::: ", referrerConstants.ACCREDITATION_FORM_REFERRERS_LIST);

    const role = [
        { value: "staff", label: "Staff" },
        { value: "directorOwner", label: "Director/Owner" },
        { value: "subContract", label: "Sub Contract" }
    ];

    useEffect(() => {
        const loadReferrer = async () => {
            if (referrerName !== undefined
                && Array.isArray(Object.keys(referrerConstants.ACCREDITATION_FORM_REFERRERS_LIST))
                && Object.keys(referrerConstants.ACCREDITATION_FORM_REFERRERS_LIST).includes(referrerName)) {
                console.log("Referrer Name", referrerName);

                let referrer = referrerConstants.ACCREDITATION_FORM_REFERRERS_LIST[referrerName];
                console.log("Referrer Name LINE 48 ---------", referrer);


                setReferrerAccreditationFormValues({
                    ...referrerAccreditationFormValues,
                    referrer: {
                        ...referrerAccreditationFormValues.referrer,
                        referredBy: referrer
                    }
                })
            }
        }

        loadReferrer();

    }, []);

    /*  ********************* HTML COMPONENTS OF REFERRER GROUP  - START *****************/


    const Summary = (errors, touched, handleChange, setFieldValue, values) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Referrer Details</h3>
                        </div>
                    </div>

                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">

                                { /*<tr className="even">
                                    <td className="text-start">Referred By</td>
                                    <td >
                                        <Field name="referrer.referredBy" placeholder="Referred By" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.referredBy ? (<div className="error">{errors.referrer.referredBy}</div>) : null}
                                    </td>
                                </tr> */ }
                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td >
                                        <Field name="referrer.tradingName" placeholder="Trading Name" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.tradingName ? (<div className="error">{errors.referrer.tradingName}</div>) : null}
                                    </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name </td>
                                    <td ><Field name="referrer.entityName" placeholder="Entity Name" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.entityName ? (<div className="error">{errors.referrer.entityName}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td >
                                        <Field name="referrer.structure">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="referrer.structure"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Company", value: "company" }, { label: "Partnership", value: "partnership" }, { label: "Sole Trader", value: "soleTrader" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors && errors.referrer && errors.referrer.structure ? (<div className="error">{errors.referrer.structure}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN </td>
                                    <td ><Field name="referrer.abn" placeholder="ABN" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.abn ? (<div className="error">{errors.referrer.abn}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN </td>
                                    <td ><Field name="referrer.acn" placeholder="ACN" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.acn ? (<div className="error">{errors.referrer.acn}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Date Established </td>
                                    <td >
                                        <Field type="text" name="referrer.dateEstablished" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                            {({ field }) => (
                                                <MaskedField fieldName="referrer.dateEstablished" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                        {errors && errors.referrer && errors.referrer.dateEstablished ? (<div className="error">{errors.referrer.dateEstablished}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">contact Number </td>
                                    <td ><Field name="referrer.contactNumber" placeholder="Contact Number" className="form-control form-control-solid" onChange={handleChange} />
                                        {errors && errors.referrer && errors.referrer.contactNumber ? (<div className="error">{errors.referrer.contactNumber}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax </td>
                                    <td >
                                        <Field name="referrer.fax" placeholder="Fax" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.fax && touched ? (<div className="error">{errors.referrer.fax}</div>) : null}
                                    </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Address</td>
                                    <td >
                                        <Field name="referrer.address">
                                            {({ field }) => (
                                                <input
                                                    className="form-control form-control-solid"
                                                    type="text"
                                                    value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                    onClick={() => setAddressModal({ visible: true, filedName: "referrer.address", callback: setFieldValue })}
                                                    onChange={(e) => { }}
                                                />
                                            )}
                                        </Field>
                                        {errors && errors.referrer && errors.referrer.address ? (<div className="error">{errors.referrer.address}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website Url </td>
                                    <td ><Field name="referrer.websiteUrl" placeholder="Website Url" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.websiteUrl ? (<div className="error">{errors.referrer.websiteUrl}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td ><Field name="referrer.email" placeholder="Email" className="form-control form-control-solid" />
                                        {errors && errors.referrer && errors.referrer.email ? (<div className="error">{errors.referrer.email}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Gst Registered</td>
                                    <td >
                                        <Field name="referrer.isGstRegistered">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="referrer.isGstRegistered"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors && errors.referrer && errors.referrer.isGstRegistered ? (<div className="error">{errors.referrer.isGstRegistered}</div>) : null}</td>
                                </tr>




                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const BankingDetails = (errors, touched, handleChange, setFieldValue, values) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td ><Field name="bankingDetail.name" placeholder="Bank Name" className="form-control form-control-solid" />
                                        {errors && errors.bankingDetail && errors.bankingDetail.name && touched && touched.bankingDetail && touched.bankingDetail.name ? (<div className="error">{errors.bankingDetail.name}</div>) : null}</td>

                                    <td className="text-start">Branch</td>
                                    <td ><Field name="bankingDetail.branchName" placeholder="Branch Name" className="form-control form-control-solid" />
                                        {errors && errors.bankingDetail && errors.bankingDetail.branchName && touched && touched.bankingDetail && touched.bankingDetail.branchName ? (<div className="error">{errors.bankingDetail.branchName}</div>) : null}</td>


                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td ><Field name="bankingDetail.bsb" placeholder="BSB" className="form-control form-control-solid" />
                                        {errors && errors.bankingDetail && errors.bankingDetail.bsb && touched && touched.bankingDetail && touched.bankingDetail.bsb ? (<div className="error">{errors.bankingDetail.bsb}</div>) : null}</td>

                                    <td className="text-start">Account Number</td>
                                    <td ><Field name="bankingDetail.accountNumber" placeholder="Account Number" className="form-control form-control-solid" />
                                        {errors && errors.bankingDetail && errors.bankingDetail.accountNumber && touched && touched.bankingDetail && touched.bankingDetail.accountNumber ? (<div className="error">{errors.bankingDetail.accountNumber}</div>) : null}</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    //DIRECTORS
    const Directors = (errors, touched, handleChange, setFieldValue, values) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Director/Partners Details</h3>
                            {<FieldArray name="directors">
                                {({ remove, push }) => (
                                    <>
                                        <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                            push({ "name": "", "email": '', "contactNumber": '' });
                                        }}>
                                            Add Another Director
                                        </button>
                                    </>
                                )}
                            </FieldArray>}
                        </div>
                    </div>

                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact #</th>
                                    <th>   </th>
                                </tr>
                            </thead>
                            <tbody className="fs-6">
                                {
                                    values.directors && Array.isArray(values.directors) && values.directors.length > 0 ? values.directors.map((staff, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="even">
                                                    <td >
                                                        <Field name={`directors[${index}].name`} placeholder="Full Name" className="form-control" />
                                                        {(errors?.directors?.[index]?.name && touched?.directors?.[index]?.name)
                                                            ? (<div className="error">{errors.directors[index].name}</div>)
                                                            : null}
                                                    </td>

                                                    <td >
                                                        <Field name={`directors[${index}].email`} placeholder="Email" className="form-control form-control-solid" />
                                                        {(errors?.directors?.[index]?.email && touched?.directors?.[index]?.email)
                                                            ? (<div className="error">{errors.directors[index].email}</div>)
                                                            : null}
                                                    </td>
                                                    <td >
                                                        <Field name={`directors[${index}].contactNumber`} placeholder="Contact #" className="form-control form-control-solid" />
                                                        {(errors?.directors?.[index]?.contactNumber && touched?.directors?.[index]?.contactNumber)
                                                            ? (<div className="error">{errors.directors[index].contactNumber}</div>)
                                                            : null}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-sm btn-flex btn-danger"
                                                            type="button"
                                                            onClick={() => {
                                                                const newDirectors = [...values.directors];
                                                                newDirectors.splice(index, 1);
                                                                setFieldValue("directors", newDirectors);
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }) : <tr>
                                        <td colSpan="6" className="text-center">No Director added</td>
                                    </tr>
                                }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div >
        </>)
    }


    //STAFF ACCREDITATION

    const StaffAccreditation = (errors, touched, handleChange, setFieldValue, values) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Staff Accreditation</h3>


                            {<FieldArray name="acreditatedStaff">
                                {({ remove, push }) => (
                                    <>
                                        <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                            push({ "name": "", "email": '', "contactNumber": '', "role": '' });
                                        }}>
                                            Add Another Staff member
                                        </button>
                                    </>
                                )}
                            </FieldArray>}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile #</th>
                                    <th>DOB (DD/MM/YYYY)</th>
                                    <th>Role</th>
                                    <th>    </th>
                                </tr>
                            </thead>

                            <tbody className="fs-6">
                                {
                                    values.acreditatedStaff && Array.isArray(values.acreditatedStaff) && values.acreditatedStaff.length > 0 ? values.acreditatedStaff.map((staff, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="even">
                                                    <td >
                                                        <Field name={`acreditatedStaff[${index}].name`} placeholder="Full Name" className="form-control form-control-solid" />
                                                        {(errors?.acreditatedStaff?.[index]?.name && touched?.acreditatedStaff?.[index]?.name)
                                                            ? (<div className="error">{errors.acreditatedStaff[index].name}</div>)
                                                            : null} </td>

                                                    <td >
                                                        <Field name={`acreditatedStaff[${index}].email`} placeholder="Email" className="form-control form-control-solid" />
                                                        {(errors?.acreditatedStaff?.[index]?.email && touched?.acreditatedStaff?.[index]?.email)
                                                            ? (<div className="error">{errors.acreditatedStaff[index].email}</div>)
                                                            : null}  </td>
                                                    <td >
                                                        <Field name={`acreditatedStaff[${index}].contactNumber`} placeholder="Mobile Number" className="form-control form-control-solid" />
                                                        {(errors?.acreditatedStaff?.[index]?.contactNumber && touched?.acreditatedStaff?.[index]?.contactNumber)
                                                            ? (<div className="error">{errors.acreditatedStaff[index].contactNumber}</div>)
                                                            : null}  </td>
                                                    <td >
                                                        <Field name={`acreditatedStaff[${index}].dob`} placeholder="DD/MM/YYYY" className="form-control form-control-solid" />
                                                        {(errors?.acreditatedStaff?.[index]?.dob && touched?.acreditatedStaff?.[index]?.dob)
                                                            ? (<div className="error">{errors.acreditatedStaff[index].dob}</div>)
                                                            : null}  </td>
                                                    <td >

                                                        <Field name={`acreditatedStaff[${index}].role`}>
                                                            {({ field }) => (
                                                                <SelectField
                                                                    fieldName={`acreditatedStaff[${index}].role`}
                                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                                    options={role}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            )}
                                                        </Field>

                                                        {(errors?.acreditatedStaff?.[index]?.role && touched?.acreditatedStaff?.[index]?.role)
                                                            ? (<div className="error">{errors.acreditatedStaff[index].role}</div>)
                                                            : null}  </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-sm btn-flex btn-danger"
                                                            type="button"
                                                            onClick={() => {
                                                                const newStaff = [...values.acreditatedStaff];
                                                                newStaff.splice(index, 1);
                                                                setFieldValue("acreditatedStaff", newStaff);
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }) : <tr>
                                        <td colSpan="6" className="text-center">No staff members added</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    //SUBMISSION MESSAGE 
    const SentSuccessfullyForm = () => {
        return (
            <>
                <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-fluid">
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                    <div className="d-flex flex-row-fluid flex-column text-center">
                                        <a href="void:javascript(0)" className="py-9 mb-5">
                                            <img alt="Logo" src="/media/logos/brokahub-logo-horizontal-black.svg" className="h-75px" />
                                        </a>
                                    </div>
                                    <div className="d-flex flex-row-fluid flex-column text-center"></div>

                                    <div className='card'>
                                        <div className='card-body' style={{ textAlign: "center" }}>
                                            <h2>Submitted Successfully</h2>
                                            <div> <p> Thank you for your accreditation request. An NFAL Representative will be in contact with you to finalise the on boarding.</p></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    /********************** HTML COMPONENTS OF REFERRER GROUP  - END *****************/


    function LoadView() {
        return (
            <>
                <Formik
                    initialValues={referrerAccreditationFormValues}
                    validationSchema={ReferrerAccreditationFormValidationSchema}
                    enableReinitialize={true}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        let valuesClone = JSON.parse(JSON.stringify(values));

                        setProcessing(true);
                        var response = await referrerAccreditationRequest(valuesClone)
                        if (response["error"]) {
                            Notifications("error", response["message"] || "Unable to process this. Please try again or reach out to NFAL team.")
                        }
                        else {
                            Notifications("success", response["message"] || "Submitted Successfully");
                            console.log("RESPONSE------------------", response);
                            setSentSuccessfully(true);

                        }
                        setProcessing(false);
                    }}
                >
                    {({ errors, touched, handleChange, setFieldValue, values }) => (
                        <Form>
                            <div id="kt_content_container" className="container-xxl">
                                <div className="col-xl-12">
                                    {
                                        values?.referrer?.referredBy === undefined || values.referrer.referredBy === "" || values.referrer.referredBy === "NFAL" ? "" :

                                            <div className="card-rounded bg-primary" style={{ marginBottom: "30px", marginTop: "30px", padding: "15px" }}>
                                                <div style={{ color: "white", fontSize: "18px", textAlign: "center" }}>
                                                    You are referrered by <b>{values.referrer.referredBy}</b>
                                                </div>
                                            </div>
                                    }
                                    { /*JSON.stringify(singleCreditRep , undefined, 4) */}

                                </div>

                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-6">
                                        {Summary(errors, touched, handleChange, setFieldValue, values)}
                                    </div>
                                    <div className="col-xl-6">
                                        {BankingDetails(errors, touched)}
                                        {Directors(errors, touched, handleChange, setFieldValue, values)}
                                    </div>

                                    <div className="col-xl-12">
                                        {StaffAccreditation(errors, touched, handleChange, setFieldValue, values)}
                                    </div>

                                    <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                    <div className="col-xl-12 mb-5"> {
                                        <FormSubmissionButton
                                            isActive={JSON.stringify(errors).length < 3 ? true : false}
                                            label="Submit"
                                        />}</div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }

    return (
        <>
            {processing ? <LoadingScreen /> : (sentSuccessfully ? <SentSuccessfullyForm /> : LoadView())}

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Referrer Group Address">
                    <AddressForm
                        fieldName={addressModal.filedName}
                        closeButtonHandler={() => setAddressModal({ visible: false, callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    );
}

export default ReferrerAccreditationForm;