
import { useRole } from '../../hooks/useAuth';
import { IconButton } from '../HtmlComponents';
const SingleOfficeContact = ({ officeContact, callback }) => {

    const userRole = useRole();
    return (
        <>
            <div className="col-sm-6 col-md-4">
                <div className="px-9 pt-7 card-rounded h-175px bg-primary">
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3"> {officeContact && officeContact["name"] !== undefined ? officeContact["name"] : ""} </h3>

                        {
                            ['superAdmin', 'finance' , 'admin'].includes(userRole)? 
                            <IconButton
                            iconType="edit"
                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                            buttonOnClick={() => { callback("openUpdateOfficeContactDrawer",  {officeContactId : officeContact.id }) }}
                            tooltipDescription="Update"
                        />: ""
                        }

                    </div>
                </div>
                <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                    {Array.isArray(officeContact["properties"]) ?
                        officeContact["properties"].map(singleDetail => {
                            return (
                                <>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td> <label className='bold'>{singleDetail["name"]} </label>  :    </td>
                                        <td>   {singleDetail["value"]} </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </>)
                        }) : ""
                    }
                </div>
            </div>
        </>

    )
}

export default SingleOfficeContact;

