import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useToken, useRole } from '../../hooks/useAuth';
//REDUS PERSISTENT

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const token = useToken();
    const userRole = useRole();

    var activeSession = localStorage.getItem("activeSession");
    activeSession = activeSession !== undefined && activeSession === "true" ? true : false;
    //CHECK IF TOKEN IS 30 MINUTES OLD THEN REMOVE THE TOKEN AND SEND THE USER BACK TO LOGIN SCREEN
    return (
        token !== null && activeSession == true && allowedRoles.includes(userRole) ?
            <Outlet /> :
            (token !== null && activeSession == true ? <Navigate to="/unauthorized" state={{ from: location }} replace /> :
                <Navigate to="/login" state={{ from: location }} replace />)
    )

}

export default RequireAuth;