import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, Field, useField, useFormikContext, FieldArray } from 'formik';
import { FormSubmissionButton, CustomButton, SelectField, InputField } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import ListAssets from "./components/ListAssets";

import { v4 as uuid } from 'uuid';
import { useRole } from "../../../../hooks/useAuth";

const Assets = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault }) => {
    const [assets, setAssets] = useState([]);
    const [assetsTypeList, setAssetsTypeList] = useState([]);
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const userRole = useRole()

    const handleChange = () => { setIsOpen(isOpen === "none" ? "block" : "none"); }

    const FetchAssetsType = () => {
        var options = [];
        Object.keys(Constants.ASSETS_TYPES).map(key => {
            options.push({ value: key, label: Constants.ASSETS_TYPES[key] });
        })
        setAssetsTypeList(options);
    }

    useEffect(() => {
        FetchAssetsType();
    }, []);

    async function updateAssetsStateAndAddUniqueKeyToEachAsset() {
        var localAssets = await initialValues.assets.map((asset) => {
            asset["uId"] = uuid();
            return asset;
        })
        setAssets(localAssets);
    }

    //UPDATE ASSETS STATE ONLI APPLICANT CHANGES
    useEffect(() => {
        updateAssetsStateAndAddUniqueKeyToEachAsset();
    }, [applicantId])

    const CreateAsset = () => {
        const newAsset = { "assetType": "", "assetValue": '', "assetOwnership": '', uId: uuid() }

        if (Array.isArray(assets) && assets.length == 0) {
            var assetsList = [];
            assetsList.push(newAsset);
            setAssets(assetsList);
        }
        else if (Array.isArray(assets) && assets.length > 0) {
            var tempAssets = [];
            if (formRef.current.values.assets.length > 0) { tempAssets = JSON.parse(JSON.stringify(formRef.current.values.assets)); }
            tempAssets.push(newAsset);
            setAssets(tempAssets);
        }
    }


    //DELETE ASSET
    const DeleteAsset = (index) => {
        //FIRST CREATE DEEP COPY OF ASSETS - FORMIK VALUES
        var tempAssets = [];
        if (formRef.current.values.assets.length > 0) { tempAssets = JSON.parse(JSON.stringify(formRef.current.values.assets)); }
        tempAssets.splice(index, 1);
        setAssets(tempAssets);
    }


    useEffect(() => {
    }, [assets])

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="assetsBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Assets</h3>
                        <DownArrowIcon />
                    </div>
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>

                        <Formik
                            initialValues={{
                                "assets": assets
                                //initialValues.assets
                            }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("assets");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    {<FieldArray name="assets">
                                        {({ remove, push }) => (
                                            <>
                                            <ListAssets assets={values.assets} assetsTypeList={assetsTypeList} setFieldValue={setFieldValue} deleteAsset={remove} />
                                                <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                    push({ "assetType": "", "assetValue": '', "assetOwnership": ''})
                                                }}>
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Add Asset
                                                </button>
                                            </>
                                        )}
                                    </FieldArray>}
                                    
                                    <div className="col-xl-12">
                                        { userRole !== "auditor" ? <FormSubmissionButton isActive={applicationId != 0 ? true : false} /> : "" }
                                    </div>
                                </Form>
                            )}
                        </Formik>                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Assets;


/**
 * 
 * 
   return (
        <>
            <div className="card mb-5 mb-xxl-8" key="assetsBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Assets</h3>
                        <DownArrowIcon />
                    </div>
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>

                        <Formik
                            initialValues={{
                                "assets": assets
                                //initialValues.assets
                            }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("assets");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <ListAssets assets={assets} assetsTypeList={assetsTypeList} setFieldValue={setFieldValue} deleteAsset={DeleteAsset} />

                                    <div className="col-xl-12">
                                        {<FormSubmissionButton isActive={ applicationId != 0 ? true : false } />}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        
                         <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={CreateAsset}>
                            <span className="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                </svg>
                            </span>
                            Add Asset
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

    
 */