
const Pagination = ({ totalRecords, currentPageNumber, totalRecordsOnpage, updateCurrentPageNumber }) => {


    const handlePageClicked = (e) => {
        e.preventDefault()
        updateCurrentPageNumber("currentPageNumber", e.currentTarget.dataset.id);
    }

    var totalPages = Math.ceil(totalRecords / totalRecordsOnpage);
    var pagesArray = Array.from(Array(totalPages).keys());
    var pageNumberingDevider = 1;   //DECIDE WHAT WOULD BE THE NEXT NUMBER AFTER 2 (1, 10, 20, 30 OR 1,2,3,4 ETC)
    pageNumberingDevider = totalPages > 15 ? 10 : 1;
    pageNumberingDevider = pageNumberingDevider > 100 ? 50 : pageNumberingDevider;      //IF PAGES ARE MORE THAN 100 THEN REMINDER IS 50 

    /*
        switch (true) {
            case totalPages <= 5:
                //print all pages 
                 
                break;
            case totalPages > 5:
                //PRINT FIRST AND LAST 2 PAGE AND ... IN THE MIDDLE 
                break;
        }*/

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", alignItems: "center" }}>
                <div>Total Records:  {totalRecords}</div>                 
                <div>
                    <ul className="pagination pagination-circle pagination-outline">
                        <li
                            onClick={handlePageClicked}
                            data-id={parseInt(currentPageNumber) - 1 > 1 ? parseInt(currentPageNumber) - 1 : 1}
                            className={currentPageNumber == 1 ? " page-item disabled previous m-1 " : "page-item previous m-1"} ><span className="page-link"><i className="previous"></i></span></li>
                        {
                            pagesArray.map((index) => {
                                if ((parseInt(index) + 1) == currentPageNumber) {
                                    return <li key={index} onClick={handlePageClicked} className="page-item active m-1 " data-id={parseInt(index) + 1}> <span className="page-link">  {parseInt(index) + 1} </span> </li>
                                }
                                else {
                                    if(index == 0 || index ==1 || index%pageNumberingDevider == 0){
                                        return <li key={index} onClick={handlePageClicked} className="page-item m-1 " data-id={parseInt(index) + 1}> <span className="page-link">  {parseInt(index) + 1} </span> </li>
                                    }
                                    else{
                                        return "";
                                    }
                                }
                            })
                        }
                        <li
                            onClick={handlePageClicked}
                            data-id={parseInt(currentPageNumber) + 1 > totalPages ? totalPages : parseInt(currentPageNumber) + 1}
                            className="page-item next m-1">
                            <span className="page-link"><i className="next"></i></span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Pagination;


/**
 * 
 *                   <div style={{    padding: "0 30px" }}>
                <ul className="pagination pagination-circle pagination-outline" style={{    marginTop: "26px" ,  justifyContent: "end"}}>
                    <li className="page-item previous disabled m-1"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">1</a></li>
                    <li className="page-item active m-1"><a href="#" className="page-link">2</a></li>
                    <li class="page-item "><a href="#" class="page-link">...</a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">9</a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">10</a></li>
                    <li className="page-item next m-1"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
 */ 