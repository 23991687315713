import { CustomButton, IconButton } from '../HtmlComponents';

const NoteListItem = ({ singleNote, callback }) => {
    return (
        <>
            <tr>
                <th>{singleNote.note} </th>
                <th>
                    <IconButton iconType="deleteBin" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" buttonOnClick={() => { callback("deleteNote", singleNote.id) }} />
                </th>
            </tr>
        </>
    )
}

export default NoteListItem;