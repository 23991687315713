
//import toast from 'react-hot-toast';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { IconButton } from '../HtmlComponents';

const Notifications = (notificationType, message) => {
    if (typeof message == "string") { } else { message = JSON.stringify(message); }

    /*
    switch (notificationType) {
        case "success": toast.success(message, {
            duration: 2000,
            position: 'top-center',
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        }); break;
        case "error": toast.error(message); break;
    }*/
    switch (notificationType) {
        case "success": 
            toast(
                (t) => (
                    <>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                            <div> <span> {message} </span> </div>
                            <div style={{ width: "25px" }}>
                                <IconButton buttonOnClick={() => toast.dismiss(t.id)} iconType="delete" buttonStyle={{ border: "0px", backgroundColor: "transparent" }} iconColor="red" />
                            </div>
                        </div>
                    </>
                ),
                {
                    type: "success",
                    duration: 5000,
                    position: 'top-center',
                    iconTheme: {
                        primary: '#61d345',
                        secondary: '#fff',
                    },
                }
            );
            break;
        case "error": toast.error(message); break;
    }
    //TOASTER COMPNENT IS CALLED IN app.JS 

}

export default Notifications;