import axios from './axios';


const getComments = async (token, commentType, applicationId, ) => {

    try {
      const response = await axios.get(`comment/${commentType}/${applicationId}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const createComment = async (token,  commentObj) => {
  try{
    const response = await axios.post(`comment`, commentObj , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}

 
const deleteComment = async(token, id) => {
  try {
    const response = await axios.delete(`comment/${id}`, { headers: { Authorization: "Bearer " + token } });
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

export { createComment, getComments , deleteComment};