
import { useEffect, useState } from "react";
import { SelectField, CustomButton, InputField } from "../../HtmlComponents";
import Constants from "../../global/Constants";
import { getUsers, getApplicationProgressStatus } from '../../../api';
import { useToken, useEntityId } from '../../../hooks/useAuth';


const AdvanceSearch = ({callback}) => {
    const [allowSubmission, setAllowSubmission] = useState(false);
    const [progressStatusList, setProgressStatusList] = useState(false);
    const [loanPurposes, setLoanPurposes] = useState(false);
    const [analystList, setAnalystList] = useState(false);


    var token = useToken();
    var entityId = useEntityId();


    const [filters, setFilters] = useState({
        firstName: "",
        surname: "",
        contactNumber: "",
        email: "",
        progressStatus: "",
        purpose: "",   //LOAN PURPOSE
        analystId: "",
        applicationId: ""
    })


    //FETCH LOAN PURPOSES
    const fetchLoanPurposes = () => {
        //LOAD LOAN PURPOSES
        var loanPurposeOptions = [];
        Object.keys(Constants.LOAN_PURPOSES).map(function (key, index) {
            loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] })
        })
        setLoanPurposes(loanPurposeOptions);
    }
    //FETCH ANALYST LIST
    const fetchAnalyst = async () => {
        var response = await getUsers(token, "creditRep", entityId);
        console.log(response);

        if (response["error"] == 0) {
            var users = response["data"];
            if (Array.isArray(users)) {
                var options = users.map(user => {
                    return { value: `${user.id}`, label: user.firstName }
                })
                setAnalystList(options);
            }
        }
    }

    //FETCH PROGRESS STATUS LIST
    const fetchProgressStatus = async () => {
        var response = await getApplicationProgressStatus(token);
        if (response["error"] == 0) {
            var progressStatusArray = response["data"];
            if (Array.isArray(progressStatusArray)) {
                var options = progressStatusArray.map(singleProgressStatus => {
                    return { value: singleProgressStatus.name, label: singleProgressStatus.label }
                })
                setProgressStatusList(options);
            }
        }
    }

    useEffect(() => {
        fetchLoanPurposes();
        fetchAnalyst();
        fetchProgressStatus();

    }, [])

    const handler = (name, value) => {
        switch (name) {
            case "submit": 
            let tmpFilters = JSON.parse(JSON.stringify(filters));
            
            Object.keys(tmpFilters).map( index => {  if(tmpFilters[index].length == 0){ delete tmpFilters[index]; } })
            callback("advanceSearch", tmpFilters );
            break;
            case "clearFilters":
                setFilters({
                    firstName: "",
                    surname: "",
                    contactNumber: "",
                    email: "",
                    progressStatus: "",
                    purpose: "",   //LOAN PURPOSE
                    analystId: "",
                    applicationId: ""
                });
                callback("advanceSearch", {} );
                break;
            default:
                console.log("values of input/select  name", name)
                console.log("values of input/select", value)
                setFilters({ ...filters, [name]: value })
                break;
        }

    }

    useEffect( ()=>{
        //CHECK IF SOM EVAUE IS PROVIDED FOR ATLEAST 1 KET THEN ALLOW SUBMISSION
        let countKeyshasValues = 0;
        Object.keys(filters).map(index => {if(filters[index].length > 0){countKeyshasValues++; } })
        if(countKeyshasValues > 0){ setAllowSubmission(true); }
        else{setAllowSubmission(false);}

    },[filters])

    return (<>
        <div className="card card-flush h-md-50 mb-5 mb-xl-10">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-3 col-md-3 mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>First Name</label>
                        <InputField
                            fieldName="firstName"
                            placeholder=""
                            fieldValue={filters.firstName}
                            setFieldValue={handler}
                        />
                    </div>
                    <div className="col-sm-3 col-md-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Surname</label>
                        <InputField
                            fieldName="surname"
                            placeholder=""
                            fieldValue={filters.surname}
                            setFieldValue={handler}
                        />
                    </div>

                    <div className="col-sm-3 col-md-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Contact #</label>
                        <InputField
                            fieldName="contactNumber"
                            placeholder=""
                            fieldValue={filters.contactNumber}
                            setFieldValue={handler}
                        />
                    </div>

                    <div className="col-sm-3 col-md-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Email</label>
                        <InputField
                            fieldName="email"
                            placeholder=""
                            fieldValue={filters.email}
                            setFieldValue={handler}
                        />
                    </div>


                    <div className="col-sm-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Referral Status</label>
                        <SelectField
                            fieldName="progressStatus"
                            defaultSelectedOption={{ value: filters.progressStatus, label: "" }}
                            options={progressStatusList}
                            setFieldValue={handler}
                        />
                    </div>
                    <div className="col-sm-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Loan Purpose</label>
                        <SelectField
                            fieldName="purpose"
                            defaultSelectedOption={{ value: filters.purpose, label: "" }}
                            options={loanPurposes}
                            setFieldValue={handler}
                        />
                    </div>

                    <div className="col-sm-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Analyst</label>
                        <SelectField
                            fieldName="analystId"
                            defaultSelectedOption={{ value: filters.analystId, label: "" }}
                            options={analystList}
                            setFieldValue={handler}
                        />
                    </div>
                    <div className="col-sm-3 col-md-3  mt-3 ">
                        <label style={{ fontSize: "10px", fontWeight: "600" }}>Referral #</label>
                        <InputField
                            fieldName="applicationId"
                            placeholder=""
                            fieldValue={filters.applicationId}
                            setFieldValue={handler}
                        />
                    </div>

                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={() => { handler("submit", "") }}
                            buttonText="Search"
                            isActive={allowSubmission}
                        />

                        <CustomButton
                            buttonClassName="btn btn-sm btn-secondary "
                            buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                            buttonOnClick={() => { handler("clearFilters", "") }}
                            buttonText="Clear Filters"
                            isActive={true}
                        />
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default AdvanceSearch;