import { useEffect, useState } from 'react';
import { useToken } from '../../../hooks/useAuth';
import { getComments } from '../../../api';
import{formatDate} from '../../../helpers';


const Comments = ({ applicationId, callback }) => {

    const [comments, setComments] = useState([]);    
    var token = useToken();

    //FETCH NOTES 
    const fetchComments = async () => {
        var response = await getComments(token, "progress", applicationId);
        if (response["error"] == 0) {
            setComments(response["data"]);
        }
    }

    useEffect(() => {
        fetchComments();
    }, []);
 
    return (
        <>
             <div className='d-flex row'>
                <h3>Comments</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment}  ({ formatDate(singleComment.timeStamp, 'unix', 'DD/MM/yyyy')  }) </div> }) : ""}
                </div>
            </div>
        </>
    )
}

export default Comments;