import axios from './axios';



/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getFiles = async (token, entityType, entityId) => {
  try{
    console.log("URL" , `file/${entityType}/${entityId}/`)
    const response = await axios.get(`file/${entityType}/${entityId}/`, { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}


const updateFile = async (token, entityType, entityId, fileId, fileObj) => {

  try {
    const response = await axios.put(`file/${entityType}/${entityId}/${fileId}`, fileObj, { headers: { Authorization: "Bearer " + token } });
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

const uploadFile = async(token, entityType, entityId, selectedFolderId, fileObj) => {
  try {
    const response = await axios.post(`file/${entityType}/${entityId}/${selectedFolderId}`, fileObj, { headers: { Authorization: "Bearer " + token } });
    return response.data ? response.data : [];
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

export { getFiles, updateFile , uploadFile};