import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
    window.isScriptLoaded = true;
  }
};

function handleScriptLoad(updateQuery, updateAddress, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "aus" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, updateAddress)
  );
}

async function handlePlaceSelect(updateQuery, updateAddress) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);

  //LOOP AND FETCH COMPONENTS FROM ARRAY
  var address = {
    fullAddress: "",
    unitNumber: "",
    streetNumber: "",
    streetTypeShortName: "",
    streetTypeLongName: "",
    streetName: "",
    state: "",
    postCode: "",
    suburb: "",
    country: ""
  };
  address["fullAddress"] = addressObject.formatted_address;

  Object.keys(addressObject.address_components).map((key) => {
    console.log(addressObject.address_components[key].types[0]);

    switch (addressObject.address_components[key].types[0]) {
      case "subpremise":
        address["unitNumber"] =
          addressObject.address_components[key]["short_name"];
        break; //unit NUmebr
      case "street_number":
        address["streetNumber"] =
          addressObject.address_components[key]["short_name"];
        break; //st number
      case "locality":
        address["suburb"] = addressObject.address_components[key]["short_name"];
        break; //SUBURB
      case "administrative_area_level_1":
        address["state"] = addressObject.address_components[key]["short_name"];
        break; //STATE
      case "country":
        address["country"] =
          addressObject.address_components[key]["short_name"];
        break; //COUNTRY
      case "postal_code":
        address["postCode"] =
          addressObject.address_components[key]["short_name"];
        break; //POSTAL CODE
      case "route":
        var shortName = addressObject.address_components[key]["short_name"];
        var routeComponents = shortName.split(" ");
        address["streetName"] = routeComponents[0] ? routeComponents[0] : "";
        address["streetTypeShortName"] = routeComponents[1]
          ? routeComponents[1]
          : "";

        //SPLIT LONG NAME
        var longName = addressObject.address_components[key]["long_name"];
        var routeLongComponents = longName.split(" ");
        address["streetTypeLongName"] = routeLongComponents[1]
          ? routeLongComponents[1]
          : "";

        break; //COMBINATION OF STREET NAME AND STREET TYPE - BREAK IT DOWN
      default:
        break;
    }
  });

  updateAddress(address);
}

function SearchLocationInput({ setAddressModule, disableField= false }) {
  const [query, setQuery] = useState("");
  const [lookUpAddress, setLookUpAddress] = useState({});

  const autoCompleteRef = useRef(null);

  useEffect(() => {
    setAddressModule(lookUpAddress);
  }, [lookUpAddress]);

  useEffect(() => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyAw20K_6TmUuJpOo3hQplmISJOZj-p7fSY&libraries=places`,
        () => handleScriptLoad(setQuery, setLookUpAddress, autoCompleteRef)
      ); 
  }, []);

  return (
    <div className=" search-location-input">
      <input
        ref={autoCompleteRef}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
        placeholder="Start Typing"
        value={query}
        className=" form-control form-control-solid"
        disabled={disableField}
      />
    </div>
  );
}

export { SearchLocationInput };
