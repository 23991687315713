import { useEffect, useState } from "react";



const DrawTable = ({ data }) => {
    const [tableData, setTableData] = useState([]);
    const [comparePeriod, setComparePeriod] = useState(false);

    useEffect(() => {

        if (data !== null && data !== undefined && data.entitiesName !== undefined && data.report !== undefined && data.report.periodData !== undefined && data.report.comparePeriodData !== undefined) {
            let entities = data.entitiesName;
            let periodData = data.report.periodData;
            let comparePeriodData = data.report.comparePeriodData;
            let tmpTableData = {};

            //LOAD ENTITIES NAME INTO AN ARRAY
            if (Array.isArray(entities) && entities.length > 0) { entities.forEach(entity => { tmpTableData[entity["tradingName"]] = { periodValue: 0, comparePeriodValue: 0 }; }); }

            //LOOP THROUGH PERIOD DATA
            if (Array.isArray(periodData) && periodData.length > 0) {
                periodData.forEach(data => {
                    let entityName = data.entityName;
                    let periodValue = isNaN(parseFloat(data.value)) ? 0 : parseFloat(data.value);

                    //EXTRA CHECK IF ENTITY NAME IS NOT PROVIDED BUT THERE IS A RECORD FOR A NEW ENTITY
                    if(tmpTableData[entityName]){ 
                        tmpTableData[entityName].periodValue += periodValue; 
                    } 
                });
            }

            //LOOP THROUGH COMPARE PERIOD DATA
            if (Array.isArray(comparePeriodData) && comparePeriodData.length > 0) {
                comparePeriodData.forEach(data => {
                    let entityName = data.entityName;
                    let comparePeriodValue = isNaN(parseFloat(data.value)) ? 0 : parseFloat(data.value);
                    if(tmpTableData[entityName]){ tmpTableData[entityName].comparePeriodValue += comparePeriodValue; }                     
                });
                setComparePeriod(true); //SET COMPARE PERIOD TO TRUE
            }
            setTableData(tmpTableData);
        }
    }, [data]);

    return (
        <>
            <div className="card card-body" py-3> 
                <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-4">
                        <thead>
                            <tr className="fw-bolder text-muted bg-light">
                                <th>Entity Name</th>
                                <th>Period</th>
                                {comparePeriod && <th>Compare Period</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(tableData).map(([entityName, { periodValue, comparePeriodValue }]) => (
                                <tr key={entityName}>
                                    <td>{entityName}</td>
                                    <td>{periodValue}</td>
                                    {comparePeriod && <td>{comparePeriodValue}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )


}

export default DrawTable;