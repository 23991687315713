

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../HtmlComponents';

import CreateOfficeContact from './CreateOfficeContact';
import UpdateOfficeContact from './UpdateOfficeContact';
import SingleOfficeContact from "./SingleOfficeContact";

import { getOfficeContact, getAllOfficeContacts, createOfficeContact, updateOfficeContact } from "../../api";

const ListOfficeContacts = () => {
    const [officeContacts, setOfficeContacts] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });

    let { id } = useParams();
    const userRole = useRole();
    const token = useToken();

    var createOfficeContactAllowed = true;

    useEffect(() => {
        loadOfficeContacts();
    }, []);

    const loadOfficeContacts = async() => {         
       var response = await getAllOfficeContacts(token);
        if(response["error"] !== undefined && response["error"] == 0 && response["data"] !==undefined && Array.isArray(response["data"])){
            setOfficeContacts(response["data"]);  
        } 
    }


    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, payload) => {
        switch (action) {
            case "updatePayloadOnOfficeContactCreation":
                setOfficeContacts([...officeContacts, payload]);
                drawHandler("closeDrawer");
                break;
            case "updatePayloadOnOfficeContactUpdation":
                console.log("payload::::::::::  ", payload);  
                var officeContactId = payload["id"] !== undefined ?   payload["id"]: 0;
                var officeContactName = payload["name"] !== undefined ?   payload["name"]: "Untitled";
                var officeContactProperties = payload["properties"] !== undefined && Array.isArray(payload["properties"]) ? payload["properties"] : [];

                if(officeContactId !== 0){
                    setOfficeContacts(
                        officeContacts.map( singleOfficeContact => singleOfficeContact.id === officeContactId ? {...singleOfficeContact, name: officeContactName, properties: officeContactProperties} : singleOfficeContact)
                    )
                }
            drawHandler("closeDrawer");
                break;    

        }
    }

    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateOfficeContactDrawer":
                setDrawerSettings({ visible: true, heading: "Create Office Contact", childName: "CreateOfficeContact", meta: { width: "800" } });
                break;
            case "openUpdateOfficeContactDrawer":
                console.log("meta ::: ", meta);
                var officeContactId = meta["officeContactId"] || 0;
                var singleOfficeContact = officeContacts.find(singleOfficeContact => singleOfficeContact.id == officeContactId );
                console.log("tmpOfficeContact :: ", singleOfficeContact);
                if(singleOfficeContact !== undefined){
                    setDrawerSettings({ visible: true, heading: "Update Office Contact", childName: "UpdateOfficeContact", meta: { width: "800", officeContactId: meta["officeContactId"] || 0 , singleOfficeContact : singleOfficeContact} });
                }
 
                break;

        }
    }

    

    const OfficeContacts = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 ">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-black fw-bolder fs-3">Office Contacts</h3>
                            {createOfficeContactAllowed ?
                                <CustomButton
                                    buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                    isActive={true}
                                    buttonText="Add"
                                    buttonOnClick={() => { drawHandler("openCreateOfficeContactDrawer") }}
                                />
                                : ""}
                        </div>
                    </div>
                    <div className="bg-body card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <div className="row">
                        {
                            Array.isArray(officeContacts) ? 
                            officeContacts.map( singleOfficeContact => {
                                return (
                                    <>
                                        <SingleOfficeContact officeContact= {singleOfficeContact} callback={drawHandler} /> 
                                    </>
                                )
                            })
                            :""

                        }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {OfficeContacts()}
                    </div>
                </div>
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "CreateOfficeContact" ? <CreateOfficeContact  callback={  payloadUpdateHandler } /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateOfficeContact" ? <UpdateOfficeContact officeContactId={drawerSettings["meta"]["officeContactId"]} singleOfficeContact= {drawerSettings["meta"]["singleOfficeContact"] } callback= { payloadUpdateHandler } /> : ""
                    }
                </RightDrawer> : ""
            }
        </>
    );

}


export default ListOfficeContacts;