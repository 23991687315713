import * as Yup from 'yup';
import { parse, isDate } from "date-fns";


function parseDateString(value, originalValue) {
    //originalValue = '21/01/2023';
    console.log("Orignal value: " + originalValue)
    console.log( parse(originalValue, "dd-MM-yyyy", new Date()));
    return isDate(originalValue) ? originalValue  : parse(originalValue, "dd-MM-yyyy", new Date());
  }

const CreditRepValidationSchema = Yup.object().shape({
    tradingName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    entityName: Yup.string().min(2, 'Too Short!') .max(50, 'Too Long!').required('Required'),
    abn: Yup.string() .matches(/^[0-9]+$/, "Must be only digits").min(11, 'Must be exactly 11 digits').max(11, 'Must be exactly 11 digits'),
    acn: Yup.string() .matches(/^[0-9]+$/, "Must be only digits") .min(9, 'Must be exactly 9 digits') .max(9, 'Must be exactly 9 digits'),
    contactNumber: Yup.string() .matches(/^[0-9]+$/, "Must be only digits").max(15, 'Must not be more than 15 digits'),    
    fax: Yup.string().matches(/^[0-9]+$/, "Must be only digits"),        
    email: Yup.string().email("Must be valid email"),
    websiteUrl: Yup.string().url("Must be valid URL"),
    //dateEstablished : Yup.date("Invalid Formatting").nullable().transform(parseDateString).max(new Date(), "Cannot be future date"),

    
    bankingDetail: Yup.object().shape({
        bsb : Yup.string() .matches(/^[0-9]+$/, "Must be only digits"),
        accountNumber : Yup.string().matches(/^[0-9]+$/, "Must be only digits")        
    }),

    associations : Yup.object().shape({
        afcaMembership : Yup.object().shape({
            //expiryDate : Yup.date("Invalid Formatting").nullable().transform(parseDateString).min(new Date(), "Cannot be past date"),
            membershipNumber : Yup.string().matches(/^[0-9]+$/, "Must be only digits"),
        }),
        insurer: Yup.object().shape({
            //expiryDate : Yup.date("Invalid Formatting").nullable().transform(parseDateString).min(new Date(), "Cannot be past date"),
            name : Yup.string().nullable().min(0).max(70, 'Too Long!')         
        })        
    })

});

export default CreditRepValidationSchema;