import axios from './axios';


//newReferrerSignupRequest
const newReferrerSignupRequest = async (payload) => {
    try {
      const response = await axios.post("public/newReferrerSignupRequest", payload, { });
      console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
      return { "error": 0, httpCode: response.status, "message": "Submitted successfully", referrer: response.data }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
      }
    }
  }

  //REFERRER ACREDITATION REQUEST
  const referrerAccreditationRequest = async (payload) => {
    try {
      const response = await axios.post("public/referrerAccreditationRequest", payload, {  headers: {  'Authorization': `Bearer ${payload.token}`  } });
      console.log("RESPOSNE FROM SERVER CREATING THE ENDER--------------", response);
      return { "error": 0, httpCode: response.status, "message": "Submitted successfully", referrer: response.data }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
      }
    }
  }

  
  export {  newReferrerSignupRequest , referrerAccreditationRequest};
