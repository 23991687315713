
import { formatDate } from '../../helpers';
import { IconButton, ConfirmDialog } from '../HtmlComponents';
import { useToken } from '../../hooks/useAuth';
import Notifications from '../global/Notifications';
import { deleteContact } from "../../api";

const SingleContact = ({ contact, callback }) => {
    var token = useToken();
    const handler = async (name, contactId) => {
        switch (name) {
            case "deleteContact":
                ConfirmDialog({ callback: () => { handler("executeDeleteContactOperation", contactId) }, "purpose": "general", "heading": `Are you sure you want to delete contact?`, "description": "" })
                break;
            case "executeDeleteContactOperation":
                 let response = await deleteContact(token, contactId);
                 if(response["error"] !== undefined && response["error"] == 1){ Notifications("error", response["message"] || ""); }
                 else{Notifications("success", response["message"] || ""); callback("refreshContactsListing", contactId)} 
                break;
        }
    }

    return (
        <>
            <tr>
                <td> {contact && contact.firstName !== undefined ? contact.firstName : ""} </td>
                <td> {contact && contact.role !== undefined ? contact.role : ""} </td>
                <td> {contact && contact.contactNumber !== undefined ? contact.contactNumber : ""}</td>
                <td> {contact && contact.email !== undefined ? contact.email : ""} </td>
                <td> <IconButton
                    iconType="edit"
                    buttonClassName="btn btn-sm btn-light btn-active-secondary"
                    buttonOnClick={() => { callback("updateContact", contact.id) }}
                    tooltipDescription="Update"
                />

                    <IconButton
                        iconType="delete"
                        buttonClassName="btn btn-sm btn-light btn-active-secondary"
                        buttonStyle={{ background: "transparent", color: "red" }}
                        buttonOnClick={() => { handler("deleteContact", contact.id) }}
                        tooltipDescription="Delete User"
                    />
                </td>
            </tr>
        </>

    )
}
export default SingleContact;