import { useEffect, useState } from "react";
import { useToken } from "../../../../../hooks/useAuth";
import { loadReporting } from '../../../../../api';
import ReportingConstants from '../../ReportingConstants';
import { months, colors, getPeriodNameArray, getFormattedDataForPeriod, getFormattedDataForComparePeriod, DrawGraphForComparePeriod, DrawGraph } from "./GraphHelpers";
import DrawTable from "./DrawTable";
const statRequiresSecondGrapoh = ["currentSettledReferrals", "currentDeclinedReferrals", "currentApprovedReferrals"];
//generateReportForSecondGraph called if we need to load multiple grapg against single stat

const LineGraph = ({ searchFilters }) => {

    const token = useToken();
    const [reportComparisionEnabled, setReportComparisionEnabled] = useState(false);

    const [lineChartData, setLineChartData] = useState([]);             //GRAPH DATA
    const [lineChartDataComparePeriod, setLineChartDataComparePeriod] = useState([]);             //GRAPH DATA

    //FOR A FEW STATS WE NEED TO PRODUCE 2 GRAPH SO HAVING SEPRATE STATE TO ACCOMODATE THIS - (currentSettledReferrals -> NEED 2 GRAPH 1 FOR LISTING SETTLEMENTS AND ANOTHER ONE FOR APPLICATIONS)
    const [lineChartDataSecondGraph, setLineChartDataSecondGraph] = useState([]);             //GRAPH DATA
    const [lineChartDataSecondGraphComparePeriod, setLineChartDataSecondGraphComparePeriod] = useState([]);             //GRAPH DATA
    const [statForSecondGraph, setStatForSecondGraph] = useState("");

    const [rawDataForTable, setRawDataForTable] = useState({});  //USE THIS TO PROVIDE DATA FOR TABLE

    const [notes, setNotes] = useState(""); // USE THIS TO PROVIDE ADDITIONAL NOTES IF REQUIRED

    //GENERATE A NEW REPORT WHEN SEARCH FILTERS ARE UPDATED
    useEffect(() => {
        generateReport();
        generateReportForSecondGraph();
        __updateAdditionalInfoForYAxis();
    }, [searchFilters])


    //CALL BACKEND TO GET THE NEW REPORT DATA FOR GIVEN SEARCH FILTERS
    const generateReport = async () => {
        var periodRresponse = await loadReporting(token, searchFilters);
//        console.log("periodRresponse:::: ", periodRresponse);

        let comparisionEnabled = false;

        if (periodRresponse["data"] !== undefined && periodRresponse["data"]["report"] !== undefined && periodRresponse["data"]["report"]["periodData"] !== undefined && periodRresponse["data"]["report"]["periodData"].length > 0) {
            var addingNewFiledValueResponse = await __calculateAndAddNewColumn(periodRresponse["data"]["report"]["periodData"], searchFilters["stat"]);
            periodRresponse["data"]["report"]["periodData"] = addingNewFiledValueResponse;
        }

        comparisionEnabled = periodRresponse["data"] !== undefined && periodRresponse["data"]["report"] !== undefined && periodRresponse["data"]["report"]["comparePeriodData"] !== null && periodRresponse["data"]["report"]["comparePeriodData"] !== undefined && periodRresponse["data"]["report"]["comparePeriodData"].length > 0 ? true : false;
        setReportComparisionEnabled(comparisionEnabled);  //UPDATE STATE     
        if (comparisionEnabled) {
            var addingNewFiledValueResponseForComparisionsReport = await __calculateAndAddNewColumn(periodRresponse["data"]["report"]["comparePeriodData"], searchFilters["stat"]);
            periodRresponse["data"]["report"]["comparePeriodData"] = addingNewFiledValueResponseForComparisionsReport;
        }

        //FORMAT THE DATA THAT THE GRAPH CAN UNDERSTAND
        updateLineChartDataV2(periodRresponse["data"], comparisionEnabled);   //LINE CHART DATA - V2
        console.log("periodRresponse::::   line 54 ------------- ", periodRresponse);
        setRawDataForTable(periodRresponse["data"])
    }


    //GENERATE REPORT IF SELECTED STAT REQUIRES ANOTHER GRAPH
    const generateReportForSecondGraph = async () => {
        //DECIDE WHICH STAT TO USE FOR SECOND GRAPH - IT DEPENDS ON THE SELECTED STAT
        var dependatStat = "";
        switch (true) {
            case ["currentSettledReferrals", "currentApprovedReferrals"].includes(searchFilters["stat"]):
                dependatStat = "totalReferralsReceivedAscendingOrderBasedOnNumbers";
                break;
        }

        setStatForSecondGraph(dependatStat);

        //GENERATE REPORT FOR SECOND GRAPH
        if (dependatStat == "") {
            return null;
        }

        let searchFiltersForSecondGraph = JSON.parse(JSON.stringify(searchFilters));
        searchFiltersForSecondGraph["stat"] = dependatStat;
        let periodRresponse = await loadReporting(token, searchFiltersForSecondGraph);
        console.log("periodRresponse fror 2nd graph --- :::: ", periodRresponse);
        var comparePeriodResponse = {};
        let comparisionEnabled = false;

        //FETCH DATA FOR COMPARED PERIOD
        /* if (searchFilters["comparePeriod"]["name"] !== '' &&
             searchFilters["comparePeriod"]["startDate"] !== 0 &&
             searchFilters["comparePeriod"]["endDate"] !== 0
         ) {
             comparePeriodResponse = await loadReporting(token, {
                 reportFor: searchFilters["reportFor"],
                 reportForExtraInfo: searchFilters["reportForExtraInfo"],
                 stat: dependatStat,
                 periodicType: searchFilters["periodicType"],
                 period: {
                     name: searchFilters["comparePeriod"]["name"],
                     startDate: searchFilters["comparePeriod"]["startDate"],
                     endDate: searchFilters["comparePeriod"]["endDate"]
                 }
             });
             comparisionEnabled = true;
         }*/

        if (periodRresponse["data"] !== undefined && periodRresponse["data"]["report"] !== undefined && periodRresponse["data"]["report"]["periodData"] !== undefined && periodRresponse["data"]["report"]["periodData"].length > 0) {
            var addingNewFiledValueResponse = await __calculateAndAddNewColumn(periodRresponse["data"]["report"]["periodData"], dependatStat);
            periodRresponse["data"]["report"]["periodData"] = addingNewFiledValueResponse;
        }

        /*if (periodRresponse["data"] !== undefined && periodRresponse["data"]["report"] !== undefined && periodRresponse["data"]["report"].length > 0) {
            var addingNewFiledValueResponse = await __calculateAndAddNewColumn(periodRresponse["data"]["report"], dependatStat);
            periodRresponse["data"]["report"] = addingNewFiledValueResponse;
        }*/

        comparisionEnabled = periodRresponse["data"] !== undefined && periodRresponse["data"]["report"] !== undefined && periodRresponse["data"]["report"]["comparePeriodData"] !== null && periodRresponse["data"]["report"]["comparePeriodData"] !== undefined && periodRresponse["data"]["report"]["comparePeriodData"].length > 0 ? true : false;
        if (comparisionEnabled) {
            var addingNewFiledValueResponseForComparisionsReport = await __calculateAndAddNewColumn(periodRresponse["data"]["report"]["comparePeriodData"], dependatStat);
            periodRresponse["data"]["report"]["comparePeriodData"] = addingNewFiledValueResponseForComparisionsReport;
        }


        /*if (comparePeriodResponse["data"] !== undefined && comparePeriodResponse["data"]["report"] !== undefined && comparePeriodResponse["data"]["report"].length > 0) {
            var addingNewFiledValueResponseForComparisionsReport = await __calculateAndAddNewColumn(comparePeriodResponse["data"]["report"], dependatStat)
            comparePeriodResponse["data"]["report"] = addingNewFiledValueResponseForComparisionsReport;
        }*/

        //FORMAT THE DATA THAT THE GRAPH CAN UNDERSTAND
        //updateLineChartDataV2(periodRresponse["data"], comparisionEnabled, true);   //LINE CHART DATA - V2
        updateLineChartDataV2(periodRresponse["data"], comparisionEnabled, true);   //LINE CHART DATA - V2
    }

    //UPDATE LINE CHART DATA V2  - VALUES ON X AXIS AND DATE ON CHART (FOR PERIOD VS COMPARE PERIOD)
    const updateLineChartDataV2 = (data, comparisionEnabled, isSecondGraphSelected = false) => {
        //STEP 1 - GET FORMATTED DATA (2D ARRAY) FOR PERIOD
        var periodEntityArray = getFormattedDataForPeriod(data["entitiesName"], data["report"]["periodData"], searchFilters);
        
        //STEP 2 - GET FORMATTED DATA (2D ARRAY) FOR COMPARE PERIOD
        if (comparisionEnabled) {
            let periodEntityArrayForComparePeriod = getFormattedDataForComparePeriod(data["entitiesName"], data["report"]["comparePeriodData"], searchFilters);

            //GET OBJECT KEYS 
            let periodEntityArrayKeysForComparePeriod = Object.keys(periodEntityArrayForComparePeriod);
            var iteratorConter = 0;
            var mergedArray = {};

            Object.keys(periodEntityArray).map(singleRecord => {
                //STEP 3 - MERGE RECORDS OF PERIOD AND COMPARE PERIOD
                let tmpPeriodSingleRecord = periodEntityArray[singleRecord];
                let tmpComparePeriodSingleRecord = periodEntityArrayKeysForComparePeriod[iteratorConter] !== undefined && periodEntityArrayForComparePeriod[periodEntityArrayKeysForComparePeriod[iteratorConter]] !== undefined ? periodEntityArrayForComparePeriod[periodEntityArrayKeysForComparePeriod[iteratorConter]] : {};

                let comparePeriodSingleRecordIndexName = periodEntityArrayKeysForComparePeriod[iteratorConter] !== undefined ? periodEntityArrayKeysForComparePeriod[iteratorConter] : "NA"
                mergedArray[`${singleRecord} | ${comparePeriodSingleRecordIndexName}`] = { periodName: singleRecord, comparePeriodName: comparePeriodSingleRecordIndexName, ...tmpPeriodSingleRecord, ...tmpComparePeriodSingleRecord }
                iteratorConter++;
            })
            //STEP 3.1 
            let tmpLineChartDataForComparePeriod = Object.keys(mergedArray).map(singleRecord => { return { name: singleRecord, ...mergedArray[singleRecord] } })

            //STEP 3.2 - UPDATE THE STATE
            isSecondGraphSelected ? setLineChartDataSecondGraphComparePeriod(tmpLineChartDataForComparePeriod) : setLineChartDataComparePeriod(tmpLineChartDataForComparePeriod);

            //MERGE RECORDS OF PERIOD AND COMPARE PERIOD
            //  console.log(" mergedArray:::::::::::::::   LINE 308 ------ ", mergedArray);
        }
        else {
            //STEP 3.1 - FORMAT THE DATA FOR LINE CHART
            let tmpLineChartData = Object.keys(periodEntityArray).map(singleRecord => { return { name: singleRecord, ...periodEntityArray[singleRecord] } })

            //STEP 3.2 - UPDATE THE STATE
            isSecondGraphSelected ? setLineChartDataSecondGraph(tmpLineChartData) : setLineChartData(tmpLineChartData);
        }
    }

    //ADD A NEW COLUMN 'VALUE' SO VALUES FOR EACH GRAPH CAN BE FECTHED FROM SAME NAME  - CALLED FROM GENERATE-REPORT FUNCTION
    const __calculateAndAddNewColumn = async (reportData, stat) => {
        if (!Array.isArray(reportData) || reportData.length == 0) {
            return reportData;
        }

        return new Promise((resolve) => {
            //COLUMN NANME THAT WOULD BE COPIED OVER OR REQUIRES CALCULATION FOR ASSIGNING A VALUE TO THE COLUMN 'VALUE'
            switch (true) {
                case ["totalReferralsReceivedAscendingOrderBasedOnNumbers"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalRecords"];
                    })
                    break;
                case ["currentApprovedReferralsAccurateBasedOnReceivedDatePercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["approvedApplications"] / singleRecord["totalRecords"]) * 100).toFixed(2);
                    })
                    break;
                case ["currentSettledReferralsAcurateBasedOnReceivedDatePercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["settledApplications"] / singleRecord["totalRecords"]) * 100).toFixed(2);
                    })
                    break;
                case ["currentDeclinedReferralsAccurateBasedOnReceivedDatePercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["declinedApplications"] / singleRecord["totalRecords"]) * 100).toFixed(2);
                    })
                    break;
                case ["currentApprovedReferrals"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalApprovedApplications"];
                    })
                    break;
                case ["currentSettledReferrals"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalSettlements"];
                    })
                    break;
                case ["currentDeclinedReferrals", "statsForAllIndividualReasonsForDeclinedPercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalDeclined"];
                    })
                    if (stat === "statsForAllIndividualReasonsForDeclinedPercentage") {
                        setNotes("This statistic does not accurately represent the total number of declined applications. Instead, it accounts for all selected reasons. For instance, if one declined application has three distinct reasons for rejection, it will be counted three times, once for each reason, unless a single reason has been selected explicitly in the filter.")
                    }
                    break;
                case ["activeReferrals"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["activeApplications"];
                    })
                    break;
                case ["settlementRateApplicationsApprovedToSettlementPercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalSettledApplications"] / singleRecord["totalApprovedApplications"]) * 100;
                    })
                    break;

                case ["didNotProceedApplicationsPercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalDidNotProceedApplications"] / singleRecord["totalRecords"]).toFixed(2);;
                    })
                    break;
                case ["daysToDecision"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["daysToDecision"];
                    })
                    break;
                case ["naf"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalFinancedAmount"];
                    })
                    break;
                case ["averageNAF"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["averageNetAmountFinanced"];
                    })
                    break;
                case ["averageGrossIncomePerSettlement"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (parseFloat(singleRecord["totalFinanceCommission"]) + parseFloat(singleRecord["totalOriginationFee"]) + parseFloat(singleRecord["totalBrokarageFee"]) + parseFloat(singleRecord["totalInsuranceCommission"]) / singleRecord["totalSettledApplications"]);
                    })
                    break;
                case ["grossIncome"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = parseFloat(singleRecord["totalFinanceCommission"]) + parseFloat(singleRecord["totalOriginationFee"]) + parseFloat(singleRecord["totalBrokarageFee"]) + parseFloat(singleRecord["totalInsuranceCommission"]);
                    })
                    break;
                case ["nettIncome"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalCommission"];
                    })
                    break;
                case ["averageNettIncomePerSettlement"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalCommission"] / singleRecord["totalSettledApplications"]).toFixed(2);
                    })
                    break;
                case ["dealerCommission"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalReferrerCommission"]).toFixed(2);
                    })
                    break;
                case ["averageDealerCommissionPerDeal"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalReferrerCommission"] / singleRecord["totalSettledApplications"]).toFixed(2);
                    })
                    break;
                case ["insuranceIncomeTotal", "insuranceIncomeWarranty", "insuranceIncomeOther", "insuranceIncomeComprehensive"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = (singleRecord["totalInsuranceCommission"]).toFixed(2);
                    })
                    break;
                case ["dealerCommissionAsPercentageOfGrossIncome"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["totalReferrerCommission"]) / (parseFloat(singleRecord["totalFinanceCommission"]) + parseFloat(singleRecord["totalOriginationFee"]) + parseFloat(singleRecord["totalBrokarageFee"]) + parseFloat(singleRecord["totalInsuranceCommission"])) * 100).toFixed(2);
                    })
                    break;
                case ["commercialLoansPercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["totalCommercialApplications"] / parseFloat(singleRecord["totalRecords"])) * 100).toFixed(2);
                    })
                    break;
                case ["consumerLoansPercentage"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = ((singleRecord["totalConsumerApplications"] / parseFloat(singleRecord["totalRecords"])) * 100).toFixed(2);
                    })
                    break;
                case  ["individualLoanPurpose"].includes(stat):
                    reportData.map(singleRecord => {
                        singleRecord["value"] = singleRecord["totalApplications"];
                    })    

            }
            resolve(reportData)

        })
    }

    //RETURN THE ADDITIONAL INFO FOR Y AXIS (E.G IS IT NUMBER OR % AND POSITION OF SYMBOL ETC)
    const __updateAdditionalInfoForYAxis = (stat) => {
        switch (true) {
            case ["totalReferralsReceivedAscendingOrderBasedOnNumbers", "currentSettledReferrals", "currentApprovedReferrals"].includes(stat):
                return { signPosition: "right", sign: "" };
                break;
            case [
                "currentApprovedReferralsAccurateBasedOnReceivedDatePercentage",
                "currentSettledReferralsAcurateBasedOnReceivedDatePercentage",
                "currentDeclinedReferralsAccurateBasedOnReceivedDatePercentage",
                "currentDeclinedReferrals",
                "didNotProceedApplicationsPercentage",
                "dealerCommissionAsPercentageOfGrossIncome",
                "totalCommercialApplications",
                "consumerLoansPercentage"
            ].includes(stat):
                return { signPosition: "right", sign: "%" };
                break;
            case ["naf", "averageNAF", "grossIncome", "averageGrossIncomePerSettlement", "nettIncome", "averageNettIncomePerSettlement",
                "dealerCommission", "averageDealerCommissionPerDeal", "insuranceIncomeTotal", "insuranceIncomeMotor", "insuranceIncomeWarranty", "insuranceIncomeOther"].includes(stat):
                return { signPosition: "left", sign: "$ " };
                break;
            default:
                return { signPosition: "left", sign: "" };
                break;
        }
    }
    return (<>
        <div className=" ">
            {/** LINE CHART */}

            <h4> {
                searchFilters["stat"] !== undefined && searchFilters["stat"].length > 0 ? ReportingConstants.stats.find(stat => stat.value === searchFilters["stat"])["label"] : ""

            }</h4><br />

            {reportComparisionEnabled ?
                <DrawGraphForComparePeriod lineChartDataComparePeriod={lineChartDataComparePeriod} additionalInfoForYAxis={__updateAdditionalInfoForYAxis(searchFilters["stat"])} /> :
                <DrawGraph lineChartData={lineChartData} lineChartDataComparePeriod={lineChartDataComparePeriod} additionalInfoForYAxis={__updateAdditionalInfoForYAxis(searchFilters["stat"])} />
            }

            {
                statRequiresSecondGrapoh.includes(searchFilters["stat"]) ?
                    <>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <h4> {
                            statForSecondGraph.length > 0 ? ReportingConstants.stats.find(stat => stat.value === statForSecondGraph)["label"] : ""

                        }</h4><br />
                        {reportComparisionEnabled ?
                            <DrawGraphForComparePeriod lineChartDataComparePeriod={lineChartDataSecondGraphComparePeriod} additionalInfoForYAxis={__updateAdditionalInfoForYAxis(statForSecondGraph)} /> :
                            <DrawGraph lineChartData={lineChartDataSecondGraph} lineChartDataComparePeriod={lineChartDataSecondGraphComparePeriod} additionalInfoForYAxis={__updateAdditionalInfoForYAxis(statForSecondGraph)} />
                        }
                    </>
                    : null
            }

            <div className="row">
                <div className="col-sm-12">
                    {notes.length > 0 ? <p> <b>Note:</b> {notes} </p> : ""}
                </div>
            </div>


            <div>
                { /** DRAW TABLE ONLY IF THE VALUE IS WHOLE NUMBER AND NOT PERCENTAGE */}
                {
                    ["currentApprovedReferralsAccurateBasedOnReceivedDatePercentage", "currentSettledReferralsAcurateBasedOnReceivedDatePercentage",
                        "currentDeclinedReferralsAccurateBasedOnReceivedDatePercentage", "settlementRateApplicationsApprovedToSettlementPercentage",
                        "didNotProceedApplicationsPercentage", "averageNettIncomePerSettlement", "dealerCommissionAsPercentageOfGrossIncome", 
                        "commercialLoansPercentage","consumerLoansPercentage"
                    ].includes(searchFilters["stat"]) ? "":
                        <>
                            <br /><br />
                            <h4>{searchFilters["stat"] !== undefined && searchFilters["stat"].length > 0 ? ReportingConstants.stats.find(stat => stat.value === searchFilters["stat"])["label"] : ""}</h4>
                            <br />
                            <DrawTable data={rawDataForTable} />
                        </> 
                }

            </div>

        </div>

    </>)
}

export default LineGraph;