import axios from './axios';


const getTemplates = async (token ) => {

    try {
      const response = await axios.get(`eSignature/getTemplates`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const createESignatureRequest = async (token,  payload) => {
  try{
    const response = await axios.post(`eSignature/`, payload , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}

export { getTemplates, createESignatureRequest };