import { FloatField, SelectField } from '../../HtmlComponents';
import { Field } from 'formik';

const AnalystCommission = ({ index, analysts, setFieldValue }) => {

    return (
        <>
            <div className="col-md-3 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                <label className=" fs-8 fw-bold mb-2">Analyst {parseInt(index)+1}</label>
                <Field name={`analystCommission[${index}].analystId`}>
                    {({ field }) => (
                        <SelectField
                            fieldName={`analystCommission[${index}].analystId`}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={analysts}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-md-3 col-sm-2  mb-4 fv-row fv-plugins-icon-container">
                <label className=" fs-8 fw-bold mb-2">Analyst {parseInt(index)+1} Finance Commission</label>
                <Field name={`analystCommission[${index}].commission`}  >
                    {({ field }) => (
                        <FloatField
                            fieldName={`analystCommission[${index}].commission`}
                            fieldValue={field.value !== undefined ? field.value : 0.00}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

        </>

    )
}




export default AnalystCommission;