import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker } from '../../HtmlComponents/';
import { formatDate, getCurrentDate } from '../../../helpers';
import Notifications from "../../global/Notifications";
import { performApplicationAdditionalActions, getReferrer, getComments } from "../../../api";
import ReferralIndicativeQuoteValidationSchema from '../schema/ReferralIndicativeQuoteValidationSchema';

import { useToken } from '../../../hooks/useAuth';

import LoadingScreen from '../../global/LoadingScreen';


const ReferralIndicativeQuote = ({ applicant, applicationId, callback }) => {
    const [initialValues, setInitialValues] = useState({ name : "", email: "", contactNumber:"", address : "" });
    const [processing, setProcessing] = useState(false);
    const [comments, setComments] = useState([]);

    const formRef = useRef();
    const token = useToken();

    //FETCH NOTES /cCOMMENTS
    const fetchComments = async () => {
        var response = await getComments(token, "indicativeQuote", applicationId);
        console.log("comments", response)
        if (response["error"] == 0) {
            setComments(response["data"]);
        }
    }

    useEffect(() => {
        //FILL INDIVIDUALDETAILS 
        var personalDetail = applicant.personalDetail !== undefined ? applicant.personalDetail : {};
        var firstName = personalDetail.firstName !== undefined ? personalDetail.firstName : "";
        var surname = personalDetail.surname !== undefined ? personalDetail.surname : "";
        var middleName = personalDetail.middleName !== undefined ? personalDetail.middleName : "";

        var fullName = firstName + " " + middleName + " " + surname;
        var email = personalDetail.email !== undefined ? personalDetail.email : "";
        var contactNumber = personalDetail.mobileNumber !== undefined ? personalDetail.mobileNumber: "";
        var currentFullAddress = personalDetail.residentialHistory !== undefined && personalDetail.residentialHistory.current !== undefined &&
        personalDetail.residentialHistory.current.address !== undefined && personalDetail.residentialHistory.current.address.fullAddress  ? personalDetail.residentialHistory.current.address.fullAddress : "";


        var tmpInitialValues = {
            name: fullName,
            contactNumber : contactNumber,
            address : currentFullAddress,
            email: email
        }
        setInitialValues(tmpInitialValues);

    }, [applicant]);

    useEffect( () => {
        fetchComments();
    }, [])

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}                        
                        validateOnBlur={false}
                        validationSchema={ReferralIndicativeQuoteValidationSchema}

                        onSubmit={async (values) => {

                            console.log("VALUES READY FOR SUBMISSION S:::::", values);
                            //MAKE AN API CALL 
                            setProcessing(true);
                            var response = await performApplicationAdditionalActions(token, "referralIndicativeQuote", applicationId, values);

                            var responseResult = response.data !== undefined ? response.data : {};
                            if (responseResult["error"] !== undefined && responseResult["error"] == 0) {
                                Notifications("success", responseResult["message"] !== undefined ? responseResult["message"] : "Sent");
                                callback("closeDrawer");
                            }
                            else {
                                Notifications("error", responseResult["message"] !== undefined ? responseResult["message"] : "Error");
                            }
                            fetchComments();
                            setProcessing(false);

                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referral Name</label>
                                        <Field name="name" className="form-control formField2" />
                                        { errors.name !== undefined  ? <div className="error">{errors.name}</div> : null}
                                    </div>
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referral Contact Number</label>
                                        <Field name="contactNumber" className="form-control formField2" />
                                        { errors.contactNumber !== undefined  ? <div className="error">{errors.contactNumber}</div> : null}
                                    </div>
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referral Address</label>
                                        <Field name="address" className="form-control formField2" />
                                        { errors.address !== undefined  ? <div className="error">{errors.address}</div> : null}
                                    </div>
                                    <div className="col-sm-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                                        <label className="required fs-8 fw-bold mb-2">Referral Email</label>
                                        <Field name="email" className="form-control formField2" />
                                        { errors.email !== undefined  ? <div className="error">{errors.email}</div> : null} 
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    {<FormSubmissionButton isActive={ errors.length > 0 ? false : true} label= "Save & Send Email" />}
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
            <div className='d-flex row'>
                <div className="separator separator-dashed my-5"></div>
                <h3>Referral Indicative Quote Log</h3>
                <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                </div>
            </div>

            {
                processing ? <LoadingScreen /> : ""
            }


        </>

    );

}

export default ReferralIndicativeQuote;