import axios from '../api/axios'; 


const createInsuranceCompany = async (token, insuranceCompany) => {
  try {
    const response = await axios.post(`insuranceCompany`, insuranceCompany, { headers: { Authorization: "Bearer " + token } });
    console.log(response);
    console.log("CREATION PROCESS COMPLETED");
    return { "error": 0, "message": "Createds successfully", "data": response.data }
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

//UPDATE 
const updateInsuranceCompany = async (token, id, insuranceCompany) => {
  try {
    const response = await axios.put(`insuranceCompany/${id}`, insuranceCompany, { headers: { Authorization: "Bearer " + token } });
    console.log(response);
    console.log("CREATION PROCESS COMPLETED");
    return { "error": 0, "message": "Updated successfully", "data": response.data }
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

/*GET SINGLE APPLICATION*/
const getInsuranceCompany = async (token, id) => {
  try {
    const response = await axios.get(`insuranceCompany/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
    }
  }

}

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getInsuranceCompaniesList = async (token) => {
  try {
    const response = await axios.get(`insuranceCompany`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

const deleteInsuranceCompany = async (token, id) => {

  try {
    const response = await axios.delete(`insuranceCompany/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, "message": response.data["message"] ? response.data["message"] || "Updated successfully" :"" }
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, application: {} }
    }
  }

}


export { createInsuranceCompany, updateInsuranceCompany, getInsuranceCompany, getInsuranceCompaniesList, deleteInsuranceCompany };